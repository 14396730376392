html {
	font-size: .625rem; // 1rem = 10px with this line (easier calcul)
}
body {
  @include font($global-font-family, 1.6rem, $font-weight-normal, $primary);
}

sup {
	font-size: 60%;
}

ul {
	padding-left: 1.8rem;
	list-style: square;
}

.invalid {
    color: #9d261d;
    font-weight: bold;
}

input.invalid {
    border: 1px solid #9d261d;
}

ol {
	padding-left: 1rem;
	li {
		counter-increment: li;
		padding-left: 1rem;
		margin-bottom: 1.5rem;
		&::marker {
			color: $vert;
			content: counter(li);
		}
	}
}
