.contact-dokamo{
  label{
	color: $gris;
	font-size: 1.4rem;
	padding-bottom: 16px;
	display: block;
  }
  .validate-email{
	border: 1px solid $gris9;
	border-radius: 4px;
	background-color: $blanc;
	padding-left: 15px;
	&::placeholder{
	  color:$gris10;
	  font-size: 1.4rem;
	}
  }
  select{
	border: 1px solid $gris9;
	border-radius: 4px;
	background-color: $white;
	color:$bleu;
	font-size: 1.4rem;
	box-shadow: none;
	padding:6px 16px;
	height: auto;
	div{
	  display: none;
	}
  }
  textarea{
	border: 1px solid $gris9;
	border-radius: 4px;
	background-color: $blanc;
	padding-left: 15px;
	min-height: 100px;
	&::placeholder{
	  color:$gris10;
	  font-size: 1.4rem;
	}
  }
  
  .validate-email, textarea{
	width: 100%;
  }
}


.contact-dokamo{

  button{
    padding: 13px 80px;
    border-radius: 4px;
    background-color: $vert;
    color: $blanc;
    text-transform: uppercase;
    text-align: center;
    line-height: 15px !important;
    font-size: 1.3rem;
    font-weight: bold;
    letter-spacing: 0.37px;
  }
  .informations{
    background-color: $violet;
    color: $blanc;
    padding: 21px 24px;
    border-radius: 4px;
    margin-bottom: 32px;
    h2{
      font-size: 2rem;
      color: $blanc;
      background: url(../img/contact-bulle.gif) no-repeat left 4px;
      padding-left: 35px;
    }
    address{
      position: relative;
      padding-left: 35px;
      margin-bottom: 0;
      font-size: 1.4rem;
      &.physique{
        background: url(../img/contact-position.gif) no-repeat left 3px;
      }
      &.tel{
        background: url(../img/contact-tel.gif) no-repeat left 3px;
        a{
          color: inherit;
          text-decoration: underline;
          &:hover{
            color: inherit;
          }
        }
      }
    }
  }
  .comment-sy-rendre{
    background-color: $bleu;
    color: $blanc;
    border-radius: 4px;
    padding: 20px 25px;
    margin-bottom: 30px;
    h2{
      color: $blanc !important;
      margin: 0;
      background: url(../img/picto_sy_rendre.png) no-repeat left top;
      padding-left: 35px;
      padding-bottom: 2px;
    }
    p{
      font-size: 1.4rem;
    }
    a{
      border: 1px solid $blanc;
      border-radius: 4px;
      text-transform: uppercase;
      text-align: center;
      color: $blanc !important;
      display: block;
      width: 100%;
      padding: 8px 0;
      font-size: 1.3rem;
    }
  }
  .agence{
    border: 1px solid $gris5;
    border-radius: 4px;
    padding: 24px;
    h2{
      color:$violet;
      font-size: 2rem;
      background: url(../img/contact-loupe.gif) no-repeat left 3px;
      padding-left: 35px;
      font-family: $global-font-family;
    }
    p{
      font-size: 1.4rem;
      color: $gris;
    }
    a{
      color: $violet;
      border: 1px solid $violet;
      border-radius: 4px;
      text-align: center;
      padding: 12px;
      text-transform: uppercase;
      font-size: 1.3rem;
      display: block;
      font-family: $global-font-family;
      font-weight: $font-weight-bold;
    }
  }
  .bord-gauche{
    @media(min-width: 640px) {
      padding-right: 35px;
    }
    @media(max-width: 640px) {
      padding-left: 0;
      & + div{
        padding-left: 0;
      }
    }
  }
}

.b-contact {
  border-radius: .4rem;
  border: 1px solid $gris5;
  .uk-card {
    background: $blanc;
    width: 100%;
    &-body {
      padding: 1.5rem;
    }
  }
  &__title {
    font-family: $global-font-family;
    font-weight: $font-weight-medium;
  }
  &__title, h3 {
    margin: 1rem 0;
  }
  &__address {
    padding: 0 2.5rem;
  }
  &__deco {
    background-color: $vert;
    background-position: center 15px;
    background-repeat: no-repeat;
    border-radius: .4rem;
    width: 40px;
    &.contact-addictologie {
      background-image: url(../img/ico-addiction.svg);
    }
    &.contact-sante-oral {
      background-image: url(../img/ico-dents-sante.svg);
    }
    &.contact-cancers-feminins {
      background-image: url(../img/ico-cancer-sein.svg);
    }
    &.contact-raa {
      background-image: url(../img/ico-raa.svg);
    }
    &.contact-mange-mieux-bouge-plus {
      background-image: url(../img/ico-mange-mieux.svg);
    }
    &.contact-sante-sexuelle {
      background-image: url(../img/ico-sante-sexuelle.svg);
    }
    &.contact-tuberculose {
      background-image: url(../img/ico-tuberculose.svg);
    }
    &.contact-barometre-sante {
      background-image: url(../img/ico-barometre-blanc.svg);
    }
    &.contact-centre-d-education {
      background-image: url(../img/ico-centre-education.svg);
    }
    &-simple {
      background: $violet2;
      background-image: url(../img/ico-contact.svg);
      background-position: center 25px;
      background-repeat: no-repeat;
      width: 90px;
    }
  }
  &__address,
  &__phone,
  &__mail {
    position:relative;
    padding: 0 2.5rem;
    &:before {
      content: url(../img/ico-adresse.svg);
      position: absolute;
      left: 0;
    }
  }
  &__address:before {
    content: url(../img/ico-adresse.svg);
  }
  &__phone:before {
    content: url(../img/ico-tel.svg);
  }
  &__mail:before {
    content: url(../img/ico-mail.svg);
  }
  &-simple {
    .uk-card {
      &-body {
        padding: 2.5rem;
      }
    }
  }
}


