.menu-footer{
	//color: $bleu;
	padding: 10px 0;
	ul{
		list-style-type: none;
		padding: 0;
		li{
			color: $white;
			font-size: 1.4rem;
			&:before{
				content:'-';
				padding: 0 8px;
				float: left;
			}
			a{
				color: $white;
				font-size: 1.4rem;
				padding: 0;
			}
			&:first-child{
				&:before{
					display: none;
				  	padding: 0;
				}
			}
		}
	}
}

@media(max-width: 640px){
	.menu-footer{
		ul {
			display: block;
			li {
				&:before{
					display: none;
				}
			}
		}
	}
}