
// variables de bootstrap par défault
// https://getbootstrap.com/docs/5.1/utilities/colors/#variables
$white:    #fff;
$gray-100: #EBF0F5;
$gray-101: #DFE7EA;
$gray-200: #e9ecef;
$gray-300: #EFF4F9;
$gray-400: #CFDBE8;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #444444;
$gray-900: #212529;
$black:    #000;

$blue:    #194270;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #8B1F5A;
$red:     #dc3545;
$orange:  #ED9353;
$yellow:  #ffc107;
$green:   #32B4B4;
$teal:    #20c997;
$cyan:    #65afe2;

$primary:       #2F3F8D;
$primary01:       #758DA7;
$primary02:       #141D47;
$primary03:       #293780;
$primary04:       #C0CBFF;
$primary05:       #F5FCFD;
$secondary:     $green;
$secondary01:	#F5FCFD;
$secondary02 : #BE581F;
$secondary03 : #F2B38F;
$secondary04 : #95C8D5;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-800;


// couleurs projet

$violet : #6A65A7;
$violet2 : #807BCD;
$violet3 : #494769;

$vert 		: #3CC0CC;
$vert2 		: #5BD5AC;
$vert3 		: #329EA8;
$vert4		: #A4B71B;
$vert-quizz : #50E3C2;

$gris : #758DA7;
$gris2 : #F9F8F8;
$gris3 : $gris;
$gris4 : #617993;
$gris5 : #E3E8ED;
$gris6 : #959FAB;
$gris7 : #E4E7EA;
$gris8 : #92A1B2;
$gris9 : #B9B9CC;
$gris10 : #9FB0C2;
$gris11 : #9F9F9F;
$gris12 : #EDEDED;
$gris13 : #effbfd;
$gris14 : #4A4F55;
$gris15 : #252525;
$gris16 : #6F7E94;
$gris17 : #64748B;
$gris18 : #B9B9CA;

$noir : #2E2E2E;
$rouge : #E55A35;

$orange : #fcaa3a;
$orange2 : #ED9353;
$orange3 : #FFC397;

$bleu : $primary;
$bleu2 : #141D47;
$bleu3 : #6A89AB;
$bleu4 : #192A3B;
$bleu5 : #354A76;
$bleu6 : #304d6d;
$turquoise : #B7E7EF;
$turquoise2 : #74C4D2;
$turquoise-clair : #E7F5F7;
$turquoise-75 : rgba(239, 251, 253, 0.75);
$turquoise-80 : rgba(231, 245, 247, 0.80);

$familiale-scolaire : #914198;
$etat-sante : #544598;
$alimentation : #F66A5D;
$sante-mentale : #FEDC71;
$tabac : #1DBDD6;
$sante-sexuelle : #90BF3B;
$contact : #999;

$theme-colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
	(
			"primary":    $primary,
			"primary01":    $primary01,
			"primary02":    $primary02,
			"primary03":    $primary03,
			"primary04":    $primary04,
			"primary05":    $primary05,
			"secondary":    $secondary,
			"secondary01":    $secondary01,
			"secondary02":    $secondary02,
			"secondary03":    $secondary03,
			"secondary04":    $secondary04,
			"violet":    $violet,
			"bleu5":    $bleu5,
			"orange":    $orange,
			"orange3":    $orange3,
			"gray-101":    $gray-101,
			"gray-300":    $gray-300,
			"gray-400":    $gray-400,
			"success":    $success,
			"info":       $info,
			"warning":    $warning,
			"danger":     $danger,
			"light":      $white,
			"dark":       $dark,
			"grisclair":  $gris13,
			"grisclair2":  $gris14,
			"turquoise":  $turquoise,
			"turquoise2":  $turquoise2,
			"turquoise-clair":  $turquoise-clair,
			"bleu2":  $bleu2,
			"turquoise-75":  $turquoise-75,
			"turquoise-80":  $turquoise-80,
	  //"new-color":  $new-color, // permet de créer des class bootstrap complète de type btn-new-color, bg-new-color, text-new-color etc...
	),
	$theme-colors
);
