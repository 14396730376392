@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Gantari:wght@400;500;600;700;900&family=Roboto:wght@400;500;700&display=swap");
/* ======= variables de bootsrap à réutiliser
https://getbootstrap.com/docs/5.1/utilities/text/#font-weight-and-italics
*/
/* ======= border-radius
https://getbootstrap.com/docs/5.1/utilities/borders/#variables
*/
/* ======= spacers
https://getbootstrap.com/docs/5.1/utilities/spacing/#sass
*/
/* ======= les gutters
https://getbootstrap.com/docs/5.1/layout/gutters/#change-the-gutters
*/
/* ======= les buttons
https://getbootstrap.com/docs/5.2/components/buttons/#sass-variables
*/
/* ======= les selects
https://getbootstrap.com/docs/5.1/forms/select/#variables
*/
/* ======= les positions
https://getbootstrap.com/docs/5.2/utilities/position/#sass
*/
/* ======= https://getbootstrap.com/docs/5.2/utilities/sizing/#utilities-api
*/
/* ======= utilities de bootstrap
*/
/*
https://getbootstrap.com/docs/5.2/components/card/#sass-variables
 */
/*
https://getbootstrap.com/docs/5.2/layout/grid/#variables
*/
/*
https://getbootstrap.com/docs/5.2/components/accordion/#sass-variables
 */
/*
https://getbootstrap.com/docs/5.2/components/card/#variables
 */
@font-face {
  font-family: 'myriad_probold';
  src: url("../fonts/myriadpro-bold-webfont.woff2") format("woff2"), url("../fonts/myriadpro-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

/* déclaration d'une fonction

@mixin truc($variable){
	color:$variable;
}

////* appel de la fonction

.class{
	@include truc(#000);
}

/* déclaration d'une procédure

%ma-fonction{
	color:#000;
}

////* appel de la procédure

.class{
	@extend %ma-fonction;
	border:0;
}


// fonctions déjà existantes
 
 - lighten($color, $amount) : Makes a color lighter.
 - darken($color, $amount) : Makes a color darker.
 - saturate($color, $amount) : Makes a color more saturated.
 - desaturate($color, $amount) : Makes a color less saturated.
 
Le tout sur ce site : http://sass-lang.com/documentation/Sass/Script/Functions.html
 
*/
.menu-responsive li.divider, .menu-responsive li .nav-header, .menu-responsive h2, .menu-responsive .h2 {
  margin-left: -50vw;
  margin-right: -50vw;
  clear: both;
  position: relative;
  right: 50%;
  left: 50%;
  width: 100vw; }

/* ========= appel des fonts
*/
/* ====== obligatoire
 */
/* ====== optionnel
 */
:root {
  --bs-blue: #194270;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #8B1F5A;
  --bs-red: #dc3545;
  --bs-orange: #fcaa3a;
  --bs-yellow: #ffc107;
  --bs-green: #32B4B4;
  --bs-teal: #20c997;
  --bs-cyan: #65afe2;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #444444;
  --bs-gray-100: #EBF0F5;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #EFF4F9;
  --bs-gray-400: #CFDBE8;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #444444;
  --bs-gray-900: #212529;
  --bs-primary: #2F3F8D;
  --bs-primary01: #758DA7;
  --bs-primary02: #141D47;
  --bs-primary03: #293780;
  --bs-primary04: #C0CBFF;
  --bs-primary05: #F5FCFD;
  --bs-secondary: #32B4B4;
  --bs-secondary01: #F5FCFD;
  --bs-secondary02: #BE581F;
  --bs-secondary03: #F2B38F;
  --bs-secondary04: #95C8D5;
  --bs-violet: #6A65A7;
  --bs-bleu5: #354A76;
  --bs-orange: #fcaa3a;
  --bs-orange3: #FFC397;
  --bs-gray-101: #DFE7EA;
  --bs-gray-300: #EFF4F9;
  --bs-gray-400: #CFDBE8;
  --bs-success: #32B4B4;
  --bs-info: #65afe2;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #fff;
  --bs-dark: #444444;
  --bs-grisclair: #effbfd;
  --bs-grisclair2: #4A4F55;
  --bs-turquoise: #B7E7EF;
  --bs-turquoise2: #74C4D2;
  --bs-turquoise-clair: #E7F5F7;
  --bs-bleu2: #141D47;
  --bs-turquoise-75: rgba(239, 251, 253, 0.75);
  --bs-turquoise-80: rgba(231, 245, 247, 0.8);
  --bs-primary-rgb: 47, 63, 141;
  --bs-primary01-rgb: 117, 141, 167;
  --bs-primary02-rgb: 20, 29, 71;
  --bs-primary03-rgb: 41, 55, 128;
  --bs-primary04-rgb: 192, 203, 255;
  --bs-primary05-rgb: 245, 252, 253;
  --bs-secondary-rgb: 50, 180, 180;
  --bs-secondary01-rgb: 245, 252, 253;
  --bs-secondary02-rgb: 190, 88, 31;
  --bs-secondary03-rgb: 242, 179, 143;
  --bs-secondary04-rgb: 149, 200, 213;
  --bs-violet-rgb: 106, 101, 167;
  --bs-bleu5-rgb: 53, 74, 118;
  --bs-orange-rgb: 252, 170, 58;
  --bs-orange3-rgb: 255, 195, 151;
  --bs-gray-101-rgb: 223, 231, 234;
  --bs-gray-300-rgb: 239, 244, 249;
  --bs-gray-400-rgb: 207, 219, 232;
  --bs-success-rgb: 50, 180, 180;
  --bs-info-rgb: 101, 175, 226;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 255, 255, 255;
  --bs-dark-rgb: 68, 68, 68;
  --bs-grisclair-rgb: 239, 251, 253;
  --bs-grisclair2-rgb: 74, 79, 85;
  --bs-turquoise-rgb: 183, 231, 239;
  --bs-turquoise2-rgb: 116, 196, 210;
  --bs-turquoise-clair-rgb: 231, 245, 247;
  --bs-bleu2-rgb: 20, 29, 71;
  --bs-turquoise-75-rgb: 239, 251, 253;
  --bs-turquoise-80-rgb: 231, 245, 247;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #EFF4F9;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.8rem;
  --bs-border-radius-sm: 0.6rem;
  --bs-border-radius-lg: 5px 20px 5px 20px;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #2F3F8D;
  --bs-link-hover-color: #263271;
  --bs-code-color: #8B1F5A;
  --bs-highlight-bg: #fff3cd; }

*,
*::before,
*::after {
  box-sizing: border-box; }

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth; } }

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw); }
  @media (min-width: 1200px) {
    h1, .h1 {
      font-size: 2.5rem; } }

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw); }
  @media (min-width: 1200px) {
    h2, .h2 {
      font-size: 2rem; } }

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw); }
  @media (min-width: 1200px) {
    h3, .h3 {
      font-size: 1.75rem; } }

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw); }
  @media (min-width: 1200px) {
    h4, .h4 {
      font-size: 1.5rem; } }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul {
  padding-left: 2rem; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small, .small {
  font-size: 0.875em; }

mark, .mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg); }

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: var(--bs-link-color);
  text-decoration: underline; }
  a:hover {
    color: var(--bs-link-hover-color); }

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.6rem; }
  kbd kbd {
    padding: 0;
    font-size: 1em; }

figure {
  margin: 0 0 1rem; }

img,
svg {
  vertical-align: middle; }

table {
  caption-side: bottom;
  border-collapse: collapse; }

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0; }

label {
  display: inline-block; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }
  select:disabled {
    opacity: 1; }

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }
  button:not(:disabled),
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled) {
    cursor: pointer; }

::-moz-focus-inner {
  padding: 0;
  border-style: none; }

textarea {
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit; }
  @media (min-width: 1200px) {
    legend {
      font-size: 1.5rem; } }
  legend + * {
    clear: left; }

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0; }

::-webkit-inner-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield; }

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-color-swatch-wrapper {
  padding: 0; }

::file-selector-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

iframe {
  border: 0; }

summary {
  display: list-item;
  cursor: pointer; }

progress {
  vertical-align: baseline; }

[hidden] {
  display: none !important; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-1 {
      font-size: 5rem; } }

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-2 {
      font-size: 4.5rem; } }

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-3 {
      font-size: 4rem; } }

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-4 {
      font-size: 3.5rem; } }

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-5 {
      font-size: 3rem; } }

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-6 {
      font-size: 2.5rem; } }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 0.875em;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }
  .blockquote > :last-child {
    margin-bottom: 0; }

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color); }
  .table > :not(caption) > * > * {
    padding: 0.5rem 0.5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg); }
  .table > tbody {
    vertical-align: inherit; }
  .table > thead {
    vertical-align: bottom; }

.table-group-divider {
  border-top: 2px solid currentcolor; }

.caption-top {
  caption-side: top; }

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem; }

.table-bordered > :not(caption) > * {
  border-width: 1px 0; }
  .table-bordered > :not(caption) > * > * {
    border-width: 0 1px; }

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0; }

.table-borderless > :not(:first-child) {
  border-top-width: 0; }

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color); }

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color); }

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color); }

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color); }

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #d5d9e8;
  --bs-table-border-color: #c0c3d1;
  --bs-table-striped-bg: #cacedc;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c0c3d1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c5c9d7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #d6f0f0;
  --bs-table-border-color: #c1d8d8;
  --bs-table-striped-bg: #cbe4e4;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c1d8d8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c6dede;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d6f0f0;
  --bs-table-border-color: #c1d8d8;
  --bs-table-striped-bg: #cbe4e4;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c1d8d8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c6dede;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #e0eff9;
  --bs-table-border-color: #cad7e0;
  --bs-table-striped-bg: #d5e3ed;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cad7e0;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #cfdde6;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #e6dbb9;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #dfc2c4;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #EBF0F5;
  --bs-table-border-color: #d4d8dd;
  --bs-table-striped-bg: #dfe4e9;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #d4d8dd;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d9dee3;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #444444;
  --bs-table-border-color: #575757;
  --bs-table-striped-bg: #4d4d4d;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #575757;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #525252;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1919.98px) {
  .table-responsive-xxxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  --bs-gutter-x: 3rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

@media (min-width: 1400px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1320px; } }

.row {
  --bs-gutter-x: 3rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x)); }
  .row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y); }

.col {
  flex: 1 0 0%; }

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto; }

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%; }

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%; }

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%; }

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%; }

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%; }

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-3 {
  flex: 0 0 auto;
  width: 25%; }

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%; }

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%; }

.col-6 {
  flex: 0 0 auto;
  width: 50%; }

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%; }

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%; }

.col-9 {
  flex: 0 0 auto;
  width: 75%; }

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%; }

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%; }

.col-12 {
  flex: 0 0 auto;
  width: 100%; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

.g-0,
.gx-0 {
  --bs-gutter-x: 0; }

.g-0,
.gy-0 {
  --bs-gutter-y: 0; }

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem; }

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem; }

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem; }

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem; }

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem; }

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem; }

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem; }

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem; }

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem; }

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem; }

.g-10,
.gx-10 {
  --bs-gutter-x: 1rem; }

.g-10,
.gy-10 {
  --bs-gutter-y: 1rem; }

.g-12,
.gx-12 {
  --bs-gutter-x: 1.2rem; }

.g-12,
.gy-12 {
  --bs-gutter-y: 1.2rem; }

.g-16,
.gx-16 {
  --bs-gutter-x: 1.6rem; }

.g-16,
.gy-16 {
  --bs-gutter-y: 1.6rem; }

.g-24,
.gx-24 {
  --bs-gutter-x: 2.4rem; }

.g-24,
.gy-24 {
  --bs-gutter-y: 2.4rem; }

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%; }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0; }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0; }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem; }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem; }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem; }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem; }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem; }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem; }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem; }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem; }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem; }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem; }
  .g-sm-10,
  .gx-sm-10 {
    --bs-gutter-x: 1rem; }
  .g-sm-10,
  .gy-sm-10 {
    --bs-gutter-y: 1rem; }
  .g-sm-12,
  .gx-sm-12 {
    --bs-gutter-x: 1.2rem; }
  .g-sm-12,
  .gy-sm-12 {
    --bs-gutter-y: 1.2rem; }
  .g-sm-16,
  .gx-sm-16 {
    --bs-gutter-x: 1.6rem; }
  .g-sm-16,
  .gy-sm-16 {
    --bs-gutter-y: 1.6rem; }
  .g-sm-24,
  .gx-sm-24 {
    --bs-gutter-x: 2.4rem; }
  .g-sm-24,
  .gy-sm-24 {
    --bs-gutter-y: 2.4rem; } }

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%; }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0; }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0; }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem; }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem; }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem; }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem; }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem; }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem; }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem; }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem; }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem; }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem; }
  .g-md-10,
  .gx-md-10 {
    --bs-gutter-x: 1rem; }
  .g-md-10,
  .gy-md-10 {
    --bs-gutter-y: 1rem; }
  .g-md-12,
  .gx-md-12 {
    --bs-gutter-x: 1.2rem; }
  .g-md-12,
  .gy-md-12 {
    --bs-gutter-y: 1.2rem; }
  .g-md-16,
  .gx-md-16 {
    --bs-gutter-x: 1.6rem; }
  .g-md-16,
  .gy-md-16 {
    --bs-gutter-y: 1.6rem; }
  .g-md-24,
  .gx-md-24 {
    --bs-gutter-x: 2.4rem; }
  .g-md-24,
  .gy-md-24 {
    --bs-gutter-y: 2.4rem; } }

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%; }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0; }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0; }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem; }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem; }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem; }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem; }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem; }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem; }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem; }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem; }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem; }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem; }
  .g-lg-10,
  .gx-lg-10 {
    --bs-gutter-x: 1rem; }
  .g-lg-10,
  .gy-lg-10 {
    --bs-gutter-y: 1rem; }
  .g-lg-12,
  .gx-lg-12 {
    --bs-gutter-x: 1.2rem; }
  .g-lg-12,
  .gy-lg-12 {
    --bs-gutter-y: 1.2rem; }
  .g-lg-16,
  .gx-lg-16 {
    --bs-gutter-x: 1.6rem; }
  .g-lg-16,
  .gy-lg-16 {
    --bs-gutter-y: 1.6rem; }
  .g-lg-24,
  .gx-lg-24 {
    --bs-gutter-x: 2.4rem; }
  .g-lg-24,
  .gy-lg-24 {
    --bs-gutter-y: 2.4rem; } }

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%; }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0; }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0; }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem; }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem; }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem; }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem; }
  .g-xl-10,
  .gx-xl-10 {
    --bs-gutter-x: 1rem; }
  .g-xl-10,
  .gy-xl-10 {
    --bs-gutter-y: 1rem; }
  .g-xl-12,
  .gx-xl-12 {
    --bs-gutter-x: 1.2rem; }
  .g-xl-12,
  .gy-xl-12 {
    --bs-gutter-y: 1.2rem; }
  .g-xl-16,
  .gx-xl-16 {
    --bs-gutter-x: 1.6rem; }
  .g-xl-16,
  .gy-xl-16 {
    --bs-gutter-y: 1.6rem; }
  .g-xl-24,
  .gx-xl-24 {
    --bs-gutter-x: 2.4rem; }
  .g-xl-24,
  .gy-xl-24 {
    --bs-gutter-y: 2.4rem; } }

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%; }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0; }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0; }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem; }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem; }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem; }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem; }
  .g-xxl-10,
  .gx-xxl-10 {
    --bs-gutter-x: 1rem; }
  .g-xxl-10,
  .gy-xxl-10 {
    --bs-gutter-y: 1rem; }
  .g-xxl-12,
  .gx-xxl-12 {
    --bs-gutter-x: 1.2rem; }
  .g-xxl-12,
  .gy-xxl-12 {
    --bs-gutter-y: 1.2rem; }
  .g-xxl-16,
  .gx-xxl-16 {
    --bs-gutter-x: 1.6rem; }
  .g-xxl-16,
  .gy-xxl-16 {
    --bs-gutter-y: 1.6rem; }
  .g-xxl-24,
  .gx-xxl-24 {
    --bs-gutter-x: 2.4rem; }
  .g-xxl-24,
  .gy-xxl-24 {
    --bs-gutter-y: 2.4rem; } }

@media (min-width: 1920px) {
  .col-xxxl {
    flex: 1 0 0%; }
  .row-cols-xxxl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xxxl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xxxl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xxxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xxxl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xxxl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xxxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxxl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xxxl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xxxl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxxl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xxxl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xxxl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xxxl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xxxl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xxxl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xxxl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xxxl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xxxl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xxxl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xxxl-0 {
    margin-left: 0; }
  .offset-xxxl-1 {
    margin-left: 8.33333%; }
  .offset-xxxl-2 {
    margin-left: 16.66667%; }
  .offset-xxxl-3 {
    margin-left: 25%; }
  .offset-xxxl-4 {
    margin-left: 33.33333%; }
  .offset-xxxl-5 {
    margin-left: 41.66667%; }
  .offset-xxxl-6 {
    margin-left: 50%; }
  .offset-xxxl-7 {
    margin-left: 58.33333%; }
  .offset-xxxl-8 {
    margin-left: 66.66667%; }
  .offset-xxxl-9 {
    margin-left: 75%; }
  .offset-xxxl-10 {
    margin-left: 83.33333%; }
  .offset-xxxl-11 {
    margin-left: 91.66667%; }
  .g-xxxl-0,
  .gx-xxxl-0 {
    --bs-gutter-x: 0; }
  .g-xxxl-0,
  .gy-xxxl-0 {
    --bs-gutter-y: 0; }
  .g-xxxl-1,
  .gx-xxxl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xxxl-1,
  .gy-xxxl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xxxl-2,
  .gx-xxxl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xxxl-2,
  .gy-xxxl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xxxl-3,
  .gx-xxxl-3 {
    --bs-gutter-x: 1rem; }
  .g-xxxl-3,
  .gy-xxxl-3 {
    --bs-gutter-y: 1rem; }
  .g-xxxl-4,
  .gx-xxxl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xxxl-4,
  .gy-xxxl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xxxl-5,
  .gx-xxxl-5 {
    --bs-gutter-x: 3rem; }
  .g-xxxl-5,
  .gy-xxxl-5 {
    --bs-gutter-y: 3rem; }
  .g-xxxl-10,
  .gx-xxxl-10 {
    --bs-gutter-x: 1rem; }
  .g-xxxl-10,
  .gy-xxxl-10 {
    --bs-gutter-y: 1rem; }
  .g-xxxl-12,
  .gx-xxxl-12 {
    --bs-gutter-x: 1.2rem; }
  .g-xxxl-12,
  .gy-xxxl-12 {
    --bs-gutter-y: 1.2rem; }
  .g-xxxl-16,
  .gx-xxxl-16 {
    --bs-gutter-x: 1.6rem; }
  .g-xxxl-16,
  .gy-xxxl-16 {
    --bs-gutter-y: 1.6rem; }
  .g-xxxl-24,
  .gx-xxxl-24 {
    --bs-gutter-x: 2.4rem; }
  .g-xxxl-24,
  .gy-xxxl-24 {
    --bs-gutter-y: 2.4rem; } }

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: 0.8rem;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius); }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 3rem; }
  .alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1.25rem 1rem; }

.alert-primary {
  --bs-alert-color: #1c2655;
  --bs-alert-bg: #d5d9e8;
  --bs-alert-border-color: #c1c5dd; }
  .alert-primary .alert-link {
    color: #161e44; }

.alert-primary01 {
  --bs-alert-color: #465564;
  --bs-alert-bg: #e3e8ed;
  --bs-alert-border-color: #d6dde5; }
  .alert-primary01 .alert-link {
    color: #384450; }

.alert-primary02 {
  --bs-alert-color: #0c112b;
  --bs-alert-bg: #d0d2da;
  --bs-alert-border-color: #b9bbc8; }
  .alert-primary02 .alert-link {
    color: #0a0e22; }

.alert-primary03 {
  --bs-alert-color: #19214d;
  --bs-alert-bg: #d4d7e6;
  --bs-alert-border-color: #bfc3d9; }
  .alert-primary03 .alert-link {
    color: #141a3e; }

.alert-primary04 {
  --bs-alert-color: #4d5166;
  --bs-alert-bg: #f2f5ff;
  --bs-alert-border-color: #ecefff; }
  .alert-primary04 .alert-link {
    color: #3e4152; }

.alert-primary05 {
  --bs-alert-color: #626565;
  --bs-alert-bg: #fdfeff;
  --bs-alert-border-color: #fcfefe; }
  .alert-primary05 .alert-link {
    color: #4e5151; }

.alert-secondary {
  --bs-alert-color: #1e6c6c;
  --bs-alert-bg: #d6f0f0;
  --bs-alert-border-color: #c2e9e9; }
  .alert-secondary .alert-link {
    color: #185656; }

.alert-secondary01 {
  --bs-alert-color: #626565;
  --bs-alert-bg: #fdfeff;
  --bs-alert-border-color: #fcfefe; }
  .alert-secondary01 .alert-link {
    color: #4e5151; }

.alert-secondary02 {
  --bs-alert-color: #723513;
  --bs-alert-bg: #f2ded2;
  --bs-alert-border-color: #eccdbc; }
  .alert-secondary02 .alert-link {
    color: #5b2a0f; }

.alert-secondary03 {
  --bs-alert-color: #614839;
  --bs-alert-bg: #fcf0e9;
  --bs-alert-border-color: #fbe8dd; }
  .alert-secondary03 .alert-link {
    color: #4e3a2e; }

.alert-secondary04 {
  --bs-alert-color: #3c5055;
  --bs-alert-bg: #eaf4f7;
  --bs-alert-border-color: #dfeff2; }
  .alert-secondary04 .alert-link {
    color: #304044; }

.alert-violet {
  --bs-alert-color: #403d64;
  --bs-alert-bg: #e1e0ed;
  --bs-alert-border-color: #d2d1e5; }
  .alert-violet .alert-link {
    color: #333150; }

.alert-bleu5 {
  --bs-alert-color: #202c47;
  --bs-alert-bg: #d7dbe4;
  --bs-alert-border-color: #c2c9d6; }
  .alert-bleu5 .alert-link {
    color: #1a2339; }

.alert-orange {
  --bs-alert-color: #654417;
  --bs-alert-bg: #feeed8;
  --bs-alert-border-color: #fee6c4; }
  .alert-orange .alert-link {
    color: #513612; }

.alert-orange3 {
  --bs-alert-color: #664e3c;
  --bs-alert-bg: #fff3ea;
  --bs-alert-border-color: #ffede0; }
  .alert-orange3 .alert-link {
    color: #523e30; }

.alert-gray-101 {
  --bs-alert-color: #595c5e;
  --bs-alert-bg: #f9fafb;
  --bs-alert-border-color: #f5f8f9; }
  .alert-gray-101 .alert-link {
    color: #474a4b; }

.alert-gray-300 {
  --bs-alert-color: #606264;
  --bs-alert-bg: #fcfdfe;
  --bs-alert-border-color: #fafcfd; }
  .alert-gray-300 .alert-link {
    color: #4d4e50; }

.alert-gray-400 {
  --bs-alert-color: #53585d;
  --bs-alert-bg: #f5f8fa;
  --bs-alert-border-color: #f1f4f8; }
  .alert-gray-400 .alert-link {
    color: #42464a; }

.alert-success {
  --bs-alert-color: #1e6c6c;
  --bs-alert-bg: #d6f0f0;
  --bs-alert-border-color: #c2e9e9; }
  .alert-success .alert-link {
    color: #185656; }

.alert-info {
  --bs-alert-color: #3d6988;
  --bs-alert-bg: #e0eff9;
  --bs-alert-border-color: #d1e7f6; }
  .alert-info .alert-link {
    color: #31546d; }

.alert-warning {
  --bs-alert-color: #664d03;
  --bs-alert-bg: #fff3cd;
  --bs-alert-border-color: #ffecb5; }
  .alert-warning .alert-link {
    color: #523e02; }

.alert-danger {
  --bs-alert-color: #842029;
  --bs-alert-bg: #f8d7da;
  --bs-alert-border-color: #f5c2c7; }
  .alert-danger .alert-link {
    color: #6a1a21; }

.alert-light {
  --bs-alert-color: #666666;
  --bs-alert-bg: white;
  --bs-alert-border-color: white; }
  .alert-light .alert-link {
    color: #525252; }

.alert-dark {
  --bs-alert-color: #292929;
  --bs-alert-bg: #dadada;
  --bs-alert-border-color: #c7c7c7; }
  .alert-dark .alert-link {
    color: #212121; }

.alert-grisclair {
  --bs-alert-color: #606465;
  --bs-alert-bg: #fcfeff;
  --bs-alert-border-color: #fafefe; }
  .alert-grisclair .alert-link {
    color: #4d5051; }

.alert-grisclair2 {
  --bs-alert-color: #2c2f33;
  --bs-alert-bg: #dbdcdd;
  --bs-alert-border-color: #c9cacc; }
  .alert-grisclair2 .alert-link {
    color: #232629; }

.alert-turquoise {
  --bs-alert-color: #495c60;
  --bs-alert-bg: #f1fafc;
  --bs-alert-border-color: #e9f8fa; }
  .alert-turquoise .alert-link {
    color: #3a4a4d; }

.alert-turquoise2 {
  --bs-alert-color: #2e4e54;
  --bs-alert-bg: #e3f3f6;
  --bs-alert-border-color: #d5edf2; }
  .alert-turquoise2 .alert-link {
    color: #253e43; }

.alert-turquoise-clair {
  --bs-alert-color: #5c6263;
  --bs-alert-bg: #fafdfd;
  --bs-alert-border-color: #f8fcfd; }
  .alert-turquoise-clair .alert-link {
    color: #4a4e4f; }

.alert-bleu2 {
  --bs-alert-color: #0c112b;
  --bs-alert-bg: #d0d2da;
  --bs-alert-border-color: #b9bbc8; }
  .alert-bleu2 .alert-link {
    color: #0a0e22; }

.alert-turquoise-75 {
  --bs-alert-color: rgba(68, 72, 72, 0.9);
  --bs-alert-bg: rgba(253, 254, 255, 0.95);
  --bs-alert-border-color: rgba(252, 254, 255, 0.925); }
  .alert-turquoise-75 .alert-link {
    color: rgba(52, 55, 55, 0.92); }

.alert-turquoise-80 {
  --bs-alert-color: rgba(71, 75, 76, 0.92);
  --bs-alert-bg: rgba(252, 254, 254, 0.96);
  --bs-alert-border-color: rgba(250, 253, 253, 0.94); }
  .alert-turquoise-80 .alert-link {
    color: rgba(55, 58, 59, 0.936); }

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: 0.8rem;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius); }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.form-label {
  margin-bottom: 0.5rem; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem; }

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d; }

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #CFDBE8;
  appearance: none;
  border-radius: 0.8rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control[type="file"] {
    overflow: hidden; }
    .form-control[type="file"]:not(:disabled):not([readonly]) {
      cursor: pointer; }
  .form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: #979fc6;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(47, 63, 141, 0.25); }
  .form-control::-webkit-date-and-time-value {
    height: 1.5em; }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled {
    background-color: #e9ecef;
    opacity: 1; }
  .form-control::file-selector-button {
    padding: 0.375rem 0.75rem;
    margin: -0.375rem -0.75rem;
    margin-inline-end: 0.75rem;
    color: #212529;
    background-color: #e9ecef;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-control::file-selector-button {
        transition: none; } }
  .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: #dde0e3; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext:focus {
    outline: 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.6rem; }
  .form-control-sm::file-selector-button {
    padding: 0.25rem 0.5rem;
    margin: -0.25rem -0.5rem;
    margin-inline-end: 0.5rem; }

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 5px 20px 5px 20px; }
  .form-control-lg::file-selector-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    margin-inline-end: 1rem; }

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px); }

textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px); }

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px); }

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem; }
  .form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer; }
  .form-control-color::-moz-color-swatch {
    border: 0 !important;
    border-radius: 0.8rem; }
  .form-control-color::-webkit-color-swatch {
    border-radius: 0.8rem; }
  .form-control-color.form-control-sm {
    height: calc(1.5em + 0.5rem + 2px); }
  .form-control-color.form-control-lg {
    height: calc(1.5em + 1rem + 2px); }

.form-select {
  display: block;
  width: 100%;
  padding: 2.1rem 4.8rem 2.1rem 1.6rem;
  -moz-padding-start: calc(1.6rem - 3px);
  font-size: calc(1.285rem + 0.42vw);
  font-weight: 400;
  line-height: 1.5;
  color: #2F3F8D;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23758DA7' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1.6rem center;
  background-size: 16px 12px;
  border: 1px solid #CFDBE8;
  border-radius: 8px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none; }
  @media (min-width: 1200px) {
    .form-select {
      font-size: 1.6rem; } }
  @media (prefers-reduced-motion: reduce) {
    .form-select {
      transition: none; } }
  .form-select:focus {
    border-color: #979fc6;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(47, 63, 141, 0.25); }
  .form-select[multiple], .form-select[size]:not([size="1"]) {
    padding-right: 1.6rem;
    background-image: none; }
  .form-select:disabled {
    background-color: #e9ecef; }
  .form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #2F3F8D; }

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.6rem; }

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: 5px 20px 5px 20px; }

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem; }
  .form-check .form-check-input {
    float: left;
    margin-left: -1.5em; }

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right; }
  .form-check-reverse .form-check-input {
    float: right;
    margin-right: -1.5em;
    margin-left: 0; }

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  print-color-adjust: exact; }
  .form-check-input[type="checkbox"] {
    border-radius: 0.25em; }
  .form-check-input[type="radio"] {
    border-radius: 50%; }
  .form-check-input:active {
    filter: brightness(90%); }
  .form-check-input:focus {
    border-color: #979fc6;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(47, 63, 141, 0.25); }
  .form-check-input:checked {
    background-color: #2F3F8D;
    border-color: #2F3F8D; }
    .form-check-input:checked[type="checkbox"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e"); }
    .form-check-input:checked[type="radio"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e"); }
  .form-check-input[type="checkbox"]:indeterminate {
    background-color: #2F3F8D;
    border-color: #2F3F8D;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e"); }
  .form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: 0.5; }
  .form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
    cursor: default;
    opacity: 0.5; }

.form-switch {
  padding-left: 2.5em; }
  .form-switch .form-check-input {
    width: 2em;
    margin-left: -2.5em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2em;
    transition: background-position 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-switch .form-check-input {
        transition: none; } }
    .form-switch .form-check-input:focus {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23979fc6'/%3e%3c/svg%3e"); }
    .form-switch .form-check-input:checked {
      background-position: right center;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }
  .form-switch.form-check-reverse {
    padding-right: 2.5em;
    padding-left: 0; }
    .form-switch.form-check-reverse .form-check-input {
      margin-right: -2.5em;
      margin-left: 0; }

.form-check-inline {
  display: inline-block;
  margin-right: 1rem; }

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }
  .btn-check[disabled] + .btn, .btn-check:disabled + .btn {
    pointer-events: none;
    filter: none;
    opacity: 0.65; }

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .form-range:focus {
    outline: 0; }
    .form-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(47, 63, 141, 0.25); }
    .form-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(47, 63, 141, 0.25); }
  .form-range::-moz-focus-outer {
    border: 0; }
  .form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #2F3F8D;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-webkit-slider-thumb {
        transition: none; } }
    .form-range::-webkit-slider-thumb:active {
      background-color: #c1c5dd; }
  .form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #EFF4F9;
    border-color: transparent;
    border-radius: 1rem; }
  .form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #2F3F8D;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-moz-range-thumb {
        transition: none; } }
    .form-range::-moz-range-thumb:active {
      background-color: #c1c5dd; }
  .form-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #EFF4F9;
    border-color: transparent;
    border-radius: 1rem; }
  .form-range:disabled {
    pointer-events: none; }
    .form-range:disabled::-webkit-slider-thumb {
      background-color: #adb5bd; }
    .form-range:disabled::-moz-range-thumb {
      background-color: #adb5bd; }

.form-floating {
  position: relative; }
  .form-floating > .form-control,
  .form-floating > .form-control-plaintext,
  .form-floating > .form-select {
    height: calc(3.5rem + 2px);
    line-height: 1.25; }
  .form-floating > label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1rem 0.75rem;
    overflow: hidden;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-floating > label {
        transition: none; } }
  .form-floating > .form-control,
  .form-floating > .form-control-plaintext {
    padding: 1rem 0.75rem; }
    .form-floating > .form-control::placeholder,
    .form-floating > .form-control-plaintext::placeholder {
      color: transparent; }
    .form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
    .form-floating > .form-control-plaintext:focus,
    .form-floating > .form-control-plaintext:not(:placeholder-shown) {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
    .form-floating > .form-control:-webkit-autofill,
    .form-floating > .form-control-plaintext:-webkit-autofill {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
  .form-floating > .form-select {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem; }
  .form-floating > .form-control:focus ~ label,
  .form-floating > .form-control:not(:placeholder-shown) ~ label,
  .form-floating > .form-control-plaintext ~ label,
  .form-floating > .form-select ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
  .form-floating > .form-control:-webkit-autofill ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
  .form-floating > .form-control-plaintext ~ label {
    border-width: 1px 0; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-select,
  .input-group > .form-floating {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0; }
  .input-group > .form-control:focus,
  .input-group > .form-select:focus,
  .input-group > .form-floating:focus-within {
    z-index: 5; }
  .input-group .btn {
    position: relative;
    z-index: 2; }
    .input-group .btn:focus {
      z-index: 5; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #CFDBE8;
  border-radius: 0.8rem; }

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 5px 20px 5px 20px; }

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.6rem; }

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 6.4rem; }

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4),
.input-group.has-validation > .form-floating:nth-last-child(n + 3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n + 3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #32B4B4; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #000;
  background-color: rgba(50, 180, 180, 0.9);
  border-radius: 0.8rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #32B4B4;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2332B4B4' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #32B4B4;
    box-shadow: 0 0 0 0.25rem rgba(50, 180, 180, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #32B4B4; }
  .was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
    padding-right: 8.8rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23758DA7' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2332B4B4' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-position: right 1.6rem center, center right 4.8rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-select:valid:focus, .form-select.is-valid:focus {
    border-color: #32B4B4;
    box-shadow: 0 0 0 0.25rem rgba(50, 180, 180, 0.25); }

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem)); }

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #32B4B4; }
  .was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
    background-color: #32B4B4; }
  .was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
    box-shadow: 0 0 0 0.25rem rgba(50, 180, 180, 0.25); }
  .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
    color: #32B4B4; }

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em; }

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid, .was-validated
.input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid, .was-validated
.input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3; }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.8rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
    padding-right: 8.8rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23758DA7' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-position: right 1.6rem center, center right 4.8rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25); }

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem)); }

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
    background-color: #dc3545; }
  .was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
    color: #dc3545; }

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em; }

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid, .was-validated
.input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid, .was-validated
.input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
  .btn-group > .btn-check:checked + .btn,
  .btn-group > .btn-check:focus + .btn,
  .btn-group > .btn:hover,
  .btn-group > .btn:focus,
  .btn-group > .btn:active,
  .btn-group > .btn.active,
  .btn-group-vertical > .btn-check:checked + .btn,
  .btn-group-vertical > .btn-check:focus + .btn,
  .btn-group-vertical > .btn:hover,
  .btn-group-vertical > .btn:focus,
  .btn-group-vertical > .btn:active,
  .btn-group-vertical > .btn.active {
    z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group {
  border-radius: 3px; }
  .btn-group > :not(.btn-check:first-child) + .btn,
  .btn-group > .btn-group:not(:first-child) {
    margin-left: -1px; }
  .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group > .btn.dropdown-toggle-split:first-child,
  .btn-group > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .btn-group > .btn:nth-child(n + 3),
  .btn-group > :not(.btn-check) + .btn,
  .btn-group > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 24px;
  padding-left: 24px; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropend .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropstart .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn ~ .btn,
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .nav-link {
      transition: none; } }
  .nav-link:hover, .nav-link:focus {
    color: var(--bs-nav-link-hover-color); }
  .nav-link.disabled {
    color: var(--bs-nav-link-disabled-color);
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #EFF4F9;
  --bs-nav-tabs-border-radius: 0.8rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #EFF4F9;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #EFF4F9 #EFF4F9 #fff;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color); }
  .nav-tabs .nav-link {
    margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
    background: none;
    border: var(--bs-nav-tabs-border-width) solid transparent;
    border-top-left-radius: var(--bs-nav-tabs-border-radius);
    border-top-right-radius: var(--bs-nav-tabs-border-radius); }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      isolation: isolate;
      border-color: var(--bs-nav-tabs-link-hover-border-color); }
    .nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
      color: var(--bs-nav-link-disabled-color);
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: var(--bs-nav-tabs-link-active-color);
    background-color: var(--bs-nav-tabs-link-active-bg);
    border-color: var(--bs-nav-tabs-link-active-border-color); }
  .nav-tabs .dropdown-menu {
    margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills {
  --bs-nav-pills-border-radius: 0.8rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #2F3F8D; }
  .nav-pills .nav-link {
    background: none;
    border: 0;
    border-radius: var(--bs-nav-pills-border-radius); }
    .nav-pills .nav-link:disabled {
      color: var(--bs-nav-link-disabled-color);
      background-color: transparent;
      border-color: transparent; }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: var(--bs-nav-pills-link-active-color);
    background-color: var(--bs-nav-pills-link-active-bg); }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(0, 0, 0, 0.55);
  --bs-navbar-hover-color: rgba(0, 0, 0, 0.7);
  --bs-navbar-disabled-color: rgba(0, 0, 0, 0.3);
  --bs-navbar-active-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-hover-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(0, 0, 0, 0.1);
  --bs-navbar-toggler-border-radius: 3px;
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x); }
  .navbar > .container,
  .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    color: var(--bs-navbar-brand-hover-color); }

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .show > .nav-link,
  .navbar-nav .nav-link.active {
    color: var(--bs-navbar-active-color); }
  .navbar-nav .dropdown-menu {
    position: static; }

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color); }
  .navbar-text a,
  .navbar-text a:hover,
  .navbar-text a:focus {
    color: var(--bs-navbar-active-color); }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition); }
  @media (prefers-reduced-motion: reduce) {
    .navbar-toggler {
      transition: none; } }
  .navbar-toggler:hover {
    text-decoration: none; }
  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width); }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%; }

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto; }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; }
    .navbar-expand-sm .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-sm .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-sm .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; }
    .navbar-expand-md .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-md .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-md .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; }
    .navbar-expand-lg .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-lg .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-lg .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; }
    .navbar-expand-xl .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-xl .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-xl .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-xxl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; }
    .navbar-expand-xxl .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-xxl .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-xxl .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 1920px) {
  .navbar-expand-xxxl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxxl .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-xxxl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xxxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxxl .navbar-toggler {
      display: none; }
    .navbar-expand-xxxl .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-xxxl .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-xxxl .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: var(--bs-navbar-nav-link-padding-x);
      padding-left: var(--bs-navbar-nav-link-padding-x); }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }
  .navbar-expand .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none; }
    .navbar-expand .offcanvas .offcanvas-header {
      display: none; }
    .navbar-expand .offcanvas .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; }

.navbar-dark {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.btn {
  --bs-btn-padding-x: 32px;
  --bs-btn-padding-y: 8px;
  --bs-btn-font-family: ;
  --bs-btn-font-size: calc(1.255rem + 0.06vw);
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #212529;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 3px;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (min-width: 1200px) {
    .btn {
      --bs-btn-font-size: 1.3rem; } }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color); }
  .btn-check + .btn:hover {
    color: var(--bs-btn-color);
    background-color: var(--bs-btn-bg);
    border-color: var(--bs-btn-border-color); }
  .btn:focus-visible {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
    outline: 0;
    box-shadow: var(--bs-btn-focus-box-shadow); }
  .btn-check:focus-visible + .btn {
    border-color: var(--bs-btn-hover-border-color);
    outline: 0;
    box-shadow: var(--bs-btn-focus-box-shadow); }
  .btn-check:checked + .btn,
  :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
    color: var(--bs-btn-active-color);
    background-color: var(--bs-btn-active-bg);
    border-color: var(--bs-btn-active-border-color); }
    .btn-check:checked + .btn:focus-visible,
    :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
      box-shadow: var(--bs-btn-focus-box-shadow); }
  .btn:disabled, .btn.disabled,
  fieldset:disabled .btn {
    color: var(--bs-btn-disabled-color);
    pointer-events: none;
    background-color: var(--bs-btn-disabled-bg);
    border-color: var(--bs-btn-disabled-border-color);
    opacity: var(--bs-btn-disabled-opacity); }

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #2F3F8D;
  --bs-btn-border-color: #2F3F8D;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #283678;
  --bs-btn-hover-border-color: #263271;
  --bs-btn-focus-shadow-rgb: 78, 92, 158;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #263271;
  --bs-btn-active-border-color: #232f6a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #2F3F8D;
  --bs-btn-disabled-border-color: #2F3F8D; }

.btn-primary01 {
  --bs-btn-color: #000;
  --bs-btn-bg: #758DA7;
  --bs-btn-border-color: #758DA7;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #8a9eb4;
  --bs-btn-hover-border-color: #8398b0;
  --bs-btn-focus-shadow-rgb: 99, 120, 142;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #91a4b9;
  --bs-btn-active-border-color: #8398b0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #758DA7;
  --bs-btn-disabled-border-color: #758DA7; }

.btn-primary02 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #141D47;
  --bs-btn-border-color: #141D47;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #11193c;
  --bs-btn-hover-border-color: #101739;
  --bs-btn-focus-shadow-rgb: 55, 63, 99;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #101739;
  --bs-btn-active-border-color: #0f1635;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #141D47;
  --bs-btn-disabled-border-color: #141D47; }

.btn-primary03 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #293780;
  --bs-btn-border-color: #293780;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #232f6d;
  --bs-btn-hover-border-color: #212c66;
  --bs-btn-focus-shadow-rgb: 73, 85, 147;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212c66;
  --bs-btn-active-border-color: #1f2960;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #293780;
  --bs-btn-disabled-border-color: #293780; }

.btn-primary04 {
  --bs-btn-color: #000;
  --bs-btn-bg: #C0CBFF;
  --bs-btn-border-color: #C0CBFF;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #c9d3ff;
  --bs-btn-hover-border-color: #c6d0ff;
  --bs-btn-focus-shadow-rgb: 163, 173, 217;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #cdd5ff;
  --bs-btn-active-border-color: #c6d0ff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #C0CBFF;
  --bs-btn-disabled-border-color: #C0CBFF; }

.btn-primary05 {
  --bs-btn-color: #000;
  --bs-btn-bg: #F5FCFD;
  --bs-btn-border-color: #F5FCFD;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f7fcfd;
  --bs-btn-hover-border-color: #f6fcfd;
  --bs-btn-focus-shadow-rgb: 208, 214, 215;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f7fdfd;
  --bs-btn-active-border-color: #f6fcfd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #F5FCFD;
  --bs-btn-disabled-border-color: #F5FCFD; }

.btn-secondary {
  --bs-btn-color: #000;
  --bs-btn-bg: #32B4B4;
  --bs-btn-border-color: #32B4B4;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #51bfbf;
  --bs-btn-hover-border-color: #47bcbc;
  --bs-btn-focus-shadow-rgb: 43, 153, 153;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #5bc3c3;
  --bs-btn-active-border-color: #47bcbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #32B4B4;
  --bs-btn-disabled-border-color: #32B4B4; }

.btn-secondary01 {
  --bs-btn-color: #000;
  --bs-btn-bg: #F5FCFD;
  --bs-btn-border-color: #F5FCFD;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f7fcfd;
  --bs-btn-hover-border-color: #f6fcfd;
  --bs-btn-focus-shadow-rgb: 208, 214, 215;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f7fdfd;
  --bs-btn-active-border-color: #f6fcfd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #F5FCFD;
  --bs-btn-disabled-border-color: #F5FCFD; }

.btn-secondary02 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #BE581F;
  --bs-btn-border-color: #BE581F;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #a24b1a;
  --bs-btn-hover-border-color: #984619;
  --bs-btn-focus-shadow-rgb: 200, 113, 65;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #984619;
  --bs-btn-active-border-color: #8f4217;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #BE581F;
  --bs-btn-disabled-border-color: #BE581F; }

.btn-secondary03 {
  --bs-btn-color: #000;
  --bs-btn-bg: #F2B38F;
  --bs-btn-border-color: #F2B38F;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f4bea0;
  --bs-btn-hover-border-color: #f3bb9a;
  --bs-btn-focus-shadow-rgb: 206, 152, 122;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f5c2a5;
  --bs-btn-active-border-color: #f3bb9a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #F2B38F;
  --bs-btn-disabled-border-color: #F2B38F; }

.btn-secondary04 {
  --bs-btn-color: #000;
  --bs-btn-bg: #95C8D5;
  --bs-btn-border-color: #95C8D5;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #a5d0db;
  --bs-btn-hover-border-color: #a0ced9;
  --bs-btn-focus-shadow-rgb: 127, 170, 181;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #aad3dd;
  --bs-btn-active-border-color: #a0ced9;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #95C8D5;
  --bs-btn-disabled-border-color: #95C8D5; }

.btn-violet {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6A65A7;
  --bs-btn-border-color: #6A65A7;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5a568e;
  --bs-btn-hover-border-color: #555186;
  --bs-btn-focus-shadow-rgb: 128, 124, 180;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #555186;
  --bs-btn-active-border-color: #504c7d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6A65A7;
  --bs-btn-disabled-border-color: #6A65A7; }

.btn-bleu5 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #354A76;
  --bs-btn-border-color: #354A76;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2d3f64;
  --bs-btn-hover-border-color: #2a3b5e;
  --bs-btn-focus-shadow-rgb: 83, 101, 139;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #2a3b5e;
  --bs-btn-active-border-color: #283859;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #354A76;
  --bs-btn-disabled-border-color: #354A76; }

.btn-orange {
  --bs-btn-color: #000;
  --bs-btn-bg: #fcaa3a;
  --bs-btn-border-color: #fcaa3a;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #fcb758;
  --bs-btn-hover-border-color: #fcb34e;
  --bs-btn-focus-shadow-rgb: 214, 145, 49;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #fdbb61;
  --bs-btn-active-border-color: #fcb34e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #fcaa3a;
  --bs-btn-disabled-border-color: #fcaa3a; }

.btn-orange3 {
  --bs-btn-color: #000;
  --bs-btn-bg: #FFC397;
  --bs-btn-border-color: #FFC397;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffcca7;
  --bs-btn-hover-border-color: #ffc9a1;
  --bs-btn-focus-shadow-rgb: 217, 166, 128;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcfac;
  --bs-btn-active-border-color: #ffc9a1;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #FFC397;
  --bs-btn-disabled-border-color: #FFC397; }

.btn-gray-101 {
  --bs-btn-color: #000;
  --bs-btn-bg: #DFE7EA;
  --bs-btn-border-color: #DFE7EA;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #e4ebed;
  --bs-btn-hover-border-color: #e2e9ec;
  --bs-btn-focus-shadow-rgb: 190, 196, 199;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #e5ecee;
  --bs-btn-active-border-color: #e2e9ec;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #DFE7EA;
  --bs-btn-disabled-border-color: #DFE7EA; }

.btn-gray-300 {
  --bs-btn-color: #000;
  --bs-btn-bg: #EFF4F9;
  --bs-btn-border-color: #EFF4F9;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f1f6fa;
  --bs-btn-hover-border-color: #f1f5fa;
  --bs-btn-focus-shadow-rgb: 203, 207, 212;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f2f6fa;
  --bs-btn-active-border-color: #f1f5fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #EFF4F9;
  --bs-btn-disabled-border-color: #EFF4F9; }

.btn-gray-400 {
  --bs-btn-color: #000;
  --bs-btn-bg: #CFDBE8;
  --bs-btn-border-color: #CFDBE8;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d6e0eb;
  --bs-btn-hover-border-color: #d4dfea;
  --bs-btn-focus-shadow-rgb: 176, 186, 197;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #d9e2ed;
  --bs-btn-active-border-color: #d4dfea;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #CFDBE8;
  --bs-btn-disabled-border-color: #CFDBE8; }

.btn-success {
  --bs-btn-color: #000;
  --bs-btn-bg: #32B4B4;
  --bs-btn-border-color: #32B4B4;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #51bfbf;
  --bs-btn-hover-border-color: #47bcbc;
  --bs-btn-focus-shadow-rgb: 43, 153, 153;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #5bc3c3;
  --bs-btn-active-border-color: #47bcbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #32B4B4;
  --bs-btn-disabled-border-color: #32B4B4; }

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #65afe2;
  --bs-btn-border-color: #65afe2;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #7cbbe6;
  --bs-btn-hover-border-color: #74b7e5;
  --bs-btn-focus-shadow-rgb: 86, 149, 192;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #84bfe8;
  --bs-btn-active-border-color: #74b7e5;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #65afe2;
  --bs-btn-disabled-border-color: #65afe2; }

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107; }

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545; }

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #fff;
  --bs-btn-border-color: #fff;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d9d9d9;
  --bs-btn-hover-border-color: #cccccc;
  --bs-btn-focus-shadow-rgb: 217, 217, 217;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #cccccc;
  --bs-btn-active-border-color: #bfbfbf;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #fff;
  --bs-btn-disabled-border-color: #fff; }

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #444444;
  --bs-btn-border-color: #444444;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #606060;
  --bs-btn-hover-border-color: #575757;
  --bs-btn-focus-shadow-rgb: 96, 96, 96;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: dimgray;
  --bs-btn-active-border-color: #575757;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #444444;
  --bs-btn-disabled-border-color: #444444; }

.btn-grisclair {
  --bs-btn-color: #000;
  --bs-btn-bg: #effbfd;
  --bs-btn-border-color: #effbfd;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f1fcfd;
  --bs-btn-hover-border-color: #f1fbfd;
  --bs-btn-focus-shadow-rgb: 203, 213, 215;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f2fcfd;
  --bs-btn-active-border-color: #f1fbfd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #effbfd;
  --bs-btn-disabled-border-color: #effbfd; }

.btn-grisclair2 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #4A4F55;
  --bs-btn-border-color: #4A4F55;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #3f4348;
  --bs-btn-hover-border-color: #3b3f44;
  --bs-btn-focus-shadow-rgb: 101, 105, 111;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #3b3f44;
  --bs-btn-active-border-color: #383b40;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #4A4F55;
  --bs-btn-disabled-border-color: #4A4F55; }

.btn-turquoise {
  --bs-btn-color: #000;
  --bs-btn-bg: #B7E7EF;
  --bs-btn-border-color: #B7E7EF;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #c2ebf1;
  --bs-btn-hover-border-color: #bee9f1;
  --bs-btn-focus-shadow-rgb: 156, 196, 203;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c5ecf2;
  --bs-btn-active-border-color: #bee9f1;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #B7E7EF;
  --bs-btn-disabled-border-color: #B7E7EF; }

.btn-turquoise2 {
  --bs-btn-color: #000;
  --bs-btn-bg: #74C4D2;
  --bs-btn-border-color: #74C4D2;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #89cdd9;
  --bs-btn-hover-border-color: #82cad7;
  --bs-btn-focus-shadow-rgb: 99, 167, 179;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #90d0db;
  --bs-btn-active-border-color: #82cad7;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #74C4D2;
  --bs-btn-disabled-border-color: #74C4D2; }

.btn-turquoise-clair {
  --bs-btn-color: #000;
  --bs-btn-bg: #E7F5F7;
  --bs-btn-border-color: #E7F5F7;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ebf7f8;
  --bs-btn-hover-border-color: #e9f6f8;
  --bs-btn-focus-shadow-rgb: 196, 208, 210;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ecf7f9;
  --bs-btn-active-border-color: #e9f6f8;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #E7F5F7;
  --bs-btn-disabled-border-color: #E7F5F7; }

.btn-bleu2 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #141D47;
  --bs-btn-border-color: #141D47;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #11193c;
  --bs-btn-hover-border-color: #101739;
  --bs-btn-focus-shadow-rgb: 55, 63, 99;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #101739;
  --bs-btn-active-border-color: #0f1635;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #141D47;
  --bs-btn-disabled-border-color: #141D47; }

.btn-turquoise-75 {
  --bs-btn-color: #000;
  --bs-btn-bg: rgba(239, 251, 253, 0.75);
  --bs-btn-border-color: rgba(239, 251, 253, 0.75);
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: rgba(243, 252, 253, 0.7875);
  --bs-btn-hover-border-color: rgba(242, 252, 253, 0.775);
  --bs-btn-focus-shadow-rgb: 185, 194, 196;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: rgba(244, 252, 254, 0.8);
  --bs-btn-active-border-color: rgba(242, 252, 253, 0.775);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: rgba(239, 251, 253, 0.75);
  --bs-btn-disabled-border-color: rgba(239, 251, 253, 0.75); }

.btn-turquoise-80 {
  --bs-btn-color: #000;
  --bs-btn-bg: rgba(231, 245, 247, 0.8);
  --bs-btn-border-color: rgba(231, 245, 247, 0.8);
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: rgba(236, 247, 249, 0.83);
  --bs-btn-hover-border-color: rgba(234, 246, 248, 0.82);
  --bs-btn-focus-shadow-rgb: 183, 194, 195;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: rgba(238, 248, 249, 0.84);
  --bs-btn-active-border-color: rgba(234, 246, 248, 0.82);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: rgba(231, 245, 247, 0.8);
  --bs-btn-disabled-border-color: rgba(231, 245, 247, 0.8); }

.btn-outline-primary {
  --bs-btn-color: #2F3F8D;
  --bs-btn-border-color: #2F3F8D;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2F3F8D;
  --bs-btn-hover-border-color: #2F3F8D;
  --bs-btn-focus-shadow-rgb: 47, 63, 141;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #2F3F8D;
  --bs-btn-active-border-color: #2F3F8D;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #2F3F8D;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #2F3F8D;
  --bs-gradient: none; }

.btn-outline-primary01 {
  --bs-btn-color: #758DA7;
  --bs-btn-border-color: #758DA7;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #758DA7;
  --bs-btn-hover-border-color: #758DA7;
  --bs-btn-focus-shadow-rgb: 117, 141, 167;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #758DA7;
  --bs-btn-active-border-color: #758DA7;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #758DA7;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #758DA7;
  --bs-gradient: none; }

.btn-outline-primary02 {
  --bs-btn-color: #141D47;
  --bs-btn-border-color: #141D47;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #141D47;
  --bs-btn-hover-border-color: #141D47;
  --bs-btn-focus-shadow-rgb: 20, 29, 71;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #141D47;
  --bs-btn-active-border-color: #141D47;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #141D47;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #141D47;
  --bs-gradient: none; }

.btn-outline-primary03 {
  --bs-btn-color: #293780;
  --bs-btn-border-color: #293780;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #293780;
  --bs-btn-hover-border-color: #293780;
  --bs-btn-focus-shadow-rgb: 41, 55, 128;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #293780;
  --bs-btn-active-border-color: #293780;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #293780;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #293780;
  --bs-gradient: none; }

.btn-outline-primary04 {
  --bs-btn-color: #C0CBFF;
  --bs-btn-border-color: #C0CBFF;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #C0CBFF;
  --bs-btn-hover-border-color: #C0CBFF;
  --bs-btn-focus-shadow-rgb: 192, 203, 255;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #C0CBFF;
  --bs-btn-active-border-color: #C0CBFF;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #C0CBFF;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #C0CBFF;
  --bs-gradient: none; }

.btn-outline-primary05 {
  --bs-btn-color: #F5FCFD;
  --bs-btn-border-color: #F5FCFD;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #F5FCFD;
  --bs-btn-hover-border-color: #F5FCFD;
  --bs-btn-focus-shadow-rgb: 245, 252, 253;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #F5FCFD;
  --bs-btn-active-border-color: #F5FCFD;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #F5FCFD;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #F5FCFD;
  --bs-gradient: none; }

.btn-outline-secondary {
  --bs-btn-color: #32B4B4;
  --bs-btn-border-color: #32B4B4;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #32B4B4;
  --bs-btn-hover-border-color: #32B4B4;
  --bs-btn-focus-shadow-rgb: 50, 180, 180;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #32B4B4;
  --bs-btn-active-border-color: #32B4B4;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #32B4B4;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #32B4B4;
  --bs-gradient: none; }

.btn-outline-secondary01 {
  --bs-btn-color: #F5FCFD;
  --bs-btn-border-color: #F5FCFD;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #F5FCFD;
  --bs-btn-hover-border-color: #F5FCFD;
  --bs-btn-focus-shadow-rgb: 245, 252, 253;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #F5FCFD;
  --bs-btn-active-border-color: #F5FCFD;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #F5FCFD;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #F5FCFD;
  --bs-gradient: none; }

.btn-outline-secondary02 {
  --bs-btn-color: #BE581F;
  --bs-btn-border-color: #BE581F;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #BE581F;
  --bs-btn-hover-border-color: #BE581F;
  --bs-btn-focus-shadow-rgb: 190, 88, 31;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #BE581F;
  --bs-btn-active-border-color: #BE581F;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #BE581F;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #BE581F;
  --bs-gradient: none; }

.btn-outline-secondary03 {
  --bs-btn-color: #F2B38F;
  --bs-btn-border-color: #F2B38F;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #F2B38F;
  --bs-btn-hover-border-color: #F2B38F;
  --bs-btn-focus-shadow-rgb: 242, 179, 143;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #F2B38F;
  --bs-btn-active-border-color: #F2B38F;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #F2B38F;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #F2B38F;
  --bs-gradient: none; }

.btn-outline-secondary04 {
  --bs-btn-color: #95C8D5;
  --bs-btn-border-color: #95C8D5;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #95C8D5;
  --bs-btn-hover-border-color: #95C8D5;
  --bs-btn-focus-shadow-rgb: 149, 200, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #95C8D5;
  --bs-btn-active-border-color: #95C8D5;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #95C8D5;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #95C8D5;
  --bs-gradient: none; }

.btn-outline-violet {
  --bs-btn-color: #6A65A7;
  --bs-btn-border-color: #6A65A7;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6A65A7;
  --bs-btn-hover-border-color: #6A65A7;
  --bs-btn-focus-shadow-rgb: 106, 101, 167;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6A65A7;
  --bs-btn-active-border-color: #6A65A7;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6A65A7;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6A65A7;
  --bs-gradient: none; }

.btn-outline-bleu5 {
  --bs-btn-color: #354A76;
  --bs-btn-border-color: #354A76;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #354A76;
  --bs-btn-hover-border-color: #354A76;
  --bs-btn-focus-shadow-rgb: 53, 74, 118;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #354A76;
  --bs-btn-active-border-color: #354A76;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #354A76;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #354A76;
  --bs-gradient: none; }

.btn-outline-orange {
  --bs-btn-color: #fcaa3a;
  --bs-btn-border-color: #fcaa3a;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #fcaa3a;
  --bs-btn-hover-border-color: #fcaa3a;
  --bs-btn-focus-shadow-rgb: 252, 170, 58;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #fcaa3a;
  --bs-btn-active-border-color: #fcaa3a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fcaa3a;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #fcaa3a;
  --bs-gradient: none; }

.btn-outline-orange3 {
  --bs-btn-color: #FFC397;
  --bs-btn-border-color: #FFC397;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #FFC397;
  --bs-btn-hover-border-color: #FFC397;
  --bs-btn-focus-shadow-rgb: 255, 195, 151;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #FFC397;
  --bs-btn-active-border-color: #FFC397;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFC397;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #FFC397;
  --bs-gradient: none; }

.btn-outline-gray-101 {
  --bs-btn-color: #DFE7EA;
  --bs-btn-border-color: #DFE7EA;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #DFE7EA;
  --bs-btn-hover-border-color: #DFE7EA;
  --bs-btn-focus-shadow-rgb: 223, 231, 234;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #DFE7EA;
  --bs-btn-active-border-color: #DFE7EA;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #DFE7EA;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #DFE7EA;
  --bs-gradient: none; }

.btn-outline-gray-300 {
  --bs-btn-color: #EFF4F9;
  --bs-btn-border-color: #EFF4F9;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #EFF4F9;
  --bs-btn-hover-border-color: #EFF4F9;
  --bs-btn-focus-shadow-rgb: 239, 244, 249;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #EFF4F9;
  --bs-btn-active-border-color: #EFF4F9;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #EFF4F9;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #EFF4F9;
  --bs-gradient: none; }

.btn-outline-gray-400 {
  --bs-btn-color: #CFDBE8;
  --bs-btn-border-color: #CFDBE8;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #CFDBE8;
  --bs-btn-hover-border-color: #CFDBE8;
  --bs-btn-focus-shadow-rgb: 207, 219, 232;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #CFDBE8;
  --bs-btn-active-border-color: #CFDBE8;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #CFDBE8;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #CFDBE8;
  --bs-gradient: none; }

.btn-outline-success {
  --bs-btn-color: #32B4B4;
  --bs-btn-border-color: #32B4B4;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #32B4B4;
  --bs-btn-hover-border-color: #32B4B4;
  --bs-btn-focus-shadow-rgb: 50, 180, 180;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #32B4B4;
  --bs-btn-active-border-color: #32B4B4;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #32B4B4;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #32B4B4;
  --bs-gradient: none; }

.btn-outline-info {
  --bs-btn-color: #65afe2;
  --bs-btn-border-color: #65afe2;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #65afe2;
  --bs-btn-hover-border-color: #65afe2;
  --bs-btn-focus-shadow-rgb: 101, 175, 226;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #65afe2;
  --bs-btn-active-border-color: #65afe2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #65afe2;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #65afe2;
  --bs-gradient: none; }

.btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none; }

.btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none; }

.btn-outline-light {
  --bs-btn-color: #fff;
  --bs-btn-border-color: #fff;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #fff;
  --bs-btn-hover-border-color: #fff;
  --bs-btn-focus-shadow-rgb: 255, 255, 255;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #fff;
  --bs-btn-active-border-color: #fff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #fff;
  --bs-gradient: none; }

.btn-outline-dark {
  --bs-btn-color: #444444;
  --bs-btn-border-color: #444444;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #444444;
  --bs-btn-hover-border-color: #444444;
  --bs-btn-focus-shadow-rgb: 68, 68, 68;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #444444;
  --bs-btn-active-border-color: #444444;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #444444;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #444444;
  --bs-gradient: none; }

.btn-outline-grisclair {
  --bs-btn-color: #effbfd;
  --bs-btn-border-color: #effbfd;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #effbfd;
  --bs-btn-hover-border-color: #effbfd;
  --bs-btn-focus-shadow-rgb: 239, 251, 253;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #effbfd;
  --bs-btn-active-border-color: #effbfd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #effbfd;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #effbfd;
  --bs-gradient: none; }

.btn-outline-grisclair2 {
  --bs-btn-color: #4A4F55;
  --bs-btn-border-color: #4A4F55;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #4A4F55;
  --bs-btn-hover-border-color: #4A4F55;
  --bs-btn-focus-shadow-rgb: 74, 79, 85;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4A4F55;
  --bs-btn-active-border-color: #4A4F55;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #4A4F55;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #4A4F55;
  --bs-gradient: none; }

.btn-outline-turquoise {
  --bs-btn-color: #B7E7EF;
  --bs-btn-border-color: #B7E7EF;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #B7E7EF;
  --bs-btn-hover-border-color: #B7E7EF;
  --bs-btn-focus-shadow-rgb: 183, 231, 239;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #B7E7EF;
  --bs-btn-active-border-color: #B7E7EF;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #B7E7EF;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #B7E7EF;
  --bs-gradient: none; }

.btn-outline-turquoise2 {
  --bs-btn-color: #74C4D2;
  --bs-btn-border-color: #74C4D2;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #74C4D2;
  --bs-btn-hover-border-color: #74C4D2;
  --bs-btn-focus-shadow-rgb: 116, 196, 210;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #74C4D2;
  --bs-btn-active-border-color: #74C4D2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #74C4D2;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #74C4D2;
  --bs-gradient: none; }

.btn-outline-turquoise-clair {
  --bs-btn-color: #E7F5F7;
  --bs-btn-border-color: #E7F5F7;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #E7F5F7;
  --bs-btn-hover-border-color: #E7F5F7;
  --bs-btn-focus-shadow-rgb: 231, 245, 247;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #E7F5F7;
  --bs-btn-active-border-color: #E7F5F7;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #E7F5F7;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #E7F5F7;
  --bs-gradient: none; }

.btn-outline-bleu2 {
  --bs-btn-color: #141D47;
  --bs-btn-border-color: #141D47;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #141D47;
  --bs-btn-hover-border-color: #141D47;
  --bs-btn-focus-shadow-rgb: 20, 29, 71;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #141D47;
  --bs-btn-active-border-color: #141D47;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #141D47;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #141D47;
  --bs-gradient: none; }

.btn-outline-turquoise-75 {
  --bs-btn-color: rgba(239, 251, 253, 0.75);
  --bs-btn-border-color: rgba(239, 251, 253, 0.75);
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: rgba(239, 251, 253, 0.75);
  --bs-btn-hover-border-color: rgba(239, 251, 253, 0.75);
  --bs-btn-focus-shadow-rgb: 239, 251, 253;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: rgba(239, 251, 253, 0.75);
  --bs-btn-active-border-color: rgba(239, 251, 253, 0.75);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: rgba(239, 251, 253, 0.75);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: rgba(239, 251, 253, 0.75);
  --bs-gradient: none; }

.btn-outline-turquoise-80 {
  --bs-btn-color: rgba(231, 245, 247, 0.8);
  --bs-btn-border-color: rgba(231, 245, 247, 0.8);
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: rgba(231, 245, 247, 0.8);
  --bs-btn-hover-border-color: rgba(231, 245, 247, 0.8);
  --bs-btn-focus-shadow-rgb: 231, 245, 247;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: rgba(231, 245, 247, 0.8);
  --bs-btn-active-border-color: rgba(231, 245, 247, 0.8);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: rgba(231, 245, 247, 0.8);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: rgba(231, 245, 247, 0.8);
  --bs-gradient: none; }

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 78, 92, 158;
  text-decoration: underline; }
  .btn-link:focus-visible {
    color: var(--bs-btn-color); }
  .btn-link:hover {
    color: var(--bs-btn-hover-color); }

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: 5px 20px 5px 20px; }

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: 0.6rem; }

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #212529;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: 0.8rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(0.8rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #1e2125;
  --bs-dropdown-link-hover-bg: #e9ecef;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #2F3F8D;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius); }
  .dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: var(--bs-dropdown-spacer); }

.dropdown-menu-start {
  --bs-position: start; }
  .dropdown-menu-start[data-bs-popper] {
    right: auto;
    left: 0; }

.dropdown-menu-end {
  --bs-position: end; }
  .dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start; }
    .dropdown-menu-sm-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-sm-end {
    --bs-position: end; }
    .dropdown-menu-sm-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start; }
    .dropdown-menu-md-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-md-end {
    --bs-position: end; }
    .dropdown-menu-md-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start; }
    .dropdown-menu-lg-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-lg-end {
    --bs-position: end; }
    .dropdown-menu-lg-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start; }
    .dropdown-menu-xl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xl-end {
    --bs-position: end; }
    .dropdown-menu-xl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start; }
    .dropdown-menu-xxl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xxl-end {
    --bs-position: end; }
    .dropdown-menu-xxl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1920px) {
  .dropdown-menu-xxxl-start {
    --bs-position: start; }
    .dropdown-menu-xxxl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xxxl-end {
    --bs-position: end; }
    .dropdown-menu-xxxl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer); }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer); }

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropend .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-toggle::after {
  vertical-align: 0; }

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer); }

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropstart .dropdown-toggle::after {
  display: none; }

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropstart .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: var(--bs-dropdown-link-hover-color);
    background-color: var(--bs-dropdown-link-hover-bg); }
  .dropdown-item.active, .dropdown-item:active {
    color: var(--bs-dropdown-link-active-color);
    text-decoration: none;
    background-color: var(--bs-dropdown-link-active-bg); }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: var(--bs-dropdown-link-disabled-color);
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color); }

.dropdown-menu-dark {
  --bs-dropdown-color: #EFF4F9;
  --bs-dropdown-bg: #444444;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #EFF4F9;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #2F3F8D;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }
  .collapsing.collapse-horizontal {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      .collapsing.collapse-horizontal {
        transition: none; } }

.accordion {
  --bs-accordion-color: #fff;
  --bs-accordion-bg: #2F3F8D;
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 0;
  --bs-accordion-border-radius: 0;
  --bs-accordion-inner-border-radius: 0;
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: #fff;
  --bs-accordion-btn-bg: #2F3F8D;
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #979fc6;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(47, 63, 141, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #fff;
  --bs-accordion-active-bg: #2F3F8D; }

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition); }
  @media (prefers-reduced-motion: reduce) {
    .accordion-button {
      transition: none; } }
  .accordion-button:not(.collapsed) {
    color: var(--bs-accordion-active-color);
    background-color: var(--bs-accordion-active-bg);
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color); }
    .accordion-button:not(.collapsed)::after {
      background-image: var(--bs-accordion-btn-active-icon);
      transform: var(--bs-accordion-btn-icon-transform); }
  .accordion-button::after {
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    margin-left: auto;
    content: "";
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    transition: var(--bs-accordion-btn-icon-transition); }
    @media (prefers-reduced-motion: reduce) {
      .accordion-button::after {
        transition: none; } }
  .accordion-button:hover {
    z-index: 2; }
  .accordion-button:focus {
    z-index: 3;
    border-color: var(--bs-accordion-btn-focus-border-color);
    outline: 0;
    box-shadow: var(--bs-accordion-btn-focus-box-shadow); }

.accordion-header {
  margin-bottom: 0; }

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color); }
  .accordion-item:first-of-type {
    border-top-left-radius: var(--bs-accordion-border-radius);
    border-top-right-radius: var(--bs-accordion-border-radius); }
    .accordion-item:first-of-type .accordion-button {
      border-top-left-radius: var(--bs-accordion-inner-border-radius);
      border-top-right-radius: var(--bs-accordion-inner-border-radius); }
  .accordion-item:not(:first-of-type) {
    border-top: 0; }
  .accordion-item:last-of-type {
    border-bottom-right-radius: var(--bs-accordion-border-radius);
    border-bottom-left-radius: var(--bs-accordion-border-radius); }
    .accordion-item:last-of-type .accordion-button.collapsed {
      border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
      border-bottom-left-radius: var(--bs-accordion-inner-border-radius); }
    .accordion-item:last-of-type .accordion-collapse {
      border-bottom-right-radius: var(--bs-accordion-border-radius);
      border-bottom-left-radius: var(--bs-accordion-border-radius); }

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x); }

.accordion-flush .accordion-collapse {
  border-width: 0; }

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .accordion-flush .accordion-item:first-child {
    border-top: 0; }
  .accordion-flush .accordion-item:last-child {
    border-bottom: 0; }
  .accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
    border-radius: 0; }

.card {
  --bs-card-spacer-y: 21px;
  --bs-card-spacer-x: 24px;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-border-width: 0;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: 4px;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: 4px;
  --bs-card-cap-padding-y: 10.5px;
  --bs-card-cap-padding-x: 24px;
  --bs-card-cap-bg: rgba(0, 0, 0, 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 1.5rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius); }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: var(--bs-card-inner-border-radius);
      border-top-right-radius: var(--bs-card-inner-border-radius); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: var(--bs-card-inner-border-radius);
      border-bottom-left-radius: var(--bs-card-inner-border-radius); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color); }

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y); }

.card-subtitle {
  margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x); }

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color); }
  .card-header:first-child {
    border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0; }

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color); }
  .card-footer:last-child {
    border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius); }

.card-header-tabs {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0; }
  .card-header-tabs .nav-link.active {
    background-color: var(--bs-card-bg);
    border-bottom-color: var(--bs-card-bg); }

.card-header-pills {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x)); }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius); }

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius); }

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin); }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: 0.8rem;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius); }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.list-group {
  --bs-list-group-color: #212529;
  --bs-list-group-bg: #fff;
  --bs-list-group-border-color: rgba(0, 0, 0, 0.125);
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: 0.8rem;
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: #495057;
  --bs-list-group-action-hover-color: #495057;
  --bs-list-group-action-hover-bg: #EBF0F5;
  --bs-list-group-action-active-color: #212529;
  --bs-list-group-action-active-bg: #e9ecef;
  --bs-list-group-disabled-color: #6c757d;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #2F3F8D;
  --bs-list-group-active-border-color: #2F3F8D;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius); }

.list-group-numbered {
  list-style-type: none;
  counter-reset: section; }
  .list-group-numbered > .list-group-item::before {
    content: counters(section, ".") ". ";
    counter-increment: section; }

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: var(--bs-list-group-action-hover-color);
    text-decoration: none;
    background-color: var(--bs-list-group-action-hover-bg); }
  .list-group-item-action:active {
    color: var(--bs-list-group-action-active-color);
    background-color: var(--bs-list-group-action-active-bg); }

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: var(--bs-list-group-disabled-color);
    pointer-events: none;
    background-color: var(--bs-list-group-disabled-bg); }
  .list-group-item.active {
    z-index: 2;
    color: var(--bs-list-group-active-color);
    background-color: var(--bs-list-group-active-bg);
    border-color: var(--bs-list-group-active-border-color); }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: calc(-1 * var(--bs-list-group-border-width));
      border-top-width: var(--bs-list-group-border-width); }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: calc(-1 * var(--bs-list-group-border-width));
      border-left-width: var(--bs-list-group-border-width); }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row; }
    .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 1920px) {
  .list-group-horizontal-xxxl {
    flex-direction: row; }
    .list-group-horizontal-xxxl > .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-xxxl > .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xxxl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xxxl > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-xxxl > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 var(--bs-list-group-border-width); }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #1c2655;
  background-color: #d5d9e8; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #1c2655;
    background-color: #c0c3d1; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #1c2655;
    border-color: #1c2655; }

.list-group-item-primary01 {
  color: #465564;
  background-color: #e3e8ed; }
  .list-group-item-primary01.list-group-item-action:hover, .list-group-item-primary01.list-group-item-action:focus {
    color: #465564;
    background-color: #ccd1d5; }
  .list-group-item-primary01.list-group-item-action.active {
    color: #fff;
    background-color: #465564;
    border-color: #465564; }

.list-group-item-primary02 {
  color: #0c112b;
  background-color: #d0d2da; }
  .list-group-item-primary02.list-group-item-action:hover, .list-group-item-primary02.list-group-item-action:focus {
    color: #0c112b;
    background-color: #bbbdc4; }
  .list-group-item-primary02.list-group-item-action.active {
    color: #fff;
    background-color: #0c112b;
    border-color: #0c112b; }

.list-group-item-primary03 {
  color: #19214d;
  background-color: #d4d7e6; }
  .list-group-item-primary03.list-group-item-action:hover, .list-group-item-primary03.list-group-item-action:focus {
    color: #19214d;
    background-color: #bfc2cf; }
  .list-group-item-primary03.list-group-item-action.active {
    color: #fff;
    background-color: #19214d;
    border-color: #19214d; }

.list-group-item-primary04 {
  color: #4d5166;
  background-color: #f2f5ff; }
  .list-group-item-primary04.list-group-item-action:hover, .list-group-item-primary04.list-group-item-action:focus {
    color: #4d5166;
    background-color: #dadde6; }
  .list-group-item-primary04.list-group-item-action.active {
    color: #fff;
    background-color: #4d5166;
    border-color: #4d5166; }

.list-group-item-primary05 {
  color: #626565;
  background-color: #fdfeff; }
  .list-group-item-primary05.list-group-item-action:hover, .list-group-item-primary05.list-group-item-action:focus {
    color: #626565;
    background-color: #e4e5e6; }
  .list-group-item-primary05.list-group-item-action.active {
    color: #fff;
    background-color: #626565;
    border-color: #626565; }

.list-group-item-secondary {
  color: #1e6c6c;
  background-color: #d6f0f0; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #1e6c6c;
    background-color: #c1d8d8; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #1e6c6c;
    border-color: #1e6c6c; }

.list-group-item-secondary01 {
  color: #626565;
  background-color: #fdfeff; }
  .list-group-item-secondary01.list-group-item-action:hover, .list-group-item-secondary01.list-group-item-action:focus {
    color: #626565;
    background-color: #e4e5e6; }
  .list-group-item-secondary01.list-group-item-action.active {
    color: #fff;
    background-color: #626565;
    border-color: #626565; }

.list-group-item-secondary02 {
  color: #723513;
  background-color: #f2ded2; }
  .list-group-item-secondary02.list-group-item-action:hover, .list-group-item-secondary02.list-group-item-action:focus {
    color: #723513;
    background-color: #dac8bd; }
  .list-group-item-secondary02.list-group-item-action.active {
    color: #fff;
    background-color: #723513;
    border-color: #723513; }

.list-group-item-secondary03 {
  color: #614839;
  background-color: #fcf0e9; }
  .list-group-item-secondary03.list-group-item-action:hover, .list-group-item-secondary03.list-group-item-action:focus {
    color: #614839;
    background-color: #e3d8d2; }
  .list-group-item-secondary03.list-group-item-action.active {
    color: #fff;
    background-color: #614839;
    border-color: #614839; }

.list-group-item-secondary04 {
  color: #3c5055;
  background-color: #eaf4f7; }
  .list-group-item-secondary04.list-group-item-action:hover, .list-group-item-secondary04.list-group-item-action:focus {
    color: #3c5055;
    background-color: #d3dcde; }
  .list-group-item-secondary04.list-group-item-action.active {
    color: #fff;
    background-color: #3c5055;
    border-color: #3c5055; }

.list-group-item-violet {
  color: #403d64;
  background-color: #e1e0ed; }
  .list-group-item-violet.list-group-item-action:hover, .list-group-item-violet.list-group-item-action:focus {
    color: #403d64;
    background-color: #cbcad5; }
  .list-group-item-violet.list-group-item-action.active {
    color: #fff;
    background-color: #403d64;
    border-color: #403d64; }

.list-group-item-bleu5 {
  color: #202c47;
  background-color: #d7dbe4; }
  .list-group-item-bleu5.list-group-item-action:hover, .list-group-item-bleu5.list-group-item-action:focus {
    color: #202c47;
    background-color: #c2c5cd; }
  .list-group-item-bleu5.list-group-item-action.active {
    color: #fff;
    background-color: #202c47;
    border-color: #202c47; }

.list-group-item-orange {
  color: #654417;
  background-color: #feeed8; }
  .list-group-item-orange.list-group-item-action:hover, .list-group-item-orange.list-group-item-action:focus {
    color: #654417;
    background-color: #e5d6c2; }
  .list-group-item-orange.list-group-item-action.active {
    color: #fff;
    background-color: #654417;
    border-color: #654417; }

.list-group-item-orange3 {
  color: #664e3c;
  background-color: #fff3ea; }
  .list-group-item-orange3.list-group-item-action:hover, .list-group-item-orange3.list-group-item-action:focus {
    color: #664e3c;
    background-color: #e6dbd3; }
  .list-group-item-orange3.list-group-item-action.active {
    color: #fff;
    background-color: #664e3c;
    border-color: #664e3c; }

.list-group-item-gray-101 {
  color: #595c5e;
  background-color: #f9fafb; }
  .list-group-item-gray-101.list-group-item-action:hover, .list-group-item-gray-101.list-group-item-action:focus {
    color: #595c5e;
    background-color: #e0e1e2; }
  .list-group-item-gray-101.list-group-item-action.active {
    color: #fff;
    background-color: #595c5e;
    border-color: #595c5e; }

.list-group-item-gray-300 {
  color: #606264;
  background-color: #fcfdfe; }
  .list-group-item-gray-300.list-group-item-action:hover, .list-group-item-gray-300.list-group-item-action:focus {
    color: #606264;
    background-color: #e3e4e5; }
  .list-group-item-gray-300.list-group-item-action.active {
    color: #fff;
    background-color: #606264;
    border-color: #606264; }

.list-group-item-gray-400 {
  color: #53585d;
  background-color: #f5f8fa; }
  .list-group-item-gray-400.list-group-item-action:hover, .list-group-item-gray-400.list-group-item-action:focus {
    color: #53585d;
    background-color: #dddfe1; }
  .list-group-item-gray-400.list-group-item-action.active {
    color: #fff;
    background-color: #53585d;
    border-color: #53585d; }

.list-group-item-success {
  color: #1e6c6c;
  background-color: #d6f0f0; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #1e6c6c;
    background-color: #c1d8d8; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #1e6c6c;
    border-color: #1e6c6c; }

.list-group-item-info {
  color: #3d6988;
  background-color: #e0eff9; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #3d6988;
    background-color: #cad7e0; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #3d6988;
    border-color: #3d6988; }

.list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #664d03;
    background-color: #e6dbb9; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #664d03;
    border-color: #664d03; }

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #842029;
    background-color: #dfc2c4; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #842029;
    border-color: #842029; }

.list-group-item-light {
  color: #666666;
  background-color: white; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #666666;
    background-color: #e6e6e6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #666666;
    border-color: #666666; }

.list-group-item-dark {
  color: #292929;
  background-color: #dadada; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #292929;
    background-color: #c4c4c4; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #292929;
    border-color: #292929; }

.list-group-item-grisclair {
  color: #606465;
  background-color: #fcfeff; }
  .list-group-item-grisclair.list-group-item-action:hover, .list-group-item-grisclair.list-group-item-action:focus {
    color: #606465;
    background-color: #e3e5e6; }
  .list-group-item-grisclair.list-group-item-action.active {
    color: #fff;
    background-color: #606465;
    border-color: #606465; }

.list-group-item-grisclair2 {
  color: #2c2f33;
  background-color: #dbdcdd; }
  .list-group-item-grisclair2.list-group-item-action:hover, .list-group-item-grisclair2.list-group-item-action:focus {
    color: #2c2f33;
    background-color: #c5c6c7; }
  .list-group-item-grisclair2.list-group-item-action.active {
    color: #fff;
    background-color: #2c2f33;
    border-color: #2c2f33; }

.list-group-item-turquoise {
  color: #495c60;
  background-color: #f1fafc; }
  .list-group-item-turquoise.list-group-item-action:hover, .list-group-item-turquoise.list-group-item-action:focus {
    color: #495c60;
    background-color: #d9e1e3; }
  .list-group-item-turquoise.list-group-item-action.active {
    color: #fff;
    background-color: #495c60;
    border-color: #495c60; }

.list-group-item-turquoise2 {
  color: #2e4e54;
  background-color: #e3f3f6; }
  .list-group-item-turquoise2.list-group-item-action:hover, .list-group-item-turquoise2.list-group-item-action:focus {
    color: #2e4e54;
    background-color: #ccdbdd; }
  .list-group-item-turquoise2.list-group-item-action.active {
    color: #fff;
    background-color: #2e4e54;
    border-color: #2e4e54; }

.list-group-item-turquoise-clair {
  color: #5c6263;
  background-color: #fafdfd; }
  .list-group-item-turquoise-clair.list-group-item-action:hover, .list-group-item-turquoise-clair.list-group-item-action:focus {
    color: #5c6263;
    background-color: #e1e4e4; }
  .list-group-item-turquoise-clair.list-group-item-action.active {
    color: #fff;
    background-color: #5c6263;
    border-color: #5c6263; }

.list-group-item-bleu2 {
  color: #0c112b;
  background-color: #d0d2da; }
  .list-group-item-bleu2.list-group-item-action:hover, .list-group-item-bleu2.list-group-item-action:focus {
    color: #0c112b;
    background-color: #bbbdc4; }
  .list-group-item-bleu2.list-group-item-action.active {
    color: #fff;
    background-color: #0c112b;
    border-color: #0c112b; }

.list-group-item-turquoise-75 {
  color: rgba(68, 72, 72, 0.9);
  background-color: rgba(253, 254, 255, 0.95); }
  .list-group-item-turquoise-75.list-group-item-action:hover, .list-group-item-turquoise-75.list-group-item-action:focus {
    color: rgba(68, 72, 72, 0.9);
    background-color: rgba(225, 226, 227, 0.955); }
  .list-group-item-turquoise-75.list-group-item-action.active {
    color: #fff;
    background-color: rgba(68, 72, 72, 0.9);
    border-color: rgba(68, 72, 72, 0.9); }

.list-group-item-turquoise-80 {
  color: rgba(71, 75, 76, 0.92);
  background-color: rgba(252, 254, 254, 0.96); }
  .list-group-item-turquoise-80.list-group-item-action:hover, .list-group-item-turquoise-80.list-group-item-action:focus {
    color: rgba(71, 75, 76, 0.92);
    background-color: rgba(225, 227, 227, 0.964); }
  .list-group-item-turquoise-80.list-group-item-action.active {
    color: #fff;
    background-color: rgba(71, 75, 76, 0.92);
    border-color: rgba(71, 75, 76, 0.92); }

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: #6c757d;
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius); }

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x); }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: var(--bs-breadcrumb-item-padding-x);
    color: var(--bs-breadcrumb-divider-color);
    content: var(--bs-breadcrumb-divider, "") /* rtl: var(--bs-breadcrumb-divider, "") */; }

.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color); }

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #EFF4F9;
  --bs-pagination-border-radius: 0.8rem;
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: #e9ecef;
  --bs-pagination-hover-border-color: #EFF4F9;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: #e9ecef;
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(47, 63, 141, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #2F3F8D;
  --bs-pagination-active-border-color: #2F3F8D;
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #EFF4F9;
  display: flex;
  padding-left: 0;
  list-style: none; }

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .page-link {
      transition: none; } }
  .page-link:hover {
    z-index: 2;
    color: var(--bs-pagination-hover-color);
    background-color: var(--bs-pagination-hover-bg);
    border-color: var(--bs-pagination-hover-border-color); }
  .page-link:focus {
    z-index: 3;
    color: var(--bs-pagination-focus-color);
    background-color: var(--bs-pagination-focus-bg);
    outline: 0;
    box-shadow: var(--bs-pagination-focus-box-shadow); }
  .page-link.active,
  .active > .page-link {
    z-index: 3;
    color: var(--bs-pagination-active-color);
    background-color: var(--bs-pagination-active-bg);
    border-color: var(--bs-pagination-active-border-color); }
  .page-link.disabled,
  .disabled > .page-link {
    color: var(--bs-pagination-disabled-color);
    pointer-events: none;
    background-color: var(--bs-pagination-disabled-bg);
    border-color: var(--bs-pagination-disabled-border-color); }

.page-item:not(:first-child) .page-link {
  margin-left: -1px; }

.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius); }

.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius); }

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: 5px 20px 5px 20px; }

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-border-radius: 0.6rem; }

.link-primary {
  color: #2F3F8D !important; }
  .link-primary:hover, .link-primary:focus {
    color: #263271 !important; }

.link-primary01 {
  color: #758DA7 !important; }
  .link-primary01:hover, .link-primary01:focus {
    color: #91a4b9 !important; }

.link-primary02 {
  color: #141D47 !important; }
  .link-primary02:hover, .link-primary02:focus {
    color: #101739 !important; }

.link-primary03 {
  color: #293780 !important; }
  .link-primary03:hover, .link-primary03:focus {
    color: #212c66 !important; }

.link-primary04 {
  color: #C0CBFF !important; }
  .link-primary04:hover, .link-primary04:focus {
    color: #cdd5ff !important; }

.link-primary05 {
  color: #F5FCFD !important; }
  .link-primary05:hover, .link-primary05:focus {
    color: #f7fdfd !important; }

.link-secondary {
  color: #32B4B4 !important; }
  .link-secondary:hover, .link-secondary:focus {
    color: #5bc3c3 !important; }

.link-secondary01 {
  color: #F5FCFD !important; }
  .link-secondary01:hover, .link-secondary01:focus {
    color: #f7fdfd !important; }

.link-secondary02 {
  color: #BE581F !important; }
  .link-secondary02:hover, .link-secondary02:focus {
    color: #984619 !important; }

.link-secondary03 {
  color: #F2B38F !important; }
  .link-secondary03:hover, .link-secondary03:focus {
    color: #f5c2a5 !important; }

.link-secondary04 {
  color: #95C8D5 !important; }
  .link-secondary04:hover, .link-secondary04:focus {
    color: #aad3dd !important; }

.link-violet {
  color: #6A65A7 !important; }
  .link-violet:hover, .link-violet:focus {
    color: #555186 !important; }

.link-bleu5 {
  color: #354A76 !important; }
  .link-bleu5:hover, .link-bleu5:focus {
    color: #2a3b5e !important; }

.link-orange {
  color: #fcaa3a !important; }
  .link-orange:hover, .link-orange:focus {
    color: #fdbb61 !important; }

.link-orange3 {
  color: #FFC397 !important; }
  .link-orange3:hover, .link-orange3:focus {
    color: #ffcfac !important; }

.link-gray-101 {
  color: #DFE7EA !important; }
  .link-gray-101:hover, .link-gray-101:focus {
    color: #e5ecee !important; }

.link-gray-300 {
  color: #EFF4F9 !important; }
  .link-gray-300:hover, .link-gray-300:focus {
    color: #f2f6fa !important; }

.link-gray-400 {
  color: #CFDBE8 !important; }
  .link-gray-400:hover, .link-gray-400:focus {
    color: #d9e2ed !important; }

.link-success {
  color: #32B4B4 !important; }
  .link-success:hover, .link-success:focus {
    color: #5bc3c3 !important; }

.link-info {
  color: #65afe2 !important; }
  .link-info:hover, .link-info:focus {
    color: #84bfe8 !important; }

.link-warning {
  color: #ffc107 !important; }
  .link-warning:hover, .link-warning:focus {
    color: #ffcd39 !important; }

.link-danger {
  color: #dc3545 !important; }
  .link-danger:hover, .link-danger:focus {
    color: #b02a37 !important; }

.link-light {
  color: #fff !important; }
  .link-light:hover, .link-light:focus {
    color: white !important; }

.link-dark {
  color: #444444 !important; }
  .link-dark:hover, .link-dark:focus {
    color: #363636 !important; }

.link-grisclair {
  color: #effbfd !important; }
  .link-grisclair:hover, .link-grisclair:focus {
    color: #f2fcfd !important; }

.link-grisclair2 {
  color: #4A4F55 !important; }
  .link-grisclair2:hover, .link-grisclair2:focus {
    color: #3b3f44 !important; }

.link-turquoise {
  color: #B7E7EF !important; }
  .link-turquoise:hover, .link-turquoise:focus {
    color: #c5ecf2 !important; }

.link-turquoise2 {
  color: #74C4D2 !important; }
  .link-turquoise2:hover, .link-turquoise2:focus {
    color: #90d0db !important; }

.link-turquoise-clair {
  color: #E7F5F7 !important; }
  .link-turquoise-clair:hover, .link-turquoise-clair:focus {
    color: #ecf7f9 !important; }

.link-bleu2 {
  color: #141D47 !important; }
  .link-bleu2:hover, .link-bleu2:focus {
    color: #101739 !important; }

.link-turquoise-75 {
  color: rgba(239, 251, 253, 0.75) !important; }
  .link-turquoise-75:hover, .link-turquoise-75:focus {
    color: rgba(244, 252, 254, 0.8) !important; }

.link-turquoise-80 {
  color: rgba(231, 245, 247, 0.8) !important; }
  .link-turquoise-80:hover, .link-turquoise-80:focus {
    color: rgba(238, 248, 249, 0.84) !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: ""; }

.ratio {
  position: relative;
  width: 100%; }
  .ratio::before {
    display: block;
    padding-top: var(--bs-aspect-ratio);
    content: ""; }
  .ratio > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.ratio-1x1 {
  --bs-aspect-ratio: 100%; }

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%); }

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%); }

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%); }

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch; }

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(47, 63, 141, var(--bs-bg-opacity, 1)) !important; }

.text-bg-primary01 {
  color: #000 !important;
  background-color: RGBA(117, 141, 167, var(--bs-bg-opacity, 1)) !important; }

.text-bg-primary02 {
  color: #fff !important;
  background-color: RGBA(20, 29, 71, var(--bs-bg-opacity, 1)) !important; }

.text-bg-primary03 {
  color: #fff !important;
  background-color: RGBA(41, 55, 128, var(--bs-bg-opacity, 1)) !important; }

.text-bg-primary04 {
  color: #000 !important;
  background-color: RGBA(192, 203, 255, var(--bs-bg-opacity, 1)) !important; }

.text-bg-primary05 {
  color: #000 !important;
  background-color: RGBA(245, 252, 253, var(--bs-bg-opacity, 1)) !important; }

.text-bg-secondary {
  color: #000 !important;
  background-color: RGBA(50, 180, 180, var(--bs-bg-opacity, 1)) !important; }

.text-bg-secondary01 {
  color: #000 !important;
  background-color: RGBA(245, 252, 253, var(--bs-bg-opacity, 1)) !important; }

.text-bg-secondary02 {
  color: #fff !important;
  background-color: RGBA(190, 88, 31, var(--bs-bg-opacity, 1)) !important; }

.text-bg-secondary03 {
  color: #000 !important;
  background-color: RGBA(242, 179, 143, var(--bs-bg-opacity, 1)) !important; }

.text-bg-secondary04 {
  color: #000 !important;
  background-color: RGBA(149, 200, 213, var(--bs-bg-opacity, 1)) !important; }

.text-bg-violet {
  color: #fff !important;
  background-color: RGBA(106, 101, 167, var(--bs-bg-opacity, 1)) !important; }

.text-bg-bleu5 {
  color: #fff !important;
  background-color: RGBA(53, 74, 118, var(--bs-bg-opacity, 1)) !important; }

.text-bg-orange {
  color: #000 !important;
  background-color: RGBA(252, 170, 58, var(--bs-bg-opacity, 1)) !important; }

.text-bg-orange3 {
  color: #000 !important;
  background-color: RGBA(255, 195, 151, var(--bs-bg-opacity, 1)) !important; }

.text-bg-gray-101 {
  color: #000 !important;
  background-color: RGBA(223, 231, 234, var(--bs-bg-opacity, 1)) !important; }

.text-bg-gray-300 {
  color: #000 !important;
  background-color: RGBA(239, 244, 249, var(--bs-bg-opacity, 1)) !important; }

.text-bg-gray-400 {
  color: #000 !important;
  background-color: RGBA(207, 219, 232, var(--bs-bg-opacity, 1)) !important; }

.text-bg-success {
  color: #000 !important;
  background-color: RGBA(50, 180, 180, var(--bs-bg-opacity, 1)) !important; }

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(101, 175, 226, var(--bs-bg-opacity, 1)) !important; }

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(255, 193, 7, var(--bs-bg-opacity, 1)) !important; }

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(220, 53, 69, var(--bs-bg-opacity, 1)) !important; }

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(255, 255, 255, var(--bs-bg-opacity, 1)) !important; }

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(68, 68, 68, var(--bs-bg-opacity, 1)) !important; }

.text-bg-grisclair {
  color: #000 !important;
  background-color: RGBA(239, 251, 253, var(--bs-bg-opacity, 1)) !important; }

.text-bg-grisclair2 {
  color: #fff !important;
  background-color: RGBA(74, 79, 85, var(--bs-bg-opacity, 1)) !important; }

.text-bg-turquoise {
  color: #000 !important;
  background-color: RGBA(183, 231, 239, var(--bs-bg-opacity, 1)) !important; }

.text-bg-turquoise2 {
  color: #000 !important;
  background-color: RGBA(116, 196, 210, var(--bs-bg-opacity, 1)) !important; }

.text-bg-turquoise-clair {
  color: #000 !important;
  background-color: RGBA(231, 245, 247, var(--bs-bg-opacity, 1)) !important; }

.text-bg-bleu2 {
  color: #fff !important;
  background-color: RGBA(20, 29, 71, var(--bs-bg-opacity, 1)) !important; }

.text-bg-turquoise-75 {
  color: #000 !important;
  background-color: RGBA(239, 251, 253, var(--bs-bg-opacity, 1)) !important; }

.text-bg-turquoise-80 {
  color: #000 !important;
  background-color: RGBA(231, 245, 247, var(--bs-bg-opacity, 1)) !important; }

.link-primary {
  color: #2F3F8D !important; }
  .link-primary:hover, .link-primary:focus {
    color: #263271 !important; }

.link-primary01 {
  color: #758DA7 !important; }
  .link-primary01:hover, .link-primary01:focus {
    color: #91a4b9 !important; }

.link-primary02 {
  color: #141D47 !important; }
  .link-primary02:hover, .link-primary02:focus {
    color: #101739 !important; }

.link-primary03 {
  color: #293780 !important; }
  .link-primary03:hover, .link-primary03:focus {
    color: #212c66 !important; }

.link-primary04 {
  color: #C0CBFF !important; }
  .link-primary04:hover, .link-primary04:focus {
    color: #cdd5ff !important; }

.link-primary05 {
  color: #F5FCFD !important; }
  .link-primary05:hover, .link-primary05:focus {
    color: #f7fdfd !important; }

.link-secondary {
  color: #32B4B4 !important; }
  .link-secondary:hover, .link-secondary:focus {
    color: #5bc3c3 !important; }

.link-secondary01 {
  color: #F5FCFD !important; }
  .link-secondary01:hover, .link-secondary01:focus {
    color: #f7fdfd !important; }

.link-secondary02 {
  color: #BE581F !important; }
  .link-secondary02:hover, .link-secondary02:focus {
    color: #984619 !important; }

.link-secondary03 {
  color: #F2B38F !important; }
  .link-secondary03:hover, .link-secondary03:focus {
    color: #f5c2a5 !important; }

.link-secondary04 {
  color: #95C8D5 !important; }
  .link-secondary04:hover, .link-secondary04:focus {
    color: #aad3dd !important; }

.link-violet {
  color: #6A65A7 !important; }
  .link-violet:hover, .link-violet:focus {
    color: #555186 !important; }

.link-bleu5 {
  color: #354A76 !important; }
  .link-bleu5:hover, .link-bleu5:focus {
    color: #2a3b5e !important; }

.link-orange {
  color: #fcaa3a !important; }
  .link-orange:hover, .link-orange:focus {
    color: #fdbb61 !important; }

.link-orange3 {
  color: #FFC397 !important; }
  .link-orange3:hover, .link-orange3:focus {
    color: #ffcfac !important; }

.link-gray-101 {
  color: #DFE7EA !important; }
  .link-gray-101:hover, .link-gray-101:focus {
    color: #e5ecee !important; }

.link-gray-300 {
  color: #EFF4F9 !important; }
  .link-gray-300:hover, .link-gray-300:focus {
    color: #f2f6fa !important; }

.link-gray-400 {
  color: #CFDBE8 !important; }
  .link-gray-400:hover, .link-gray-400:focus {
    color: #d9e2ed !important; }

.link-success {
  color: #32B4B4 !important; }
  .link-success:hover, .link-success:focus {
    color: #5bc3c3 !important; }

.link-info {
  color: #65afe2 !important; }
  .link-info:hover, .link-info:focus {
    color: #84bfe8 !important; }

.link-warning {
  color: #ffc107 !important; }
  .link-warning:hover, .link-warning:focus {
    color: #ffcd39 !important; }

.link-danger {
  color: #dc3545 !important; }
  .link-danger:hover, .link-danger:focus {
    color: #b02a37 !important; }

.link-light {
  color: #fff !important; }
  .link-light:hover, .link-light:focus {
    color: white !important; }

.link-dark {
  color: #444444 !important; }
  .link-dark:hover, .link-dark:focus {
    color: #363636 !important; }

.link-grisclair {
  color: #effbfd !important; }
  .link-grisclair:hover, .link-grisclair:focus {
    color: #f2fcfd !important; }

.link-grisclair2 {
  color: #4A4F55 !important; }
  .link-grisclair2:hover, .link-grisclair2:focus {
    color: #3b3f44 !important; }

.link-turquoise {
  color: #B7E7EF !important; }
  .link-turquoise:hover, .link-turquoise:focus {
    color: #c5ecf2 !important; }

.link-turquoise2 {
  color: #74C4D2 !important; }
  .link-turquoise2:hover, .link-turquoise2:focus {
    color: #90d0db !important; }

.link-turquoise-clair {
  color: #E7F5F7 !important; }
  .link-turquoise-clair:hover, .link-turquoise-clair:focus {
    color: #ecf7f9 !important; }

.link-bleu2 {
  color: #141D47 !important; }
  .link-bleu2:hover, .link-bleu2:focus {
    color: #101739 !important; }

.link-turquoise-75 {
  color: rgba(239, 251, 253, 0.75) !important; }
  .link-turquoise-75:hover, .link-turquoise-75:focus {
    color: rgba(244, 252, 254, 0.8) !important; }

.link-turquoise-80 {
  color: rgba(231, 245, 247, 0.8) !important; }
  .link-turquoise-80:hover, .link-turquoise-80:focus {
    color: rgba(238, 248, 249, 0.84) !important; }

.ratio {
  position: relative;
  width: 100%; }
  .ratio::before {
    display: block;
    padding-top: var(--bs-aspect-ratio);
    content: ""; }
  .ratio > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.ratio-1x1 {
  --bs-aspect-ratio: 100%; }

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%); }

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%); }

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%); }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020; }

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020; }

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 1920px) {
  .sticky-xxxl-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-xxxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch; }

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch; }

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: ""; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.float-start {
  float: left !important; }

.float-end {
  float: right !important; }

.float-none {
  float: none !important; }

.opacity-0 {
  opacity: 0 !important; }

.opacity-25 {
  opacity: 0.25 !important; }

.opacity-50 {
  opacity: 0.5 !important; }

.opacity-75 {
  opacity: 0.75 !important; }

.opacity-100 {
  opacity: 1 !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.overflow-visible {
  overflow: visible !important; }

.overflow-scroll {
  overflow: scroll !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-grid {
  display: grid !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

.d-none {
  display: none !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.top-0 {
  top: 0 !important; }

.top-5 {
  top: 5% !important; }

.top-25 {
  top: 25% !important; }

.top-50 {
  top: 50% !important; }

.top-90 {
  top: 90% !important; }

.top-100 {
  top: 100% !important; }

.top-10px {
  top: 10px !important; }

.top-14px {
  top: 14px !important; }

.top-24px {
  top: 24px !important; }

.top-50px {
  top: 50px !important; }

.top-70px {
  top: 70px !important; }

.top-100px {
  top: 100px !important; }

.top-120px {
  top: 120px !important; }

.top-150px {
  top: 150px !important; }

.top-170px {
  top: 170px !important; }

.top-200px {
  top: 200px !important; }

.top-230px {
  top: 230px !important; }

.top-240px {
  top: 240px !important; }

.top-250px {
  top: 250px !important; }

.top-n8px {
  top: -8px !important; }

.top-n10px {
  top: -10px !important; }

.top-n19px {
  top: -19px !important; }

.top-n25px {
  top: -25px !important; }

.top-n50px {
  top: -50px !important; }

.top-n90px {
  top: -90px !important; }

.top-n200px {
  top: -200px !important; }

.top-n240px {
  top: -240px !important; }

.top-n340px {
  top: -340px !important; }

.top-n350px {
  top: -350px !important; }

.top-100n20 {
  top: calc(100% + 20px) !important; }

.top-100n50 {
  top: calc(100% - 50px) !important; }

.top-100n100 {
  top: calc(100% + 100px) !important; }

.top-100n200 {
  top: calc(100% + 200px) !important; }

.top-100n250 {
  top: calc(100% + 250px) !important; }

.top-100n350 {
  top: calc(100% + 350px) !important; }

.bottom-0 {
  bottom: 0 !important; }

.bottom-5 {
  bottom: 5% !important; }

.bottom-25 {
  bottom: 25% !important; }

.bottom-50 {
  bottom: 50% !important; }

.bottom-90 {
  bottom: 90% !important; }

.bottom-100 {
  bottom: 100% !important; }

.bottom-10px {
  bottom: 10px !important; }

.bottom-14px {
  bottom: 14px !important; }

.bottom-24px {
  bottom: 24px !important; }

.bottom-50px {
  bottom: 50px !important; }

.bottom-70px {
  bottom: 70px !important; }

.bottom-100px {
  bottom: 100px !important; }

.bottom-120px {
  bottom: 120px !important; }

.bottom-150px {
  bottom: 150px !important; }

.bottom-170px {
  bottom: 170px !important; }

.bottom-200px {
  bottom: 200px !important; }

.bottom-230px {
  bottom: 230px !important; }

.bottom-240px {
  bottom: 240px !important; }

.bottom-250px {
  bottom: 250px !important; }

.bottom-n8px {
  bottom: -8px !important; }

.bottom-n10px {
  bottom: -10px !important; }

.bottom-n19px {
  bottom: -19px !important; }

.bottom-n25px {
  bottom: -25px !important; }

.bottom-n50px {
  bottom: -50px !important; }

.bottom-n90px {
  bottom: -90px !important; }

.bottom-n200px {
  bottom: -200px !important; }

.bottom-n240px {
  bottom: -240px !important; }

.bottom-n340px {
  bottom: -340px !important; }

.bottom-n350px {
  bottom: -350px !important; }

.bottom-100n20 {
  bottom: calc(100% + 20px) !important; }

.bottom-100n50 {
  bottom: calc(100% - 50px) !important; }

.bottom-100n100 {
  bottom: calc(100% + 100px) !important; }

.bottom-100n200 {
  bottom: calc(100% + 200px) !important; }

.bottom-100n250 {
  bottom: calc(100% + 250px) !important; }

.bottom-100n350 {
  bottom: calc(100% + 350px) !important; }

.start-0 {
  left: 0 !important; }

.start-5 {
  left: 5% !important; }

.start-25 {
  left: 25% !important; }

.start-50 {
  left: 50% !important; }

.start-90 {
  left: 90% !important; }

.start-100 {
  left: 100% !important; }

.start-10px {
  left: 10px !important; }

.start-14px {
  left: 14px !important; }

.start-24px {
  left: 24px !important; }

.start-50px {
  left: 50px !important; }

.start-70px {
  left: 70px !important; }

.start-100px {
  left: 100px !important; }

.start-120px {
  left: 120px !important; }

.start-150px {
  left: 150px !important; }

.start-170px {
  left: 170px !important; }

.start-200px {
  left: 200px !important; }

.start-230px {
  left: 230px !important; }

.start-240px {
  left: 240px !important; }

.start-250px {
  left: 250px !important; }

.start-n8px {
  left: -8px !important; }

.start-n10px {
  left: -10px !important; }

.start-n19px {
  left: -19px !important; }

.start-n25px {
  left: -25px !important; }

.start-n50px {
  left: -50px !important; }

.start-n90px {
  left: -90px !important; }

.start-n200px {
  left: -200px !important; }

.start-n240px {
  left: -240px !important; }

.start-n340px {
  left: -340px !important; }

.start-n350px {
  left: -350px !important; }

.start-100n20 {
  left: calc(100% + 20px) !important; }

.start-100n50 {
  left: calc(100% - 50px) !important; }

.start-100n100 {
  left: calc(100% + 100px) !important; }

.start-100n200 {
  left: calc(100% + 200px) !important; }

.start-100n250 {
  left: calc(100% + 250px) !important; }

.start-100n350 {
  left: calc(100% + 350px) !important; }

.end-0 {
  right: 0 !important; }

.end-5 {
  right: 5% !important; }

.end-25 {
  right: 25% !important; }

.end-50 {
  right: 50% !important; }

.end-90 {
  right: 90% !important; }

.end-100 {
  right: 100% !important; }

.end-10px {
  right: 10px !important; }

.end-14px {
  right: 14px !important; }

.end-24px {
  right: 24px !important; }

.end-50px {
  right: 50px !important; }

.end-70px {
  right: 70px !important; }

.end-100px {
  right: 100px !important; }

.end-120px {
  right: 120px !important; }

.end-150px {
  right: 150px !important; }

.end-170px {
  right: 170px !important; }

.end-200px {
  right: 200px !important; }

.end-230px {
  right: 230px !important; }

.end-240px {
  right: 240px !important; }

.end-250px {
  right: 250px !important; }

.end-n8px {
  right: -8px !important; }

.end-n10px {
  right: -10px !important; }

.end-n19px {
  right: -19px !important; }

.end-n25px {
  right: -25px !important; }

.end-n50px {
  right: -50px !important; }

.end-n90px {
  right: -90px !important; }

.end-n200px {
  right: -200px !important; }

.end-n240px {
  right: -240px !important; }

.end-n340px {
  right: -340px !important; }

.end-n350px {
  right: -350px !important; }

.end-100n20 {
  right: calc(100% + 20px) !important; }

.end-100n50 {
  right: calc(100% - 50px) !important; }

.end-100n100 {
  right: calc(100% + 100px) !important; }

.end-100n200 {
  right: calc(100% + 200px) !important; }

.end-100n250 {
  right: calc(100% + 250px) !important; }

.end-100n350 {
  right: calc(100% + 350px) !important; }

.translate-middle {
  transform: translate(-50%, -50%) !important; }

.translate-middle-x {
  transform: translateX(-50%) !important; }

.translate-middle-y {
  transform: translateY(-50%) !important; }

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-0 {
  border: 0 !important; }

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-end-0 {
  border-right: 0 !important; }

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-start-0 {
  border-left: 0 !important; }

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important; }

.border-primary01 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary01-rgb), var(--bs-border-opacity)) !important; }

.border-primary02 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary02-rgb), var(--bs-border-opacity)) !important; }

.border-primary03 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary03-rgb), var(--bs-border-opacity)) !important; }

.border-primary04 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary04-rgb), var(--bs-border-opacity)) !important; }

.border-primary05 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary05-rgb), var(--bs-border-opacity)) !important; }

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important; }

.border-secondary01 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary01-rgb), var(--bs-border-opacity)) !important; }

.border-secondary02 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary02-rgb), var(--bs-border-opacity)) !important; }

.border-secondary03 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary03-rgb), var(--bs-border-opacity)) !important; }

.border-secondary04 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary04-rgb), var(--bs-border-opacity)) !important; }

.border-violet {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-violet-rgb), var(--bs-border-opacity)) !important; }

.border-bleu5 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-bleu5-rgb), var(--bs-border-opacity)) !important; }

.border-orange {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-orange-rgb), var(--bs-border-opacity)) !important; }

.border-orange3 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-orange3-rgb), var(--bs-border-opacity)) !important; }

.border-gray-101 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-gray-101-rgb), var(--bs-border-opacity)) !important; }

.border-gray-300 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-gray-300-rgb), var(--bs-border-opacity)) !important; }

.border-gray-400 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-gray-400-rgb), var(--bs-border-opacity)) !important; }

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important; }

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important; }

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important; }

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important; }

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important; }

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important; }

.border-grisclair {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-grisclair-rgb), var(--bs-border-opacity)) !important; }

.border-grisclair2 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-grisclair2-rgb), var(--bs-border-opacity)) !important; }

.border-turquoise {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-turquoise-rgb), var(--bs-border-opacity)) !important; }

.border-turquoise2 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-turquoise2-rgb), var(--bs-border-opacity)) !important; }

.border-turquoise-clair {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-turquoise-clair-rgb), var(--bs-border-opacity)) !important; }

.border-bleu2 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-bleu2-rgb), var(--bs-border-opacity)) !important; }

.border-turquoise-75 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-turquoise-75-rgb), var(--bs-border-opacity)) !important; }

.border-turquoise-80 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-turquoise-80-rgb), var(--bs-border-opacity)) !important; }

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important; }

.border-1 {
  --bs-border-width: 1px; }

.border-2 {
  --bs-border-width: 2px; }

.border-3 {
  --bs-border-width: 3px; }

.border-4 {
  --bs-border-width: 4px; }

.border-5 {
  --bs-border-width: 5px; }

.border-opacity-10 {
  --bs-border-opacity: 0.1; }

.border-opacity-25 {
  --bs-border-opacity: 0.25; }

.border-opacity-50 {
  --bs-border-opacity: 0.5; }

.border-opacity-75 {
  --bs-border-opacity: 0.75; }

.border-opacity-100 {
  --bs-border-opacity: 1; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.w-135px {
  width: 135px !important; }

.w-150px {
  width: 150px !important; }

.w-180px {
  width: 180px !important; }

.w-190px {
  width: 190px !important; }

.w-200px {
  width: 200px !important; }

.w-240px {
  width: 240px !important; }

.w-260px {
  width: 260px !important; }

.w-296px {
  width: 296px !important; }

.w-340px {
  width: 340px !important; }

.w-368px {
  width: 368px !important; }

.w-400px {
  width: 400px !important; }

.w-500px {
  width: 500px !important; }

.w-520px {
  width: 520px !important; }

.w-800px {
  width: 800px !important; }

.mw-100 {
  max-width: 100% !important; }

.vw-100 {
  width: 100vw !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.h-135px {
  height: 135px !important; }

.h-150px {
  height: 150px !important; }

.h-180px {
  height: 180px !important; }

.h-190px {
  height: 190px !important; }

.h-200px {
  height: 200px !important; }

.h-240px {
  height: 240px !important; }

.h-260px {
  height: 260px !important; }

.h-296px {
  height: 296px !important; }

.h-340px {
  height: 340px !important; }

.h-368px {
  height: 368px !important; }

.h-400px {
  height: 400px !important; }

.h-500px {
  height: 500px !important; }

.h-520px {
  height: 520px !important; }

.h-800px {
  height: 800px !important; }

.mh-100 {
  max-height: 100% !important; }

.vh-100 {
  height: 100vh !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.justify-content-evenly {
  justify-content: space-evenly !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

.order-first {
  order: -1 !important; }

.order-0 {
  order: 0 !important; }

.order-1 {
  order: 1 !important; }

.order-2 {
  order: 2 !important; }

.order-3 {
  order: 3 !important; }

.order-4 {
  order: 4 !important; }

.order-5 {
  order: 5 !important; }

.order-last {
  order: 6 !important; }

.m-0 {
  margin: 0 !important; }

.m-1 {
  margin: 0.1rem !important; }

.m-2 {
  margin: 0.2rem !important; }

.m-3 {
  margin: 0.3rem !important; }

.m-4 {
  margin: 0.4rem !important; }

.m-5 {
  margin: 0.5rem !important; }

.m-6 {
  margin: 0.6rem !important; }

.m-8 {
  margin: 0.8rem !important; }

.m-10 {
  margin: 1rem !important; }

.m-12 {
  margin: 1.2rem !important; }

.m-13 {
  margin: 1.3rem !important; }

.m-15 {
  margin: 1.5rem !important; }

.m-16 {
  margin: 1.6rem !important; }

.m-20 {
  margin: 2rem !important; }

.m-24 {
  margin: 2.4rem !important; }

.m-25 {
  margin: 2.5rem !important; }

.m-29 {
  margin: 2.9rem !important; }

.m-30 {
  margin: 3rem !important; }

.m-32 {
  margin: 3.2rem !important; }

.m-35 {
  margin: 3.5rem !important; }

.m-37 {
  margin: 3.7rem !important; }

.m-40 {
  margin: 4rem !important; }

.m-42 {
  margin: 4.2rem !important; }

.m-44 {
  margin: 4.4rem !important; }

.m-48 {
  margin: 4.8rem !important; }

.m-50 {
  margin: 5rem !important; }

.m-52 {
  margin: 5.2rem !important; }

.m-55 {
  margin: 5.5rem !important; }

.m-60 {
  margin: 6rem !important; }

.m-62 {
  margin: 6.2rem !important; }

.m-64 {
  margin: 6.4rem !important; }

.m-65 {
  margin: 6.5rem !important; }

.m-70 {
  margin: 7rem !important; }

.m-72 {
  margin: 7.2rem !important; }

.m-80 {
  margin: 8rem !important; }

.m-85 {
  margin: 8.5rem !important; }

.m-95 {
  margin: 9.5rem !important; }

.m-96 {
  margin: 9.6rem !important; }

.m-100 {
  margin: 10rem !important; }

.m-104 {
  margin: 10.4rem !important; }

.m-108 {
  margin: 10.8rem !important; }

.m-110 {
  margin: 11rem !important; }

.m-116 {
  margin: 11.6rem !important; }

.m-120 {
  margin: 12rem !important; }

.m-128 {
  margin: 12.8rem !important; }

.m-140 {
  margin: 14rem !important; }

.m-148 {
  margin: 14.8rem !important; }

.m-160 {
  margin: 16rem !important; }

.m-170 {
  margin: 17rem !important; }

.m-200 {
  margin: 20rem !important; }

.m-240 {
  margin: 24rem !important; }

.m-245 {
  margin: 24.5rem !important; }

.m-250 {
  margin: 25rem !important; }

.m-300 {
  margin: 30rem !important; }

.m-400 {
  margin: 40rem !important; }

.m-n25 {
  margin: -25% !important; }

.m-auto {
  margin: auto !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.mx-1 {
  margin-right: 0.1rem !important;
  margin-left: 0.1rem !important; }

.mx-2 {
  margin-right: 0.2rem !important;
  margin-left: 0.2rem !important; }

.mx-3 {
  margin-right: 0.3rem !important;
  margin-left: 0.3rem !important; }

.mx-4 {
  margin-right: 0.4rem !important;
  margin-left: 0.4rem !important; }

.mx-5 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important; }

.mx-6 {
  margin-right: 0.6rem !important;
  margin-left: 0.6rem !important; }

.mx-8 {
  margin-right: 0.8rem !important;
  margin-left: 0.8rem !important; }

.mx-10 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.mx-12 {
  margin-right: 1.2rem !important;
  margin-left: 1.2rem !important; }

.mx-13 {
  margin-right: 1.3rem !important;
  margin-left: 1.3rem !important; }

.mx-15 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important; }

.mx-16 {
  margin-right: 1.6rem !important;
  margin-left: 1.6rem !important; }

.mx-20 {
  margin-right: 2rem !important;
  margin-left: 2rem !important; }

.mx-24 {
  margin-right: 2.4rem !important;
  margin-left: 2.4rem !important; }

.mx-25 {
  margin-right: 2.5rem !important;
  margin-left: 2.5rem !important; }

.mx-29 {
  margin-right: 2.9rem !important;
  margin-left: 2.9rem !important; }

.mx-30 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.mx-32 {
  margin-right: 3.2rem !important;
  margin-left: 3.2rem !important; }

.mx-35 {
  margin-right: 3.5rem !important;
  margin-left: 3.5rem !important; }

.mx-37 {
  margin-right: 3.7rem !important;
  margin-left: 3.7rem !important; }

.mx-40 {
  margin-right: 4rem !important;
  margin-left: 4rem !important; }

.mx-42 {
  margin-right: 4.2rem !important;
  margin-left: 4.2rem !important; }

.mx-44 {
  margin-right: 4.4rem !important;
  margin-left: 4.4rem !important; }

.mx-48 {
  margin-right: 4.8rem !important;
  margin-left: 4.8rem !important; }

.mx-50 {
  margin-right: 5rem !important;
  margin-left: 5rem !important; }

.mx-52 {
  margin-right: 5.2rem !important;
  margin-left: 5.2rem !important; }

.mx-55 {
  margin-right: 5.5rem !important;
  margin-left: 5.5rem !important; }

.mx-60 {
  margin-right: 6rem !important;
  margin-left: 6rem !important; }

.mx-62 {
  margin-right: 6.2rem !important;
  margin-left: 6.2rem !important; }

.mx-64 {
  margin-right: 6.4rem !important;
  margin-left: 6.4rem !important; }

.mx-65 {
  margin-right: 6.5rem !important;
  margin-left: 6.5rem !important; }

.mx-70 {
  margin-right: 7rem !important;
  margin-left: 7rem !important; }

.mx-72 {
  margin-right: 7.2rem !important;
  margin-left: 7.2rem !important; }

.mx-80 {
  margin-right: 8rem !important;
  margin-left: 8rem !important; }

.mx-85 {
  margin-right: 8.5rem !important;
  margin-left: 8.5rem !important; }

.mx-95 {
  margin-right: 9.5rem !important;
  margin-left: 9.5rem !important; }

.mx-96 {
  margin-right: 9.6rem !important;
  margin-left: 9.6rem !important; }

.mx-100 {
  margin-right: 10rem !important;
  margin-left: 10rem !important; }

.mx-104 {
  margin-right: 10.4rem !important;
  margin-left: 10.4rem !important; }

.mx-108 {
  margin-right: 10.8rem !important;
  margin-left: 10.8rem !important; }

.mx-110 {
  margin-right: 11rem !important;
  margin-left: 11rem !important; }

.mx-116 {
  margin-right: 11.6rem !important;
  margin-left: 11.6rem !important; }

.mx-120 {
  margin-right: 12rem !important;
  margin-left: 12rem !important; }

.mx-128 {
  margin-right: 12.8rem !important;
  margin-left: 12.8rem !important; }

.mx-140 {
  margin-right: 14rem !important;
  margin-left: 14rem !important; }

.mx-148 {
  margin-right: 14.8rem !important;
  margin-left: 14.8rem !important; }

.mx-160 {
  margin-right: 16rem !important;
  margin-left: 16rem !important; }

.mx-170 {
  margin-right: 17rem !important;
  margin-left: 17rem !important; }

.mx-200 {
  margin-right: 20rem !important;
  margin-left: 20rem !important; }

.mx-240 {
  margin-right: 24rem !important;
  margin-left: 24rem !important; }

.mx-245 {
  margin-right: 24.5rem !important;
  margin-left: 24.5rem !important; }

.mx-250 {
  margin-right: 25rem !important;
  margin-left: 25rem !important; }

.mx-300 {
  margin-right: 30rem !important;
  margin-left: 30rem !important; }

.mx-400 {
  margin-right: 40rem !important;
  margin-left: 40rem !important; }

.mx-n25 {
  margin-right: -25% !important;
  margin-left: -25% !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-1 {
  margin-top: 0.1rem !important;
  margin-bottom: 0.1rem !important; }

.my-2 {
  margin-top: 0.2rem !important;
  margin-bottom: 0.2rem !important; }

.my-3 {
  margin-top: 0.3rem !important;
  margin-bottom: 0.3rem !important; }

.my-4 {
  margin-top: 0.4rem !important;
  margin-bottom: 0.4rem !important; }

.my-5 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.my-6 {
  margin-top: 0.6rem !important;
  margin-bottom: 0.6rem !important; }

.my-8 {
  margin-top: 0.8rem !important;
  margin-bottom: 0.8rem !important; }

.my-10 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.my-12 {
  margin-top: 1.2rem !important;
  margin-bottom: 1.2rem !important; }

.my-13 {
  margin-top: 1.3rem !important;
  margin-bottom: 1.3rem !important; }

.my-15 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.my-16 {
  margin-top: 1.6rem !important;
  margin-bottom: 1.6rem !important; }

.my-20 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important; }

.my-24 {
  margin-top: 2.4rem !important;
  margin-bottom: 2.4rem !important; }

.my-25 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important; }

.my-29 {
  margin-top: 2.9rem !important;
  margin-bottom: 2.9rem !important; }

.my-30 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.my-32 {
  margin-top: 3.2rem !important;
  margin-bottom: 3.2rem !important; }

.my-35 {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important; }

.my-37 {
  margin-top: 3.7rem !important;
  margin-bottom: 3.7rem !important; }

.my-40 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important; }

.my-42 {
  margin-top: 4.2rem !important;
  margin-bottom: 4.2rem !important; }

.my-44 {
  margin-top: 4.4rem !important;
  margin-bottom: 4.4rem !important; }

.my-48 {
  margin-top: 4.8rem !important;
  margin-bottom: 4.8rem !important; }

.my-50 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important; }

.my-52 {
  margin-top: 5.2rem !important;
  margin-bottom: 5.2rem !important; }

.my-55 {
  margin-top: 5.5rem !important;
  margin-bottom: 5.5rem !important; }

.my-60 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important; }

.my-62 {
  margin-top: 6.2rem !important;
  margin-bottom: 6.2rem !important; }

.my-64 {
  margin-top: 6.4rem !important;
  margin-bottom: 6.4rem !important; }

.my-65 {
  margin-top: 6.5rem !important;
  margin-bottom: 6.5rem !important; }

.my-70 {
  margin-top: 7rem !important;
  margin-bottom: 7rem !important; }

.my-72 {
  margin-top: 7.2rem !important;
  margin-bottom: 7.2rem !important; }

.my-80 {
  margin-top: 8rem !important;
  margin-bottom: 8rem !important; }

.my-85 {
  margin-top: 8.5rem !important;
  margin-bottom: 8.5rem !important; }

.my-95 {
  margin-top: 9.5rem !important;
  margin-bottom: 9.5rem !important; }

.my-96 {
  margin-top: 9.6rem !important;
  margin-bottom: 9.6rem !important; }

.my-100 {
  margin-top: 10rem !important;
  margin-bottom: 10rem !important; }

.my-104 {
  margin-top: 10.4rem !important;
  margin-bottom: 10.4rem !important; }

.my-108 {
  margin-top: 10.8rem !important;
  margin-bottom: 10.8rem !important; }

.my-110 {
  margin-top: 11rem !important;
  margin-bottom: 11rem !important; }

.my-116 {
  margin-top: 11.6rem !important;
  margin-bottom: 11.6rem !important; }

.my-120 {
  margin-top: 12rem !important;
  margin-bottom: 12rem !important; }

.my-128 {
  margin-top: 12.8rem !important;
  margin-bottom: 12.8rem !important; }

.my-140 {
  margin-top: 14rem !important;
  margin-bottom: 14rem !important; }

.my-148 {
  margin-top: 14.8rem !important;
  margin-bottom: 14.8rem !important; }

.my-160 {
  margin-top: 16rem !important;
  margin-bottom: 16rem !important; }

.my-170 {
  margin-top: 17rem !important;
  margin-bottom: 17rem !important; }

.my-200 {
  margin-top: 20rem !important;
  margin-bottom: 20rem !important; }

.my-240 {
  margin-top: 24rem !important;
  margin-bottom: 24rem !important; }

.my-245 {
  margin-top: 24.5rem !important;
  margin-bottom: 24.5rem !important; }

.my-250 {
  margin-top: 25rem !important;
  margin-bottom: 25rem !important; }

.my-300 {
  margin-top: 30rem !important;
  margin-bottom: 30rem !important; }

.my-400 {
  margin-top: 40rem !important;
  margin-bottom: 40rem !important; }

.my-n25 {
  margin-top: -25% !important;
  margin-bottom: -25% !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-1 {
  margin-top: 0.1rem !important; }

.mt-2 {
  margin-top: 0.2rem !important; }

.mt-3 {
  margin-top: 0.3rem !important; }

.mt-4 {
  margin-top: 0.4rem !important; }

.mt-5 {
  margin-top: 0.5rem !important; }

.mt-6 {
  margin-top: 0.6rem !important; }

.mt-8 {
  margin-top: 0.8rem !important; }

.mt-10 {
  margin-top: 1rem !important; }

.mt-12 {
  margin-top: 1.2rem !important; }

.mt-13 {
  margin-top: 1.3rem !important; }

.mt-15 {
  margin-top: 1.5rem !important; }

.mt-16 {
  margin-top: 1.6rem !important; }

.mt-20 {
  margin-top: 2rem !important; }

.mt-24 {
  margin-top: 2.4rem !important; }

.mt-25 {
  margin-top: 2.5rem !important; }

.mt-29 {
  margin-top: 2.9rem !important; }

.mt-30 {
  margin-top: 3rem !important; }

.mt-32 {
  margin-top: 3.2rem !important; }

.mt-35 {
  margin-top: 3.5rem !important; }

.mt-37 {
  margin-top: 3.7rem !important; }

.mt-40 {
  margin-top: 4rem !important; }

.mt-42 {
  margin-top: 4.2rem !important; }

.mt-44 {
  margin-top: 4.4rem !important; }

.mt-48 {
  margin-top: 4.8rem !important; }

.mt-50 {
  margin-top: 5rem !important; }

.mt-52 {
  margin-top: 5.2rem !important; }

.mt-55 {
  margin-top: 5.5rem !important; }

.mt-60 {
  margin-top: 6rem !important; }

.mt-62 {
  margin-top: 6.2rem !important; }

.mt-64 {
  margin-top: 6.4rem !important; }

.mt-65 {
  margin-top: 6.5rem !important; }

.mt-70 {
  margin-top: 7rem !important; }

.mt-72 {
  margin-top: 7.2rem !important; }

.mt-80 {
  margin-top: 8rem !important; }

.mt-85 {
  margin-top: 8.5rem !important; }

.mt-95 {
  margin-top: 9.5rem !important; }

.mt-96 {
  margin-top: 9.6rem !important; }

.mt-100 {
  margin-top: 10rem !important; }

.mt-104 {
  margin-top: 10.4rem !important; }

.mt-108 {
  margin-top: 10.8rem !important; }

.mt-110 {
  margin-top: 11rem !important; }

.mt-116 {
  margin-top: 11.6rem !important; }

.mt-120 {
  margin-top: 12rem !important; }

.mt-128 {
  margin-top: 12.8rem !important; }

.mt-140 {
  margin-top: 14rem !important; }

.mt-148 {
  margin-top: 14.8rem !important; }

.mt-160 {
  margin-top: 16rem !important; }

.mt-170 {
  margin-top: 17rem !important; }

.mt-200 {
  margin-top: 20rem !important; }

.mt-240 {
  margin-top: 24rem !important; }

.mt-245 {
  margin-top: 24.5rem !important; }

.mt-250 {
  margin-top: 25rem !important; }

.mt-300 {
  margin-top: 30rem !important; }

.mt-400 {
  margin-top: 40rem !important; }

.mt-n25 {
  margin-top: -25% !important; }

.mt-auto {
  margin-top: auto !important; }

.me-0 {
  margin-right: 0 !important; }

.me-1 {
  margin-right: 0.1rem !important; }

.me-2 {
  margin-right: 0.2rem !important; }

.me-3 {
  margin-right: 0.3rem !important; }

.me-4 {
  margin-right: 0.4rem !important; }

.me-5 {
  margin-right: 0.5rem !important; }

.me-6 {
  margin-right: 0.6rem !important; }

.me-8 {
  margin-right: 0.8rem !important; }

.me-10 {
  margin-right: 1rem !important; }

.me-12 {
  margin-right: 1.2rem !important; }

.me-13 {
  margin-right: 1.3rem !important; }

.me-15 {
  margin-right: 1.5rem !important; }

.me-16 {
  margin-right: 1.6rem !important; }

.me-20 {
  margin-right: 2rem !important; }

.me-24 {
  margin-right: 2.4rem !important; }

.me-25 {
  margin-right: 2.5rem !important; }

.me-29 {
  margin-right: 2.9rem !important; }

.me-30 {
  margin-right: 3rem !important; }

.me-32 {
  margin-right: 3.2rem !important; }

.me-35 {
  margin-right: 3.5rem !important; }

.me-37 {
  margin-right: 3.7rem !important; }

.me-40 {
  margin-right: 4rem !important; }

.me-42 {
  margin-right: 4.2rem !important; }

.me-44 {
  margin-right: 4.4rem !important; }

.me-48 {
  margin-right: 4.8rem !important; }

.me-50 {
  margin-right: 5rem !important; }

.me-52 {
  margin-right: 5.2rem !important; }

.me-55 {
  margin-right: 5.5rem !important; }

.me-60 {
  margin-right: 6rem !important; }

.me-62 {
  margin-right: 6.2rem !important; }

.me-64 {
  margin-right: 6.4rem !important; }

.me-65 {
  margin-right: 6.5rem !important; }

.me-70 {
  margin-right: 7rem !important; }

.me-72 {
  margin-right: 7.2rem !important; }

.me-80 {
  margin-right: 8rem !important; }

.me-85 {
  margin-right: 8.5rem !important; }

.me-95 {
  margin-right: 9.5rem !important; }

.me-96 {
  margin-right: 9.6rem !important; }

.me-100 {
  margin-right: 10rem !important; }

.me-104 {
  margin-right: 10.4rem !important; }

.me-108 {
  margin-right: 10.8rem !important; }

.me-110 {
  margin-right: 11rem !important; }

.me-116 {
  margin-right: 11.6rem !important; }

.me-120 {
  margin-right: 12rem !important; }

.me-128 {
  margin-right: 12.8rem !important; }

.me-140 {
  margin-right: 14rem !important; }

.me-148 {
  margin-right: 14.8rem !important; }

.me-160 {
  margin-right: 16rem !important; }

.me-170 {
  margin-right: 17rem !important; }

.me-200 {
  margin-right: 20rem !important; }

.me-240 {
  margin-right: 24rem !important; }

.me-245 {
  margin-right: 24.5rem !important; }

.me-250 {
  margin-right: 25rem !important; }

.me-300 {
  margin-right: 30rem !important; }

.me-400 {
  margin-right: 40rem !important; }

.me-n25 {
  margin-right: -25% !important; }

.me-auto {
  margin-right: auto !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-1 {
  margin-bottom: 0.1rem !important; }

.mb-2 {
  margin-bottom: 0.2rem !important; }

.mb-3 {
  margin-bottom: 0.3rem !important; }

.mb-4 {
  margin-bottom: 0.4rem !important; }

.mb-5 {
  margin-bottom: 0.5rem !important; }

.mb-6 {
  margin-bottom: 0.6rem !important; }

.mb-8 {
  margin-bottom: 0.8rem !important; }

.mb-10 {
  margin-bottom: 1rem !important; }

.mb-12 {
  margin-bottom: 1.2rem !important; }

.mb-13 {
  margin-bottom: 1.3rem !important; }

.mb-15 {
  margin-bottom: 1.5rem !important; }

.mb-16 {
  margin-bottom: 1.6rem !important; }

.mb-20 {
  margin-bottom: 2rem !important; }

.mb-24 {
  margin-bottom: 2.4rem !important; }

.mb-25 {
  margin-bottom: 2.5rem !important; }

.mb-29 {
  margin-bottom: 2.9rem !important; }

.mb-30 {
  margin-bottom: 3rem !important; }

.mb-32 {
  margin-bottom: 3.2rem !important; }

.mb-35 {
  margin-bottom: 3.5rem !important; }

.mb-37 {
  margin-bottom: 3.7rem !important; }

.mb-40 {
  margin-bottom: 4rem !important; }

.mb-42 {
  margin-bottom: 4.2rem !important; }

.mb-44 {
  margin-bottom: 4.4rem !important; }

.mb-48 {
  margin-bottom: 4.8rem !important; }

.mb-50 {
  margin-bottom: 5rem !important; }

.mb-52 {
  margin-bottom: 5.2rem !important; }

.mb-55 {
  margin-bottom: 5.5rem !important; }

.mb-60 {
  margin-bottom: 6rem !important; }

.mb-62 {
  margin-bottom: 6.2rem !important; }

.mb-64 {
  margin-bottom: 6.4rem !important; }

.mb-65 {
  margin-bottom: 6.5rem !important; }

.mb-70 {
  margin-bottom: 7rem !important; }

.mb-72 {
  margin-bottom: 7.2rem !important; }

.mb-80 {
  margin-bottom: 8rem !important; }

.mb-85 {
  margin-bottom: 8.5rem !important; }

.mb-95 {
  margin-bottom: 9.5rem !important; }

.mb-96 {
  margin-bottom: 9.6rem !important; }

.mb-100 {
  margin-bottom: 10rem !important; }

.mb-104 {
  margin-bottom: 10.4rem !important; }

.mb-108 {
  margin-bottom: 10.8rem !important; }

.mb-110 {
  margin-bottom: 11rem !important; }

.mb-116 {
  margin-bottom: 11.6rem !important; }

.mb-120 {
  margin-bottom: 12rem !important; }

.mb-128 {
  margin-bottom: 12.8rem !important; }

.mb-140 {
  margin-bottom: 14rem !important; }

.mb-148 {
  margin-bottom: 14.8rem !important; }

.mb-160 {
  margin-bottom: 16rem !important; }

.mb-170 {
  margin-bottom: 17rem !important; }

.mb-200 {
  margin-bottom: 20rem !important; }

.mb-240 {
  margin-bottom: 24rem !important; }

.mb-245 {
  margin-bottom: 24.5rem !important; }

.mb-250 {
  margin-bottom: 25rem !important; }

.mb-300 {
  margin-bottom: 30rem !important; }

.mb-400 {
  margin-bottom: 40rem !important; }

.mb-n25 {
  margin-bottom: -25% !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ms-0 {
  margin-left: 0 !important; }

.ms-1 {
  margin-left: 0.1rem !important; }

.ms-2 {
  margin-left: 0.2rem !important; }

.ms-3 {
  margin-left: 0.3rem !important; }

.ms-4 {
  margin-left: 0.4rem !important; }

.ms-5 {
  margin-left: 0.5rem !important; }

.ms-6 {
  margin-left: 0.6rem !important; }

.ms-8 {
  margin-left: 0.8rem !important; }

.ms-10 {
  margin-left: 1rem !important; }

.ms-12 {
  margin-left: 1.2rem !important; }

.ms-13 {
  margin-left: 1.3rem !important; }

.ms-15 {
  margin-left: 1.5rem !important; }

.ms-16 {
  margin-left: 1.6rem !important; }

.ms-20 {
  margin-left: 2rem !important; }

.ms-24 {
  margin-left: 2.4rem !important; }

.ms-25 {
  margin-left: 2.5rem !important; }

.ms-29 {
  margin-left: 2.9rem !important; }

.ms-30 {
  margin-left: 3rem !important; }

.ms-32 {
  margin-left: 3.2rem !important; }

.ms-35 {
  margin-left: 3.5rem !important; }

.ms-37 {
  margin-left: 3.7rem !important; }

.ms-40 {
  margin-left: 4rem !important; }

.ms-42 {
  margin-left: 4.2rem !important; }

.ms-44 {
  margin-left: 4.4rem !important; }

.ms-48 {
  margin-left: 4.8rem !important; }

.ms-50 {
  margin-left: 5rem !important; }

.ms-52 {
  margin-left: 5.2rem !important; }

.ms-55 {
  margin-left: 5.5rem !important; }

.ms-60 {
  margin-left: 6rem !important; }

.ms-62 {
  margin-left: 6.2rem !important; }

.ms-64 {
  margin-left: 6.4rem !important; }

.ms-65 {
  margin-left: 6.5rem !important; }

.ms-70 {
  margin-left: 7rem !important; }

.ms-72 {
  margin-left: 7.2rem !important; }

.ms-80 {
  margin-left: 8rem !important; }

.ms-85 {
  margin-left: 8.5rem !important; }

.ms-95 {
  margin-left: 9.5rem !important; }

.ms-96 {
  margin-left: 9.6rem !important; }

.ms-100 {
  margin-left: 10rem !important; }

.ms-104 {
  margin-left: 10.4rem !important; }

.ms-108 {
  margin-left: 10.8rem !important; }

.ms-110 {
  margin-left: 11rem !important; }

.ms-116 {
  margin-left: 11.6rem !important; }

.ms-120 {
  margin-left: 12rem !important; }

.ms-128 {
  margin-left: 12.8rem !important; }

.ms-140 {
  margin-left: 14rem !important; }

.ms-148 {
  margin-left: 14.8rem !important; }

.ms-160 {
  margin-left: 16rem !important; }

.ms-170 {
  margin-left: 17rem !important; }

.ms-200 {
  margin-left: 20rem !important; }

.ms-240 {
  margin-left: 24rem !important; }

.ms-245 {
  margin-left: 24.5rem !important; }

.ms-250 {
  margin-left: 25rem !important; }

.ms-300 {
  margin-left: 30rem !important; }

.ms-400 {
  margin-left: 40rem !important; }

.ms-n25 {
  margin-left: -25% !important; }

.ms-auto {
  margin-left: auto !important; }

.p-0 {
  padding: 0 !important; }

.p-1 {
  padding: 0.1rem !important; }

.p-2 {
  padding: 0.2rem !important; }

.p-3 {
  padding: 0.3rem !important; }

.p-4 {
  padding: 0.4rem !important; }

.p-5 {
  padding: 0.5rem !important; }

.p-6 {
  padding: 0.6rem !important; }

.p-8 {
  padding: 0.8rem !important; }

.p-10 {
  padding: 1rem !important; }

.p-12 {
  padding: 1.2rem !important; }

.p-13 {
  padding: 1.3rem !important; }

.p-15 {
  padding: 1.5rem !important; }

.p-16 {
  padding: 1.6rem !important; }

.p-20 {
  padding: 2rem !important; }

.p-24 {
  padding: 2.4rem !important; }

.p-25 {
  padding: 2.5rem !important; }

.p-29 {
  padding: 2.9rem !important; }

.p-30 {
  padding: 3rem !important; }

.p-32 {
  padding: 3.2rem !important; }

.p-35 {
  padding: 3.5rem !important; }

.p-37 {
  padding: 3.7rem !important; }

.p-40 {
  padding: 4rem !important; }

.p-42 {
  padding: 4.2rem !important; }

.p-44 {
  padding: 4.4rem !important; }

.p-48 {
  padding: 4.8rem !important; }

.p-50 {
  padding: 5rem !important; }

.p-52 {
  padding: 5.2rem !important; }

.p-55 {
  padding: 5.5rem !important; }

.p-60 {
  padding: 6rem !important; }

.p-62 {
  padding: 6.2rem !important; }

.p-64 {
  padding: 6.4rem !important; }

.p-65 {
  padding: 6.5rem !important; }

.p-70 {
  padding: 7rem !important; }

.p-72 {
  padding: 7.2rem !important; }

.p-80 {
  padding: 8rem !important; }

.p-85 {
  padding: 8.5rem !important; }

.p-95 {
  padding: 9.5rem !important; }

.p-96 {
  padding: 9.6rem !important; }

.p-100 {
  padding: 10rem !important; }

.p-104 {
  padding: 10.4rem !important; }

.p-108 {
  padding: 10.8rem !important; }

.p-110 {
  padding: 11rem !important; }

.p-116 {
  padding: 11.6rem !important; }

.p-120 {
  padding: 12rem !important; }

.p-128 {
  padding: 12.8rem !important; }

.p-140 {
  padding: 14rem !important; }

.p-148 {
  padding: 14.8rem !important; }

.p-160 {
  padding: 16rem !important; }

.p-170 {
  padding: 17rem !important; }

.p-200 {
  padding: 20rem !important; }

.p-240 {
  padding: 24rem !important; }

.p-245 {
  padding: 24.5rem !important; }

.p-250 {
  padding: 25rem !important; }

.p-300 {
  padding: 30rem !important; }

.p-400 {
  padding: 40rem !important; }

.p-n25 {
  padding: -25% !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.px-1 {
  padding-right: 0.1rem !important;
  padding-left: 0.1rem !important; }

.px-2 {
  padding-right: 0.2rem !important;
  padding-left: 0.2rem !important; }

.px-3 {
  padding-right: 0.3rem !important;
  padding-left: 0.3rem !important; }

.px-4 {
  padding-right: 0.4rem !important;
  padding-left: 0.4rem !important; }

.px-5 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important; }

.px-6 {
  padding-right: 0.6rem !important;
  padding-left: 0.6rem !important; }

.px-8 {
  padding-right: 0.8rem !important;
  padding-left: 0.8rem !important; }

.px-10 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.px-12 {
  padding-right: 1.2rem !important;
  padding-left: 1.2rem !important; }

.px-13 {
  padding-right: 1.3rem !important;
  padding-left: 1.3rem !important; }

.px-15 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

.px-16 {
  padding-right: 1.6rem !important;
  padding-left: 1.6rem !important; }

.px-20 {
  padding-right: 2rem !important;
  padding-left: 2rem !important; }

.px-24 {
  padding-right: 2.4rem !important;
  padding-left: 2.4rem !important; }

.px-25 {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important; }

.px-29 {
  padding-right: 2.9rem !important;
  padding-left: 2.9rem !important; }

.px-30 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.px-32 {
  padding-right: 3.2rem !important;
  padding-left: 3.2rem !important; }

.px-35 {
  padding-right: 3.5rem !important;
  padding-left: 3.5rem !important; }

.px-37 {
  padding-right: 3.7rem !important;
  padding-left: 3.7rem !important; }

.px-40 {
  padding-right: 4rem !important;
  padding-left: 4rem !important; }

.px-42 {
  padding-right: 4.2rem !important;
  padding-left: 4.2rem !important; }

.px-44 {
  padding-right: 4.4rem !important;
  padding-left: 4.4rem !important; }

.px-48 {
  padding-right: 4.8rem !important;
  padding-left: 4.8rem !important; }

.px-50 {
  padding-right: 5rem !important;
  padding-left: 5rem !important; }

.px-52 {
  padding-right: 5.2rem !important;
  padding-left: 5.2rem !important; }

.px-55 {
  padding-right: 5.5rem !important;
  padding-left: 5.5rem !important; }

.px-60 {
  padding-right: 6rem !important;
  padding-left: 6rem !important; }

.px-62 {
  padding-right: 6.2rem !important;
  padding-left: 6.2rem !important; }

.px-64 {
  padding-right: 6.4rem !important;
  padding-left: 6.4rem !important; }

.px-65 {
  padding-right: 6.5rem !important;
  padding-left: 6.5rem !important; }

.px-70 {
  padding-right: 7rem !important;
  padding-left: 7rem !important; }

.px-72 {
  padding-right: 7.2rem !important;
  padding-left: 7.2rem !important; }

.px-80 {
  padding-right: 8rem !important;
  padding-left: 8rem !important; }

.px-85 {
  padding-right: 8.5rem !important;
  padding-left: 8.5rem !important; }

.px-95 {
  padding-right: 9.5rem !important;
  padding-left: 9.5rem !important; }

.px-96 {
  padding-right: 9.6rem !important;
  padding-left: 9.6rem !important; }

.px-100 {
  padding-right: 10rem !important;
  padding-left: 10rem !important; }

.px-104 {
  padding-right: 10.4rem !important;
  padding-left: 10.4rem !important; }

.px-108 {
  padding-right: 10.8rem !important;
  padding-left: 10.8rem !important; }

.px-110 {
  padding-right: 11rem !important;
  padding-left: 11rem !important; }

.px-116 {
  padding-right: 11.6rem !important;
  padding-left: 11.6rem !important; }

.px-120 {
  padding-right: 12rem !important;
  padding-left: 12rem !important; }

.px-128 {
  padding-right: 12.8rem !important;
  padding-left: 12.8rem !important; }

.px-140 {
  padding-right: 14rem !important;
  padding-left: 14rem !important; }

.px-148 {
  padding-right: 14.8rem !important;
  padding-left: 14.8rem !important; }

.px-160 {
  padding-right: 16rem !important;
  padding-left: 16rem !important; }

.px-170 {
  padding-right: 17rem !important;
  padding-left: 17rem !important; }

.px-200 {
  padding-right: 20rem !important;
  padding-left: 20rem !important; }

.px-240 {
  padding-right: 24rem !important;
  padding-left: 24rem !important; }

.px-245 {
  padding-right: 24.5rem !important;
  padding-left: 24.5rem !important; }

.px-250 {
  padding-right: 25rem !important;
  padding-left: 25rem !important; }

.px-300 {
  padding-right: 30rem !important;
  padding-left: 30rem !important; }

.px-400 {
  padding-right: 40rem !important;
  padding-left: 40rem !important; }

.px-n25 {
  padding-right: -25% !important;
  padding-left: -25% !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-1 {
  padding-top: 0.1rem !important;
  padding-bottom: 0.1rem !important; }

.py-2 {
  padding-top: 0.2rem !important;
  padding-bottom: 0.2rem !important; }

.py-3 {
  padding-top: 0.3rem !important;
  padding-bottom: 0.3rem !important; }

.py-4 {
  padding-top: 0.4rem !important;
  padding-bottom: 0.4rem !important; }

.py-5 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.py-6 {
  padding-top: 0.6rem !important;
  padding-bottom: 0.6rem !important; }

.py-8 {
  padding-top: 0.8rem !important;
  padding-bottom: 0.8rem !important; }

.py-10 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.py-12 {
  padding-top: 1.2rem !important;
  padding-bottom: 1.2rem !important; }

.py-13 {
  padding-top: 1.3rem !important;
  padding-bottom: 1.3rem !important; }

.py-15 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.py-16 {
  padding-top: 1.6rem !important;
  padding-bottom: 1.6rem !important; }

.py-20 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important; }

.py-24 {
  padding-top: 2.4rem !important;
  padding-bottom: 2.4rem !important; }

.py-25 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important; }

.py-29 {
  padding-top: 2.9rem !important;
  padding-bottom: 2.9rem !important; }

.py-30 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.py-32 {
  padding-top: 3.2rem !important;
  padding-bottom: 3.2rem !important; }

.py-35 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important; }

.py-37 {
  padding-top: 3.7rem !important;
  padding-bottom: 3.7rem !important; }

.py-40 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important; }

.py-42 {
  padding-top: 4.2rem !important;
  padding-bottom: 4.2rem !important; }

.py-44 {
  padding-top: 4.4rem !important;
  padding-bottom: 4.4rem !important; }

.py-48 {
  padding-top: 4.8rem !important;
  padding-bottom: 4.8rem !important; }

.py-50 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important; }

.py-52 {
  padding-top: 5.2rem !important;
  padding-bottom: 5.2rem !important; }

.py-55 {
  padding-top: 5.5rem !important;
  padding-bottom: 5.5rem !important; }

.py-60 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important; }

.py-62 {
  padding-top: 6.2rem !important;
  padding-bottom: 6.2rem !important; }

.py-64 {
  padding-top: 6.4rem !important;
  padding-bottom: 6.4rem !important; }

.py-65 {
  padding-top: 6.5rem !important;
  padding-bottom: 6.5rem !important; }

.py-70 {
  padding-top: 7rem !important;
  padding-bottom: 7rem !important; }

.py-72 {
  padding-top: 7.2rem !important;
  padding-bottom: 7.2rem !important; }

.py-80 {
  padding-top: 8rem !important;
  padding-bottom: 8rem !important; }

.py-85 {
  padding-top: 8.5rem !important;
  padding-bottom: 8.5rem !important; }

.py-95 {
  padding-top: 9.5rem !important;
  padding-bottom: 9.5rem !important; }

.py-96 {
  padding-top: 9.6rem !important;
  padding-bottom: 9.6rem !important; }

.py-100 {
  padding-top: 10rem !important;
  padding-bottom: 10rem !important; }

.py-104 {
  padding-top: 10.4rem !important;
  padding-bottom: 10.4rem !important; }

.py-108 {
  padding-top: 10.8rem !important;
  padding-bottom: 10.8rem !important; }

.py-110 {
  padding-top: 11rem !important;
  padding-bottom: 11rem !important; }

.py-116 {
  padding-top: 11.6rem !important;
  padding-bottom: 11.6rem !important; }

.py-120 {
  padding-top: 12rem !important;
  padding-bottom: 12rem !important; }

.py-128 {
  padding-top: 12.8rem !important;
  padding-bottom: 12.8rem !important; }

.py-140 {
  padding-top: 14rem !important;
  padding-bottom: 14rem !important; }

.py-148 {
  padding-top: 14.8rem !important;
  padding-bottom: 14.8rem !important; }

.py-160 {
  padding-top: 16rem !important;
  padding-bottom: 16rem !important; }

.py-170 {
  padding-top: 17rem !important;
  padding-bottom: 17rem !important; }

.py-200 {
  padding-top: 20rem !important;
  padding-bottom: 20rem !important; }

.py-240 {
  padding-top: 24rem !important;
  padding-bottom: 24rem !important; }

.py-245 {
  padding-top: 24.5rem !important;
  padding-bottom: 24.5rem !important; }

.py-250 {
  padding-top: 25rem !important;
  padding-bottom: 25rem !important; }

.py-300 {
  padding-top: 30rem !important;
  padding-bottom: 30rem !important; }

.py-400 {
  padding-top: 40rem !important;
  padding-bottom: 40rem !important; }

.py-n25 {
  padding-top: -25% !important;
  padding-bottom: -25% !important; }

.pt-0 {
  padding-top: 0 !important; }

.pt-1 {
  padding-top: 0.1rem !important; }

.pt-2 {
  padding-top: 0.2rem !important; }

.pt-3 {
  padding-top: 0.3rem !important; }

.pt-4 {
  padding-top: 0.4rem !important; }

.pt-5 {
  padding-top: 0.5rem !important; }

.pt-6 {
  padding-top: 0.6rem !important; }

.pt-8 {
  padding-top: 0.8rem !important; }

.pt-10 {
  padding-top: 1rem !important; }

.pt-12 {
  padding-top: 1.2rem !important; }

.pt-13 {
  padding-top: 1.3rem !important; }

.pt-15 {
  padding-top: 1.5rem !important; }

.pt-16 {
  padding-top: 1.6rem !important; }

.pt-20 {
  padding-top: 2rem !important; }

.pt-24 {
  padding-top: 2.4rem !important; }

.pt-25 {
  padding-top: 2.5rem !important; }

.pt-29 {
  padding-top: 2.9rem !important; }

.pt-30 {
  padding-top: 3rem !important; }

.pt-32 {
  padding-top: 3.2rem !important; }

.pt-35 {
  padding-top: 3.5rem !important; }

.pt-37 {
  padding-top: 3.7rem !important; }

.pt-40 {
  padding-top: 4rem !important; }

.pt-42 {
  padding-top: 4.2rem !important; }

.pt-44 {
  padding-top: 4.4rem !important; }

.pt-48 {
  padding-top: 4.8rem !important; }

.pt-50 {
  padding-top: 5rem !important; }

.pt-52 {
  padding-top: 5.2rem !important; }

.pt-55 {
  padding-top: 5.5rem !important; }

.pt-60 {
  padding-top: 6rem !important; }

.pt-62 {
  padding-top: 6.2rem !important; }

.pt-64 {
  padding-top: 6.4rem !important; }

.pt-65 {
  padding-top: 6.5rem !important; }

.pt-70 {
  padding-top: 7rem !important; }

.pt-72 {
  padding-top: 7.2rem !important; }

.pt-80 {
  padding-top: 8rem !important; }

.pt-85 {
  padding-top: 8.5rem !important; }

.pt-95 {
  padding-top: 9.5rem !important; }

.pt-96 {
  padding-top: 9.6rem !important; }

.pt-100 {
  padding-top: 10rem !important; }

.pt-104 {
  padding-top: 10.4rem !important; }

.pt-108 {
  padding-top: 10.8rem !important; }

.pt-110 {
  padding-top: 11rem !important; }

.pt-116 {
  padding-top: 11.6rem !important; }

.pt-120 {
  padding-top: 12rem !important; }

.pt-128 {
  padding-top: 12.8rem !important; }

.pt-140 {
  padding-top: 14rem !important; }

.pt-148 {
  padding-top: 14.8rem !important; }

.pt-160 {
  padding-top: 16rem !important; }

.pt-170 {
  padding-top: 17rem !important; }

.pt-200 {
  padding-top: 20rem !important; }

.pt-240 {
  padding-top: 24rem !important; }

.pt-245 {
  padding-top: 24.5rem !important; }

.pt-250 {
  padding-top: 25rem !important; }

.pt-300 {
  padding-top: 30rem !important; }

.pt-400 {
  padding-top: 40rem !important; }

.pt-n25 {
  padding-top: -25% !important; }

.pe-0 {
  padding-right: 0 !important; }

.pe-1 {
  padding-right: 0.1rem !important; }

.pe-2 {
  padding-right: 0.2rem !important; }

.pe-3 {
  padding-right: 0.3rem !important; }

.pe-4 {
  padding-right: 0.4rem !important; }

.pe-5 {
  padding-right: 0.5rem !important; }

.pe-6 {
  padding-right: 0.6rem !important; }

.pe-8 {
  padding-right: 0.8rem !important; }

.pe-10 {
  padding-right: 1rem !important; }

.pe-12 {
  padding-right: 1.2rem !important; }

.pe-13 {
  padding-right: 1.3rem !important; }

.pe-15 {
  padding-right: 1.5rem !important; }

.pe-16 {
  padding-right: 1.6rem !important; }

.pe-20 {
  padding-right: 2rem !important; }

.pe-24 {
  padding-right: 2.4rem !important; }

.pe-25 {
  padding-right: 2.5rem !important; }

.pe-29 {
  padding-right: 2.9rem !important; }

.pe-30 {
  padding-right: 3rem !important; }

.pe-32 {
  padding-right: 3.2rem !important; }

.pe-35 {
  padding-right: 3.5rem !important; }

.pe-37 {
  padding-right: 3.7rem !important; }

.pe-40 {
  padding-right: 4rem !important; }

.pe-42 {
  padding-right: 4.2rem !important; }

.pe-44 {
  padding-right: 4.4rem !important; }

.pe-48 {
  padding-right: 4.8rem !important; }

.pe-50 {
  padding-right: 5rem !important; }

.pe-52 {
  padding-right: 5.2rem !important; }

.pe-55 {
  padding-right: 5.5rem !important; }

.pe-60 {
  padding-right: 6rem !important; }

.pe-62 {
  padding-right: 6.2rem !important; }

.pe-64 {
  padding-right: 6.4rem !important; }

.pe-65 {
  padding-right: 6.5rem !important; }

.pe-70 {
  padding-right: 7rem !important; }

.pe-72 {
  padding-right: 7.2rem !important; }

.pe-80 {
  padding-right: 8rem !important; }

.pe-85 {
  padding-right: 8.5rem !important; }

.pe-95 {
  padding-right: 9.5rem !important; }

.pe-96 {
  padding-right: 9.6rem !important; }

.pe-100 {
  padding-right: 10rem !important; }

.pe-104 {
  padding-right: 10.4rem !important; }

.pe-108 {
  padding-right: 10.8rem !important; }

.pe-110 {
  padding-right: 11rem !important; }

.pe-116 {
  padding-right: 11.6rem !important; }

.pe-120 {
  padding-right: 12rem !important; }

.pe-128 {
  padding-right: 12.8rem !important; }

.pe-140 {
  padding-right: 14rem !important; }

.pe-148 {
  padding-right: 14.8rem !important; }

.pe-160 {
  padding-right: 16rem !important; }

.pe-170 {
  padding-right: 17rem !important; }

.pe-200 {
  padding-right: 20rem !important; }

.pe-240 {
  padding-right: 24rem !important; }

.pe-245 {
  padding-right: 24.5rem !important; }

.pe-250 {
  padding-right: 25rem !important; }

.pe-300 {
  padding-right: 30rem !important; }

.pe-400 {
  padding-right: 40rem !important; }

.pe-n25 {
  padding-right: -25% !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-1 {
  padding-bottom: 0.1rem !important; }

.pb-2 {
  padding-bottom: 0.2rem !important; }

.pb-3 {
  padding-bottom: 0.3rem !important; }

.pb-4 {
  padding-bottom: 0.4rem !important; }

.pb-5 {
  padding-bottom: 0.5rem !important; }

.pb-6 {
  padding-bottom: 0.6rem !important; }

.pb-8 {
  padding-bottom: 0.8rem !important; }

.pb-10 {
  padding-bottom: 1rem !important; }

.pb-12 {
  padding-bottom: 1.2rem !important; }

.pb-13 {
  padding-bottom: 1.3rem !important; }

.pb-15 {
  padding-bottom: 1.5rem !important; }

.pb-16 {
  padding-bottom: 1.6rem !important; }

.pb-20 {
  padding-bottom: 2rem !important; }

.pb-24 {
  padding-bottom: 2.4rem !important; }

.pb-25 {
  padding-bottom: 2.5rem !important; }

.pb-29 {
  padding-bottom: 2.9rem !important; }

.pb-30 {
  padding-bottom: 3rem !important; }

.pb-32 {
  padding-bottom: 3.2rem !important; }

.pb-35 {
  padding-bottom: 3.5rem !important; }

.pb-37 {
  padding-bottom: 3.7rem !important; }

.pb-40 {
  padding-bottom: 4rem !important; }

.pb-42 {
  padding-bottom: 4.2rem !important; }

.pb-44 {
  padding-bottom: 4.4rem !important; }

.pb-48 {
  padding-bottom: 4.8rem !important; }

.pb-50 {
  padding-bottom: 5rem !important; }

.pb-52 {
  padding-bottom: 5.2rem !important; }

.pb-55 {
  padding-bottom: 5.5rem !important; }

.pb-60 {
  padding-bottom: 6rem !important; }

.pb-62 {
  padding-bottom: 6.2rem !important; }

.pb-64 {
  padding-bottom: 6.4rem !important; }

.pb-65 {
  padding-bottom: 6.5rem !important; }

.pb-70 {
  padding-bottom: 7rem !important; }

.pb-72 {
  padding-bottom: 7.2rem !important; }

.pb-80 {
  padding-bottom: 8rem !important; }

.pb-85 {
  padding-bottom: 8.5rem !important; }

.pb-95 {
  padding-bottom: 9.5rem !important; }

.pb-96 {
  padding-bottom: 9.6rem !important; }

.pb-100 {
  padding-bottom: 10rem !important; }

.pb-104 {
  padding-bottom: 10.4rem !important; }

.pb-108 {
  padding-bottom: 10.8rem !important; }

.pb-110 {
  padding-bottom: 11rem !important; }

.pb-116 {
  padding-bottom: 11.6rem !important; }

.pb-120 {
  padding-bottom: 12rem !important; }

.pb-128 {
  padding-bottom: 12.8rem !important; }

.pb-140 {
  padding-bottom: 14rem !important; }

.pb-148 {
  padding-bottom: 14.8rem !important; }

.pb-160 {
  padding-bottom: 16rem !important; }

.pb-170 {
  padding-bottom: 17rem !important; }

.pb-200 {
  padding-bottom: 20rem !important; }

.pb-240 {
  padding-bottom: 24rem !important; }

.pb-245 {
  padding-bottom: 24.5rem !important; }

.pb-250 {
  padding-bottom: 25rem !important; }

.pb-300 {
  padding-bottom: 30rem !important; }

.pb-400 {
  padding-bottom: 40rem !important; }

.pb-n25 {
  padding-bottom: -25% !important; }

.ps-0 {
  padding-left: 0 !important; }

.ps-1 {
  padding-left: 0.1rem !important; }

.ps-2 {
  padding-left: 0.2rem !important; }

.ps-3 {
  padding-left: 0.3rem !important; }

.ps-4 {
  padding-left: 0.4rem !important; }

.ps-5 {
  padding-left: 0.5rem !important; }

.ps-6 {
  padding-left: 0.6rem !important; }

.ps-8 {
  padding-left: 0.8rem !important; }

.ps-10 {
  padding-left: 1rem !important; }

.ps-12 {
  padding-left: 1.2rem !important; }

.ps-13 {
  padding-left: 1.3rem !important; }

.ps-15 {
  padding-left: 1.5rem !important; }

.ps-16 {
  padding-left: 1.6rem !important; }

.ps-20 {
  padding-left: 2rem !important; }

.ps-24 {
  padding-left: 2.4rem !important; }

.ps-25 {
  padding-left: 2.5rem !important; }

.ps-29 {
  padding-left: 2.9rem !important; }

.ps-30 {
  padding-left: 3rem !important; }

.ps-32 {
  padding-left: 3.2rem !important; }

.ps-35 {
  padding-left: 3.5rem !important; }

.ps-37 {
  padding-left: 3.7rem !important; }

.ps-40 {
  padding-left: 4rem !important; }

.ps-42 {
  padding-left: 4.2rem !important; }

.ps-44 {
  padding-left: 4.4rem !important; }

.ps-48 {
  padding-left: 4.8rem !important; }

.ps-50 {
  padding-left: 5rem !important; }

.ps-52 {
  padding-left: 5.2rem !important; }

.ps-55 {
  padding-left: 5.5rem !important; }

.ps-60 {
  padding-left: 6rem !important; }

.ps-62 {
  padding-left: 6.2rem !important; }

.ps-64 {
  padding-left: 6.4rem !important; }

.ps-65 {
  padding-left: 6.5rem !important; }

.ps-70 {
  padding-left: 7rem !important; }

.ps-72 {
  padding-left: 7.2rem !important; }

.ps-80 {
  padding-left: 8rem !important; }

.ps-85 {
  padding-left: 8.5rem !important; }

.ps-95 {
  padding-left: 9.5rem !important; }

.ps-96 {
  padding-left: 9.6rem !important; }

.ps-100 {
  padding-left: 10rem !important; }

.ps-104 {
  padding-left: 10.4rem !important; }

.ps-108 {
  padding-left: 10.8rem !important; }

.ps-110 {
  padding-left: 11rem !important; }

.ps-116 {
  padding-left: 11.6rem !important; }

.ps-120 {
  padding-left: 12rem !important; }

.ps-128 {
  padding-left: 12.8rem !important; }

.ps-140 {
  padding-left: 14rem !important; }

.ps-148 {
  padding-left: 14.8rem !important; }

.ps-160 {
  padding-left: 16rem !important; }

.ps-170 {
  padding-left: 17rem !important; }

.ps-200 {
  padding-left: 20rem !important; }

.ps-240 {
  padding-left: 24rem !important; }

.ps-245 {
  padding-left: 24.5rem !important; }

.ps-250 {
  padding-left: 25rem !important; }

.ps-300 {
  padding-left: 30rem !important; }

.ps-400 {
  padding-left: 40rem !important; }

.ps-n25 {
  padding-left: -25% !important; }

.gap-0 {
  gap: 0 !important; }

.gap-1 {
  gap: 0.1rem !important; }

.gap-2 {
  gap: 0.2rem !important; }

.gap-3 {
  gap: 0.3rem !important; }

.gap-4 {
  gap: 0.4rem !important; }

.gap-5 {
  gap: 0.5rem !important; }

.gap-6 {
  gap: 0.6rem !important; }

.gap-8 {
  gap: 0.8rem !important; }

.gap-10 {
  gap: 1rem !important; }

.gap-12 {
  gap: 1.2rem !important; }

.gap-13 {
  gap: 1.3rem !important; }

.gap-15 {
  gap: 1.5rem !important; }

.gap-16 {
  gap: 1.6rem !important; }

.gap-20 {
  gap: 2rem !important; }

.gap-24 {
  gap: 2.4rem !important; }

.gap-25 {
  gap: 2.5rem !important; }

.gap-29 {
  gap: 2.9rem !important; }

.gap-30 {
  gap: 3rem !important; }

.gap-32 {
  gap: 3.2rem !important; }

.gap-35 {
  gap: 3.5rem !important; }

.gap-37 {
  gap: 3.7rem !important; }

.gap-40 {
  gap: 4rem !important; }

.gap-42 {
  gap: 4.2rem !important; }

.gap-44 {
  gap: 4.4rem !important; }

.gap-48 {
  gap: 4.8rem !important; }

.gap-50 {
  gap: 5rem !important; }

.gap-52 {
  gap: 5.2rem !important; }

.gap-55 {
  gap: 5.5rem !important; }

.gap-60 {
  gap: 6rem !important; }

.gap-62 {
  gap: 6.2rem !important; }

.gap-64 {
  gap: 6.4rem !important; }

.gap-65 {
  gap: 6.5rem !important; }

.gap-70 {
  gap: 7rem !important; }

.gap-72 {
  gap: 7.2rem !important; }

.gap-80 {
  gap: 8rem !important; }

.gap-85 {
  gap: 8.5rem !important; }

.gap-95 {
  gap: 9.5rem !important; }

.gap-96 {
  gap: 9.6rem !important; }

.gap-100 {
  gap: 10rem !important; }

.gap-104 {
  gap: 10.4rem !important; }

.gap-108 {
  gap: 10.8rem !important; }

.gap-110 {
  gap: 11rem !important; }

.gap-116 {
  gap: 11.6rem !important; }

.gap-120 {
  gap: 12rem !important; }

.gap-128 {
  gap: 12.8rem !important; }

.gap-140 {
  gap: 14rem !important; }

.gap-148 {
  gap: 14.8rem !important; }

.gap-160 {
  gap: 16rem !important; }

.gap-170 {
  gap: 17rem !important; }

.gap-200 {
  gap: 20rem !important; }

.gap-240 {
  gap: 24rem !important; }

.gap-245 {
  gap: 24.5rem !important; }

.gap-250 {
  gap: 25rem !important; }

.gap-300 {
  gap: 30rem !important; }

.gap-400 {
  gap: 40rem !important; }

.gap-n25 {
  gap: -25% !important; }

.font-family-gantari {
  font-family: "Gantari", sans-serif !important; }

.font-family-roboto {
  font-family: "Roboto", sans-serif !important; }

.fs-12 {
  font-size: 1.2rem !important; }

.fs-13 {
  font-size: 1.3rem !important; }

.fs-14 {
  font-size: 1.4rem !important; }

.fs-15 {
  font-size: 1.5rem !important; }

.fs-16 {
  font-size: 1.6rem !important; }

.fs-18 {
  font-size: 1.8rem !important; }

.fs-24 {
  font-size: 2.4rem !important; }

.fs-28 {
  font-size: 2.8rem !important; }

.fs-30 {
  font-size: 3rem !important; }

.fs-32 {
  font-size: 3.2rem !important; }

.fs-40 {
  font-size: 4rem !important; }

.fs-50 {
  font-size: 5rem !important; }

.fs-60 {
  font-size: 6rem !important; }

.fst-italic {
  font-style: italic !important; }

.fst-normal {
  font-style: normal !important; }

.fw-light {
  font-weight: 300 !important; }

.fw-normal {
  font-weight: 400 !important; }

.fw-bold {
  font-weight: 700 !important; }

.fw-black {
  font-weight: 900 !important; }

.fw-medium {
  font-weight: 500 !important; }

.fw-semi-bold {
  font-weight: 600 !important; }

.lh-1 {
  line-height: 1 !important; }

.lh-sm {
  line-height: 1.25 !important; }

.lh-base {
  line-height: 1.5 !important; }

.lh-lg {
  line-height: 2 !important; }

.text-start {
  text-align: left !important; }

.text-end {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-decoration-none {
  text-decoration: none !important; }

.text-decoration-underline {
  text-decoration: underline !important; }

.text-decoration-line-through {
  text-decoration: line-through !important; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important; }

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important; }

.text-primary01 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary01-rgb), var(--bs-text-opacity)) !important; }

.text-primary02 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary02-rgb), var(--bs-text-opacity)) !important; }

.text-primary03 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary03-rgb), var(--bs-text-opacity)) !important; }

.text-primary04 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary04-rgb), var(--bs-text-opacity)) !important; }

.text-primary05 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary05-rgb), var(--bs-text-opacity)) !important; }

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important; }

.text-secondary01 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary01-rgb), var(--bs-text-opacity)) !important; }

.text-secondary02 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary02-rgb), var(--bs-text-opacity)) !important; }

.text-secondary03 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary03-rgb), var(--bs-text-opacity)) !important; }

.text-secondary04 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary04-rgb), var(--bs-text-opacity)) !important; }

.text-violet {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-violet-rgb), var(--bs-text-opacity)) !important; }

.text-bleu5 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-bleu5-rgb), var(--bs-text-opacity)) !important; }

.text-orange {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-orange-rgb), var(--bs-text-opacity)) !important; }

.text-orange3 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-orange3-rgb), var(--bs-text-opacity)) !important; }

.text-gray-101 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-101-rgb), var(--bs-text-opacity)) !important; }

.text-gray-300 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-300-rgb), var(--bs-text-opacity)) !important; }

.text-gray-400 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-400-rgb), var(--bs-text-opacity)) !important; }

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important; }

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important; }

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important; }

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important; }

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important; }

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important; }

.text-grisclair {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-grisclair-rgb), var(--bs-text-opacity)) !important; }

.text-grisclair2 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-grisclair2-rgb), var(--bs-text-opacity)) !important; }

.text-turquoise {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-turquoise-rgb), var(--bs-text-opacity)) !important; }

.text-turquoise2 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-turquoise2-rgb), var(--bs-text-opacity)) !important; }

.text-turquoise-clair {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-turquoise-clair-rgb), var(--bs-text-opacity)) !important; }

.text-bleu2 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-bleu2-rgb), var(--bs-text-opacity)) !important; }

.text-turquoise-75 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-turquoise-75-rgb), var(--bs-text-opacity)) !important; }

.text-turquoise-80 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-turquoise-80-rgb), var(--bs-text-opacity)) !important; }

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important; }

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important; }

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important; }

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important; }

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important; }

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important; }

.text-opacity-25 {
  --bs-text-opacity: 0.25; }

.text-opacity-50 {
  --bs-text-opacity: 0.5; }

.text-opacity-75 {
  --bs-text-opacity: 0.75; }

.text-opacity-100 {
  --bs-text-opacity: 1; }

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important; }

.bg-primary01 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary01-rgb), var(--bs-bg-opacity)) !important; }

.bg-primary02 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary02-rgb), var(--bs-bg-opacity)) !important; }

.bg-primary03 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary03-rgb), var(--bs-bg-opacity)) !important; }

.bg-primary04 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary04-rgb), var(--bs-bg-opacity)) !important; }

.bg-primary05 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary05-rgb), var(--bs-bg-opacity)) !important; }

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important; }

.bg-secondary01 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary01-rgb), var(--bs-bg-opacity)) !important; }

.bg-secondary02 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary02-rgb), var(--bs-bg-opacity)) !important; }

.bg-secondary03 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary03-rgb), var(--bs-bg-opacity)) !important; }

.bg-secondary04 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary04-rgb), var(--bs-bg-opacity)) !important; }

.bg-violet {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-violet-rgb), var(--bs-bg-opacity)) !important; }

.bg-bleu5 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-bleu5-rgb), var(--bs-bg-opacity)) !important; }

.bg-orange {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-orange-rgb), var(--bs-bg-opacity)) !important; }

.bg-orange3 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-orange3-rgb), var(--bs-bg-opacity)) !important; }

.bg-gray-101 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-101-rgb), var(--bs-bg-opacity)) !important; }

.bg-gray-300 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-300-rgb), var(--bs-bg-opacity)) !important; }

.bg-gray-400 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-400-rgb), var(--bs-bg-opacity)) !important; }

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important; }

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important; }

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important; }

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important; }

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important; }

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important; }

.bg-grisclair {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-grisclair-rgb), var(--bs-bg-opacity)) !important; }

.bg-grisclair2 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-grisclair2-rgb), var(--bs-bg-opacity)) !important; }

.bg-turquoise {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-turquoise-rgb), var(--bs-bg-opacity)) !important; }

.bg-turquoise2 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-turquoise2-rgb), var(--bs-bg-opacity)) !important; }

.bg-turquoise-clair {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-turquoise-clair-rgb), var(--bs-bg-opacity)) !important; }

.bg-bleu2 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-bleu2-rgb), var(--bs-bg-opacity)) !important; }

.bg-turquoise-75 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-turquoise-75-rgb), var(--bs-bg-opacity)) !important; }

.bg-turquoise-80 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-turquoise-80-rgb), var(--bs-bg-opacity)) !important; }

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important; }

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important; }

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important; }

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important; }

.bg-opacity-10 {
  --bs-bg-opacity: 0.1; }

.bg-opacity-25 {
  --bs-bg-opacity: 0.25; }

.bg-opacity-50 {
  --bs-bg-opacity: 0.5; }

.bg-opacity-75 {
  --bs-bg-opacity: 0.75; }

.bg-opacity-100 {
  --bs-bg-opacity: 1; }

.bg-gradient {
  background-image: var(--bs-gradient) !important; }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.pe-none {
  pointer-events: none !important; }

.pe-auto {
  pointer-events: auto !important; }

.rounded {
  border-radius: 0.8rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.rounded-1 {
  border-radius: 0.6rem !important; }

.rounded-2 {
  border-radius: 0.8rem !important; }

.rounded-3 {
  border-radius: 5px 20px 5px 20px !important; }

.rounded-4 {
  border-radius: 0.4rem !important; }

.rounded-5 {
  border-radius: 0.5rem !important; }

.rounded-8 {
  border-radius: 0.8rem !important; }

.rounded-9 {
  border-radius: 0.9rem !important; }

.rounded-15 {
  border-radius: 1.5rem !important; }

.rounded-16 {
  border-radius: 1.6rem !important; }

.rounded-64 {
  border-radius: 6.4rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-intro {
  border-radius: 50% 50% 0 0 !important; }

.rounded-top {
  border-top-left-radius: 0.8rem !important;
  border-top-right-radius: 0.8rem !important; }

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important; }

.rounded-top-1 {
  border-top-left-radius: 0.6rem !important;
  border-top-right-radius: 0.6rem !important; }

.rounded-top-2 {
  border-top-left-radius: 0.8rem !important;
  border-top-right-radius: 0.8rem !important; }

.rounded-top-3 {
  border-top-left-radius: 5px 20px 5px 20px !important;
  border-top-right-radius: 5px 20px 5px 20px !important; }

.rounded-top-4 {
  border-top-left-radius: 0.4rem !important;
  border-top-right-radius: 0.4rem !important; }

.rounded-top-5 {
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important; }

.rounded-top-8 {
  border-top-left-radius: 0.8rem !important;
  border-top-right-radius: 0.8rem !important; }

.rounded-top-9 {
  border-top-left-radius: 0.9rem !important;
  border-top-right-radius: 0.9rem !important; }

.rounded-top-15 {
  border-top-left-radius: 1.5rem !important;
  border-top-right-radius: 1.5rem !important; }

.rounded-top-16 {
  border-top-left-radius: 1.6rem !important;
  border-top-right-radius: 1.6rem !important; }

.rounded-top-64 {
  border-top-left-radius: 6.4rem !important;
  border-top-right-radius: 6.4rem !important; }

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important; }

.rounded-top-pill {
  border-top-left-radius: 50rem !important;
  border-top-right-radius: 50rem !important; }

.rounded-top-intro {
  border-top-left-radius: 50% 50% 0 0 !important;
  border-top-right-radius: 50% 50% 0 0 !important; }

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important; }

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important; }

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

.z-index-0 {
  z-index: 0 !important; }

.z-index-1 {
  z-index: 1 !important; }

.z-index-2 {
  z-index: 2 !important; }

.z-index-3 {
  z-index: 3 !important; }

.z-index-4 {
  z-index: 4 !important; }

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important; }
  .float-sm-end {
    float: right !important; }
  .float-sm-none {
    float: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-grid {
    display: grid !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; }
  .d-sm-none {
    display: none !important; }
  .position-sm-static {
    position: static !important; }
  .position-sm-relative {
    position: relative !important; }
  .position-sm-absolute {
    position: absolute !important; }
  .position-sm-fixed {
    position: fixed !important; }
  .position-sm-sticky {
    position: sticky !important; }
  .top-sm-0 {
    top: 0 !important; }
  .top-sm-5 {
    top: 5% !important; }
  .top-sm-25 {
    top: 25% !important; }
  .top-sm-50 {
    top: 50% !important; }
  .top-sm-90 {
    top: 90% !important; }
  .top-sm-100 {
    top: 100% !important; }
  .top-sm-10px {
    top: 10px !important; }
  .top-sm-14px {
    top: 14px !important; }
  .top-sm-24px {
    top: 24px !important; }
  .top-sm-50px {
    top: 50px !important; }
  .top-sm-70px {
    top: 70px !important; }
  .top-sm-100px {
    top: 100px !important; }
  .top-sm-120px {
    top: 120px !important; }
  .top-sm-150px {
    top: 150px !important; }
  .top-sm-170px {
    top: 170px !important; }
  .top-sm-200px {
    top: 200px !important; }
  .top-sm-230px {
    top: 230px !important; }
  .top-sm-240px {
    top: 240px !important; }
  .top-sm-250px {
    top: 250px !important; }
  .top-sm-n8px {
    top: -8px !important; }
  .top-sm-n10px {
    top: -10px !important; }
  .top-sm-n19px {
    top: -19px !important; }
  .top-sm-n25px {
    top: -25px !important; }
  .top-sm-n50px {
    top: -50px !important; }
  .top-sm-n90px {
    top: -90px !important; }
  .top-sm-n200px {
    top: -200px !important; }
  .top-sm-n240px {
    top: -240px !important; }
  .top-sm-n340px {
    top: -340px !important; }
  .top-sm-n350px {
    top: -350px !important; }
  .top-sm-100n20 {
    top: calc(100% + 20px) !important; }
  .top-sm-100n50 {
    top: calc(100% - 50px) !important; }
  .top-sm-100n100 {
    top: calc(100% + 100px) !important; }
  .top-sm-100n200 {
    top: calc(100% + 200px) !important; }
  .top-sm-100n250 {
    top: calc(100% + 250px) !important; }
  .top-sm-100n350 {
    top: calc(100% + 350px) !important; }
  .bottom-sm-0 {
    bottom: 0 !important; }
  .bottom-sm-5 {
    bottom: 5% !important; }
  .bottom-sm-25 {
    bottom: 25% !important; }
  .bottom-sm-50 {
    bottom: 50% !important; }
  .bottom-sm-90 {
    bottom: 90% !important; }
  .bottom-sm-100 {
    bottom: 100% !important; }
  .bottom-sm-10px {
    bottom: 10px !important; }
  .bottom-sm-14px {
    bottom: 14px !important; }
  .bottom-sm-24px {
    bottom: 24px !important; }
  .bottom-sm-50px {
    bottom: 50px !important; }
  .bottom-sm-70px {
    bottom: 70px !important; }
  .bottom-sm-100px {
    bottom: 100px !important; }
  .bottom-sm-120px {
    bottom: 120px !important; }
  .bottom-sm-150px {
    bottom: 150px !important; }
  .bottom-sm-170px {
    bottom: 170px !important; }
  .bottom-sm-200px {
    bottom: 200px !important; }
  .bottom-sm-230px {
    bottom: 230px !important; }
  .bottom-sm-240px {
    bottom: 240px !important; }
  .bottom-sm-250px {
    bottom: 250px !important; }
  .bottom-sm-n8px {
    bottom: -8px !important; }
  .bottom-sm-n10px {
    bottom: -10px !important; }
  .bottom-sm-n19px {
    bottom: -19px !important; }
  .bottom-sm-n25px {
    bottom: -25px !important; }
  .bottom-sm-n50px {
    bottom: -50px !important; }
  .bottom-sm-n90px {
    bottom: -90px !important; }
  .bottom-sm-n200px {
    bottom: -200px !important; }
  .bottom-sm-n240px {
    bottom: -240px !important; }
  .bottom-sm-n340px {
    bottom: -340px !important; }
  .bottom-sm-n350px {
    bottom: -350px !important; }
  .bottom-sm-100n20 {
    bottom: calc(100% + 20px) !important; }
  .bottom-sm-100n50 {
    bottom: calc(100% - 50px) !important; }
  .bottom-sm-100n100 {
    bottom: calc(100% + 100px) !important; }
  .bottom-sm-100n200 {
    bottom: calc(100% + 200px) !important; }
  .bottom-sm-100n250 {
    bottom: calc(100% + 250px) !important; }
  .bottom-sm-100n350 {
    bottom: calc(100% + 350px) !important; }
  .start-sm-0 {
    left: 0 !important; }
  .start-sm-5 {
    left: 5% !important; }
  .start-sm-25 {
    left: 25% !important; }
  .start-sm-50 {
    left: 50% !important; }
  .start-sm-90 {
    left: 90% !important; }
  .start-sm-100 {
    left: 100% !important; }
  .start-sm-10px {
    left: 10px !important; }
  .start-sm-14px {
    left: 14px !important; }
  .start-sm-24px {
    left: 24px !important; }
  .start-sm-50px {
    left: 50px !important; }
  .start-sm-70px {
    left: 70px !important; }
  .start-sm-100px {
    left: 100px !important; }
  .start-sm-120px {
    left: 120px !important; }
  .start-sm-150px {
    left: 150px !important; }
  .start-sm-170px {
    left: 170px !important; }
  .start-sm-200px {
    left: 200px !important; }
  .start-sm-230px {
    left: 230px !important; }
  .start-sm-240px {
    left: 240px !important; }
  .start-sm-250px {
    left: 250px !important; }
  .start-sm-n8px {
    left: -8px !important; }
  .start-sm-n10px {
    left: -10px !important; }
  .start-sm-n19px {
    left: -19px !important; }
  .start-sm-n25px {
    left: -25px !important; }
  .start-sm-n50px {
    left: -50px !important; }
  .start-sm-n90px {
    left: -90px !important; }
  .start-sm-n200px {
    left: -200px !important; }
  .start-sm-n240px {
    left: -240px !important; }
  .start-sm-n340px {
    left: -340px !important; }
  .start-sm-n350px {
    left: -350px !important; }
  .start-sm-100n20 {
    left: calc(100% + 20px) !important; }
  .start-sm-100n50 {
    left: calc(100% - 50px) !important; }
  .start-sm-100n100 {
    left: calc(100% + 100px) !important; }
  .start-sm-100n200 {
    left: calc(100% + 200px) !important; }
  .start-sm-100n250 {
    left: calc(100% + 250px) !important; }
  .start-sm-100n350 {
    left: calc(100% + 350px) !important; }
  .end-sm-0 {
    right: 0 !important; }
  .end-sm-5 {
    right: 5% !important; }
  .end-sm-25 {
    right: 25% !important; }
  .end-sm-50 {
    right: 50% !important; }
  .end-sm-90 {
    right: 90% !important; }
  .end-sm-100 {
    right: 100% !important; }
  .end-sm-10px {
    right: 10px !important; }
  .end-sm-14px {
    right: 14px !important; }
  .end-sm-24px {
    right: 24px !important; }
  .end-sm-50px {
    right: 50px !important; }
  .end-sm-70px {
    right: 70px !important; }
  .end-sm-100px {
    right: 100px !important; }
  .end-sm-120px {
    right: 120px !important; }
  .end-sm-150px {
    right: 150px !important; }
  .end-sm-170px {
    right: 170px !important; }
  .end-sm-200px {
    right: 200px !important; }
  .end-sm-230px {
    right: 230px !important; }
  .end-sm-240px {
    right: 240px !important; }
  .end-sm-250px {
    right: 250px !important; }
  .end-sm-n8px {
    right: -8px !important; }
  .end-sm-n10px {
    right: -10px !important; }
  .end-sm-n19px {
    right: -19px !important; }
  .end-sm-n25px {
    right: -25px !important; }
  .end-sm-n50px {
    right: -50px !important; }
  .end-sm-n90px {
    right: -90px !important; }
  .end-sm-n200px {
    right: -200px !important; }
  .end-sm-n240px {
    right: -240px !important; }
  .end-sm-n340px {
    right: -340px !important; }
  .end-sm-n350px {
    right: -350px !important; }
  .end-sm-100n20 {
    right: calc(100% + 20px) !important; }
  .end-sm-100n50 {
    right: calc(100% - 50px) !important; }
  .end-sm-100n100 {
    right: calc(100% + 100px) !important; }
  .end-sm-100n200 {
    right: calc(100% + 200px) !important; }
  .end-sm-100n250 {
    right: calc(100% + 250px) !important; }
  .end-sm-100n350 {
    right: calc(100% + 350px) !important; }
  .w-sm-25 {
    width: 25% !important; }
  .w-sm-50 {
    width: 50% !important; }
  .w-sm-75 {
    width: 75% !important; }
  .w-sm-100 {
    width: 100% !important; }
  .w-sm-auto {
    width: auto !important; }
  .w-sm-135px {
    width: 135px !important; }
  .w-sm-150px {
    width: 150px !important; }
  .w-sm-180px {
    width: 180px !important; }
  .w-sm-190px {
    width: 190px !important; }
  .w-sm-200px {
    width: 200px !important; }
  .w-sm-240px {
    width: 240px !important; }
  .w-sm-260px {
    width: 260px !important; }
  .w-sm-296px {
    width: 296px !important; }
  .w-sm-340px {
    width: 340px !important; }
  .w-sm-368px {
    width: 368px !important; }
  .w-sm-400px {
    width: 400px !important; }
  .w-sm-500px {
    width: 500px !important; }
  .w-sm-520px {
    width: 520px !important; }
  .w-sm-800px {
    width: 800px !important; }
  .h-sm-25 {
    height: 25% !important; }
  .h-sm-50 {
    height: 50% !important; }
  .h-sm-75 {
    height: 75% !important; }
  .h-sm-100 {
    height: 100% !important; }
  .h-sm-auto {
    height: auto !important; }
  .h-sm-135px {
    height: 135px !important; }
  .h-sm-150px {
    height: 150px !important; }
  .h-sm-180px {
    height: 180px !important; }
  .h-sm-190px {
    height: 190px !important; }
  .h-sm-200px {
    height: 200px !important; }
  .h-sm-240px {
    height: 240px !important; }
  .h-sm-260px {
    height: 260px !important; }
  .h-sm-296px {
    height: 296px !important; }
  .h-sm-340px {
    height: 340px !important; }
  .h-sm-368px {
    height: 368px !important; }
  .h-sm-400px {
    height: 400px !important; }
  .h-sm-500px {
    height: 500px !important; }
  .h-sm-520px {
    height: 520px !important; }
  .h-sm-800px {
    height: 800px !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; }
  .order-sm-first {
    order: -1 !important; }
  .order-sm-0 {
    order: 0 !important; }
  .order-sm-1 {
    order: 1 !important; }
  .order-sm-2 {
    order: 2 !important; }
  .order-sm-3 {
    order: 3 !important; }
  .order-sm-4 {
    order: 4 !important; }
  .order-sm-5 {
    order: 5 !important; }
  .order-sm-last {
    order: 6 !important; }
  .m-sm-0 {
    margin: 0 !important; }
  .m-sm-1 {
    margin: 0.1rem !important; }
  .m-sm-2 {
    margin: 0.2rem !important; }
  .m-sm-3 {
    margin: 0.3rem !important; }
  .m-sm-4 {
    margin: 0.4rem !important; }
  .m-sm-5 {
    margin: 0.5rem !important; }
  .m-sm-6 {
    margin: 0.6rem !important; }
  .m-sm-8 {
    margin: 0.8rem !important; }
  .m-sm-10 {
    margin: 1rem !important; }
  .m-sm-12 {
    margin: 1.2rem !important; }
  .m-sm-13 {
    margin: 1.3rem !important; }
  .m-sm-15 {
    margin: 1.5rem !important; }
  .m-sm-16 {
    margin: 1.6rem !important; }
  .m-sm-20 {
    margin: 2rem !important; }
  .m-sm-24 {
    margin: 2.4rem !important; }
  .m-sm-25 {
    margin: 2.5rem !important; }
  .m-sm-29 {
    margin: 2.9rem !important; }
  .m-sm-30 {
    margin: 3rem !important; }
  .m-sm-32 {
    margin: 3.2rem !important; }
  .m-sm-35 {
    margin: 3.5rem !important; }
  .m-sm-37 {
    margin: 3.7rem !important; }
  .m-sm-40 {
    margin: 4rem !important; }
  .m-sm-42 {
    margin: 4.2rem !important; }
  .m-sm-44 {
    margin: 4.4rem !important; }
  .m-sm-48 {
    margin: 4.8rem !important; }
  .m-sm-50 {
    margin: 5rem !important; }
  .m-sm-52 {
    margin: 5.2rem !important; }
  .m-sm-55 {
    margin: 5.5rem !important; }
  .m-sm-60 {
    margin: 6rem !important; }
  .m-sm-62 {
    margin: 6.2rem !important; }
  .m-sm-64 {
    margin: 6.4rem !important; }
  .m-sm-65 {
    margin: 6.5rem !important; }
  .m-sm-70 {
    margin: 7rem !important; }
  .m-sm-72 {
    margin: 7.2rem !important; }
  .m-sm-80 {
    margin: 8rem !important; }
  .m-sm-85 {
    margin: 8.5rem !important; }
  .m-sm-95 {
    margin: 9.5rem !important; }
  .m-sm-96 {
    margin: 9.6rem !important; }
  .m-sm-100 {
    margin: 10rem !important; }
  .m-sm-104 {
    margin: 10.4rem !important; }
  .m-sm-108 {
    margin: 10.8rem !important; }
  .m-sm-110 {
    margin: 11rem !important; }
  .m-sm-116 {
    margin: 11.6rem !important; }
  .m-sm-120 {
    margin: 12rem !important; }
  .m-sm-128 {
    margin: 12.8rem !important; }
  .m-sm-140 {
    margin: 14rem !important; }
  .m-sm-148 {
    margin: 14.8rem !important; }
  .m-sm-160 {
    margin: 16rem !important; }
  .m-sm-170 {
    margin: 17rem !important; }
  .m-sm-200 {
    margin: 20rem !important; }
  .m-sm-240 {
    margin: 24rem !important; }
  .m-sm-245 {
    margin: 24.5rem !important; }
  .m-sm-250 {
    margin: 25rem !important; }
  .m-sm-300 {
    margin: 30rem !important; }
  .m-sm-400 {
    margin: 40rem !important; }
  .m-sm-n25 {
    margin: -25% !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-sm-1 {
    margin-right: 0.1rem !important;
    margin-left: 0.1rem !important; }
  .mx-sm-2 {
    margin-right: 0.2rem !important;
    margin-left: 0.2rem !important; }
  .mx-sm-3 {
    margin-right: 0.3rem !important;
    margin-left: 0.3rem !important; }
  .mx-sm-4 {
    margin-right: 0.4rem !important;
    margin-left: 0.4rem !important; }
  .mx-sm-5 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-sm-6 {
    margin-right: 0.6rem !important;
    margin-left: 0.6rem !important; }
  .mx-sm-8 {
    margin-right: 0.8rem !important;
    margin-left: 0.8rem !important; }
  .mx-sm-10 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-sm-12 {
    margin-right: 1.2rem !important;
    margin-left: 1.2rem !important; }
  .mx-sm-13 {
    margin-right: 1.3rem !important;
    margin-left: 1.3rem !important; }
  .mx-sm-15 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-sm-16 {
    margin-right: 1.6rem !important;
    margin-left: 1.6rem !important; }
  .mx-sm-20 {
    margin-right: 2rem !important;
    margin-left: 2rem !important; }
  .mx-sm-24 {
    margin-right: 2.4rem !important;
    margin-left: 2.4rem !important; }
  .mx-sm-25 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important; }
  .mx-sm-29 {
    margin-right: 2.9rem !important;
    margin-left: 2.9rem !important; }
  .mx-sm-30 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-sm-32 {
    margin-right: 3.2rem !important;
    margin-left: 3.2rem !important; }
  .mx-sm-35 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important; }
  .mx-sm-37 {
    margin-right: 3.7rem !important;
    margin-left: 3.7rem !important; }
  .mx-sm-40 {
    margin-right: 4rem !important;
    margin-left: 4rem !important; }
  .mx-sm-42 {
    margin-right: 4.2rem !important;
    margin-left: 4.2rem !important; }
  .mx-sm-44 {
    margin-right: 4.4rem !important;
    margin-left: 4.4rem !important; }
  .mx-sm-48 {
    margin-right: 4.8rem !important;
    margin-left: 4.8rem !important; }
  .mx-sm-50 {
    margin-right: 5rem !important;
    margin-left: 5rem !important; }
  .mx-sm-52 {
    margin-right: 5.2rem !important;
    margin-left: 5.2rem !important; }
  .mx-sm-55 {
    margin-right: 5.5rem !important;
    margin-left: 5.5rem !important; }
  .mx-sm-60 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .mx-sm-62 {
    margin-right: 6.2rem !important;
    margin-left: 6.2rem !important; }
  .mx-sm-64 {
    margin-right: 6.4rem !important;
    margin-left: 6.4rem !important; }
  .mx-sm-65 {
    margin-right: 6.5rem !important;
    margin-left: 6.5rem !important; }
  .mx-sm-70 {
    margin-right: 7rem !important;
    margin-left: 7rem !important; }
  .mx-sm-72 {
    margin-right: 7.2rem !important;
    margin-left: 7.2rem !important; }
  .mx-sm-80 {
    margin-right: 8rem !important;
    margin-left: 8rem !important; }
  .mx-sm-85 {
    margin-right: 8.5rem !important;
    margin-left: 8.5rem !important; }
  .mx-sm-95 {
    margin-right: 9.5rem !important;
    margin-left: 9.5rem !important; }
  .mx-sm-96 {
    margin-right: 9.6rem !important;
    margin-left: 9.6rem !important; }
  .mx-sm-100 {
    margin-right: 10rem !important;
    margin-left: 10rem !important; }
  .mx-sm-104 {
    margin-right: 10.4rem !important;
    margin-left: 10.4rem !important; }
  .mx-sm-108 {
    margin-right: 10.8rem !important;
    margin-left: 10.8rem !important; }
  .mx-sm-110 {
    margin-right: 11rem !important;
    margin-left: 11rem !important; }
  .mx-sm-116 {
    margin-right: 11.6rem !important;
    margin-left: 11.6rem !important; }
  .mx-sm-120 {
    margin-right: 12rem !important;
    margin-left: 12rem !important; }
  .mx-sm-128 {
    margin-right: 12.8rem !important;
    margin-left: 12.8rem !important; }
  .mx-sm-140 {
    margin-right: 14rem !important;
    margin-left: 14rem !important; }
  .mx-sm-148 {
    margin-right: 14.8rem !important;
    margin-left: 14.8rem !important; }
  .mx-sm-160 {
    margin-right: 16rem !important;
    margin-left: 16rem !important; }
  .mx-sm-170 {
    margin-right: 17rem !important;
    margin-left: 17rem !important; }
  .mx-sm-200 {
    margin-right: 20rem !important;
    margin-left: 20rem !important; }
  .mx-sm-240 {
    margin-right: 24rem !important;
    margin-left: 24rem !important; }
  .mx-sm-245 {
    margin-right: 24.5rem !important;
    margin-left: 24.5rem !important; }
  .mx-sm-250 {
    margin-right: 25rem !important;
    margin-left: 25rem !important; }
  .mx-sm-300 {
    margin-right: 30rem !important;
    margin-left: 30rem !important; }
  .mx-sm-400 {
    margin-right: 40rem !important;
    margin-left: 40rem !important; }
  .mx-sm-n25 {
    margin-right: -25% !important;
    margin-left: -25% !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-sm-1 {
    margin-top: 0.1rem !important;
    margin-bottom: 0.1rem !important; }
  .my-sm-2 {
    margin-top: 0.2rem !important;
    margin-bottom: 0.2rem !important; }
  .my-sm-3 {
    margin-top: 0.3rem !important;
    margin-bottom: 0.3rem !important; }
  .my-sm-4 {
    margin-top: 0.4rem !important;
    margin-bottom: 0.4rem !important; }
  .my-sm-5 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-sm-6 {
    margin-top: 0.6rem !important;
    margin-bottom: 0.6rem !important; }
  .my-sm-8 {
    margin-top: 0.8rem !important;
    margin-bottom: 0.8rem !important; }
  .my-sm-10 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-sm-12 {
    margin-top: 1.2rem !important;
    margin-bottom: 1.2rem !important; }
  .my-sm-13 {
    margin-top: 1.3rem !important;
    margin-bottom: 1.3rem !important; }
  .my-sm-15 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-sm-16 {
    margin-top: 1.6rem !important;
    margin-bottom: 1.6rem !important; }
  .my-sm-20 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important; }
  .my-sm-24 {
    margin-top: 2.4rem !important;
    margin-bottom: 2.4rem !important; }
  .my-sm-25 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important; }
  .my-sm-29 {
    margin-top: 2.9rem !important;
    margin-bottom: 2.9rem !important; }
  .my-sm-30 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-sm-32 {
    margin-top: 3.2rem !important;
    margin-bottom: 3.2rem !important; }
  .my-sm-35 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important; }
  .my-sm-37 {
    margin-top: 3.7rem !important;
    margin-bottom: 3.7rem !important; }
  .my-sm-40 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important; }
  .my-sm-42 {
    margin-top: 4.2rem !important;
    margin-bottom: 4.2rem !important; }
  .my-sm-44 {
    margin-top: 4.4rem !important;
    margin-bottom: 4.4rem !important; }
  .my-sm-48 {
    margin-top: 4.8rem !important;
    margin-bottom: 4.8rem !important; }
  .my-sm-50 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important; }
  .my-sm-52 {
    margin-top: 5.2rem !important;
    margin-bottom: 5.2rem !important; }
  .my-sm-55 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important; }
  .my-sm-60 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .my-sm-62 {
    margin-top: 6.2rem !important;
    margin-bottom: 6.2rem !important; }
  .my-sm-64 {
    margin-top: 6.4rem !important;
    margin-bottom: 6.4rem !important; }
  .my-sm-65 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important; }
  .my-sm-70 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important; }
  .my-sm-72 {
    margin-top: 7.2rem !important;
    margin-bottom: 7.2rem !important; }
  .my-sm-80 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important; }
  .my-sm-85 {
    margin-top: 8.5rem !important;
    margin-bottom: 8.5rem !important; }
  .my-sm-95 {
    margin-top: 9.5rem !important;
    margin-bottom: 9.5rem !important; }
  .my-sm-96 {
    margin-top: 9.6rem !important;
    margin-bottom: 9.6rem !important; }
  .my-sm-100 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important; }
  .my-sm-104 {
    margin-top: 10.4rem !important;
    margin-bottom: 10.4rem !important; }
  .my-sm-108 {
    margin-top: 10.8rem !important;
    margin-bottom: 10.8rem !important; }
  .my-sm-110 {
    margin-top: 11rem !important;
    margin-bottom: 11rem !important; }
  .my-sm-116 {
    margin-top: 11.6rem !important;
    margin-bottom: 11.6rem !important; }
  .my-sm-120 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important; }
  .my-sm-128 {
    margin-top: 12.8rem !important;
    margin-bottom: 12.8rem !important; }
  .my-sm-140 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important; }
  .my-sm-148 {
    margin-top: 14.8rem !important;
    margin-bottom: 14.8rem !important; }
  .my-sm-160 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important; }
  .my-sm-170 {
    margin-top: 17rem !important;
    margin-bottom: 17rem !important; }
  .my-sm-200 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important; }
  .my-sm-240 {
    margin-top: 24rem !important;
    margin-bottom: 24rem !important; }
  .my-sm-245 {
    margin-top: 24.5rem !important;
    margin-bottom: 24.5rem !important; }
  .my-sm-250 {
    margin-top: 25rem !important;
    margin-bottom: 25rem !important; }
  .my-sm-300 {
    margin-top: 30rem !important;
    margin-bottom: 30rem !important; }
  .my-sm-400 {
    margin-top: 40rem !important;
    margin-bottom: 40rem !important; }
  .my-sm-n25 {
    margin-top: -25% !important;
    margin-bottom: -25% !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mt-sm-1 {
    margin-top: 0.1rem !important; }
  .mt-sm-2 {
    margin-top: 0.2rem !important; }
  .mt-sm-3 {
    margin-top: 0.3rem !important; }
  .mt-sm-4 {
    margin-top: 0.4rem !important; }
  .mt-sm-5 {
    margin-top: 0.5rem !important; }
  .mt-sm-6 {
    margin-top: 0.6rem !important; }
  .mt-sm-8 {
    margin-top: 0.8rem !important; }
  .mt-sm-10 {
    margin-top: 1rem !important; }
  .mt-sm-12 {
    margin-top: 1.2rem !important; }
  .mt-sm-13 {
    margin-top: 1.3rem !important; }
  .mt-sm-15 {
    margin-top: 1.5rem !important; }
  .mt-sm-16 {
    margin-top: 1.6rem !important; }
  .mt-sm-20 {
    margin-top: 2rem !important; }
  .mt-sm-24 {
    margin-top: 2.4rem !important; }
  .mt-sm-25 {
    margin-top: 2.5rem !important; }
  .mt-sm-29 {
    margin-top: 2.9rem !important; }
  .mt-sm-30 {
    margin-top: 3rem !important; }
  .mt-sm-32 {
    margin-top: 3.2rem !important; }
  .mt-sm-35 {
    margin-top: 3.5rem !important; }
  .mt-sm-37 {
    margin-top: 3.7rem !important; }
  .mt-sm-40 {
    margin-top: 4rem !important; }
  .mt-sm-42 {
    margin-top: 4.2rem !important; }
  .mt-sm-44 {
    margin-top: 4.4rem !important; }
  .mt-sm-48 {
    margin-top: 4.8rem !important; }
  .mt-sm-50 {
    margin-top: 5rem !important; }
  .mt-sm-52 {
    margin-top: 5.2rem !important; }
  .mt-sm-55 {
    margin-top: 5.5rem !important; }
  .mt-sm-60 {
    margin-top: 6rem !important; }
  .mt-sm-62 {
    margin-top: 6.2rem !important; }
  .mt-sm-64 {
    margin-top: 6.4rem !important; }
  .mt-sm-65 {
    margin-top: 6.5rem !important; }
  .mt-sm-70 {
    margin-top: 7rem !important; }
  .mt-sm-72 {
    margin-top: 7.2rem !important; }
  .mt-sm-80 {
    margin-top: 8rem !important; }
  .mt-sm-85 {
    margin-top: 8.5rem !important; }
  .mt-sm-95 {
    margin-top: 9.5rem !important; }
  .mt-sm-96 {
    margin-top: 9.6rem !important; }
  .mt-sm-100 {
    margin-top: 10rem !important; }
  .mt-sm-104 {
    margin-top: 10.4rem !important; }
  .mt-sm-108 {
    margin-top: 10.8rem !important; }
  .mt-sm-110 {
    margin-top: 11rem !important; }
  .mt-sm-116 {
    margin-top: 11.6rem !important; }
  .mt-sm-120 {
    margin-top: 12rem !important; }
  .mt-sm-128 {
    margin-top: 12.8rem !important; }
  .mt-sm-140 {
    margin-top: 14rem !important; }
  .mt-sm-148 {
    margin-top: 14.8rem !important; }
  .mt-sm-160 {
    margin-top: 16rem !important; }
  .mt-sm-170 {
    margin-top: 17rem !important; }
  .mt-sm-200 {
    margin-top: 20rem !important; }
  .mt-sm-240 {
    margin-top: 24rem !important; }
  .mt-sm-245 {
    margin-top: 24.5rem !important; }
  .mt-sm-250 {
    margin-top: 25rem !important; }
  .mt-sm-300 {
    margin-top: 30rem !important; }
  .mt-sm-400 {
    margin-top: 40rem !important; }
  .mt-sm-n25 {
    margin-top: -25% !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .me-sm-0 {
    margin-right: 0 !important; }
  .me-sm-1 {
    margin-right: 0.1rem !important; }
  .me-sm-2 {
    margin-right: 0.2rem !important; }
  .me-sm-3 {
    margin-right: 0.3rem !important; }
  .me-sm-4 {
    margin-right: 0.4rem !important; }
  .me-sm-5 {
    margin-right: 0.5rem !important; }
  .me-sm-6 {
    margin-right: 0.6rem !important; }
  .me-sm-8 {
    margin-right: 0.8rem !important; }
  .me-sm-10 {
    margin-right: 1rem !important; }
  .me-sm-12 {
    margin-right: 1.2rem !important; }
  .me-sm-13 {
    margin-right: 1.3rem !important; }
  .me-sm-15 {
    margin-right: 1.5rem !important; }
  .me-sm-16 {
    margin-right: 1.6rem !important; }
  .me-sm-20 {
    margin-right: 2rem !important; }
  .me-sm-24 {
    margin-right: 2.4rem !important; }
  .me-sm-25 {
    margin-right: 2.5rem !important; }
  .me-sm-29 {
    margin-right: 2.9rem !important; }
  .me-sm-30 {
    margin-right: 3rem !important; }
  .me-sm-32 {
    margin-right: 3.2rem !important; }
  .me-sm-35 {
    margin-right: 3.5rem !important; }
  .me-sm-37 {
    margin-right: 3.7rem !important; }
  .me-sm-40 {
    margin-right: 4rem !important; }
  .me-sm-42 {
    margin-right: 4.2rem !important; }
  .me-sm-44 {
    margin-right: 4.4rem !important; }
  .me-sm-48 {
    margin-right: 4.8rem !important; }
  .me-sm-50 {
    margin-right: 5rem !important; }
  .me-sm-52 {
    margin-right: 5.2rem !important; }
  .me-sm-55 {
    margin-right: 5.5rem !important; }
  .me-sm-60 {
    margin-right: 6rem !important; }
  .me-sm-62 {
    margin-right: 6.2rem !important; }
  .me-sm-64 {
    margin-right: 6.4rem !important; }
  .me-sm-65 {
    margin-right: 6.5rem !important; }
  .me-sm-70 {
    margin-right: 7rem !important; }
  .me-sm-72 {
    margin-right: 7.2rem !important; }
  .me-sm-80 {
    margin-right: 8rem !important; }
  .me-sm-85 {
    margin-right: 8.5rem !important; }
  .me-sm-95 {
    margin-right: 9.5rem !important; }
  .me-sm-96 {
    margin-right: 9.6rem !important; }
  .me-sm-100 {
    margin-right: 10rem !important; }
  .me-sm-104 {
    margin-right: 10.4rem !important; }
  .me-sm-108 {
    margin-right: 10.8rem !important; }
  .me-sm-110 {
    margin-right: 11rem !important; }
  .me-sm-116 {
    margin-right: 11.6rem !important; }
  .me-sm-120 {
    margin-right: 12rem !important; }
  .me-sm-128 {
    margin-right: 12.8rem !important; }
  .me-sm-140 {
    margin-right: 14rem !important; }
  .me-sm-148 {
    margin-right: 14.8rem !important; }
  .me-sm-160 {
    margin-right: 16rem !important; }
  .me-sm-170 {
    margin-right: 17rem !important; }
  .me-sm-200 {
    margin-right: 20rem !important; }
  .me-sm-240 {
    margin-right: 24rem !important; }
  .me-sm-245 {
    margin-right: 24.5rem !important; }
  .me-sm-250 {
    margin-right: 25rem !important; }
  .me-sm-300 {
    margin-right: 30rem !important; }
  .me-sm-400 {
    margin-right: 40rem !important; }
  .me-sm-n25 {
    margin-right: -25% !important; }
  .me-sm-auto {
    margin-right: auto !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .mb-sm-1 {
    margin-bottom: 0.1rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.2rem !important; }
  .mb-sm-3 {
    margin-bottom: 0.3rem !important; }
  .mb-sm-4 {
    margin-bottom: 0.4rem !important; }
  .mb-sm-5 {
    margin-bottom: 0.5rem !important; }
  .mb-sm-6 {
    margin-bottom: 0.6rem !important; }
  .mb-sm-8 {
    margin-bottom: 0.8rem !important; }
  .mb-sm-10 {
    margin-bottom: 1rem !important; }
  .mb-sm-12 {
    margin-bottom: 1.2rem !important; }
  .mb-sm-13 {
    margin-bottom: 1.3rem !important; }
  .mb-sm-15 {
    margin-bottom: 1.5rem !important; }
  .mb-sm-16 {
    margin-bottom: 1.6rem !important; }
  .mb-sm-20 {
    margin-bottom: 2rem !important; }
  .mb-sm-24 {
    margin-bottom: 2.4rem !important; }
  .mb-sm-25 {
    margin-bottom: 2.5rem !important; }
  .mb-sm-29 {
    margin-bottom: 2.9rem !important; }
  .mb-sm-30 {
    margin-bottom: 3rem !important; }
  .mb-sm-32 {
    margin-bottom: 3.2rem !important; }
  .mb-sm-35 {
    margin-bottom: 3.5rem !important; }
  .mb-sm-37 {
    margin-bottom: 3.7rem !important; }
  .mb-sm-40 {
    margin-bottom: 4rem !important; }
  .mb-sm-42 {
    margin-bottom: 4.2rem !important; }
  .mb-sm-44 {
    margin-bottom: 4.4rem !important; }
  .mb-sm-48 {
    margin-bottom: 4.8rem !important; }
  .mb-sm-50 {
    margin-bottom: 5rem !important; }
  .mb-sm-52 {
    margin-bottom: 5.2rem !important; }
  .mb-sm-55 {
    margin-bottom: 5.5rem !important; }
  .mb-sm-60 {
    margin-bottom: 6rem !important; }
  .mb-sm-62 {
    margin-bottom: 6.2rem !important; }
  .mb-sm-64 {
    margin-bottom: 6.4rem !important; }
  .mb-sm-65 {
    margin-bottom: 6.5rem !important; }
  .mb-sm-70 {
    margin-bottom: 7rem !important; }
  .mb-sm-72 {
    margin-bottom: 7.2rem !important; }
  .mb-sm-80 {
    margin-bottom: 8rem !important; }
  .mb-sm-85 {
    margin-bottom: 8.5rem !important; }
  .mb-sm-95 {
    margin-bottom: 9.5rem !important; }
  .mb-sm-96 {
    margin-bottom: 9.6rem !important; }
  .mb-sm-100 {
    margin-bottom: 10rem !important; }
  .mb-sm-104 {
    margin-bottom: 10.4rem !important; }
  .mb-sm-108 {
    margin-bottom: 10.8rem !important; }
  .mb-sm-110 {
    margin-bottom: 11rem !important; }
  .mb-sm-116 {
    margin-bottom: 11.6rem !important; }
  .mb-sm-120 {
    margin-bottom: 12rem !important; }
  .mb-sm-128 {
    margin-bottom: 12.8rem !important; }
  .mb-sm-140 {
    margin-bottom: 14rem !important; }
  .mb-sm-148 {
    margin-bottom: 14.8rem !important; }
  .mb-sm-160 {
    margin-bottom: 16rem !important; }
  .mb-sm-170 {
    margin-bottom: 17rem !important; }
  .mb-sm-200 {
    margin-bottom: 20rem !important; }
  .mb-sm-240 {
    margin-bottom: 24rem !important; }
  .mb-sm-245 {
    margin-bottom: 24.5rem !important; }
  .mb-sm-250 {
    margin-bottom: 25rem !important; }
  .mb-sm-300 {
    margin-bottom: 30rem !important; }
  .mb-sm-400 {
    margin-bottom: 40rem !important; }
  .mb-sm-n25 {
    margin-bottom: -25% !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ms-sm-0 {
    margin-left: 0 !important; }
  .ms-sm-1 {
    margin-left: 0.1rem !important; }
  .ms-sm-2 {
    margin-left: 0.2rem !important; }
  .ms-sm-3 {
    margin-left: 0.3rem !important; }
  .ms-sm-4 {
    margin-left: 0.4rem !important; }
  .ms-sm-5 {
    margin-left: 0.5rem !important; }
  .ms-sm-6 {
    margin-left: 0.6rem !important; }
  .ms-sm-8 {
    margin-left: 0.8rem !important; }
  .ms-sm-10 {
    margin-left: 1rem !important; }
  .ms-sm-12 {
    margin-left: 1.2rem !important; }
  .ms-sm-13 {
    margin-left: 1.3rem !important; }
  .ms-sm-15 {
    margin-left: 1.5rem !important; }
  .ms-sm-16 {
    margin-left: 1.6rem !important; }
  .ms-sm-20 {
    margin-left: 2rem !important; }
  .ms-sm-24 {
    margin-left: 2.4rem !important; }
  .ms-sm-25 {
    margin-left: 2.5rem !important; }
  .ms-sm-29 {
    margin-left: 2.9rem !important; }
  .ms-sm-30 {
    margin-left: 3rem !important; }
  .ms-sm-32 {
    margin-left: 3.2rem !important; }
  .ms-sm-35 {
    margin-left: 3.5rem !important; }
  .ms-sm-37 {
    margin-left: 3.7rem !important; }
  .ms-sm-40 {
    margin-left: 4rem !important; }
  .ms-sm-42 {
    margin-left: 4.2rem !important; }
  .ms-sm-44 {
    margin-left: 4.4rem !important; }
  .ms-sm-48 {
    margin-left: 4.8rem !important; }
  .ms-sm-50 {
    margin-left: 5rem !important; }
  .ms-sm-52 {
    margin-left: 5.2rem !important; }
  .ms-sm-55 {
    margin-left: 5.5rem !important; }
  .ms-sm-60 {
    margin-left: 6rem !important; }
  .ms-sm-62 {
    margin-left: 6.2rem !important; }
  .ms-sm-64 {
    margin-left: 6.4rem !important; }
  .ms-sm-65 {
    margin-left: 6.5rem !important; }
  .ms-sm-70 {
    margin-left: 7rem !important; }
  .ms-sm-72 {
    margin-left: 7.2rem !important; }
  .ms-sm-80 {
    margin-left: 8rem !important; }
  .ms-sm-85 {
    margin-left: 8.5rem !important; }
  .ms-sm-95 {
    margin-left: 9.5rem !important; }
  .ms-sm-96 {
    margin-left: 9.6rem !important; }
  .ms-sm-100 {
    margin-left: 10rem !important; }
  .ms-sm-104 {
    margin-left: 10.4rem !important; }
  .ms-sm-108 {
    margin-left: 10.8rem !important; }
  .ms-sm-110 {
    margin-left: 11rem !important; }
  .ms-sm-116 {
    margin-left: 11.6rem !important; }
  .ms-sm-120 {
    margin-left: 12rem !important; }
  .ms-sm-128 {
    margin-left: 12.8rem !important; }
  .ms-sm-140 {
    margin-left: 14rem !important; }
  .ms-sm-148 {
    margin-left: 14.8rem !important; }
  .ms-sm-160 {
    margin-left: 16rem !important; }
  .ms-sm-170 {
    margin-left: 17rem !important; }
  .ms-sm-200 {
    margin-left: 20rem !important; }
  .ms-sm-240 {
    margin-left: 24rem !important; }
  .ms-sm-245 {
    margin-left: 24.5rem !important; }
  .ms-sm-250 {
    margin-left: 25rem !important; }
  .ms-sm-300 {
    margin-left: 30rem !important; }
  .ms-sm-400 {
    margin-left: 40rem !important; }
  .ms-sm-n25 {
    margin-left: -25% !important; }
  .ms-sm-auto {
    margin-left: auto !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .p-sm-1 {
    padding: 0.1rem !important; }
  .p-sm-2 {
    padding: 0.2rem !important; }
  .p-sm-3 {
    padding: 0.3rem !important; }
  .p-sm-4 {
    padding: 0.4rem !important; }
  .p-sm-5 {
    padding: 0.5rem !important; }
  .p-sm-6 {
    padding: 0.6rem !important; }
  .p-sm-8 {
    padding: 0.8rem !important; }
  .p-sm-10 {
    padding: 1rem !important; }
  .p-sm-12 {
    padding: 1.2rem !important; }
  .p-sm-13 {
    padding: 1.3rem !important; }
  .p-sm-15 {
    padding: 1.5rem !important; }
  .p-sm-16 {
    padding: 1.6rem !important; }
  .p-sm-20 {
    padding: 2rem !important; }
  .p-sm-24 {
    padding: 2.4rem !important; }
  .p-sm-25 {
    padding: 2.5rem !important; }
  .p-sm-29 {
    padding: 2.9rem !important; }
  .p-sm-30 {
    padding: 3rem !important; }
  .p-sm-32 {
    padding: 3.2rem !important; }
  .p-sm-35 {
    padding: 3.5rem !important; }
  .p-sm-37 {
    padding: 3.7rem !important; }
  .p-sm-40 {
    padding: 4rem !important; }
  .p-sm-42 {
    padding: 4.2rem !important; }
  .p-sm-44 {
    padding: 4.4rem !important; }
  .p-sm-48 {
    padding: 4.8rem !important; }
  .p-sm-50 {
    padding: 5rem !important; }
  .p-sm-52 {
    padding: 5.2rem !important; }
  .p-sm-55 {
    padding: 5.5rem !important; }
  .p-sm-60 {
    padding: 6rem !important; }
  .p-sm-62 {
    padding: 6.2rem !important; }
  .p-sm-64 {
    padding: 6.4rem !important; }
  .p-sm-65 {
    padding: 6.5rem !important; }
  .p-sm-70 {
    padding: 7rem !important; }
  .p-sm-72 {
    padding: 7.2rem !important; }
  .p-sm-80 {
    padding: 8rem !important; }
  .p-sm-85 {
    padding: 8.5rem !important; }
  .p-sm-95 {
    padding: 9.5rem !important; }
  .p-sm-96 {
    padding: 9.6rem !important; }
  .p-sm-100 {
    padding: 10rem !important; }
  .p-sm-104 {
    padding: 10.4rem !important; }
  .p-sm-108 {
    padding: 10.8rem !important; }
  .p-sm-110 {
    padding: 11rem !important; }
  .p-sm-116 {
    padding: 11.6rem !important; }
  .p-sm-120 {
    padding: 12rem !important; }
  .p-sm-128 {
    padding: 12.8rem !important; }
  .p-sm-140 {
    padding: 14rem !important; }
  .p-sm-148 {
    padding: 14.8rem !important; }
  .p-sm-160 {
    padding: 16rem !important; }
  .p-sm-170 {
    padding: 17rem !important; }
  .p-sm-200 {
    padding: 20rem !important; }
  .p-sm-240 {
    padding: 24rem !important; }
  .p-sm-245 {
    padding: 24.5rem !important; }
  .p-sm-250 {
    padding: 25rem !important; }
  .p-sm-300 {
    padding: 30rem !important; }
  .p-sm-400 {
    padding: 40rem !important; }
  .p-sm-n25 {
    padding: -25% !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-sm-1 {
    padding-right: 0.1rem !important;
    padding-left: 0.1rem !important; }
  .px-sm-2 {
    padding-right: 0.2rem !important;
    padding-left: 0.2rem !important; }
  .px-sm-3 {
    padding-right: 0.3rem !important;
    padding-left: 0.3rem !important; }
  .px-sm-4 {
    padding-right: 0.4rem !important;
    padding-left: 0.4rem !important; }
  .px-sm-5 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-sm-6 {
    padding-right: 0.6rem !important;
    padding-left: 0.6rem !important; }
  .px-sm-8 {
    padding-right: 0.8rem !important;
    padding-left: 0.8rem !important; }
  .px-sm-10 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-sm-12 {
    padding-right: 1.2rem !important;
    padding-left: 1.2rem !important; }
  .px-sm-13 {
    padding-right: 1.3rem !important;
    padding-left: 1.3rem !important; }
  .px-sm-15 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-sm-16 {
    padding-right: 1.6rem !important;
    padding-left: 1.6rem !important; }
  .px-sm-20 {
    padding-right: 2rem !important;
    padding-left: 2rem !important; }
  .px-sm-24 {
    padding-right: 2.4rem !important;
    padding-left: 2.4rem !important; }
  .px-sm-25 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important; }
  .px-sm-29 {
    padding-right: 2.9rem !important;
    padding-left: 2.9rem !important; }
  .px-sm-30 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .px-sm-32 {
    padding-right: 3.2rem !important;
    padding-left: 3.2rem !important; }
  .px-sm-35 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important; }
  .px-sm-37 {
    padding-right: 3.7rem !important;
    padding-left: 3.7rem !important; }
  .px-sm-40 {
    padding-right: 4rem !important;
    padding-left: 4rem !important; }
  .px-sm-42 {
    padding-right: 4.2rem !important;
    padding-left: 4.2rem !important; }
  .px-sm-44 {
    padding-right: 4.4rem !important;
    padding-left: 4.4rem !important; }
  .px-sm-48 {
    padding-right: 4.8rem !important;
    padding-left: 4.8rem !important; }
  .px-sm-50 {
    padding-right: 5rem !important;
    padding-left: 5rem !important; }
  .px-sm-52 {
    padding-right: 5.2rem !important;
    padding-left: 5.2rem !important; }
  .px-sm-55 {
    padding-right: 5.5rem !important;
    padding-left: 5.5rem !important; }
  .px-sm-60 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .px-sm-62 {
    padding-right: 6.2rem !important;
    padding-left: 6.2rem !important; }
  .px-sm-64 {
    padding-right: 6.4rem !important;
    padding-left: 6.4rem !important; }
  .px-sm-65 {
    padding-right: 6.5rem !important;
    padding-left: 6.5rem !important; }
  .px-sm-70 {
    padding-right: 7rem !important;
    padding-left: 7rem !important; }
  .px-sm-72 {
    padding-right: 7.2rem !important;
    padding-left: 7.2rem !important; }
  .px-sm-80 {
    padding-right: 8rem !important;
    padding-left: 8rem !important; }
  .px-sm-85 {
    padding-right: 8.5rem !important;
    padding-left: 8.5rem !important; }
  .px-sm-95 {
    padding-right: 9.5rem !important;
    padding-left: 9.5rem !important; }
  .px-sm-96 {
    padding-right: 9.6rem !important;
    padding-left: 9.6rem !important; }
  .px-sm-100 {
    padding-right: 10rem !important;
    padding-left: 10rem !important; }
  .px-sm-104 {
    padding-right: 10.4rem !important;
    padding-left: 10.4rem !important; }
  .px-sm-108 {
    padding-right: 10.8rem !important;
    padding-left: 10.8rem !important; }
  .px-sm-110 {
    padding-right: 11rem !important;
    padding-left: 11rem !important; }
  .px-sm-116 {
    padding-right: 11.6rem !important;
    padding-left: 11.6rem !important; }
  .px-sm-120 {
    padding-right: 12rem !important;
    padding-left: 12rem !important; }
  .px-sm-128 {
    padding-right: 12.8rem !important;
    padding-left: 12.8rem !important; }
  .px-sm-140 {
    padding-right: 14rem !important;
    padding-left: 14rem !important; }
  .px-sm-148 {
    padding-right: 14.8rem !important;
    padding-left: 14.8rem !important; }
  .px-sm-160 {
    padding-right: 16rem !important;
    padding-left: 16rem !important; }
  .px-sm-170 {
    padding-right: 17rem !important;
    padding-left: 17rem !important; }
  .px-sm-200 {
    padding-right: 20rem !important;
    padding-left: 20rem !important; }
  .px-sm-240 {
    padding-right: 24rem !important;
    padding-left: 24rem !important; }
  .px-sm-245 {
    padding-right: 24.5rem !important;
    padding-left: 24.5rem !important; }
  .px-sm-250 {
    padding-right: 25rem !important;
    padding-left: 25rem !important; }
  .px-sm-300 {
    padding-right: 30rem !important;
    padding-left: 30rem !important; }
  .px-sm-400 {
    padding-right: 40rem !important;
    padding-left: 40rem !important; }
  .px-sm-n25 {
    padding-right: -25% !important;
    padding-left: -25% !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-sm-1 {
    padding-top: 0.1rem !important;
    padding-bottom: 0.1rem !important; }
  .py-sm-2 {
    padding-top: 0.2rem !important;
    padding-bottom: 0.2rem !important; }
  .py-sm-3 {
    padding-top: 0.3rem !important;
    padding-bottom: 0.3rem !important; }
  .py-sm-4 {
    padding-top: 0.4rem !important;
    padding-bottom: 0.4rem !important; }
  .py-sm-5 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-sm-6 {
    padding-top: 0.6rem !important;
    padding-bottom: 0.6rem !important; }
  .py-sm-8 {
    padding-top: 0.8rem !important;
    padding-bottom: 0.8rem !important; }
  .py-sm-10 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-sm-12 {
    padding-top: 1.2rem !important;
    padding-bottom: 1.2rem !important; }
  .py-sm-13 {
    padding-top: 1.3rem !important;
    padding-bottom: 1.3rem !important; }
  .py-sm-15 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-sm-16 {
    padding-top: 1.6rem !important;
    padding-bottom: 1.6rem !important; }
  .py-sm-20 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important; }
  .py-sm-24 {
    padding-top: 2.4rem !important;
    padding-bottom: 2.4rem !important; }
  .py-sm-25 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important; }
  .py-sm-29 {
    padding-top: 2.9rem !important;
    padding-bottom: 2.9rem !important; }
  .py-sm-30 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .py-sm-32 {
    padding-top: 3.2rem !important;
    padding-bottom: 3.2rem !important; }
  .py-sm-35 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important; }
  .py-sm-37 {
    padding-top: 3.7rem !important;
    padding-bottom: 3.7rem !important; }
  .py-sm-40 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important; }
  .py-sm-42 {
    padding-top: 4.2rem !important;
    padding-bottom: 4.2rem !important; }
  .py-sm-44 {
    padding-top: 4.4rem !important;
    padding-bottom: 4.4rem !important; }
  .py-sm-48 {
    padding-top: 4.8rem !important;
    padding-bottom: 4.8rem !important; }
  .py-sm-50 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important; }
  .py-sm-52 {
    padding-top: 5.2rem !important;
    padding-bottom: 5.2rem !important; }
  .py-sm-55 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important; }
  .py-sm-60 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .py-sm-62 {
    padding-top: 6.2rem !important;
    padding-bottom: 6.2rem !important; }
  .py-sm-64 {
    padding-top: 6.4rem !important;
    padding-bottom: 6.4rem !important; }
  .py-sm-65 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important; }
  .py-sm-70 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important; }
  .py-sm-72 {
    padding-top: 7.2rem !important;
    padding-bottom: 7.2rem !important; }
  .py-sm-80 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important; }
  .py-sm-85 {
    padding-top: 8.5rem !important;
    padding-bottom: 8.5rem !important; }
  .py-sm-95 {
    padding-top: 9.5rem !important;
    padding-bottom: 9.5rem !important; }
  .py-sm-96 {
    padding-top: 9.6rem !important;
    padding-bottom: 9.6rem !important; }
  .py-sm-100 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important; }
  .py-sm-104 {
    padding-top: 10.4rem !important;
    padding-bottom: 10.4rem !important; }
  .py-sm-108 {
    padding-top: 10.8rem !important;
    padding-bottom: 10.8rem !important; }
  .py-sm-110 {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important; }
  .py-sm-116 {
    padding-top: 11.6rem !important;
    padding-bottom: 11.6rem !important; }
  .py-sm-120 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important; }
  .py-sm-128 {
    padding-top: 12.8rem !important;
    padding-bottom: 12.8rem !important; }
  .py-sm-140 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important; }
  .py-sm-148 {
    padding-top: 14.8rem !important;
    padding-bottom: 14.8rem !important; }
  .py-sm-160 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important; }
  .py-sm-170 {
    padding-top: 17rem !important;
    padding-bottom: 17rem !important; }
  .py-sm-200 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important; }
  .py-sm-240 {
    padding-top: 24rem !important;
    padding-bottom: 24rem !important; }
  .py-sm-245 {
    padding-top: 24.5rem !important;
    padding-bottom: 24.5rem !important; }
  .py-sm-250 {
    padding-top: 25rem !important;
    padding-bottom: 25rem !important; }
  .py-sm-300 {
    padding-top: 30rem !important;
    padding-bottom: 30rem !important; }
  .py-sm-400 {
    padding-top: 40rem !important;
    padding-bottom: 40rem !important; }
  .py-sm-n25 {
    padding-top: -25% !important;
    padding-bottom: -25% !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pt-sm-1 {
    padding-top: 0.1rem !important; }
  .pt-sm-2 {
    padding-top: 0.2rem !important; }
  .pt-sm-3 {
    padding-top: 0.3rem !important; }
  .pt-sm-4 {
    padding-top: 0.4rem !important; }
  .pt-sm-5 {
    padding-top: 0.5rem !important; }
  .pt-sm-6 {
    padding-top: 0.6rem !important; }
  .pt-sm-8 {
    padding-top: 0.8rem !important; }
  .pt-sm-10 {
    padding-top: 1rem !important; }
  .pt-sm-12 {
    padding-top: 1.2rem !important; }
  .pt-sm-13 {
    padding-top: 1.3rem !important; }
  .pt-sm-15 {
    padding-top: 1.5rem !important; }
  .pt-sm-16 {
    padding-top: 1.6rem !important; }
  .pt-sm-20 {
    padding-top: 2rem !important; }
  .pt-sm-24 {
    padding-top: 2.4rem !important; }
  .pt-sm-25 {
    padding-top: 2.5rem !important; }
  .pt-sm-29 {
    padding-top: 2.9rem !important; }
  .pt-sm-30 {
    padding-top: 3rem !important; }
  .pt-sm-32 {
    padding-top: 3.2rem !important; }
  .pt-sm-35 {
    padding-top: 3.5rem !important; }
  .pt-sm-37 {
    padding-top: 3.7rem !important; }
  .pt-sm-40 {
    padding-top: 4rem !important; }
  .pt-sm-42 {
    padding-top: 4.2rem !important; }
  .pt-sm-44 {
    padding-top: 4.4rem !important; }
  .pt-sm-48 {
    padding-top: 4.8rem !important; }
  .pt-sm-50 {
    padding-top: 5rem !important; }
  .pt-sm-52 {
    padding-top: 5.2rem !important; }
  .pt-sm-55 {
    padding-top: 5.5rem !important; }
  .pt-sm-60 {
    padding-top: 6rem !important; }
  .pt-sm-62 {
    padding-top: 6.2rem !important; }
  .pt-sm-64 {
    padding-top: 6.4rem !important; }
  .pt-sm-65 {
    padding-top: 6.5rem !important; }
  .pt-sm-70 {
    padding-top: 7rem !important; }
  .pt-sm-72 {
    padding-top: 7.2rem !important; }
  .pt-sm-80 {
    padding-top: 8rem !important; }
  .pt-sm-85 {
    padding-top: 8.5rem !important; }
  .pt-sm-95 {
    padding-top: 9.5rem !important; }
  .pt-sm-96 {
    padding-top: 9.6rem !important; }
  .pt-sm-100 {
    padding-top: 10rem !important; }
  .pt-sm-104 {
    padding-top: 10.4rem !important; }
  .pt-sm-108 {
    padding-top: 10.8rem !important; }
  .pt-sm-110 {
    padding-top: 11rem !important; }
  .pt-sm-116 {
    padding-top: 11.6rem !important; }
  .pt-sm-120 {
    padding-top: 12rem !important; }
  .pt-sm-128 {
    padding-top: 12.8rem !important; }
  .pt-sm-140 {
    padding-top: 14rem !important; }
  .pt-sm-148 {
    padding-top: 14.8rem !important; }
  .pt-sm-160 {
    padding-top: 16rem !important; }
  .pt-sm-170 {
    padding-top: 17rem !important; }
  .pt-sm-200 {
    padding-top: 20rem !important; }
  .pt-sm-240 {
    padding-top: 24rem !important; }
  .pt-sm-245 {
    padding-top: 24.5rem !important; }
  .pt-sm-250 {
    padding-top: 25rem !important; }
  .pt-sm-300 {
    padding-top: 30rem !important; }
  .pt-sm-400 {
    padding-top: 40rem !important; }
  .pt-sm-n25 {
    padding-top: -25% !important; }
  .pe-sm-0 {
    padding-right: 0 !important; }
  .pe-sm-1 {
    padding-right: 0.1rem !important; }
  .pe-sm-2 {
    padding-right: 0.2rem !important; }
  .pe-sm-3 {
    padding-right: 0.3rem !important; }
  .pe-sm-4 {
    padding-right: 0.4rem !important; }
  .pe-sm-5 {
    padding-right: 0.5rem !important; }
  .pe-sm-6 {
    padding-right: 0.6rem !important; }
  .pe-sm-8 {
    padding-right: 0.8rem !important; }
  .pe-sm-10 {
    padding-right: 1rem !important; }
  .pe-sm-12 {
    padding-right: 1.2rem !important; }
  .pe-sm-13 {
    padding-right: 1.3rem !important; }
  .pe-sm-15 {
    padding-right: 1.5rem !important; }
  .pe-sm-16 {
    padding-right: 1.6rem !important; }
  .pe-sm-20 {
    padding-right: 2rem !important; }
  .pe-sm-24 {
    padding-right: 2.4rem !important; }
  .pe-sm-25 {
    padding-right: 2.5rem !important; }
  .pe-sm-29 {
    padding-right: 2.9rem !important; }
  .pe-sm-30 {
    padding-right: 3rem !important; }
  .pe-sm-32 {
    padding-right: 3.2rem !important; }
  .pe-sm-35 {
    padding-right: 3.5rem !important; }
  .pe-sm-37 {
    padding-right: 3.7rem !important; }
  .pe-sm-40 {
    padding-right: 4rem !important; }
  .pe-sm-42 {
    padding-right: 4.2rem !important; }
  .pe-sm-44 {
    padding-right: 4.4rem !important; }
  .pe-sm-48 {
    padding-right: 4.8rem !important; }
  .pe-sm-50 {
    padding-right: 5rem !important; }
  .pe-sm-52 {
    padding-right: 5.2rem !important; }
  .pe-sm-55 {
    padding-right: 5.5rem !important; }
  .pe-sm-60 {
    padding-right: 6rem !important; }
  .pe-sm-62 {
    padding-right: 6.2rem !important; }
  .pe-sm-64 {
    padding-right: 6.4rem !important; }
  .pe-sm-65 {
    padding-right: 6.5rem !important; }
  .pe-sm-70 {
    padding-right: 7rem !important; }
  .pe-sm-72 {
    padding-right: 7.2rem !important; }
  .pe-sm-80 {
    padding-right: 8rem !important; }
  .pe-sm-85 {
    padding-right: 8.5rem !important; }
  .pe-sm-95 {
    padding-right: 9.5rem !important; }
  .pe-sm-96 {
    padding-right: 9.6rem !important; }
  .pe-sm-100 {
    padding-right: 10rem !important; }
  .pe-sm-104 {
    padding-right: 10.4rem !important; }
  .pe-sm-108 {
    padding-right: 10.8rem !important; }
  .pe-sm-110 {
    padding-right: 11rem !important; }
  .pe-sm-116 {
    padding-right: 11.6rem !important; }
  .pe-sm-120 {
    padding-right: 12rem !important; }
  .pe-sm-128 {
    padding-right: 12.8rem !important; }
  .pe-sm-140 {
    padding-right: 14rem !important; }
  .pe-sm-148 {
    padding-right: 14.8rem !important; }
  .pe-sm-160 {
    padding-right: 16rem !important; }
  .pe-sm-170 {
    padding-right: 17rem !important; }
  .pe-sm-200 {
    padding-right: 20rem !important; }
  .pe-sm-240 {
    padding-right: 24rem !important; }
  .pe-sm-245 {
    padding-right: 24.5rem !important; }
  .pe-sm-250 {
    padding-right: 25rem !important; }
  .pe-sm-300 {
    padding-right: 30rem !important; }
  .pe-sm-400 {
    padding-right: 40rem !important; }
  .pe-sm-n25 {
    padding-right: -25% !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pb-sm-1 {
    padding-bottom: 0.1rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.2rem !important; }
  .pb-sm-3 {
    padding-bottom: 0.3rem !important; }
  .pb-sm-4 {
    padding-bottom: 0.4rem !important; }
  .pb-sm-5 {
    padding-bottom: 0.5rem !important; }
  .pb-sm-6 {
    padding-bottom: 0.6rem !important; }
  .pb-sm-8 {
    padding-bottom: 0.8rem !important; }
  .pb-sm-10 {
    padding-bottom: 1rem !important; }
  .pb-sm-12 {
    padding-bottom: 1.2rem !important; }
  .pb-sm-13 {
    padding-bottom: 1.3rem !important; }
  .pb-sm-15 {
    padding-bottom: 1.5rem !important; }
  .pb-sm-16 {
    padding-bottom: 1.6rem !important; }
  .pb-sm-20 {
    padding-bottom: 2rem !important; }
  .pb-sm-24 {
    padding-bottom: 2.4rem !important; }
  .pb-sm-25 {
    padding-bottom: 2.5rem !important; }
  .pb-sm-29 {
    padding-bottom: 2.9rem !important; }
  .pb-sm-30 {
    padding-bottom: 3rem !important; }
  .pb-sm-32 {
    padding-bottom: 3.2rem !important; }
  .pb-sm-35 {
    padding-bottom: 3.5rem !important; }
  .pb-sm-37 {
    padding-bottom: 3.7rem !important; }
  .pb-sm-40 {
    padding-bottom: 4rem !important; }
  .pb-sm-42 {
    padding-bottom: 4.2rem !important; }
  .pb-sm-44 {
    padding-bottom: 4.4rem !important; }
  .pb-sm-48 {
    padding-bottom: 4.8rem !important; }
  .pb-sm-50 {
    padding-bottom: 5rem !important; }
  .pb-sm-52 {
    padding-bottom: 5.2rem !important; }
  .pb-sm-55 {
    padding-bottom: 5.5rem !important; }
  .pb-sm-60 {
    padding-bottom: 6rem !important; }
  .pb-sm-62 {
    padding-bottom: 6.2rem !important; }
  .pb-sm-64 {
    padding-bottom: 6.4rem !important; }
  .pb-sm-65 {
    padding-bottom: 6.5rem !important; }
  .pb-sm-70 {
    padding-bottom: 7rem !important; }
  .pb-sm-72 {
    padding-bottom: 7.2rem !important; }
  .pb-sm-80 {
    padding-bottom: 8rem !important; }
  .pb-sm-85 {
    padding-bottom: 8.5rem !important; }
  .pb-sm-95 {
    padding-bottom: 9.5rem !important; }
  .pb-sm-96 {
    padding-bottom: 9.6rem !important; }
  .pb-sm-100 {
    padding-bottom: 10rem !important; }
  .pb-sm-104 {
    padding-bottom: 10.4rem !important; }
  .pb-sm-108 {
    padding-bottom: 10.8rem !important; }
  .pb-sm-110 {
    padding-bottom: 11rem !important; }
  .pb-sm-116 {
    padding-bottom: 11.6rem !important; }
  .pb-sm-120 {
    padding-bottom: 12rem !important; }
  .pb-sm-128 {
    padding-bottom: 12.8rem !important; }
  .pb-sm-140 {
    padding-bottom: 14rem !important; }
  .pb-sm-148 {
    padding-bottom: 14.8rem !important; }
  .pb-sm-160 {
    padding-bottom: 16rem !important; }
  .pb-sm-170 {
    padding-bottom: 17rem !important; }
  .pb-sm-200 {
    padding-bottom: 20rem !important; }
  .pb-sm-240 {
    padding-bottom: 24rem !important; }
  .pb-sm-245 {
    padding-bottom: 24.5rem !important; }
  .pb-sm-250 {
    padding-bottom: 25rem !important; }
  .pb-sm-300 {
    padding-bottom: 30rem !important; }
  .pb-sm-400 {
    padding-bottom: 40rem !important; }
  .pb-sm-n25 {
    padding-bottom: -25% !important; }
  .ps-sm-0 {
    padding-left: 0 !important; }
  .ps-sm-1 {
    padding-left: 0.1rem !important; }
  .ps-sm-2 {
    padding-left: 0.2rem !important; }
  .ps-sm-3 {
    padding-left: 0.3rem !important; }
  .ps-sm-4 {
    padding-left: 0.4rem !important; }
  .ps-sm-5 {
    padding-left: 0.5rem !important; }
  .ps-sm-6 {
    padding-left: 0.6rem !important; }
  .ps-sm-8 {
    padding-left: 0.8rem !important; }
  .ps-sm-10 {
    padding-left: 1rem !important; }
  .ps-sm-12 {
    padding-left: 1.2rem !important; }
  .ps-sm-13 {
    padding-left: 1.3rem !important; }
  .ps-sm-15 {
    padding-left: 1.5rem !important; }
  .ps-sm-16 {
    padding-left: 1.6rem !important; }
  .ps-sm-20 {
    padding-left: 2rem !important; }
  .ps-sm-24 {
    padding-left: 2.4rem !important; }
  .ps-sm-25 {
    padding-left: 2.5rem !important; }
  .ps-sm-29 {
    padding-left: 2.9rem !important; }
  .ps-sm-30 {
    padding-left: 3rem !important; }
  .ps-sm-32 {
    padding-left: 3.2rem !important; }
  .ps-sm-35 {
    padding-left: 3.5rem !important; }
  .ps-sm-37 {
    padding-left: 3.7rem !important; }
  .ps-sm-40 {
    padding-left: 4rem !important; }
  .ps-sm-42 {
    padding-left: 4.2rem !important; }
  .ps-sm-44 {
    padding-left: 4.4rem !important; }
  .ps-sm-48 {
    padding-left: 4.8rem !important; }
  .ps-sm-50 {
    padding-left: 5rem !important; }
  .ps-sm-52 {
    padding-left: 5.2rem !important; }
  .ps-sm-55 {
    padding-left: 5.5rem !important; }
  .ps-sm-60 {
    padding-left: 6rem !important; }
  .ps-sm-62 {
    padding-left: 6.2rem !important; }
  .ps-sm-64 {
    padding-left: 6.4rem !important; }
  .ps-sm-65 {
    padding-left: 6.5rem !important; }
  .ps-sm-70 {
    padding-left: 7rem !important; }
  .ps-sm-72 {
    padding-left: 7.2rem !important; }
  .ps-sm-80 {
    padding-left: 8rem !important; }
  .ps-sm-85 {
    padding-left: 8.5rem !important; }
  .ps-sm-95 {
    padding-left: 9.5rem !important; }
  .ps-sm-96 {
    padding-left: 9.6rem !important; }
  .ps-sm-100 {
    padding-left: 10rem !important; }
  .ps-sm-104 {
    padding-left: 10.4rem !important; }
  .ps-sm-108 {
    padding-left: 10.8rem !important; }
  .ps-sm-110 {
    padding-left: 11rem !important; }
  .ps-sm-116 {
    padding-left: 11.6rem !important; }
  .ps-sm-120 {
    padding-left: 12rem !important; }
  .ps-sm-128 {
    padding-left: 12.8rem !important; }
  .ps-sm-140 {
    padding-left: 14rem !important; }
  .ps-sm-148 {
    padding-left: 14.8rem !important; }
  .ps-sm-160 {
    padding-left: 16rem !important; }
  .ps-sm-170 {
    padding-left: 17rem !important; }
  .ps-sm-200 {
    padding-left: 20rem !important; }
  .ps-sm-240 {
    padding-left: 24rem !important; }
  .ps-sm-245 {
    padding-left: 24.5rem !important; }
  .ps-sm-250 {
    padding-left: 25rem !important; }
  .ps-sm-300 {
    padding-left: 30rem !important; }
  .ps-sm-400 {
    padding-left: 40rem !important; }
  .ps-sm-n25 {
    padding-left: -25% !important; }
  .gap-sm-0 {
    gap: 0 !important; }
  .gap-sm-1 {
    gap: 0.1rem !important; }
  .gap-sm-2 {
    gap: 0.2rem !important; }
  .gap-sm-3 {
    gap: 0.3rem !important; }
  .gap-sm-4 {
    gap: 0.4rem !important; }
  .gap-sm-5 {
    gap: 0.5rem !important; }
  .gap-sm-6 {
    gap: 0.6rem !important; }
  .gap-sm-8 {
    gap: 0.8rem !important; }
  .gap-sm-10 {
    gap: 1rem !important; }
  .gap-sm-12 {
    gap: 1.2rem !important; }
  .gap-sm-13 {
    gap: 1.3rem !important; }
  .gap-sm-15 {
    gap: 1.5rem !important; }
  .gap-sm-16 {
    gap: 1.6rem !important; }
  .gap-sm-20 {
    gap: 2rem !important; }
  .gap-sm-24 {
    gap: 2.4rem !important; }
  .gap-sm-25 {
    gap: 2.5rem !important; }
  .gap-sm-29 {
    gap: 2.9rem !important; }
  .gap-sm-30 {
    gap: 3rem !important; }
  .gap-sm-32 {
    gap: 3.2rem !important; }
  .gap-sm-35 {
    gap: 3.5rem !important; }
  .gap-sm-37 {
    gap: 3.7rem !important; }
  .gap-sm-40 {
    gap: 4rem !important; }
  .gap-sm-42 {
    gap: 4.2rem !important; }
  .gap-sm-44 {
    gap: 4.4rem !important; }
  .gap-sm-48 {
    gap: 4.8rem !important; }
  .gap-sm-50 {
    gap: 5rem !important; }
  .gap-sm-52 {
    gap: 5.2rem !important; }
  .gap-sm-55 {
    gap: 5.5rem !important; }
  .gap-sm-60 {
    gap: 6rem !important; }
  .gap-sm-62 {
    gap: 6.2rem !important; }
  .gap-sm-64 {
    gap: 6.4rem !important; }
  .gap-sm-65 {
    gap: 6.5rem !important; }
  .gap-sm-70 {
    gap: 7rem !important; }
  .gap-sm-72 {
    gap: 7.2rem !important; }
  .gap-sm-80 {
    gap: 8rem !important; }
  .gap-sm-85 {
    gap: 8.5rem !important; }
  .gap-sm-95 {
    gap: 9.5rem !important; }
  .gap-sm-96 {
    gap: 9.6rem !important; }
  .gap-sm-100 {
    gap: 10rem !important; }
  .gap-sm-104 {
    gap: 10.4rem !important; }
  .gap-sm-108 {
    gap: 10.8rem !important; }
  .gap-sm-110 {
    gap: 11rem !important; }
  .gap-sm-116 {
    gap: 11.6rem !important; }
  .gap-sm-120 {
    gap: 12rem !important; }
  .gap-sm-128 {
    gap: 12.8rem !important; }
  .gap-sm-140 {
    gap: 14rem !important; }
  .gap-sm-148 {
    gap: 14.8rem !important; }
  .gap-sm-160 {
    gap: 16rem !important; }
  .gap-sm-170 {
    gap: 17rem !important; }
  .gap-sm-200 {
    gap: 20rem !important; }
  .gap-sm-240 {
    gap: 24rem !important; }
  .gap-sm-245 {
    gap: 24.5rem !important; }
  .gap-sm-250 {
    gap: 25rem !important; }
  .gap-sm-300 {
    gap: 30rem !important; }
  .gap-sm-400 {
    gap: 40rem !important; }
  .gap-sm-n25 {
    gap: -25% !important; }
  .font-sm-family-gantari {
    font-family: "Gantari", sans-serif !important; }
  .font-sm-family-roboto {
    font-family: "Roboto", sans-serif !important; }
  .fs-sm-12 {
    font-size: 1.2rem !important; }
  .fs-sm-13 {
    font-size: 1.3rem !important; }
  .fs-sm-14 {
    font-size: 1.4rem !important; }
  .fs-sm-15 {
    font-size: 1.5rem !important; }
  .fs-sm-16 {
    font-size: 1.6rem !important; }
  .fs-sm-18 {
    font-size: 1.8rem !important; }
  .fs-sm-24 {
    font-size: 2.4rem !important; }
  .fs-sm-28 {
    font-size: 2.8rem !important; }
  .fs-sm-30 {
    font-size: 3rem !important; }
  .fs-sm-32 {
    font-size: 3.2rem !important; }
  .fs-sm-40 {
    font-size: 4rem !important; }
  .fs-sm-50 {
    font-size: 5rem !important; }
  .fs-sm-60 {
    font-size: 6rem !important; }
  .text-sm-start {
    text-align: left !important; }
  .text-sm-end {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; }
  .rounded-sm {
    border-radius: 0.8rem !important; }
  .rounded-sm-0 {
    border-radius: 0 !important; }
  .rounded-sm-1 {
    border-radius: 0.6rem !important; }
  .rounded-sm-2 {
    border-radius: 0.8rem !important; }
  .rounded-sm-3 {
    border-radius: 5px 20px 5px 20px !important; }
  .rounded-sm-4 {
    border-radius: 0.4rem !important; }
  .rounded-sm-5 {
    border-radius: 0.5rem !important; }
  .rounded-sm-8 {
    border-radius: 0.8rem !important; }
  .rounded-sm-9 {
    border-radius: 0.9rem !important; }
  .rounded-sm-15 {
    border-radius: 1.5rem !important; }
  .rounded-sm-16 {
    border-radius: 1.6rem !important; }
  .rounded-sm-64 {
    border-radius: 6.4rem !important; }
  .rounded-sm-circle {
    border-radius: 50% !important; }
  .rounded-sm-pill {
    border-radius: 50rem !important; }
  .rounded-sm-intro {
    border-radius: 50% 50% 0 0 !important; }
  .rounded-top-sm {
    border-top-left-radius: 0.8rem !important;
    border-top-right-radius: 0.8rem !important; }
  .rounded-top-sm-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important; }
  .rounded-top-sm-1 {
    border-top-left-radius: 0.6rem !important;
    border-top-right-radius: 0.6rem !important; }
  .rounded-top-sm-2 {
    border-top-left-radius: 0.8rem !important;
    border-top-right-radius: 0.8rem !important; }
  .rounded-top-sm-3 {
    border-top-left-radius: 5px 20px 5px 20px !important;
    border-top-right-radius: 5px 20px 5px 20px !important; }
  .rounded-top-sm-4 {
    border-top-left-radius: 0.4rem !important;
    border-top-right-radius: 0.4rem !important; }
  .rounded-top-sm-5 {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important; }
  .rounded-top-sm-8 {
    border-top-left-radius: 0.8rem !important;
    border-top-right-radius: 0.8rem !important; }
  .rounded-top-sm-9 {
    border-top-left-radius: 0.9rem !important;
    border-top-right-radius: 0.9rem !important; }
  .rounded-top-sm-15 {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important; }
  .rounded-top-sm-16 {
    border-top-left-radius: 1.6rem !important;
    border-top-right-radius: 1.6rem !important; }
  .rounded-top-sm-64 {
    border-top-left-radius: 6.4rem !important;
    border-top-right-radius: 6.4rem !important; }
  .rounded-top-sm-circle {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important; }
  .rounded-top-sm-pill {
    border-top-left-radius: 50rem !important;
    border-top-right-radius: 50rem !important; }
  .rounded-top-sm-intro {
    border-top-left-radius: 50% 50% 0 0 !important;
    border-top-right-radius: 50% 50% 0 0 !important; } }

@media (min-width: 768px) {
  .float-md-start {
    float: left !important; }
  .float-md-end {
    float: right !important; }
  .float-md-none {
    float: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-grid {
    display: grid !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; }
  .d-md-none {
    display: none !important; }
  .position-md-static {
    position: static !important; }
  .position-md-relative {
    position: relative !important; }
  .position-md-absolute {
    position: absolute !important; }
  .position-md-fixed {
    position: fixed !important; }
  .position-md-sticky {
    position: sticky !important; }
  .top-md-0 {
    top: 0 !important; }
  .top-md-5 {
    top: 5% !important; }
  .top-md-25 {
    top: 25% !important; }
  .top-md-50 {
    top: 50% !important; }
  .top-md-90 {
    top: 90% !important; }
  .top-md-100 {
    top: 100% !important; }
  .top-md-10px {
    top: 10px !important; }
  .top-md-14px {
    top: 14px !important; }
  .top-md-24px {
    top: 24px !important; }
  .top-md-50px {
    top: 50px !important; }
  .top-md-70px {
    top: 70px !important; }
  .top-md-100px {
    top: 100px !important; }
  .top-md-120px {
    top: 120px !important; }
  .top-md-150px {
    top: 150px !important; }
  .top-md-170px {
    top: 170px !important; }
  .top-md-200px {
    top: 200px !important; }
  .top-md-230px {
    top: 230px !important; }
  .top-md-240px {
    top: 240px !important; }
  .top-md-250px {
    top: 250px !important; }
  .top-md-n8px {
    top: -8px !important; }
  .top-md-n10px {
    top: -10px !important; }
  .top-md-n19px {
    top: -19px !important; }
  .top-md-n25px {
    top: -25px !important; }
  .top-md-n50px {
    top: -50px !important; }
  .top-md-n90px {
    top: -90px !important; }
  .top-md-n200px {
    top: -200px !important; }
  .top-md-n240px {
    top: -240px !important; }
  .top-md-n340px {
    top: -340px !important; }
  .top-md-n350px {
    top: -350px !important; }
  .top-md-100n20 {
    top: calc(100% + 20px) !important; }
  .top-md-100n50 {
    top: calc(100% - 50px) !important; }
  .top-md-100n100 {
    top: calc(100% + 100px) !important; }
  .top-md-100n200 {
    top: calc(100% + 200px) !important; }
  .top-md-100n250 {
    top: calc(100% + 250px) !important; }
  .top-md-100n350 {
    top: calc(100% + 350px) !important; }
  .bottom-md-0 {
    bottom: 0 !important; }
  .bottom-md-5 {
    bottom: 5% !important; }
  .bottom-md-25 {
    bottom: 25% !important; }
  .bottom-md-50 {
    bottom: 50% !important; }
  .bottom-md-90 {
    bottom: 90% !important; }
  .bottom-md-100 {
    bottom: 100% !important; }
  .bottom-md-10px {
    bottom: 10px !important; }
  .bottom-md-14px {
    bottom: 14px !important; }
  .bottom-md-24px {
    bottom: 24px !important; }
  .bottom-md-50px {
    bottom: 50px !important; }
  .bottom-md-70px {
    bottom: 70px !important; }
  .bottom-md-100px {
    bottom: 100px !important; }
  .bottom-md-120px {
    bottom: 120px !important; }
  .bottom-md-150px {
    bottom: 150px !important; }
  .bottom-md-170px {
    bottom: 170px !important; }
  .bottom-md-200px {
    bottom: 200px !important; }
  .bottom-md-230px {
    bottom: 230px !important; }
  .bottom-md-240px {
    bottom: 240px !important; }
  .bottom-md-250px {
    bottom: 250px !important; }
  .bottom-md-n8px {
    bottom: -8px !important; }
  .bottom-md-n10px {
    bottom: -10px !important; }
  .bottom-md-n19px {
    bottom: -19px !important; }
  .bottom-md-n25px {
    bottom: -25px !important; }
  .bottom-md-n50px {
    bottom: -50px !important; }
  .bottom-md-n90px {
    bottom: -90px !important; }
  .bottom-md-n200px {
    bottom: -200px !important; }
  .bottom-md-n240px {
    bottom: -240px !important; }
  .bottom-md-n340px {
    bottom: -340px !important; }
  .bottom-md-n350px {
    bottom: -350px !important; }
  .bottom-md-100n20 {
    bottom: calc(100% + 20px) !important; }
  .bottom-md-100n50 {
    bottom: calc(100% - 50px) !important; }
  .bottom-md-100n100 {
    bottom: calc(100% + 100px) !important; }
  .bottom-md-100n200 {
    bottom: calc(100% + 200px) !important; }
  .bottom-md-100n250 {
    bottom: calc(100% + 250px) !important; }
  .bottom-md-100n350 {
    bottom: calc(100% + 350px) !important; }
  .start-md-0 {
    left: 0 !important; }
  .start-md-5 {
    left: 5% !important; }
  .start-md-25 {
    left: 25% !important; }
  .start-md-50 {
    left: 50% !important; }
  .start-md-90 {
    left: 90% !important; }
  .start-md-100 {
    left: 100% !important; }
  .start-md-10px {
    left: 10px !important; }
  .start-md-14px {
    left: 14px !important; }
  .start-md-24px {
    left: 24px !important; }
  .start-md-50px {
    left: 50px !important; }
  .start-md-70px {
    left: 70px !important; }
  .start-md-100px {
    left: 100px !important; }
  .start-md-120px {
    left: 120px !important; }
  .start-md-150px {
    left: 150px !important; }
  .start-md-170px {
    left: 170px !important; }
  .start-md-200px {
    left: 200px !important; }
  .start-md-230px {
    left: 230px !important; }
  .start-md-240px {
    left: 240px !important; }
  .start-md-250px {
    left: 250px !important; }
  .start-md-n8px {
    left: -8px !important; }
  .start-md-n10px {
    left: -10px !important; }
  .start-md-n19px {
    left: -19px !important; }
  .start-md-n25px {
    left: -25px !important; }
  .start-md-n50px {
    left: -50px !important; }
  .start-md-n90px {
    left: -90px !important; }
  .start-md-n200px {
    left: -200px !important; }
  .start-md-n240px {
    left: -240px !important; }
  .start-md-n340px {
    left: -340px !important; }
  .start-md-n350px {
    left: -350px !important; }
  .start-md-100n20 {
    left: calc(100% + 20px) !important; }
  .start-md-100n50 {
    left: calc(100% - 50px) !important; }
  .start-md-100n100 {
    left: calc(100% + 100px) !important; }
  .start-md-100n200 {
    left: calc(100% + 200px) !important; }
  .start-md-100n250 {
    left: calc(100% + 250px) !important; }
  .start-md-100n350 {
    left: calc(100% + 350px) !important; }
  .end-md-0 {
    right: 0 !important; }
  .end-md-5 {
    right: 5% !important; }
  .end-md-25 {
    right: 25% !important; }
  .end-md-50 {
    right: 50% !important; }
  .end-md-90 {
    right: 90% !important; }
  .end-md-100 {
    right: 100% !important; }
  .end-md-10px {
    right: 10px !important; }
  .end-md-14px {
    right: 14px !important; }
  .end-md-24px {
    right: 24px !important; }
  .end-md-50px {
    right: 50px !important; }
  .end-md-70px {
    right: 70px !important; }
  .end-md-100px {
    right: 100px !important; }
  .end-md-120px {
    right: 120px !important; }
  .end-md-150px {
    right: 150px !important; }
  .end-md-170px {
    right: 170px !important; }
  .end-md-200px {
    right: 200px !important; }
  .end-md-230px {
    right: 230px !important; }
  .end-md-240px {
    right: 240px !important; }
  .end-md-250px {
    right: 250px !important; }
  .end-md-n8px {
    right: -8px !important; }
  .end-md-n10px {
    right: -10px !important; }
  .end-md-n19px {
    right: -19px !important; }
  .end-md-n25px {
    right: -25px !important; }
  .end-md-n50px {
    right: -50px !important; }
  .end-md-n90px {
    right: -90px !important; }
  .end-md-n200px {
    right: -200px !important; }
  .end-md-n240px {
    right: -240px !important; }
  .end-md-n340px {
    right: -340px !important; }
  .end-md-n350px {
    right: -350px !important; }
  .end-md-100n20 {
    right: calc(100% + 20px) !important; }
  .end-md-100n50 {
    right: calc(100% - 50px) !important; }
  .end-md-100n100 {
    right: calc(100% + 100px) !important; }
  .end-md-100n200 {
    right: calc(100% + 200px) !important; }
  .end-md-100n250 {
    right: calc(100% + 250px) !important; }
  .end-md-100n350 {
    right: calc(100% + 350px) !important; }
  .w-md-25 {
    width: 25% !important; }
  .w-md-50 {
    width: 50% !important; }
  .w-md-75 {
    width: 75% !important; }
  .w-md-100 {
    width: 100% !important; }
  .w-md-auto {
    width: auto !important; }
  .w-md-135px {
    width: 135px !important; }
  .w-md-150px {
    width: 150px !important; }
  .w-md-180px {
    width: 180px !important; }
  .w-md-190px {
    width: 190px !important; }
  .w-md-200px {
    width: 200px !important; }
  .w-md-240px {
    width: 240px !important; }
  .w-md-260px {
    width: 260px !important; }
  .w-md-296px {
    width: 296px !important; }
  .w-md-340px {
    width: 340px !important; }
  .w-md-368px {
    width: 368px !important; }
  .w-md-400px {
    width: 400px !important; }
  .w-md-500px {
    width: 500px !important; }
  .w-md-520px {
    width: 520px !important; }
  .w-md-800px {
    width: 800px !important; }
  .h-md-25 {
    height: 25% !important; }
  .h-md-50 {
    height: 50% !important; }
  .h-md-75 {
    height: 75% !important; }
  .h-md-100 {
    height: 100% !important; }
  .h-md-auto {
    height: auto !important; }
  .h-md-135px {
    height: 135px !important; }
  .h-md-150px {
    height: 150px !important; }
  .h-md-180px {
    height: 180px !important; }
  .h-md-190px {
    height: 190px !important; }
  .h-md-200px {
    height: 200px !important; }
  .h-md-240px {
    height: 240px !important; }
  .h-md-260px {
    height: 260px !important; }
  .h-md-296px {
    height: 296px !important; }
  .h-md-340px {
    height: 340px !important; }
  .h-md-368px {
    height: 368px !important; }
  .h-md-400px {
    height: 400px !important; }
  .h-md-500px {
    height: 500px !important; }
  .h-md-520px {
    height: 520px !important; }
  .h-md-800px {
    height: 800px !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .justify-content-md-evenly {
    justify-content: space-evenly !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; }
  .order-md-first {
    order: -1 !important; }
  .order-md-0 {
    order: 0 !important; }
  .order-md-1 {
    order: 1 !important; }
  .order-md-2 {
    order: 2 !important; }
  .order-md-3 {
    order: 3 !important; }
  .order-md-4 {
    order: 4 !important; }
  .order-md-5 {
    order: 5 !important; }
  .order-md-last {
    order: 6 !important; }
  .m-md-0 {
    margin: 0 !important; }
  .m-md-1 {
    margin: 0.1rem !important; }
  .m-md-2 {
    margin: 0.2rem !important; }
  .m-md-3 {
    margin: 0.3rem !important; }
  .m-md-4 {
    margin: 0.4rem !important; }
  .m-md-5 {
    margin: 0.5rem !important; }
  .m-md-6 {
    margin: 0.6rem !important; }
  .m-md-8 {
    margin: 0.8rem !important; }
  .m-md-10 {
    margin: 1rem !important; }
  .m-md-12 {
    margin: 1.2rem !important; }
  .m-md-13 {
    margin: 1.3rem !important; }
  .m-md-15 {
    margin: 1.5rem !important; }
  .m-md-16 {
    margin: 1.6rem !important; }
  .m-md-20 {
    margin: 2rem !important; }
  .m-md-24 {
    margin: 2.4rem !important; }
  .m-md-25 {
    margin: 2.5rem !important; }
  .m-md-29 {
    margin: 2.9rem !important; }
  .m-md-30 {
    margin: 3rem !important; }
  .m-md-32 {
    margin: 3.2rem !important; }
  .m-md-35 {
    margin: 3.5rem !important; }
  .m-md-37 {
    margin: 3.7rem !important; }
  .m-md-40 {
    margin: 4rem !important; }
  .m-md-42 {
    margin: 4.2rem !important; }
  .m-md-44 {
    margin: 4.4rem !important; }
  .m-md-48 {
    margin: 4.8rem !important; }
  .m-md-50 {
    margin: 5rem !important; }
  .m-md-52 {
    margin: 5.2rem !important; }
  .m-md-55 {
    margin: 5.5rem !important; }
  .m-md-60 {
    margin: 6rem !important; }
  .m-md-62 {
    margin: 6.2rem !important; }
  .m-md-64 {
    margin: 6.4rem !important; }
  .m-md-65 {
    margin: 6.5rem !important; }
  .m-md-70 {
    margin: 7rem !important; }
  .m-md-72 {
    margin: 7.2rem !important; }
  .m-md-80 {
    margin: 8rem !important; }
  .m-md-85 {
    margin: 8.5rem !important; }
  .m-md-95 {
    margin: 9.5rem !important; }
  .m-md-96 {
    margin: 9.6rem !important; }
  .m-md-100 {
    margin: 10rem !important; }
  .m-md-104 {
    margin: 10.4rem !important; }
  .m-md-108 {
    margin: 10.8rem !important; }
  .m-md-110 {
    margin: 11rem !important; }
  .m-md-116 {
    margin: 11.6rem !important; }
  .m-md-120 {
    margin: 12rem !important; }
  .m-md-128 {
    margin: 12.8rem !important; }
  .m-md-140 {
    margin: 14rem !important; }
  .m-md-148 {
    margin: 14.8rem !important; }
  .m-md-160 {
    margin: 16rem !important; }
  .m-md-170 {
    margin: 17rem !important; }
  .m-md-200 {
    margin: 20rem !important; }
  .m-md-240 {
    margin: 24rem !important; }
  .m-md-245 {
    margin: 24.5rem !important; }
  .m-md-250 {
    margin: 25rem !important; }
  .m-md-300 {
    margin: 30rem !important; }
  .m-md-400 {
    margin: 40rem !important; }
  .m-md-n25 {
    margin: -25% !important; }
  .m-md-auto {
    margin: auto !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-md-1 {
    margin-right: 0.1rem !important;
    margin-left: 0.1rem !important; }
  .mx-md-2 {
    margin-right: 0.2rem !important;
    margin-left: 0.2rem !important; }
  .mx-md-3 {
    margin-right: 0.3rem !important;
    margin-left: 0.3rem !important; }
  .mx-md-4 {
    margin-right: 0.4rem !important;
    margin-left: 0.4rem !important; }
  .mx-md-5 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-md-6 {
    margin-right: 0.6rem !important;
    margin-left: 0.6rem !important; }
  .mx-md-8 {
    margin-right: 0.8rem !important;
    margin-left: 0.8rem !important; }
  .mx-md-10 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-md-12 {
    margin-right: 1.2rem !important;
    margin-left: 1.2rem !important; }
  .mx-md-13 {
    margin-right: 1.3rem !important;
    margin-left: 1.3rem !important; }
  .mx-md-15 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-md-16 {
    margin-right: 1.6rem !important;
    margin-left: 1.6rem !important; }
  .mx-md-20 {
    margin-right: 2rem !important;
    margin-left: 2rem !important; }
  .mx-md-24 {
    margin-right: 2.4rem !important;
    margin-left: 2.4rem !important; }
  .mx-md-25 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important; }
  .mx-md-29 {
    margin-right: 2.9rem !important;
    margin-left: 2.9rem !important; }
  .mx-md-30 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-md-32 {
    margin-right: 3.2rem !important;
    margin-left: 3.2rem !important; }
  .mx-md-35 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important; }
  .mx-md-37 {
    margin-right: 3.7rem !important;
    margin-left: 3.7rem !important; }
  .mx-md-40 {
    margin-right: 4rem !important;
    margin-left: 4rem !important; }
  .mx-md-42 {
    margin-right: 4.2rem !important;
    margin-left: 4.2rem !important; }
  .mx-md-44 {
    margin-right: 4.4rem !important;
    margin-left: 4.4rem !important; }
  .mx-md-48 {
    margin-right: 4.8rem !important;
    margin-left: 4.8rem !important; }
  .mx-md-50 {
    margin-right: 5rem !important;
    margin-left: 5rem !important; }
  .mx-md-52 {
    margin-right: 5.2rem !important;
    margin-left: 5.2rem !important; }
  .mx-md-55 {
    margin-right: 5.5rem !important;
    margin-left: 5.5rem !important; }
  .mx-md-60 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .mx-md-62 {
    margin-right: 6.2rem !important;
    margin-left: 6.2rem !important; }
  .mx-md-64 {
    margin-right: 6.4rem !important;
    margin-left: 6.4rem !important; }
  .mx-md-65 {
    margin-right: 6.5rem !important;
    margin-left: 6.5rem !important; }
  .mx-md-70 {
    margin-right: 7rem !important;
    margin-left: 7rem !important; }
  .mx-md-72 {
    margin-right: 7.2rem !important;
    margin-left: 7.2rem !important; }
  .mx-md-80 {
    margin-right: 8rem !important;
    margin-left: 8rem !important; }
  .mx-md-85 {
    margin-right: 8.5rem !important;
    margin-left: 8.5rem !important; }
  .mx-md-95 {
    margin-right: 9.5rem !important;
    margin-left: 9.5rem !important; }
  .mx-md-96 {
    margin-right: 9.6rem !important;
    margin-left: 9.6rem !important; }
  .mx-md-100 {
    margin-right: 10rem !important;
    margin-left: 10rem !important; }
  .mx-md-104 {
    margin-right: 10.4rem !important;
    margin-left: 10.4rem !important; }
  .mx-md-108 {
    margin-right: 10.8rem !important;
    margin-left: 10.8rem !important; }
  .mx-md-110 {
    margin-right: 11rem !important;
    margin-left: 11rem !important; }
  .mx-md-116 {
    margin-right: 11.6rem !important;
    margin-left: 11.6rem !important; }
  .mx-md-120 {
    margin-right: 12rem !important;
    margin-left: 12rem !important; }
  .mx-md-128 {
    margin-right: 12.8rem !important;
    margin-left: 12.8rem !important; }
  .mx-md-140 {
    margin-right: 14rem !important;
    margin-left: 14rem !important; }
  .mx-md-148 {
    margin-right: 14.8rem !important;
    margin-left: 14.8rem !important; }
  .mx-md-160 {
    margin-right: 16rem !important;
    margin-left: 16rem !important; }
  .mx-md-170 {
    margin-right: 17rem !important;
    margin-left: 17rem !important; }
  .mx-md-200 {
    margin-right: 20rem !important;
    margin-left: 20rem !important; }
  .mx-md-240 {
    margin-right: 24rem !important;
    margin-left: 24rem !important; }
  .mx-md-245 {
    margin-right: 24.5rem !important;
    margin-left: 24.5rem !important; }
  .mx-md-250 {
    margin-right: 25rem !important;
    margin-left: 25rem !important; }
  .mx-md-300 {
    margin-right: 30rem !important;
    margin-left: 30rem !important; }
  .mx-md-400 {
    margin-right: 40rem !important;
    margin-left: 40rem !important; }
  .mx-md-n25 {
    margin-right: -25% !important;
    margin-left: -25% !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-md-1 {
    margin-top: 0.1rem !important;
    margin-bottom: 0.1rem !important; }
  .my-md-2 {
    margin-top: 0.2rem !important;
    margin-bottom: 0.2rem !important; }
  .my-md-3 {
    margin-top: 0.3rem !important;
    margin-bottom: 0.3rem !important; }
  .my-md-4 {
    margin-top: 0.4rem !important;
    margin-bottom: 0.4rem !important; }
  .my-md-5 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-md-6 {
    margin-top: 0.6rem !important;
    margin-bottom: 0.6rem !important; }
  .my-md-8 {
    margin-top: 0.8rem !important;
    margin-bottom: 0.8rem !important; }
  .my-md-10 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-md-12 {
    margin-top: 1.2rem !important;
    margin-bottom: 1.2rem !important; }
  .my-md-13 {
    margin-top: 1.3rem !important;
    margin-bottom: 1.3rem !important; }
  .my-md-15 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-md-16 {
    margin-top: 1.6rem !important;
    margin-bottom: 1.6rem !important; }
  .my-md-20 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important; }
  .my-md-24 {
    margin-top: 2.4rem !important;
    margin-bottom: 2.4rem !important; }
  .my-md-25 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important; }
  .my-md-29 {
    margin-top: 2.9rem !important;
    margin-bottom: 2.9rem !important; }
  .my-md-30 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-md-32 {
    margin-top: 3.2rem !important;
    margin-bottom: 3.2rem !important; }
  .my-md-35 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important; }
  .my-md-37 {
    margin-top: 3.7rem !important;
    margin-bottom: 3.7rem !important; }
  .my-md-40 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important; }
  .my-md-42 {
    margin-top: 4.2rem !important;
    margin-bottom: 4.2rem !important; }
  .my-md-44 {
    margin-top: 4.4rem !important;
    margin-bottom: 4.4rem !important; }
  .my-md-48 {
    margin-top: 4.8rem !important;
    margin-bottom: 4.8rem !important; }
  .my-md-50 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important; }
  .my-md-52 {
    margin-top: 5.2rem !important;
    margin-bottom: 5.2rem !important; }
  .my-md-55 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important; }
  .my-md-60 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .my-md-62 {
    margin-top: 6.2rem !important;
    margin-bottom: 6.2rem !important; }
  .my-md-64 {
    margin-top: 6.4rem !important;
    margin-bottom: 6.4rem !important; }
  .my-md-65 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important; }
  .my-md-70 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important; }
  .my-md-72 {
    margin-top: 7.2rem !important;
    margin-bottom: 7.2rem !important; }
  .my-md-80 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important; }
  .my-md-85 {
    margin-top: 8.5rem !important;
    margin-bottom: 8.5rem !important; }
  .my-md-95 {
    margin-top: 9.5rem !important;
    margin-bottom: 9.5rem !important; }
  .my-md-96 {
    margin-top: 9.6rem !important;
    margin-bottom: 9.6rem !important; }
  .my-md-100 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important; }
  .my-md-104 {
    margin-top: 10.4rem !important;
    margin-bottom: 10.4rem !important; }
  .my-md-108 {
    margin-top: 10.8rem !important;
    margin-bottom: 10.8rem !important; }
  .my-md-110 {
    margin-top: 11rem !important;
    margin-bottom: 11rem !important; }
  .my-md-116 {
    margin-top: 11.6rem !important;
    margin-bottom: 11.6rem !important; }
  .my-md-120 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important; }
  .my-md-128 {
    margin-top: 12.8rem !important;
    margin-bottom: 12.8rem !important; }
  .my-md-140 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important; }
  .my-md-148 {
    margin-top: 14.8rem !important;
    margin-bottom: 14.8rem !important; }
  .my-md-160 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important; }
  .my-md-170 {
    margin-top: 17rem !important;
    margin-bottom: 17rem !important; }
  .my-md-200 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important; }
  .my-md-240 {
    margin-top: 24rem !important;
    margin-bottom: 24rem !important; }
  .my-md-245 {
    margin-top: 24.5rem !important;
    margin-bottom: 24.5rem !important; }
  .my-md-250 {
    margin-top: 25rem !important;
    margin-bottom: 25rem !important; }
  .my-md-300 {
    margin-top: 30rem !important;
    margin-bottom: 30rem !important; }
  .my-md-400 {
    margin-top: 40rem !important;
    margin-bottom: 40rem !important; }
  .my-md-n25 {
    margin-top: -25% !important;
    margin-bottom: -25% !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mt-md-1 {
    margin-top: 0.1rem !important; }
  .mt-md-2 {
    margin-top: 0.2rem !important; }
  .mt-md-3 {
    margin-top: 0.3rem !important; }
  .mt-md-4 {
    margin-top: 0.4rem !important; }
  .mt-md-5 {
    margin-top: 0.5rem !important; }
  .mt-md-6 {
    margin-top: 0.6rem !important; }
  .mt-md-8 {
    margin-top: 0.8rem !important; }
  .mt-md-10 {
    margin-top: 1rem !important; }
  .mt-md-12 {
    margin-top: 1.2rem !important; }
  .mt-md-13 {
    margin-top: 1.3rem !important; }
  .mt-md-15 {
    margin-top: 1.5rem !important; }
  .mt-md-16 {
    margin-top: 1.6rem !important; }
  .mt-md-20 {
    margin-top: 2rem !important; }
  .mt-md-24 {
    margin-top: 2.4rem !important; }
  .mt-md-25 {
    margin-top: 2.5rem !important; }
  .mt-md-29 {
    margin-top: 2.9rem !important; }
  .mt-md-30 {
    margin-top: 3rem !important; }
  .mt-md-32 {
    margin-top: 3.2rem !important; }
  .mt-md-35 {
    margin-top: 3.5rem !important; }
  .mt-md-37 {
    margin-top: 3.7rem !important; }
  .mt-md-40 {
    margin-top: 4rem !important; }
  .mt-md-42 {
    margin-top: 4.2rem !important; }
  .mt-md-44 {
    margin-top: 4.4rem !important; }
  .mt-md-48 {
    margin-top: 4.8rem !important; }
  .mt-md-50 {
    margin-top: 5rem !important; }
  .mt-md-52 {
    margin-top: 5.2rem !important; }
  .mt-md-55 {
    margin-top: 5.5rem !important; }
  .mt-md-60 {
    margin-top: 6rem !important; }
  .mt-md-62 {
    margin-top: 6.2rem !important; }
  .mt-md-64 {
    margin-top: 6.4rem !important; }
  .mt-md-65 {
    margin-top: 6.5rem !important; }
  .mt-md-70 {
    margin-top: 7rem !important; }
  .mt-md-72 {
    margin-top: 7.2rem !important; }
  .mt-md-80 {
    margin-top: 8rem !important; }
  .mt-md-85 {
    margin-top: 8.5rem !important; }
  .mt-md-95 {
    margin-top: 9.5rem !important; }
  .mt-md-96 {
    margin-top: 9.6rem !important; }
  .mt-md-100 {
    margin-top: 10rem !important; }
  .mt-md-104 {
    margin-top: 10.4rem !important; }
  .mt-md-108 {
    margin-top: 10.8rem !important; }
  .mt-md-110 {
    margin-top: 11rem !important; }
  .mt-md-116 {
    margin-top: 11.6rem !important; }
  .mt-md-120 {
    margin-top: 12rem !important; }
  .mt-md-128 {
    margin-top: 12.8rem !important; }
  .mt-md-140 {
    margin-top: 14rem !important; }
  .mt-md-148 {
    margin-top: 14.8rem !important; }
  .mt-md-160 {
    margin-top: 16rem !important; }
  .mt-md-170 {
    margin-top: 17rem !important; }
  .mt-md-200 {
    margin-top: 20rem !important; }
  .mt-md-240 {
    margin-top: 24rem !important; }
  .mt-md-245 {
    margin-top: 24.5rem !important; }
  .mt-md-250 {
    margin-top: 25rem !important; }
  .mt-md-300 {
    margin-top: 30rem !important; }
  .mt-md-400 {
    margin-top: 40rem !important; }
  .mt-md-n25 {
    margin-top: -25% !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .me-md-0 {
    margin-right: 0 !important; }
  .me-md-1 {
    margin-right: 0.1rem !important; }
  .me-md-2 {
    margin-right: 0.2rem !important; }
  .me-md-3 {
    margin-right: 0.3rem !important; }
  .me-md-4 {
    margin-right: 0.4rem !important; }
  .me-md-5 {
    margin-right: 0.5rem !important; }
  .me-md-6 {
    margin-right: 0.6rem !important; }
  .me-md-8 {
    margin-right: 0.8rem !important; }
  .me-md-10 {
    margin-right: 1rem !important; }
  .me-md-12 {
    margin-right: 1.2rem !important; }
  .me-md-13 {
    margin-right: 1.3rem !important; }
  .me-md-15 {
    margin-right: 1.5rem !important; }
  .me-md-16 {
    margin-right: 1.6rem !important; }
  .me-md-20 {
    margin-right: 2rem !important; }
  .me-md-24 {
    margin-right: 2.4rem !important; }
  .me-md-25 {
    margin-right: 2.5rem !important; }
  .me-md-29 {
    margin-right: 2.9rem !important; }
  .me-md-30 {
    margin-right: 3rem !important; }
  .me-md-32 {
    margin-right: 3.2rem !important; }
  .me-md-35 {
    margin-right: 3.5rem !important; }
  .me-md-37 {
    margin-right: 3.7rem !important; }
  .me-md-40 {
    margin-right: 4rem !important; }
  .me-md-42 {
    margin-right: 4.2rem !important; }
  .me-md-44 {
    margin-right: 4.4rem !important; }
  .me-md-48 {
    margin-right: 4.8rem !important; }
  .me-md-50 {
    margin-right: 5rem !important; }
  .me-md-52 {
    margin-right: 5.2rem !important; }
  .me-md-55 {
    margin-right: 5.5rem !important; }
  .me-md-60 {
    margin-right: 6rem !important; }
  .me-md-62 {
    margin-right: 6.2rem !important; }
  .me-md-64 {
    margin-right: 6.4rem !important; }
  .me-md-65 {
    margin-right: 6.5rem !important; }
  .me-md-70 {
    margin-right: 7rem !important; }
  .me-md-72 {
    margin-right: 7.2rem !important; }
  .me-md-80 {
    margin-right: 8rem !important; }
  .me-md-85 {
    margin-right: 8.5rem !important; }
  .me-md-95 {
    margin-right: 9.5rem !important; }
  .me-md-96 {
    margin-right: 9.6rem !important; }
  .me-md-100 {
    margin-right: 10rem !important; }
  .me-md-104 {
    margin-right: 10.4rem !important; }
  .me-md-108 {
    margin-right: 10.8rem !important; }
  .me-md-110 {
    margin-right: 11rem !important; }
  .me-md-116 {
    margin-right: 11.6rem !important; }
  .me-md-120 {
    margin-right: 12rem !important; }
  .me-md-128 {
    margin-right: 12.8rem !important; }
  .me-md-140 {
    margin-right: 14rem !important; }
  .me-md-148 {
    margin-right: 14.8rem !important; }
  .me-md-160 {
    margin-right: 16rem !important; }
  .me-md-170 {
    margin-right: 17rem !important; }
  .me-md-200 {
    margin-right: 20rem !important; }
  .me-md-240 {
    margin-right: 24rem !important; }
  .me-md-245 {
    margin-right: 24.5rem !important; }
  .me-md-250 {
    margin-right: 25rem !important; }
  .me-md-300 {
    margin-right: 30rem !important; }
  .me-md-400 {
    margin-right: 40rem !important; }
  .me-md-n25 {
    margin-right: -25% !important; }
  .me-md-auto {
    margin-right: auto !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .mb-md-1 {
    margin-bottom: 0.1rem !important; }
  .mb-md-2 {
    margin-bottom: 0.2rem !important; }
  .mb-md-3 {
    margin-bottom: 0.3rem !important; }
  .mb-md-4 {
    margin-bottom: 0.4rem !important; }
  .mb-md-5 {
    margin-bottom: 0.5rem !important; }
  .mb-md-6 {
    margin-bottom: 0.6rem !important; }
  .mb-md-8 {
    margin-bottom: 0.8rem !important; }
  .mb-md-10 {
    margin-bottom: 1rem !important; }
  .mb-md-12 {
    margin-bottom: 1.2rem !important; }
  .mb-md-13 {
    margin-bottom: 1.3rem !important; }
  .mb-md-15 {
    margin-bottom: 1.5rem !important; }
  .mb-md-16 {
    margin-bottom: 1.6rem !important; }
  .mb-md-20 {
    margin-bottom: 2rem !important; }
  .mb-md-24 {
    margin-bottom: 2.4rem !important; }
  .mb-md-25 {
    margin-bottom: 2.5rem !important; }
  .mb-md-29 {
    margin-bottom: 2.9rem !important; }
  .mb-md-30 {
    margin-bottom: 3rem !important; }
  .mb-md-32 {
    margin-bottom: 3.2rem !important; }
  .mb-md-35 {
    margin-bottom: 3.5rem !important; }
  .mb-md-37 {
    margin-bottom: 3.7rem !important; }
  .mb-md-40 {
    margin-bottom: 4rem !important; }
  .mb-md-42 {
    margin-bottom: 4.2rem !important; }
  .mb-md-44 {
    margin-bottom: 4.4rem !important; }
  .mb-md-48 {
    margin-bottom: 4.8rem !important; }
  .mb-md-50 {
    margin-bottom: 5rem !important; }
  .mb-md-52 {
    margin-bottom: 5.2rem !important; }
  .mb-md-55 {
    margin-bottom: 5.5rem !important; }
  .mb-md-60 {
    margin-bottom: 6rem !important; }
  .mb-md-62 {
    margin-bottom: 6.2rem !important; }
  .mb-md-64 {
    margin-bottom: 6.4rem !important; }
  .mb-md-65 {
    margin-bottom: 6.5rem !important; }
  .mb-md-70 {
    margin-bottom: 7rem !important; }
  .mb-md-72 {
    margin-bottom: 7.2rem !important; }
  .mb-md-80 {
    margin-bottom: 8rem !important; }
  .mb-md-85 {
    margin-bottom: 8.5rem !important; }
  .mb-md-95 {
    margin-bottom: 9.5rem !important; }
  .mb-md-96 {
    margin-bottom: 9.6rem !important; }
  .mb-md-100 {
    margin-bottom: 10rem !important; }
  .mb-md-104 {
    margin-bottom: 10.4rem !important; }
  .mb-md-108 {
    margin-bottom: 10.8rem !important; }
  .mb-md-110 {
    margin-bottom: 11rem !important; }
  .mb-md-116 {
    margin-bottom: 11.6rem !important; }
  .mb-md-120 {
    margin-bottom: 12rem !important; }
  .mb-md-128 {
    margin-bottom: 12.8rem !important; }
  .mb-md-140 {
    margin-bottom: 14rem !important; }
  .mb-md-148 {
    margin-bottom: 14.8rem !important; }
  .mb-md-160 {
    margin-bottom: 16rem !important; }
  .mb-md-170 {
    margin-bottom: 17rem !important; }
  .mb-md-200 {
    margin-bottom: 20rem !important; }
  .mb-md-240 {
    margin-bottom: 24rem !important; }
  .mb-md-245 {
    margin-bottom: 24.5rem !important; }
  .mb-md-250 {
    margin-bottom: 25rem !important; }
  .mb-md-300 {
    margin-bottom: 30rem !important; }
  .mb-md-400 {
    margin-bottom: 40rem !important; }
  .mb-md-n25 {
    margin-bottom: -25% !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ms-md-0 {
    margin-left: 0 !important; }
  .ms-md-1 {
    margin-left: 0.1rem !important; }
  .ms-md-2 {
    margin-left: 0.2rem !important; }
  .ms-md-3 {
    margin-left: 0.3rem !important; }
  .ms-md-4 {
    margin-left: 0.4rem !important; }
  .ms-md-5 {
    margin-left: 0.5rem !important; }
  .ms-md-6 {
    margin-left: 0.6rem !important; }
  .ms-md-8 {
    margin-left: 0.8rem !important; }
  .ms-md-10 {
    margin-left: 1rem !important; }
  .ms-md-12 {
    margin-left: 1.2rem !important; }
  .ms-md-13 {
    margin-left: 1.3rem !important; }
  .ms-md-15 {
    margin-left: 1.5rem !important; }
  .ms-md-16 {
    margin-left: 1.6rem !important; }
  .ms-md-20 {
    margin-left: 2rem !important; }
  .ms-md-24 {
    margin-left: 2.4rem !important; }
  .ms-md-25 {
    margin-left: 2.5rem !important; }
  .ms-md-29 {
    margin-left: 2.9rem !important; }
  .ms-md-30 {
    margin-left: 3rem !important; }
  .ms-md-32 {
    margin-left: 3.2rem !important; }
  .ms-md-35 {
    margin-left: 3.5rem !important; }
  .ms-md-37 {
    margin-left: 3.7rem !important; }
  .ms-md-40 {
    margin-left: 4rem !important; }
  .ms-md-42 {
    margin-left: 4.2rem !important; }
  .ms-md-44 {
    margin-left: 4.4rem !important; }
  .ms-md-48 {
    margin-left: 4.8rem !important; }
  .ms-md-50 {
    margin-left: 5rem !important; }
  .ms-md-52 {
    margin-left: 5.2rem !important; }
  .ms-md-55 {
    margin-left: 5.5rem !important; }
  .ms-md-60 {
    margin-left: 6rem !important; }
  .ms-md-62 {
    margin-left: 6.2rem !important; }
  .ms-md-64 {
    margin-left: 6.4rem !important; }
  .ms-md-65 {
    margin-left: 6.5rem !important; }
  .ms-md-70 {
    margin-left: 7rem !important; }
  .ms-md-72 {
    margin-left: 7.2rem !important; }
  .ms-md-80 {
    margin-left: 8rem !important; }
  .ms-md-85 {
    margin-left: 8.5rem !important; }
  .ms-md-95 {
    margin-left: 9.5rem !important; }
  .ms-md-96 {
    margin-left: 9.6rem !important; }
  .ms-md-100 {
    margin-left: 10rem !important; }
  .ms-md-104 {
    margin-left: 10.4rem !important; }
  .ms-md-108 {
    margin-left: 10.8rem !important; }
  .ms-md-110 {
    margin-left: 11rem !important; }
  .ms-md-116 {
    margin-left: 11.6rem !important; }
  .ms-md-120 {
    margin-left: 12rem !important; }
  .ms-md-128 {
    margin-left: 12.8rem !important; }
  .ms-md-140 {
    margin-left: 14rem !important; }
  .ms-md-148 {
    margin-left: 14.8rem !important; }
  .ms-md-160 {
    margin-left: 16rem !important; }
  .ms-md-170 {
    margin-left: 17rem !important; }
  .ms-md-200 {
    margin-left: 20rem !important; }
  .ms-md-240 {
    margin-left: 24rem !important; }
  .ms-md-245 {
    margin-left: 24.5rem !important; }
  .ms-md-250 {
    margin-left: 25rem !important; }
  .ms-md-300 {
    margin-left: 30rem !important; }
  .ms-md-400 {
    margin-left: 40rem !important; }
  .ms-md-n25 {
    margin-left: -25% !important; }
  .ms-md-auto {
    margin-left: auto !important; }
  .p-md-0 {
    padding: 0 !important; }
  .p-md-1 {
    padding: 0.1rem !important; }
  .p-md-2 {
    padding: 0.2rem !important; }
  .p-md-3 {
    padding: 0.3rem !important; }
  .p-md-4 {
    padding: 0.4rem !important; }
  .p-md-5 {
    padding: 0.5rem !important; }
  .p-md-6 {
    padding: 0.6rem !important; }
  .p-md-8 {
    padding: 0.8rem !important; }
  .p-md-10 {
    padding: 1rem !important; }
  .p-md-12 {
    padding: 1.2rem !important; }
  .p-md-13 {
    padding: 1.3rem !important; }
  .p-md-15 {
    padding: 1.5rem !important; }
  .p-md-16 {
    padding: 1.6rem !important; }
  .p-md-20 {
    padding: 2rem !important; }
  .p-md-24 {
    padding: 2.4rem !important; }
  .p-md-25 {
    padding: 2.5rem !important; }
  .p-md-29 {
    padding: 2.9rem !important; }
  .p-md-30 {
    padding: 3rem !important; }
  .p-md-32 {
    padding: 3.2rem !important; }
  .p-md-35 {
    padding: 3.5rem !important; }
  .p-md-37 {
    padding: 3.7rem !important; }
  .p-md-40 {
    padding: 4rem !important; }
  .p-md-42 {
    padding: 4.2rem !important; }
  .p-md-44 {
    padding: 4.4rem !important; }
  .p-md-48 {
    padding: 4.8rem !important; }
  .p-md-50 {
    padding: 5rem !important; }
  .p-md-52 {
    padding: 5.2rem !important; }
  .p-md-55 {
    padding: 5.5rem !important; }
  .p-md-60 {
    padding: 6rem !important; }
  .p-md-62 {
    padding: 6.2rem !important; }
  .p-md-64 {
    padding: 6.4rem !important; }
  .p-md-65 {
    padding: 6.5rem !important; }
  .p-md-70 {
    padding: 7rem !important; }
  .p-md-72 {
    padding: 7.2rem !important; }
  .p-md-80 {
    padding: 8rem !important; }
  .p-md-85 {
    padding: 8.5rem !important; }
  .p-md-95 {
    padding: 9.5rem !important; }
  .p-md-96 {
    padding: 9.6rem !important; }
  .p-md-100 {
    padding: 10rem !important; }
  .p-md-104 {
    padding: 10.4rem !important; }
  .p-md-108 {
    padding: 10.8rem !important; }
  .p-md-110 {
    padding: 11rem !important; }
  .p-md-116 {
    padding: 11.6rem !important; }
  .p-md-120 {
    padding: 12rem !important; }
  .p-md-128 {
    padding: 12.8rem !important; }
  .p-md-140 {
    padding: 14rem !important; }
  .p-md-148 {
    padding: 14.8rem !important; }
  .p-md-160 {
    padding: 16rem !important; }
  .p-md-170 {
    padding: 17rem !important; }
  .p-md-200 {
    padding: 20rem !important; }
  .p-md-240 {
    padding: 24rem !important; }
  .p-md-245 {
    padding: 24.5rem !important; }
  .p-md-250 {
    padding: 25rem !important; }
  .p-md-300 {
    padding: 30rem !important; }
  .p-md-400 {
    padding: 40rem !important; }
  .p-md-n25 {
    padding: -25% !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-md-1 {
    padding-right: 0.1rem !important;
    padding-left: 0.1rem !important; }
  .px-md-2 {
    padding-right: 0.2rem !important;
    padding-left: 0.2rem !important; }
  .px-md-3 {
    padding-right: 0.3rem !important;
    padding-left: 0.3rem !important; }
  .px-md-4 {
    padding-right: 0.4rem !important;
    padding-left: 0.4rem !important; }
  .px-md-5 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-md-6 {
    padding-right: 0.6rem !important;
    padding-left: 0.6rem !important; }
  .px-md-8 {
    padding-right: 0.8rem !important;
    padding-left: 0.8rem !important; }
  .px-md-10 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-md-12 {
    padding-right: 1.2rem !important;
    padding-left: 1.2rem !important; }
  .px-md-13 {
    padding-right: 1.3rem !important;
    padding-left: 1.3rem !important; }
  .px-md-15 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-md-16 {
    padding-right: 1.6rem !important;
    padding-left: 1.6rem !important; }
  .px-md-20 {
    padding-right: 2rem !important;
    padding-left: 2rem !important; }
  .px-md-24 {
    padding-right: 2.4rem !important;
    padding-left: 2.4rem !important; }
  .px-md-25 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important; }
  .px-md-29 {
    padding-right: 2.9rem !important;
    padding-left: 2.9rem !important; }
  .px-md-30 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .px-md-32 {
    padding-right: 3.2rem !important;
    padding-left: 3.2rem !important; }
  .px-md-35 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important; }
  .px-md-37 {
    padding-right: 3.7rem !important;
    padding-left: 3.7rem !important; }
  .px-md-40 {
    padding-right: 4rem !important;
    padding-left: 4rem !important; }
  .px-md-42 {
    padding-right: 4.2rem !important;
    padding-left: 4.2rem !important; }
  .px-md-44 {
    padding-right: 4.4rem !important;
    padding-left: 4.4rem !important; }
  .px-md-48 {
    padding-right: 4.8rem !important;
    padding-left: 4.8rem !important; }
  .px-md-50 {
    padding-right: 5rem !important;
    padding-left: 5rem !important; }
  .px-md-52 {
    padding-right: 5.2rem !important;
    padding-left: 5.2rem !important; }
  .px-md-55 {
    padding-right: 5.5rem !important;
    padding-left: 5.5rem !important; }
  .px-md-60 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .px-md-62 {
    padding-right: 6.2rem !important;
    padding-left: 6.2rem !important; }
  .px-md-64 {
    padding-right: 6.4rem !important;
    padding-left: 6.4rem !important; }
  .px-md-65 {
    padding-right: 6.5rem !important;
    padding-left: 6.5rem !important; }
  .px-md-70 {
    padding-right: 7rem !important;
    padding-left: 7rem !important; }
  .px-md-72 {
    padding-right: 7.2rem !important;
    padding-left: 7.2rem !important; }
  .px-md-80 {
    padding-right: 8rem !important;
    padding-left: 8rem !important; }
  .px-md-85 {
    padding-right: 8.5rem !important;
    padding-left: 8.5rem !important; }
  .px-md-95 {
    padding-right: 9.5rem !important;
    padding-left: 9.5rem !important; }
  .px-md-96 {
    padding-right: 9.6rem !important;
    padding-left: 9.6rem !important; }
  .px-md-100 {
    padding-right: 10rem !important;
    padding-left: 10rem !important; }
  .px-md-104 {
    padding-right: 10.4rem !important;
    padding-left: 10.4rem !important; }
  .px-md-108 {
    padding-right: 10.8rem !important;
    padding-left: 10.8rem !important; }
  .px-md-110 {
    padding-right: 11rem !important;
    padding-left: 11rem !important; }
  .px-md-116 {
    padding-right: 11.6rem !important;
    padding-left: 11.6rem !important; }
  .px-md-120 {
    padding-right: 12rem !important;
    padding-left: 12rem !important; }
  .px-md-128 {
    padding-right: 12.8rem !important;
    padding-left: 12.8rem !important; }
  .px-md-140 {
    padding-right: 14rem !important;
    padding-left: 14rem !important; }
  .px-md-148 {
    padding-right: 14.8rem !important;
    padding-left: 14.8rem !important; }
  .px-md-160 {
    padding-right: 16rem !important;
    padding-left: 16rem !important; }
  .px-md-170 {
    padding-right: 17rem !important;
    padding-left: 17rem !important; }
  .px-md-200 {
    padding-right: 20rem !important;
    padding-left: 20rem !important; }
  .px-md-240 {
    padding-right: 24rem !important;
    padding-left: 24rem !important; }
  .px-md-245 {
    padding-right: 24.5rem !important;
    padding-left: 24.5rem !important; }
  .px-md-250 {
    padding-right: 25rem !important;
    padding-left: 25rem !important; }
  .px-md-300 {
    padding-right: 30rem !important;
    padding-left: 30rem !important; }
  .px-md-400 {
    padding-right: 40rem !important;
    padding-left: 40rem !important; }
  .px-md-n25 {
    padding-right: -25% !important;
    padding-left: -25% !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-md-1 {
    padding-top: 0.1rem !important;
    padding-bottom: 0.1rem !important; }
  .py-md-2 {
    padding-top: 0.2rem !important;
    padding-bottom: 0.2rem !important; }
  .py-md-3 {
    padding-top: 0.3rem !important;
    padding-bottom: 0.3rem !important; }
  .py-md-4 {
    padding-top: 0.4rem !important;
    padding-bottom: 0.4rem !important; }
  .py-md-5 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-md-6 {
    padding-top: 0.6rem !important;
    padding-bottom: 0.6rem !important; }
  .py-md-8 {
    padding-top: 0.8rem !important;
    padding-bottom: 0.8rem !important; }
  .py-md-10 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-md-12 {
    padding-top: 1.2rem !important;
    padding-bottom: 1.2rem !important; }
  .py-md-13 {
    padding-top: 1.3rem !important;
    padding-bottom: 1.3rem !important; }
  .py-md-15 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-md-16 {
    padding-top: 1.6rem !important;
    padding-bottom: 1.6rem !important; }
  .py-md-20 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important; }
  .py-md-24 {
    padding-top: 2.4rem !important;
    padding-bottom: 2.4rem !important; }
  .py-md-25 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important; }
  .py-md-29 {
    padding-top: 2.9rem !important;
    padding-bottom: 2.9rem !important; }
  .py-md-30 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .py-md-32 {
    padding-top: 3.2rem !important;
    padding-bottom: 3.2rem !important; }
  .py-md-35 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important; }
  .py-md-37 {
    padding-top: 3.7rem !important;
    padding-bottom: 3.7rem !important; }
  .py-md-40 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important; }
  .py-md-42 {
    padding-top: 4.2rem !important;
    padding-bottom: 4.2rem !important; }
  .py-md-44 {
    padding-top: 4.4rem !important;
    padding-bottom: 4.4rem !important; }
  .py-md-48 {
    padding-top: 4.8rem !important;
    padding-bottom: 4.8rem !important; }
  .py-md-50 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important; }
  .py-md-52 {
    padding-top: 5.2rem !important;
    padding-bottom: 5.2rem !important; }
  .py-md-55 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important; }
  .py-md-60 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .py-md-62 {
    padding-top: 6.2rem !important;
    padding-bottom: 6.2rem !important; }
  .py-md-64 {
    padding-top: 6.4rem !important;
    padding-bottom: 6.4rem !important; }
  .py-md-65 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important; }
  .py-md-70 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important; }
  .py-md-72 {
    padding-top: 7.2rem !important;
    padding-bottom: 7.2rem !important; }
  .py-md-80 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important; }
  .py-md-85 {
    padding-top: 8.5rem !important;
    padding-bottom: 8.5rem !important; }
  .py-md-95 {
    padding-top: 9.5rem !important;
    padding-bottom: 9.5rem !important; }
  .py-md-96 {
    padding-top: 9.6rem !important;
    padding-bottom: 9.6rem !important; }
  .py-md-100 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important; }
  .py-md-104 {
    padding-top: 10.4rem !important;
    padding-bottom: 10.4rem !important; }
  .py-md-108 {
    padding-top: 10.8rem !important;
    padding-bottom: 10.8rem !important; }
  .py-md-110 {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important; }
  .py-md-116 {
    padding-top: 11.6rem !important;
    padding-bottom: 11.6rem !important; }
  .py-md-120 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important; }
  .py-md-128 {
    padding-top: 12.8rem !important;
    padding-bottom: 12.8rem !important; }
  .py-md-140 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important; }
  .py-md-148 {
    padding-top: 14.8rem !important;
    padding-bottom: 14.8rem !important; }
  .py-md-160 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important; }
  .py-md-170 {
    padding-top: 17rem !important;
    padding-bottom: 17rem !important; }
  .py-md-200 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important; }
  .py-md-240 {
    padding-top: 24rem !important;
    padding-bottom: 24rem !important; }
  .py-md-245 {
    padding-top: 24.5rem !important;
    padding-bottom: 24.5rem !important; }
  .py-md-250 {
    padding-top: 25rem !important;
    padding-bottom: 25rem !important; }
  .py-md-300 {
    padding-top: 30rem !important;
    padding-bottom: 30rem !important; }
  .py-md-400 {
    padding-top: 40rem !important;
    padding-bottom: 40rem !important; }
  .py-md-n25 {
    padding-top: -25% !important;
    padding-bottom: -25% !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pt-md-1 {
    padding-top: 0.1rem !important; }
  .pt-md-2 {
    padding-top: 0.2rem !important; }
  .pt-md-3 {
    padding-top: 0.3rem !important; }
  .pt-md-4 {
    padding-top: 0.4rem !important; }
  .pt-md-5 {
    padding-top: 0.5rem !important; }
  .pt-md-6 {
    padding-top: 0.6rem !important; }
  .pt-md-8 {
    padding-top: 0.8rem !important; }
  .pt-md-10 {
    padding-top: 1rem !important; }
  .pt-md-12 {
    padding-top: 1.2rem !important; }
  .pt-md-13 {
    padding-top: 1.3rem !important; }
  .pt-md-15 {
    padding-top: 1.5rem !important; }
  .pt-md-16 {
    padding-top: 1.6rem !important; }
  .pt-md-20 {
    padding-top: 2rem !important; }
  .pt-md-24 {
    padding-top: 2.4rem !important; }
  .pt-md-25 {
    padding-top: 2.5rem !important; }
  .pt-md-29 {
    padding-top: 2.9rem !important; }
  .pt-md-30 {
    padding-top: 3rem !important; }
  .pt-md-32 {
    padding-top: 3.2rem !important; }
  .pt-md-35 {
    padding-top: 3.5rem !important; }
  .pt-md-37 {
    padding-top: 3.7rem !important; }
  .pt-md-40 {
    padding-top: 4rem !important; }
  .pt-md-42 {
    padding-top: 4.2rem !important; }
  .pt-md-44 {
    padding-top: 4.4rem !important; }
  .pt-md-48 {
    padding-top: 4.8rem !important; }
  .pt-md-50 {
    padding-top: 5rem !important; }
  .pt-md-52 {
    padding-top: 5.2rem !important; }
  .pt-md-55 {
    padding-top: 5.5rem !important; }
  .pt-md-60 {
    padding-top: 6rem !important; }
  .pt-md-62 {
    padding-top: 6.2rem !important; }
  .pt-md-64 {
    padding-top: 6.4rem !important; }
  .pt-md-65 {
    padding-top: 6.5rem !important; }
  .pt-md-70 {
    padding-top: 7rem !important; }
  .pt-md-72 {
    padding-top: 7.2rem !important; }
  .pt-md-80 {
    padding-top: 8rem !important; }
  .pt-md-85 {
    padding-top: 8.5rem !important; }
  .pt-md-95 {
    padding-top: 9.5rem !important; }
  .pt-md-96 {
    padding-top: 9.6rem !important; }
  .pt-md-100 {
    padding-top: 10rem !important; }
  .pt-md-104 {
    padding-top: 10.4rem !important; }
  .pt-md-108 {
    padding-top: 10.8rem !important; }
  .pt-md-110 {
    padding-top: 11rem !important; }
  .pt-md-116 {
    padding-top: 11.6rem !important; }
  .pt-md-120 {
    padding-top: 12rem !important; }
  .pt-md-128 {
    padding-top: 12.8rem !important; }
  .pt-md-140 {
    padding-top: 14rem !important; }
  .pt-md-148 {
    padding-top: 14.8rem !important; }
  .pt-md-160 {
    padding-top: 16rem !important; }
  .pt-md-170 {
    padding-top: 17rem !important; }
  .pt-md-200 {
    padding-top: 20rem !important; }
  .pt-md-240 {
    padding-top: 24rem !important; }
  .pt-md-245 {
    padding-top: 24.5rem !important; }
  .pt-md-250 {
    padding-top: 25rem !important; }
  .pt-md-300 {
    padding-top: 30rem !important; }
  .pt-md-400 {
    padding-top: 40rem !important; }
  .pt-md-n25 {
    padding-top: -25% !important; }
  .pe-md-0 {
    padding-right: 0 !important; }
  .pe-md-1 {
    padding-right: 0.1rem !important; }
  .pe-md-2 {
    padding-right: 0.2rem !important; }
  .pe-md-3 {
    padding-right: 0.3rem !important; }
  .pe-md-4 {
    padding-right: 0.4rem !important; }
  .pe-md-5 {
    padding-right: 0.5rem !important; }
  .pe-md-6 {
    padding-right: 0.6rem !important; }
  .pe-md-8 {
    padding-right: 0.8rem !important; }
  .pe-md-10 {
    padding-right: 1rem !important; }
  .pe-md-12 {
    padding-right: 1.2rem !important; }
  .pe-md-13 {
    padding-right: 1.3rem !important; }
  .pe-md-15 {
    padding-right: 1.5rem !important; }
  .pe-md-16 {
    padding-right: 1.6rem !important; }
  .pe-md-20 {
    padding-right: 2rem !important; }
  .pe-md-24 {
    padding-right: 2.4rem !important; }
  .pe-md-25 {
    padding-right: 2.5rem !important; }
  .pe-md-29 {
    padding-right: 2.9rem !important; }
  .pe-md-30 {
    padding-right: 3rem !important; }
  .pe-md-32 {
    padding-right: 3.2rem !important; }
  .pe-md-35 {
    padding-right: 3.5rem !important; }
  .pe-md-37 {
    padding-right: 3.7rem !important; }
  .pe-md-40 {
    padding-right: 4rem !important; }
  .pe-md-42 {
    padding-right: 4.2rem !important; }
  .pe-md-44 {
    padding-right: 4.4rem !important; }
  .pe-md-48 {
    padding-right: 4.8rem !important; }
  .pe-md-50 {
    padding-right: 5rem !important; }
  .pe-md-52 {
    padding-right: 5.2rem !important; }
  .pe-md-55 {
    padding-right: 5.5rem !important; }
  .pe-md-60 {
    padding-right: 6rem !important; }
  .pe-md-62 {
    padding-right: 6.2rem !important; }
  .pe-md-64 {
    padding-right: 6.4rem !important; }
  .pe-md-65 {
    padding-right: 6.5rem !important; }
  .pe-md-70 {
    padding-right: 7rem !important; }
  .pe-md-72 {
    padding-right: 7.2rem !important; }
  .pe-md-80 {
    padding-right: 8rem !important; }
  .pe-md-85 {
    padding-right: 8.5rem !important; }
  .pe-md-95 {
    padding-right: 9.5rem !important; }
  .pe-md-96 {
    padding-right: 9.6rem !important; }
  .pe-md-100 {
    padding-right: 10rem !important; }
  .pe-md-104 {
    padding-right: 10.4rem !important; }
  .pe-md-108 {
    padding-right: 10.8rem !important; }
  .pe-md-110 {
    padding-right: 11rem !important; }
  .pe-md-116 {
    padding-right: 11.6rem !important; }
  .pe-md-120 {
    padding-right: 12rem !important; }
  .pe-md-128 {
    padding-right: 12.8rem !important; }
  .pe-md-140 {
    padding-right: 14rem !important; }
  .pe-md-148 {
    padding-right: 14.8rem !important; }
  .pe-md-160 {
    padding-right: 16rem !important; }
  .pe-md-170 {
    padding-right: 17rem !important; }
  .pe-md-200 {
    padding-right: 20rem !important; }
  .pe-md-240 {
    padding-right: 24rem !important; }
  .pe-md-245 {
    padding-right: 24.5rem !important; }
  .pe-md-250 {
    padding-right: 25rem !important; }
  .pe-md-300 {
    padding-right: 30rem !important; }
  .pe-md-400 {
    padding-right: 40rem !important; }
  .pe-md-n25 {
    padding-right: -25% !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pb-md-1 {
    padding-bottom: 0.1rem !important; }
  .pb-md-2 {
    padding-bottom: 0.2rem !important; }
  .pb-md-3 {
    padding-bottom: 0.3rem !important; }
  .pb-md-4 {
    padding-bottom: 0.4rem !important; }
  .pb-md-5 {
    padding-bottom: 0.5rem !important; }
  .pb-md-6 {
    padding-bottom: 0.6rem !important; }
  .pb-md-8 {
    padding-bottom: 0.8rem !important; }
  .pb-md-10 {
    padding-bottom: 1rem !important; }
  .pb-md-12 {
    padding-bottom: 1.2rem !important; }
  .pb-md-13 {
    padding-bottom: 1.3rem !important; }
  .pb-md-15 {
    padding-bottom: 1.5rem !important; }
  .pb-md-16 {
    padding-bottom: 1.6rem !important; }
  .pb-md-20 {
    padding-bottom: 2rem !important; }
  .pb-md-24 {
    padding-bottom: 2.4rem !important; }
  .pb-md-25 {
    padding-bottom: 2.5rem !important; }
  .pb-md-29 {
    padding-bottom: 2.9rem !important; }
  .pb-md-30 {
    padding-bottom: 3rem !important; }
  .pb-md-32 {
    padding-bottom: 3.2rem !important; }
  .pb-md-35 {
    padding-bottom: 3.5rem !important; }
  .pb-md-37 {
    padding-bottom: 3.7rem !important; }
  .pb-md-40 {
    padding-bottom: 4rem !important; }
  .pb-md-42 {
    padding-bottom: 4.2rem !important; }
  .pb-md-44 {
    padding-bottom: 4.4rem !important; }
  .pb-md-48 {
    padding-bottom: 4.8rem !important; }
  .pb-md-50 {
    padding-bottom: 5rem !important; }
  .pb-md-52 {
    padding-bottom: 5.2rem !important; }
  .pb-md-55 {
    padding-bottom: 5.5rem !important; }
  .pb-md-60 {
    padding-bottom: 6rem !important; }
  .pb-md-62 {
    padding-bottom: 6.2rem !important; }
  .pb-md-64 {
    padding-bottom: 6.4rem !important; }
  .pb-md-65 {
    padding-bottom: 6.5rem !important; }
  .pb-md-70 {
    padding-bottom: 7rem !important; }
  .pb-md-72 {
    padding-bottom: 7.2rem !important; }
  .pb-md-80 {
    padding-bottom: 8rem !important; }
  .pb-md-85 {
    padding-bottom: 8.5rem !important; }
  .pb-md-95 {
    padding-bottom: 9.5rem !important; }
  .pb-md-96 {
    padding-bottom: 9.6rem !important; }
  .pb-md-100 {
    padding-bottom: 10rem !important; }
  .pb-md-104 {
    padding-bottom: 10.4rem !important; }
  .pb-md-108 {
    padding-bottom: 10.8rem !important; }
  .pb-md-110 {
    padding-bottom: 11rem !important; }
  .pb-md-116 {
    padding-bottom: 11.6rem !important; }
  .pb-md-120 {
    padding-bottom: 12rem !important; }
  .pb-md-128 {
    padding-bottom: 12.8rem !important; }
  .pb-md-140 {
    padding-bottom: 14rem !important; }
  .pb-md-148 {
    padding-bottom: 14.8rem !important; }
  .pb-md-160 {
    padding-bottom: 16rem !important; }
  .pb-md-170 {
    padding-bottom: 17rem !important; }
  .pb-md-200 {
    padding-bottom: 20rem !important; }
  .pb-md-240 {
    padding-bottom: 24rem !important; }
  .pb-md-245 {
    padding-bottom: 24.5rem !important; }
  .pb-md-250 {
    padding-bottom: 25rem !important; }
  .pb-md-300 {
    padding-bottom: 30rem !important; }
  .pb-md-400 {
    padding-bottom: 40rem !important; }
  .pb-md-n25 {
    padding-bottom: -25% !important; }
  .ps-md-0 {
    padding-left: 0 !important; }
  .ps-md-1 {
    padding-left: 0.1rem !important; }
  .ps-md-2 {
    padding-left: 0.2rem !important; }
  .ps-md-3 {
    padding-left: 0.3rem !important; }
  .ps-md-4 {
    padding-left: 0.4rem !important; }
  .ps-md-5 {
    padding-left: 0.5rem !important; }
  .ps-md-6 {
    padding-left: 0.6rem !important; }
  .ps-md-8 {
    padding-left: 0.8rem !important; }
  .ps-md-10 {
    padding-left: 1rem !important; }
  .ps-md-12 {
    padding-left: 1.2rem !important; }
  .ps-md-13 {
    padding-left: 1.3rem !important; }
  .ps-md-15 {
    padding-left: 1.5rem !important; }
  .ps-md-16 {
    padding-left: 1.6rem !important; }
  .ps-md-20 {
    padding-left: 2rem !important; }
  .ps-md-24 {
    padding-left: 2.4rem !important; }
  .ps-md-25 {
    padding-left: 2.5rem !important; }
  .ps-md-29 {
    padding-left: 2.9rem !important; }
  .ps-md-30 {
    padding-left: 3rem !important; }
  .ps-md-32 {
    padding-left: 3.2rem !important; }
  .ps-md-35 {
    padding-left: 3.5rem !important; }
  .ps-md-37 {
    padding-left: 3.7rem !important; }
  .ps-md-40 {
    padding-left: 4rem !important; }
  .ps-md-42 {
    padding-left: 4.2rem !important; }
  .ps-md-44 {
    padding-left: 4.4rem !important; }
  .ps-md-48 {
    padding-left: 4.8rem !important; }
  .ps-md-50 {
    padding-left: 5rem !important; }
  .ps-md-52 {
    padding-left: 5.2rem !important; }
  .ps-md-55 {
    padding-left: 5.5rem !important; }
  .ps-md-60 {
    padding-left: 6rem !important; }
  .ps-md-62 {
    padding-left: 6.2rem !important; }
  .ps-md-64 {
    padding-left: 6.4rem !important; }
  .ps-md-65 {
    padding-left: 6.5rem !important; }
  .ps-md-70 {
    padding-left: 7rem !important; }
  .ps-md-72 {
    padding-left: 7.2rem !important; }
  .ps-md-80 {
    padding-left: 8rem !important; }
  .ps-md-85 {
    padding-left: 8.5rem !important; }
  .ps-md-95 {
    padding-left: 9.5rem !important; }
  .ps-md-96 {
    padding-left: 9.6rem !important; }
  .ps-md-100 {
    padding-left: 10rem !important; }
  .ps-md-104 {
    padding-left: 10.4rem !important; }
  .ps-md-108 {
    padding-left: 10.8rem !important; }
  .ps-md-110 {
    padding-left: 11rem !important; }
  .ps-md-116 {
    padding-left: 11.6rem !important; }
  .ps-md-120 {
    padding-left: 12rem !important; }
  .ps-md-128 {
    padding-left: 12.8rem !important; }
  .ps-md-140 {
    padding-left: 14rem !important; }
  .ps-md-148 {
    padding-left: 14.8rem !important; }
  .ps-md-160 {
    padding-left: 16rem !important; }
  .ps-md-170 {
    padding-left: 17rem !important; }
  .ps-md-200 {
    padding-left: 20rem !important; }
  .ps-md-240 {
    padding-left: 24rem !important; }
  .ps-md-245 {
    padding-left: 24.5rem !important; }
  .ps-md-250 {
    padding-left: 25rem !important; }
  .ps-md-300 {
    padding-left: 30rem !important; }
  .ps-md-400 {
    padding-left: 40rem !important; }
  .ps-md-n25 {
    padding-left: -25% !important; }
  .gap-md-0 {
    gap: 0 !important; }
  .gap-md-1 {
    gap: 0.1rem !important; }
  .gap-md-2 {
    gap: 0.2rem !important; }
  .gap-md-3 {
    gap: 0.3rem !important; }
  .gap-md-4 {
    gap: 0.4rem !important; }
  .gap-md-5 {
    gap: 0.5rem !important; }
  .gap-md-6 {
    gap: 0.6rem !important; }
  .gap-md-8 {
    gap: 0.8rem !important; }
  .gap-md-10 {
    gap: 1rem !important; }
  .gap-md-12 {
    gap: 1.2rem !important; }
  .gap-md-13 {
    gap: 1.3rem !important; }
  .gap-md-15 {
    gap: 1.5rem !important; }
  .gap-md-16 {
    gap: 1.6rem !important; }
  .gap-md-20 {
    gap: 2rem !important; }
  .gap-md-24 {
    gap: 2.4rem !important; }
  .gap-md-25 {
    gap: 2.5rem !important; }
  .gap-md-29 {
    gap: 2.9rem !important; }
  .gap-md-30 {
    gap: 3rem !important; }
  .gap-md-32 {
    gap: 3.2rem !important; }
  .gap-md-35 {
    gap: 3.5rem !important; }
  .gap-md-37 {
    gap: 3.7rem !important; }
  .gap-md-40 {
    gap: 4rem !important; }
  .gap-md-42 {
    gap: 4.2rem !important; }
  .gap-md-44 {
    gap: 4.4rem !important; }
  .gap-md-48 {
    gap: 4.8rem !important; }
  .gap-md-50 {
    gap: 5rem !important; }
  .gap-md-52 {
    gap: 5.2rem !important; }
  .gap-md-55 {
    gap: 5.5rem !important; }
  .gap-md-60 {
    gap: 6rem !important; }
  .gap-md-62 {
    gap: 6.2rem !important; }
  .gap-md-64 {
    gap: 6.4rem !important; }
  .gap-md-65 {
    gap: 6.5rem !important; }
  .gap-md-70 {
    gap: 7rem !important; }
  .gap-md-72 {
    gap: 7.2rem !important; }
  .gap-md-80 {
    gap: 8rem !important; }
  .gap-md-85 {
    gap: 8.5rem !important; }
  .gap-md-95 {
    gap: 9.5rem !important; }
  .gap-md-96 {
    gap: 9.6rem !important; }
  .gap-md-100 {
    gap: 10rem !important; }
  .gap-md-104 {
    gap: 10.4rem !important; }
  .gap-md-108 {
    gap: 10.8rem !important; }
  .gap-md-110 {
    gap: 11rem !important; }
  .gap-md-116 {
    gap: 11.6rem !important; }
  .gap-md-120 {
    gap: 12rem !important; }
  .gap-md-128 {
    gap: 12.8rem !important; }
  .gap-md-140 {
    gap: 14rem !important; }
  .gap-md-148 {
    gap: 14.8rem !important; }
  .gap-md-160 {
    gap: 16rem !important; }
  .gap-md-170 {
    gap: 17rem !important; }
  .gap-md-200 {
    gap: 20rem !important; }
  .gap-md-240 {
    gap: 24rem !important; }
  .gap-md-245 {
    gap: 24.5rem !important; }
  .gap-md-250 {
    gap: 25rem !important; }
  .gap-md-300 {
    gap: 30rem !important; }
  .gap-md-400 {
    gap: 40rem !important; }
  .gap-md-n25 {
    gap: -25% !important; }
  .font-md-family-gantari {
    font-family: "Gantari", sans-serif !important; }
  .font-md-family-roboto {
    font-family: "Roboto", sans-serif !important; }
  .fs-md-12 {
    font-size: 1.2rem !important; }
  .fs-md-13 {
    font-size: 1.3rem !important; }
  .fs-md-14 {
    font-size: 1.4rem !important; }
  .fs-md-15 {
    font-size: 1.5rem !important; }
  .fs-md-16 {
    font-size: 1.6rem !important; }
  .fs-md-18 {
    font-size: 1.8rem !important; }
  .fs-md-24 {
    font-size: 2.4rem !important; }
  .fs-md-28 {
    font-size: 2.8rem !important; }
  .fs-md-30 {
    font-size: 3rem !important; }
  .fs-md-32 {
    font-size: 3.2rem !important; }
  .fs-md-40 {
    font-size: 4rem !important; }
  .fs-md-50 {
    font-size: 5rem !important; }
  .fs-md-60 {
    font-size: 6rem !important; }
  .text-md-start {
    text-align: left !important; }
  .text-md-end {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; }
  .rounded-md {
    border-radius: 0.8rem !important; }
  .rounded-md-0 {
    border-radius: 0 !important; }
  .rounded-md-1 {
    border-radius: 0.6rem !important; }
  .rounded-md-2 {
    border-radius: 0.8rem !important; }
  .rounded-md-3 {
    border-radius: 5px 20px 5px 20px !important; }
  .rounded-md-4 {
    border-radius: 0.4rem !important; }
  .rounded-md-5 {
    border-radius: 0.5rem !important; }
  .rounded-md-8 {
    border-radius: 0.8rem !important; }
  .rounded-md-9 {
    border-radius: 0.9rem !important; }
  .rounded-md-15 {
    border-radius: 1.5rem !important; }
  .rounded-md-16 {
    border-radius: 1.6rem !important; }
  .rounded-md-64 {
    border-radius: 6.4rem !important; }
  .rounded-md-circle {
    border-radius: 50% !important; }
  .rounded-md-pill {
    border-radius: 50rem !important; }
  .rounded-md-intro {
    border-radius: 50% 50% 0 0 !important; }
  .rounded-top-md {
    border-top-left-radius: 0.8rem !important;
    border-top-right-radius: 0.8rem !important; }
  .rounded-top-md-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important; }
  .rounded-top-md-1 {
    border-top-left-radius: 0.6rem !important;
    border-top-right-radius: 0.6rem !important; }
  .rounded-top-md-2 {
    border-top-left-radius: 0.8rem !important;
    border-top-right-radius: 0.8rem !important; }
  .rounded-top-md-3 {
    border-top-left-radius: 5px 20px 5px 20px !important;
    border-top-right-radius: 5px 20px 5px 20px !important; }
  .rounded-top-md-4 {
    border-top-left-radius: 0.4rem !important;
    border-top-right-radius: 0.4rem !important; }
  .rounded-top-md-5 {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important; }
  .rounded-top-md-8 {
    border-top-left-radius: 0.8rem !important;
    border-top-right-radius: 0.8rem !important; }
  .rounded-top-md-9 {
    border-top-left-radius: 0.9rem !important;
    border-top-right-radius: 0.9rem !important; }
  .rounded-top-md-15 {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important; }
  .rounded-top-md-16 {
    border-top-left-radius: 1.6rem !important;
    border-top-right-radius: 1.6rem !important; }
  .rounded-top-md-64 {
    border-top-left-radius: 6.4rem !important;
    border-top-right-radius: 6.4rem !important; }
  .rounded-top-md-circle {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important; }
  .rounded-top-md-pill {
    border-top-left-radius: 50rem !important;
    border-top-right-radius: 50rem !important; }
  .rounded-top-md-intro {
    border-top-left-radius: 50% 50% 0 0 !important;
    border-top-right-radius: 50% 50% 0 0 !important; } }

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important; }
  .float-lg-end {
    float: right !important; }
  .float-lg-none {
    float: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-grid {
    display: grid !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; }
  .d-lg-none {
    display: none !important; }
  .position-lg-static {
    position: static !important; }
  .position-lg-relative {
    position: relative !important; }
  .position-lg-absolute {
    position: absolute !important; }
  .position-lg-fixed {
    position: fixed !important; }
  .position-lg-sticky {
    position: sticky !important; }
  .top-lg-0 {
    top: 0 !important; }
  .top-lg-5 {
    top: 5% !important; }
  .top-lg-25 {
    top: 25% !important; }
  .top-lg-50 {
    top: 50% !important; }
  .top-lg-90 {
    top: 90% !important; }
  .top-lg-100 {
    top: 100% !important; }
  .top-lg-10px {
    top: 10px !important; }
  .top-lg-14px {
    top: 14px !important; }
  .top-lg-24px {
    top: 24px !important; }
  .top-lg-50px {
    top: 50px !important; }
  .top-lg-70px {
    top: 70px !important; }
  .top-lg-100px {
    top: 100px !important; }
  .top-lg-120px {
    top: 120px !important; }
  .top-lg-150px {
    top: 150px !important; }
  .top-lg-170px {
    top: 170px !important; }
  .top-lg-200px {
    top: 200px !important; }
  .top-lg-230px {
    top: 230px !important; }
  .top-lg-240px {
    top: 240px !important; }
  .top-lg-250px {
    top: 250px !important; }
  .top-lg-n8px {
    top: -8px !important; }
  .top-lg-n10px {
    top: -10px !important; }
  .top-lg-n19px {
    top: -19px !important; }
  .top-lg-n25px {
    top: -25px !important; }
  .top-lg-n50px {
    top: -50px !important; }
  .top-lg-n90px {
    top: -90px !important; }
  .top-lg-n200px {
    top: -200px !important; }
  .top-lg-n240px {
    top: -240px !important; }
  .top-lg-n340px {
    top: -340px !important; }
  .top-lg-n350px {
    top: -350px !important; }
  .top-lg-100n20 {
    top: calc(100% + 20px) !important; }
  .top-lg-100n50 {
    top: calc(100% - 50px) !important; }
  .top-lg-100n100 {
    top: calc(100% + 100px) !important; }
  .top-lg-100n200 {
    top: calc(100% + 200px) !important; }
  .top-lg-100n250 {
    top: calc(100% + 250px) !important; }
  .top-lg-100n350 {
    top: calc(100% + 350px) !important; }
  .bottom-lg-0 {
    bottom: 0 !important; }
  .bottom-lg-5 {
    bottom: 5% !important; }
  .bottom-lg-25 {
    bottom: 25% !important; }
  .bottom-lg-50 {
    bottom: 50% !important; }
  .bottom-lg-90 {
    bottom: 90% !important; }
  .bottom-lg-100 {
    bottom: 100% !important; }
  .bottom-lg-10px {
    bottom: 10px !important; }
  .bottom-lg-14px {
    bottom: 14px !important; }
  .bottom-lg-24px {
    bottom: 24px !important; }
  .bottom-lg-50px {
    bottom: 50px !important; }
  .bottom-lg-70px {
    bottom: 70px !important; }
  .bottom-lg-100px {
    bottom: 100px !important; }
  .bottom-lg-120px {
    bottom: 120px !important; }
  .bottom-lg-150px {
    bottom: 150px !important; }
  .bottom-lg-170px {
    bottom: 170px !important; }
  .bottom-lg-200px {
    bottom: 200px !important; }
  .bottom-lg-230px {
    bottom: 230px !important; }
  .bottom-lg-240px {
    bottom: 240px !important; }
  .bottom-lg-250px {
    bottom: 250px !important; }
  .bottom-lg-n8px {
    bottom: -8px !important; }
  .bottom-lg-n10px {
    bottom: -10px !important; }
  .bottom-lg-n19px {
    bottom: -19px !important; }
  .bottom-lg-n25px {
    bottom: -25px !important; }
  .bottom-lg-n50px {
    bottom: -50px !important; }
  .bottom-lg-n90px {
    bottom: -90px !important; }
  .bottom-lg-n200px {
    bottom: -200px !important; }
  .bottom-lg-n240px {
    bottom: -240px !important; }
  .bottom-lg-n340px {
    bottom: -340px !important; }
  .bottom-lg-n350px {
    bottom: -350px !important; }
  .bottom-lg-100n20 {
    bottom: calc(100% + 20px) !important; }
  .bottom-lg-100n50 {
    bottom: calc(100% - 50px) !important; }
  .bottom-lg-100n100 {
    bottom: calc(100% + 100px) !important; }
  .bottom-lg-100n200 {
    bottom: calc(100% + 200px) !important; }
  .bottom-lg-100n250 {
    bottom: calc(100% + 250px) !important; }
  .bottom-lg-100n350 {
    bottom: calc(100% + 350px) !important; }
  .start-lg-0 {
    left: 0 !important; }
  .start-lg-5 {
    left: 5% !important; }
  .start-lg-25 {
    left: 25% !important; }
  .start-lg-50 {
    left: 50% !important; }
  .start-lg-90 {
    left: 90% !important; }
  .start-lg-100 {
    left: 100% !important; }
  .start-lg-10px {
    left: 10px !important; }
  .start-lg-14px {
    left: 14px !important; }
  .start-lg-24px {
    left: 24px !important; }
  .start-lg-50px {
    left: 50px !important; }
  .start-lg-70px {
    left: 70px !important; }
  .start-lg-100px {
    left: 100px !important; }
  .start-lg-120px {
    left: 120px !important; }
  .start-lg-150px {
    left: 150px !important; }
  .start-lg-170px {
    left: 170px !important; }
  .start-lg-200px {
    left: 200px !important; }
  .start-lg-230px {
    left: 230px !important; }
  .start-lg-240px {
    left: 240px !important; }
  .start-lg-250px {
    left: 250px !important; }
  .start-lg-n8px {
    left: -8px !important; }
  .start-lg-n10px {
    left: -10px !important; }
  .start-lg-n19px {
    left: -19px !important; }
  .start-lg-n25px {
    left: -25px !important; }
  .start-lg-n50px {
    left: -50px !important; }
  .start-lg-n90px {
    left: -90px !important; }
  .start-lg-n200px {
    left: -200px !important; }
  .start-lg-n240px {
    left: -240px !important; }
  .start-lg-n340px {
    left: -340px !important; }
  .start-lg-n350px {
    left: -350px !important; }
  .start-lg-100n20 {
    left: calc(100% + 20px) !important; }
  .start-lg-100n50 {
    left: calc(100% - 50px) !important; }
  .start-lg-100n100 {
    left: calc(100% + 100px) !important; }
  .start-lg-100n200 {
    left: calc(100% + 200px) !important; }
  .start-lg-100n250 {
    left: calc(100% + 250px) !important; }
  .start-lg-100n350 {
    left: calc(100% + 350px) !important; }
  .end-lg-0 {
    right: 0 !important; }
  .end-lg-5 {
    right: 5% !important; }
  .end-lg-25 {
    right: 25% !important; }
  .end-lg-50 {
    right: 50% !important; }
  .end-lg-90 {
    right: 90% !important; }
  .end-lg-100 {
    right: 100% !important; }
  .end-lg-10px {
    right: 10px !important; }
  .end-lg-14px {
    right: 14px !important; }
  .end-lg-24px {
    right: 24px !important; }
  .end-lg-50px {
    right: 50px !important; }
  .end-lg-70px {
    right: 70px !important; }
  .end-lg-100px {
    right: 100px !important; }
  .end-lg-120px {
    right: 120px !important; }
  .end-lg-150px {
    right: 150px !important; }
  .end-lg-170px {
    right: 170px !important; }
  .end-lg-200px {
    right: 200px !important; }
  .end-lg-230px {
    right: 230px !important; }
  .end-lg-240px {
    right: 240px !important; }
  .end-lg-250px {
    right: 250px !important; }
  .end-lg-n8px {
    right: -8px !important; }
  .end-lg-n10px {
    right: -10px !important; }
  .end-lg-n19px {
    right: -19px !important; }
  .end-lg-n25px {
    right: -25px !important; }
  .end-lg-n50px {
    right: -50px !important; }
  .end-lg-n90px {
    right: -90px !important; }
  .end-lg-n200px {
    right: -200px !important; }
  .end-lg-n240px {
    right: -240px !important; }
  .end-lg-n340px {
    right: -340px !important; }
  .end-lg-n350px {
    right: -350px !important; }
  .end-lg-100n20 {
    right: calc(100% + 20px) !important; }
  .end-lg-100n50 {
    right: calc(100% - 50px) !important; }
  .end-lg-100n100 {
    right: calc(100% + 100px) !important; }
  .end-lg-100n200 {
    right: calc(100% + 200px) !important; }
  .end-lg-100n250 {
    right: calc(100% + 250px) !important; }
  .end-lg-100n350 {
    right: calc(100% + 350px) !important; }
  .w-lg-25 {
    width: 25% !important; }
  .w-lg-50 {
    width: 50% !important; }
  .w-lg-75 {
    width: 75% !important; }
  .w-lg-100 {
    width: 100% !important; }
  .w-lg-auto {
    width: auto !important; }
  .w-lg-135px {
    width: 135px !important; }
  .w-lg-150px {
    width: 150px !important; }
  .w-lg-180px {
    width: 180px !important; }
  .w-lg-190px {
    width: 190px !important; }
  .w-lg-200px {
    width: 200px !important; }
  .w-lg-240px {
    width: 240px !important; }
  .w-lg-260px {
    width: 260px !important; }
  .w-lg-296px {
    width: 296px !important; }
  .w-lg-340px {
    width: 340px !important; }
  .w-lg-368px {
    width: 368px !important; }
  .w-lg-400px {
    width: 400px !important; }
  .w-lg-500px {
    width: 500px !important; }
  .w-lg-520px {
    width: 520px !important; }
  .w-lg-800px {
    width: 800px !important; }
  .h-lg-25 {
    height: 25% !important; }
  .h-lg-50 {
    height: 50% !important; }
  .h-lg-75 {
    height: 75% !important; }
  .h-lg-100 {
    height: 100% !important; }
  .h-lg-auto {
    height: auto !important; }
  .h-lg-135px {
    height: 135px !important; }
  .h-lg-150px {
    height: 150px !important; }
  .h-lg-180px {
    height: 180px !important; }
  .h-lg-190px {
    height: 190px !important; }
  .h-lg-200px {
    height: 200px !important; }
  .h-lg-240px {
    height: 240px !important; }
  .h-lg-260px {
    height: 260px !important; }
  .h-lg-296px {
    height: 296px !important; }
  .h-lg-340px {
    height: 340px !important; }
  .h-lg-368px {
    height: 368px !important; }
  .h-lg-400px {
    height: 400px !important; }
  .h-lg-500px {
    height: 500px !important; }
  .h-lg-520px {
    height: 520px !important; }
  .h-lg-800px {
    height: 800px !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; }
  .order-lg-first {
    order: -1 !important; }
  .order-lg-0 {
    order: 0 !important; }
  .order-lg-1 {
    order: 1 !important; }
  .order-lg-2 {
    order: 2 !important; }
  .order-lg-3 {
    order: 3 !important; }
  .order-lg-4 {
    order: 4 !important; }
  .order-lg-5 {
    order: 5 !important; }
  .order-lg-last {
    order: 6 !important; }
  .m-lg-0 {
    margin: 0 !important; }
  .m-lg-1 {
    margin: 0.1rem !important; }
  .m-lg-2 {
    margin: 0.2rem !important; }
  .m-lg-3 {
    margin: 0.3rem !important; }
  .m-lg-4 {
    margin: 0.4rem !important; }
  .m-lg-5 {
    margin: 0.5rem !important; }
  .m-lg-6 {
    margin: 0.6rem !important; }
  .m-lg-8 {
    margin: 0.8rem !important; }
  .m-lg-10 {
    margin: 1rem !important; }
  .m-lg-12 {
    margin: 1.2rem !important; }
  .m-lg-13 {
    margin: 1.3rem !important; }
  .m-lg-15 {
    margin: 1.5rem !important; }
  .m-lg-16 {
    margin: 1.6rem !important; }
  .m-lg-20 {
    margin: 2rem !important; }
  .m-lg-24 {
    margin: 2.4rem !important; }
  .m-lg-25 {
    margin: 2.5rem !important; }
  .m-lg-29 {
    margin: 2.9rem !important; }
  .m-lg-30 {
    margin: 3rem !important; }
  .m-lg-32 {
    margin: 3.2rem !important; }
  .m-lg-35 {
    margin: 3.5rem !important; }
  .m-lg-37 {
    margin: 3.7rem !important; }
  .m-lg-40 {
    margin: 4rem !important; }
  .m-lg-42 {
    margin: 4.2rem !important; }
  .m-lg-44 {
    margin: 4.4rem !important; }
  .m-lg-48 {
    margin: 4.8rem !important; }
  .m-lg-50 {
    margin: 5rem !important; }
  .m-lg-52 {
    margin: 5.2rem !important; }
  .m-lg-55 {
    margin: 5.5rem !important; }
  .m-lg-60 {
    margin: 6rem !important; }
  .m-lg-62 {
    margin: 6.2rem !important; }
  .m-lg-64 {
    margin: 6.4rem !important; }
  .m-lg-65 {
    margin: 6.5rem !important; }
  .m-lg-70 {
    margin: 7rem !important; }
  .m-lg-72 {
    margin: 7.2rem !important; }
  .m-lg-80 {
    margin: 8rem !important; }
  .m-lg-85 {
    margin: 8.5rem !important; }
  .m-lg-95 {
    margin: 9.5rem !important; }
  .m-lg-96 {
    margin: 9.6rem !important; }
  .m-lg-100 {
    margin: 10rem !important; }
  .m-lg-104 {
    margin: 10.4rem !important; }
  .m-lg-108 {
    margin: 10.8rem !important; }
  .m-lg-110 {
    margin: 11rem !important; }
  .m-lg-116 {
    margin: 11.6rem !important; }
  .m-lg-120 {
    margin: 12rem !important; }
  .m-lg-128 {
    margin: 12.8rem !important; }
  .m-lg-140 {
    margin: 14rem !important; }
  .m-lg-148 {
    margin: 14.8rem !important; }
  .m-lg-160 {
    margin: 16rem !important; }
  .m-lg-170 {
    margin: 17rem !important; }
  .m-lg-200 {
    margin: 20rem !important; }
  .m-lg-240 {
    margin: 24rem !important; }
  .m-lg-245 {
    margin: 24.5rem !important; }
  .m-lg-250 {
    margin: 25rem !important; }
  .m-lg-300 {
    margin: 30rem !important; }
  .m-lg-400 {
    margin: 40rem !important; }
  .m-lg-n25 {
    margin: -25% !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-lg-1 {
    margin-right: 0.1rem !important;
    margin-left: 0.1rem !important; }
  .mx-lg-2 {
    margin-right: 0.2rem !important;
    margin-left: 0.2rem !important; }
  .mx-lg-3 {
    margin-right: 0.3rem !important;
    margin-left: 0.3rem !important; }
  .mx-lg-4 {
    margin-right: 0.4rem !important;
    margin-left: 0.4rem !important; }
  .mx-lg-5 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-lg-6 {
    margin-right: 0.6rem !important;
    margin-left: 0.6rem !important; }
  .mx-lg-8 {
    margin-right: 0.8rem !important;
    margin-left: 0.8rem !important; }
  .mx-lg-10 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-lg-12 {
    margin-right: 1.2rem !important;
    margin-left: 1.2rem !important; }
  .mx-lg-13 {
    margin-right: 1.3rem !important;
    margin-left: 1.3rem !important; }
  .mx-lg-15 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-lg-16 {
    margin-right: 1.6rem !important;
    margin-left: 1.6rem !important; }
  .mx-lg-20 {
    margin-right: 2rem !important;
    margin-left: 2rem !important; }
  .mx-lg-24 {
    margin-right: 2.4rem !important;
    margin-left: 2.4rem !important; }
  .mx-lg-25 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important; }
  .mx-lg-29 {
    margin-right: 2.9rem !important;
    margin-left: 2.9rem !important; }
  .mx-lg-30 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-lg-32 {
    margin-right: 3.2rem !important;
    margin-left: 3.2rem !important; }
  .mx-lg-35 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important; }
  .mx-lg-37 {
    margin-right: 3.7rem !important;
    margin-left: 3.7rem !important; }
  .mx-lg-40 {
    margin-right: 4rem !important;
    margin-left: 4rem !important; }
  .mx-lg-42 {
    margin-right: 4.2rem !important;
    margin-left: 4.2rem !important; }
  .mx-lg-44 {
    margin-right: 4.4rem !important;
    margin-left: 4.4rem !important; }
  .mx-lg-48 {
    margin-right: 4.8rem !important;
    margin-left: 4.8rem !important; }
  .mx-lg-50 {
    margin-right: 5rem !important;
    margin-left: 5rem !important; }
  .mx-lg-52 {
    margin-right: 5.2rem !important;
    margin-left: 5.2rem !important; }
  .mx-lg-55 {
    margin-right: 5.5rem !important;
    margin-left: 5.5rem !important; }
  .mx-lg-60 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .mx-lg-62 {
    margin-right: 6.2rem !important;
    margin-left: 6.2rem !important; }
  .mx-lg-64 {
    margin-right: 6.4rem !important;
    margin-left: 6.4rem !important; }
  .mx-lg-65 {
    margin-right: 6.5rem !important;
    margin-left: 6.5rem !important; }
  .mx-lg-70 {
    margin-right: 7rem !important;
    margin-left: 7rem !important; }
  .mx-lg-72 {
    margin-right: 7.2rem !important;
    margin-left: 7.2rem !important; }
  .mx-lg-80 {
    margin-right: 8rem !important;
    margin-left: 8rem !important; }
  .mx-lg-85 {
    margin-right: 8.5rem !important;
    margin-left: 8.5rem !important; }
  .mx-lg-95 {
    margin-right: 9.5rem !important;
    margin-left: 9.5rem !important; }
  .mx-lg-96 {
    margin-right: 9.6rem !important;
    margin-left: 9.6rem !important; }
  .mx-lg-100 {
    margin-right: 10rem !important;
    margin-left: 10rem !important; }
  .mx-lg-104 {
    margin-right: 10.4rem !important;
    margin-left: 10.4rem !important; }
  .mx-lg-108 {
    margin-right: 10.8rem !important;
    margin-left: 10.8rem !important; }
  .mx-lg-110 {
    margin-right: 11rem !important;
    margin-left: 11rem !important; }
  .mx-lg-116 {
    margin-right: 11.6rem !important;
    margin-left: 11.6rem !important; }
  .mx-lg-120 {
    margin-right: 12rem !important;
    margin-left: 12rem !important; }
  .mx-lg-128 {
    margin-right: 12.8rem !important;
    margin-left: 12.8rem !important; }
  .mx-lg-140 {
    margin-right: 14rem !important;
    margin-left: 14rem !important; }
  .mx-lg-148 {
    margin-right: 14.8rem !important;
    margin-left: 14.8rem !important; }
  .mx-lg-160 {
    margin-right: 16rem !important;
    margin-left: 16rem !important; }
  .mx-lg-170 {
    margin-right: 17rem !important;
    margin-left: 17rem !important; }
  .mx-lg-200 {
    margin-right: 20rem !important;
    margin-left: 20rem !important; }
  .mx-lg-240 {
    margin-right: 24rem !important;
    margin-left: 24rem !important; }
  .mx-lg-245 {
    margin-right: 24.5rem !important;
    margin-left: 24.5rem !important; }
  .mx-lg-250 {
    margin-right: 25rem !important;
    margin-left: 25rem !important; }
  .mx-lg-300 {
    margin-right: 30rem !important;
    margin-left: 30rem !important; }
  .mx-lg-400 {
    margin-right: 40rem !important;
    margin-left: 40rem !important; }
  .mx-lg-n25 {
    margin-right: -25% !important;
    margin-left: -25% !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-lg-1 {
    margin-top: 0.1rem !important;
    margin-bottom: 0.1rem !important; }
  .my-lg-2 {
    margin-top: 0.2rem !important;
    margin-bottom: 0.2rem !important; }
  .my-lg-3 {
    margin-top: 0.3rem !important;
    margin-bottom: 0.3rem !important; }
  .my-lg-4 {
    margin-top: 0.4rem !important;
    margin-bottom: 0.4rem !important; }
  .my-lg-5 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-lg-6 {
    margin-top: 0.6rem !important;
    margin-bottom: 0.6rem !important; }
  .my-lg-8 {
    margin-top: 0.8rem !important;
    margin-bottom: 0.8rem !important; }
  .my-lg-10 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-lg-12 {
    margin-top: 1.2rem !important;
    margin-bottom: 1.2rem !important; }
  .my-lg-13 {
    margin-top: 1.3rem !important;
    margin-bottom: 1.3rem !important; }
  .my-lg-15 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-lg-16 {
    margin-top: 1.6rem !important;
    margin-bottom: 1.6rem !important; }
  .my-lg-20 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important; }
  .my-lg-24 {
    margin-top: 2.4rem !important;
    margin-bottom: 2.4rem !important; }
  .my-lg-25 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important; }
  .my-lg-29 {
    margin-top: 2.9rem !important;
    margin-bottom: 2.9rem !important; }
  .my-lg-30 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-lg-32 {
    margin-top: 3.2rem !important;
    margin-bottom: 3.2rem !important; }
  .my-lg-35 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important; }
  .my-lg-37 {
    margin-top: 3.7rem !important;
    margin-bottom: 3.7rem !important; }
  .my-lg-40 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important; }
  .my-lg-42 {
    margin-top: 4.2rem !important;
    margin-bottom: 4.2rem !important; }
  .my-lg-44 {
    margin-top: 4.4rem !important;
    margin-bottom: 4.4rem !important; }
  .my-lg-48 {
    margin-top: 4.8rem !important;
    margin-bottom: 4.8rem !important; }
  .my-lg-50 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important; }
  .my-lg-52 {
    margin-top: 5.2rem !important;
    margin-bottom: 5.2rem !important; }
  .my-lg-55 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important; }
  .my-lg-60 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .my-lg-62 {
    margin-top: 6.2rem !important;
    margin-bottom: 6.2rem !important; }
  .my-lg-64 {
    margin-top: 6.4rem !important;
    margin-bottom: 6.4rem !important; }
  .my-lg-65 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important; }
  .my-lg-70 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important; }
  .my-lg-72 {
    margin-top: 7.2rem !important;
    margin-bottom: 7.2rem !important; }
  .my-lg-80 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important; }
  .my-lg-85 {
    margin-top: 8.5rem !important;
    margin-bottom: 8.5rem !important; }
  .my-lg-95 {
    margin-top: 9.5rem !important;
    margin-bottom: 9.5rem !important; }
  .my-lg-96 {
    margin-top: 9.6rem !important;
    margin-bottom: 9.6rem !important; }
  .my-lg-100 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important; }
  .my-lg-104 {
    margin-top: 10.4rem !important;
    margin-bottom: 10.4rem !important; }
  .my-lg-108 {
    margin-top: 10.8rem !important;
    margin-bottom: 10.8rem !important; }
  .my-lg-110 {
    margin-top: 11rem !important;
    margin-bottom: 11rem !important; }
  .my-lg-116 {
    margin-top: 11.6rem !important;
    margin-bottom: 11.6rem !important; }
  .my-lg-120 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important; }
  .my-lg-128 {
    margin-top: 12.8rem !important;
    margin-bottom: 12.8rem !important; }
  .my-lg-140 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important; }
  .my-lg-148 {
    margin-top: 14.8rem !important;
    margin-bottom: 14.8rem !important; }
  .my-lg-160 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important; }
  .my-lg-170 {
    margin-top: 17rem !important;
    margin-bottom: 17rem !important; }
  .my-lg-200 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important; }
  .my-lg-240 {
    margin-top: 24rem !important;
    margin-bottom: 24rem !important; }
  .my-lg-245 {
    margin-top: 24.5rem !important;
    margin-bottom: 24.5rem !important; }
  .my-lg-250 {
    margin-top: 25rem !important;
    margin-bottom: 25rem !important; }
  .my-lg-300 {
    margin-top: 30rem !important;
    margin-bottom: 30rem !important; }
  .my-lg-400 {
    margin-top: 40rem !important;
    margin-bottom: 40rem !important; }
  .my-lg-n25 {
    margin-top: -25% !important;
    margin-bottom: -25% !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mt-lg-1 {
    margin-top: 0.1rem !important; }
  .mt-lg-2 {
    margin-top: 0.2rem !important; }
  .mt-lg-3 {
    margin-top: 0.3rem !important; }
  .mt-lg-4 {
    margin-top: 0.4rem !important; }
  .mt-lg-5 {
    margin-top: 0.5rem !important; }
  .mt-lg-6 {
    margin-top: 0.6rem !important; }
  .mt-lg-8 {
    margin-top: 0.8rem !important; }
  .mt-lg-10 {
    margin-top: 1rem !important; }
  .mt-lg-12 {
    margin-top: 1.2rem !important; }
  .mt-lg-13 {
    margin-top: 1.3rem !important; }
  .mt-lg-15 {
    margin-top: 1.5rem !important; }
  .mt-lg-16 {
    margin-top: 1.6rem !important; }
  .mt-lg-20 {
    margin-top: 2rem !important; }
  .mt-lg-24 {
    margin-top: 2.4rem !important; }
  .mt-lg-25 {
    margin-top: 2.5rem !important; }
  .mt-lg-29 {
    margin-top: 2.9rem !important; }
  .mt-lg-30 {
    margin-top: 3rem !important; }
  .mt-lg-32 {
    margin-top: 3.2rem !important; }
  .mt-lg-35 {
    margin-top: 3.5rem !important; }
  .mt-lg-37 {
    margin-top: 3.7rem !important; }
  .mt-lg-40 {
    margin-top: 4rem !important; }
  .mt-lg-42 {
    margin-top: 4.2rem !important; }
  .mt-lg-44 {
    margin-top: 4.4rem !important; }
  .mt-lg-48 {
    margin-top: 4.8rem !important; }
  .mt-lg-50 {
    margin-top: 5rem !important; }
  .mt-lg-52 {
    margin-top: 5.2rem !important; }
  .mt-lg-55 {
    margin-top: 5.5rem !important; }
  .mt-lg-60 {
    margin-top: 6rem !important; }
  .mt-lg-62 {
    margin-top: 6.2rem !important; }
  .mt-lg-64 {
    margin-top: 6.4rem !important; }
  .mt-lg-65 {
    margin-top: 6.5rem !important; }
  .mt-lg-70 {
    margin-top: 7rem !important; }
  .mt-lg-72 {
    margin-top: 7.2rem !important; }
  .mt-lg-80 {
    margin-top: 8rem !important; }
  .mt-lg-85 {
    margin-top: 8.5rem !important; }
  .mt-lg-95 {
    margin-top: 9.5rem !important; }
  .mt-lg-96 {
    margin-top: 9.6rem !important; }
  .mt-lg-100 {
    margin-top: 10rem !important; }
  .mt-lg-104 {
    margin-top: 10.4rem !important; }
  .mt-lg-108 {
    margin-top: 10.8rem !important; }
  .mt-lg-110 {
    margin-top: 11rem !important; }
  .mt-lg-116 {
    margin-top: 11.6rem !important; }
  .mt-lg-120 {
    margin-top: 12rem !important; }
  .mt-lg-128 {
    margin-top: 12.8rem !important; }
  .mt-lg-140 {
    margin-top: 14rem !important; }
  .mt-lg-148 {
    margin-top: 14.8rem !important; }
  .mt-lg-160 {
    margin-top: 16rem !important; }
  .mt-lg-170 {
    margin-top: 17rem !important; }
  .mt-lg-200 {
    margin-top: 20rem !important; }
  .mt-lg-240 {
    margin-top: 24rem !important; }
  .mt-lg-245 {
    margin-top: 24.5rem !important; }
  .mt-lg-250 {
    margin-top: 25rem !important; }
  .mt-lg-300 {
    margin-top: 30rem !important; }
  .mt-lg-400 {
    margin-top: 40rem !important; }
  .mt-lg-n25 {
    margin-top: -25% !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .me-lg-0 {
    margin-right: 0 !important; }
  .me-lg-1 {
    margin-right: 0.1rem !important; }
  .me-lg-2 {
    margin-right: 0.2rem !important; }
  .me-lg-3 {
    margin-right: 0.3rem !important; }
  .me-lg-4 {
    margin-right: 0.4rem !important; }
  .me-lg-5 {
    margin-right: 0.5rem !important; }
  .me-lg-6 {
    margin-right: 0.6rem !important; }
  .me-lg-8 {
    margin-right: 0.8rem !important; }
  .me-lg-10 {
    margin-right: 1rem !important; }
  .me-lg-12 {
    margin-right: 1.2rem !important; }
  .me-lg-13 {
    margin-right: 1.3rem !important; }
  .me-lg-15 {
    margin-right: 1.5rem !important; }
  .me-lg-16 {
    margin-right: 1.6rem !important; }
  .me-lg-20 {
    margin-right: 2rem !important; }
  .me-lg-24 {
    margin-right: 2.4rem !important; }
  .me-lg-25 {
    margin-right: 2.5rem !important; }
  .me-lg-29 {
    margin-right: 2.9rem !important; }
  .me-lg-30 {
    margin-right: 3rem !important; }
  .me-lg-32 {
    margin-right: 3.2rem !important; }
  .me-lg-35 {
    margin-right: 3.5rem !important; }
  .me-lg-37 {
    margin-right: 3.7rem !important; }
  .me-lg-40 {
    margin-right: 4rem !important; }
  .me-lg-42 {
    margin-right: 4.2rem !important; }
  .me-lg-44 {
    margin-right: 4.4rem !important; }
  .me-lg-48 {
    margin-right: 4.8rem !important; }
  .me-lg-50 {
    margin-right: 5rem !important; }
  .me-lg-52 {
    margin-right: 5.2rem !important; }
  .me-lg-55 {
    margin-right: 5.5rem !important; }
  .me-lg-60 {
    margin-right: 6rem !important; }
  .me-lg-62 {
    margin-right: 6.2rem !important; }
  .me-lg-64 {
    margin-right: 6.4rem !important; }
  .me-lg-65 {
    margin-right: 6.5rem !important; }
  .me-lg-70 {
    margin-right: 7rem !important; }
  .me-lg-72 {
    margin-right: 7.2rem !important; }
  .me-lg-80 {
    margin-right: 8rem !important; }
  .me-lg-85 {
    margin-right: 8.5rem !important; }
  .me-lg-95 {
    margin-right: 9.5rem !important; }
  .me-lg-96 {
    margin-right: 9.6rem !important; }
  .me-lg-100 {
    margin-right: 10rem !important; }
  .me-lg-104 {
    margin-right: 10.4rem !important; }
  .me-lg-108 {
    margin-right: 10.8rem !important; }
  .me-lg-110 {
    margin-right: 11rem !important; }
  .me-lg-116 {
    margin-right: 11.6rem !important; }
  .me-lg-120 {
    margin-right: 12rem !important; }
  .me-lg-128 {
    margin-right: 12.8rem !important; }
  .me-lg-140 {
    margin-right: 14rem !important; }
  .me-lg-148 {
    margin-right: 14.8rem !important; }
  .me-lg-160 {
    margin-right: 16rem !important; }
  .me-lg-170 {
    margin-right: 17rem !important; }
  .me-lg-200 {
    margin-right: 20rem !important; }
  .me-lg-240 {
    margin-right: 24rem !important; }
  .me-lg-245 {
    margin-right: 24.5rem !important; }
  .me-lg-250 {
    margin-right: 25rem !important; }
  .me-lg-300 {
    margin-right: 30rem !important; }
  .me-lg-400 {
    margin-right: 40rem !important; }
  .me-lg-n25 {
    margin-right: -25% !important; }
  .me-lg-auto {
    margin-right: auto !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .mb-lg-1 {
    margin-bottom: 0.1rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.2rem !important; }
  .mb-lg-3 {
    margin-bottom: 0.3rem !important; }
  .mb-lg-4 {
    margin-bottom: 0.4rem !important; }
  .mb-lg-5 {
    margin-bottom: 0.5rem !important; }
  .mb-lg-6 {
    margin-bottom: 0.6rem !important; }
  .mb-lg-8 {
    margin-bottom: 0.8rem !important; }
  .mb-lg-10 {
    margin-bottom: 1rem !important; }
  .mb-lg-12 {
    margin-bottom: 1.2rem !important; }
  .mb-lg-13 {
    margin-bottom: 1.3rem !important; }
  .mb-lg-15 {
    margin-bottom: 1.5rem !important; }
  .mb-lg-16 {
    margin-bottom: 1.6rem !important; }
  .mb-lg-20 {
    margin-bottom: 2rem !important; }
  .mb-lg-24 {
    margin-bottom: 2.4rem !important; }
  .mb-lg-25 {
    margin-bottom: 2.5rem !important; }
  .mb-lg-29 {
    margin-bottom: 2.9rem !important; }
  .mb-lg-30 {
    margin-bottom: 3rem !important; }
  .mb-lg-32 {
    margin-bottom: 3.2rem !important; }
  .mb-lg-35 {
    margin-bottom: 3.5rem !important; }
  .mb-lg-37 {
    margin-bottom: 3.7rem !important; }
  .mb-lg-40 {
    margin-bottom: 4rem !important; }
  .mb-lg-42 {
    margin-bottom: 4.2rem !important; }
  .mb-lg-44 {
    margin-bottom: 4.4rem !important; }
  .mb-lg-48 {
    margin-bottom: 4.8rem !important; }
  .mb-lg-50 {
    margin-bottom: 5rem !important; }
  .mb-lg-52 {
    margin-bottom: 5.2rem !important; }
  .mb-lg-55 {
    margin-bottom: 5.5rem !important; }
  .mb-lg-60 {
    margin-bottom: 6rem !important; }
  .mb-lg-62 {
    margin-bottom: 6.2rem !important; }
  .mb-lg-64 {
    margin-bottom: 6.4rem !important; }
  .mb-lg-65 {
    margin-bottom: 6.5rem !important; }
  .mb-lg-70 {
    margin-bottom: 7rem !important; }
  .mb-lg-72 {
    margin-bottom: 7.2rem !important; }
  .mb-lg-80 {
    margin-bottom: 8rem !important; }
  .mb-lg-85 {
    margin-bottom: 8.5rem !important; }
  .mb-lg-95 {
    margin-bottom: 9.5rem !important; }
  .mb-lg-96 {
    margin-bottom: 9.6rem !important; }
  .mb-lg-100 {
    margin-bottom: 10rem !important; }
  .mb-lg-104 {
    margin-bottom: 10.4rem !important; }
  .mb-lg-108 {
    margin-bottom: 10.8rem !important; }
  .mb-lg-110 {
    margin-bottom: 11rem !important; }
  .mb-lg-116 {
    margin-bottom: 11.6rem !important; }
  .mb-lg-120 {
    margin-bottom: 12rem !important; }
  .mb-lg-128 {
    margin-bottom: 12.8rem !important; }
  .mb-lg-140 {
    margin-bottom: 14rem !important; }
  .mb-lg-148 {
    margin-bottom: 14.8rem !important; }
  .mb-lg-160 {
    margin-bottom: 16rem !important; }
  .mb-lg-170 {
    margin-bottom: 17rem !important; }
  .mb-lg-200 {
    margin-bottom: 20rem !important; }
  .mb-lg-240 {
    margin-bottom: 24rem !important; }
  .mb-lg-245 {
    margin-bottom: 24.5rem !important; }
  .mb-lg-250 {
    margin-bottom: 25rem !important; }
  .mb-lg-300 {
    margin-bottom: 30rem !important; }
  .mb-lg-400 {
    margin-bottom: 40rem !important; }
  .mb-lg-n25 {
    margin-bottom: -25% !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ms-lg-0 {
    margin-left: 0 !important; }
  .ms-lg-1 {
    margin-left: 0.1rem !important; }
  .ms-lg-2 {
    margin-left: 0.2rem !important; }
  .ms-lg-3 {
    margin-left: 0.3rem !important; }
  .ms-lg-4 {
    margin-left: 0.4rem !important; }
  .ms-lg-5 {
    margin-left: 0.5rem !important; }
  .ms-lg-6 {
    margin-left: 0.6rem !important; }
  .ms-lg-8 {
    margin-left: 0.8rem !important; }
  .ms-lg-10 {
    margin-left: 1rem !important; }
  .ms-lg-12 {
    margin-left: 1.2rem !important; }
  .ms-lg-13 {
    margin-left: 1.3rem !important; }
  .ms-lg-15 {
    margin-left: 1.5rem !important; }
  .ms-lg-16 {
    margin-left: 1.6rem !important; }
  .ms-lg-20 {
    margin-left: 2rem !important; }
  .ms-lg-24 {
    margin-left: 2.4rem !important; }
  .ms-lg-25 {
    margin-left: 2.5rem !important; }
  .ms-lg-29 {
    margin-left: 2.9rem !important; }
  .ms-lg-30 {
    margin-left: 3rem !important; }
  .ms-lg-32 {
    margin-left: 3.2rem !important; }
  .ms-lg-35 {
    margin-left: 3.5rem !important; }
  .ms-lg-37 {
    margin-left: 3.7rem !important; }
  .ms-lg-40 {
    margin-left: 4rem !important; }
  .ms-lg-42 {
    margin-left: 4.2rem !important; }
  .ms-lg-44 {
    margin-left: 4.4rem !important; }
  .ms-lg-48 {
    margin-left: 4.8rem !important; }
  .ms-lg-50 {
    margin-left: 5rem !important; }
  .ms-lg-52 {
    margin-left: 5.2rem !important; }
  .ms-lg-55 {
    margin-left: 5.5rem !important; }
  .ms-lg-60 {
    margin-left: 6rem !important; }
  .ms-lg-62 {
    margin-left: 6.2rem !important; }
  .ms-lg-64 {
    margin-left: 6.4rem !important; }
  .ms-lg-65 {
    margin-left: 6.5rem !important; }
  .ms-lg-70 {
    margin-left: 7rem !important; }
  .ms-lg-72 {
    margin-left: 7.2rem !important; }
  .ms-lg-80 {
    margin-left: 8rem !important; }
  .ms-lg-85 {
    margin-left: 8.5rem !important; }
  .ms-lg-95 {
    margin-left: 9.5rem !important; }
  .ms-lg-96 {
    margin-left: 9.6rem !important; }
  .ms-lg-100 {
    margin-left: 10rem !important; }
  .ms-lg-104 {
    margin-left: 10.4rem !important; }
  .ms-lg-108 {
    margin-left: 10.8rem !important; }
  .ms-lg-110 {
    margin-left: 11rem !important; }
  .ms-lg-116 {
    margin-left: 11.6rem !important; }
  .ms-lg-120 {
    margin-left: 12rem !important; }
  .ms-lg-128 {
    margin-left: 12.8rem !important; }
  .ms-lg-140 {
    margin-left: 14rem !important; }
  .ms-lg-148 {
    margin-left: 14.8rem !important; }
  .ms-lg-160 {
    margin-left: 16rem !important; }
  .ms-lg-170 {
    margin-left: 17rem !important; }
  .ms-lg-200 {
    margin-left: 20rem !important; }
  .ms-lg-240 {
    margin-left: 24rem !important; }
  .ms-lg-245 {
    margin-left: 24.5rem !important; }
  .ms-lg-250 {
    margin-left: 25rem !important; }
  .ms-lg-300 {
    margin-left: 30rem !important; }
  .ms-lg-400 {
    margin-left: 40rem !important; }
  .ms-lg-n25 {
    margin-left: -25% !important; }
  .ms-lg-auto {
    margin-left: auto !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .p-lg-1 {
    padding: 0.1rem !important; }
  .p-lg-2 {
    padding: 0.2rem !important; }
  .p-lg-3 {
    padding: 0.3rem !important; }
  .p-lg-4 {
    padding: 0.4rem !important; }
  .p-lg-5 {
    padding: 0.5rem !important; }
  .p-lg-6 {
    padding: 0.6rem !important; }
  .p-lg-8 {
    padding: 0.8rem !important; }
  .p-lg-10 {
    padding: 1rem !important; }
  .p-lg-12 {
    padding: 1.2rem !important; }
  .p-lg-13 {
    padding: 1.3rem !important; }
  .p-lg-15 {
    padding: 1.5rem !important; }
  .p-lg-16 {
    padding: 1.6rem !important; }
  .p-lg-20 {
    padding: 2rem !important; }
  .p-lg-24 {
    padding: 2.4rem !important; }
  .p-lg-25 {
    padding: 2.5rem !important; }
  .p-lg-29 {
    padding: 2.9rem !important; }
  .p-lg-30 {
    padding: 3rem !important; }
  .p-lg-32 {
    padding: 3.2rem !important; }
  .p-lg-35 {
    padding: 3.5rem !important; }
  .p-lg-37 {
    padding: 3.7rem !important; }
  .p-lg-40 {
    padding: 4rem !important; }
  .p-lg-42 {
    padding: 4.2rem !important; }
  .p-lg-44 {
    padding: 4.4rem !important; }
  .p-lg-48 {
    padding: 4.8rem !important; }
  .p-lg-50 {
    padding: 5rem !important; }
  .p-lg-52 {
    padding: 5.2rem !important; }
  .p-lg-55 {
    padding: 5.5rem !important; }
  .p-lg-60 {
    padding: 6rem !important; }
  .p-lg-62 {
    padding: 6.2rem !important; }
  .p-lg-64 {
    padding: 6.4rem !important; }
  .p-lg-65 {
    padding: 6.5rem !important; }
  .p-lg-70 {
    padding: 7rem !important; }
  .p-lg-72 {
    padding: 7.2rem !important; }
  .p-lg-80 {
    padding: 8rem !important; }
  .p-lg-85 {
    padding: 8.5rem !important; }
  .p-lg-95 {
    padding: 9.5rem !important; }
  .p-lg-96 {
    padding: 9.6rem !important; }
  .p-lg-100 {
    padding: 10rem !important; }
  .p-lg-104 {
    padding: 10.4rem !important; }
  .p-lg-108 {
    padding: 10.8rem !important; }
  .p-lg-110 {
    padding: 11rem !important; }
  .p-lg-116 {
    padding: 11.6rem !important; }
  .p-lg-120 {
    padding: 12rem !important; }
  .p-lg-128 {
    padding: 12.8rem !important; }
  .p-lg-140 {
    padding: 14rem !important; }
  .p-lg-148 {
    padding: 14.8rem !important; }
  .p-lg-160 {
    padding: 16rem !important; }
  .p-lg-170 {
    padding: 17rem !important; }
  .p-lg-200 {
    padding: 20rem !important; }
  .p-lg-240 {
    padding: 24rem !important; }
  .p-lg-245 {
    padding: 24.5rem !important; }
  .p-lg-250 {
    padding: 25rem !important; }
  .p-lg-300 {
    padding: 30rem !important; }
  .p-lg-400 {
    padding: 40rem !important; }
  .p-lg-n25 {
    padding: -25% !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-lg-1 {
    padding-right: 0.1rem !important;
    padding-left: 0.1rem !important; }
  .px-lg-2 {
    padding-right: 0.2rem !important;
    padding-left: 0.2rem !important; }
  .px-lg-3 {
    padding-right: 0.3rem !important;
    padding-left: 0.3rem !important; }
  .px-lg-4 {
    padding-right: 0.4rem !important;
    padding-left: 0.4rem !important; }
  .px-lg-5 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-lg-6 {
    padding-right: 0.6rem !important;
    padding-left: 0.6rem !important; }
  .px-lg-8 {
    padding-right: 0.8rem !important;
    padding-left: 0.8rem !important; }
  .px-lg-10 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-lg-12 {
    padding-right: 1.2rem !important;
    padding-left: 1.2rem !important; }
  .px-lg-13 {
    padding-right: 1.3rem !important;
    padding-left: 1.3rem !important; }
  .px-lg-15 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-lg-16 {
    padding-right: 1.6rem !important;
    padding-left: 1.6rem !important; }
  .px-lg-20 {
    padding-right: 2rem !important;
    padding-left: 2rem !important; }
  .px-lg-24 {
    padding-right: 2.4rem !important;
    padding-left: 2.4rem !important; }
  .px-lg-25 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important; }
  .px-lg-29 {
    padding-right: 2.9rem !important;
    padding-left: 2.9rem !important; }
  .px-lg-30 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .px-lg-32 {
    padding-right: 3.2rem !important;
    padding-left: 3.2rem !important; }
  .px-lg-35 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important; }
  .px-lg-37 {
    padding-right: 3.7rem !important;
    padding-left: 3.7rem !important; }
  .px-lg-40 {
    padding-right: 4rem !important;
    padding-left: 4rem !important; }
  .px-lg-42 {
    padding-right: 4.2rem !important;
    padding-left: 4.2rem !important; }
  .px-lg-44 {
    padding-right: 4.4rem !important;
    padding-left: 4.4rem !important; }
  .px-lg-48 {
    padding-right: 4.8rem !important;
    padding-left: 4.8rem !important; }
  .px-lg-50 {
    padding-right: 5rem !important;
    padding-left: 5rem !important; }
  .px-lg-52 {
    padding-right: 5.2rem !important;
    padding-left: 5.2rem !important; }
  .px-lg-55 {
    padding-right: 5.5rem !important;
    padding-left: 5.5rem !important; }
  .px-lg-60 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .px-lg-62 {
    padding-right: 6.2rem !important;
    padding-left: 6.2rem !important; }
  .px-lg-64 {
    padding-right: 6.4rem !important;
    padding-left: 6.4rem !important; }
  .px-lg-65 {
    padding-right: 6.5rem !important;
    padding-left: 6.5rem !important; }
  .px-lg-70 {
    padding-right: 7rem !important;
    padding-left: 7rem !important; }
  .px-lg-72 {
    padding-right: 7.2rem !important;
    padding-left: 7.2rem !important; }
  .px-lg-80 {
    padding-right: 8rem !important;
    padding-left: 8rem !important; }
  .px-lg-85 {
    padding-right: 8.5rem !important;
    padding-left: 8.5rem !important; }
  .px-lg-95 {
    padding-right: 9.5rem !important;
    padding-left: 9.5rem !important; }
  .px-lg-96 {
    padding-right: 9.6rem !important;
    padding-left: 9.6rem !important; }
  .px-lg-100 {
    padding-right: 10rem !important;
    padding-left: 10rem !important; }
  .px-lg-104 {
    padding-right: 10.4rem !important;
    padding-left: 10.4rem !important; }
  .px-lg-108 {
    padding-right: 10.8rem !important;
    padding-left: 10.8rem !important; }
  .px-lg-110 {
    padding-right: 11rem !important;
    padding-left: 11rem !important; }
  .px-lg-116 {
    padding-right: 11.6rem !important;
    padding-left: 11.6rem !important; }
  .px-lg-120 {
    padding-right: 12rem !important;
    padding-left: 12rem !important; }
  .px-lg-128 {
    padding-right: 12.8rem !important;
    padding-left: 12.8rem !important; }
  .px-lg-140 {
    padding-right: 14rem !important;
    padding-left: 14rem !important; }
  .px-lg-148 {
    padding-right: 14.8rem !important;
    padding-left: 14.8rem !important; }
  .px-lg-160 {
    padding-right: 16rem !important;
    padding-left: 16rem !important; }
  .px-lg-170 {
    padding-right: 17rem !important;
    padding-left: 17rem !important; }
  .px-lg-200 {
    padding-right: 20rem !important;
    padding-left: 20rem !important; }
  .px-lg-240 {
    padding-right: 24rem !important;
    padding-left: 24rem !important; }
  .px-lg-245 {
    padding-right: 24.5rem !important;
    padding-left: 24.5rem !important; }
  .px-lg-250 {
    padding-right: 25rem !important;
    padding-left: 25rem !important; }
  .px-lg-300 {
    padding-right: 30rem !important;
    padding-left: 30rem !important; }
  .px-lg-400 {
    padding-right: 40rem !important;
    padding-left: 40rem !important; }
  .px-lg-n25 {
    padding-right: -25% !important;
    padding-left: -25% !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-lg-1 {
    padding-top: 0.1rem !important;
    padding-bottom: 0.1rem !important; }
  .py-lg-2 {
    padding-top: 0.2rem !important;
    padding-bottom: 0.2rem !important; }
  .py-lg-3 {
    padding-top: 0.3rem !important;
    padding-bottom: 0.3rem !important; }
  .py-lg-4 {
    padding-top: 0.4rem !important;
    padding-bottom: 0.4rem !important; }
  .py-lg-5 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-lg-6 {
    padding-top: 0.6rem !important;
    padding-bottom: 0.6rem !important; }
  .py-lg-8 {
    padding-top: 0.8rem !important;
    padding-bottom: 0.8rem !important; }
  .py-lg-10 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-lg-12 {
    padding-top: 1.2rem !important;
    padding-bottom: 1.2rem !important; }
  .py-lg-13 {
    padding-top: 1.3rem !important;
    padding-bottom: 1.3rem !important; }
  .py-lg-15 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-lg-16 {
    padding-top: 1.6rem !important;
    padding-bottom: 1.6rem !important; }
  .py-lg-20 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important; }
  .py-lg-24 {
    padding-top: 2.4rem !important;
    padding-bottom: 2.4rem !important; }
  .py-lg-25 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important; }
  .py-lg-29 {
    padding-top: 2.9rem !important;
    padding-bottom: 2.9rem !important; }
  .py-lg-30 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .py-lg-32 {
    padding-top: 3.2rem !important;
    padding-bottom: 3.2rem !important; }
  .py-lg-35 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important; }
  .py-lg-37 {
    padding-top: 3.7rem !important;
    padding-bottom: 3.7rem !important; }
  .py-lg-40 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important; }
  .py-lg-42 {
    padding-top: 4.2rem !important;
    padding-bottom: 4.2rem !important; }
  .py-lg-44 {
    padding-top: 4.4rem !important;
    padding-bottom: 4.4rem !important; }
  .py-lg-48 {
    padding-top: 4.8rem !important;
    padding-bottom: 4.8rem !important; }
  .py-lg-50 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important; }
  .py-lg-52 {
    padding-top: 5.2rem !important;
    padding-bottom: 5.2rem !important; }
  .py-lg-55 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important; }
  .py-lg-60 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .py-lg-62 {
    padding-top: 6.2rem !important;
    padding-bottom: 6.2rem !important; }
  .py-lg-64 {
    padding-top: 6.4rem !important;
    padding-bottom: 6.4rem !important; }
  .py-lg-65 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important; }
  .py-lg-70 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important; }
  .py-lg-72 {
    padding-top: 7.2rem !important;
    padding-bottom: 7.2rem !important; }
  .py-lg-80 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important; }
  .py-lg-85 {
    padding-top: 8.5rem !important;
    padding-bottom: 8.5rem !important; }
  .py-lg-95 {
    padding-top: 9.5rem !important;
    padding-bottom: 9.5rem !important; }
  .py-lg-96 {
    padding-top: 9.6rem !important;
    padding-bottom: 9.6rem !important; }
  .py-lg-100 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important; }
  .py-lg-104 {
    padding-top: 10.4rem !important;
    padding-bottom: 10.4rem !important; }
  .py-lg-108 {
    padding-top: 10.8rem !important;
    padding-bottom: 10.8rem !important; }
  .py-lg-110 {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important; }
  .py-lg-116 {
    padding-top: 11.6rem !important;
    padding-bottom: 11.6rem !important; }
  .py-lg-120 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important; }
  .py-lg-128 {
    padding-top: 12.8rem !important;
    padding-bottom: 12.8rem !important; }
  .py-lg-140 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important; }
  .py-lg-148 {
    padding-top: 14.8rem !important;
    padding-bottom: 14.8rem !important; }
  .py-lg-160 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important; }
  .py-lg-170 {
    padding-top: 17rem !important;
    padding-bottom: 17rem !important; }
  .py-lg-200 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important; }
  .py-lg-240 {
    padding-top: 24rem !important;
    padding-bottom: 24rem !important; }
  .py-lg-245 {
    padding-top: 24.5rem !important;
    padding-bottom: 24.5rem !important; }
  .py-lg-250 {
    padding-top: 25rem !important;
    padding-bottom: 25rem !important; }
  .py-lg-300 {
    padding-top: 30rem !important;
    padding-bottom: 30rem !important; }
  .py-lg-400 {
    padding-top: 40rem !important;
    padding-bottom: 40rem !important; }
  .py-lg-n25 {
    padding-top: -25% !important;
    padding-bottom: -25% !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pt-lg-1 {
    padding-top: 0.1rem !important; }
  .pt-lg-2 {
    padding-top: 0.2rem !important; }
  .pt-lg-3 {
    padding-top: 0.3rem !important; }
  .pt-lg-4 {
    padding-top: 0.4rem !important; }
  .pt-lg-5 {
    padding-top: 0.5rem !important; }
  .pt-lg-6 {
    padding-top: 0.6rem !important; }
  .pt-lg-8 {
    padding-top: 0.8rem !important; }
  .pt-lg-10 {
    padding-top: 1rem !important; }
  .pt-lg-12 {
    padding-top: 1.2rem !important; }
  .pt-lg-13 {
    padding-top: 1.3rem !important; }
  .pt-lg-15 {
    padding-top: 1.5rem !important; }
  .pt-lg-16 {
    padding-top: 1.6rem !important; }
  .pt-lg-20 {
    padding-top: 2rem !important; }
  .pt-lg-24 {
    padding-top: 2.4rem !important; }
  .pt-lg-25 {
    padding-top: 2.5rem !important; }
  .pt-lg-29 {
    padding-top: 2.9rem !important; }
  .pt-lg-30 {
    padding-top: 3rem !important; }
  .pt-lg-32 {
    padding-top: 3.2rem !important; }
  .pt-lg-35 {
    padding-top: 3.5rem !important; }
  .pt-lg-37 {
    padding-top: 3.7rem !important; }
  .pt-lg-40 {
    padding-top: 4rem !important; }
  .pt-lg-42 {
    padding-top: 4.2rem !important; }
  .pt-lg-44 {
    padding-top: 4.4rem !important; }
  .pt-lg-48 {
    padding-top: 4.8rem !important; }
  .pt-lg-50 {
    padding-top: 5rem !important; }
  .pt-lg-52 {
    padding-top: 5.2rem !important; }
  .pt-lg-55 {
    padding-top: 5.5rem !important; }
  .pt-lg-60 {
    padding-top: 6rem !important; }
  .pt-lg-62 {
    padding-top: 6.2rem !important; }
  .pt-lg-64 {
    padding-top: 6.4rem !important; }
  .pt-lg-65 {
    padding-top: 6.5rem !important; }
  .pt-lg-70 {
    padding-top: 7rem !important; }
  .pt-lg-72 {
    padding-top: 7.2rem !important; }
  .pt-lg-80 {
    padding-top: 8rem !important; }
  .pt-lg-85 {
    padding-top: 8.5rem !important; }
  .pt-lg-95 {
    padding-top: 9.5rem !important; }
  .pt-lg-96 {
    padding-top: 9.6rem !important; }
  .pt-lg-100 {
    padding-top: 10rem !important; }
  .pt-lg-104 {
    padding-top: 10.4rem !important; }
  .pt-lg-108 {
    padding-top: 10.8rem !important; }
  .pt-lg-110 {
    padding-top: 11rem !important; }
  .pt-lg-116 {
    padding-top: 11.6rem !important; }
  .pt-lg-120 {
    padding-top: 12rem !important; }
  .pt-lg-128 {
    padding-top: 12.8rem !important; }
  .pt-lg-140 {
    padding-top: 14rem !important; }
  .pt-lg-148 {
    padding-top: 14.8rem !important; }
  .pt-lg-160 {
    padding-top: 16rem !important; }
  .pt-lg-170 {
    padding-top: 17rem !important; }
  .pt-lg-200 {
    padding-top: 20rem !important; }
  .pt-lg-240 {
    padding-top: 24rem !important; }
  .pt-lg-245 {
    padding-top: 24.5rem !important; }
  .pt-lg-250 {
    padding-top: 25rem !important; }
  .pt-lg-300 {
    padding-top: 30rem !important; }
  .pt-lg-400 {
    padding-top: 40rem !important; }
  .pt-lg-n25 {
    padding-top: -25% !important; }
  .pe-lg-0 {
    padding-right: 0 !important; }
  .pe-lg-1 {
    padding-right: 0.1rem !important; }
  .pe-lg-2 {
    padding-right: 0.2rem !important; }
  .pe-lg-3 {
    padding-right: 0.3rem !important; }
  .pe-lg-4 {
    padding-right: 0.4rem !important; }
  .pe-lg-5 {
    padding-right: 0.5rem !important; }
  .pe-lg-6 {
    padding-right: 0.6rem !important; }
  .pe-lg-8 {
    padding-right: 0.8rem !important; }
  .pe-lg-10 {
    padding-right: 1rem !important; }
  .pe-lg-12 {
    padding-right: 1.2rem !important; }
  .pe-lg-13 {
    padding-right: 1.3rem !important; }
  .pe-lg-15 {
    padding-right: 1.5rem !important; }
  .pe-lg-16 {
    padding-right: 1.6rem !important; }
  .pe-lg-20 {
    padding-right: 2rem !important; }
  .pe-lg-24 {
    padding-right: 2.4rem !important; }
  .pe-lg-25 {
    padding-right: 2.5rem !important; }
  .pe-lg-29 {
    padding-right: 2.9rem !important; }
  .pe-lg-30 {
    padding-right: 3rem !important; }
  .pe-lg-32 {
    padding-right: 3.2rem !important; }
  .pe-lg-35 {
    padding-right: 3.5rem !important; }
  .pe-lg-37 {
    padding-right: 3.7rem !important; }
  .pe-lg-40 {
    padding-right: 4rem !important; }
  .pe-lg-42 {
    padding-right: 4.2rem !important; }
  .pe-lg-44 {
    padding-right: 4.4rem !important; }
  .pe-lg-48 {
    padding-right: 4.8rem !important; }
  .pe-lg-50 {
    padding-right: 5rem !important; }
  .pe-lg-52 {
    padding-right: 5.2rem !important; }
  .pe-lg-55 {
    padding-right: 5.5rem !important; }
  .pe-lg-60 {
    padding-right: 6rem !important; }
  .pe-lg-62 {
    padding-right: 6.2rem !important; }
  .pe-lg-64 {
    padding-right: 6.4rem !important; }
  .pe-lg-65 {
    padding-right: 6.5rem !important; }
  .pe-lg-70 {
    padding-right: 7rem !important; }
  .pe-lg-72 {
    padding-right: 7.2rem !important; }
  .pe-lg-80 {
    padding-right: 8rem !important; }
  .pe-lg-85 {
    padding-right: 8.5rem !important; }
  .pe-lg-95 {
    padding-right: 9.5rem !important; }
  .pe-lg-96 {
    padding-right: 9.6rem !important; }
  .pe-lg-100 {
    padding-right: 10rem !important; }
  .pe-lg-104 {
    padding-right: 10.4rem !important; }
  .pe-lg-108 {
    padding-right: 10.8rem !important; }
  .pe-lg-110 {
    padding-right: 11rem !important; }
  .pe-lg-116 {
    padding-right: 11.6rem !important; }
  .pe-lg-120 {
    padding-right: 12rem !important; }
  .pe-lg-128 {
    padding-right: 12.8rem !important; }
  .pe-lg-140 {
    padding-right: 14rem !important; }
  .pe-lg-148 {
    padding-right: 14.8rem !important; }
  .pe-lg-160 {
    padding-right: 16rem !important; }
  .pe-lg-170 {
    padding-right: 17rem !important; }
  .pe-lg-200 {
    padding-right: 20rem !important; }
  .pe-lg-240 {
    padding-right: 24rem !important; }
  .pe-lg-245 {
    padding-right: 24.5rem !important; }
  .pe-lg-250 {
    padding-right: 25rem !important; }
  .pe-lg-300 {
    padding-right: 30rem !important; }
  .pe-lg-400 {
    padding-right: 40rem !important; }
  .pe-lg-n25 {
    padding-right: -25% !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pb-lg-1 {
    padding-bottom: 0.1rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.2rem !important; }
  .pb-lg-3 {
    padding-bottom: 0.3rem !important; }
  .pb-lg-4 {
    padding-bottom: 0.4rem !important; }
  .pb-lg-5 {
    padding-bottom: 0.5rem !important; }
  .pb-lg-6 {
    padding-bottom: 0.6rem !important; }
  .pb-lg-8 {
    padding-bottom: 0.8rem !important; }
  .pb-lg-10 {
    padding-bottom: 1rem !important; }
  .pb-lg-12 {
    padding-bottom: 1.2rem !important; }
  .pb-lg-13 {
    padding-bottom: 1.3rem !important; }
  .pb-lg-15 {
    padding-bottom: 1.5rem !important; }
  .pb-lg-16 {
    padding-bottom: 1.6rem !important; }
  .pb-lg-20 {
    padding-bottom: 2rem !important; }
  .pb-lg-24 {
    padding-bottom: 2.4rem !important; }
  .pb-lg-25 {
    padding-bottom: 2.5rem !important; }
  .pb-lg-29 {
    padding-bottom: 2.9rem !important; }
  .pb-lg-30 {
    padding-bottom: 3rem !important; }
  .pb-lg-32 {
    padding-bottom: 3.2rem !important; }
  .pb-lg-35 {
    padding-bottom: 3.5rem !important; }
  .pb-lg-37 {
    padding-bottom: 3.7rem !important; }
  .pb-lg-40 {
    padding-bottom: 4rem !important; }
  .pb-lg-42 {
    padding-bottom: 4.2rem !important; }
  .pb-lg-44 {
    padding-bottom: 4.4rem !important; }
  .pb-lg-48 {
    padding-bottom: 4.8rem !important; }
  .pb-lg-50 {
    padding-bottom: 5rem !important; }
  .pb-lg-52 {
    padding-bottom: 5.2rem !important; }
  .pb-lg-55 {
    padding-bottom: 5.5rem !important; }
  .pb-lg-60 {
    padding-bottom: 6rem !important; }
  .pb-lg-62 {
    padding-bottom: 6.2rem !important; }
  .pb-lg-64 {
    padding-bottom: 6.4rem !important; }
  .pb-lg-65 {
    padding-bottom: 6.5rem !important; }
  .pb-lg-70 {
    padding-bottom: 7rem !important; }
  .pb-lg-72 {
    padding-bottom: 7.2rem !important; }
  .pb-lg-80 {
    padding-bottom: 8rem !important; }
  .pb-lg-85 {
    padding-bottom: 8.5rem !important; }
  .pb-lg-95 {
    padding-bottom: 9.5rem !important; }
  .pb-lg-96 {
    padding-bottom: 9.6rem !important; }
  .pb-lg-100 {
    padding-bottom: 10rem !important; }
  .pb-lg-104 {
    padding-bottom: 10.4rem !important; }
  .pb-lg-108 {
    padding-bottom: 10.8rem !important; }
  .pb-lg-110 {
    padding-bottom: 11rem !important; }
  .pb-lg-116 {
    padding-bottom: 11.6rem !important; }
  .pb-lg-120 {
    padding-bottom: 12rem !important; }
  .pb-lg-128 {
    padding-bottom: 12.8rem !important; }
  .pb-lg-140 {
    padding-bottom: 14rem !important; }
  .pb-lg-148 {
    padding-bottom: 14.8rem !important; }
  .pb-lg-160 {
    padding-bottom: 16rem !important; }
  .pb-lg-170 {
    padding-bottom: 17rem !important; }
  .pb-lg-200 {
    padding-bottom: 20rem !important; }
  .pb-lg-240 {
    padding-bottom: 24rem !important; }
  .pb-lg-245 {
    padding-bottom: 24.5rem !important; }
  .pb-lg-250 {
    padding-bottom: 25rem !important; }
  .pb-lg-300 {
    padding-bottom: 30rem !important; }
  .pb-lg-400 {
    padding-bottom: 40rem !important; }
  .pb-lg-n25 {
    padding-bottom: -25% !important; }
  .ps-lg-0 {
    padding-left: 0 !important; }
  .ps-lg-1 {
    padding-left: 0.1rem !important; }
  .ps-lg-2 {
    padding-left: 0.2rem !important; }
  .ps-lg-3 {
    padding-left: 0.3rem !important; }
  .ps-lg-4 {
    padding-left: 0.4rem !important; }
  .ps-lg-5 {
    padding-left: 0.5rem !important; }
  .ps-lg-6 {
    padding-left: 0.6rem !important; }
  .ps-lg-8 {
    padding-left: 0.8rem !important; }
  .ps-lg-10 {
    padding-left: 1rem !important; }
  .ps-lg-12 {
    padding-left: 1.2rem !important; }
  .ps-lg-13 {
    padding-left: 1.3rem !important; }
  .ps-lg-15 {
    padding-left: 1.5rem !important; }
  .ps-lg-16 {
    padding-left: 1.6rem !important; }
  .ps-lg-20 {
    padding-left: 2rem !important; }
  .ps-lg-24 {
    padding-left: 2.4rem !important; }
  .ps-lg-25 {
    padding-left: 2.5rem !important; }
  .ps-lg-29 {
    padding-left: 2.9rem !important; }
  .ps-lg-30 {
    padding-left: 3rem !important; }
  .ps-lg-32 {
    padding-left: 3.2rem !important; }
  .ps-lg-35 {
    padding-left: 3.5rem !important; }
  .ps-lg-37 {
    padding-left: 3.7rem !important; }
  .ps-lg-40 {
    padding-left: 4rem !important; }
  .ps-lg-42 {
    padding-left: 4.2rem !important; }
  .ps-lg-44 {
    padding-left: 4.4rem !important; }
  .ps-lg-48 {
    padding-left: 4.8rem !important; }
  .ps-lg-50 {
    padding-left: 5rem !important; }
  .ps-lg-52 {
    padding-left: 5.2rem !important; }
  .ps-lg-55 {
    padding-left: 5.5rem !important; }
  .ps-lg-60 {
    padding-left: 6rem !important; }
  .ps-lg-62 {
    padding-left: 6.2rem !important; }
  .ps-lg-64 {
    padding-left: 6.4rem !important; }
  .ps-lg-65 {
    padding-left: 6.5rem !important; }
  .ps-lg-70 {
    padding-left: 7rem !important; }
  .ps-lg-72 {
    padding-left: 7.2rem !important; }
  .ps-lg-80 {
    padding-left: 8rem !important; }
  .ps-lg-85 {
    padding-left: 8.5rem !important; }
  .ps-lg-95 {
    padding-left: 9.5rem !important; }
  .ps-lg-96 {
    padding-left: 9.6rem !important; }
  .ps-lg-100 {
    padding-left: 10rem !important; }
  .ps-lg-104 {
    padding-left: 10.4rem !important; }
  .ps-lg-108 {
    padding-left: 10.8rem !important; }
  .ps-lg-110 {
    padding-left: 11rem !important; }
  .ps-lg-116 {
    padding-left: 11.6rem !important; }
  .ps-lg-120 {
    padding-left: 12rem !important; }
  .ps-lg-128 {
    padding-left: 12.8rem !important; }
  .ps-lg-140 {
    padding-left: 14rem !important; }
  .ps-lg-148 {
    padding-left: 14.8rem !important; }
  .ps-lg-160 {
    padding-left: 16rem !important; }
  .ps-lg-170 {
    padding-left: 17rem !important; }
  .ps-lg-200 {
    padding-left: 20rem !important; }
  .ps-lg-240 {
    padding-left: 24rem !important; }
  .ps-lg-245 {
    padding-left: 24.5rem !important; }
  .ps-lg-250 {
    padding-left: 25rem !important; }
  .ps-lg-300 {
    padding-left: 30rem !important; }
  .ps-lg-400 {
    padding-left: 40rem !important; }
  .ps-lg-n25 {
    padding-left: -25% !important; }
  .gap-lg-0 {
    gap: 0 !important; }
  .gap-lg-1 {
    gap: 0.1rem !important; }
  .gap-lg-2 {
    gap: 0.2rem !important; }
  .gap-lg-3 {
    gap: 0.3rem !important; }
  .gap-lg-4 {
    gap: 0.4rem !important; }
  .gap-lg-5 {
    gap: 0.5rem !important; }
  .gap-lg-6 {
    gap: 0.6rem !important; }
  .gap-lg-8 {
    gap: 0.8rem !important; }
  .gap-lg-10 {
    gap: 1rem !important; }
  .gap-lg-12 {
    gap: 1.2rem !important; }
  .gap-lg-13 {
    gap: 1.3rem !important; }
  .gap-lg-15 {
    gap: 1.5rem !important; }
  .gap-lg-16 {
    gap: 1.6rem !important; }
  .gap-lg-20 {
    gap: 2rem !important; }
  .gap-lg-24 {
    gap: 2.4rem !important; }
  .gap-lg-25 {
    gap: 2.5rem !important; }
  .gap-lg-29 {
    gap: 2.9rem !important; }
  .gap-lg-30 {
    gap: 3rem !important; }
  .gap-lg-32 {
    gap: 3.2rem !important; }
  .gap-lg-35 {
    gap: 3.5rem !important; }
  .gap-lg-37 {
    gap: 3.7rem !important; }
  .gap-lg-40 {
    gap: 4rem !important; }
  .gap-lg-42 {
    gap: 4.2rem !important; }
  .gap-lg-44 {
    gap: 4.4rem !important; }
  .gap-lg-48 {
    gap: 4.8rem !important; }
  .gap-lg-50 {
    gap: 5rem !important; }
  .gap-lg-52 {
    gap: 5.2rem !important; }
  .gap-lg-55 {
    gap: 5.5rem !important; }
  .gap-lg-60 {
    gap: 6rem !important; }
  .gap-lg-62 {
    gap: 6.2rem !important; }
  .gap-lg-64 {
    gap: 6.4rem !important; }
  .gap-lg-65 {
    gap: 6.5rem !important; }
  .gap-lg-70 {
    gap: 7rem !important; }
  .gap-lg-72 {
    gap: 7.2rem !important; }
  .gap-lg-80 {
    gap: 8rem !important; }
  .gap-lg-85 {
    gap: 8.5rem !important; }
  .gap-lg-95 {
    gap: 9.5rem !important; }
  .gap-lg-96 {
    gap: 9.6rem !important; }
  .gap-lg-100 {
    gap: 10rem !important; }
  .gap-lg-104 {
    gap: 10.4rem !important; }
  .gap-lg-108 {
    gap: 10.8rem !important; }
  .gap-lg-110 {
    gap: 11rem !important; }
  .gap-lg-116 {
    gap: 11.6rem !important; }
  .gap-lg-120 {
    gap: 12rem !important; }
  .gap-lg-128 {
    gap: 12.8rem !important; }
  .gap-lg-140 {
    gap: 14rem !important; }
  .gap-lg-148 {
    gap: 14.8rem !important; }
  .gap-lg-160 {
    gap: 16rem !important; }
  .gap-lg-170 {
    gap: 17rem !important; }
  .gap-lg-200 {
    gap: 20rem !important; }
  .gap-lg-240 {
    gap: 24rem !important; }
  .gap-lg-245 {
    gap: 24.5rem !important; }
  .gap-lg-250 {
    gap: 25rem !important; }
  .gap-lg-300 {
    gap: 30rem !important; }
  .gap-lg-400 {
    gap: 40rem !important; }
  .gap-lg-n25 {
    gap: -25% !important; }
  .font-lg-family-gantari {
    font-family: "Gantari", sans-serif !important; }
  .font-lg-family-roboto {
    font-family: "Roboto", sans-serif !important; }
  .fs-lg-12 {
    font-size: 1.2rem !important; }
  .fs-lg-13 {
    font-size: 1.3rem !important; }
  .fs-lg-14 {
    font-size: 1.4rem !important; }
  .fs-lg-15 {
    font-size: 1.5rem !important; }
  .fs-lg-16 {
    font-size: 1.6rem !important; }
  .fs-lg-18 {
    font-size: 1.8rem !important; }
  .fs-lg-24 {
    font-size: 2.4rem !important; }
  .fs-lg-28 {
    font-size: 2.8rem !important; }
  .fs-lg-30 {
    font-size: 3rem !important; }
  .fs-lg-32 {
    font-size: 3.2rem !important; }
  .fs-lg-40 {
    font-size: 4rem !important; }
  .fs-lg-50 {
    font-size: 5rem !important; }
  .fs-lg-60 {
    font-size: 6rem !important; }
  .text-lg-start {
    text-align: left !important; }
  .text-lg-end {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; }
  .rounded-lg {
    border-radius: 0.8rem !important; }
  .rounded-lg-0 {
    border-radius: 0 !important; }
  .rounded-lg-1 {
    border-radius: 0.6rem !important; }
  .rounded-lg-2 {
    border-radius: 0.8rem !important; }
  .rounded-lg-3 {
    border-radius: 5px 20px 5px 20px !important; }
  .rounded-lg-4 {
    border-radius: 0.4rem !important; }
  .rounded-lg-5 {
    border-radius: 0.5rem !important; }
  .rounded-lg-8 {
    border-radius: 0.8rem !important; }
  .rounded-lg-9 {
    border-radius: 0.9rem !important; }
  .rounded-lg-15 {
    border-radius: 1.5rem !important; }
  .rounded-lg-16 {
    border-radius: 1.6rem !important; }
  .rounded-lg-64 {
    border-radius: 6.4rem !important; }
  .rounded-lg-circle {
    border-radius: 50% !important; }
  .rounded-lg-pill {
    border-radius: 50rem !important; }
  .rounded-lg-intro {
    border-radius: 50% 50% 0 0 !important; }
  .rounded-top-lg {
    border-top-left-radius: 0.8rem !important;
    border-top-right-radius: 0.8rem !important; }
  .rounded-top-lg-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important; }
  .rounded-top-lg-1 {
    border-top-left-radius: 0.6rem !important;
    border-top-right-radius: 0.6rem !important; }
  .rounded-top-lg-2 {
    border-top-left-radius: 0.8rem !important;
    border-top-right-radius: 0.8rem !important; }
  .rounded-top-lg-3 {
    border-top-left-radius: 5px 20px 5px 20px !important;
    border-top-right-radius: 5px 20px 5px 20px !important; }
  .rounded-top-lg-4 {
    border-top-left-radius: 0.4rem !important;
    border-top-right-radius: 0.4rem !important; }
  .rounded-top-lg-5 {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important; }
  .rounded-top-lg-8 {
    border-top-left-radius: 0.8rem !important;
    border-top-right-radius: 0.8rem !important; }
  .rounded-top-lg-9 {
    border-top-left-radius: 0.9rem !important;
    border-top-right-radius: 0.9rem !important; }
  .rounded-top-lg-15 {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important; }
  .rounded-top-lg-16 {
    border-top-left-radius: 1.6rem !important;
    border-top-right-radius: 1.6rem !important; }
  .rounded-top-lg-64 {
    border-top-left-radius: 6.4rem !important;
    border-top-right-radius: 6.4rem !important; }
  .rounded-top-lg-circle {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important; }
  .rounded-top-lg-pill {
    border-top-left-radius: 50rem !important;
    border-top-right-radius: 50rem !important; }
  .rounded-top-lg-intro {
    border-top-left-radius: 50% 50% 0 0 !important;
    border-top-right-radius: 50% 50% 0 0 !important; } }

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important; }
  .float-xl-end {
    float: right !important; }
  .float-xl-none {
    float: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-grid {
    display: grid !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; }
  .d-xl-none {
    display: none !important; }
  .position-xl-static {
    position: static !important; }
  .position-xl-relative {
    position: relative !important; }
  .position-xl-absolute {
    position: absolute !important; }
  .position-xl-fixed {
    position: fixed !important; }
  .position-xl-sticky {
    position: sticky !important; }
  .top-xl-0 {
    top: 0 !important; }
  .top-xl-5 {
    top: 5% !important; }
  .top-xl-25 {
    top: 25% !important; }
  .top-xl-50 {
    top: 50% !important; }
  .top-xl-90 {
    top: 90% !important; }
  .top-xl-100 {
    top: 100% !important; }
  .top-xl-10px {
    top: 10px !important; }
  .top-xl-14px {
    top: 14px !important; }
  .top-xl-24px {
    top: 24px !important; }
  .top-xl-50px {
    top: 50px !important; }
  .top-xl-70px {
    top: 70px !important; }
  .top-xl-100px {
    top: 100px !important; }
  .top-xl-120px {
    top: 120px !important; }
  .top-xl-150px {
    top: 150px !important; }
  .top-xl-170px {
    top: 170px !important; }
  .top-xl-200px {
    top: 200px !important; }
  .top-xl-230px {
    top: 230px !important; }
  .top-xl-240px {
    top: 240px !important; }
  .top-xl-250px {
    top: 250px !important; }
  .top-xl-n8px {
    top: -8px !important; }
  .top-xl-n10px {
    top: -10px !important; }
  .top-xl-n19px {
    top: -19px !important; }
  .top-xl-n25px {
    top: -25px !important; }
  .top-xl-n50px {
    top: -50px !important; }
  .top-xl-n90px {
    top: -90px !important; }
  .top-xl-n200px {
    top: -200px !important; }
  .top-xl-n240px {
    top: -240px !important; }
  .top-xl-n340px {
    top: -340px !important; }
  .top-xl-n350px {
    top: -350px !important; }
  .top-xl-100n20 {
    top: calc(100% + 20px) !important; }
  .top-xl-100n50 {
    top: calc(100% - 50px) !important; }
  .top-xl-100n100 {
    top: calc(100% + 100px) !important; }
  .top-xl-100n200 {
    top: calc(100% + 200px) !important; }
  .top-xl-100n250 {
    top: calc(100% + 250px) !important; }
  .top-xl-100n350 {
    top: calc(100% + 350px) !important; }
  .bottom-xl-0 {
    bottom: 0 !important; }
  .bottom-xl-5 {
    bottom: 5% !important; }
  .bottom-xl-25 {
    bottom: 25% !important; }
  .bottom-xl-50 {
    bottom: 50% !important; }
  .bottom-xl-90 {
    bottom: 90% !important; }
  .bottom-xl-100 {
    bottom: 100% !important; }
  .bottom-xl-10px {
    bottom: 10px !important; }
  .bottom-xl-14px {
    bottom: 14px !important; }
  .bottom-xl-24px {
    bottom: 24px !important; }
  .bottom-xl-50px {
    bottom: 50px !important; }
  .bottom-xl-70px {
    bottom: 70px !important; }
  .bottom-xl-100px {
    bottom: 100px !important; }
  .bottom-xl-120px {
    bottom: 120px !important; }
  .bottom-xl-150px {
    bottom: 150px !important; }
  .bottom-xl-170px {
    bottom: 170px !important; }
  .bottom-xl-200px {
    bottom: 200px !important; }
  .bottom-xl-230px {
    bottom: 230px !important; }
  .bottom-xl-240px {
    bottom: 240px !important; }
  .bottom-xl-250px {
    bottom: 250px !important; }
  .bottom-xl-n8px {
    bottom: -8px !important; }
  .bottom-xl-n10px {
    bottom: -10px !important; }
  .bottom-xl-n19px {
    bottom: -19px !important; }
  .bottom-xl-n25px {
    bottom: -25px !important; }
  .bottom-xl-n50px {
    bottom: -50px !important; }
  .bottom-xl-n90px {
    bottom: -90px !important; }
  .bottom-xl-n200px {
    bottom: -200px !important; }
  .bottom-xl-n240px {
    bottom: -240px !important; }
  .bottom-xl-n340px {
    bottom: -340px !important; }
  .bottom-xl-n350px {
    bottom: -350px !important; }
  .bottom-xl-100n20 {
    bottom: calc(100% + 20px) !important; }
  .bottom-xl-100n50 {
    bottom: calc(100% - 50px) !important; }
  .bottom-xl-100n100 {
    bottom: calc(100% + 100px) !important; }
  .bottom-xl-100n200 {
    bottom: calc(100% + 200px) !important; }
  .bottom-xl-100n250 {
    bottom: calc(100% + 250px) !important; }
  .bottom-xl-100n350 {
    bottom: calc(100% + 350px) !important; }
  .start-xl-0 {
    left: 0 !important; }
  .start-xl-5 {
    left: 5% !important; }
  .start-xl-25 {
    left: 25% !important; }
  .start-xl-50 {
    left: 50% !important; }
  .start-xl-90 {
    left: 90% !important; }
  .start-xl-100 {
    left: 100% !important; }
  .start-xl-10px {
    left: 10px !important; }
  .start-xl-14px {
    left: 14px !important; }
  .start-xl-24px {
    left: 24px !important; }
  .start-xl-50px {
    left: 50px !important; }
  .start-xl-70px {
    left: 70px !important; }
  .start-xl-100px {
    left: 100px !important; }
  .start-xl-120px {
    left: 120px !important; }
  .start-xl-150px {
    left: 150px !important; }
  .start-xl-170px {
    left: 170px !important; }
  .start-xl-200px {
    left: 200px !important; }
  .start-xl-230px {
    left: 230px !important; }
  .start-xl-240px {
    left: 240px !important; }
  .start-xl-250px {
    left: 250px !important; }
  .start-xl-n8px {
    left: -8px !important; }
  .start-xl-n10px {
    left: -10px !important; }
  .start-xl-n19px {
    left: -19px !important; }
  .start-xl-n25px {
    left: -25px !important; }
  .start-xl-n50px {
    left: -50px !important; }
  .start-xl-n90px {
    left: -90px !important; }
  .start-xl-n200px {
    left: -200px !important; }
  .start-xl-n240px {
    left: -240px !important; }
  .start-xl-n340px {
    left: -340px !important; }
  .start-xl-n350px {
    left: -350px !important; }
  .start-xl-100n20 {
    left: calc(100% + 20px) !important; }
  .start-xl-100n50 {
    left: calc(100% - 50px) !important; }
  .start-xl-100n100 {
    left: calc(100% + 100px) !important; }
  .start-xl-100n200 {
    left: calc(100% + 200px) !important; }
  .start-xl-100n250 {
    left: calc(100% + 250px) !important; }
  .start-xl-100n350 {
    left: calc(100% + 350px) !important; }
  .end-xl-0 {
    right: 0 !important; }
  .end-xl-5 {
    right: 5% !important; }
  .end-xl-25 {
    right: 25% !important; }
  .end-xl-50 {
    right: 50% !important; }
  .end-xl-90 {
    right: 90% !important; }
  .end-xl-100 {
    right: 100% !important; }
  .end-xl-10px {
    right: 10px !important; }
  .end-xl-14px {
    right: 14px !important; }
  .end-xl-24px {
    right: 24px !important; }
  .end-xl-50px {
    right: 50px !important; }
  .end-xl-70px {
    right: 70px !important; }
  .end-xl-100px {
    right: 100px !important; }
  .end-xl-120px {
    right: 120px !important; }
  .end-xl-150px {
    right: 150px !important; }
  .end-xl-170px {
    right: 170px !important; }
  .end-xl-200px {
    right: 200px !important; }
  .end-xl-230px {
    right: 230px !important; }
  .end-xl-240px {
    right: 240px !important; }
  .end-xl-250px {
    right: 250px !important; }
  .end-xl-n8px {
    right: -8px !important; }
  .end-xl-n10px {
    right: -10px !important; }
  .end-xl-n19px {
    right: -19px !important; }
  .end-xl-n25px {
    right: -25px !important; }
  .end-xl-n50px {
    right: -50px !important; }
  .end-xl-n90px {
    right: -90px !important; }
  .end-xl-n200px {
    right: -200px !important; }
  .end-xl-n240px {
    right: -240px !important; }
  .end-xl-n340px {
    right: -340px !important; }
  .end-xl-n350px {
    right: -350px !important; }
  .end-xl-100n20 {
    right: calc(100% + 20px) !important; }
  .end-xl-100n50 {
    right: calc(100% - 50px) !important; }
  .end-xl-100n100 {
    right: calc(100% + 100px) !important; }
  .end-xl-100n200 {
    right: calc(100% + 200px) !important; }
  .end-xl-100n250 {
    right: calc(100% + 250px) !important; }
  .end-xl-100n350 {
    right: calc(100% + 350px) !important; }
  .w-xl-25 {
    width: 25% !important; }
  .w-xl-50 {
    width: 50% !important; }
  .w-xl-75 {
    width: 75% !important; }
  .w-xl-100 {
    width: 100% !important; }
  .w-xl-auto {
    width: auto !important; }
  .w-xl-135px {
    width: 135px !important; }
  .w-xl-150px {
    width: 150px !important; }
  .w-xl-180px {
    width: 180px !important; }
  .w-xl-190px {
    width: 190px !important; }
  .w-xl-200px {
    width: 200px !important; }
  .w-xl-240px {
    width: 240px !important; }
  .w-xl-260px {
    width: 260px !important; }
  .w-xl-296px {
    width: 296px !important; }
  .w-xl-340px {
    width: 340px !important; }
  .w-xl-368px {
    width: 368px !important; }
  .w-xl-400px {
    width: 400px !important; }
  .w-xl-500px {
    width: 500px !important; }
  .w-xl-520px {
    width: 520px !important; }
  .w-xl-800px {
    width: 800px !important; }
  .h-xl-25 {
    height: 25% !important; }
  .h-xl-50 {
    height: 50% !important; }
  .h-xl-75 {
    height: 75% !important; }
  .h-xl-100 {
    height: 100% !important; }
  .h-xl-auto {
    height: auto !important; }
  .h-xl-135px {
    height: 135px !important; }
  .h-xl-150px {
    height: 150px !important; }
  .h-xl-180px {
    height: 180px !important; }
  .h-xl-190px {
    height: 190px !important; }
  .h-xl-200px {
    height: 200px !important; }
  .h-xl-240px {
    height: 240px !important; }
  .h-xl-260px {
    height: 260px !important; }
  .h-xl-296px {
    height: 296px !important; }
  .h-xl-340px {
    height: 340px !important; }
  .h-xl-368px {
    height: 368px !important; }
  .h-xl-400px {
    height: 400px !important; }
  .h-xl-500px {
    height: 500px !important; }
  .h-xl-520px {
    height: 520px !important; }
  .h-xl-800px {
    height: 800px !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; }
  .order-xl-first {
    order: -1 !important; }
  .order-xl-0 {
    order: 0 !important; }
  .order-xl-1 {
    order: 1 !important; }
  .order-xl-2 {
    order: 2 !important; }
  .order-xl-3 {
    order: 3 !important; }
  .order-xl-4 {
    order: 4 !important; }
  .order-xl-5 {
    order: 5 !important; }
  .order-xl-last {
    order: 6 !important; }
  .m-xl-0 {
    margin: 0 !important; }
  .m-xl-1 {
    margin: 0.1rem !important; }
  .m-xl-2 {
    margin: 0.2rem !important; }
  .m-xl-3 {
    margin: 0.3rem !important; }
  .m-xl-4 {
    margin: 0.4rem !important; }
  .m-xl-5 {
    margin: 0.5rem !important; }
  .m-xl-6 {
    margin: 0.6rem !important; }
  .m-xl-8 {
    margin: 0.8rem !important; }
  .m-xl-10 {
    margin: 1rem !important; }
  .m-xl-12 {
    margin: 1.2rem !important; }
  .m-xl-13 {
    margin: 1.3rem !important; }
  .m-xl-15 {
    margin: 1.5rem !important; }
  .m-xl-16 {
    margin: 1.6rem !important; }
  .m-xl-20 {
    margin: 2rem !important; }
  .m-xl-24 {
    margin: 2.4rem !important; }
  .m-xl-25 {
    margin: 2.5rem !important; }
  .m-xl-29 {
    margin: 2.9rem !important; }
  .m-xl-30 {
    margin: 3rem !important; }
  .m-xl-32 {
    margin: 3.2rem !important; }
  .m-xl-35 {
    margin: 3.5rem !important; }
  .m-xl-37 {
    margin: 3.7rem !important; }
  .m-xl-40 {
    margin: 4rem !important; }
  .m-xl-42 {
    margin: 4.2rem !important; }
  .m-xl-44 {
    margin: 4.4rem !important; }
  .m-xl-48 {
    margin: 4.8rem !important; }
  .m-xl-50 {
    margin: 5rem !important; }
  .m-xl-52 {
    margin: 5.2rem !important; }
  .m-xl-55 {
    margin: 5.5rem !important; }
  .m-xl-60 {
    margin: 6rem !important; }
  .m-xl-62 {
    margin: 6.2rem !important; }
  .m-xl-64 {
    margin: 6.4rem !important; }
  .m-xl-65 {
    margin: 6.5rem !important; }
  .m-xl-70 {
    margin: 7rem !important; }
  .m-xl-72 {
    margin: 7.2rem !important; }
  .m-xl-80 {
    margin: 8rem !important; }
  .m-xl-85 {
    margin: 8.5rem !important; }
  .m-xl-95 {
    margin: 9.5rem !important; }
  .m-xl-96 {
    margin: 9.6rem !important; }
  .m-xl-100 {
    margin: 10rem !important; }
  .m-xl-104 {
    margin: 10.4rem !important; }
  .m-xl-108 {
    margin: 10.8rem !important; }
  .m-xl-110 {
    margin: 11rem !important; }
  .m-xl-116 {
    margin: 11.6rem !important; }
  .m-xl-120 {
    margin: 12rem !important; }
  .m-xl-128 {
    margin: 12.8rem !important; }
  .m-xl-140 {
    margin: 14rem !important; }
  .m-xl-148 {
    margin: 14.8rem !important; }
  .m-xl-160 {
    margin: 16rem !important; }
  .m-xl-170 {
    margin: 17rem !important; }
  .m-xl-200 {
    margin: 20rem !important; }
  .m-xl-240 {
    margin: 24rem !important; }
  .m-xl-245 {
    margin: 24.5rem !important; }
  .m-xl-250 {
    margin: 25rem !important; }
  .m-xl-300 {
    margin: 30rem !important; }
  .m-xl-400 {
    margin: 40rem !important; }
  .m-xl-n25 {
    margin: -25% !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xl-1 {
    margin-right: 0.1rem !important;
    margin-left: 0.1rem !important; }
  .mx-xl-2 {
    margin-right: 0.2rem !important;
    margin-left: 0.2rem !important; }
  .mx-xl-3 {
    margin-right: 0.3rem !important;
    margin-left: 0.3rem !important; }
  .mx-xl-4 {
    margin-right: 0.4rem !important;
    margin-left: 0.4rem !important; }
  .mx-xl-5 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xl-6 {
    margin-right: 0.6rem !important;
    margin-left: 0.6rem !important; }
  .mx-xl-8 {
    margin-right: 0.8rem !important;
    margin-left: 0.8rem !important; }
  .mx-xl-10 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xl-12 {
    margin-right: 1.2rem !important;
    margin-left: 1.2rem !important; }
  .mx-xl-13 {
    margin-right: 1.3rem !important;
    margin-left: 1.3rem !important; }
  .mx-xl-15 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xl-16 {
    margin-right: 1.6rem !important;
    margin-left: 1.6rem !important; }
  .mx-xl-20 {
    margin-right: 2rem !important;
    margin-left: 2rem !important; }
  .mx-xl-24 {
    margin-right: 2.4rem !important;
    margin-left: 2.4rem !important; }
  .mx-xl-25 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important; }
  .mx-xl-29 {
    margin-right: 2.9rem !important;
    margin-left: 2.9rem !important; }
  .mx-xl-30 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xl-32 {
    margin-right: 3.2rem !important;
    margin-left: 3.2rem !important; }
  .mx-xl-35 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important; }
  .mx-xl-37 {
    margin-right: 3.7rem !important;
    margin-left: 3.7rem !important; }
  .mx-xl-40 {
    margin-right: 4rem !important;
    margin-left: 4rem !important; }
  .mx-xl-42 {
    margin-right: 4.2rem !important;
    margin-left: 4.2rem !important; }
  .mx-xl-44 {
    margin-right: 4.4rem !important;
    margin-left: 4.4rem !important; }
  .mx-xl-48 {
    margin-right: 4.8rem !important;
    margin-left: 4.8rem !important; }
  .mx-xl-50 {
    margin-right: 5rem !important;
    margin-left: 5rem !important; }
  .mx-xl-52 {
    margin-right: 5.2rem !important;
    margin-left: 5.2rem !important; }
  .mx-xl-55 {
    margin-right: 5.5rem !important;
    margin-left: 5.5rem !important; }
  .mx-xl-60 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .mx-xl-62 {
    margin-right: 6.2rem !important;
    margin-left: 6.2rem !important; }
  .mx-xl-64 {
    margin-right: 6.4rem !important;
    margin-left: 6.4rem !important; }
  .mx-xl-65 {
    margin-right: 6.5rem !important;
    margin-left: 6.5rem !important; }
  .mx-xl-70 {
    margin-right: 7rem !important;
    margin-left: 7rem !important; }
  .mx-xl-72 {
    margin-right: 7.2rem !important;
    margin-left: 7.2rem !important; }
  .mx-xl-80 {
    margin-right: 8rem !important;
    margin-left: 8rem !important; }
  .mx-xl-85 {
    margin-right: 8.5rem !important;
    margin-left: 8.5rem !important; }
  .mx-xl-95 {
    margin-right: 9.5rem !important;
    margin-left: 9.5rem !important; }
  .mx-xl-96 {
    margin-right: 9.6rem !important;
    margin-left: 9.6rem !important; }
  .mx-xl-100 {
    margin-right: 10rem !important;
    margin-left: 10rem !important; }
  .mx-xl-104 {
    margin-right: 10.4rem !important;
    margin-left: 10.4rem !important; }
  .mx-xl-108 {
    margin-right: 10.8rem !important;
    margin-left: 10.8rem !important; }
  .mx-xl-110 {
    margin-right: 11rem !important;
    margin-left: 11rem !important; }
  .mx-xl-116 {
    margin-right: 11.6rem !important;
    margin-left: 11.6rem !important; }
  .mx-xl-120 {
    margin-right: 12rem !important;
    margin-left: 12rem !important; }
  .mx-xl-128 {
    margin-right: 12.8rem !important;
    margin-left: 12.8rem !important; }
  .mx-xl-140 {
    margin-right: 14rem !important;
    margin-left: 14rem !important; }
  .mx-xl-148 {
    margin-right: 14.8rem !important;
    margin-left: 14.8rem !important; }
  .mx-xl-160 {
    margin-right: 16rem !important;
    margin-left: 16rem !important; }
  .mx-xl-170 {
    margin-right: 17rem !important;
    margin-left: 17rem !important; }
  .mx-xl-200 {
    margin-right: 20rem !important;
    margin-left: 20rem !important; }
  .mx-xl-240 {
    margin-right: 24rem !important;
    margin-left: 24rem !important; }
  .mx-xl-245 {
    margin-right: 24.5rem !important;
    margin-left: 24.5rem !important; }
  .mx-xl-250 {
    margin-right: 25rem !important;
    margin-left: 25rem !important; }
  .mx-xl-300 {
    margin-right: 30rem !important;
    margin-left: 30rem !important; }
  .mx-xl-400 {
    margin-right: 40rem !important;
    margin-left: 40rem !important; }
  .mx-xl-n25 {
    margin-right: -25% !important;
    margin-left: -25% !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xl-1 {
    margin-top: 0.1rem !important;
    margin-bottom: 0.1rem !important; }
  .my-xl-2 {
    margin-top: 0.2rem !important;
    margin-bottom: 0.2rem !important; }
  .my-xl-3 {
    margin-top: 0.3rem !important;
    margin-bottom: 0.3rem !important; }
  .my-xl-4 {
    margin-top: 0.4rem !important;
    margin-bottom: 0.4rem !important; }
  .my-xl-5 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xl-6 {
    margin-top: 0.6rem !important;
    margin-bottom: 0.6rem !important; }
  .my-xl-8 {
    margin-top: 0.8rem !important;
    margin-bottom: 0.8rem !important; }
  .my-xl-10 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xl-12 {
    margin-top: 1.2rem !important;
    margin-bottom: 1.2rem !important; }
  .my-xl-13 {
    margin-top: 1.3rem !important;
    margin-bottom: 1.3rem !important; }
  .my-xl-15 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xl-16 {
    margin-top: 1.6rem !important;
    margin-bottom: 1.6rem !important; }
  .my-xl-20 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important; }
  .my-xl-24 {
    margin-top: 2.4rem !important;
    margin-bottom: 2.4rem !important; }
  .my-xl-25 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important; }
  .my-xl-29 {
    margin-top: 2.9rem !important;
    margin-bottom: 2.9rem !important; }
  .my-xl-30 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xl-32 {
    margin-top: 3.2rem !important;
    margin-bottom: 3.2rem !important; }
  .my-xl-35 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important; }
  .my-xl-37 {
    margin-top: 3.7rem !important;
    margin-bottom: 3.7rem !important; }
  .my-xl-40 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important; }
  .my-xl-42 {
    margin-top: 4.2rem !important;
    margin-bottom: 4.2rem !important; }
  .my-xl-44 {
    margin-top: 4.4rem !important;
    margin-bottom: 4.4rem !important; }
  .my-xl-48 {
    margin-top: 4.8rem !important;
    margin-bottom: 4.8rem !important; }
  .my-xl-50 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important; }
  .my-xl-52 {
    margin-top: 5.2rem !important;
    margin-bottom: 5.2rem !important; }
  .my-xl-55 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important; }
  .my-xl-60 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .my-xl-62 {
    margin-top: 6.2rem !important;
    margin-bottom: 6.2rem !important; }
  .my-xl-64 {
    margin-top: 6.4rem !important;
    margin-bottom: 6.4rem !important; }
  .my-xl-65 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important; }
  .my-xl-70 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important; }
  .my-xl-72 {
    margin-top: 7.2rem !important;
    margin-bottom: 7.2rem !important; }
  .my-xl-80 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important; }
  .my-xl-85 {
    margin-top: 8.5rem !important;
    margin-bottom: 8.5rem !important; }
  .my-xl-95 {
    margin-top: 9.5rem !important;
    margin-bottom: 9.5rem !important; }
  .my-xl-96 {
    margin-top: 9.6rem !important;
    margin-bottom: 9.6rem !important; }
  .my-xl-100 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important; }
  .my-xl-104 {
    margin-top: 10.4rem !important;
    margin-bottom: 10.4rem !important; }
  .my-xl-108 {
    margin-top: 10.8rem !important;
    margin-bottom: 10.8rem !important; }
  .my-xl-110 {
    margin-top: 11rem !important;
    margin-bottom: 11rem !important; }
  .my-xl-116 {
    margin-top: 11.6rem !important;
    margin-bottom: 11.6rem !important; }
  .my-xl-120 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important; }
  .my-xl-128 {
    margin-top: 12.8rem !important;
    margin-bottom: 12.8rem !important; }
  .my-xl-140 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important; }
  .my-xl-148 {
    margin-top: 14.8rem !important;
    margin-bottom: 14.8rem !important; }
  .my-xl-160 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important; }
  .my-xl-170 {
    margin-top: 17rem !important;
    margin-bottom: 17rem !important; }
  .my-xl-200 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important; }
  .my-xl-240 {
    margin-top: 24rem !important;
    margin-bottom: 24rem !important; }
  .my-xl-245 {
    margin-top: 24.5rem !important;
    margin-bottom: 24.5rem !important; }
  .my-xl-250 {
    margin-top: 25rem !important;
    margin-bottom: 25rem !important; }
  .my-xl-300 {
    margin-top: 30rem !important;
    margin-bottom: 30rem !important; }
  .my-xl-400 {
    margin-top: 40rem !important;
    margin-bottom: 40rem !important; }
  .my-xl-n25 {
    margin-top: -25% !important;
    margin-bottom: -25% !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mt-xl-1 {
    margin-top: 0.1rem !important; }
  .mt-xl-2 {
    margin-top: 0.2rem !important; }
  .mt-xl-3 {
    margin-top: 0.3rem !important; }
  .mt-xl-4 {
    margin-top: 0.4rem !important; }
  .mt-xl-5 {
    margin-top: 0.5rem !important; }
  .mt-xl-6 {
    margin-top: 0.6rem !important; }
  .mt-xl-8 {
    margin-top: 0.8rem !important; }
  .mt-xl-10 {
    margin-top: 1rem !important; }
  .mt-xl-12 {
    margin-top: 1.2rem !important; }
  .mt-xl-13 {
    margin-top: 1.3rem !important; }
  .mt-xl-15 {
    margin-top: 1.5rem !important; }
  .mt-xl-16 {
    margin-top: 1.6rem !important; }
  .mt-xl-20 {
    margin-top: 2rem !important; }
  .mt-xl-24 {
    margin-top: 2.4rem !important; }
  .mt-xl-25 {
    margin-top: 2.5rem !important; }
  .mt-xl-29 {
    margin-top: 2.9rem !important; }
  .mt-xl-30 {
    margin-top: 3rem !important; }
  .mt-xl-32 {
    margin-top: 3.2rem !important; }
  .mt-xl-35 {
    margin-top: 3.5rem !important; }
  .mt-xl-37 {
    margin-top: 3.7rem !important; }
  .mt-xl-40 {
    margin-top: 4rem !important; }
  .mt-xl-42 {
    margin-top: 4.2rem !important; }
  .mt-xl-44 {
    margin-top: 4.4rem !important; }
  .mt-xl-48 {
    margin-top: 4.8rem !important; }
  .mt-xl-50 {
    margin-top: 5rem !important; }
  .mt-xl-52 {
    margin-top: 5.2rem !important; }
  .mt-xl-55 {
    margin-top: 5.5rem !important; }
  .mt-xl-60 {
    margin-top: 6rem !important; }
  .mt-xl-62 {
    margin-top: 6.2rem !important; }
  .mt-xl-64 {
    margin-top: 6.4rem !important; }
  .mt-xl-65 {
    margin-top: 6.5rem !important; }
  .mt-xl-70 {
    margin-top: 7rem !important; }
  .mt-xl-72 {
    margin-top: 7.2rem !important; }
  .mt-xl-80 {
    margin-top: 8rem !important; }
  .mt-xl-85 {
    margin-top: 8.5rem !important; }
  .mt-xl-95 {
    margin-top: 9.5rem !important; }
  .mt-xl-96 {
    margin-top: 9.6rem !important; }
  .mt-xl-100 {
    margin-top: 10rem !important; }
  .mt-xl-104 {
    margin-top: 10.4rem !important; }
  .mt-xl-108 {
    margin-top: 10.8rem !important; }
  .mt-xl-110 {
    margin-top: 11rem !important; }
  .mt-xl-116 {
    margin-top: 11.6rem !important; }
  .mt-xl-120 {
    margin-top: 12rem !important; }
  .mt-xl-128 {
    margin-top: 12.8rem !important; }
  .mt-xl-140 {
    margin-top: 14rem !important; }
  .mt-xl-148 {
    margin-top: 14.8rem !important; }
  .mt-xl-160 {
    margin-top: 16rem !important; }
  .mt-xl-170 {
    margin-top: 17rem !important; }
  .mt-xl-200 {
    margin-top: 20rem !important; }
  .mt-xl-240 {
    margin-top: 24rem !important; }
  .mt-xl-245 {
    margin-top: 24.5rem !important; }
  .mt-xl-250 {
    margin-top: 25rem !important; }
  .mt-xl-300 {
    margin-top: 30rem !important; }
  .mt-xl-400 {
    margin-top: 40rem !important; }
  .mt-xl-n25 {
    margin-top: -25% !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .me-xl-0 {
    margin-right: 0 !important; }
  .me-xl-1 {
    margin-right: 0.1rem !important; }
  .me-xl-2 {
    margin-right: 0.2rem !important; }
  .me-xl-3 {
    margin-right: 0.3rem !important; }
  .me-xl-4 {
    margin-right: 0.4rem !important; }
  .me-xl-5 {
    margin-right: 0.5rem !important; }
  .me-xl-6 {
    margin-right: 0.6rem !important; }
  .me-xl-8 {
    margin-right: 0.8rem !important; }
  .me-xl-10 {
    margin-right: 1rem !important; }
  .me-xl-12 {
    margin-right: 1.2rem !important; }
  .me-xl-13 {
    margin-right: 1.3rem !important; }
  .me-xl-15 {
    margin-right: 1.5rem !important; }
  .me-xl-16 {
    margin-right: 1.6rem !important; }
  .me-xl-20 {
    margin-right: 2rem !important; }
  .me-xl-24 {
    margin-right: 2.4rem !important; }
  .me-xl-25 {
    margin-right: 2.5rem !important; }
  .me-xl-29 {
    margin-right: 2.9rem !important; }
  .me-xl-30 {
    margin-right: 3rem !important; }
  .me-xl-32 {
    margin-right: 3.2rem !important; }
  .me-xl-35 {
    margin-right: 3.5rem !important; }
  .me-xl-37 {
    margin-right: 3.7rem !important; }
  .me-xl-40 {
    margin-right: 4rem !important; }
  .me-xl-42 {
    margin-right: 4.2rem !important; }
  .me-xl-44 {
    margin-right: 4.4rem !important; }
  .me-xl-48 {
    margin-right: 4.8rem !important; }
  .me-xl-50 {
    margin-right: 5rem !important; }
  .me-xl-52 {
    margin-right: 5.2rem !important; }
  .me-xl-55 {
    margin-right: 5.5rem !important; }
  .me-xl-60 {
    margin-right: 6rem !important; }
  .me-xl-62 {
    margin-right: 6.2rem !important; }
  .me-xl-64 {
    margin-right: 6.4rem !important; }
  .me-xl-65 {
    margin-right: 6.5rem !important; }
  .me-xl-70 {
    margin-right: 7rem !important; }
  .me-xl-72 {
    margin-right: 7.2rem !important; }
  .me-xl-80 {
    margin-right: 8rem !important; }
  .me-xl-85 {
    margin-right: 8.5rem !important; }
  .me-xl-95 {
    margin-right: 9.5rem !important; }
  .me-xl-96 {
    margin-right: 9.6rem !important; }
  .me-xl-100 {
    margin-right: 10rem !important; }
  .me-xl-104 {
    margin-right: 10.4rem !important; }
  .me-xl-108 {
    margin-right: 10.8rem !important; }
  .me-xl-110 {
    margin-right: 11rem !important; }
  .me-xl-116 {
    margin-right: 11.6rem !important; }
  .me-xl-120 {
    margin-right: 12rem !important; }
  .me-xl-128 {
    margin-right: 12.8rem !important; }
  .me-xl-140 {
    margin-right: 14rem !important; }
  .me-xl-148 {
    margin-right: 14.8rem !important; }
  .me-xl-160 {
    margin-right: 16rem !important; }
  .me-xl-170 {
    margin-right: 17rem !important; }
  .me-xl-200 {
    margin-right: 20rem !important; }
  .me-xl-240 {
    margin-right: 24rem !important; }
  .me-xl-245 {
    margin-right: 24.5rem !important; }
  .me-xl-250 {
    margin-right: 25rem !important; }
  .me-xl-300 {
    margin-right: 30rem !important; }
  .me-xl-400 {
    margin-right: 40rem !important; }
  .me-xl-n25 {
    margin-right: -25% !important; }
  .me-xl-auto {
    margin-right: auto !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .mb-xl-1 {
    margin-bottom: 0.1rem !important; }
  .mb-xl-2 {
    margin-bottom: 0.2rem !important; }
  .mb-xl-3 {
    margin-bottom: 0.3rem !important; }
  .mb-xl-4 {
    margin-bottom: 0.4rem !important; }
  .mb-xl-5 {
    margin-bottom: 0.5rem !important; }
  .mb-xl-6 {
    margin-bottom: 0.6rem !important; }
  .mb-xl-8 {
    margin-bottom: 0.8rem !important; }
  .mb-xl-10 {
    margin-bottom: 1rem !important; }
  .mb-xl-12 {
    margin-bottom: 1.2rem !important; }
  .mb-xl-13 {
    margin-bottom: 1.3rem !important; }
  .mb-xl-15 {
    margin-bottom: 1.5rem !important; }
  .mb-xl-16 {
    margin-bottom: 1.6rem !important; }
  .mb-xl-20 {
    margin-bottom: 2rem !important; }
  .mb-xl-24 {
    margin-bottom: 2.4rem !important; }
  .mb-xl-25 {
    margin-bottom: 2.5rem !important; }
  .mb-xl-29 {
    margin-bottom: 2.9rem !important; }
  .mb-xl-30 {
    margin-bottom: 3rem !important; }
  .mb-xl-32 {
    margin-bottom: 3.2rem !important; }
  .mb-xl-35 {
    margin-bottom: 3.5rem !important; }
  .mb-xl-37 {
    margin-bottom: 3.7rem !important; }
  .mb-xl-40 {
    margin-bottom: 4rem !important; }
  .mb-xl-42 {
    margin-bottom: 4.2rem !important; }
  .mb-xl-44 {
    margin-bottom: 4.4rem !important; }
  .mb-xl-48 {
    margin-bottom: 4.8rem !important; }
  .mb-xl-50 {
    margin-bottom: 5rem !important; }
  .mb-xl-52 {
    margin-bottom: 5.2rem !important; }
  .mb-xl-55 {
    margin-bottom: 5.5rem !important; }
  .mb-xl-60 {
    margin-bottom: 6rem !important; }
  .mb-xl-62 {
    margin-bottom: 6.2rem !important; }
  .mb-xl-64 {
    margin-bottom: 6.4rem !important; }
  .mb-xl-65 {
    margin-bottom: 6.5rem !important; }
  .mb-xl-70 {
    margin-bottom: 7rem !important; }
  .mb-xl-72 {
    margin-bottom: 7.2rem !important; }
  .mb-xl-80 {
    margin-bottom: 8rem !important; }
  .mb-xl-85 {
    margin-bottom: 8.5rem !important; }
  .mb-xl-95 {
    margin-bottom: 9.5rem !important; }
  .mb-xl-96 {
    margin-bottom: 9.6rem !important; }
  .mb-xl-100 {
    margin-bottom: 10rem !important; }
  .mb-xl-104 {
    margin-bottom: 10.4rem !important; }
  .mb-xl-108 {
    margin-bottom: 10.8rem !important; }
  .mb-xl-110 {
    margin-bottom: 11rem !important; }
  .mb-xl-116 {
    margin-bottom: 11.6rem !important; }
  .mb-xl-120 {
    margin-bottom: 12rem !important; }
  .mb-xl-128 {
    margin-bottom: 12.8rem !important; }
  .mb-xl-140 {
    margin-bottom: 14rem !important; }
  .mb-xl-148 {
    margin-bottom: 14.8rem !important; }
  .mb-xl-160 {
    margin-bottom: 16rem !important; }
  .mb-xl-170 {
    margin-bottom: 17rem !important; }
  .mb-xl-200 {
    margin-bottom: 20rem !important; }
  .mb-xl-240 {
    margin-bottom: 24rem !important; }
  .mb-xl-245 {
    margin-bottom: 24.5rem !important; }
  .mb-xl-250 {
    margin-bottom: 25rem !important; }
  .mb-xl-300 {
    margin-bottom: 30rem !important; }
  .mb-xl-400 {
    margin-bottom: 40rem !important; }
  .mb-xl-n25 {
    margin-bottom: -25% !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ms-xl-0 {
    margin-left: 0 !important; }
  .ms-xl-1 {
    margin-left: 0.1rem !important; }
  .ms-xl-2 {
    margin-left: 0.2rem !important; }
  .ms-xl-3 {
    margin-left: 0.3rem !important; }
  .ms-xl-4 {
    margin-left: 0.4rem !important; }
  .ms-xl-5 {
    margin-left: 0.5rem !important; }
  .ms-xl-6 {
    margin-left: 0.6rem !important; }
  .ms-xl-8 {
    margin-left: 0.8rem !important; }
  .ms-xl-10 {
    margin-left: 1rem !important; }
  .ms-xl-12 {
    margin-left: 1.2rem !important; }
  .ms-xl-13 {
    margin-left: 1.3rem !important; }
  .ms-xl-15 {
    margin-left: 1.5rem !important; }
  .ms-xl-16 {
    margin-left: 1.6rem !important; }
  .ms-xl-20 {
    margin-left: 2rem !important; }
  .ms-xl-24 {
    margin-left: 2.4rem !important; }
  .ms-xl-25 {
    margin-left: 2.5rem !important; }
  .ms-xl-29 {
    margin-left: 2.9rem !important; }
  .ms-xl-30 {
    margin-left: 3rem !important; }
  .ms-xl-32 {
    margin-left: 3.2rem !important; }
  .ms-xl-35 {
    margin-left: 3.5rem !important; }
  .ms-xl-37 {
    margin-left: 3.7rem !important; }
  .ms-xl-40 {
    margin-left: 4rem !important; }
  .ms-xl-42 {
    margin-left: 4.2rem !important; }
  .ms-xl-44 {
    margin-left: 4.4rem !important; }
  .ms-xl-48 {
    margin-left: 4.8rem !important; }
  .ms-xl-50 {
    margin-left: 5rem !important; }
  .ms-xl-52 {
    margin-left: 5.2rem !important; }
  .ms-xl-55 {
    margin-left: 5.5rem !important; }
  .ms-xl-60 {
    margin-left: 6rem !important; }
  .ms-xl-62 {
    margin-left: 6.2rem !important; }
  .ms-xl-64 {
    margin-left: 6.4rem !important; }
  .ms-xl-65 {
    margin-left: 6.5rem !important; }
  .ms-xl-70 {
    margin-left: 7rem !important; }
  .ms-xl-72 {
    margin-left: 7.2rem !important; }
  .ms-xl-80 {
    margin-left: 8rem !important; }
  .ms-xl-85 {
    margin-left: 8.5rem !important; }
  .ms-xl-95 {
    margin-left: 9.5rem !important; }
  .ms-xl-96 {
    margin-left: 9.6rem !important; }
  .ms-xl-100 {
    margin-left: 10rem !important; }
  .ms-xl-104 {
    margin-left: 10.4rem !important; }
  .ms-xl-108 {
    margin-left: 10.8rem !important; }
  .ms-xl-110 {
    margin-left: 11rem !important; }
  .ms-xl-116 {
    margin-left: 11.6rem !important; }
  .ms-xl-120 {
    margin-left: 12rem !important; }
  .ms-xl-128 {
    margin-left: 12.8rem !important; }
  .ms-xl-140 {
    margin-left: 14rem !important; }
  .ms-xl-148 {
    margin-left: 14.8rem !important; }
  .ms-xl-160 {
    margin-left: 16rem !important; }
  .ms-xl-170 {
    margin-left: 17rem !important; }
  .ms-xl-200 {
    margin-left: 20rem !important; }
  .ms-xl-240 {
    margin-left: 24rem !important; }
  .ms-xl-245 {
    margin-left: 24.5rem !important; }
  .ms-xl-250 {
    margin-left: 25rem !important; }
  .ms-xl-300 {
    margin-left: 30rem !important; }
  .ms-xl-400 {
    margin-left: 40rem !important; }
  .ms-xl-n25 {
    margin-left: -25% !important; }
  .ms-xl-auto {
    margin-left: auto !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .p-xl-1 {
    padding: 0.1rem !important; }
  .p-xl-2 {
    padding: 0.2rem !important; }
  .p-xl-3 {
    padding: 0.3rem !important; }
  .p-xl-4 {
    padding: 0.4rem !important; }
  .p-xl-5 {
    padding: 0.5rem !important; }
  .p-xl-6 {
    padding: 0.6rem !important; }
  .p-xl-8 {
    padding: 0.8rem !important; }
  .p-xl-10 {
    padding: 1rem !important; }
  .p-xl-12 {
    padding: 1.2rem !important; }
  .p-xl-13 {
    padding: 1.3rem !important; }
  .p-xl-15 {
    padding: 1.5rem !important; }
  .p-xl-16 {
    padding: 1.6rem !important; }
  .p-xl-20 {
    padding: 2rem !important; }
  .p-xl-24 {
    padding: 2.4rem !important; }
  .p-xl-25 {
    padding: 2.5rem !important; }
  .p-xl-29 {
    padding: 2.9rem !important; }
  .p-xl-30 {
    padding: 3rem !important; }
  .p-xl-32 {
    padding: 3.2rem !important; }
  .p-xl-35 {
    padding: 3.5rem !important; }
  .p-xl-37 {
    padding: 3.7rem !important; }
  .p-xl-40 {
    padding: 4rem !important; }
  .p-xl-42 {
    padding: 4.2rem !important; }
  .p-xl-44 {
    padding: 4.4rem !important; }
  .p-xl-48 {
    padding: 4.8rem !important; }
  .p-xl-50 {
    padding: 5rem !important; }
  .p-xl-52 {
    padding: 5.2rem !important; }
  .p-xl-55 {
    padding: 5.5rem !important; }
  .p-xl-60 {
    padding: 6rem !important; }
  .p-xl-62 {
    padding: 6.2rem !important; }
  .p-xl-64 {
    padding: 6.4rem !important; }
  .p-xl-65 {
    padding: 6.5rem !important; }
  .p-xl-70 {
    padding: 7rem !important; }
  .p-xl-72 {
    padding: 7.2rem !important; }
  .p-xl-80 {
    padding: 8rem !important; }
  .p-xl-85 {
    padding: 8.5rem !important; }
  .p-xl-95 {
    padding: 9.5rem !important; }
  .p-xl-96 {
    padding: 9.6rem !important; }
  .p-xl-100 {
    padding: 10rem !important; }
  .p-xl-104 {
    padding: 10.4rem !important; }
  .p-xl-108 {
    padding: 10.8rem !important; }
  .p-xl-110 {
    padding: 11rem !important; }
  .p-xl-116 {
    padding: 11.6rem !important; }
  .p-xl-120 {
    padding: 12rem !important; }
  .p-xl-128 {
    padding: 12.8rem !important; }
  .p-xl-140 {
    padding: 14rem !important; }
  .p-xl-148 {
    padding: 14.8rem !important; }
  .p-xl-160 {
    padding: 16rem !important; }
  .p-xl-170 {
    padding: 17rem !important; }
  .p-xl-200 {
    padding: 20rem !important; }
  .p-xl-240 {
    padding: 24rem !important; }
  .p-xl-245 {
    padding: 24.5rem !important; }
  .p-xl-250 {
    padding: 25rem !important; }
  .p-xl-300 {
    padding: 30rem !important; }
  .p-xl-400 {
    padding: 40rem !important; }
  .p-xl-n25 {
    padding: -25% !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xl-1 {
    padding-right: 0.1rem !important;
    padding-left: 0.1rem !important; }
  .px-xl-2 {
    padding-right: 0.2rem !important;
    padding-left: 0.2rem !important; }
  .px-xl-3 {
    padding-right: 0.3rem !important;
    padding-left: 0.3rem !important; }
  .px-xl-4 {
    padding-right: 0.4rem !important;
    padding-left: 0.4rem !important; }
  .px-xl-5 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xl-6 {
    padding-right: 0.6rem !important;
    padding-left: 0.6rem !important; }
  .px-xl-8 {
    padding-right: 0.8rem !important;
    padding-left: 0.8rem !important; }
  .px-xl-10 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xl-12 {
    padding-right: 1.2rem !important;
    padding-left: 1.2rem !important; }
  .px-xl-13 {
    padding-right: 1.3rem !important;
    padding-left: 1.3rem !important; }
  .px-xl-15 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xl-16 {
    padding-right: 1.6rem !important;
    padding-left: 1.6rem !important; }
  .px-xl-20 {
    padding-right: 2rem !important;
    padding-left: 2rem !important; }
  .px-xl-24 {
    padding-right: 2.4rem !important;
    padding-left: 2.4rem !important; }
  .px-xl-25 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important; }
  .px-xl-29 {
    padding-right: 2.9rem !important;
    padding-left: 2.9rem !important; }
  .px-xl-30 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .px-xl-32 {
    padding-right: 3.2rem !important;
    padding-left: 3.2rem !important; }
  .px-xl-35 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important; }
  .px-xl-37 {
    padding-right: 3.7rem !important;
    padding-left: 3.7rem !important; }
  .px-xl-40 {
    padding-right: 4rem !important;
    padding-left: 4rem !important; }
  .px-xl-42 {
    padding-right: 4.2rem !important;
    padding-left: 4.2rem !important; }
  .px-xl-44 {
    padding-right: 4.4rem !important;
    padding-left: 4.4rem !important; }
  .px-xl-48 {
    padding-right: 4.8rem !important;
    padding-left: 4.8rem !important; }
  .px-xl-50 {
    padding-right: 5rem !important;
    padding-left: 5rem !important; }
  .px-xl-52 {
    padding-right: 5.2rem !important;
    padding-left: 5.2rem !important; }
  .px-xl-55 {
    padding-right: 5.5rem !important;
    padding-left: 5.5rem !important; }
  .px-xl-60 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .px-xl-62 {
    padding-right: 6.2rem !important;
    padding-left: 6.2rem !important; }
  .px-xl-64 {
    padding-right: 6.4rem !important;
    padding-left: 6.4rem !important; }
  .px-xl-65 {
    padding-right: 6.5rem !important;
    padding-left: 6.5rem !important; }
  .px-xl-70 {
    padding-right: 7rem !important;
    padding-left: 7rem !important; }
  .px-xl-72 {
    padding-right: 7.2rem !important;
    padding-left: 7.2rem !important; }
  .px-xl-80 {
    padding-right: 8rem !important;
    padding-left: 8rem !important; }
  .px-xl-85 {
    padding-right: 8.5rem !important;
    padding-left: 8.5rem !important; }
  .px-xl-95 {
    padding-right: 9.5rem !important;
    padding-left: 9.5rem !important; }
  .px-xl-96 {
    padding-right: 9.6rem !important;
    padding-left: 9.6rem !important; }
  .px-xl-100 {
    padding-right: 10rem !important;
    padding-left: 10rem !important; }
  .px-xl-104 {
    padding-right: 10.4rem !important;
    padding-left: 10.4rem !important; }
  .px-xl-108 {
    padding-right: 10.8rem !important;
    padding-left: 10.8rem !important; }
  .px-xl-110 {
    padding-right: 11rem !important;
    padding-left: 11rem !important; }
  .px-xl-116 {
    padding-right: 11.6rem !important;
    padding-left: 11.6rem !important; }
  .px-xl-120 {
    padding-right: 12rem !important;
    padding-left: 12rem !important; }
  .px-xl-128 {
    padding-right: 12.8rem !important;
    padding-left: 12.8rem !important; }
  .px-xl-140 {
    padding-right: 14rem !important;
    padding-left: 14rem !important; }
  .px-xl-148 {
    padding-right: 14.8rem !important;
    padding-left: 14.8rem !important; }
  .px-xl-160 {
    padding-right: 16rem !important;
    padding-left: 16rem !important; }
  .px-xl-170 {
    padding-right: 17rem !important;
    padding-left: 17rem !important; }
  .px-xl-200 {
    padding-right: 20rem !important;
    padding-left: 20rem !important; }
  .px-xl-240 {
    padding-right: 24rem !important;
    padding-left: 24rem !important; }
  .px-xl-245 {
    padding-right: 24.5rem !important;
    padding-left: 24.5rem !important; }
  .px-xl-250 {
    padding-right: 25rem !important;
    padding-left: 25rem !important; }
  .px-xl-300 {
    padding-right: 30rem !important;
    padding-left: 30rem !important; }
  .px-xl-400 {
    padding-right: 40rem !important;
    padding-left: 40rem !important; }
  .px-xl-n25 {
    padding-right: -25% !important;
    padding-left: -25% !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xl-1 {
    padding-top: 0.1rem !important;
    padding-bottom: 0.1rem !important; }
  .py-xl-2 {
    padding-top: 0.2rem !important;
    padding-bottom: 0.2rem !important; }
  .py-xl-3 {
    padding-top: 0.3rem !important;
    padding-bottom: 0.3rem !important; }
  .py-xl-4 {
    padding-top: 0.4rem !important;
    padding-bottom: 0.4rem !important; }
  .py-xl-5 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xl-6 {
    padding-top: 0.6rem !important;
    padding-bottom: 0.6rem !important; }
  .py-xl-8 {
    padding-top: 0.8rem !important;
    padding-bottom: 0.8rem !important; }
  .py-xl-10 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xl-12 {
    padding-top: 1.2rem !important;
    padding-bottom: 1.2rem !important; }
  .py-xl-13 {
    padding-top: 1.3rem !important;
    padding-bottom: 1.3rem !important; }
  .py-xl-15 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xl-16 {
    padding-top: 1.6rem !important;
    padding-bottom: 1.6rem !important; }
  .py-xl-20 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important; }
  .py-xl-24 {
    padding-top: 2.4rem !important;
    padding-bottom: 2.4rem !important; }
  .py-xl-25 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important; }
  .py-xl-29 {
    padding-top: 2.9rem !important;
    padding-bottom: 2.9rem !important; }
  .py-xl-30 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .py-xl-32 {
    padding-top: 3.2rem !important;
    padding-bottom: 3.2rem !important; }
  .py-xl-35 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important; }
  .py-xl-37 {
    padding-top: 3.7rem !important;
    padding-bottom: 3.7rem !important; }
  .py-xl-40 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important; }
  .py-xl-42 {
    padding-top: 4.2rem !important;
    padding-bottom: 4.2rem !important; }
  .py-xl-44 {
    padding-top: 4.4rem !important;
    padding-bottom: 4.4rem !important; }
  .py-xl-48 {
    padding-top: 4.8rem !important;
    padding-bottom: 4.8rem !important; }
  .py-xl-50 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important; }
  .py-xl-52 {
    padding-top: 5.2rem !important;
    padding-bottom: 5.2rem !important; }
  .py-xl-55 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important; }
  .py-xl-60 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .py-xl-62 {
    padding-top: 6.2rem !important;
    padding-bottom: 6.2rem !important; }
  .py-xl-64 {
    padding-top: 6.4rem !important;
    padding-bottom: 6.4rem !important; }
  .py-xl-65 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important; }
  .py-xl-70 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important; }
  .py-xl-72 {
    padding-top: 7.2rem !important;
    padding-bottom: 7.2rem !important; }
  .py-xl-80 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important; }
  .py-xl-85 {
    padding-top: 8.5rem !important;
    padding-bottom: 8.5rem !important; }
  .py-xl-95 {
    padding-top: 9.5rem !important;
    padding-bottom: 9.5rem !important; }
  .py-xl-96 {
    padding-top: 9.6rem !important;
    padding-bottom: 9.6rem !important; }
  .py-xl-100 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important; }
  .py-xl-104 {
    padding-top: 10.4rem !important;
    padding-bottom: 10.4rem !important; }
  .py-xl-108 {
    padding-top: 10.8rem !important;
    padding-bottom: 10.8rem !important; }
  .py-xl-110 {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important; }
  .py-xl-116 {
    padding-top: 11.6rem !important;
    padding-bottom: 11.6rem !important; }
  .py-xl-120 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important; }
  .py-xl-128 {
    padding-top: 12.8rem !important;
    padding-bottom: 12.8rem !important; }
  .py-xl-140 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important; }
  .py-xl-148 {
    padding-top: 14.8rem !important;
    padding-bottom: 14.8rem !important; }
  .py-xl-160 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important; }
  .py-xl-170 {
    padding-top: 17rem !important;
    padding-bottom: 17rem !important; }
  .py-xl-200 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important; }
  .py-xl-240 {
    padding-top: 24rem !important;
    padding-bottom: 24rem !important; }
  .py-xl-245 {
    padding-top: 24.5rem !important;
    padding-bottom: 24.5rem !important; }
  .py-xl-250 {
    padding-top: 25rem !important;
    padding-bottom: 25rem !important; }
  .py-xl-300 {
    padding-top: 30rem !important;
    padding-bottom: 30rem !important; }
  .py-xl-400 {
    padding-top: 40rem !important;
    padding-bottom: 40rem !important; }
  .py-xl-n25 {
    padding-top: -25% !important;
    padding-bottom: -25% !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pt-xl-1 {
    padding-top: 0.1rem !important; }
  .pt-xl-2 {
    padding-top: 0.2rem !important; }
  .pt-xl-3 {
    padding-top: 0.3rem !important; }
  .pt-xl-4 {
    padding-top: 0.4rem !important; }
  .pt-xl-5 {
    padding-top: 0.5rem !important; }
  .pt-xl-6 {
    padding-top: 0.6rem !important; }
  .pt-xl-8 {
    padding-top: 0.8rem !important; }
  .pt-xl-10 {
    padding-top: 1rem !important; }
  .pt-xl-12 {
    padding-top: 1.2rem !important; }
  .pt-xl-13 {
    padding-top: 1.3rem !important; }
  .pt-xl-15 {
    padding-top: 1.5rem !important; }
  .pt-xl-16 {
    padding-top: 1.6rem !important; }
  .pt-xl-20 {
    padding-top: 2rem !important; }
  .pt-xl-24 {
    padding-top: 2.4rem !important; }
  .pt-xl-25 {
    padding-top: 2.5rem !important; }
  .pt-xl-29 {
    padding-top: 2.9rem !important; }
  .pt-xl-30 {
    padding-top: 3rem !important; }
  .pt-xl-32 {
    padding-top: 3.2rem !important; }
  .pt-xl-35 {
    padding-top: 3.5rem !important; }
  .pt-xl-37 {
    padding-top: 3.7rem !important; }
  .pt-xl-40 {
    padding-top: 4rem !important; }
  .pt-xl-42 {
    padding-top: 4.2rem !important; }
  .pt-xl-44 {
    padding-top: 4.4rem !important; }
  .pt-xl-48 {
    padding-top: 4.8rem !important; }
  .pt-xl-50 {
    padding-top: 5rem !important; }
  .pt-xl-52 {
    padding-top: 5.2rem !important; }
  .pt-xl-55 {
    padding-top: 5.5rem !important; }
  .pt-xl-60 {
    padding-top: 6rem !important; }
  .pt-xl-62 {
    padding-top: 6.2rem !important; }
  .pt-xl-64 {
    padding-top: 6.4rem !important; }
  .pt-xl-65 {
    padding-top: 6.5rem !important; }
  .pt-xl-70 {
    padding-top: 7rem !important; }
  .pt-xl-72 {
    padding-top: 7.2rem !important; }
  .pt-xl-80 {
    padding-top: 8rem !important; }
  .pt-xl-85 {
    padding-top: 8.5rem !important; }
  .pt-xl-95 {
    padding-top: 9.5rem !important; }
  .pt-xl-96 {
    padding-top: 9.6rem !important; }
  .pt-xl-100 {
    padding-top: 10rem !important; }
  .pt-xl-104 {
    padding-top: 10.4rem !important; }
  .pt-xl-108 {
    padding-top: 10.8rem !important; }
  .pt-xl-110 {
    padding-top: 11rem !important; }
  .pt-xl-116 {
    padding-top: 11.6rem !important; }
  .pt-xl-120 {
    padding-top: 12rem !important; }
  .pt-xl-128 {
    padding-top: 12.8rem !important; }
  .pt-xl-140 {
    padding-top: 14rem !important; }
  .pt-xl-148 {
    padding-top: 14.8rem !important; }
  .pt-xl-160 {
    padding-top: 16rem !important; }
  .pt-xl-170 {
    padding-top: 17rem !important; }
  .pt-xl-200 {
    padding-top: 20rem !important; }
  .pt-xl-240 {
    padding-top: 24rem !important; }
  .pt-xl-245 {
    padding-top: 24.5rem !important; }
  .pt-xl-250 {
    padding-top: 25rem !important; }
  .pt-xl-300 {
    padding-top: 30rem !important; }
  .pt-xl-400 {
    padding-top: 40rem !important; }
  .pt-xl-n25 {
    padding-top: -25% !important; }
  .pe-xl-0 {
    padding-right: 0 !important; }
  .pe-xl-1 {
    padding-right: 0.1rem !important; }
  .pe-xl-2 {
    padding-right: 0.2rem !important; }
  .pe-xl-3 {
    padding-right: 0.3rem !important; }
  .pe-xl-4 {
    padding-right: 0.4rem !important; }
  .pe-xl-5 {
    padding-right: 0.5rem !important; }
  .pe-xl-6 {
    padding-right: 0.6rem !important; }
  .pe-xl-8 {
    padding-right: 0.8rem !important; }
  .pe-xl-10 {
    padding-right: 1rem !important; }
  .pe-xl-12 {
    padding-right: 1.2rem !important; }
  .pe-xl-13 {
    padding-right: 1.3rem !important; }
  .pe-xl-15 {
    padding-right: 1.5rem !important; }
  .pe-xl-16 {
    padding-right: 1.6rem !important; }
  .pe-xl-20 {
    padding-right: 2rem !important; }
  .pe-xl-24 {
    padding-right: 2.4rem !important; }
  .pe-xl-25 {
    padding-right: 2.5rem !important; }
  .pe-xl-29 {
    padding-right: 2.9rem !important; }
  .pe-xl-30 {
    padding-right: 3rem !important; }
  .pe-xl-32 {
    padding-right: 3.2rem !important; }
  .pe-xl-35 {
    padding-right: 3.5rem !important; }
  .pe-xl-37 {
    padding-right: 3.7rem !important; }
  .pe-xl-40 {
    padding-right: 4rem !important; }
  .pe-xl-42 {
    padding-right: 4.2rem !important; }
  .pe-xl-44 {
    padding-right: 4.4rem !important; }
  .pe-xl-48 {
    padding-right: 4.8rem !important; }
  .pe-xl-50 {
    padding-right: 5rem !important; }
  .pe-xl-52 {
    padding-right: 5.2rem !important; }
  .pe-xl-55 {
    padding-right: 5.5rem !important; }
  .pe-xl-60 {
    padding-right: 6rem !important; }
  .pe-xl-62 {
    padding-right: 6.2rem !important; }
  .pe-xl-64 {
    padding-right: 6.4rem !important; }
  .pe-xl-65 {
    padding-right: 6.5rem !important; }
  .pe-xl-70 {
    padding-right: 7rem !important; }
  .pe-xl-72 {
    padding-right: 7.2rem !important; }
  .pe-xl-80 {
    padding-right: 8rem !important; }
  .pe-xl-85 {
    padding-right: 8.5rem !important; }
  .pe-xl-95 {
    padding-right: 9.5rem !important; }
  .pe-xl-96 {
    padding-right: 9.6rem !important; }
  .pe-xl-100 {
    padding-right: 10rem !important; }
  .pe-xl-104 {
    padding-right: 10.4rem !important; }
  .pe-xl-108 {
    padding-right: 10.8rem !important; }
  .pe-xl-110 {
    padding-right: 11rem !important; }
  .pe-xl-116 {
    padding-right: 11.6rem !important; }
  .pe-xl-120 {
    padding-right: 12rem !important; }
  .pe-xl-128 {
    padding-right: 12.8rem !important; }
  .pe-xl-140 {
    padding-right: 14rem !important; }
  .pe-xl-148 {
    padding-right: 14.8rem !important; }
  .pe-xl-160 {
    padding-right: 16rem !important; }
  .pe-xl-170 {
    padding-right: 17rem !important; }
  .pe-xl-200 {
    padding-right: 20rem !important; }
  .pe-xl-240 {
    padding-right: 24rem !important; }
  .pe-xl-245 {
    padding-right: 24.5rem !important; }
  .pe-xl-250 {
    padding-right: 25rem !important; }
  .pe-xl-300 {
    padding-right: 30rem !important; }
  .pe-xl-400 {
    padding-right: 40rem !important; }
  .pe-xl-n25 {
    padding-right: -25% !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pb-xl-1 {
    padding-bottom: 0.1rem !important; }
  .pb-xl-2 {
    padding-bottom: 0.2rem !important; }
  .pb-xl-3 {
    padding-bottom: 0.3rem !important; }
  .pb-xl-4 {
    padding-bottom: 0.4rem !important; }
  .pb-xl-5 {
    padding-bottom: 0.5rem !important; }
  .pb-xl-6 {
    padding-bottom: 0.6rem !important; }
  .pb-xl-8 {
    padding-bottom: 0.8rem !important; }
  .pb-xl-10 {
    padding-bottom: 1rem !important; }
  .pb-xl-12 {
    padding-bottom: 1.2rem !important; }
  .pb-xl-13 {
    padding-bottom: 1.3rem !important; }
  .pb-xl-15 {
    padding-bottom: 1.5rem !important; }
  .pb-xl-16 {
    padding-bottom: 1.6rem !important; }
  .pb-xl-20 {
    padding-bottom: 2rem !important; }
  .pb-xl-24 {
    padding-bottom: 2.4rem !important; }
  .pb-xl-25 {
    padding-bottom: 2.5rem !important; }
  .pb-xl-29 {
    padding-bottom: 2.9rem !important; }
  .pb-xl-30 {
    padding-bottom: 3rem !important; }
  .pb-xl-32 {
    padding-bottom: 3.2rem !important; }
  .pb-xl-35 {
    padding-bottom: 3.5rem !important; }
  .pb-xl-37 {
    padding-bottom: 3.7rem !important; }
  .pb-xl-40 {
    padding-bottom: 4rem !important; }
  .pb-xl-42 {
    padding-bottom: 4.2rem !important; }
  .pb-xl-44 {
    padding-bottom: 4.4rem !important; }
  .pb-xl-48 {
    padding-bottom: 4.8rem !important; }
  .pb-xl-50 {
    padding-bottom: 5rem !important; }
  .pb-xl-52 {
    padding-bottom: 5.2rem !important; }
  .pb-xl-55 {
    padding-bottom: 5.5rem !important; }
  .pb-xl-60 {
    padding-bottom: 6rem !important; }
  .pb-xl-62 {
    padding-bottom: 6.2rem !important; }
  .pb-xl-64 {
    padding-bottom: 6.4rem !important; }
  .pb-xl-65 {
    padding-bottom: 6.5rem !important; }
  .pb-xl-70 {
    padding-bottom: 7rem !important; }
  .pb-xl-72 {
    padding-bottom: 7.2rem !important; }
  .pb-xl-80 {
    padding-bottom: 8rem !important; }
  .pb-xl-85 {
    padding-bottom: 8.5rem !important; }
  .pb-xl-95 {
    padding-bottom: 9.5rem !important; }
  .pb-xl-96 {
    padding-bottom: 9.6rem !important; }
  .pb-xl-100 {
    padding-bottom: 10rem !important; }
  .pb-xl-104 {
    padding-bottom: 10.4rem !important; }
  .pb-xl-108 {
    padding-bottom: 10.8rem !important; }
  .pb-xl-110 {
    padding-bottom: 11rem !important; }
  .pb-xl-116 {
    padding-bottom: 11.6rem !important; }
  .pb-xl-120 {
    padding-bottom: 12rem !important; }
  .pb-xl-128 {
    padding-bottom: 12.8rem !important; }
  .pb-xl-140 {
    padding-bottom: 14rem !important; }
  .pb-xl-148 {
    padding-bottom: 14.8rem !important; }
  .pb-xl-160 {
    padding-bottom: 16rem !important; }
  .pb-xl-170 {
    padding-bottom: 17rem !important; }
  .pb-xl-200 {
    padding-bottom: 20rem !important; }
  .pb-xl-240 {
    padding-bottom: 24rem !important; }
  .pb-xl-245 {
    padding-bottom: 24.5rem !important; }
  .pb-xl-250 {
    padding-bottom: 25rem !important; }
  .pb-xl-300 {
    padding-bottom: 30rem !important; }
  .pb-xl-400 {
    padding-bottom: 40rem !important; }
  .pb-xl-n25 {
    padding-bottom: -25% !important; }
  .ps-xl-0 {
    padding-left: 0 !important; }
  .ps-xl-1 {
    padding-left: 0.1rem !important; }
  .ps-xl-2 {
    padding-left: 0.2rem !important; }
  .ps-xl-3 {
    padding-left: 0.3rem !important; }
  .ps-xl-4 {
    padding-left: 0.4rem !important; }
  .ps-xl-5 {
    padding-left: 0.5rem !important; }
  .ps-xl-6 {
    padding-left: 0.6rem !important; }
  .ps-xl-8 {
    padding-left: 0.8rem !important; }
  .ps-xl-10 {
    padding-left: 1rem !important; }
  .ps-xl-12 {
    padding-left: 1.2rem !important; }
  .ps-xl-13 {
    padding-left: 1.3rem !important; }
  .ps-xl-15 {
    padding-left: 1.5rem !important; }
  .ps-xl-16 {
    padding-left: 1.6rem !important; }
  .ps-xl-20 {
    padding-left: 2rem !important; }
  .ps-xl-24 {
    padding-left: 2.4rem !important; }
  .ps-xl-25 {
    padding-left: 2.5rem !important; }
  .ps-xl-29 {
    padding-left: 2.9rem !important; }
  .ps-xl-30 {
    padding-left: 3rem !important; }
  .ps-xl-32 {
    padding-left: 3.2rem !important; }
  .ps-xl-35 {
    padding-left: 3.5rem !important; }
  .ps-xl-37 {
    padding-left: 3.7rem !important; }
  .ps-xl-40 {
    padding-left: 4rem !important; }
  .ps-xl-42 {
    padding-left: 4.2rem !important; }
  .ps-xl-44 {
    padding-left: 4.4rem !important; }
  .ps-xl-48 {
    padding-left: 4.8rem !important; }
  .ps-xl-50 {
    padding-left: 5rem !important; }
  .ps-xl-52 {
    padding-left: 5.2rem !important; }
  .ps-xl-55 {
    padding-left: 5.5rem !important; }
  .ps-xl-60 {
    padding-left: 6rem !important; }
  .ps-xl-62 {
    padding-left: 6.2rem !important; }
  .ps-xl-64 {
    padding-left: 6.4rem !important; }
  .ps-xl-65 {
    padding-left: 6.5rem !important; }
  .ps-xl-70 {
    padding-left: 7rem !important; }
  .ps-xl-72 {
    padding-left: 7.2rem !important; }
  .ps-xl-80 {
    padding-left: 8rem !important; }
  .ps-xl-85 {
    padding-left: 8.5rem !important; }
  .ps-xl-95 {
    padding-left: 9.5rem !important; }
  .ps-xl-96 {
    padding-left: 9.6rem !important; }
  .ps-xl-100 {
    padding-left: 10rem !important; }
  .ps-xl-104 {
    padding-left: 10.4rem !important; }
  .ps-xl-108 {
    padding-left: 10.8rem !important; }
  .ps-xl-110 {
    padding-left: 11rem !important; }
  .ps-xl-116 {
    padding-left: 11.6rem !important; }
  .ps-xl-120 {
    padding-left: 12rem !important; }
  .ps-xl-128 {
    padding-left: 12.8rem !important; }
  .ps-xl-140 {
    padding-left: 14rem !important; }
  .ps-xl-148 {
    padding-left: 14.8rem !important; }
  .ps-xl-160 {
    padding-left: 16rem !important; }
  .ps-xl-170 {
    padding-left: 17rem !important; }
  .ps-xl-200 {
    padding-left: 20rem !important; }
  .ps-xl-240 {
    padding-left: 24rem !important; }
  .ps-xl-245 {
    padding-left: 24.5rem !important; }
  .ps-xl-250 {
    padding-left: 25rem !important; }
  .ps-xl-300 {
    padding-left: 30rem !important; }
  .ps-xl-400 {
    padding-left: 40rem !important; }
  .ps-xl-n25 {
    padding-left: -25% !important; }
  .gap-xl-0 {
    gap: 0 !important; }
  .gap-xl-1 {
    gap: 0.1rem !important; }
  .gap-xl-2 {
    gap: 0.2rem !important; }
  .gap-xl-3 {
    gap: 0.3rem !important; }
  .gap-xl-4 {
    gap: 0.4rem !important; }
  .gap-xl-5 {
    gap: 0.5rem !important; }
  .gap-xl-6 {
    gap: 0.6rem !important; }
  .gap-xl-8 {
    gap: 0.8rem !important; }
  .gap-xl-10 {
    gap: 1rem !important; }
  .gap-xl-12 {
    gap: 1.2rem !important; }
  .gap-xl-13 {
    gap: 1.3rem !important; }
  .gap-xl-15 {
    gap: 1.5rem !important; }
  .gap-xl-16 {
    gap: 1.6rem !important; }
  .gap-xl-20 {
    gap: 2rem !important; }
  .gap-xl-24 {
    gap: 2.4rem !important; }
  .gap-xl-25 {
    gap: 2.5rem !important; }
  .gap-xl-29 {
    gap: 2.9rem !important; }
  .gap-xl-30 {
    gap: 3rem !important; }
  .gap-xl-32 {
    gap: 3.2rem !important; }
  .gap-xl-35 {
    gap: 3.5rem !important; }
  .gap-xl-37 {
    gap: 3.7rem !important; }
  .gap-xl-40 {
    gap: 4rem !important; }
  .gap-xl-42 {
    gap: 4.2rem !important; }
  .gap-xl-44 {
    gap: 4.4rem !important; }
  .gap-xl-48 {
    gap: 4.8rem !important; }
  .gap-xl-50 {
    gap: 5rem !important; }
  .gap-xl-52 {
    gap: 5.2rem !important; }
  .gap-xl-55 {
    gap: 5.5rem !important; }
  .gap-xl-60 {
    gap: 6rem !important; }
  .gap-xl-62 {
    gap: 6.2rem !important; }
  .gap-xl-64 {
    gap: 6.4rem !important; }
  .gap-xl-65 {
    gap: 6.5rem !important; }
  .gap-xl-70 {
    gap: 7rem !important; }
  .gap-xl-72 {
    gap: 7.2rem !important; }
  .gap-xl-80 {
    gap: 8rem !important; }
  .gap-xl-85 {
    gap: 8.5rem !important; }
  .gap-xl-95 {
    gap: 9.5rem !important; }
  .gap-xl-96 {
    gap: 9.6rem !important; }
  .gap-xl-100 {
    gap: 10rem !important; }
  .gap-xl-104 {
    gap: 10.4rem !important; }
  .gap-xl-108 {
    gap: 10.8rem !important; }
  .gap-xl-110 {
    gap: 11rem !important; }
  .gap-xl-116 {
    gap: 11.6rem !important; }
  .gap-xl-120 {
    gap: 12rem !important; }
  .gap-xl-128 {
    gap: 12.8rem !important; }
  .gap-xl-140 {
    gap: 14rem !important; }
  .gap-xl-148 {
    gap: 14.8rem !important; }
  .gap-xl-160 {
    gap: 16rem !important; }
  .gap-xl-170 {
    gap: 17rem !important; }
  .gap-xl-200 {
    gap: 20rem !important; }
  .gap-xl-240 {
    gap: 24rem !important; }
  .gap-xl-245 {
    gap: 24.5rem !important; }
  .gap-xl-250 {
    gap: 25rem !important; }
  .gap-xl-300 {
    gap: 30rem !important; }
  .gap-xl-400 {
    gap: 40rem !important; }
  .gap-xl-n25 {
    gap: -25% !important; }
  .font-xl-family-gantari {
    font-family: "Gantari", sans-serif !important; }
  .font-xl-family-roboto {
    font-family: "Roboto", sans-serif !important; }
  .fs-xl-12 {
    font-size: 1.2rem !important; }
  .fs-xl-13 {
    font-size: 1.3rem !important; }
  .fs-xl-14 {
    font-size: 1.4rem !important; }
  .fs-xl-15 {
    font-size: 1.5rem !important; }
  .fs-xl-16 {
    font-size: 1.6rem !important; }
  .fs-xl-18 {
    font-size: 1.8rem !important; }
  .fs-xl-24 {
    font-size: 2.4rem !important; }
  .fs-xl-28 {
    font-size: 2.8rem !important; }
  .fs-xl-30 {
    font-size: 3rem !important; }
  .fs-xl-32 {
    font-size: 3.2rem !important; }
  .fs-xl-40 {
    font-size: 4rem !important; }
  .fs-xl-50 {
    font-size: 5rem !important; }
  .fs-xl-60 {
    font-size: 6rem !important; }
  .text-xl-start {
    text-align: left !important; }
  .text-xl-end {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; }
  .rounded-xl {
    border-radius: 0.8rem !important; }
  .rounded-xl-0 {
    border-radius: 0 !important; }
  .rounded-xl-1 {
    border-radius: 0.6rem !important; }
  .rounded-xl-2 {
    border-radius: 0.8rem !important; }
  .rounded-xl-3 {
    border-radius: 5px 20px 5px 20px !important; }
  .rounded-xl-4 {
    border-radius: 0.4rem !important; }
  .rounded-xl-5 {
    border-radius: 0.5rem !important; }
  .rounded-xl-8 {
    border-radius: 0.8rem !important; }
  .rounded-xl-9 {
    border-radius: 0.9rem !important; }
  .rounded-xl-15 {
    border-radius: 1.5rem !important; }
  .rounded-xl-16 {
    border-radius: 1.6rem !important; }
  .rounded-xl-64 {
    border-radius: 6.4rem !important; }
  .rounded-xl-circle {
    border-radius: 50% !important; }
  .rounded-xl-pill {
    border-radius: 50rem !important; }
  .rounded-xl-intro {
    border-radius: 50% 50% 0 0 !important; }
  .rounded-top-xl {
    border-top-left-radius: 0.8rem !important;
    border-top-right-radius: 0.8rem !important; }
  .rounded-top-xl-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important; }
  .rounded-top-xl-1 {
    border-top-left-radius: 0.6rem !important;
    border-top-right-radius: 0.6rem !important; }
  .rounded-top-xl-2 {
    border-top-left-radius: 0.8rem !important;
    border-top-right-radius: 0.8rem !important; }
  .rounded-top-xl-3 {
    border-top-left-radius: 5px 20px 5px 20px !important;
    border-top-right-radius: 5px 20px 5px 20px !important; }
  .rounded-top-xl-4 {
    border-top-left-radius: 0.4rem !important;
    border-top-right-radius: 0.4rem !important; }
  .rounded-top-xl-5 {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important; }
  .rounded-top-xl-8 {
    border-top-left-radius: 0.8rem !important;
    border-top-right-radius: 0.8rem !important; }
  .rounded-top-xl-9 {
    border-top-left-radius: 0.9rem !important;
    border-top-right-radius: 0.9rem !important; }
  .rounded-top-xl-15 {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important; }
  .rounded-top-xl-16 {
    border-top-left-radius: 1.6rem !important;
    border-top-right-radius: 1.6rem !important; }
  .rounded-top-xl-64 {
    border-top-left-radius: 6.4rem !important;
    border-top-right-radius: 6.4rem !important; }
  .rounded-top-xl-circle {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important; }
  .rounded-top-xl-pill {
    border-top-left-radius: 50rem !important;
    border-top-right-radius: 50rem !important; }
  .rounded-top-xl-intro {
    border-top-left-radius: 50% 50% 0 0 !important;
    border-top-right-radius: 50% 50% 0 0 !important; } }

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important; }
  .float-xxl-end {
    float: right !important; }
  .float-xxl-none {
    float: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-grid {
    display: grid !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; }
  .d-xxl-none {
    display: none !important; }
  .position-xxl-static {
    position: static !important; }
  .position-xxl-relative {
    position: relative !important; }
  .position-xxl-absolute {
    position: absolute !important; }
  .position-xxl-fixed {
    position: fixed !important; }
  .position-xxl-sticky {
    position: sticky !important; }
  .top-xxl-0 {
    top: 0 !important; }
  .top-xxl-5 {
    top: 5% !important; }
  .top-xxl-25 {
    top: 25% !important; }
  .top-xxl-50 {
    top: 50% !important; }
  .top-xxl-90 {
    top: 90% !important; }
  .top-xxl-100 {
    top: 100% !important; }
  .top-xxl-10px {
    top: 10px !important; }
  .top-xxl-14px {
    top: 14px !important; }
  .top-xxl-24px {
    top: 24px !important; }
  .top-xxl-50px {
    top: 50px !important; }
  .top-xxl-70px {
    top: 70px !important; }
  .top-xxl-100px {
    top: 100px !important; }
  .top-xxl-120px {
    top: 120px !important; }
  .top-xxl-150px {
    top: 150px !important; }
  .top-xxl-170px {
    top: 170px !important; }
  .top-xxl-200px {
    top: 200px !important; }
  .top-xxl-230px {
    top: 230px !important; }
  .top-xxl-240px {
    top: 240px !important; }
  .top-xxl-250px {
    top: 250px !important; }
  .top-xxl-n8px {
    top: -8px !important; }
  .top-xxl-n10px {
    top: -10px !important; }
  .top-xxl-n19px {
    top: -19px !important; }
  .top-xxl-n25px {
    top: -25px !important; }
  .top-xxl-n50px {
    top: -50px !important; }
  .top-xxl-n90px {
    top: -90px !important; }
  .top-xxl-n200px {
    top: -200px !important; }
  .top-xxl-n240px {
    top: -240px !important; }
  .top-xxl-n340px {
    top: -340px !important; }
  .top-xxl-n350px {
    top: -350px !important; }
  .top-xxl-100n20 {
    top: calc(100% + 20px) !important; }
  .top-xxl-100n50 {
    top: calc(100% - 50px) !important; }
  .top-xxl-100n100 {
    top: calc(100% + 100px) !important; }
  .top-xxl-100n200 {
    top: calc(100% + 200px) !important; }
  .top-xxl-100n250 {
    top: calc(100% + 250px) !important; }
  .top-xxl-100n350 {
    top: calc(100% + 350px) !important; }
  .bottom-xxl-0 {
    bottom: 0 !important; }
  .bottom-xxl-5 {
    bottom: 5% !important; }
  .bottom-xxl-25 {
    bottom: 25% !important; }
  .bottom-xxl-50 {
    bottom: 50% !important; }
  .bottom-xxl-90 {
    bottom: 90% !important; }
  .bottom-xxl-100 {
    bottom: 100% !important; }
  .bottom-xxl-10px {
    bottom: 10px !important; }
  .bottom-xxl-14px {
    bottom: 14px !important; }
  .bottom-xxl-24px {
    bottom: 24px !important; }
  .bottom-xxl-50px {
    bottom: 50px !important; }
  .bottom-xxl-70px {
    bottom: 70px !important; }
  .bottom-xxl-100px {
    bottom: 100px !important; }
  .bottom-xxl-120px {
    bottom: 120px !important; }
  .bottom-xxl-150px {
    bottom: 150px !important; }
  .bottom-xxl-170px {
    bottom: 170px !important; }
  .bottom-xxl-200px {
    bottom: 200px !important; }
  .bottom-xxl-230px {
    bottom: 230px !important; }
  .bottom-xxl-240px {
    bottom: 240px !important; }
  .bottom-xxl-250px {
    bottom: 250px !important; }
  .bottom-xxl-n8px {
    bottom: -8px !important; }
  .bottom-xxl-n10px {
    bottom: -10px !important; }
  .bottom-xxl-n19px {
    bottom: -19px !important; }
  .bottom-xxl-n25px {
    bottom: -25px !important; }
  .bottom-xxl-n50px {
    bottom: -50px !important; }
  .bottom-xxl-n90px {
    bottom: -90px !important; }
  .bottom-xxl-n200px {
    bottom: -200px !important; }
  .bottom-xxl-n240px {
    bottom: -240px !important; }
  .bottom-xxl-n340px {
    bottom: -340px !important; }
  .bottom-xxl-n350px {
    bottom: -350px !important; }
  .bottom-xxl-100n20 {
    bottom: calc(100% + 20px) !important; }
  .bottom-xxl-100n50 {
    bottom: calc(100% - 50px) !important; }
  .bottom-xxl-100n100 {
    bottom: calc(100% + 100px) !important; }
  .bottom-xxl-100n200 {
    bottom: calc(100% + 200px) !important; }
  .bottom-xxl-100n250 {
    bottom: calc(100% + 250px) !important; }
  .bottom-xxl-100n350 {
    bottom: calc(100% + 350px) !important; }
  .start-xxl-0 {
    left: 0 !important; }
  .start-xxl-5 {
    left: 5% !important; }
  .start-xxl-25 {
    left: 25% !important; }
  .start-xxl-50 {
    left: 50% !important; }
  .start-xxl-90 {
    left: 90% !important; }
  .start-xxl-100 {
    left: 100% !important; }
  .start-xxl-10px {
    left: 10px !important; }
  .start-xxl-14px {
    left: 14px !important; }
  .start-xxl-24px {
    left: 24px !important; }
  .start-xxl-50px {
    left: 50px !important; }
  .start-xxl-70px {
    left: 70px !important; }
  .start-xxl-100px {
    left: 100px !important; }
  .start-xxl-120px {
    left: 120px !important; }
  .start-xxl-150px {
    left: 150px !important; }
  .start-xxl-170px {
    left: 170px !important; }
  .start-xxl-200px {
    left: 200px !important; }
  .start-xxl-230px {
    left: 230px !important; }
  .start-xxl-240px {
    left: 240px !important; }
  .start-xxl-250px {
    left: 250px !important; }
  .start-xxl-n8px {
    left: -8px !important; }
  .start-xxl-n10px {
    left: -10px !important; }
  .start-xxl-n19px {
    left: -19px !important; }
  .start-xxl-n25px {
    left: -25px !important; }
  .start-xxl-n50px {
    left: -50px !important; }
  .start-xxl-n90px {
    left: -90px !important; }
  .start-xxl-n200px {
    left: -200px !important; }
  .start-xxl-n240px {
    left: -240px !important; }
  .start-xxl-n340px {
    left: -340px !important; }
  .start-xxl-n350px {
    left: -350px !important; }
  .start-xxl-100n20 {
    left: calc(100% + 20px) !important; }
  .start-xxl-100n50 {
    left: calc(100% - 50px) !important; }
  .start-xxl-100n100 {
    left: calc(100% + 100px) !important; }
  .start-xxl-100n200 {
    left: calc(100% + 200px) !important; }
  .start-xxl-100n250 {
    left: calc(100% + 250px) !important; }
  .start-xxl-100n350 {
    left: calc(100% + 350px) !important; }
  .end-xxl-0 {
    right: 0 !important; }
  .end-xxl-5 {
    right: 5% !important; }
  .end-xxl-25 {
    right: 25% !important; }
  .end-xxl-50 {
    right: 50% !important; }
  .end-xxl-90 {
    right: 90% !important; }
  .end-xxl-100 {
    right: 100% !important; }
  .end-xxl-10px {
    right: 10px !important; }
  .end-xxl-14px {
    right: 14px !important; }
  .end-xxl-24px {
    right: 24px !important; }
  .end-xxl-50px {
    right: 50px !important; }
  .end-xxl-70px {
    right: 70px !important; }
  .end-xxl-100px {
    right: 100px !important; }
  .end-xxl-120px {
    right: 120px !important; }
  .end-xxl-150px {
    right: 150px !important; }
  .end-xxl-170px {
    right: 170px !important; }
  .end-xxl-200px {
    right: 200px !important; }
  .end-xxl-230px {
    right: 230px !important; }
  .end-xxl-240px {
    right: 240px !important; }
  .end-xxl-250px {
    right: 250px !important; }
  .end-xxl-n8px {
    right: -8px !important; }
  .end-xxl-n10px {
    right: -10px !important; }
  .end-xxl-n19px {
    right: -19px !important; }
  .end-xxl-n25px {
    right: -25px !important; }
  .end-xxl-n50px {
    right: -50px !important; }
  .end-xxl-n90px {
    right: -90px !important; }
  .end-xxl-n200px {
    right: -200px !important; }
  .end-xxl-n240px {
    right: -240px !important; }
  .end-xxl-n340px {
    right: -340px !important; }
  .end-xxl-n350px {
    right: -350px !important; }
  .end-xxl-100n20 {
    right: calc(100% + 20px) !important; }
  .end-xxl-100n50 {
    right: calc(100% - 50px) !important; }
  .end-xxl-100n100 {
    right: calc(100% + 100px) !important; }
  .end-xxl-100n200 {
    right: calc(100% + 200px) !important; }
  .end-xxl-100n250 {
    right: calc(100% + 250px) !important; }
  .end-xxl-100n350 {
    right: calc(100% + 350px) !important; }
  .w-xxl-25 {
    width: 25% !important; }
  .w-xxl-50 {
    width: 50% !important; }
  .w-xxl-75 {
    width: 75% !important; }
  .w-xxl-100 {
    width: 100% !important; }
  .w-xxl-auto {
    width: auto !important; }
  .w-xxl-135px {
    width: 135px !important; }
  .w-xxl-150px {
    width: 150px !important; }
  .w-xxl-180px {
    width: 180px !important; }
  .w-xxl-190px {
    width: 190px !important; }
  .w-xxl-200px {
    width: 200px !important; }
  .w-xxl-240px {
    width: 240px !important; }
  .w-xxl-260px {
    width: 260px !important; }
  .w-xxl-296px {
    width: 296px !important; }
  .w-xxl-340px {
    width: 340px !important; }
  .w-xxl-368px {
    width: 368px !important; }
  .w-xxl-400px {
    width: 400px !important; }
  .w-xxl-500px {
    width: 500px !important; }
  .w-xxl-520px {
    width: 520px !important; }
  .w-xxl-800px {
    width: 800px !important; }
  .h-xxl-25 {
    height: 25% !important; }
  .h-xxl-50 {
    height: 50% !important; }
  .h-xxl-75 {
    height: 75% !important; }
  .h-xxl-100 {
    height: 100% !important; }
  .h-xxl-auto {
    height: auto !important; }
  .h-xxl-135px {
    height: 135px !important; }
  .h-xxl-150px {
    height: 150px !important; }
  .h-xxl-180px {
    height: 180px !important; }
  .h-xxl-190px {
    height: 190px !important; }
  .h-xxl-200px {
    height: 200px !important; }
  .h-xxl-240px {
    height: 240px !important; }
  .h-xxl-260px {
    height: 260px !important; }
  .h-xxl-296px {
    height: 296px !important; }
  .h-xxl-340px {
    height: 340px !important; }
  .h-xxl-368px {
    height: 368px !important; }
  .h-xxl-400px {
    height: 400px !important; }
  .h-xxl-500px {
    height: 500px !important; }
  .h-xxl-520px {
    height: 520px !important; }
  .h-xxl-800px {
    height: 800px !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; }
  .order-xxl-first {
    order: -1 !important; }
  .order-xxl-0 {
    order: 0 !important; }
  .order-xxl-1 {
    order: 1 !important; }
  .order-xxl-2 {
    order: 2 !important; }
  .order-xxl-3 {
    order: 3 !important; }
  .order-xxl-4 {
    order: 4 !important; }
  .order-xxl-5 {
    order: 5 !important; }
  .order-xxl-last {
    order: 6 !important; }
  .m-xxl-0 {
    margin: 0 !important; }
  .m-xxl-1 {
    margin: 0.1rem !important; }
  .m-xxl-2 {
    margin: 0.2rem !important; }
  .m-xxl-3 {
    margin: 0.3rem !important; }
  .m-xxl-4 {
    margin: 0.4rem !important; }
  .m-xxl-5 {
    margin: 0.5rem !important; }
  .m-xxl-6 {
    margin: 0.6rem !important; }
  .m-xxl-8 {
    margin: 0.8rem !important; }
  .m-xxl-10 {
    margin: 1rem !important; }
  .m-xxl-12 {
    margin: 1.2rem !important; }
  .m-xxl-13 {
    margin: 1.3rem !important; }
  .m-xxl-15 {
    margin: 1.5rem !important; }
  .m-xxl-16 {
    margin: 1.6rem !important; }
  .m-xxl-20 {
    margin: 2rem !important; }
  .m-xxl-24 {
    margin: 2.4rem !important; }
  .m-xxl-25 {
    margin: 2.5rem !important; }
  .m-xxl-29 {
    margin: 2.9rem !important; }
  .m-xxl-30 {
    margin: 3rem !important; }
  .m-xxl-32 {
    margin: 3.2rem !important; }
  .m-xxl-35 {
    margin: 3.5rem !important; }
  .m-xxl-37 {
    margin: 3.7rem !important; }
  .m-xxl-40 {
    margin: 4rem !important; }
  .m-xxl-42 {
    margin: 4.2rem !important; }
  .m-xxl-44 {
    margin: 4.4rem !important; }
  .m-xxl-48 {
    margin: 4.8rem !important; }
  .m-xxl-50 {
    margin: 5rem !important; }
  .m-xxl-52 {
    margin: 5.2rem !important; }
  .m-xxl-55 {
    margin: 5.5rem !important; }
  .m-xxl-60 {
    margin: 6rem !important; }
  .m-xxl-62 {
    margin: 6.2rem !important; }
  .m-xxl-64 {
    margin: 6.4rem !important; }
  .m-xxl-65 {
    margin: 6.5rem !important; }
  .m-xxl-70 {
    margin: 7rem !important; }
  .m-xxl-72 {
    margin: 7.2rem !important; }
  .m-xxl-80 {
    margin: 8rem !important; }
  .m-xxl-85 {
    margin: 8.5rem !important; }
  .m-xxl-95 {
    margin: 9.5rem !important; }
  .m-xxl-96 {
    margin: 9.6rem !important; }
  .m-xxl-100 {
    margin: 10rem !important; }
  .m-xxl-104 {
    margin: 10.4rem !important; }
  .m-xxl-108 {
    margin: 10.8rem !important; }
  .m-xxl-110 {
    margin: 11rem !important; }
  .m-xxl-116 {
    margin: 11.6rem !important; }
  .m-xxl-120 {
    margin: 12rem !important; }
  .m-xxl-128 {
    margin: 12.8rem !important; }
  .m-xxl-140 {
    margin: 14rem !important; }
  .m-xxl-148 {
    margin: 14.8rem !important; }
  .m-xxl-160 {
    margin: 16rem !important; }
  .m-xxl-170 {
    margin: 17rem !important; }
  .m-xxl-200 {
    margin: 20rem !important; }
  .m-xxl-240 {
    margin: 24rem !important; }
  .m-xxl-245 {
    margin: 24.5rem !important; }
  .m-xxl-250 {
    margin: 25rem !important; }
  .m-xxl-300 {
    margin: 30rem !important; }
  .m-xxl-400 {
    margin: 40rem !important; }
  .m-xxl-n25 {
    margin: -25% !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xxl-1 {
    margin-right: 0.1rem !important;
    margin-left: 0.1rem !important; }
  .mx-xxl-2 {
    margin-right: 0.2rem !important;
    margin-left: 0.2rem !important; }
  .mx-xxl-3 {
    margin-right: 0.3rem !important;
    margin-left: 0.3rem !important; }
  .mx-xxl-4 {
    margin-right: 0.4rem !important;
    margin-left: 0.4rem !important; }
  .mx-xxl-5 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xxl-6 {
    margin-right: 0.6rem !important;
    margin-left: 0.6rem !important; }
  .mx-xxl-8 {
    margin-right: 0.8rem !important;
    margin-left: 0.8rem !important; }
  .mx-xxl-10 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xxl-12 {
    margin-right: 1.2rem !important;
    margin-left: 1.2rem !important; }
  .mx-xxl-13 {
    margin-right: 1.3rem !important;
    margin-left: 1.3rem !important; }
  .mx-xxl-15 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xxl-16 {
    margin-right: 1.6rem !important;
    margin-left: 1.6rem !important; }
  .mx-xxl-20 {
    margin-right: 2rem !important;
    margin-left: 2rem !important; }
  .mx-xxl-24 {
    margin-right: 2.4rem !important;
    margin-left: 2.4rem !important; }
  .mx-xxl-25 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important; }
  .mx-xxl-29 {
    margin-right: 2.9rem !important;
    margin-left: 2.9rem !important; }
  .mx-xxl-30 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xxl-32 {
    margin-right: 3.2rem !important;
    margin-left: 3.2rem !important; }
  .mx-xxl-35 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important; }
  .mx-xxl-37 {
    margin-right: 3.7rem !important;
    margin-left: 3.7rem !important; }
  .mx-xxl-40 {
    margin-right: 4rem !important;
    margin-left: 4rem !important; }
  .mx-xxl-42 {
    margin-right: 4.2rem !important;
    margin-left: 4.2rem !important; }
  .mx-xxl-44 {
    margin-right: 4.4rem !important;
    margin-left: 4.4rem !important; }
  .mx-xxl-48 {
    margin-right: 4.8rem !important;
    margin-left: 4.8rem !important; }
  .mx-xxl-50 {
    margin-right: 5rem !important;
    margin-left: 5rem !important; }
  .mx-xxl-52 {
    margin-right: 5.2rem !important;
    margin-left: 5.2rem !important; }
  .mx-xxl-55 {
    margin-right: 5.5rem !important;
    margin-left: 5.5rem !important; }
  .mx-xxl-60 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .mx-xxl-62 {
    margin-right: 6.2rem !important;
    margin-left: 6.2rem !important; }
  .mx-xxl-64 {
    margin-right: 6.4rem !important;
    margin-left: 6.4rem !important; }
  .mx-xxl-65 {
    margin-right: 6.5rem !important;
    margin-left: 6.5rem !important; }
  .mx-xxl-70 {
    margin-right: 7rem !important;
    margin-left: 7rem !important; }
  .mx-xxl-72 {
    margin-right: 7.2rem !important;
    margin-left: 7.2rem !important; }
  .mx-xxl-80 {
    margin-right: 8rem !important;
    margin-left: 8rem !important; }
  .mx-xxl-85 {
    margin-right: 8.5rem !important;
    margin-left: 8.5rem !important; }
  .mx-xxl-95 {
    margin-right: 9.5rem !important;
    margin-left: 9.5rem !important; }
  .mx-xxl-96 {
    margin-right: 9.6rem !important;
    margin-left: 9.6rem !important; }
  .mx-xxl-100 {
    margin-right: 10rem !important;
    margin-left: 10rem !important; }
  .mx-xxl-104 {
    margin-right: 10.4rem !important;
    margin-left: 10.4rem !important; }
  .mx-xxl-108 {
    margin-right: 10.8rem !important;
    margin-left: 10.8rem !important; }
  .mx-xxl-110 {
    margin-right: 11rem !important;
    margin-left: 11rem !important; }
  .mx-xxl-116 {
    margin-right: 11.6rem !important;
    margin-left: 11.6rem !important; }
  .mx-xxl-120 {
    margin-right: 12rem !important;
    margin-left: 12rem !important; }
  .mx-xxl-128 {
    margin-right: 12.8rem !important;
    margin-left: 12.8rem !important; }
  .mx-xxl-140 {
    margin-right: 14rem !important;
    margin-left: 14rem !important; }
  .mx-xxl-148 {
    margin-right: 14.8rem !important;
    margin-left: 14.8rem !important; }
  .mx-xxl-160 {
    margin-right: 16rem !important;
    margin-left: 16rem !important; }
  .mx-xxl-170 {
    margin-right: 17rem !important;
    margin-left: 17rem !important; }
  .mx-xxl-200 {
    margin-right: 20rem !important;
    margin-left: 20rem !important; }
  .mx-xxl-240 {
    margin-right: 24rem !important;
    margin-left: 24rem !important; }
  .mx-xxl-245 {
    margin-right: 24.5rem !important;
    margin-left: 24.5rem !important; }
  .mx-xxl-250 {
    margin-right: 25rem !important;
    margin-left: 25rem !important; }
  .mx-xxl-300 {
    margin-right: 30rem !important;
    margin-left: 30rem !important; }
  .mx-xxl-400 {
    margin-right: 40rem !important;
    margin-left: 40rem !important; }
  .mx-xxl-n25 {
    margin-right: -25% !important;
    margin-left: -25% !important; }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xxl-1 {
    margin-top: 0.1rem !important;
    margin-bottom: 0.1rem !important; }
  .my-xxl-2 {
    margin-top: 0.2rem !important;
    margin-bottom: 0.2rem !important; }
  .my-xxl-3 {
    margin-top: 0.3rem !important;
    margin-bottom: 0.3rem !important; }
  .my-xxl-4 {
    margin-top: 0.4rem !important;
    margin-bottom: 0.4rem !important; }
  .my-xxl-5 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xxl-6 {
    margin-top: 0.6rem !important;
    margin-bottom: 0.6rem !important; }
  .my-xxl-8 {
    margin-top: 0.8rem !important;
    margin-bottom: 0.8rem !important; }
  .my-xxl-10 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xxl-12 {
    margin-top: 1.2rem !important;
    margin-bottom: 1.2rem !important; }
  .my-xxl-13 {
    margin-top: 1.3rem !important;
    margin-bottom: 1.3rem !important; }
  .my-xxl-15 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xxl-16 {
    margin-top: 1.6rem !important;
    margin-bottom: 1.6rem !important; }
  .my-xxl-20 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important; }
  .my-xxl-24 {
    margin-top: 2.4rem !important;
    margin-bottom: 2.4rem !important; }
  .my-xxl-25 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important; }
  .my-xxl-29 {
    margin-top: 2.9rem !important;
    margin-bottom: 2.9rem !important; }
  .my-xxl-30 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xxl-32 {
    margin-top: 3.2rem !important;
    margin-bottom: 3.2rem !important; }
  .my-xxl-35 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important; }
  .my-xxl-37 {
    margin-top: 3.7rem !important;
    margin-bottom: 3.7rem !important; }
  .my-xxl-40 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important; }
  .my-xxl-42 {
    margin-top: 4.2rem !important;
    margin-bottom: 4.2rem !important; }
  .my-xxl-44 {
    margin-top: 4.4rem !important;
    margin-bottom: 4.4rem !important; }
  .my-xxl-48 {
    margin-top: 4.8rem !important;
    margin-bottom: 4.8rem !important; }
  .my-xxl-50 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important; }
  .my-xxl-52 {
    margin-top: 5.2rem !important;
    margin-bottom: 5.2rem !important; }
  .my-xxl-55 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important; }
  .my-xxl-60 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .my-xxl-62 {
    margin-top: 6.2rem !important;
    margin-bottom: 6.2rem !important; }
  .my-xxl-64 {
    margin-top: 6.4rem !important;
    margin-bottom: 6.4rem !important; }
  .my-xxl-65 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important; }
  .my-xxl-70 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important; }
  .my-xxl-72 {
    margin-top: 7.2rem !important;
    margin-bottom: 7.2rem !important; }
  .my-xxl-80 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important; }
  .my-xxl-85 {
    margin-top: 8.5rem !important;
    margin-bottom: 8.5rem !important; }
  .my-xxl-95 {
    margin-top: 9.5rem !important;
    margin-bottom: 9.5rem !important; }
  .my-xxl-96 {
    margin-top: 9.6rem !important;
    margin-bottom: 9.6rem !important; }
  .my-xxl-100 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important; }
  .my-xxl-104 {
    margin-top: 10.4rem !important;
    margin-bottom: 10.4rem !important; }
  .my-xxl-108 {
    margin-top: 10.8rem !important;
    margin-bottom: 10.8rem !important; }
  .my-xxl-110 {
    margin-top: 11rem !important;
    margin-bottom: 11rem !important; }
  .my-xxl-116 {
    margin-top: 11.6rem !important;
    margin-bottom: 11.6rem !important; }
  .my-xxl-120 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important; }
  .my-xxl-128 {
    margin-top: 12.8rem !important;
    margin-bottom: 12.8rem !important; }
  .my-xxl-140 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important; }
  .my-xxl-148 {
    margin-top: 14.8rem !important;
    margin-bottom: 14.8rem !important; }
  .my-xxl-160 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important; }
  .my-xxl-170 {
    margin-top: 17rem !important;
    margin-bottom: 17rem !important; }
  .my-xxl-200 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important; }
  .my-xxl-240 {
    margin-top: 24rem !important;
    margin-bottom: 24rem !important; }
  .my-xxl-245 {
    margin-top: 24.5rem !important;
    margin-bottom: 24.5rem !important; }
  .my-xxl-250 {
    margin-top: 25rem !important;
    margin-bottom: 25rem !important; }
  .my-xxl-300 {
    margin-top: 30rem !important;
    margin-bottom: 30rem !important; }
  .my-xxl-400 {
    margin-top: 40rem !important;
    margin-bottom: 40rem !important; }
  .my-xxl-n25 {
    margin-top: -25% !important;
    margin-bottom: -25% !important; }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xxl-0 {
    margin-top: 0 !important; }
  .mt-xxl-1 {
    margin-top: 0.1rem !important; }
  .mt-xxl-2 {
    margin-top: 0.2rem !important; }
  .mt-xxl-3 {
    margin-top: 0.3rem !important; }
  .mt-xxl-4 {
    margin-top: 0.4rem !important; }
  .mt-xxl-5 {
    margin-top: 0.5rem !important; }
  .mt-xxl-6 {
    margin-top: 0.6rem !important; }
  .mt-xxl-8 {
    margin-top: 0.8rem !important; }
  .mt-xxl-10 {
    margin-top: 1rem !important; }
  .mt-xxl-12 {
    margin-top: 1.2rem !important; }
  .mt-xxl-13 {
    margin-top: 1.3rem !important; }
  .mt-xxl-15 {
    margin-top: 1.5rem !important; }
  .mt-xxl-16 {
    margin-top: 1.6rem !important; }
  .mt-xxl-20 {
    margin-top: 2rem !important; }
  .mt-xxl-24 {
    margin-top: 2.4rem !important; }
  .mt-xxl-25 {
    margin-top: 2.5rem !important; }
  .mt-xxl-29 {
    margin-top: 2.9rem !important; }
  .mt-xxl-30 {
    margin-top: 3rem !important; }
  .mt-xxl-32 {
    margin-top: 3.2rem !important; }
  .mt-xxl-35 {
    margin-top: 3.5rem !important; }
  .mt-xxl-37 {
    margin-top: 3.7rem !important; }
  .mt-xxl-40 {
    margin-top: 4rem !important; }
  .mt-xxl-42 {
    margin-top: 4.2rem !important; }
  .mt-xxl-44 {
    margin-top: 4.4rem !important; }
  .mt-xxl-48 {
    margin-top: 4.8rem !important; }
  .mt-xxl-50 {
    margin-top: 5rem !important; }
  .mt-xxl-52 {
    margin-top: 5.2rem !important; }
  .mt-xxl-55 {
    margin-top: 5.5rem !important; }
  .mt-xxl-60 {
    margin-top: 6rem !important; }
  .mt-xxl-62 {
    margin-top: 6.2rem !important; }
  .mt-xxl-64 {
    margin-top: 6.4rem !important; }
  .mt-xxl-65 {
    margin-top: 6.5rem !important; }
  .mt-xxl-70 {
    margin-top: 7rem !important; }
  .mt-xxl-72 {
    margin-top: 7.2rem !important; }
  .mt-xxl-80 {
    margin-top: 8rem !important; }
  .mt-xxl-85 {
    margin-top: 8.5rem !important; }
  .mt-xxl-95 {
    margin-top: 9.5rem !important; }
  .mt-xxl-96 {
    margin-top: 9.6rem !important; }
  .mt-xxl-100 {
    margin-top: 10rem !important; }
  .mt-xxl-104 {
    margin-top: 10.4rem !important; }
  .mt-xxl-108 {
    margin-top: 10.8rem !important; }
  .mt-xxl-110 {
    margin-top: 11rem !important; }
  .mt-xxl-116 {
    margin-top: 11.6rem !important; }
  .mt-xxl-120 {
    margin-top: 12rem !important; }
  .mt-xxl-128 {
    margin-top: 12.8rem !important; }
  .mt-xxl-140 {
    margin-top: 14rem !important; }
  .mt-xxl-148 {
    margin-top: 14.8rem !important; }
  .mt-xxl-160 {
    margin-top: 16rem !important; }
  .mt-xxl-170 {
    margin-top: 17rem !important; }
  .mt-xxl-200 {
    margin-top: 20rem !important; }
  .mt-xxl-240 {
    margin-top: 24rem !important; }
  .mt-xxl-245 {
    margin-top: 24.5rem !important; }
  .mt-xxl-250 {
    margin-top: 25rem !important; }
  .mt-xxl-300 {
    margin-top: 30rem !important; }
  .mt-xxl-400 {
    margin-top: 40rem !important; }
  .mt-xxl-n25 {
    margin-top: -25% !important; }
  .mt-xxl-auto {
    margin-top: auto !important; }
  .me-xxl-0 {
    margin-right: 0 !important; }
  .me-xxl-1 {
    margin-right: 0.1rem !important; }
  .me-xxl-2 {
    margin-right: 0.2rem !important; }
  .me-xxl-3 {
    margin-right: 0.3rem !important; }
  .me-xxl-4 {
    margin-right: 0.4rem !important; }
  .me-xxl-5 {
    margin-right: 0.5rem !important; }
  .me-xxl-6 {
    margin-right: 0.6rem !important; }
  .me-xxl-8 {
    margin-right: 0.8rem !important; }
  .me-xxl-10 {
    margin-right: 1rem !important; }
  .me-xxl-12 {
    margin-right: 1.2rem !important; }
  .me-xxl-13 {
    margin-right: 1.3rem !important; }
  .me-xxl-15 {
    margin-right: 1.5rem !important; }
  .me-xxl-16 {
    margin-right: 1.6rem !important; }
  .me-xxl-20 {
    margin-right: 2rem !important; }
  .me-xxl-24 {
    margin-right: 2.4rem !important; }
  .me-xxl-25 {
    margin-right: 2.5rem !important; }
  .me-xxl-29 {
    margin-right: 2.9rem !important; }
  .me-xxl-30 {
    margin-right: 3rem !important; }
  .me-xxl-32 {
    margin-right: 3.2rem !important; }
  .me-xxl-35 {
    margin-right: 3.5rem !important; }
  .me-xxl-37 {
    margin-right: 3.7rem !important; }
  .me-xxl-40 {
    margin-right: 4rem !important; }
  .me-xxl-42 {
    margin-right: 4.2rem !important; }
  .me-xxl-44 {
    margin-right: 4.4rem !important; }
  .me-xxl-48 {
    margin-right: 4.8rem !important; }
  .me-xxl-50 {
    margin-right: 5rem !important; }
  .me-xxl-52 {
    margin-right: 5.2rem !important; }
  .me-xxl-55 {
    margin-right: 5.5rem !important; }
  .me-xxl-60 {
    margin-right: 6rem !important; }
  .me-xxl-62 {
    margin-right: 6.2rem !important; }
  .me-xxl-64 {
    margin-right: 6.4rem !important; }
  .me-xxl-65 {
    margin-right: 6.5rem !important; }
  .me-xxl-70 {
    margin-right: 7rem !important; }
  .me-xxl-72 {
    margin-right: 7.2rem !important; }
  .me-xxl-80 {
    margin-right: 8rem !important; }
  .me-xxl-85 {
    margin-right: 8.5rem !important; }
  .me-xxl-95 {
    margin-right: 9.5rem !important; }
  .me-xxl-96 {
    margin-right: 9.6rem !important; }
  .me-xxl-100 {
    margin-right: 10rem !important; }
  .me-xxl-104 {
    margin-right: 10.4rem !important; }
  .me-xxl-108 {
    margin-right: 10.8rem !important; }
  .me-xxl-110 {
    margin-right: 11rem !important; }
  .me-xxl-116 {
    margin-right: 11.6rem !important; }
  .me-xxl-120 {
    margin-right: 12rem !important; }
  .me-xxl-128 {
    margin-right: 12.8rem !important; }
  .me-xxl-140 {
    margin-right: 14rem !important; }
  .me-xxl-148 {
    margin-right: 14.8rem !important; }
  .me-xxl-160 {
    margin-right: 16rem !important; }
  .me-xxl-170 {
    margin-right: 17rem !important; }
  .me-xxl-200 {
    margin-right: 20rem !important; }
  .me-xxl-240 {
    margin-right: 24rem !important; }
  .me-xxl-245 {
    margin-right: 24.5rem !important; }
  .me-xxl-250 {
    margin-right: 25rem !important; }
  .me-xxl-300 {
    margin-right: 30rem !important; }
  .me-xxl-400 {
    margin-right: 40rem !important; }
  .me-xxl-n25 {
    margin-right: -25% !important; }
  .me-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-0 {
    margin-bottom: 0 !important; }
  .mb-xxl-1 {
    margin-bottom: 0.1rem !important; }
  .mb-xxl-2 {
    margin-bottom: 0.2rem !important; }
  .mb-xxl-3 {
    margin-bottom: 0.3rem !important; }
  .mb-xxl-4 {
    margin-bottom: 0.4rem !important; }
  .mb-xxl-5 {
    margin-bottom: 0.5rem !important; }
  .mb-xxl-6 {
    margin-bottom: 0.6rem !important; }
  .mb-xxl-8 {
    margin-bottom: 0.8rem !important; }
  .mb-xxl-10 {
    margin-bottom: 1rem !important; }
  .mb-xxl-12 {
    margin-bottom: 1.2rem !important; }
  .mb-xxl-13 {
    margin-bottom: 1.3rem !important; }
  .mb-xxl-15 {
    margin-bottom: 1.5rem !important; }
  .mb-xxl-16 {
    margin-bottom: 1.6rem !important; }
  .mb-xxl-20 {
    margin-bottom: 2rem !important; }
  .mb-xxl-24 {
    margin-bottom: 2.4rem !important; }
  .mb-xxl-25 {
    margin-bottom: 2.5rem !important; }
  .mb-xxl-29 {
    margin-bottom: 2.9rem !important; }
  .mb-xxl-30 {
    margin-bottom: 3rem !important; }
  .mb-xxl-32 {
    margin-bottom: 3.2rem !important; }
  .mb-xxl-35 {
    margin-bottom: 3.5rem !important; }
  .mb-xxl-37 {
    margin-bottom: 3.7rem !important; }
  .mb-xxl-40 {
    margin-bottom: 4rem !important; }
  .mb-xxl-42 {
    margin-bottom: 4.2rem !important; }
  .mb-xxl-44 {
    margin-bottom: 4.4rem !important; }
  .mb-xxl-48 {
    margin-bottom: 4.8rem !important; }
  .mb-xxl-50 {
    margin-bottom: 5rem !important; }
  .mb-xxl-52 {
    margin-bottom: 5.2rem !important; }
  .mb-xxl-55 {
    margin-bottom: 5.5rem !important; }
  .mb-xxl-60 {
    margin-bottom: 6rem !important; }
  .mb-xxl-62 {
    margin-bottom: 6.2rem !important; }
  .mb-xxl-64 {
    margin-bottom: 6.4rem !important; }
  .mb-xxl-65 {
    margin-bottom: 6.5rem !important; }
  .mb-xxl-70 {
    margin-bottom: 7rem !important; }
  .mb-xxl-72 {
    margin-bottom: 7.2rem !important; }
  .mb-xxl-80 {
    margin-bottom: 8rem !important; }
  .mb-xxl-85 {
    margin-bottom: 8.5rem !important; }
  .mb-xxl-95 {
    margin-bottom: 9.5rem !important; }
  .mb-xxl-96 {
    margin-bottom: 9.6rem !important; }
  .mb-xxl-100 {
    margin-bottom: 10rem !important; }
  .mb-xxl-104 {
    margin-bottom: 10.4rem !important; }
  .mb-xxl-108 {
    margin-bottom: 10.8rem !important; }
  .mb-xxl-110 {
    margin-bottom: 11rem !important; }
  .mb-xxl-116 {
    margin-bottom: 11.6rem !important; }
  .mb-xxl-120 {
    margin-bottom: 12rem !important; }
  .mb-xxl-128 {
    margin-bottom: 12.8rem !important; }
  .mb-xxl-140 {
    margin-bottom: 14rem !important; }
  .mb-xxl-148 {
    margin-bottom: 14.8rem !important; }
  .mb-xxl-160 {
    margin-bottom: 16rem !important; }
  .mb-xxl-170 {
    margin-bottom: 17rem !important; }
  .mb-xxl-200 {
    margin-bottom: 20rem !important; }
  .mb-xxl-240 {
    margin-bottom: 24rem !important; }
  .mb-xxl-245 {
    margin-bottom: 24.5rem !important; }
  .mb-xxl-250 {
    margin-bottom: 25rem !important; }
  .mb-xxl-300 {
    margin-bottom: 30rem !important; }
  .mb-xxl-400 {
    margin-bottom: 40rem !important; }
  .mb-xxl-n25 {
    margin-bottom: -25% !important; }
  .mb-xxl-auto {
    margin-bottom: auto !important; }
  .ms-xxl-0 {
    margin-left: 0 !important; }
  .ms-xxl-1 {
    margin-left: 0.1rem !important; }
  .ms-xxl-2 {
    margin-left: 0.2rem !important; }
  .ms-xxl-3 {
    margin-left: 0.3rem !important; }
  .ms-xxl-4 {
    margin-left: 0.4rem !important; }
  .ms-xxl-5 {
    margin-left: 0.5rem !important; }
  .ms-xxl-6 {
    margin-left: 0.6rem !important; }
  .ms-xxl-8 {
    margin-left: 0.8rem !important; }
  .ms-xxl-10 {
    margin-left: 1rem !important; }
  .ms-xxl-12 {
    margin-left: 1.2rem !important; }
  .ms-xxl-13 {
    margin-left: 1.3rem !important; }
  .ms-xxl-15 {
    margin-left: 1.5rem !important; }
  .ms-xxl-16 {
    margin-left: 1.6rem !important; }
  .ms-xxl-20 {
    margin-left: 2rem !important; }
  .ms-xxl-24 {
    margin-left: 2.4rem !important; }
  .ms-xxl-25 {
    margin-left: 2.5rem !important; }
  .ms-xxl-29 {
    margin-left: 2.9rem !important; }
  .ms-xxl-30 {
    margin-left: 3rem !important; }
  .ms-xxl-32 {
    margin-left: 3.2rem !important; }
  .ms-xxl-35 {
    margin-left: 3.5rem !important; }
  .ms-xxl-37 {
    margin-left: 3.7rem !important; }
  .ms-xxl-40 {
    margin-left: 4rem !important; }
  .ms-xxl-42 {
    margin-left: 4.2rem !important; }
  .ms-xxl-44 {
    margin-left: 4.4rem !important; }
  .ms-xxl-48 {
    margin-left: 4.8rem !important; }
  .ms-xxl-50 {
    margin-left: 5rem !important; }
  .ms-xxl-52 {
    margin-left: 5.2rem !important; }
  .ms-xxl-55 {
    margin-left: 5.5rem !important; }
  .ms-xxl-60 {
    margin-left: 6rem !important; }
  .ms-xxl-62 {
    margin-left: 6.2rem !important; }
  .ms-xxl-64 {
    margin-left: 6.4rem !important; }
  .ms-xxl-65 {
    margin-left: 6.5rem !important; }
  .ms-xxl-70 {
    margin-left: 7rem !important; }
  .ms-xxl-72 {
    margin-left: 7.2rem !important; }
  .ms-xxl-80 {
    margin-left: 8rem !important; }
  .ms-xxl-85 {
    margin-left: 8.5rem !important; }
  .ms-xxl-95 {
    margin-left: 9.5rem !important; }
  .ms-xxl-96 {
    margin-left: 9.6rem !important; }
  .ms-xxl-100 {
    margin-left: 10rem !important; }
  .ms-xxl-104 {
    margin-left: 10.4rem !important; }
  .ms-xxl-108 {
    margin-left: 10.8rem !important; }
  .ms-xxl-110 {
    margin-left: 11rem !important; }
  .ms-xxl-116 {
    margin-left: 11.6rem !important; }
  .ms-xxl-120 {
    margin-left: 12rem !important; }
  .ms-xxl-128 {
    margin-left: 12.8rem !important; }
  .ms-xxl-140 {
    margin-left: 14rem !important; }
  .ms-xxl-148 {
    margin-left: 14.8rem !important; }
  .ms-xxl-160 {
    margin-left: 16rem !important; }
  .ms-xxl-170 {
    margin-left: 17rem !important; }
  .ms-xxl-200 {
    margin-left: 20rem !important; }
  .ms-xxl-240 {
    margin-left: 24rem !important; }
  .ms-xxl-245 {
    margin-left: 24.5rem !important; }
  .ms-xxl-250 {
    margin-left: 25rem !important; }
  .ms-xxl-300 {
    margin-left: 30rem !important; }
  .ms-xxl-400 {
    margin-left: 40rem !important; }
  .ms-xxl-n25 {
    margin-left: -25% !important; }
  .ms-xxl-auto {
    margin-left: auto !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .p-xxl-1 {
    padding: 0.1rem !important; }
  .p-xxl-2 {
    padding: 0.2rem !important; }
  .p-xxl-3 {
    padding: 0.3rem !important; }
  .p-xxl-4 {
    padding: 0.4rem !important; }
  .p-xxl-5 {
    padding: 0.5rem !important; }
  .p-xxl-6 {
    padding: 0.6rem !important; }
  .p-xxl-8 {
    padding: 0.8rem !important; }
  .p-xxl-10 {
    padding: 1rem !important; }
  .p-xxl-12 {
    padding: 1.2rem !important; }
  .p-xxl-13 {
    padding: 1.3rem !important; }
  .p-xxl-15 {
    padding: 1.5rem !important; }
  .p-xxl-16 {
    padding: 1.6rem !important; }
  .p-xxl-20 {
    padding: 2rem !important; }
  .p-xxl-24 {
    padding: 2.4rem !important; }
  .p-xxl-25 {
    padding: 2.5rem !important; }
  .p-xxl-29 {
    padding: 2.9rem !important; }
  .p-xxl-30 {
    padding: 3rem !important; }
  .p-xxl-32 {
    padding: 3.2rem !important; }
  .p-xxl-35 {
    padding: 3.5rem !important; }
  .p-xxl-37 {
    padding: 3.7rem !important; }
  .p-xxl-40 {
    padding: 4rem !important; }
  .p-xxl-42 {
    padding: 4.2rem !important; }
  .p-xxl-44 {
    padding: 4.4rem !important; }
  .p-xxl-48 {
    padding: 4.8rem !important; }
  .p-xxl-50 {
    padding: 5rem !important; }
  .p-xxl-52 {
    padding: 5.2rem !important; }
  .p-xxl-55 {
    padding: 5.5rem !important; }
  .p-xxl-60 {
    padding: 6rem !important; }
  .p-xxl-62 {
    padding: 6.2rem !important; }
  .p-xxl-64 {
    padding: 6.4rem !important; }
  .p-xxl-65 {
    padding: 6.5rem !important; }
  .p-xxl-70 {
    padding: 7rem !important; }
  .p-xxl-72 {
    padding: 7.2rem !important; }
  .p-xxl-80 {
    padding: 8rem !important; }
  .p-xxl-85 {
    padding: 8.5rem !important; }
  .p-xxl-95 {
    padding: 9.5rem !important; }
  .p-xxl-96 {
    padding: 9.6rem !important; }
  .p-xxl-100 {
    padding: 10rem !important; }
  .p-xxl-104 {
    padding: 10.4rem !important; }
  .p-xxl-108 {
    padding: 10.8rem !important; }
  .p-xxl-110 {
    padding: 11rem !important; }
  .p-xxl-116 {
    padding: 11.6rem !important; }
  .p-xxl-120 {
    padding: 12rem !important; }
  .p-xxl-128 {
    padding: 12.8rem !important; }
  .p-xxl-140 {
    padding: 14rem !important; }
  .p-xxl-148 {
    padding: 14.8rem !important; }
  .p-xxl-160 {
    padding: 16rem !important; }
  .p-xxl-170 {
    padding: 17rem !important; }
  .p-xxl-200 {
    padding: 20rem !important; }
  .p-xxl-240 {
    padding: 24rem !important; }
  .p-xxl-245 {
    padding: 24.5rem !important; }
  .p-xxl-250 {
    padding: 25rem !important; }
  .p-xxl-300 {
    padding: 30rem !important; }
  .p-xxl-400 {
    padding: 40rem !important; }
  .p-xxl-n25 {
    padding: -25% !important; }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xxl-1 {
    padding-right: 0.1rem !important;
    padding-left: 0.1rem !important; }
  .px-xxl-2 {
    padding-right: 0.2rem !important;
    padding-left: 0.2rem !important; }
  .px-xxl-3 {
    padding-right: 0.3rem !important;
    padding-left: 0.3rem !important; }
  .px-xxl-4 {
    padding-right: 0.4rem !important;
    padding-left: 0.4rem !important; }
  .px-xxl-5 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xxl-6 {
    padding-right: 0.6rem !important;
    padding-left: 0.6rem !important; }
  .px-xxl-8 {
    padding-right: 0.8rem !important;
    padding-left: 0.8rem !important; }
  .px-xxl-10 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xxl-12 {
    padding-right: 1.2rem !important;
    padding-left: 1.2rem !important; }
  .px-xxl-13 {
    padding-right: 1.3rem !important;
    padding-left: 1.3rem !important; }
  .px-xxl-15 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xxl-16 {
    padding-right: 1.6rem !important;
    padding-left: 1.6rem !important; }
  .px-xxl-20 {
    padding-right: 2rem !important;
    padding-left: 2rem !important; }
  .px-xxl-24 {
    padding-right: 2.4rem !important;
    padding-left: 2.4rem !important; }
  .px-xxl-25 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important; }
  .px-xxl-29 {
    padding-right: 2.9rem !important;
    padding-left: 2.9rem !important; }
  .px-xxl-30 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .px-xxl-32 {
    padding-right: 3.2rem !important;
    padding-left: 3.2rem !important; }
  .px-xxl-35 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important; }
  .px-xxl-37 {
    padding-right: 3.7rem !important;
    padding-left: 3.7rem !important; }
  .px-xxl-40 {
    padding-right: 4rem !important;
    padding-left: 4rem !important; }
  .px-xxl-42 {
    padding-right: 4.2rem !important;
    padding-left: 4.2rem !important; }
  .px-xxl-44 {
    padding-right: 4.4rem !important;
    padding-left: 4.4rem !important; }
  .px-xxl-48 {
    padding-right: 4.8rem !important;
    padding-left: 4.8rem !important; }
  .px-xxl-50 {
    padding-right: 5rem !important;
    padding-left: 5rem !important; }
  .px-xxl-52 {
    padding-right: 5.2rem !important;
    padding-left: 5.2rem !important; }
  .px-xxl-55 {
    padding-right: 5.5rem !important;
    padding-left: 5.5rem !important; }
  .px-xxl-60 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .px-xxl-62 {
    padding-right: 6.2rem !important;
    padding-left: 6.2rem !important; }
  .px-xxl-64 {
    padding-right: 6.4rem !important;
    padding-left: 6.4rem !important; }
  .px-xxl-65 {
    padding-right: 6.5rem !important;
    padding-left: 6.5rem !important; }
  .px-xxl-70 {
    padding-right: 7rem !important;
    padding-left: 7rem !important; }
  .px-xxl-72 {
    padding-right: 7.2rem !important;
    padding-left: 7.2rem !important; }
  .px-xxl-80 {
    padding-right: 8rem !important;
    padding-left: 8rem !important; }
  .px-xxl-85 {
    padding-right: 8.5rem !important;
    padding-left: 8.5rem !important; }
  .px-xxl-95 {
    padding-right: 9.5rem !important;
    padding-left: 9.5rem !important; }
  .px-xxl-96 {
    padding-right: 9.6rem !important;
    padding-left: 9.6rem !important; }
  .px-xxl-100 {
    padding-right: 10rem !important;
    padding-left: 10rem !important; }
  .px-xxl-104 {
    padding-right: 10.4rem !important;
    padding-left: 10.4rem !important; }
  .px-xxl-108 {
    padding-right: 10.8rem !important;
    padding-left: 10.8rem !important; }
  .px-xxl-110 {
    padding-right: 11rem !important;
    padding-left: 11rem !important; }
  .px-xxl-116 {
    padding-right: 11.6rem !important;
    padding-left: 11.6rem !important; }
  .px-xxl-120 {
    padding-right: 12rem !important;
    padding-left: 12rem !important; }
  .px-xxl-128 {
    padding-right: 12.8rem !important;
    padding-left: 12.8rem !important; }
  .px-xxl-140 {
    padding-right: 14rem !important;
    padding-left: 14rem !important; }
  .px-xxl-148 {
    padding-right: 14.8rem !important;
    padding-left: 14.8rem !important; }
  .px-xxl-160 {
    padding-right: 16rem !important;
    padding-left: 16rem !important; }
  .px-xxl-170 {
    padding-right: 17rem !important;
    padding-left: 17rem !important; }
  .px-xxl-200 {
    padding-right: 20rem !important;
    padding-left: 20rem !important; }
  .px-xxl-240 {
    padding-right: 24rem !important;
    padding-left: 24rem !important; }
  .px-xxl-245 {
    padding-right: 24.5rem !important;
    padding-left: 24.5rem !important; }
  .px-xxl-250 {
    padding-right: 25rem !important;
    padding-left: 25rem !important; }
  .px-xxl-300 {
    padding-right: 30rem !important;
    padding-left: 30rem !important; }
  .px-xxl-400 {
    padding-right: 40rem !important;
    padding-left: 40rem !important; }
  .px-xxl-n25 {
    padding-right: -25% !important;
    padding-left: -25% !important; }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xxl-1 {
    padding-top: 0.1rem !important;
    padding-bottom: 0.1rem !important; }
  .py-xxl-2 {
    padding-top: 0.2rem !important;
    padding-bottom: 0.2rem !important; }
  .py-xxl-3 {
    padding-top: 0.3rem !important;
    padding-bottom: 0.3rem !important; }
  .py-xxl-4 {
    padding-top: 0.4rem !important;
    padding-bottom: 0.4rem !important; }
  .py-xxl-5 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xxl-6 {
    padding-top: 0.6rem !important;
    padding-bottom: 0.6rem !important; }
  .py-xxl-8 {
    padding-top: 0.8rem !important;
    padding-bottom: 0.8rem !important; }
  .py-xxl-10 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xxl-12 {
    padding-top: 1.2rem !important;
    padding-bottom: 1.2rem !important; }
  .py-xxl-13 {
    padding-top: 1.3rem !important;
    padding-bottom: 1.3rem !important; }
  .py-xxl-15 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xxl-16 {
    padding-top: 1.6rem !important;
    padding-bottom: 1.6rem !important; }
  .py-xxl-20 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important; }
  .py-xxl-24 {
    padding-top: 2.4rem !important;
    padding-bottom: 2.4rem !important; }
  .py-xxl-25 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important; }
  .py-xxl-29 {
    padding-top: 2.9rem !important;
    padding-bottom: 2.9rem !important; }
  .py-xxl-30 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .py-xxl-32 {
    padding-top: 3.2rem !important;
    padding-bottom: 3.2rem !important; }
  .py-xxl-35 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important; }
  .py-xxl-37 {
    padding-top: 3.7rem !important;
    padding-bottom: 3.7rem !important; }
  .py-xxl-40 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important; }
  .py-xxl-42 {
    padding-top: 4.2rem !important;
    padding-bottom: 4.2rem !important; }
  .py-xxl-44 {
    padding-top: 4.4rem !important;
    padding-bottom: 4.4rem !important; }
  .py-xxl-48 {
    padding-top: 4.8rem !important;
    padding-bottom: 4.8rem !important; }
  .py-xxl-50 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important; }
  .py-xxl-52 {
    padding-top: 5.2rem !important;
    padding-bottom: 5.2rem !important; }
  .py-xxl-55 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important; }
  .py-xxl-60 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .py-xxl-62 {
    padding-top: 6.2rem !important;
    padding-bottom: 6.2rem !important; }
  .py-xxl-64 {
    padding-top: 6.4rem !important;
    padding-bottom: 6.4rem !important; }
  .py-xxl-65 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important; }
  .py-xxl-70 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important; }
  .py-xxl-72 {
    padding-top: 7.2rem !important;
    padding-bottom: 7.2rem !important; }
  .py-xxl-80 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important; }
  .py-xxl-85 {
    padding-top: 8.5rem !important;
    padding-bottom: 8.5rem !important; }
  .py-xxl-95 {
    padding-top: 9.5rem !important;
    padding-bottom: 9.5rem !important; }
  .py-xxl-96 {
    padding-top: 9.6rem !important;
    padding-bottom: 9.6rem !important; }
  .py-xxl-100 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important; }
  .py-xxl-104 {
    padding-top: 10.4rem !important;
    padding-bottom: 10.4rem !important; }
  .py-xxl-108 {
    padding-top: 10.8rem !important;
    padding-bottom: 10.8rem !important; }
  .py-xxl-110 {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important; }
  .py-xxl-116 {
    padding-top: 11.6rem !important;
    padding-bottom: 11.6rem !important; }
  .py-xxl-120 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important; }
  .py-xxl-128 {
    padding-top: 12.8rem !important;
    padding-bottom: 12.8rem !important; }
  .py-xxl-140 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important; }
  .py-xxl-148 {
    padding-top: 14.8rem !important;
    padding-bottom: 14.8rem !important; }
  .py-xxl-160 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important; }
  .py-xxl-170 {
    padding-top: 17rem !important;
    padding-bottom: 17rem !important; }
  .py-xxl-200 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important; }
  .py-xxl-240 {
    padding-top: 24rem !important;
    padding-bottom: 24rem !important; }
  .py-xxl-245 {
    padding-top: 24.5rem !important;
    padding-bottom: 24.5rem !important; }
  .py-xxl-250 {
    padding-top: 25rem !important;
    padding-bottom: 25rem !important; }
  .py-xxl-300 {
    padding-top: 30rem !important;
    padding-bottom: 30rem !important; }
  .py-xxl-400 {
    padding-top: 40rem !important;
    padding-bottom: 40rem !important; }
  .py-xxl-n25 {
    padding-top: -25% !important;
    padding-bottom: -25% !important; }
  .pt-xxl-0 {
    padding-top: 0 !important; }
  .pt-xxl-1 {
    padding-top: 0.1rem !important; }
  .pt-xxl-2 {
    padding-top: 0.2rem !important; }
  .pt-xxl-3 {
    padding-top: 0.3rem !important; }
  .pt-xxl-4 {
    padding-top: 0.4rem !important; }
  .pt-xxl-5 {
    padding-top: 0.5rem !important; }
  .pt-xxl-6 {
    padding-top: 0.6rem !important; }
  .pt-xxl-8 {
    padding-top: 0.8rem !important; }
  .pt-xxl-10 {
    padding-top: 1rem !important; }
  .pt-xxl-12 {
    padding-top: 1.2rem !important; }
  .pt-xxl-13 {
    padding-top: 1.3rem !important; }
  .pt-xxl-15 {
    padding-top: 1.5rem !important; }
  .pt-xxl-16 {
    padding-top: 1.6rem !important; }
  .pt-xxl-20 {
    padding-top: 2rem !important; }
  .pt-xxl-24 {
    padding-top: 2.4rem !important; }
  .pt-xxl-25 {
    padding-top: 2.5rem !important; }
  .pt-xxl-29 {
    padding-top: 2.9rem !important; }
  .pt-xxl-30 {
    padding-top: 3rem !important; }
  .pt-xxl-32 {
    padding-top: 3.2rem !important; }
  .pt-xxl-35 {
    padding-top: 3.5rem !important; }
  .pt-xxl-37 {
    padding-top: 3.7rem !important; }
  .pt-xxl-40 {
    padding-top: 4rem !important; }
  .pt-xxl-42 {
    padding-top: 4.2rem !important; }
  .pt-xxl-44 {
    padding-top: 4.4rem !important; }
  .pt-xxl-48 {
    padding-top: 4.8rem !important; }
  .pt-xxl-50 {
    padding-top: 5rem !important; }
  .pt-xxl-52 {
    padding-top: 5.2rem !important; }
  .pt-xxl-55 {
    padding-top: 5.5rem !important; }
  .pt-xxl-60 {
    padding-top: 6rem !important; }
  .pt-xxl-62 {
    padding-top: 6.2rem !important; }
  .pt-xxl-64 {
    padding-top: 6.4rem !important; }
  .pt-xxl-65 {
    padding-top: 6.5rem !important; }
  .pt-xxl-70 {
    padding-top: 7rem !important; }
  .pt-xxl-72 {
    padding-top: 7.2rem !important; }
  .pt-xxl-80 {
    padding-top: 8rem !important; }
  .pt-xxl-85 {
    padding-top: 8.5rem !important; }
  .pt-xxl-95 {
    padding-top: 9.5rem !important; }
  .pt-xxl-96 {
    padding-top: 9.6rem !important; }
  .pt-xxl-100 {
    padding-top: 10rem !important; }
  .pt-xxl-104 {
    padding-top: 10.4rem !important; }
  .pt-xxl-108 {
    padding-top: 10.8rem !important; }
  .pt-xxl-110 {
    padding-top: 11rem !important; }
  .pt-xxl-116 {
    padding-top: 11.6rem !important; }
  .pt-xxl-120 {
    padding-top: 12rem !important; }
  .pt-xxl-128 {
    padding-top: 12.8rem !important; }
  .pt-xxl-140 {
    padding-top: 14rem !important; }
  .pt-xxl-148 {
    padding-top: 14.8rem !important; }
  .pt-xxl-160 {
    padding-top: 16rem !important; }
  .pt-xxl-170 {
    padding-top: 17rem !important; }
  .pt-xxl-200 {
    padding-top: 20rem !important; }
  .pt-xxl-240 {
    padding-top: 24rem !important; }
  .pt-xxl-245 {
    padding-top: 24.5rem !important; }
  .pt-xxl-250 {
    padding-top: 25rem !important; }
  .pt-xxl-300 {
    padding-top: 30rem !important; }
  .pt-xxl-400 {
    padding-top: 40rem !important; }
  .pt-xxl-n25 {
    padding-top: -25% !important; }
  .pe-xxl-0 {
    padding-right: 0 !important; }
  .pe-xxl-1 {
    padding-right: 0.1rem !important; }
  .pe-xxl-2 {
    padding-right: 0.2rem !important; }
  .pe-xxl-3 {
    padding-right: 0.3rem !important; }
  .pe-xxl-4 {
    padding-right: 0.4rem !important; }
  .pe-xxl-5 {
    padding-right: 0.5rem !important; }
  .pe-xxl-6 {
    padding-right: 0.6rem !important; }
  .pe-xxl-8 {
    padding-right: 0.8rem !important; }
  .pe-xxl-10 {
    padding-right: 1rem !important; }
  .pe-xxl-12 {
    padding-right: 1.2rem !important; }
  .pe-xxl-13 {
    padding-right: 1.3rem !important; }
  .pe-xxl-15 {
    padding-right: 1.5rem !important; }
  .pe-xxl-16 {
    padding-right: 1.6rem !important; }
  .pe-xxl-20 {
    padding-right: 2rem !important; }
  .pe-xxl-24 {
    padding-right: 2.4rem !important; }
  .pe-xxl-25 {
    padding-right: 2.5rem !important; }
  .pe-xxl-29 {
    padding-right: 2.9rem !important; }
  .pe-xxl-30 {
    padding-right: 3rem !important; }
  .pe-xxl-32 {
    padding-right: 3.2rem !important; }
  .pe-xxl-35 {
    padding-right: 3.5rem !important; }
  .pe-xxl-37 {
    padding-right: 3.7rem !important; }
  .pe-xxl-40 {
    padding-right: 4rem !important; }
  .pe-xxl-42 {
    padding-right: 4.2rem !important; }
  .pe-xxl-44 {
    padding-right: 4.4rem !important; }
  .pe-xxl-48 {
    padding-right: 4.8rem !important; }
  .pe-xxl-50 {
    padding-right: 5rem !important; }
  .pe-xxl-52 {
    padding-right: 5.2rem !important; }
  .pe-xxl-55 {
    padding-right: 5.5rem !important; }
  .pe-xxl-60 {
    padding-right: 6rem !important; }
  .pe-xxl-62 {
    padding-right: 6.2rem !important; }
  .pe-xxl-64 {
    padding-right: 6.4rem !important; }
  .pe-xxl-65 {
    padding-right: 6.5rem !important; }
  .pe-xxl-70 {
    padding-right: 7rem !important; }
  .pe-xxl-72 {
    padding-right: 7.2rem !important; }
  .pe-xxl-80 {
    padding-right: 8rem !important; }
  .pe-xxl-85 {
    padding-right: 8.5rem !important; }
  .pe-xxl-95 {
    padding-right: 9.5rem !important; }
  .pe-xxl-96 {
    padding-right: 9.6rem !important; }
  .pe-xxl-100 {
    padding-right: 10rem !important; }
  .pe-xxl-104 {
    padding-right: 10.4rem !important; }
  .pe-xxl-108 {
    padding-right: 10.8rem !important; }
  .pe-xxl-110 {
    padding-right: 11rem !important; }
  .pe-xxl-116 {
    padding-right: 11.6rem !important; }
  .pe-xxl-120 {
    padding-right: 12rem !important; }
  .pe-xxl-128 {
    padding-right: 12.8rem !important; }
  .pe-xxl-140 {
    padding-right: 14rem !important; }
  .pe-xxl-148 {
    padding-right: 14.8rem !important; }
  .pe-xxl-160 {
    padding-right: 16rem !important; }
  .pe-xxl-170 {
    padding-right: 17rem !important; }
  .pe-xxl-200 {
    padding-right: 20rem !important; }
  .pe-xxl-240 {
    padding-right: 24rem !important; }
  .pe-xxl-245 {
    padding-right: 24.5rem !important; }
  .pe-xxl-250 {
    padding-right: 25rem !important; }
  .pe-xxl-300 {
    padding-right: 30rem !important; }
  .pe-xxl-400 {
    padding-right: 40rem !important; }
  .pe-xxl-n25 {
    padding-right: -25% !important; }
  .pb-xxl-0 {
    padding-bottom: 0 !important; }
  .pb-xxl-1 {
    padding-bottom: 0.1rem !important; }
  .pb-xxl-2 {
    padding-bottom: 0.2rem !important; }
  .pb-xxl-3 {
    padding-bottom: 0.3rem !important; }
  .pb-xxl-4 {
    padding-bottom: 0.4rem !important; }
  .pb-xxl-5 {
    padding-bottom: 0.5rem !important; }
  .pb-xxl-6 {
    padding-bottom: 0.6rem !important; }
  .pb-xxl-8 {
    padding-bottom: 0.8rem !important; }
  .pb-xxl-10 {
    padding-bottom: 1rem !important; }
  .pb-xxl-12 {
    padding-bottom: 1.2rem !important; }
  .pb-xxl-13 {
    padding-bottom: 1.3rem !important; }
  .pb-xxl-15 {
    padding-bottom: 1.5rem !important; }
  .pb-xxl-16 {
    padding-bottom: 1.6rem !important; }
  .pb-xxl-20 {
    padding-bottom: 2rem !important; }
  .pb-xxl-24 {
    padding-bottom: 2.4rem !important; }
  .pb-xxl-25 {
    padding-bottom: 2.5rem !important; }
  .pb-xxl-29 {
    padding-bottom: 2.9rem !important; }
  .pb-xxl-30 {
    padding-bottom: 3rem !important; }
  .pb-xxl-32 {
    padding-bottom: 3.2rem !important; }
  .pb-xxl-35 {
    padding-bottom: 3.5rem !important; }
  .pb-xxl-37 {
    padding-bottom: 3.7rem !important; }
  .pb-xxl-40 {
    padding-bottom: 4rem !important; }
  .pb-xxl-42 {
    padding-bottom: 4.2rem !important; }
  .pb-xxl-44 {
    padding-bottom: 4.4rem !important; }
  .pb-xxl-48 {
    padding-bottom: 4.8rem !important; }
  .pb-xxl-50 {
    padding-bottom: 5rem !important; }
  .pb-xxl-52 {
    padding-bottom: 5.2rem !important; }
  .pb-xxl-55 {
    padding-bottom: 5.5rem !important; }
  .pb-xxl-60 {
    padding-bottom: 6rem !important; }
  .pb-xxl-62 {
    padding-bottom: 6.2rem !important; }
  .pb-xxl-64 {
    padding-bottom: 6.4rem !important; }
  .pb-xxl-65 {
    padding-bottom: 6.5rem !important; }
  .pb-xxl-70 {
    padding-bottom: 7rem !important; }
  .pb-xxl-72 {
    padding-bottom: 7.2rem !important; }
  .pb-xxl-80 {
    padding-bottom: 8rem !important; }
  .pb-xxl-85 {
    padding-bottom: 8.5rem !important; }
  .pb-xxl-95 {
    padding-bottom: 9.5rem !important; }
  .pb-xxl-96 {
    padding-bottom: 9.6rem !important; }
  .pb-xxl-100 {
    padding-bottom: 10rem !important; }
  .pb-xxl-104 {
    padding-bottom: 10.4rem !important; }
  .pb-xxl-108 {
    padding-bottom: 10.8rem !important; }
  .pb-xxl-110 {
    padding-bottom: 11rem !important; }
  .pb-xxl-116 {
    padding-bottom: 11.6rem !important; }
  .pb-xxl-120 {
    padding-bottom: 12rem !important; }
  .pb-xxl-128 {
    padding-bottom: 12.8rem !important; }
  .pb-xxl-140 {
    padding-bottom: 14rem !important; }
  .pb-xxl-148 {
    padding-bottom: 14.8rem !important; }
  .pb-xxl-160 {
    padding-bottom: 16rem !important; }
  .pb-xxl-170 {
    padding-bottom: 17rem !important; }
  .pb-xxl-200 {
    padding-bottom: 20rem !important; }
  .pb-xxl-240 {
    padding-bottom: 24rem !important; }
  .pb-xxl-245 {
    padding-bottom: 24.5rem !important; }
  .pb-xxl-250 {
    padding-bottom: 25rem !important; }
  .pb-xxl-300 {
    padding-bottom: 30rem !important; }
  .pb-xxl-400 {
    padding-bottom: 40rem !important; }
  .pb-xxl-n25 {
    padding-bottom: -25% !important; }
  .ps-xxl-0 {
    padding-left: 0 !important; }
  .ps-xxl-1 {
    padding-left: 0.1rem !important; }
  .ps-xxl-2 {
    padding-left: 0.2rem !important; }
  .ps-xxl-3 {
    padding-left: 0.3rem !important; }
  .ps-xxl-4 {
    padding-left: 0.4rem !important; }
  .ps-xxl-5 {
    padding-left: 0.5rem !important; }
  .ps-xxl-6 {
    padding-left: 0.6rem !important; }
  .ps-xxl-8 {
    padding-left: 0.8rem !important; }
  .ps-xxl-10 {
    padding-left: 1rem !important; }
  .ps-xxl-12 {
    padding-left: 1.2rem !important; }
  .ps-xxl-13 {
    padding-left: 1.3rem !important; }
  .ps-xxl-15 {
    padding-left: 1.5rem !important; }
  .ps-xxl-16 {
    padding-left: 1.6rem !important; }
  .ps-xxl-20 {
    padding-left: 2rem !important; }
  .ps-xxl-24 {
    padding-left: 2.4rem !important; }
  .ps-xxl-25 {
    padding-left: 2.5rem !important; }
  .ps-xxl-29 {
    padding-left: 2.9rem !important; }
  .ps-xxl-30 {
    padding-left: 3rem !important; }
  .ps-xxl-32 {
    padding-left: 3.2rem !important; }
  .ps-xxl-35 {
    padding-left: 3.5rem !important; }
  .ps-xxl-37 {
    padding-left: 3.7rem !important; }
  .ps-xxl-40 {
    padding-left: 4rem !important; }
  .ps-xxl-42 {
    padding-left: 4.2rem !important; }
  .ps-xxl-44 {
    padding-left: 4.4rem !important; }
  .ps-xxl-48 {
    padding-left: 4.8rem !important; }
  .ps-xxl-50 {
    padding-left: 5rem !important; }
  .ps-xxl-52 {
    padding-left: 5.2rem !important; }
  .ps-xxl-55 {
    padding-left: 5.5rem !important; }
  .ps-xxl-60 {
    padding-left: 6rem !important; }
  .ps-xxl-62 {
    padding-left: 6.2rem !important; }
  .ps-xxl-64 {
    padding-left: 6.4rem !important; }
  .ps-xxl-65 {
    padding-left: 6.5rem !important; }
  .ps-xxl-70 {
    padding-left: 7rem !important; }
  .ps-xxl-72 {
    padding-left: 7.2rem !important; }
  .ps-xxl-80 {
    padding-left: 8rem !important; }
  .ps-xxl-85 {
    padding-left: 8.5rem !important; }
  .ps-xxl-95 {
    padding-left: 9.5rem !important; }
  .ps-xxl-96 {
    padding-left: 9.6rem !important; }
  .ps-xxl-100 {
    padding-left: 10rem !important; }
  .ps-xxl-104 {
    padding-left: 10.4rem !important; }
  .ps-xxl-108 {
    padding-left: 10.8rem !important; }
  .ps-xxl-110 {
    padding-left: 11rem !important; }
  .ps-xxl-116 {
    padding-left: 11.6rem !important; }
  .ps-xxl-120 {
    padding-left: 12rem !important; }
  .ps-xxl-128 {
    padding-left: 12.8rem !important; }
  .ps-xxl-140 {
    padding-left: 14rem !important; }
  .ps-xxl-148 {
    padding-left: 14.8rem !important; }
  .ps-xxl-160 {
    padding-left: 16rem !important; }
  .ps-xxl-170 {
    padding-left: 17rem !important; }
  .ps-xxl-200 {
    padding-left: 20rem !important; }
  .ps-xxl-240 {
    padding-left: 24rem !important; }
  .ps-xxl-245 {
    padding-left: 24.5rem !important; }
  .ps-xxl-250 {
    padding-left: 25rem !important; }
  .ps-xxl-300 {
    padding-left: 30rem !important; }
  .ps-xxl-400 {
    padding-left: 40rem !important; }
  .ps-xxl-n25 {
    padding-left: -25% !important; }
  .gap-xxl-0 {
    gap: 0 !important; }
  .gap-xxl-1 {
    gap: 0.1rem !important; }
  .gap-xxl-2 {
    gap: 0.2rem !important; }
  .gap-xxl-3 {
    gap: 0.3rem !important; }
  .gap-xxl-4 {
    gap: 0.4rem !important; }
  .gap-xxl-5 {
    gap: 0.5rem !important; }
  .gap-xxl-6 {
    gap: 0.6rem !important; }
  .gap-xxl-8 {
    gap: 0.8rem !important; }
  .gap-xxl-10 {
    gap: 1rem !important; }
  .gap-xxl-12 {
    gap: 1.2rem !important; }
  .gap-xxl-13 {
    gap: 1.3rem !important; }
  .gap-xxl-15 {
    gap: 1.5rem !important; }
  .gap-xxl-16 {
    gap: 1.6rem !important; }
  .gap-xxl-20 {
    gap: 2rem !important; }
  .gap-xxl-24 {
    gap: 2.4rem !important; }
  .gap-xxl-25 {
    gap: 2.5rem !important; }
  .gap-xxl-29 {
    gap: 2.9rem !important; }
  .gap-xxl-30 {
    gap: 3rem !important; }
  .gap-xxl-32 {
    gap: 3.2rem !important; }
  .gap-xxl-35 {
    gap: 3.5rem !important; }
  .gap-xxl-37 {
    gap: 3.7rem !important; }
  .gap-xxl-40 {
    gap: 4rem !important; }
  .gap-xxl-42 {
    gap: 4.2rem !important; }
  .gap-xxl-44 {
    gap: 4.4rem !important; }
  .gap-xxl-48 {
    gap: 4.8rem !important; }
  .gap-xxl-50 {
    gap: 5rem !important; }
  .gap-xxl-52 {
    gap: 5.2rem !important; }
  .gap-xxl-55 {
    gap: 5.5rem !important; }
  .gap-xxl-60 {
    gap: 6rem !important; }
  .gap-xxl-62 {
    gap: 6.2rem !important; }
  .gap-xxl-64 {
    gap: 6.4rem !important; }
  .gap-xxl-65 {
    gap: 6.5rem !important; }
  .gap-xxl-70 {
    gap: 7rem !important; }
  .gap-xxl-72 {
    gap: 7.2rem !important; }
  .gap-xxl-80 {
    gap: 8rem !important; }
  .gap-xxl-85 {
    gap: 8.5rem !important; }
  .gap-xxl-95 {
    gap: 9.5rem !important; }
  .gap-xxl-96 {
    gap: 9.6rem !important; }
  .gap-xxl-100 {
    gap: 10rem !important; }
  .gap-xxl-104 {
    gap: 10.4rem !important; }
  .gap-xxl-108 {
    gap: 10.8rem !important; }
  .gap-xxl-110 {
    gap: 11rem !important; }
  .gap-xxl-116 {
    gap: 11.6rem !important; }
  .gap-xxl-120 {
    gap: 12rem !important; }
  .gap-xxl-128 {
    gap: 12.8rem !important; }
  .gap-xxl-140 {
    gap: 14rem !important; }
  .gap-xxl-148 {
    gap: 14.8rem !important; }
  .gap-xxl-160 {
    gap: 16rem !important; }
  .gap-xxl-170 {
    gap: 17rem !important; }
  .gap-xxl-200 {
    gap: 20rem !important; }
  .gap-xxl-240 {
    gap: 24rem !important; }
  .gap-xxl-245 {
    gap: 24.5rem !important; }
  .gap-xxl-250 {
    gap: 25rem !important; }
  .gap-xxl-300 {
    gap: 30rem !important; }
  .gap-xxl-400 {
    gap: 40rem !important; }
  .gap-xxl-n25 {
    gap: -25% !important; }
  .font-xxl-family-gantari {
    font-family: "Gantari", sans-serif !important; }
  .font-xxl-family-roboto {
    font-family: "Roboto", sans-serif !important; }
  .fs-xxl-12 {
    font-size: 1.2rem !important; }
  .fs-xxl-13 {
    font-size: 1.3rem !important; }
  .fs-xxl-14 {
    font-size: 1.4rem !important; }
  .fs-xxl-15 {
    font-size: 1.5rem !important; }
  .fs-xxl-16 {
    font-size: 1.6rem !important; }
  .fs-xxl-18 {
    font-size: 1.8rem !important; }
  .fs-xxl-24 {
    font-size: 2.4rem !important; }
  .fs-xxl-28 {
    font-size: 2.8rem !important; }
  .fs-xxl-30 {
    font-size: 3rem !important; }
  .fs-xxl-32 {
    font-size: 3.2rem !important; }
  .fs-xxl-40 {
    font-size: 4rem !important; }
  .fs-xxl-50 {
    font-size: 5rem !important; }
  .fs-xxl-60 {
    font-size: 6rem !important; }
  .text-xxl-start {
    text-align: left !important; }
  .text-xxl-end {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; }
  .rounded-xxl {
    border-radius: 0.8rem !important; }
  .rounded-xxl-0 {
    border-radius: 0 !important; }
  .rounded-xxl-1 {
    border-radius: 0.6rem !important; }
  .rounded-xxl-2 {
    border-radius: 0.8rem !important; }
  .rounded-xxl-3 {
    border-radius: 5px 20px 5px 20px !important; }
  .rounded-xxl-4 {
    border-radius: 0.4rem !important; }
  .rounded-xxl-5 {
    border-radius: 0.5rem !important; }
  .rounded-xxl-8 {
    border-radius: 0.8rem !important; }
  .rounded-xxl-9 {
    border-radius: 0.9rem !important; }
  .rounded-xxl-15 {
    border-radius: 1.5rem !important; }
  .rounded-xxl-16 {
    border-radius: 1.6rem !important; }
  .rounded-xxl-64 {
    border-radius: 6.4rem !important; }
  .rounded-xxl-circle {
    border-radius: 50% !important; }
  .rounded-xxl-pill {
    border-radius: 50rem !important; }
  .rounded-xxl-intro {
    border-radius: 50% 50% 0 0 !important; }
  .rounded-top-xxl {
    border-top-left-radius: 0.8rem !important;
    border-top-right-radius: 0.8rem !important; }
  .rounded-top-xxl-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important; }
  .rounded-top-xxl-1 {
    border-top-left-radius: 0.6rem !important;
    border-top-right-radius: 0.6rem !important; }
  .rounded-top-xxl-2 {
    border-top-left-radius: 0.8rem !important;
    border-top-right-radius: 0.8rem !important; }
  .rounded-top-xxl-3 {
    border-top-left-radius: 5px 20px 5px 20px !important;
    border-top-right-radius: 5px 20px 5px 20px !important; }
  .rounded-top-xxl-4 {
    border-top-left-radius: 0.4rem !important;
    border-top-right-radius: 0.4rem !important; }
  .rounded-top-xxl-5 {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important; }
  .rounded-top-xxl-8 {
    border-top-left-radius: 0.8rem !important;
    border-top-right-radius: 0.8rem !important; }
  .rounded-top-xxl-9 {
    border-top-left-radius: 0.9rem !important;
    border-top-right-radius: 0.9rem !important; }
  .rounded-top-xxl-15 {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important; }
  .rounded-top-xxl-16 {
    border-top-left-radius: 1.6rem !important;
    border-top-right-radius: 1.6rem !important; }
  .rounded-top-xxl-64 {
    border-top-left-radius: 6.4rem !important;
    border-top-right-radius: 6.4rem !important; }
  .rounded-top-xxl-circle {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important; }
  .rounded-top-xxl-pill {
    border-top-left-radius: 50rem !important;
    border-top-right-radius: 50rem !important; }
  .rounded-top-xxl-intro {
    border-top-left-radius: 50% 50% 0 0 !important;
    border-top-right-radius: 50% 50% 0 0 !important; } }

@media (min-width: 1920px) {
  .float-xxxl-start {
    float: left !important; }
  .float-xxxl-end {
    float: right !important; }
  .float-xxxl-none {
    float: none !important; }
  .d-xxxl-inline {
    display: inline !important; }
  .d-xxxl-inline-block {
    display: inline-block !important; }
  .d-xxxl-block {
    display: block !important; }
  .d-xxxl-grid {
    display: grid !important; }
  .d-xxxl-table {
    display: table !important; }
  .d-xxxl-table-row {
    display: table-row !important; }
  .d-xxxl-table-cell {
    display: table-cell !important; }
  .d-xxxl-flex {
    display: flex !important; }
  .d-xxxl-inline-flex {
    display: inline-flex !important; }
  .d-xxxl-none {
    display: none !important; }
  .position-xxxl-static {
    position: static !important; }
  .position-xxxl-relative {
    position: relative !important; }
  .position-xxxl-absolute {
    position: absolute !important; }
  .position-xxxl-fixed {
    position: fixed !important; }
  .position-xxxl-sticky {
    position: sticky !important; }
  .top-xxxl-0 {
    top: 0 !important; }
  .top-xxxl-5 {
    top: 5% !important; }
  .top-xxxl-25 {
    top: 25% !important; }
  .top-xxxl-50 {
    top: 50% !important; }
  .top-xxxl-90 {
    top: 90% !important; }
  .top-xxxl-100 {
    top: 100% !important; }
  .top-xxxl-10px {
    top: 10px !important; }
  .top-xxxl-14px {
    top: 14px !important; }
  .top-xxxl-24px {
    top: 24px !important; }
  .top-xxxl-50px {
    top: 50px !important; }
  .top-xxxl-70px {
    top: 70px !important; }
  .top-xxxl-100px {
    top: 100px !important; }
  .top-xxxl-120px {
    top: 120px !important; }
  .top-xxxl-150px {
    top: 150px !important; }
  .top-xxxl-170px {
    top: 170px !important; }
  .top-xxxl-200px {
    top: 200px !important; }
  .top-xxxl-230px {
    top: 230px !important; }
  .top-xxxl-240px {
    top: 240px !important; }
  .top-xxxl-250px {
    top: 250px !important; }
  .top-xxxl-n8px {
    top: -8px !important; }
  .top-xxxl-n10px {
    top: -10px !important; }
  .top-xxxl-n19px {
    top: -19px !important; }
  .top-xxxl-n25px {
    top: -25px !important; }
  .top-xxxl-n50px {
    top: -50px !important; }
  .top-xxxl-n90px {
    top: -90px !important; }
  .top-xxxl-n200px {
    top: -200px !important; }
  .top-xxxl-n240px {
    top: -240px !important; }
  .top-xxxl-n340px {
    top: -340px !important; }
  .top-xxxl-n350px {
    top: -350px !important; }
  .top-xxxl-100n20 {
    top: calc(100% + 20px) !important; }
  .top-xxxl-100n50 {
    top: calc(100% - 50px) !important; }
  .top-xxxl-100n100 {
    top: calc(100% + 100px) !important; }
  .top-xxxl-100n200 {
    top: calc(100% + 200px) !important; }
  .top-xxxl-100n250 {
    top: calc(100% + 250px) !important; }
  .top-xxxl-100n350 {
    top: calc(100% + 350px) !important; }
  .bottom-xxxl-0 {
    bottom: 0 !important; }
  .bottom-xxxl-5 {
    bottom: 5% !important; }
  .bottom-xxxl-25 {
    bottom: 25% !important; }
  .bottom-xxxl-50 {
    bottom: 50% !important; }
  .bottom-xxxl-90 {
    bottom: 90% !important; }
  .bottom-xxxl-100 {
    bottom: 100% !important; }
  .bottom-xxxl-10px {
    bottom: 10px !important; }
  .bottom-xxxl-14px {
    bottom: 14px !important; }
  .bottom-xxxl-24px {
    bottom: 24px !important; }
  .bottom-xxxl-50px {
    bottom: 50px !important; }
  .bottom-xxxl-70px {
    bottom: 70px !important; }
  .bottom-xxxl-100px {
    bottom: 100px !important; }
  .bottom-xxxl-120px {
    bottom: 120px !important; }
  .bottom-xxxl-150px {
    bottom: 150px !important; }
  .bottom-xxxl-170px {
    bottom: 170px !important; }
  .bottom-xxxl-200px {
    bottom: 200px !important; }
  .bottom-xxxl-230px {
    bottom: 230px !important; }
  .bottom-xxxl-240px {
    bottom: 240px !important; }
  .bottom-xxxl-250px {
    bottom: 250px !important; }
  .bottom-xxxl-n8px {
    bottom: -8px !important; }
  .bottom-xxxl-n10px {
    bottom: -10px !important; }
  .bottom-xxxl-n19px {
    bottom: -19px !important; }
  .bottom-xxxl-n25px {
    bottom: -25px !important; }
  .bottom-xxxl-n50px {
    bottom: -50px !important; }
  .bottom-xxxl-n90px {
    bottom: -90px !important; }
  .bottom-xxxl-n200px {
    bottom: -200px !important; }
  .bottom-xxxl-n240px {
    bottom: -240px !important; }
  .bottom-xxxl-n340px {
    bottom: -340px !important; }
  .bottom-xxxl-n350px {
    bottom: -350px !important; }
  .bottom-xxxl-100n20 {
    bottom: calc(100% + 20px) !important; }
  .bottom-xxxl-100n50 {
    bottom: calc(100% - 50px) !important; }
  .bottom-xxxl-100n100 {
    bottom: calc(100% + 100px) !important; }
  .bottom-xxxl-100n200 {
    bottom: calc(100% + 200px) !important; }
  .bottom-xxxl-100n250 {
    bottom: calc(100% + 250px) !important; }
  .bottom-xxxl-100n350 {
    bottom: calc(100% + 350px) !important; }
  .start-xxxl-0 {
    left: 0 !important; }
  .start-xxxl-5 {
    left: 5% !important; }
  .start-xxxl-25 {
    left: 25% !important; }
  .start-xxxl-50 {
    left: 50% !important; }
  .start-xxxl-90 {
    left: 90% !important; }
  .start-xxxl-100 {
    left: 100% !important; }
  .start-xxxl-10px {
    left: 10px !important; }
  .start-xxxl-14px {
    left: 14px !important; }
  .start-xxxl-24px {
    left: 24px !important; }
  .start-xxxl-50px {
    left: 50px !important; }
  .start-xxxl-70px {
    left: 70px !important; }
  .start-xxxl-100px {
    left: 100px !important; }
  .start-xxxl-120px {
    left: 120px !important; }
  .start-xxxl-150px {
    left: 150px !important; }
  .start-xxxl-170px {
    left: 170px !important; }
  .start-xxxl-200px {
    left: 200px !important; }
  .start-xxxl-230px {
    left: 230px !important; }
  .start-xxxl-240px {
    left: 240px !important; }
  .start-xxxl-250px {
    left: 250px !important; }
  .start-xxxl-n8px {
    left: -8px !important; }
  .start-xxxl-n10px {
    left: -10px !important; }
  .start-xxxl-n19px {
    left: -19px !important; }
  .start-xxxl-n25px {
    left: -25px !important; }
  .start-xxxl-n50px {
    left: -50px !important; }
  .start-xxxl-n90px {
    left: -90px !important; }
  .start-xxxl-n200px {
    left: -200px !important; }
  .start-xxxl-n240px {
    left: -240px !important; }
  .start-xxxl-n340px {
    left: -340px !important; }
  .start-xxxl-n350px {
    left: -350px !important; }
  .start-xxxl-100n20 {
    left: calc(100% + 20px) !important; }
  .start-xxxl-100n50 {
    left: calc(100% - 50px) !important; }
  .start-xxxl-100n100 {
    left: calc(100% + 100px) !important; }
  .start-xxxl-100n200 {
    left: calc(100% + 200px) !important; }
  .start-xxxl-100n250 {
    left: calc(100% + 250px) !important; }
  .start-xxxl-100n350 {
    left: calc(100% + 350px) !important; }
  .end-xxxl-0 {
    right: 0 !important; }
  .end-xxxl-5 {
    right: 5% !important; }
  .end-xxxl-25 {
    right: 25% !important; }
  .end-xxxl-50 {
    right: 50% !important; }
  .end-xxxl-90 {
    right: 90% !important; }
  .end-xxxl-100 {
    right: 100% !important; }
  .end-xxxl-10px {
    right: 10px !important; }
  .end-xxxl-14px {
    right: 14px !important; }
  .end-xxxl-24px {
    right: 24px !important; }
  .end-xxxl-50px {
    right: 50px !important; }
  .end-xxxl-70px {
    right: 70px !important; }
  .end-xxxl-100px {
    right: 100px !important; }
  .end-xxxl-120px {
    right: 120px !important; }
  .end-xxxl-150px {
    right: 150px !important; }
  .end-xxxl-170px {
    right: 170px !important; }
  .end-xxxl-200px {
    right: 200px !important; }
  .end-xxxl-230px {
    right: 230px !important; }
  .end-xxxl-240px {
    right: 240px !important; }
  .end-xxxl-250px {
    right: 250px !important; }
  .end-xxxl-n8px {
    right: -8px !important; }
  .end-xxxl-n10px {
    right: -10px !important; }
  .end-xxxl-n19px {
    right: -19px !important; }
  .end-xxxl-n25px {
    right: -25px !important; }
  .end-xxxl-n50px {
    right: -50px !important; }
  .end-xxxl-n90px {
    right: -90px !important; }
  .end-xxxl-n200px {
    right: -200px !important; }
  .end-xxxl-n240px {
    right: -240px !important; }
  .end-xxxl-n340px {
    right: -340px !important; }
  .end-xxxl-n350px {
    right: -350px !important; }
  .end-xxxl-100n20 {
    right: calc(100% + 20px) !important; }
  .end-xxxl-100n50 {
    right: calc(100% - 50px) !important; }
  .end-xxxl-100n100 {
    right: calc(100% + 100px) !important; }
  .end-xxxl-100n200 {
    right: calc(100% + 200px) !important; }
  .end-xxxl-100n250 {
    right: calc(100% + 250px) !important; }
  .end-xxxl-100n350 {
    right: calc(100% + 350px) !important; }
  .w-xxxl-25 {
    width: 25% !important; }
  .w-xxxl-50 {
    width: 50% !important; }
  .w-xxxl-75 {
    width: 75% !important; }
  .w-xxxl-100 {
    width: 100% !important; }
  .w-xxxl-auto {
    width: auto !important; }
  .w-xxxl-135px {
    width: 135px !important; }
  .w-xxxl-150px {
    width: 150px !important; }
  .w-xxxl-180px {
    width: 180px !important; }
  .w-xxxl-190px {
    width: 190px !important; }
  .w-xxxl-200px {
    width: 200px !important; }
  .w-xxxl-240px {
    width: 240px !important; }
  .w-xxxl-260px {
    width: 260px !important; }
  .w-xxxl-296px {
    width: 296px !important; }
  .w-xxxl-340px {
    width: 340px !important; }
  .w-xxxl-368px {
    width: 368px !important; }
  .w-xxxl-400px {
    width: 400px !important; }
  .w-xxxl-500px {
    width: 500px !important; }
  .w-xxxl-520px {
    width: 520px !important; }
  .w-xxxl-800px {
    width: 800px !important; }
  .h-xxxl-25 {
    height: 25% !important; }
  .h-xxxl-50 {
    height: 50% !important; }
  .h-xxxl-75 {
    height: 75% !important; }
  .h-xxxl-100 {
    height: 100% !important; }
  .h-xxxl-auto {
    height: auto !important; }
  .h-xxxl-135px {
    height: 135px !important; }
  .h-xxxl-150px {
    height: 150px !important; }
  .h-xxxl-180px {
    height: 180px !important; }
  .h-xxxl-190px {
    height: 190px !important; }
  .h-xxxl-200px {
    height: 200px !important; }
  .h-xxxl-240px {
    height: 240px !important; }
  .h-xxxl-260px {
    height: 260px !important; }
  .h-xxxl-296px {
    height: 296px !important; }
  .h-xxxl-340px {
    height: 340px !important; }
  .h-xxxl-368px {
    height: 368px !important; }
  .h-xxxl-400px {
    height: 400px !important; }
  .h-xxxl-500px {
    height: 500px !important; }
  .h-xxxl-520px {
    height: 520px !important; }
  .h-xxxl-800px {
    height: 800px !important; }
  .flex-xxxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxxl-row {
    flex-direction: row !important; }
  .flex-xxxl-column {
    flex-direction: column !important; }
  .flex-xxxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxxl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xxxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xxxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxxl-center {
    justify-content: center !important; }
  .justify-content-xxxl-between {
    justify-content: space-between !important; }
  .justify-content-xxxl-around {
    justify-content: space-around !important; }
  .justify-content-xxxl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xxxl-start {
    align-items: flex-start !important; }
  .align-items-xxxl-end {
    align-items: flex-end !important; }
  .align-items-xxxl-center {
    align-items: center !important; }
  .align-items-xxxl-baseline {
    align-items: baseline !important; }
  .align-items-xxxl-stretch {
    align-items: stretch !important; }
  .align-content-xxxl-start {
    align-content: flex-start !important; }
  .align-content-xxxl-end {
    align-content: flex-end !important; }
  .align-content-xxxl-center {
    align-content: center !important; }
  .align-content-xxxl-between {
    align-content: space-between !important; }
  .align-content-xxxl-around {
    align-content: space-around !important; }
  .align-content-xxxl-stretch {
    align-content: stretch !important; }
  .align-self-xxxl-auto {
    align-self: auto !important; }
  .align-self-xxxl-start {
    align-self: flex-start !important; }
  .align-self-xxxl-end {
    align-self: flex-end !important; }
  .align-self-xxxl-center {
    align-self: center !important; }
  .align-self-xxxl-baseline {
    align-self: baseline !important; }
  .align-self-xxxl-stretch {
    align-self: stretch !important; }
  .order-xxxl-first {
    order: -1 !important; }
  .order-xxxl-0 {
    order: 0 !important; }
  .order-xxxl-1 {
    order: 1 !important; }
  .order-xxxl-2 {
    order: 2 !important; }
  .order-xxxl-3 {
    order: 3 !important; }
  .order-xxxl-4 {
    order: 4 !important; }
  .order-xxxl-5 {
    order: 5 !important; }
  .order-xxxl-last {
    order: 6 !important; }
  .m-xxxl-0 {
    margin: 0 !important; }
  .m-xxxl-1 {
    margin: 0.1rem !important; }
  .m-xxxl-2 {
    margin: 0.2rem !important; }
  .m-xxxl-3 {
    margin: 0.3rem !important; }
  .m-xxxl-4 {
    margin: 0.4rem !important; }
  .m-xxxl-5 {
    margin: 0.5rem !important; }
  .m-xxxl-6 {
    margin: 0.6rem !important; }
  .m-xxxl-8 {
    margin: 0.8rem !important; }
  .m-xxxl-10 {
    margin: 1rem !important; }
  .m-xxxl-12 {
    margin: 1.2rem !important; }
  .m-xxxl-13 {
    margin: 1.3rem !important; }
  .m-xxxl-15 {
    margin: 1.5rem !important; }
  .m-xxxl-16 {
    margin: 1.6rem !important; }
  .m-xxxl-20 {
    margin: 2rem !important; }
  .m-xxxl-24 {
    margin: 2.4rem !important; }
  .m-xxxl-25 {
    margin: 2.5rem !important; }
  .m-xxxl-29 {
    margin: 2.9rem !important; }
  .m-xxxl-30 {
    margin: 3rem !important; }
  .m-xxxl-32 {
    margin: 3.2rem !important; }
  .m-xxxl-35 {
    margin: 3.5rem !important; }
  .m-xxxl-37 {
    margin: 3.7rem !important; }
  .m-xxxl-40 {
    margin: 4rem !important; }
  .m-xxxl-42 {
    margin: 4.2rem !important; }
  .m-xxxl-44 {
    margin: 4.4rem !important; }
  .m-xxxl-48 {
    margin: 4.8rem !important; }
  .m-xxxl-50 {
    margin: 5rem !important; }
  .m-xxxl-52 {
    margin: 5.2rem !important; }
  .m-xxxl-55 {
    margin: 5.5rem !important; }
  .m-xxxl-60 {
    margin: 6rem !important; }
  .m-xxxl-62 {
    margin: 6.2rem !important; }
  .m-xxxl-64 {
    margin: 6.4rem !important; }
  .m-xxxl-65 {
    margin: 6.5rem !important; }
  .m-xxxl-70 {
    margin: 7rem !important; }
  .m-xxxl-72 {
    margin: 7.2rem !important; }
  .m-xxxl-80 {
    margin: 8rem !important; }
  .m-xxxl-85 {
    margin: 8.5rem !important; }
  .m-xxxl-95 {
    margin: 9.5rem !important; }
  .m-xxxl-96 {
    margin: 9.6rem !important; }
  .m-xxxl-100 {
    margin: 10rem !important; }
  .m-xxxl-104 {
    margin: 10.4rem !important; }
  .m-xxxl-108 {
    margin: 10.8rem !important; }
  .m-xxxl-110 {
    margin: 11rem !important; }
  .m-xxxl-116 {
    margin: 11.6rem !important; }
  .m-xxxl-120 {
    margin: 12rem !important; }
  .m-xxxl-128 {
    margin: 12.8rem !important; }
  .m-xxxl-140 {
    margin: 14rem !important; }
  .m-xxxl-148 {
    margin: 14.8rem !important; }
  .m-xxxl-160 {
    margin: 16rem !important; }
  .m-xxxl-170 {
    margin: 17rem !important; }
  .m-xxxl-200 {
    margin: 20rem !important; }
  .m-xxxl-240 {
    margin: 24rem !important; }
  .m-xxxl-245 {
    margin: 24.5rem !important; }
  .m-xxxl-250 {
    margin: 25rem !important; }
  .m-xxxl-300 {
    margin: 30rem !important; }
  .m-xxxl-400 {
    margin: 40rem !important; }
  .m-xxxl-n25 {
    margin: -25% !important; }
  .m-xxxl-auto {
    margin: auto !important; }
  .mx-xxxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xxxl-1 {
    margin-right: 0.1rem !important;
    margin-left: 0.1rem !important; }
  .mx-xxxl-2 {
    margin-right: 0.2rem !important;
    margin-left: 0.2rem !important; }
  .mx-xxxl-3 {
    margin-right: 0.3rem !important;
    margin-left: 0.3rem !important; }
  .mx-xxxl-4 {
    margin-right: 0.4rem !important;
    margin-left: 0.4rem !important; }
  .mx-xxxl-5 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xxxl-6 {
    margin-right: 0.6rem !important;
    margin-left: 0.6rem !important; }
  .mx-xxxl-8 {
    margin-right: 0.8rem !important;
    margin-left: 0.8rem !important; }
  .mx-xxxl-10 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xxxl-12 {
    margin-right: 1.2rem !important;
    margin-left: 1.2rem !important; }
  .mx-xxxl-13 {
    margin-right: 1.3rem !important;
    margin-left: 1.3rem !important; }
  .mx-xxxl-15 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xxxl-16 {
    margin-right: 1.6rem !important;
    margin-left: 1.6rem !important; }
  .mx-xxxl-20 {
    margin-right: 2rem !important;
    margin-left: 2rem !important; }
  .mx-xxxl-24 {
    margin-right: 2.4rem !important;
    margin-left: 2.4rem !important; }
  .mx-xxxl-25 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important; }
  .mx-xxxl-29 {
    margin-right: 2.9rem !important;
    margin-left: 2.9rem !important; }
  .mx-xxxl-30 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xxxl-32 {
    margin-right: 3.2rem !important;
    margin-left: 3.2rem !important; }
  .mx-xxxl-35 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important; }
  .mx-xxxl-37 {
    margin-right: 3.7rem !important;
    margin-left: 3.7rem !important; }
  .mx-xxxl-40 {
    margin-right: 4rem !important;
    margin-left: 4rem !important; }
  .mx-xxxl-42 {
    margin-right: 4.2rem !important;
    margin-left: 4.2rem !important; }
  .mx-xxxl-44 {
    margin-right: 4.4rem !important;
    margin-left: 4.4rem !important; }
  .mx-xxxl-48 {
    margin-right: 4.8rem !important;
    margin-left: 4.8rem !important; }
  .mx-xxxl-50 {
    margin-right: 5rem !important;
    margin-left: 5rem !important; }
  .mx-xxxl-52 {
    margin-right: 5.2rem !important;
    margin-left: 5.2rem !important; }
  .mx-xxxl-55 {
    margin-right: 5.5rem !important;
    margin-left: 5.5rem !important; }
  .mx-xxxl-60 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .mx-xxxl-62 {
    margin-right: 6.2rem !important;
    margin-left: 6.2rem !important; }
  .mx-xxxl-64 {
    margin-right: 6.4rem !important;
    margin-left: 6.4rem !important; }
  .mx-xxxl-65 {
    margin-right: 6.5rem !important;
    margin-left: 6.5rem !important; }
  .mx-xxxl-70 {
    margin-right: 7rem !important;
    margin-left: 7rem !important; }
  .mx-xxxl-72 {
    margin-right: 7.2rem !important;
    margin-left: 7.2rem !important; }
  .mx-xxxl-80 {
    margin-right: 8rem !important;
    margin-left: 8rem !important; }
  .mx-xxxl-85 {
    margin-right: 8.5rem !important;
    margin-left: 8.5rem !important; }
  .mx-xxxl-95 {
    margin-right: 9.5rem !important;
    margin-left: 9.5rem !important; }
  .mx-xxxl-96 {
    margin-right: 9.6rem !important;
    margin-left: 9.6rem !important; }
  .mx-xxxl-100 {
    margin-right: 10rem !important;
    margin-left: 10rem !important; }
  .mx-xxxl-104 {
    margin-right: 10.4rem !important;
    margin-left: 10.4rem !important; }
  .mx-xxxl-108 {
    margin-right: 10.8rem !important;
    margin-left: 10.8rem !important; }
  .mx-xxxl-110 {
    margin-right: 11rem !important;
    margin-left: 11rem !important; }
  .mx-xxxl-116 {
    margin-right: 11.6rem !important;
    margin-left: 11.6rem !important; }
  .mx-xxxl-120 {
    margin-right: 12rem !important;
    margin-left: 12rem !important; }
  .mx-xxxl-128 {
    margin-right: 12.8rem !important;
    margin-left: 12.8rem !important; }
  .mx-xxxl-140 {
    margin-right: 14rem !important;
    margin-left: 14rem !important; }
  .mx-xxxl-148 {
    margin-right: 14.8rem !important;
    margin-left: 14.8rem !important; }
  .mx-xxxl-160 {
    margin-right: 16rem !important;
    margin-left: 16rem !important; }
  .mx-xxxl-170 {
    margin-right: 17rem !important;
    margin-left: 17rem !important; }
  .mx-xxxl-200 {
    margin-right: 20rem !important;
    margin-left: 20rem !important; }
  .mx-xxxl-240 {
    margin-right: 24rem !important;
    margin-left: 24rem !important; }
  .mx-xxxl-245 {
    margin-right: 24.5rem !important;
    margin-left: 24.5rem !important; }
  .mx-xxxl-250 {
    margin-right: 25rem !important;
    margin-left: 25rem !important; }
  .mx-xxxl-300 {
    margin-right: 30rem !important;
    margin-left: 30rem !important; }
  .mx-xxxl-400 {
    margin-right: 40rem !important;
    margin-left: 40rem !important; }
  .mx-xxxl-n25 {
    margin-right: -25% !important;
    margin-left: -25% !important; }
  .mx-xxxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xxxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xxxl-1 {
    margin-top: 0.1rem !important;
    margin-bottom: 0.1rem !important; }
  .my-xxxl-2 {
    margin-top: 0.2rem !important;
    margin-bottom: 0.2rem !important; }
  .my-xxxl-3 {
    margin-top: 0.3rem !important;
    margin-bottom: 0.3rem !important; }
  .my-xxxl-4 {
    margin-top: 0.4rem !important;
    margin-bottom: 0.4rem !important; }
  .my-xxxl-5 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xxxl-6 {
    margin-top: 0.6rem !important;
    margin-bottom: 0.6rem !important; }
  .my-xxxl-8 {
    margin-top: 0.8rem !important;
    margin-bottom: 0.8rem !important; }
  .my-xxxl-10 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xxxl-12 {
    margin-top: 1.2rem !important;
    margin-bottom: 1.2rem !important; }
  .my-xxxl-13 {
    margin-top: 1.3rem !important;
    margin-bottom: 1.3rem !important; }
  .my-xxxl-15 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xxxl-16 {
    margin-top: 1.6rem !important;
    margin-bottom: 1.6rem !important; }
  .my-xxxl-20 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important; }
  .my-xxxl-24 {
    margin-top: 2.4rem !important;
    margin-bottom: 2.4rem !important; }
  .my-xxxl-25 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important; }
  .my-xxxl-29 {
    margin-top: 2.9rem !important;
    margin-bottom: 2.9rem !important; }
  .my-xxxl-30 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xxxl-32 {
    margin-top: 3.2rem !important;
    margin-bottom: 3.2rem !important; }
  .my-xxxl-35 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important; }
  .my-xxxl-37 {
    margin-top: 3.7rem !important;
    margin-bottom: 3.7rem !important; }
  .my-xxxl-40 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important; }
  .my-xxxl-42 {
    margin-top: 4.2rem !important;
    margin-bottom: 4.2rem !important; }
  .my-xxxl-44 {
    margin-top: 4.4rem !important;
    margin-bottom: 4.4rem !important; }
  .my-xxxl-48 {
    margin-top: 4.8rem !important;
    margin-bottom: 4.8rem !important; }
  .my-xxxl-50 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important; }
  .my-xxxl-52 {
    margin-top: 5.2rem !important;
    margin-bottom: 5.2rem !important; }
  .my-xxxl-55 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important; }
  .my-xxxl-60 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .my-xxxl-62 {
    margin-top: 6.2rem !important;
    margin-bottom: 6.2rem !important; }
  .my-xxxl-64 {
    margin-top: 6.4rem !important;
    margin-bottom: 6.4rem !important; }
  .my-xxxl-65 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important; }
  .my-xxxl-70 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important; }
  .my-xxxl-72 {
    margin-top: 7.2rem !important;
    margin-bottom: 7.2rem !important; }
  .my-xxxl-80 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important; }
  .my-xxxl-85 {
    margin-top: 8.5rem !important;
    margin-bottom: 8.5rem !important; }
  .my-xxxl-95 {
    margin-top: 9.5rem !important;
    margin-bottom: 9.5rem !important; }
  .my-xxxl-96 {
    margin-top: 9.6rem !important;
    margin-bottom: 9.6rem !important; }
  .my-xxxl-100 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important; }
  .my-xxxl-104 {
    margin-top: 10.4rem !important;
    margin-bottom: 10.4rem !important; }
  .my-xxxl-108 {
    margin-top: 10.8rem !important;
    margin-bottom: 10.8rem !important; }
  .my-xxxl-110 {
    margin-top: 11rem !important;
    margin-bottom: 11rem !important; }
  .my-xxxl-116 {
    margin-top: 11.6rem !important;
    margin-bottom: 11.6rem !important; }
  .my-xxxl-120 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important; }
  .my-xxxl-128 {
    margin-top: 12.8rem !important;
    margin-bottom: 12.8rem !important; }
  .my-xxxl-140 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important; }
  .my-xxxl-148 {
    margin-top: 14.8rem !important;
    margin-bottom: 14.8rem !important; }
  .my-xxxl-160 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important; }
  .my-xxxl-170 {
    margin-top: 17rem !important;
    margin-bottom: 17rem !important; }
  .my-xxxl-200 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important; }
  .my-xxxl-240 {
    margin-top: 24rem !important;
    margin-bottom: 24rem !important; }
  .my-xxxl-245 {
    margin-top: 24.5rem !important;
    margin-bottom: 24.5rem !important; }
  .my-xxxl-250 {
    margin-top: 25rem !important;
    margin-bottom: 25rem !important; }
  .my-xxxl-300 {
    margin-top: 30rem !important;
    margin-bottom: 30rem !important; }
  .my-xxxl-400 {
    margin-top: 40rem !important;
    margin-bottom: 40rem !important; }
  .my-xxxl-n25 {
    margin-top: -25% !important;
    margin-bottom: -25% !important; }
  .my-xxxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xxxl-0 {
    margin-top: 0 !important; }
  .mt-xxxl-1 {
    margin-top: 0.1rem !important; }
  .mt-xxxl-2 {
    margin-top: 0.2rem !important; }
  .mt-xxxl-3 {
    margin-top: 0.3rem !important; }
  .mt-xxxl-4 {
    margin-top: 0.4rem !important; }
  .mt-xxxl-5 {
    margin-top: 0.5rem !important; }
  .mt-xxxl-6 {
    margin-top: 0.6rem !important; }
  .mt-xxxl-8 {
    margin-top: 0.8rem !important; }
  .mt-xxxl-10 {
    margin-top: 1rem !important; }
  .mt-xxxl-12 {
    margin-top: 1.2rem !important; }
  .mt-xxxl-13 {
    margin-top: 1.3rem !important; }
  .mt-xxxl-15 {
    margin-top: 1.5rem !important; }
  .mt-xxxl-16 {
    margin-top: 1.6rem !important; }
  .mt-xxxl-20 {
    margin-top: 2rem !important; }
  .mt-xxxl-24 {
    margin-top: 2.4rem !important; }
  .mt-xxxl-25 {
    margin-top: 2.5rem !important; }
  .mt-xxxl-29 {
    margin-top: 2.9rem !important; }
  .mt-xxxl-30 {
    margin-top: 3rem !important; }
  .mt-xxxl-32 {
    margin-top: 3.2rem !important; }
  .mt-xxxl-35 {
    margin-top: 3.5rem !important; }
  .mt-xxxl-37 {
    margin-top: 3.7rem !important; }
  .mt-xxxl-40 {
    margin-top: 4rem !important; }
  .mt-xxxl-42 {
    margin-top: 4.2rem !important; }
  .mt-xxxl-44 {
    margin-top: 4.4rem !important; }
  .mt-xxxl-48 {
    margin-top: 4.8rem !important; }
  .mt-xxxl-50 {
    margin-top: 5rem !important; }
  .mt-xxxl-52 {
    margin-top: 5.2rem !important; }
  .mt-xxxl-55 {
    margin-top: 5.5rem !important; }
  .mt-xxxl-60 {
    margin-top: 6rem !important; }
  .mt-xxxl-62 {
    margin-top: 6.2rem !important; }
  .mt-xxxl-64 {
    margin-top: 6.4rem !important; }
  .mt-xxxl-65 {
    margin-top: 6.5rem !important; }
  .mt-xxxl-70 {
    margin-top: 7rem !important; }
  .mt-xxxl-72 {
    margin-top: 7.2rem !important; }
  .mt-xxxl-80 {
    margin-top: 8rem !important; }
  .mt-xxxl-85 {
    margin-top: 8.5rem !important; }
  .mt-xxxl-95 {
    margin-top: 9.5rem !important; }
  .mt-xxxl-96 {
    margin-top: 9.6rem !important; }
  .mt-xxxl-100 {
    margin-top: 10rem !important; }
  .mt-xxxl-104 {
    margin-top: 10.4rem !important; }
  .mt-xxxl-108 {
    margin-top: 10.8rem !important; }
  .mt-xxxl-110 {
    margin-top: 11rem !important; }
  .mt-xxxl-116 {
    margin-top: 11.6rem !important; }
  .mt-xxxl-120 {
    margin-top: 12rem !important; }
  .mt-xxxl-128 {
    margin-top: 12.8rem !important; }
  .mt-xxxl-140 {
    margin-top: 14rem !important; }
  .mt-xxxl-148 {
    margin-top: 14.8rem !important; }
  .mt-xxxl-160 {
    margin-top: 16rem !important; }
  .mt-xxxl-170 {
    margin-top: 17rem !important; }
  .mt-xxxl-200 {
    margin-top: 20rem !important; }
  .mt-xxxl-240 {
    margin-top: 24rem !important; }
  .mt-xxxl-245 {
    margin-top: 24.5rem !important; }
  .mt-xxxl-250 {
    margin-top: 25rem !important; }
  .mt-xxxl-300 {
    margin-top: 30rem !important; }
  .mt-xxxl-400 {
    margin-top: 40rem !important; }
  .mt-xxxl-n25 {
    margin-top: -25% !important; }
  .mt-xxxl-auto {
    margin-top: auto !important; }
  .me-xxxl-0 {
    margin-right: 0 !important; }
  .me-xxxl-1 {
    margin-right: 0.1rem !important; }
  .me-xxxl-2 {
    margin-right: 0.2rem !important; }
  .me-xxxl-3 {
    margin-right: 0.3rem !important; }
  .me-xxxl-4 {
    margin-right: 0.4rem !important; }
  .me-xxxl-5 {
    margin-right: 0.5rem !important; }
  .me-xxxl-6 {
    margin-right: 0.6rem !important; }
  .me-xxxl-8 {
    margin-right: 0.8rem !important; }
  .me-xxxl-10 {
    margin-right: 1rem !important; }
  .me-xxxl-12 {
    margin-right: 1.2rem !important; }
  .me-xxxl-13 {
    margin-right: 1.3rem !important; }
  .me-xxxl-15 {
    margin-right: 1.5rem !important; }
  .me-xxxl-16 {
    margin-right: 1.6rem !important; }
  .me-xxxl-20 {
    margin-right: 2rem !important; }
  .me-xxxl-24 {
    margin-right: 2.4rem !important; }
  .me-xxxl-25 {
    margin-right: 2.5rem !important; }
  .me-xxxl-29 {
    margin-right: 2.9rem !important; }
  .me-xxxl-30 {
    margin-right: 3rem !important; }
  .me-xxxl-32 {
    margin-right: 3.2rem !important; }
  .me-xxxl-35 {
    margin-right: 3.5rem !important; }
  .me-xxxl-37 {
    margin-right: 3.7rem !important; }
  .me-xxxl-40 {
    margin-right: 4rem !important; }
  .me-xxxl-42 {
    margin-right: 4.2rem !important; }
  .me-xxxl-44 {
    margin-right: 4.4rem !important; }
  .me-xxxl-48 {
    margin-right: 4.8rem !important; }
  .me-xxxl-50 {
    margin-right: 5rem !important; }
  .me-xxxl-52 {
    margin-right: 5.2rem !important; }
  .me-xxxl-55 {
    margin-right: 5.5rem !important; }
  .me-xxxl-60 {
    margin-right: 6rem !important; }
  .me-xxxl-62 {
    margin-right: 6.2rem !important; }
  .me-xxxl-64 {
    margin-right: 6.4rem !important; }
  .me-xxxl-65 {
    margin-right: 6.5rem !important; }
  .me-xxxl-70 {
    margin-right: 7rem !important; }
  .me-xxxl-72 {
    margin-right: 7.2rem !important; }
  .me-xxxl-80 {
    margin-right: 8rem !important; }
  .me-xxxl-85 {
    margin-right: 8.5rem !important; }
  .me-xxxl-95 {
    margin-right: 9.5rem !important; }
  .me-xxxl-96 {
    margin-right: 9.6rem !important; }
  .me-xxxl-100 {
    margin-right: 10rem !important; }
  .me-xxxl-104 {
    margin-right: 10.4rem !important; }
  .me-xxxl-108 {
    margin-right: 10.8rem !important; }
  .me-xxxl-110 {
    margin-right: 11rem !important; }
  .me-xxxl-116 {
    margin-right: 11.6rem !important; }
  .me-xxxl-120 {
    margin-right: 12rem !important; }
  .me-xxxl-128 {
    margin-right: 12.8rem !important; }
  .me-xxxl-140 {
    margin-right: 14rem !important; }
  .me-xxxl-148 {
    margin-right: 14.8rem !important; }
  .me-xxxl-160 {
    margin-right: 16rem !important; }
  .me-xxxl-170 {
    margin-right: 17rem !important; }
  .me-xxxl-200 {
    margin-right: 20rem !important; }
  .me-xxxl-240 {
    margin-right: 24rem !important; }
  .me-xxxl-245 {
    margin-right: 24.5rem !important; }
  .me-xxxl-250 {
    margin-right: 25rem !important; }
  .me-xxxl-300 {
    margin-right: 30rem !important; }
  .me-xxxl-400 {
    margin-right: 40rem !important; }
  .me-xxxl-n25 {
    margin-right: -25% !important; }
  .me-xxxl-auto {
    margin-right: auto !important; }
  .mb-xxxl-0 {
    margin-bottom: 0 !important; }
  .mb-xxxl-1 {
    margin-bottom: 0.1rem !important; }
  .mb-xxxl-2 {
    margin-bottom: 0.2rem !important; }
  .mb-xxxl-3 {
    margin-bottom: 0.3rem !important; }
  .mb-xxxl-4 {
    margin-bottom: 0.4rem !important; }
  .mb-xxxl-5 {
    margin-bottom: 0.5rem !important; }
  .mb-xxxl-6 {
    margin-bottom: 0.6rem !important; }
  .mb-xxxl-8 {
    margin-bottom: 0.8rem !important; }
  .mb-xxxl-10 {
    margin-bottom: 1rem !important; }
  .mb-xxxl-12 {
    margin-bottom: 1.2rem !important; }
  .mb-xxxl-13 {
    margin-bottom: 1.3rem !important; }
  .mb-xxxl-15 {
    margin-bottom: 1.5rem !important; }
  .mb-xxxl-16 {
    margin-bottom: 1.6rem !important; }
  .mb-xxxl-20 {
    margin-bottom: 2rem !important; }
  .mb-xxxl-24 {
    margin-bottom: 2.4rem !important; }
  .mb-xxxl-25 {
    margin-bottom: 2.5rem !important; }
  .mb-xxxl-29 {
    margin-bottom: 2.9rem !important; }
  .mb-xxxl-30 {
    margin-bottom: 3rem !important; }
  .mb-xxxl-32 {
    margin-bottom: 3.2rem !important; }
  .mb-xxxl-35 {
    margin-bottom: 3.5rem !important; }
  .mb-xxxl-37 {
    margin-bottom: 3.7rem !important; }
  .mb-xxxl-40 {
    margin-bottom: 4rem !important; }
  .mb-xxxl-42 {
    margin-bottom: 4.2rem !important; }
  .mb-xxxl-44 {
    margin-bottom: 4.4rem !important; }
  .mb-xxxl-48 {
    margin-bottom: 4.8rem !important; }
  .mb-xxxl-50 {
    margin-bottom: 5rem !important; }
  .mb-xxxl-52 {
    margin-bottom: 5.2rem !important; }
  .mb-xxxl-55 {
    margin-bottom: 5.5rem !important; }
  .mb-xxxl-60 {
    margin-bottom: 6rem !important; }
  .mb-xxxl-62 {
    margin-bottom: 6.2rem !important; }
  .mb-xxxl-64 {
    margin-bottom: 6.4rem !important; }
  .mb-xxxl-65 {
    margin-bottom: 6.5rem !important; }
  .mb-xxxl-70 {
    margin-bottom: 7rem !important; }
  .mb-xxxl-72 {
    margin-bottom: 7.2rem !important; }
  .mb-xxxl-80 {
    margin-bottom: 8rem !important; }
  .mb-xxxl-85 {
    margin-bottom: 8.5rem !important; }
  .mb-xxxl-95 {
    margin-bottom: 9.5rem !important; }
  .mb-xxxl-96 {
    margin-bottom: 9.6rem !important; }
  .mb-xxxl-100 {
    margin-bottom: 10rem !important; }
  .mb-xxxl-104 {
    margin-bottom: 10.4rem !important; }
  .mb-xxxl-108 {
    margin-bottom: 10.8rem !important; }
  .mb-xxxl-110 {
    margin-bottom: 11rem !important; }
  .mb-xxxl-116 {
    margin-bottom: 11.6rem !important; }
  .mb-xxxl-120 {
    margin-bottom: 12rem !important; }
  .mb-xxxl-128 {
    margin-bottom: 12.8rem !important; }
  .mb-xxxl-140 {
    margin-bottom: 14rem !important; }
  .mb-xxxl-148 {
    margin-bottom: 14.8rem !important; }
  .mb-xxxl-160 {
    margin-bottom: 16rem !important; }
  .mb-xxxl-170 {
    margin-bottom: 17rem !important; }
  .mb-xxxl-200 {
    margin-bottom: 20rem !important; }
  .mb-xxxl-240 {
    margin-bottom: 24rem !important; }
  .mb-xxxl-245 {
    margin-bottom: 24.5rem !important; }
  .mb-xxxl-250 {
    margin-bottom: 25rem !important; }
  .mb-xxxl-300 {
    margin-bottom: 30rem !important; }
  .mb-xxxl-400 {
    margin-bottom: 40rem !important; }
  .mb-xxxl-n25 {
    margin-bottom: -25% !important; }
  .mb-xxxl-auto {
    margin-bottom: auto !important; }
  .ms-xxxl-0 {
    margin-left: 0 !important; }
  .ms-xxxl-1 {
    margin-left: 0.1rem !important; }
  .ms-xxxl-2 {
    margin-left: 0.2rem !important; }
  .ms-xxxl-3 {
    margin-left: 0.3rem !important; }
  .ms-xxxl-4 {
    margin-left: 0.4rem !important; }
  .ms-xxxl-5 {
    margin-left: 0.5rem !important; }
  .ms-xxxl-6 {
    margin-left: 0.6rem !important; }
  .ms-xxxl-8 {
    margin-left: 0.8rem !important; }
  .ms-xxxl-10 {
    margin-left: 1rem !important; }
  .ms-xxxl-12 {
    margin-left: 1.2rem !important; }
  .ms-xxxl-13 {
    margin-left: 1.3rem !important; }
  .ms-xxxl-15 {
    margin-left: 1.5rem !important; }
  .ms-xxxl-16 {
    margin-left: 1.6rem !important; }
  .ms-xxxl-20 {
    margin-left: 2rem !important; }
  .ms-xxxl-24 {
    margin-left: 2.4rem !important; }
  .ms-xxxl-25 {
    margin-left: 2.5rem !important; }
  .ms-xxxl-29 {
    margin-left: 2.9rem !important; }
  .ms-xxxl-30 {
    margin-left: 3rem !important; }
  .ms-xxxl-32 {
    margin-left: 3.2rem !important; }
  .ms-xxxl-35 {
    margin-left: 3.5rem !important; }
  .ms-xxxl-37 {
    margin-left: 3.7rem !important; }
  .ms-xxxl-40 {
    margin-left: 4rem !important; }
  .ms-xxxl-42 {
    margin-left: 4.2rem !important; }
  .ms-xxxl-44 {
    margin-left: 4.4rem !important; }
  .ms-xxxl-48 {
    margin-left: 4.8rem !important; }
  .ms-xxxl-50 {
    margin-left: 5rem !important; }
  .ms-xxxl-52 {
    margin-left: 5.2rem !important; }
  .ms-xxxl-55 {
    margin-left: 5.5rem !important; }
  .ms-xxxl-60 {
    margin-left: 6rem !important; }
  .ms-xxxl-62 {
    margin-left: 6.2rem !important; }
  .ms-xxxl-64 {
    margin-left: 6.4rem !important; }
  .ms-xxxl-65 {
    margin-left: 6.5rem !important; }
  .ms-xxxl-70 {
    margin-left: 7rem !important; }
  .ms-xxxl-72 {
    margin-left: 7.2rem !important; }
  .ms-xxxl-80 {
    margin-left: 8rem !important; }
  .ms-xxxl-85 {
    margin-left: 8.5rem !important; }
  .ms-xxxl-95 {
    margin-left: 9.5rem !important; }
  .ms-xxxl-96 {
    margin-left: 9.6rem !important; }
  .ms-xxxl-100 {
    margin-left: 10rem !important; }
  .ms-xxxl-104 {
    margin-left: 10.4rem !important; }
  .ms-xxxl-108 {
    margin-left: 10.8rem !important; }
  .ms-xxxl-110 {
    margin-left: 11rem !important; }
  .ms-xxxl-116 {
    margin-left: 11.6rem !important; }
  .ms-xxxl-120 {
    margin-left: 12rem !important; }
  .ms-xxxl-128 {
    margin-left: 12.8rem !important; }
  .ms-xxxl-140 {
    margin-left: 14rem !important; }
  .ms-xxxl-148 {
    margin-left: 14.8rem !important; }
  .ms-xxxl-160 {
    margin-left: 16rem !important; }
  .ms-xxxl-170 {
    margin-left: 17rem !important; }
  .ms-xxxl-200 {
    margin-left: 20rem !important; }
  .ms-xxxl-240 {
    margin-left: 24rem !important; }
  .ms-xxxl-245 {
    margin-left: 24.5rem !important; }
  .ms-xxxl-250 {
    margin-left: 25rem !important; }
  .ms-xxxl-300 {
    margin-left: 30rem !important; }
  .ms-xxxl-400 {
    margin-left: 40rem !important; }
  .ms-xxxl-n25 {
    margin-left: -25% !important; }
  .ms-xxxl-auto {
    margin-left: auto !important; }
  .p-xxxl-0 {
    padding: 0 !important; }
  .p-xxxl-1 {
    padding: 0.1rem !important; }
  .p-xxxl-2 {
    padding: 0.2rem !important; }
  .p-xxxl-3 {
    padding: 0.3rem !important; }
  .p-xxxl-4 {
    padding: 0.4rem !important; }
  .p-xxxl-5 {
    padding: 0.5rem !important; }
  .p-xxxl-6 {
    padding: 0.6rem !important; }
  .p-xxxl-8 {
    padding: 0.8rem !important; }
  .p-xxxl-10 {
    padding: 1rem !important; }
  .p-xxxl-12 {
    padding: 1.2rem !important; }
  .p-xxxl-13 {
    padding: 1.3rem !important; }
  .p-xxxl-15 {
    padding: 1.5rem !important; }
  .p-xxxl-16 {
    padding: 1.6rem !important; }
  .p-xxxl-20 {
    padding: 2rem !important; }
  .p-xxxl-24 {
    padding: 2.4rem !important; }
  .p-xxxl-25 {
    padding: 2.5rem !important; }
  .p-xxxl-29 {
    padding: 2.9rem !important; }
  .p-xxxl-30 {
    padding: 3rem !important; }
  .p-xxxl-32 {
    padding: 3.2rem !important; }
  .p-xxxl-35 {
    padding: 3.5rem !important; }
  .p-xxxl-37 {
    padding: 3.7rem !important; }
  .p-xxxl-40 {
    padding: 4rem !important; }
  .p-xxxl-42 {
    padding: 4.2rem !important; }
  .p-xxxl-44 {
    padding: 4.4rem !important; }
  .p-xxxl-48 {
    padding: 4.8rem !important; }
  .p-xxxl-50 {
    padding: 5rem !important; }
  .p-xxxl-52 {
    padding: 5.2rem !important; }
  .p-xxxl-55 {
    padding: 5.5rem !important; }
  .p-xxxl-60 {
    padding: 6rem !important; }
  .p-xxxl-62 {
    padding: 6.2rem !important; }
  .p-xxxl-64 {
    padding: 6.4rem !important; }
  .p-xxxl-65 {
    padding: 6.5rem !important; }
  .p-xxxl-70 {
    padding: 7rem !important; }
  .p-xxxl-72 {
    padding: 7.2rem !important; }
  .p-xxxl-80 {
    padding: 8rem !important; }
  .p-xxxl-85 {
    padding: 8.5rem !important; }
  .p-xxxl-95 {
    padding: 9.5rem !important; }
  .p-xxxl-96 {
    padding: 9.6rem !important; }
  .p-xxxl-100 {
    padding: 10rem !important; }
  .p-xxxl-104 {
    padding: 10.4rem !important; }
  .p-xxxl-108 {
    padding: 10.8rem !important; }
  .p-xxxl-110 {
    padding: 11rem !important; }
  .p-xxxl-116 {
    padding: 11.6rem !important; }
  .p-xxxl-120 {
    padding: 12rem !important; }
  .p-xxxl-128 {
    padding: 12.8rem !important; }
  .p-xxxl-140 {
    padding: 14rem !important; }
  .p-xxxl-148 {
    padding: 14.8rem !important; }
  .p-xxxl-160 {
    padding: 16rem !important; }
  .p-xxxl-170 {
    padding: 17rem !important; }
  .p-xxxl-200 {
    padding: 20rem !important; }
  .p-xxxl-240 {
    padding: 24rem !important; }
  .p-xxxl-245 {
    padding: 24.5rem !important; }
  .p-xxxl-250 {
    padding: 25rem !important; }
  .p-xxxl-300 {
    padding: 30rem !important; }
  .p-xxxl-400 {
    padding: 40rem !important; }
  .p-xxxl-n25 {
    padding: -25% !important; }
  .px-xxxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xxxl-1 {
    padding-right: 0.1rem !important;
    padding-left: 0.1rem !important; }
  .px-xxxl-2 {
    padding-right: 0.2rem !important;
    padding-left: 0.2rem !important; }
  .px-xxxl-3 {
    padding-right: 0.3rem !important;
    padding-left: 0.3rem !important; }
  .px-xxxl-4 {
    padding-right: 0.4rem !important;
    padding-left: 0.4rem !important; }
  .px-xxxl-5 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xxxl-6 {
    padding-right: 0.6rem !important;
    padding-left: 0.6rem !important; }
  .px-xxxl-8 {
    padding-right: 0.8rem !important;
    padding-left: 0.8rem !important; }
  .px-xxxl-10 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xxxl-12 {
    padding-right: 1.2rem !important;
    padding-left: 1.2rem !important; }
  .px-xxxl-13 {
    padding-right: 1.3rem !important;
    padding-left: 1.3rem !important; }
  .px-xxxl-15 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xxxl-16 {
    padding-right: 1.6rem !important;
    padding-left: 1.6rem !important; }
  .px-xxxl-20 {
    padding-right: 2rem !important;
    padding-left: 2rem !important; }
  .px-xxxl-24 {
    padding-right: 2.4rem !important;
    padding-left: 2.4rem !important; }
  .px-xxxl-25 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important; }
  .px-xxxl-29 {
    padding-right: 2.9rem !important;
    padding-left: 2.9rem !important; }
  .px-xxxl-30 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .px-xxxl-32 {
    padding-right: 3.2rem !important;
    padding-left: 3.2rem !important; }
  .px-xxxl-35 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important; }
  .px-xxxl-37 {
    padding-right: 3.7rem !important;
    padding-left: 3.7rem !important; }
  .px-xxxl-40 {
    padding-right: 4rem !important;
    padding-left: 4rem !important; }
  .px-xxxl-42 {
    padding-right: 4.2rem !important;
    padding-left: 4.2rem !important; }
  .px-xxxl-44 {
    padding-right: 4.4rem !important;
    padding-left: 4.4rem !important; }
  .px-xxxl-48 {
    padding-right: 4.8rem !important;
    padding-left: 4.8rem !important; }
  .px-xxxl-50 {
    padding-right: 5rem !important;
    padding-left: 5rem !important; }
  .px-xxxl-52 {
    padding-right: 5.2rem !important;
    padding-left: 5.2rem !important; }
  .px-xxxl-55 {
    padding-right: 5.5rem !important;
    padding-left: 5.5rem !important; }
  .px-xxxl-60 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .px-xxxl-62 {
    padding-right: 6.2rem !important;
    padding-left: 6.2rem !important; }
  .px-xxxl-64 {
    padding-right: 6.4rem !important;
    padding-left: 6.4rem !important; }
  .px-xxxl-65 {
    padding-right: 6.5rem !important;
    padding-left: 6.5rem !important; }
  .px-xxxl-70 {
    padding-right: 7rem !important;
    padding-left: 7rem !important; }
  .px-xxxl-72 {
    padding-right: 7.2rem !important;
    padding-left: 7.2rem !important; }
  .px-xxxl-80 {
    padding-right: 8rem !important;
    padding-left: 8rem !important; }
  .px-xxxl-85 {
    padding-right: 8.5rem !important;
    padding-left: 8.5rem !important; }
  .px-xxxl-95 {
    padding-right: 9.5rem !important;
    padding-left: 9.5rem !important; }
  .px-xxxl-96 {
    padding-right: 9.6rem !important;
    padding-left: 9.6rem !important; }
  .px-xxxl-100 {
    padding-right: 10rem !important;
    padding-left: 10rem !important; }
  .px-xxxl-104 {
    padding-right: 10.4rem !important;
    padding-left: 10.4rem !important; }
  .px-xxxl-108 {
    padding-right: 10.8rem !important;
    padding-left: 10.8rem !important; }
  .px-xxxl-110 {
    padding-right: 11rem !important;
    padding-left: 11rem !important; }
  .px-xxxl-116 {
    padding-right: 11.6rem !important;
    padding-left: 11.6rem !important; }
  .px-xxxl-120 {
    padding-right: 12rem !important;
    padding-left: 12rem !important; }
  .px-xxxl-128 {
    padding-right: 12.8rem !important;
    padding-left: 12.8rem !important; }
  .px-xxxl-140 {
    padding-right: 14rem !important;
    padding-left: 14rem !important; }
  .px-xxxl-148 {
    padding-right: 14.8rem !important;
    padding-left: 14.8rem !important; }
  .px-xxxl-160 {
    padding-right: 16rem !important;
    padding-left: 16rem !important; }
  .px-xxxl-170 {
    padding-right: 17rem !important;
    padding-left: 17rem !important; }
  .px-xxxl-200 {
    padding-right: 20rem !important;
    padding-left: 20rem !important; }
  .px-xxxl-240 {
    padding-right: 24rem !important;
    padding-left: 24rem !important; }
  .px-xxxl-245 {
    padding-right: 24.5rem !important;
    padding-left: 24.5rem !important; }
  .px-xxxl-250 {
    padding-right: 25rem !important;
    padding-left: 25rem !important; }
  .px-xxxl-300 {
    padding-right: 30rem !important;
    padding-left: 30rem !important; }
  .px-xxxl-400 {
    padding-right: 40rem !important;
    padding-left: 40rem !important; }
  .px-xxxl-n25 {
    padding-right: -25% !important;
    padding-left: -25% !important; }
  .py-xxxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xxxl-1 {
    padding-top: 0.1rem !important;
    padding-bottom: 0.1rem !important; }
  .py-xxxl-2 {
    padding-top: 0.2rem !important;
    padding-bottom: 0.2rem !important; }
  .py-xxxl-3 {
    padding-top: 0.3rem !important;
    padding-bottom: 0.3rem !important; }
  .py-xxxl-4 {
    padding-top: 0.4rem !important;
    padding-bottom: 0.4rem !important; }
  .py-xxxl-5 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xxxl-6 {
    padding-top: 0.6rem !important;
    padding-bottom: 0.6rem !important; }
  .py-xxxl-8 {
    padding-top: 0.8rem !important;
    padding-bottom: 0.8rem !important; }
  .py-xxxl-10 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xxxl-12 {
    padding-top: 1.2rem !important;
    padding-bottom: 1.2rem !important; }
  .py-xxxl-13 {
    padding-top: 1.3rem !important;
    padding-bottom: 1.3rem !important; }
  .py-xxxl-15 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xxxl-16 {
    padding-top: 1.6rem !important;
    padding-bottom: 1.6rem !important; }
  .py-xxxl-20 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important; }
  .py-xxxl-24 {
    padding-top: 2.4rem !important;
    padding-bottom: 2.4rem !important; }
  .py-xxxl-25 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important; }
  .py-xxxl-29 {
    padding-top: 2.9rem !important;
    padding-bottom: 2.9rem !important; }
  .py-xxxl-30 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .py-xxxl-32 {
    padding-top: 3.2rem !important;
    padding-bottom: 3.2rem !important; }
  .py-xxxl-35 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important; }
  .py-xxxl-37 {
    padding-top: 3.7rem !important;
    padding-bottom: 3.7rem !important; }
  .py-xxxl-40 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important; }
  .py-xxxl-42 {
    padding-top: 4.2rem !important;
    padding-bottom: 4.2rem !important; }
  .py-xxxl-44 {
    padding-top: 4.4rem !important;
    padding-bottom: 4.4rem !important; }
  .py-xxxl-48 {
    padding-top: 4.8rem !important;
    padding-bottom: 4.8rem !important; }
  .py-xxxl-50 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important; }
  .py-xxxl-52 {
    padding-top: 5.2rem !important;
    padding-bottom: 5.2rem !important; }
  .py-xxxl-55 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important; }
  .py-xxxl-60 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .py-xxxl-62 {
    padding-top: 6.2rem !important;
    padding-bottom: 6.2rem !important; }
  .py-xxxl-64 {
    padding-top: 6.4rem !important;
    padding-bottom: 6.4rem !important; }
  .py-xxxl-65 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important; }
  .py-xxxl-70 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important; }
  .py-xxxl-72 {
    padding-top: 7.2rem !important;
    padding-bottom: 7.2rem !important; }
  .py-xxxl-80 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important; }
  .py-xxxl-85 {
    padding-top: 8.5rem !important;
    padding-bottom: 8.5rem !important; }
  .py-xxxl-95 {
    padding-top: 9.5rem !important;
    padding-bottom: 9.5rem !important; }
  .py-xxxl-96 {
    padding-top: 9.6rem !important;
    padding-bottom: 9.6rem !important; }
  .py-xxxl-100 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important; }
  .py-xxxl-104 {
    padding-top: 10.4rem !important;
    padding-bottom: 10.4rem !important; }
  .py-xxxl-108 {
    padding-top: 10.8rem !important;
    padding-bottom: 10.8rem !important; }
  .py-xxxl-110 {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important; }
  .py-xxxl-116 {
    padding-top: 11.6rem !important;
    padding-bottom: 11.6rem !important; }
  .py-xxxl-120 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important; }
  .py-xxxl-128 {
    padding-top: 12.8rem !important;
    padding-bottom: 12.8rem !important; }
  .py-xxxl-140 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important; }
  .py-xxxl-148 {
    padding-top: 14.8rem !important;
    padding-bottom: 14.8rem !important; }
  .py-xxxl-160 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important; }
  .py-xxxl-170 {
    padding-top: 17rem !important;
    padding-bottom: 17rem !important; }
  .py-xxxl-200 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important; }
  .py-xxxl-240 {
    padding-top: 24rem !important;
    padding-bottom: 24rem !important; }
  .py-xxxl-245 {
    padding-top: 24.5rem !important;
    padding-bottom: 24.5rem !important; }
  .py-xxxl-250 {
    padding-top: 25rem !important;
    padding-bottom: 25rem !important; }
  .py-xxxl-300 {
    padding-top: 30rem !important;
    padding-bottom: 30rem !important; }
  .py-xxxl-400 {
    padding-top: 40rem !important;
    padding-bottom: 40rem !important; }
  .py-xxxl-n25 {
    padding-top: -25% !important;
    padding-bottom: -25% !important; }
  .pt-xxxl-0 {
    padding-top: 0 !important; }
  .pt-xxxl-1 {
    padding-top: 0.1rem !important; }
  .pt-xxxl-2 {
    padding-top: 0.2rem !important; }
  .pt-xxxl-3 {
    padding-top: 0.3rem !important; }
  .pt-xxxl-4 {
    padding-top: 0.4rem !important; }
  .pt-xxxl-5 {
    padding-top: 0.5rem !important; }
  .pt-xxxl-6 {
    padding-top: 0.6rem !important; }
  .pt-xxxl-8 {
    padding-top: 0.8rem !important; }
  .pt-xxxl-10 {
    padding-top: 1rem !important; }
  .pt-xxxl-12 {
    padding-top: 1.2rem !important; }
  .pt-xxxl-13 {
    padding-top: 1.3rem !important; }
  .pt-xxxl-15 {
    padding-top: 1.5rem !important; }
  .pt-xxxl-16 {
    padding-top: 1.6rem !important; }
  .pt-xxxl-20 {
    padding-top: 2rem !important; }
  .pt-xxxl-24 {
    padding-top: 2.4rem !important; }
  .pt-xxxl-25 {
    padding-top: 2.5rem !important; }
  .pt-xxxl-29 {
    padding-top: 2.9rem !important; }
  .pt-xxxl-30 {
    padding-top: 3rem !important; }
  .pt-xxxl-32 {
    padding-top: 3.2rem !important; }
  .pt-xxxl-35 {
    padding-top: 3.5rem !important; }
  .pt-xxxl-37 {
    padding-top: 3.7rem !important; }
  .pt-xxxl-40 {
    padding-top: 4rem !important; }
  .pt-xxxl-42 {
    padding-top: 4.2rem !important; }
  .pt-xxxl-44 {
    padding-top: 4.4rem !important; }
  .pt-xxxl-48 {
    padding-top: 4.8rem !important; }
  .pt-xxxl-50 {
    padding-top: 5rem !important; }
  .pt-xxxl-52 {
    padding-top: 5.2rem !important; }
  .pt-xxxl-55 {
    padding-top: 5.5rem !important; }
  .pt-xxxl-60 {
    padding-top: 6rem !important; }
  .pt-xxxl-62 {
    padding-top: 6.2rem !important; }
  .pt-xxxl-64 {
    padding-top: 6.4rem !important; }
  .pt-xxxl-65 {
    padding-top: 6.5rem !important; }
  .pt-xxxl-70 {
    padding-top: 7rem !important; }
  .pt-xxxl-72 {
    padding-top: 7.2rem !important; }
  .pt-xxxl-80 {
    padding-top: 8rem !important; }
  .pt-xxxl-85 {
    padding-top: 8.5rem !important; }
  .pt-xxxl-95 {
    padding-top: 9.5rem !important; }
  .pt-xxxl-96 {
    padding-top: 9.6rem !important; }
  .pt-xxxl-100 {
    padding-top: 10rem !important; }
  .pt-xxxl-104 {
    padding-top: 10.4rem !important; }
  .pt-xxxl-108 {
    padding-top: 10.8rem !important; }
  .pt-xxxl-110 {
    padding-top: 11rem !important; }
  .pt-xxxl-116 {
    padding-top: 11.6rem !important; }
  .pt-xxxl-120 {
    padding-top: 12rem !important; }
  .pt-xxxl-128 {
    padding-top: 12.8rem !important; }
  .pt-xxxl-140 {
    padding-top: 14rem !important; }
  .pt-xxxl-148 {
    padding-top: 14.8rem !important; }
  .pt-xxxl-160 {
    padding-top: 16rem !important; }
  .pt-xxxl-170 {
    padding-top: 17rem !important; }
  .pt-xxxl-200 {
    padding-top: 20rem !important; }
  .pt-xxxl-240 {
    padding-top: 24rem !important; }
  .pt-xxxl-245 {
    padding-top: 24.5rem !important; }
  .pt-xxxl-250 {
    padding-top: 25rem !important; }
  .pt-xxxl-300 {
    padding-top: 30rem !important; }
  .pt-xxxl-400 {
    padding-top: 40rem !important; }
  .pt-xxxl-n25 {
    padding-top: -25% !important; }
  .pe-xxxl-0 {
    padding-right: 0 !important; }
  .pe-xxxl-1 {
    padding-right: 0.1rem !important; }
  .pe-xxxl-2 {
    padding-right: 0.2rem !important; }
  .pe-xxxl-3 {
    padding-right: 0.3rem !important; }
  .pe-xxxl-4 {
    padding-right: 0.4rem !important; }
  .pe-xxxl-5 {
    padding-right: 0.5rem !important; }
  .pe-xxxl-6 {
    padding-right: 0.6rem !important; }
  .pe-xxxl-8 {
    padding-right: 0.8rem !important; }
  .pe-xxxl-10 {
    padding-right: 1rem !important; }
  .pe-xxxl-12 {
    padding-right: 1.2rem !important; }
  .pe-xxxl-13 {
    padding-right: 1.3rem !important; }
  .pe-xxxl-15 {
    padding-right: 1.5rem !important; }
  .pe-xxxl-16 {
    padding-right: 1.6rem !important; }
  .pe-xxxl-20 {
    padding-right: 2rem !important; }
  .pe-xxxl-24 {
    padding-right: 2.4rem !important; }
  .pe-xxxl-25 {
    padding-right: 2.5rem !important; }
  .pe-xxxl-29 {
    padding-right: 2.9rem !important; }
  .pe-xxxl-30 {
    padding-right: 3rem !important; }
  .pe-xxxl-32 {
    padding-right: 3.2rem !important; }
  .pe-xxxl-35 {
    padding-right: 3.5rem !important; }
  .pe-xxxl-37 {
    padding-right: 3.7rem !important; }
  .pe-xxxl-40 {
    padding-right: 4rem !important; }
  .pe-xxxl-42 {
    padding-right: 4.2rem !important; }
  .pe-xxxl-44 {
    padding-right: 4.4rem !important; }
  .pe-xxxl-48 {
    padding-right: 4.8rem !important; }
  .pe-xxxl-50 {
    padding-right: 5rem !important; }
  .pe-xxxl-52 {
    padding-right: 5.2rem !important; }
  .pe-xxxl-55 {
    padding-right: 5.5rem !important; }
  .pe-xxxl-60 {
    padding-right: 6rem !important; }
  .pe-xxxl-62 {
    padding-right: 6.2rem !important; }
  .pe-xxxl-64 {
    padding-right: 6.4rem !important; }
  .pe-xxxl-65 {
    padding-right: 6.5rem !important; }
  .pe-xxxl-70 {
    padding-right: 7rem !important; }
  .pe-xxxl-72 {
    padding-right: 7.2rem !important; }
  .pe-xxxl-80 {
    padding-right: 8rem !important; }
  .pe-xxxl-85 {
    padding-right: 8.5rem !important; }
  .pe-xxxl-95 {
    padding-right: 9.5rem !important; }
  .pe-xxxl-96 {
    padding-right: 9.6rem !important; }
  .pe-xxxl-100 {
    padding-right: 10rem !important; }
  .pe-xxxl-104 {
    padding-right: 10.4rem !important; }
  .pe-xxxl-108 {
    padding-right: 10.8rem !important; }
  .pe-xxxl-110 {
    padding-right: 11rem !important; }
  .pe-xxxl-116 {
    padding-right: 11.6rem !important; }
  .pe-xxxl-120 {
    padding-right: 12rem !important; }
  .pe-xxxl-128 {
    padding-right: 12.8rem !important; }
  .pe-xxxl-140 {
    padding-right: 14rem !important; }
  .pe-xxxl-148 {
    padding-right: 14.8rem !important; }
  .pe-xxxl-160 {
    padding-right: 16rem !important; }
  .pe-xxxl-170 {
    padding-right: 17rem !important; }
  .pe-xxxl-200 {
    padding-right: 20rem !important; }
  .pe-xxxl-240 {
    padding-right: 24rem !important; }
  .pe-xxxl-245 {
    padding-right: 24.5rem !important; }
  .pe-xxxl-250 {
    padding-right: 25rem !important; }
  .pe-xxxl-300 {
    padding-right: 30rem !important; }
  .pe-xxxl-400 {
    padding-right: 40rem !important; }
  .pe-xxxl-n25 {
    padding-right: -25% !important; }
  .pb-xxxl-0 {
    padding-bottom: 0 !important; }
  .pb-xxxl-1 {
    padding-bottom: 0.1rem !important; }
  .pb-xxxl-2 {
    padding-bottom: 0.2rem !important; }
  .pb-xxxl-3 {
    padding-bottom: 0.3rem !important; }
  .pb-xxxl-4 {
    padding-bottom: 0.4rem !important; }
  .pb-xxxl-5 {
    padding-bottom: 0.5rem !important; }
  .pb-xxxl-6 {
    padding-bottom: 0.6rem !important; }
  .pb-xxxl-8 {
    padding-bottom: 0.8rem !important; }
  .pb-xxxl-10 {
    padding-bottom: 1rem !important; }
  .pb-xxxl-12 {
    padding-bottom: 1.2rem !important; }
  .pb-xxxl-13 {
    padding-bottom: 1.3rem !important; }
  .pb-xxxl-15 {
    padding-bottom: 1.5rem !important; }
  .pb-xxxl-16 {
    padding-bottom: 1.6rem !important; }
  .pb-xxxl-20 {
    padding-bottom: 2rem !important; }
  .pb-xxxl-24 {
    padding-bottom: 2.4rem !important; }
  .pb-xxxl-25 {
    padding-bottom: 2.5rem !important; }
  .pb-xxxl-29 {
    padding-bottom: 2.9rem !important; }
  .pb-xxxl-30 {
    padding-bottom: 3rem !important; }
  .pb-xxxl-32 {
    padding-bottom: 3.2rem !important; }
  .pb-xxxl-35 {
    padding-bottom: 3.5rem !important; }
  .pb-xxxl-37 {
    padding-bottom: 3.7rem !important; }
  .pb-xxxl-40 {
    padding-bottom: 4rem !important; }
  .pb-xxxl-42 {
    padding-bottom: 4.2rem !important; }
  .pb-xxxl-44 {
    padding-bottom: 4.4rem !important; }
  .pb-xxxl-48 {
    padding-bottom: 4.8rem !important; }
  .pb-xxxl-50 {
    padding-bottom: 5rem !important; }
  .pb-xxxl-52 {
    padding-bottom: 5.2rem !important; }
  .pb-xxxl-55 {
    padding-bottom: 5.5rem !important; }
  .pb-xxxl-60 {
    padding-bottom: 6rem !important; }
  .pb-xxxl-62 {
    padding-bottom: 6.2rem !important; }
  .pb-xxxl-64 {
    padding-bottom: 6.4rem !important; }
  .pb-xxxl-65 {
    padding-bottom: 6.5rem !important; }
  .pb-xxxl-70 {
    padding-bottom: 7rem !important; }
  .pb-xxxl-72 {
    padding-bottom: 7.2rem !important; }
  .pb-xxxl-80 {
    padding-bottom: 8rem !important; }
  .pb-xxxl-85 {
    padding-bottom: 8.5rem !important; }
  .pb-xxxl-95 {
    padding-bottom: 9.5rem !important; }
  .pb-xxxl-96 {
    padding-bottom: 9.6rem !important; }
  .pb-xxxl-100 {
    padding-bottom: 10rem !important; }
  .pb-xxxl-104 {
    padding-bottom: 10.4rem !important; }
  .pb-xxxl-108 {
    padding-bottom: 10.8rem !important; }
  .pb-xxxl-110 {
    padding-bottom: 11rem !important; }
  .pb-xxxl-116 {
    padding-bottom: 11.6rem !important; }
  .pb-xxxl-120 {
    padding-bottom: 12rem !important; }
  .pb-xxxl-128 {
    padding-bottom: 12.8rem !important; }
  .pb-xxxl-140 {
    padding-bottom: 14rem !important; }
  .pb-xxxl-148 {
    padding-bottom: 14.8rem !important; }
  .pb-xxxl-160 {
    padding-bottom: 16rem !important; }
  .pb-xxxl-170 {
    padding-bottom: 17rem !important; }
  .pb-xxxl-200 {
    padding-bottom: 20rem !important; }
  .pb-xxxl-240 {
    padding-bottom: 24rem !important; }
  .pb-xxxl-245 {
    padding-bottom: 24.5rem !important; }
  .pb-xxxl-250 {
    padding-bottom: 25rem !important; }
  .pb-xxxl-300 {
    padding-bottom: 30rem !important; }
  .pb-xxxl-400 {
    padding-bottom: 40rem !important; }
  .pb-xxxl-n25 {
    padding-bottom: -25% !important; }
  .ps-xxxl-0 {
    padding-left: 0 !important; }
  .ps-xxxl-1 {
    padding-left: 0.1rem !important; }
  .ps-xxxl-2 {
    padding-left: 0.2rem !important; }
  .ps-xxxl-3 {
    padding-left: 0.3rem !important; }
  .ps-xxxl-4 {
    padding-left: 0.4rem !important; }
  .ps-xxxl-5 {
    padding-left: 0.5rem !important; }
  .ps-xxxl-6 {
    padding-left: 0.6rem !important; }
  .ps-xxxl-8 {
    padding-left: 0.8rem !important; }
  .ps-xxxl-10 {
    padding-left: 1rem !important; }
  .ps-xxxl-12 {
    padding-left: 1.2rem !important; }
  .ps-xxxl-13 {
    padding-left: 1.3rem !important; }
  .ps-xxxl-15 {
    padding-left: 1.5rem !important; }
  .ps-xxxl-16 {
    padding-left: 1.6rem !important; }
  .ps-xxxl-20 {
    padding-left: 2rem !important; }
  .ps-xxxl-24 {
    padding-left: 2.4rem !important; }
  .ps-xxxl-25 {
    padding-left: 2.5rem !important; }
  .ps-xxxl-29 {
    padding-left: 2.9rem !important; }
  .ps-xxxl-30 {
    padding-left: 3rem !important; }
  .ps-xxxl-32 {
    padding-left: 3.2rem !important; }
  .ps-xxxl-35 {
    padding-left: 3.5rem !important; }
  .ps-xxxl-37 {
    padding-left: 3.7rem !important; }
  .ps-xxxl-40 {
    padding-left: 4rem !important; }
  .ps-xxxl-42 {
    padding-left: 4.2rem !important; }
  .ps-xxxl-44 {
    padding-left: 4.4rem !important; }
  .ps-xxxl-48 {
    padding-left: 4.8rem !important; }
  .ps-xxxl-50 {
    padding-left: 5rem !important; }
  .ps-xxxl-52 {
    padding-left: 5.2rem !important; }
  .ps-xxxl-55 {
    padding-left: 5.5rem !important; }
  .ps-xxxl-60 {
    padding-left: 6rem !important; }
  .ps-xxxl-62 {
    padding-left: 6.2rem !important; }
  .ps-xxxl-64 {
    padding-left: 6.4rem !important; }
  .ps-xxxl-65 {
    padding-left: 6.5rem !important; }
  .ps-xxxl-70 {
    padding-left: 7rem !important; }
  .ps-xxxl-72 {
    padding-left: 7.2rem !important; }
  .ps-xxxl-80 {
    padding-left: 8rem !important; }
  .ps-xxxl-85 {
    padding-left: 8.5rem !important; }
  .ps-xxxl-95 {
    padding-left: 9.5rem !important; }
  .ps-xxxl-96 {
    padding-left: 9.6rem !important; }
  .ps-xxxl-100 {
    padding-left: 10rem !important; }
  .ps-xxxl-104 {
    padding-left: 10.4rem !important; }
  .ps-xxxl-108 {
    padding-left: 10.8rem !important; }
  .ps-xxxl-110 {
    padding-left: 11rem !important; }
  .ps-xxxl-116 {
    padding-left: 11.6rem !important; }
  .ps-xxxl-120 {
    padding-left: 12rem !important; }
  .ps-xxxl-128 {
    padding-left: 12.8rem !important; }
  .ps-xxxl-140 {
    padding-left: 14rem !important; }
  .ps-xxxl-148 {
    padding-left: 14.8rem !important; }
  .ps-xxxl-160 {
    padding-left: 16rem !important; }
  .ps-xxxl-170 {
    padding-left: 17rem !important; }
  .ps-xxxl-200 {
    padding-left: 20rem !important; }
  .ps-xxxl-240 {
    padding-left: 24rem !important; }
  .ps-xxxl-245 {
    padding-left: 24.5rem !important; }
  .ps-xxxl-250 {
    padding-left: 25rem !important; }
  .ps-xxxl-300 {
    padding-left: 30rem !important; }
  .ps-xxxl-400 {
    padding-left: 40rem !important; }
  .ps-xxxl-n25 {
    padding-left: -25% !important; }
  .gap-xxxl-0 {
    gap: 0 !important; }
  .gap-xxxl-1 {
    gap: 0.1rem !important; }
  .gap-xxxl-2 {
    gap: 0.2rem !important; }
  .gap-xxxl-3 {
    gap: 0.3rem !important; }
  .gap-xxxl-4 {
    gap: 0.4rem !important; }
  .gap-xxxl-5 {
    gap: 0.5rem !important; }
  .gap-xxxl-6 {
    gap: 0.6rem !important; }
  .gap-xxxl-8 {
    gap: 0.8rem !important; }
  .gap-xxxl-10 {
    gap: 1rem !important; }
  .gap-xxxl-12 {
    gap: 1.2rem !important; }
  .gap-xxxl-13 {
    gap: 1.3rem !important; }
  .gap-xxxl-15 {
    gap: 1.5rem !important; }
  .gap-xxxl-16 {
    gap: 1.6rem !important; }
  .gap-xxxl-20 {
    gap: 2rem !important; }
  .gap-xxxl-24 {
    gap: 2.4rem !important; }
  .gap-xxxl-25 {
    gap: 2.5rem !important; }
  .gap-xxxl-29 {
    gap: 2.9rem !important; }
  .gap-xxxl-30 {
    gap: 3rem !important; }
  .gap-xxxl-32 {
    gap: 3.2rem !important; }
  .gap-xxxl-35 {
    gap: 3.5rem !important; }
  .gap-xxxl-37 {
    gap: 3.7rem !important; }
  .gap-xxxl-40 {
    gap: 4rem !important; }
  .gap-xxxl-42 {
    gap: 4.2rem !important; }
  .gap-xxxl-44 {
    gap: 4.4rem !important; }
  .gap-xxxl-48 {
    gap: 4.8rem !important; }
  .gap-xxxl-50 {
    gap: 5rem !important; }
  .gap-xxxl-52 {
    gap: 5.2rem !important; }
  .gap-xxxl-55 {
    gap: 5.5rem !important; }
  .gap-xxxl-60 {
    gap: 6rem !important; }
  .gap-xxxl-62 {
    gap: 6.2rem !important; }
  .gap-xxxl-64 {
    gap: 6.4rem !important; }
  .gap-xxxl-65 {
    gap: 6.5rem !important; }
  .gap-xxxl-70 {
    gap: 7rem !important; }
  .gap-xxxl-72 {
    gap: 7.2rem !important; }
  .gap-xxxl-80 {
    gap: 8rem !important; }
  .gap-xxxl-85 {
    gap: 8.5rem !important; }
  .gap-xxxl-95 {
    gap: 9.5rem !important; }
  .gap-xxxl-96 {
    gap: 9.6rem !important; }
  .gap-xxxl-100 {
    gap: 10rem !important; }
  .gap-xxxl-104 {
    gap: 10.4rem !important; }
  .gap-xxxl-108 {
    gap: 10.8rem !important; }
  .gap-xxxl-110 {
    gap: 11rem !important; }
  .gap-xxxl-116 {
    gap: 11.6rem !important; }
  .gap-xxxl-120 {
    gap: 12rem !important; }
  .gap-xxxl-128 {
    gap: 12.8rem !important; }
  .gap-xxxl-140 {
    gap: 14rem !important; }
  .gap-xxxl-148 {
    gap: 14.8rem !important; }
  .gap-xxxl-160 {
    gap: 16rem !important; }
  .gap-xxxl-170 {
    gap: 17rem !important; }
  .gap-xxxl-200 {
    gap: 20rem !important; }
  .gap-xxxl-240 {
    gap: 24rem !important; }
  .gap-xxxl-245 {
    gap: 24.5rem !important; }
  .gap-xxxl-250 {
    gap: 25rem !important; }
  .gap-xxxl-300 {
    gap: 30rem !important; }
  .gap-xxxl-400 {
    gap: 40rem !important; }
  .gap-xxxl-n25 {
    gap: -25% !important; }
  .font-xxxl-family-gantari {
    font-family: "Gantari", sans-serif !important; }
  .font-xxxl-family-roboto {
    font-family: "Roboto", sans-serif !important; }
  .fs-xxxl-12 {
    font-size: 1.2rem !important; }
  .fs-xxxl-13 {
    font-size: 1.3rem !important; }
  .fs-xxxl-14 {
    font-size: 1.4rem !important; }
  .fs-xxxl-15 {
    font-size: 1.5rem !important; }
  .fs-xxxl-16 {
    font-size: 1.6rem !important; }
  .fs-xxxl-18 {
    font-size: 1.8rem !important; }
  .fs-xxxl-24 {
    font-size: 2.4rem !important; }
  .fs-xxxl-28 {
    font-size: 2.8rem !important; }
  .fs-xxxl-30 {
    font-size: 3rem !important; }
  .fs-xxxl-32 {
    font-size: 3.2rem !important; }
  .fs-xxxl-40 {
    font-size: 4rem !important; }
  .fs-xxxl-50 {
    font-size: 5rem !important; }
  .fs-xxxl-60 {
    font-size: 6rem !important; }
  .text-xxxl-start {
    text-align: left !important; }
  .text-xxxl-end {
    text-align: right !important; }
  .text-xxxl-center {
    text-align: center !important; }
  .rounded-xxxl {
    border-radius: 0.8rem !important; }
  .rounded-xxxl-0 {
    border-radius: 0 !important; }
  .rounded-xxxl-1 {
    border-radius: 0.6rem !important; }
  .rounded-xxxl-2 {
    border-radius: 0.8rem !important; }
  .rounded-xxxl-3 {
    border-radius: 5px 20px 5px 20px !important; }
  .rounded-xxxl-4 {
    border-radius: 0.4rem !important; }
  .rounded-xxxl-5 {
    border-radius: 0.5rem !important; }
  .rounded-xxxl-8 {
    border-radius: 0.8rem !important; }
  .rounded-xxxl-9 {
    border-radius: 0.9rem !important; }
  .rounded-xxxl-15 {
    border-radius: 1.5rem !important; }
  .rounded-xxxl-16 {
    border-radius: 1.6rem !important; }
  .rounded-xxxl-64 {
    border-radius: 6.4rem !important; }
  .rounded-xxxl-circle {
    border-radius: 50% !important; }
  .rounded-xxxl-pill {
    border-radius: 50rem !important; }
  .rounded-xxxl-intro {
    border-radius: 50% 50% 0 0 !important; }
  .rounded-top-xxxl {
    border-top-left-radius: 0.8rem !important;
    border-top-right-radius: 0.8rem !important; }
  .rounded-top-xxxl-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important; }
  .rounded-top-xxxl-1 {
    border-top-left-radius: 0.6rem !important;
    border-top-right-radius: 0.6rem !important; }
  .rounded-top-xxxl-2 {
    border-top-left-radius: 0.8rem !important;
    border-top-right-radius: 0.8rem !important; }
  .rounded-top-xxxl-3 {
    border-top-left-radius: 5px 20px 5px 20px !important;
    border-top-right-radius: 5px 20px 5px 20px !important; }
  .rounded-top-xxxl-4 {
    border-top-left-radius: 0.4rem !important;
    border-top-right-radius: 0.4rem !important; }
  .rounded-top-xxxl-5 {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important; }
  .rounded-top-xxxl-8 {
    border-top-left-radius: 0.8rem !important;
    border-top-right-radius: 0.8rem !important; }
  .rounded-top-xxxl-9 {
    border-top-left-radius: 0.9rem !important;
    border-top-right-radius: 0.9rem !important; }
  .rounded-top-xxxl-15 {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important; }
  .rounded-top-xxxl-16 {
    border-top-left-radius: 1.6rem !important;
    border-top-right-radius: 1.6rem !important; }
  .rounded-top-xxxl-64 {
    border-top-left-radius: 6.4rem !important;
    border-top-right-radius: 6.4rem !important; }
  .rounded-top-xxxl-circle {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important; }
  .rounded-top-xxxl-pill {
    border-top-left-radius: 50rem !important;
    border-top-right-radius: 50rem !important; }
  .rounded-top-xxxl-intro {
    border-top-left-radius: 50% 50% 0 0 !important;
    border-top-right-radius: 50% 50% 0 0 !important; } }

@media print {
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-grid {
    display: grid !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; }
  .d-print-none {
    display: none !important; } }

/* ====== obligatoire
*/
/* ========================================================================
   Component: Base
 ========================================================================== */
/*
 * 1. Set `font-size` to support `rem` units
 * 2. Prevent adjustments of font size after orientation changes in iOS.
 * 3. Style
 */
html {
  /* 1 */
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 3 */
  background: #fff;
  color: #666; }

/*
 * Remove the margin in all browsers.
 */
body {
  margin: 0; }

/* Links
 ========================================================================== */
/*
 * Style
 */
a,
.uk-link {
  color: #1e87f0;
  text-decoration: none;
  cursor: pointer; }

a:hover,
.uk-link:hover,
.uk-link-toggle:hover .uk-link {
  color: #0f6ecd;
  text-decoration: none; }

/* Text-level semantics
 ========================================================================== */
/*
 * 1. Add the correct text decoration in Edge.
 * 2. The shorthand declaration `underline dotted` is not supported in Safari.
 */
abbr[title] {
  /* 1 */
  text-decoration: underline dotted;
  /* 2 */
  -webkit-text-decoration-style: dotted; }

/*
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder; }

/*
 * 1. Consolas has a better baseline in running text compared to `Courier`
 * 2. Correct the odd `em` font sizing in all browsers.
 * 3. Style
 */
:not(pre) > code,
:not(pre) > kbd,
:not(pre) > samp {
  /* 1 */
  font-family: Consolas, monaco, monospace;
  /* 2 */
  font-size: 0.875rem;
  /* 3 */
  color: #f0506e;
  white-space: nowrap; }

/*
 * Emphasize
 */
em {
  color: #f0506e; }

/*
 * Insert
 */
ins {
  background: #ffd;
  color: #666;
  text-decoration: none; }

/*
 * Mark
 */
mark, .mark {
  background: #ffd;
  color: #666; }

/*
 * Quote
 */
q {
  font-style: italic; }

/*
 * Add the correct font size in all browsers.
 */
small, .small {
  font-size: 80%; }

/*
 * Prevents `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* Embedded content
 ========================================================================== */
/*
 * Remove the gap between the element and the bottom of its parent container.
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

/*
 * 1. Constrain the element to its parent width.
 * 2. Preserve the intrinsic aspect ratio and auto-scale the height of an image if the `height` attribute is present.
 * 3. Take border and padding into account.
 */
canvas,
img,
svg,
video {
  /* 1 */
  max-width: 100%;
  /* 2 */
  height: auto;
  /* 3 */
  box-sizing: border-box; }

/*
 * Deprecated: only needed for `img` elements with `uk-img`
 * 1. Hide `alt` text for lazy load images.
 * 2. Fix lazy loading images if parent element is set to `display: inline` and has `overflow: hidden`.
 */
img:not([src]) {
  /* 1 */
  visibility: hidden;
  /* 2 */
  min-width: 1px; }

/*
 * Iframe
 * Remove border in all browsers
 */
iframe {
  border: 0; }

/* Block elements
 ========================================================================== */
/*
 * Margins
 */
p,
ul,
ol,
dl,
pre,
address,
fieldset,
figure {
  margin: 0 0 20px 0; }

/* Add margin if adjacent element */
* + p,
* + ul,
* + ol,
* + dl,
* + pre,
* + address,
* + fieldset,
* + figure {
  margin-top: 20px; }

/* Headings
 ========================================================================== */
h1, .h1, .uk-h1, h2, .h2, .uk-h2, h3, .h3, .uk-h3, h4, .h4, .uk-h4, h5, .h5, .uk-h5, h6, .h6, .uk-h6,
.uk-heading-small,
.uk-heading-medium,
.uk-heading-large,
.uk-heading-xlarge,
.uk-heading-2xlarge {
  margin: 0 0 20px 0;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  color: #333;
  text-transform: none; }

/* Add margin if adjacent element */
* + h1, * + .h1, * + .uk-h1,
* + h2,
* + .h2, * + .uk-h2,
* + h3,
* + .h3, * + .uk-h3,
* + h4,
* + .h4, * + .uk-h4,
* + h5,
* + .h5, * + .uk-h5,
* + h6,
* + .h6, * + .uk-h6,
* + .uk-heading-small,
* + .uk-heading-medium,
* + .uk-heading-large,
* + .uk-heading-xlarge,
* + .uk-heading-2xlarge {
  margin-top: 40px; }

/*
 * Sizes
 */
h1, .h1, .uk-h1 {
  font-size: 2.23125rem;
  line-height: 1.2; }

h2, .h2, .uk-h2 {
  font-size: 1.7rem;
  line-height: 1.3; }

h3, .h3, .uk-h3 {
  font-size: 1.5rem;
  line-height: 1.4; }

h4, .h4, .uk-h4 {
  font-size: 1.25rem;
  line-height: 1.4; }

h5, .h5, .uk-h5 {
  font-size: 16px;
  line-height: 1.4; }

h6, .h6, .uk-h6 {
  font-size: 0.875rem;
  line-height: 1.4; }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  h1, .h1, .uk-h1 {
    font-size: 2.625rem; }
  h2, .h2, .uk-h2 {
    font-size: 2rem; } }

/* Lists
 ========================================================================== */
ul,
ol {
  padding-left: 30px; }

/*
 * Reset margin for nested lists
 */
ul > li > ul,
ul > li > ol,
ol > li > ol,
ol > li > ul {
  margin: 0; }

/* Description lists
 ========================================================================== */
dt {
  font-weight: bold; }

dd {
  margin-left: 0; }

/* Horizontal rules
 ========================================================================== */
/*
 * 1. Show the overflow in Chrome, Edge and IE.
 * 2. Add the correct text-align in Edge and IE.
 * 3. Style
 */
hr, .uk-hr {
  /* 1 */
  overflow: visible;
  /* 2 */
  text-align: inherit;
  /* 3 */
  margin: 0 0 20px 0;
  border: 0;
  border-top: 1px solid #e5e5e5; }

/* Add margin if adjacent element */
* + hr,
* + .uk-hr {
  margin-top: 20px; }

/* Address
 ========================================================================== */
address {
  font-style: normal; }

/* Blockquotes
 ========================================================================== */
blockquote {
  margin: 0 0 20px 0;
  font-size: 1.25rem;
  line-height: 1.5;
  font-style: italic; }

/* Add margin if adjacent element */
* + blockquote {
  margin-top: 20px; }

/*
 * Content
 */
blockquote p:last-of-type {
  margin-bottom: 0; }

blockquote footer {
  margin-top: 10px;
  font-size: 0.875rem;
  line-height: 1.5; }

/* Preformatted text
 ========================================================================== */
/*
 * 1. Contain overflow in all browsers.
 */
pre {
  font: 0.875rem / 1.5 Consolas, monaco, monospace;
  color: #666;
  -moz-tab-size: 4;
  tab-size: 4;
  /* 1 */
  overflow: auto; }

pre code {
  font-family: Consolas, monaco, monospace; }

/* Focus
 ========================================================================== */
:focus {
  outline: none; }

:focus-visible {
  outline: 2px dotted #333; }

/* Selection pseudo-element
 ========================================================================== */
::selection {
  background: #39f;
  color: #fff;
  text-shadow: none; }

/* HTML5 elements
 ========================================================================== */
/*
 * 1. Add the correct display in Edge, IE 10+, and Firefox.
 * 2. Add the correct display in IE.
 */
details,
main {
  /* 2 */
  display: block; }

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item; }

/*
 * Add the correct display in IE.
 */
template {
  display: none; }

/* Pass media breakpoints to JS
 ========================================================================== */
/*
 * Breakpoints
 */
:root {
  --uk-breakpoint-s: 640px;
  --uk-breakpoint-m: 960px;
  --uk-breakpoint-l: 1200px;
  --uk-breakpoint-xl: 1600px; }

/* ====== optionnel
*/
/* ========================================================================
   Component: Icon
 ========================================================================== */
/*
 * Note: 1. - 7. is required for `button` elements. Needed for Close and Form Icon component.
 * 1. Remove margins in Chrome, Safari and Opera.
 * 2. Remove borders for `button`.
 * 3. Remove border-radius in Chrome.
 * 4. Address `overflow` set to `hidden` in IE.
 * 5. Correct `font` properties and `color` not being inherited for `button`.
 * 6. Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 7. Remove default `button` padding and background color
 * 8. Style
 * 9. Fill all SVG elements with the current text color if no `fill` attribute is set
 * 10. Let the container fit the height of the icon
 */
.uk-icon {
  /* 1 */
  margin: 0;
  /* 2 */
  border: none;
  /* 3 */
  border-radius: 0;
  /* 4 */
  overflow: visible;
  /* 5 */
  font: inherit;
  color: inherit;
  /* 6 */
  text-transform: none;
  /* 7. */
  padding: 0;
  background-color: transparent;
  /* 8 */
  display: inline-block;
  /* 9 */
  fill: currentcolor;
  /* 10 */
  line-height: 0; }

/* Required for `button`. */
button.uk-icon:not(:disabled) {
  cursor: pointer; }

/*
 * Remove the inner border and padding in Firefox.
 */
.uk-icon::-moz-focus-inner {
  border: 0;
  padding: 0; }

/*
 * Set the fill and stroke color of all SVG elements to the current text color
 */
.uk-icon:not(.uk-preserve) [fill*='#']:not(.uk-preserve) {
  fill: currentcolor; }

.uk-icon:not(.uk-preserve) [stroke*='#']:not(.uk-preserve) {
  stroke: currentcolor; }

/*
 * Fix Firefox blurry SVG rendering: https://bugzilla.mozilla.org/show_bug.cgi?id=1046835
 */
.uk-icon > * {
  transform: translate(0, 0); }

/* Image modifier
 ========================================================================== */
/*
 * Display images in icon dimensions
 * 1. Required for `span` with background image
 * 2. Required for `image`
 */
.uk-icon-image {
  width: 20px;
  height: 20px;
  /* 1 */
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  vertical-align: middle;
  /* 2 */
  object-fit: scale-down;
  max-width: none; }

/* Style modifiers
 ========================================================================== */
/*
 * Link
 * 1. Allow text within link
 */
.uk-icon-link {
  color: #999;
  /* 1 */
  text-decoration: none !important; }

.uk-icon-link:hover {
  color: #666; }

/* OnClick + Active */
.uk-icon-link:active,
.uk-active > .uk-icon-link {
  color: #595959; }

/*
 * Button
 * 1. Center icon vertically and horizontally
 */
.uk-icon-button {
  box-sizing: border-box;
  width: 36px;
  height: 36px;
  border-radius: 500px;
  background: #f8f8f8;
  color: #999;
  vertical-align: middle;
  /* 1 */
  display: inline-flex;
  justify-content: center;
  align-items: center; }

/* Hover */
.uk-icon-button:hover {
  background-color: #ebebeb;
  color: #666; }

/* OnClick + Active */
.uk-icon-button:active,
.uk-active > .uk-icon-button {
  background-color: #dfdfdf;
  color: #666; }

/* ========================================================================
   Component: List
 ========================================================================== */
.uk-list {
  padding: 0;
  list-style: none; }

/*
 * Avoid column break within the list item, when using `column-count`
 */
.uk-list > * {
  break-inside: avoid-column; }

/*
 * Remove margin from the last-child
 */
.uk-list > * > :last-child {
  margin-bottom: 0; }

/*
 * Style
 */
.uk-list > :nth-child(n+2),
.uk-list > * > ul {
  margin-top: 10px; }

/* Marker modifiers
 * Moving `::marker` inside `::before` to style it differently
 * To style the `::marker` is currently only supported in Firefox and Safari
 ========================================================================== */
.uk-list-disc > *,
.uk-list-circle > *,
.uk-list-square > *,
.uk-list-decimal > *,
.uk-list-hyphen > * {
  padding-left: 30px; }

/*
 * Type modifiers
 */
.uk-list-decimal {
  counter-reset: decimal; }

.uk-list-decimal > * {
  counter-increment: decimal; }

.uk-list-disc > ::before,
.uk-list-circle > ::before,
.uk-list-square > ::before,
.uk-list-decimal > ::before,
.uk-list-hyphen > ::before {
  content: "";
  position: relative;
  left: -30px;
  width: 30px;
  height: 1.5em;
  margin-bottom: -1.5em;
  display: list-item;
  list-style-position: inside;
  text-align: right; }

.uk-list-disc > ::before {
  list-style-type: disc; }

.uk-list-circle > ::before {
  list-style-type: circle; }

.uk-list-square > ::before {
  list-style-type: square; }

.uk-list-decimal > ::before {
  content: counter(decimal, decimal) " . "; }

.uk-list-hyphen > ::before {
  content: '–\00A0\00A0'; }

/*
 * Color modifiers
 */
.uk-list-muted > ::before {
  color: #999 !important; }

.uk-list-emphasis > ::before {
  color: #333 !important; }

.uk-list-primary > ::before {
  color: #1e87f0 !important; }

.uk-list-secondary > ::before {
  color: #222 !important; }

/* Image bullet modifier
 ========================================================================== */
.uk-list-bullet > * {
  padding-left: 30px; }

.uk-list-bullet > ::before {
  content: "";
  display: list-item;
  position: relative;
  left: -30px;
  width: 30px;
  height: 1.5em;
  margin-bottom: -1.5em;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%226%22%20height%3D%226%22%20viewBox%3D%220%200%206%206%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23666%22%20cx%3D%223%22%20cy%3D%223%22%20r%3D%223%22%20%2F%3E%0A%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: 50% 50%; }

/* Style modifiers
 ========================================================================== */
/*
 * Divider
 */
.uk-list-divider > :nth-child(n+2) {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #e5e5e5; }

/*
 * Striped
 */
.uk-list-striped > * {
  padding: 10px 10px; }

.uk-list-striped > :nth-of-type(odd) {
  background: #f8f8f8; }

.uk-list-striped > :nth-child(n+2) {
  margin-top: 0; }

/* Size modifier
 ========================================================================== */
.uk-list-large > :nth-child(n+2),
.uk-list-large > * > ul {
  margin-top: 20px; }

.uk-list-collapse > :nth-child(n+2),
.uk-list-collapse > * > ul {
  margin-top: 0; }

/*
 * Divider
 */
.uk-list-large.uk-list-divider > :nth-child(n+2) {
  margin-top: 20px;
  padding-top: 20px; }

.uk-list-collapse.uk-list-divider > :nth-child(n+2) {
  margin-top: 0;
  padding-top: 0; }

/*
 * Striped
 */
.uk-list-large.uk-list-striped > * {
  padding: 20px 10px; }

.uk-list-collapse.uk-list-striped > * {
  padding-top: 0;
  padding-bottom: 0; }

.uk-list-large.uk-list-striped > :nth-child(n+2),
.uk-list-collapse.uk-list-striped > :nth-child(n+2) {
  margin-top: 0; }

/* ========================================================================
   Component: Button
 ========================================================================== */
/*
 * 1. Remove margins in Chrome, Safari and Opera.
 * 2. Remove borders for `button`.
 * 3. Address `overflow` set to `hidden` in IE.
 * 4. Correct `font` properties and `color` not being inherited for `button`.
 * 5. Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 6. Remove default style for `input type="submit"`in iOS.
 * 7. Style
 * 8. `line-height` is used to create a height because it also centers the text vertically for `a` elements.
 *    Better would be to use height and flexbox to center the text vertically but flexbox doesn't work in Firefox on `button` elements.
 * 9. Align text if button has a width
 * 10. Required for `a`.
 */
.uk-button {
  /* 1 */
  margin: 0;
  /* 2 */
  border: none;
  /* 3 */
  overflow: visible;
  /* 4 */
  font: inherit;
  color: inherit;
  /* 5 */
  text-transform: none;
  /* 6 */
  -webkit-appearance: none;
  border-radius: 0;
  /* 7 */
  display: inline-block;
  box-sizing: border-box;
  padding: 0 30px;
  vertical-align: middle;
  font-size: 16px;
  /* 8 */
  line-height: 40px;
  /* 9 */
  text-align: center;
  /* 10 */
  text-decoration: none; }

.uk-button:not(:disabled) {
  cursor: pointer; }

/*
 * Remove the inner border and padding in Firefox.
 */
.uk-button::-moz-focus-inner {
  border: 0;
  padding: 0; }

/* Hover */
.uk-button:hover {
  /* 9 */
  text-decoration: none; }

/* OnClick + Active */
/* Style modifiers
 ========================================================================== */
/*
 * Default
 */
.uk-button-default {
  background-color: #f8f8f8;
  color: #333; }

/* Hover */
.uk-button-default:hover {
  background-color: #ebebeb;
  color: #333; }

/* OnClick + Active */
.uk-button-default:active,
.uk-button-default.uk-active {
  background-color: #dfdfdf;
  color: #333; }

/*
 * Primary
 */
.uk-button-primary {
  background-color: #1e87f0;
  color: #fff; }

/* Hover */
.uk-button-primary:hover {
  background-color: #0f7ae5;
  color: #fff; }

/* OnClick + Active */
.uk-button-primary:active,
.uk-button-primary.uk-active {
  background-color: #0e6ecd;
  color: #fff; }

/*
 * Secondary
 */
.uk-button-secondary {
  background-color: #222;
  color: #fff; }

/* Hover */
.uk-button-secondary:hover {
  background-color: #151515;
  color: #fff; }

/* OnClick + Active */
.uk-button-secondary:active,
.uk-button-secondary.uk-active {
  background-color: #090909;
  color: #fff; }

/*
 * Danger
 */
.uk-button-danger {
  background-color: #f0506e;
  color: #fff; }

/* Hover */
.uk-button-danger:hover {
  background-color: #ee395b;
  color: #fff; }

/* OnClick + Active */
.uk-button-danger:active,
.uk-button-danger.uk-active {
  background-color: #ec2147;
  color: #fff; }

/*
 * Disabled
 * The same for all style modifiers
 */
.uk-button-default:disabled,
.uk-button-primary:disabled,
.uk-button-secondary:disabled,
.uk-button-danger:disabled {
  background-color: #f8f8f8;
  color: #999; }

/* Size modifiers
 ========================================================================== */
.uk-button-small {
  padding: 0 15px;
  line-height: 30px;
  font-size: 0.875rem; }

.uk-button-large {
  padding: 0 40px;
  line-height: 55px;
  font-size: 1.25rem; }

/* Text modifiers
 ========================================================================== */
/*
 * Text
 * 1. Reset
 * 2. Style
 */
.uk-button-text {
  /* 1 */
  padding: 0;
  line-height: 1.5;
  background: none;
  /* 2 */
  color: #333; }

/* Hover */
.uk-button-text:hover {
  color: #999; }

/* Disabled */
.uk-button-text:disabled {
  color: #999; }

/*
 * Link
 * 1. Reset
 * 2. Style
 */
.uk-button-link {
  /* 1 */
  padding: 0;
  line-height: 1.5;
  background: none;
  /* 2 */
  color: #333; }

/* Hover */
.uk-button-link:hover {
  color: #999;
  text-decoration: none; }

/* Disabled */
.uk-button-link:disabled {
  color: #999;
  text-decoration: none; }

/* Group
 ========================================================================== */
/*
 * 1. Using `flex` instead of `inline-block` to prevent whitespace betweent child elements
 * 2. Behave like button
 * 3. Create position context
 */
.uk-button-group {
  /* 1 */
  display: inline-flex;
  /* 2 */
  vertical-align: middle;
  /* 3 */
  position: relative; }

/* ========================================================================
   Component: Grid
 ========================================================================== */
/*
 * 1. Allow cells to wrap into the next line
 * 2. Reset list
 */
.uk-grid {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  margin: 0;
  padding: 0;
  list-style: none; }

/*
 * Grid cell
 * Note: Space is allocated solely based on content dimensions, but shrinks: 0 1 auto
 * Reset margin for e.g. paragraphs
 */
.uk-grid > * {
  margin: 0; }

/*
 * Remove margin from the last-child
 */
.uk-grid > * > :last-child {
  margin-bottom: 0; }

/* Gutter
 ========================================================================== */
/*
 * Default
 */
/* Horizontal */
.uk-grid {
  margin-left: -30px; }

.uk-grid > * {
  padding-left: 30px; }

/* Vertical */
.uk-grid + .uk-grid,
.uk-grid > .uk-grid-margin,
* + .uk-grid-margin {
  margin-top: 30px; }

/* Desktop and bigger */
@media (min-width: 1200px) {
  /* Horizontal */
  .uk-grid {
    margin-left: -40px; }
  .uk-grid > * {
    padding-left: 40px; }
  /* Vertical */
  .uk-grid + .uk-grid,
  .uk-grid > .uk-grid-margin,
  * + .uk-grid-margin {
    margin-top: 40px; } }

/*
 * Small
 */
/* Horizontal */
.uk-grid-small,
.uk-grid-column-small {
  margin-left: -15px; }

.uk-grid-small > *,
.uk-grid-column-small > * {
  padding-left: 15px; }

/* Vertical */
.uk-grid + .uk-grid-small,
.uk-grid + .uk-grid-row-small,
.uk-grid-small > .uk-grid-margin,
.uk-grid-row-small > .uk-grid-margin,
* + .uk-grid-margin-small {
  margin-top: 15px; }

/*
 * Medium
 */
/* Horizontal */
.uk-grid-medium,
.uk-grid-column-medium {
  margin-left: -30px; }

.uk-grid-medium > *,
.uk-grid-column-medium > * {
  padding-left: 30px; }

/* Vertical */
.uk-grid + .uk-grid-medium,
.uk-grid + .uk-grid-row-medium,
.uk-grid-medium > .uk-grid-margin,
.uk-grid-row-medium > .uk-grid-margin,
* + .uk-grid-margin-medium {
  margin-top: 30px; }

/*
 * Large
 */
/* Horizontal */
.uk-grid-large,
.uk-grid-column-large {
  margin-left: -40px; }

.uk-grid-large > *,
.uk-grid-column-large > * {
  padding-left: 40px; }

/* Vertical */
.uk-grid + .uk-grid-large,
.uk-grid + .uk-grid-row-large,
.uk-grid-large > .uk-grid-margin,
.uk-grid-row-large > .uk-grid-margin,
* + .uk-grid-margin-large {
  margin-top: 40px; }

/* Desktop and bigger */
@media (min-width: 1200px) {
  /* Horizontal */
  .uk-grid-large,
  .uk-grid-column-large {
    margin-left: -70px; }
  .uk-grid-large > *,
  .uk-grid-column-large > * {
    padding-left: 70px; }
  /* Vertical */
  .uk-grid + .uk-grid-large,
  .uk-grid + .uk-grid-row-large,
  .uk-grid-large > .uk-grid-margin,
  .uk-grid-row-large > .uk-grid-margin,
  * + .uk-grid-margin-large {
    margin-top: 70px; } }

/*
 * Collapse
 */
/* Horizontal */
.uk-grid-collapse,
.uk-grid-column-collapse {
  margin-left: 0; }

.uk-grid-collapse > *,
.uk-grid-column-collapse > * {
  padding-left: 0; }

/* Vertical */
.uk-grid + .uk-grid-collapse,
.uk-grid + .uk-grid-row-collapse,
.uk-grid-collapse > .uk-grid-margin,
.uk-grid-row-collapse > .uk-grid-margin {
  margin-top: 0; }

/* Divider
 ========================================================================== */
.uk-grid-divider > * {
  position: relative; }

.uk-grid-divider > :not(.uk-first-column)::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  border-left: 1px solid #e5e5e5; }

/* Vertical */
.uk-grid-divider.uk-grid-stack > .uk-grid-margin::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  border-top: 1px solid #e5e5e5; }

/*
 * Default
 */
/* Horizontal */
.uk-grid-divider {
  margin-left: -60px; }

.uk-grid-divider > * {
  padding-left: 60px; }

.uk-grid-divider > :not(.uk-first-column)::before {
  left: 30px; }

/* Vertical */
.uk-grid-divider.uk-grid-stack > .uk-grid-margin {
  margin-top: 60px; }

.uk-grid-divider.uk-grid-stack > .uk-grid-margin::before {
  top: -30px;
  left: 60px; }

/* Desktop and bigger */
@media (min-width: 1200px) {
  /* Horizontal */
  .uk-grid-divider {
    margin-left: -80px; }
  .uk-grid-divider > * {
    padding-left: 80px; }
  .uk-grid-divider > :not(.uk-first-column)::before {
    left: 40px; }
  /* Vertical */
  .uk-grid-divider.uk-grid-stack > .uk-grid-margin {
    margin-top: 80px; }
  .uk-grid-divider.uk-grid-stack > .uk-grid-margin::before {
    top: -40px;
    left: 80px; } }

/*
 * Small
 */
/* Horizontal */
.uk-grid-divider.uk-grid-small,
.uk-grid-divider.uk-grid-column-small {
  margin-left: -30px; }

.uk-grid-divider.uk-grid-small > *,
.uk-grid-divider.uk-grid-column-small > * {
  padding-left: 30px; }

.uk-grid-divider.uk-grid-small > :not(.uk-first-column)::before,
.uk-grid-divider.uk-grid-column-small > :not(.uk-first-column)::before {
  left: 15px; }

/* Vertical */
.uk-grid-divider.uk-grid-small.uk-grid-stack > .uk-grid-margin,
.uk-grid-divider.uk-grid-row-small.uk-grid-stack > .uk-grid-margin {
  margin-top: 30px; }

.uk-grid-divider.uk-grid-small.uk-grid-stack > .uk-grid-margin::before {
  top: -15px;
  left: 30px; }

.uk-grid-divider.uk-grid-row-small.uk-grid-stack > .uk-grid-margin::before {
  top: -15px; }

.uk-grid-divider.uk-grid-column-small.uk-grid-stack > .uk-grid-margin::before {
  left: 30px; }

/*
 * Medium
 */
/* Horizontal */
.uk-grid-divider.uk-grid-medium,
.uk-grid-divider.uk-grid-column-medium {
  margin-left: -60px; }

.uk-grid-divider.uk-grid-medium > *,
.uk-grid-divider.uk-grid-column-medium > * {
  padding-left: 60px; }

.uk-grid-divider.uk-grid-medium > :not(.uk-first-column)::before,
.uk-grid-divider.uk-grid-column-medium > :not(.uk-first-column)::before {
  left: 30px; }

/* Vertical */
.uk-grid-divider.uk-grid-medium.uk-grid-stack > .uk-grid-margin,
.uk-grid-divider.uk-grid-row-medium.uk-grid-stack > .uk-grid-margin {
  margin-top: 60px; }

.uk-grid-divider.uk-grid-medium.uk-grid-stack > .uk-grid-margin::before {
  top: -30px;
  left: 60px; }

.uk-grid-divider.uk-grid-row-medium.uk-grid-stack > .uk-grid-margin::before {
  top: -30px; }

.uk-grid-divider.uk-grid-column-medium.uk-grid-stack > .uk-grid-margin::before {
  left: 60px; }

/*
 * Large
 */
/* Horizontal */
.uk-grid-divider.uk-grid-large,
.uk-grid-divider.uk-grid-column-large {
  margin-left: -80px; }

.uk-grid-divider.uk-grid-large > *,
.uk-grid-divider.uk-grid-column-large > * {
  padding-left: 80px; }

.uk-grid-divider.uk-grid-large > :not(.uk-first-column)::before,
.uk-grid-divider.uk-grid-column-large > :not(.uk-first-column)::before {
  left: 40px; }

/* Vertical */
.uk-grid-divider.uk-grid-large.uk-grid-stack > .uk-grid-margin,
.uk-grid-divider.uk-grid-row-large.uk-grid-stack > .uk-grid-margin {
  margin-top: 80px; }

.uk-grid-divider.uk-grid-large.uk-grid-stack > .uk-grid-margin::before {
  top: -40px;
  left: 80px; }

.uk-grid-divider.uk-grid-row-large.uk-grid-stack > .uk-grid-margin::before {
  top: -40px; }

.uk-grid-divider.uk-grid-column-large.uk-grid-stack > .uk-grid-margin::before {
  left: 80px; }

/* Desktop and bigger */
@media (min-width: 1200px) {
  /* Horizontal */
  .uk-grid-divider.uk-grid-large,
  .uk-grid-divider.uk-grid-column-large {
    margin-left: -140px; }
  .uk-grid-divider.uk-grid-large > *,
  .uk-grid-divider.uk-grid-column-large > * {
    padding-left: 140px; }
  .uk-grid-divider.uk-grid-large > :not(.uk-first-column)::before,
  .uk-grid-divider.uk-grid-column-large > :not(.uk-first-column)::before {
    left: 70px; }
  /* Vertical */
  .uk-grid-divider.uk-grid-large.uk-grid-stack > .uk-grid-margin,
  .uk-grid-divider.uk-grid-row-large.uk-grid-stack > .uk-grid-margin {
    margin-top: 140px; }
  .uk-grid-divider.uk-grid-large.uk-grid-stack > .uk-grid-margin::before {
    top: -70px;
    left: 140px; }
  .uk-grid-divider.uk-grid-row-large.uk-grid-stack > .uk-grid-margin::before {
    top: -70px; }
  .uk-grid-divider.uk-grid-column-large.uk-grid-stack > .uk-grid-margin::before {
    left: 140px; } }

/* Match child of a grid cell
 ========================================================================== */
/*
 * Behave like a block element
 * 1. Wrap into the next line
 * 2. Take the full width, at least 100%. Only if no class from the Width component is set.
 * 3. Expand width even if larger than 100%, e.g. because of negative margin (Needed for nested grids)
 */
.uk-grid-match > *,
.uk-grid-item-match {
  display: flex;
  /* 1 */
  flex-wrap: wrap; }

.uk-grid-match > * > :not([class*='uk-width']),
.uk-grid-item-match > :not([class*='uk-width']) {
  /* 2 */
  box-sizing: border-box;
  width: 100%;
  /* 3 */
  flex: auto; }

/* ========================================================================
   Component: Container
 ========================================================================== */
/*
 * 1. Box sizing has to be `content-box` so the max-width is always the same and
 *    unaffected by the padding on different breakpoints. It's important for the size modifiers.
 */
.uk-container {
  display: flow-root;
  /* 1 */
  box-sizing: content-box;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-container {
    padding-left: 30px;
    padding-right: 30px; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-container {
    padding-left: 40px;
    padding-right: 40px; } }

/*
 * Remove margin from the last-child
 */
.uk-container > :last-child {
  margin-bottom: 0; }

/*
 * Remove padding from nested containers
 */
.uk-container .uk-container {
  padding-left: 0;
  padding-right: 0; }

/* Size modifier
 ========================================================================== */
.uk-container-xsmall {
  max-width: 750px; }

.uk-container-small {
  max-width: 900px; }

.uk-container-large {
  max-width: 1400px; }

.uk-container-xlarge {
  max-width: 1600px; }

.uk-container-expand {
  max-width: none; }

/* Expand modifier
 ========================================================================== */
/*
 * Expand one side only
 */
.uk-container-expand-left {
  margin-left: 0; }

.uk-container-expand-right {
  margin-right: 0; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-container-expand-left.uk-container-xsmall,
  .uk-container-expand-right.uk-container-xsmall {
    max-width: calc(50% + (750px / 2) - 30px); }
  .uk-container-expand-left.uk-container-small,
  .uk-container-expand-right.uk-container-small {
    max-width: calc(50% + (900px / 2) - 30px); } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-container-expand-left,
  .uk-container-expand-right {
    max-width: calc(50% + (1200px / 2) - 40px); }
  .uk-container-expand-left.uk-container-xsmall,
  .uk-container-expand-right.uk-container-xsmall {
    max-width: calc(50% + (750px / 2) - 40px); }
  .uk-container-expand-left.uk-container-small,
  .uk-container-expand-right.uk-container-small {
    max-width: calc(50% + (900px / 2) - 40px); }
  .uk-container-expand-left.uk-container-large,
  .uk-container-expand-right.uk-container-large {
    max-width: calc(50% + (1400px / 2) - 40px); }
  .uk-container-expand-left.uk-container-xlarge,
  .uk-container-expand-right.uk-container-xlarge {
    max-width: calc(50% + (1600px / 2) - 40px); } }

/* Item
 ========================================================================== */
/*
 * Utility classes to reset container padding on the left or right side
 * Note: It has to be negative margin on the item, because it's specific to the item.
 */
.uk-container-item-padding-remove-left,
.uk-container-item-padding-remove-right {
  width: calc(100% + 15px); }

.uk-container-item-padding-remove-left {
  margin-left: -15px; }

.uk-container-item-padding-remove-right {
  margin-right: -15px; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-container-item-padding-remove-left,
  .uk-container-item-padding-remove-right {
    width: calc(100% + 30px); }
  .uk-container-item-padding-remove-left {
    margin-left: -30px; }
  .uk-container-item-padding-remove-right {
    margin-right: -30px; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-container-item-padding-remove-left,
  .uk-container-item-padding-remove-right {
    width: calc(100% + 40px); }
  .uk-container-item-padding-remove-left {
    margin-left: -40px; }
  .uk-container-item-padding-remove-right {
    margin-right: -40px; } }

/* ========================================================================
   Component: Card
 ========================================================================== */
.uk-card {
  position: relative;
  box-sizing: border-box; }

/* Sections
 ========================================================================== */
.uk-card-body {
  display: flow-root;
  padding: 30px 30px; }

.uk-card-header {
  display: flow-root;
  padding: 15px 30px; }

.uk-card-footer {
  display: flow-root;
  padding: 15px 30px; }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-card-body {
    padding: 40px 40px; }
  .uk-card-header {
    padding: 20px 40px; }
  .uk-card-footer {
    padding: 20px 40px; } }

/*
 * Remove margin from the last-child
 */
.uk-card-body > :last-child,
.uk-card-header > :last-child,
.uk-card-footer > :last-child {
  margin-bottom: 0; }

/* Media
 ========================================================================== */
/*
 * Reserved alignment modifier to style the media element, e.g. with `border-radius`
 * Implemented by the theme
 */
/* Title
 ========================================================================== */
.uk-card-title {
  font-size: 1.5rem;
  line-height: 1.4; }

/* Badge
 ========================================================================== */
/*
 * 1. Position
 * 2. Size
 * 3. Style
 * 4. Center child vertically
 */
.uk-card-badge {
  /* 1 */
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 1;
  /* 2 */
  height: 22px;
  padding: 0 10px;
  /* 3 */
  background: #1e87f0;
  color: #fff;
  font-size: 0.875rem;
  /* 4 */
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0; }

/*
 * Remove margin from adjacent element
 */
.uk-card-badge:first-child + * {
  margin-top: 0; }

/* Hover modifier
 ========================================================================== */
.uk-card-hover:not(.uk-card-default):not(.uk-card-primary):not(.uk-card-secondary):hover {
  background-color: #f8f8f8; }

/* Style modifiers
 ========================================================================== */
/*
 * Default
 * Note: Header and Footer are only implemented for the default style
 */
.uk-card-default {
  background-color: #f8f8f8;
  color: #666; }

.uk-card-default .uk-card-title {
  color: #333; }

.uk-card-default.uk-card-hover:hover {
  background-color: #ebebeb; }

/*
 * Primary
 */
.uk-card-primary {
  background-color: #1e87f0;
  color: #fff; }

.uk-card-primary .uk-card-title {
  color: #fff; }

.uk-card-primary.uk-card-hover:hover {
  background-color: #0f7ae5; }

/*
 * Secondary
 */
.uk-card-secondary {
  background-color: #222;
  color: #fff; }

.uk-card-secondary .uk-card-title {
  color: #fff; }

.uk-card-secondary.uk-card-hover:hover {
  background-color: #151515; }

/* Size modifier
 ========================================================================== */
/*
 * Small
 */
.uk-card-small.uk-card-body,
.uk-card-small .uk-card-body {
  padding: 20px 20px; }

.uk-card-small .uk-card-header {
  padding: 13px 20px; }

.uk-card-small .uk-card-footer {
  padding: 13px 20px; }

/*
 * Large
 */
/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-card-large.uk-card-body,
  .uk-card-large .uk-card-body {
    padding: 70px 70px; }
  .uk-card-large .uk-card-header {
    padding: 35px 70px; }
  .uk-card-large .uk-card-footer {
    padding: 35px 70px; } }

/* ========================================================================
   Component: Close
 ========================================================================== */
/*
 * Adopts `uk-icon`
 */
.uk-close {
  color: #999; }

/* Hover */
.uk-close:hover {
  color: #666; }

/* ========================================================================
   Component: Totop
 ========================================================================== */
/*
 * Addopts `uk-icon`
 */
.uk-totop {
  padding: 5px;
  color: #999; }

/* Hover */
.uk-totop:hover {
  color: #666; }

/* OnClick */
.uk-totop:active {
  color: #333; }

/* ========================================================================
   Component: Alert
 ========================================================================== */
.uk-alert {
  position: relative;
  margin-bottom: 20px;
  padding: 15px 29px 15px 15px;
  background: #f8f8f8;
  color: #666; }

/* Add margin if adjacent element */
* + .uk-alert {
  margin-top: 20px; }

/*
 * Remove margin from the last-child
 */
.uk-alert > :last-child {
  margin-bottom: 0; }

/* Close
 * Adopts `uk-close`
 ========================================================================== */
.uk-alert-close {
  position: absolute;
  top: 20px;
  right: 15px; }

/*
 * Remove margin from adjacent element
 */
.uk-alert-close:first-child + * {
  margin-top: 0; }

/*
 * Hover
 */
/* Style modifiers
 ========================================================================== */
/*
 * Primary
 */
.uk-alert-primary {
  background: #d8eafc;
  color: #1e87f0; }

/*
 * Success
 */
.uk-alert-success {
  background: #edfbf6;
  color: #32d296; }

/*
 * Warning
 */
.uk-alert-warning {
  background: #fef5ee;
  color: #faa05a; }

/*
 * Danger
 */
.uk-alert-danger {
  background: #fef4f6;
  color: #f0506e; }

/* ========================================================================
   Component: Badge
 ========================================================================== */
/*
 * 1. Style
 * 2. Center child vertically and horizontally
 */
.uk-badge {
  box-sizing: border-box;
  min-width: 18px;
  height: 18px;
  padding: 0 5px;
  border-radius: 0.8rem;
  vertical-align: middle;
  /* 1 */
  background: #1e87f0;
  color: #fff !important;
  font-size: 0.75em;
  /* 2 */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 0; }

/*
 * Required for `a`
 */
.uk-badge:hover {
  text-decoration: none; }

/* ========================================================================
   Component: Overlay
 ========================================================================== */
.uk-overlay {
  padding: 30px 30px; }

/*
 * Remove margin from the last-child
 */
.uk-overlay > :last-child {
  margin-bottom: 0; }

/* Icon
 ========================================================================== */
/* Style modifiers
 ========================================================================== */
/*
 * Default
 */
.uk-overlay-default {
  background: rgba(255, 255, 255, 0.8); }

/*
 * Primary
 */
.uk-overlay-primary {
  background: rgba(34, 34, 34, 0.8); }

/* ========================================================================
   Component: Nav
 ========================================================================== */
/*
 * Reset
 */
.uk-nav,
.uk-nav ul {
  margin: 0;
  padding: 0;
  list-style: none; }

/*
* 1. Center content vertically, e.g. an icon
* 2. Imitate white space gap when using flexbox
* 3. Reset link
 */
.uk-nav li > a {
  /* 1 */
  display: flex;
  align-items: center;
  /* 2 */
  column-gap: 0.25em;
  /* 3*/
  text-decoration: none; }

/*
 * Items
 * Must target `a` elements to exclude other elements (e.g. lists)
 */
.uk-nav > li > a {
  padding: 5px 0; }

/* Sublists
 ========================================================================== */
/*
 * Level 2
 * `ul` needed for higher specificity to override padding
 */
ul.uk-nav-sub {
  padding: 5px 0 5px 15px; }

/*
 * Level 3 and deeper
 */
.uk-nav-sub ul {
  padding-left: 15px; }

/*
 * Items
 */
.uk-nav-sub a {
  padding: 2px 0; }

/* Parent icon
 ========================================================================== */
.uk-nav-parent-icon {
  margin-left: auto; }

.uk-nav > li > a[aria-expanded="true"] .uk-nav-parent-icon {
  transform: rotate(180deg); }

/* Header
 ========================================================================== */
.uk-nav-header {
  padding: 5px 0;
  text-transform: uppercase;
  font-size: 0.875rem; }

.uk-nav-header:not(:first-child) {
  margin-top: 20px; }

/* Divider
 ========================================================================== */
.uk-nav .uk-nav-divider {
  margin: 5px 0; }

/* Default modifier
 ========================================================================== */
.uk-nav-default {
  font-size: 16px;
  line-height: 1.5; }

/*
 * Items
 */
.uk-nav-default > li > a {
  color: #999; }

/* Hover */
.uk-nav-default > li > a:hover {
  color: #666; }

/* Active */
.uk-nav-default > li.uk-active > a {
  color: #333; }

/*
 * Subtitle
 */
.uk-nav-default .uk-nav-subtitle {
  font-size: 0.875rem; }

/*
 * Header
 */
.uk-nav-default .uk-nav-header {
  color: #333; }

/*
 * Divider
 */
.uk-nav-default .uk-nav-divider {
  border-top: 1px solid #e5e5e5; }

/*
 * Sublists
 */
.uk-nav-default .uk-nav-sub {
  font-size: 16px;
  line-height: 1.5; }

.uk-nav-default .uk-nav-sub a {
  color: #999; }

.uk-nav-default .uk-nav-sub a:hover {
  color: #666; }

.uk-nav-default .uk-nav-sub li.uk-active > a {
  color: #333; }

/* Primary modifier
 ========================================================================== */
.uk-nav-primary {
  font-size: 1.5rem;
  line-height: 1.5; }

/*
 * Items
 */
.uk-nav-primary > li > a {
  color: #999; }

/* Hover */
.uk-nav-primary > li > a:hover {
  color: #666; }

/* Active */
.uk-nav-primary > li.uk-active > a {
  color: #333; }

/*
 * Subtitle
 */
.uk-nav-primary .uk-nav-subtitle {
  font-size: 1.25rem; }

/*
 * Header
 */
.uk-nav-primary .uk-nav-header {
  color: #333; }

/*
 * Divider
 */
.uk-nav-primary .uk-nav-divider {
  border-top: 1px solid #e5e5e5; }

/*
 * Sublists
 */
.uk-nav-primary .uk-nav-sub {
  font-size: 1.25rem;
  line-height: 1.5; }

.uk-nav-primary .uk-nav-sub a {
  color: #999; }

.uk-nav-primary .uk-nav-sub a:hover {
  color: #666; }

.uk-nav-primary .uk-nav-sub li.uk-active > a {
  color: #333; }

/* Secondary modifier
 ========================================================================== */
.uk-nav-secondary {
  font-size: 16px;
  line-height: 1.5; }

/*
 * Items
 */
.uk-nav-secondary > li > a {
  color: #333; }

/* Hover */
.uk-nav-secondary > li > a:hover {
  color: #333; }

/* Active */
.uk-nav-secondary > li.uk-active > a {
  color: #333; }

/*
 * Subtitle
 */
.uk-nav-secondary .uk-nav-subtitle {
  font-size: 0.875rem;
  color: #999; }

/* Hover */
.uk-nav-secondary > li > a:hover .uk-nav-subtitle {
  color: #666; }

/* Active */
.uk-nav-secondary > li.uk-active > a .uk-nav-subtitle {
  color: #333; }

/*
 * Header
 */
.uk-nav-secondary .uk-nav-header {
  color: #333; }

/*
 * Divider
 */
.uk-nav-secondary .uk-nav-divider {
  border-top: 1px solid #e5e5e5; }

/*
 * Sublists
 */
.uk-nav-secondary .uk-nav-sub {
  font-size: 0.875rem;
  line-height: 1.5; }

.uk-nav-secondary .uk-nav-sub a {
  color: #999; }

.uk-nav-secondary .uk-nav-sub a:hover {
  color: #666; }

.uk-nav-secondary .uk-nav-sub li.uk-active > a {
  color: #333; }

/* Alignment modifier
 ========================================================================== */
/*
 * 1. Center header
 * 2. Center items
 */
/* 1 */
.uk-nav-center {
  text-align: center; }

/* 2 */
.uk-nav-center li > a {
  justify-content: center; }

/* Sublists */
.uk-nav-center .uk-nav-sub,
.uk-nav-center .uk-nav-sub ul {
  padding-left: 0; }

/* Parent icon  */
.uk-nav-center .uk-nav-parent-icon {
  margin-left: 4px; }

/* Style modifier
 ========================================================================== */
/*
 * Divider
 * Naming is in plural to prevent conflicts with divider sub object.
 */
.uk-nav.uk-nav-divider > :not(.uk-nav-header):not(.uk-nav-divider) + :not(.uk-nav-header):not(.uk-nav-divider) {
  margin-top: 5px;
  padding-top: 5px;
  border-top: 1px solid #e5e5e5; }

/* ========================================================================
   Component: Navbar
 ========================================================================== */
/*
 * 1. Create position context to center navbar group
 */
.uk-navbar {
  display: flex;
  /* 1 */
  position: relative; }

/* Container
 ========================================================================== */
.uk-navbar-container:not(.uk-navbar-transparent) {
  background: #f8f8f8; }

/* Groups
 ========================================================================== */
/*
 * 1. Align navs and items vertically if they have a different height
 */
.uk-navbar-left,
.uk-navbar-right,
[class*='uk-navbar-center'] {
  display: flex;
  gap: 0px;
  /* 1 */
  align-items: center; }

/*
 * Horizontal alignment
 * 1. Create position context for centered navbar with sub groups (left/right)
 * 2. Fix text wrapping if content is larger than 50% of the container.
 * 3. Needed for dropdowns because a new position context is created
 *    `z-index` must be smaller than off-canvas
 * 4. Align sub groups for centered navbar
 */
.uk-navbar-right {
  margin-left: auto; }

.uk-navbar-center:only-child {
  margin-left: auto;
  margin-right: auto;
  /* 1 */
  position: relative; }

.uk-navbar-center:not(:only-child) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* 2 */
  width: max-content;
  box-sizing: border-box;
  /* 3 */
  z-index: 990; }

/* 4 */
.uk-navbar-center-left,
.uk-navbar-center-right {
  position: absolute;
  top: 0; }

.uk-navbar-center-left {
  right: calc(100% + 0px); }

.uk-navbar-center-right {
  left: calc(100% + 0px); }

[class*='uk-navbar-center-'] {
  width: max-content;
  box-sizing: border-box; }

/* Nav
 ========================================================================== */
/*
 * 1. Reset list
 */
.uk-navbar-nav {
  display: flex;
  gap: 0px;
  /* 1 */
  margin: 0;
  padding: 0;
  list-style: none; }

/*
 * Allow items to wrap into the next line
 * Only not `absolute` positioned groups
 */
.uk-navbar-left,
.uk-navbar-right,
.uk-navbar-center:only-child {
  flex-wrap: wrap; }

/*
 * Items
 * 1. Center content vertically and horizontally
 * 2. Imitate white space gap when using flexbox
 * 3. Dimensions
 * 4. Style
 * 5. Required for `a`
 */
.uk-navbar-nav > li > a,
.uk-navbar-item,
.uk-navbar-toggle {
  /* 1 */
  display: flex;
  justify-content: center;
  align-items: center;
  /* 2 */
  column-gap: 0.25em;
  /* 3 */
  box-sizing: border-box;
  min-height: auto;
  /* 4 */
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  /* 5 */
  text-decoration: none; }

/*
 * Nav items
 */
.uk-navbar-nav > li > a {
  padding: 0 15px;
  color: #999; }

/*
 * Hover
 * Apply hover style also if dropdown is opened
 */
.uk-navbar-nav > li:hover > a,
.uk-navbar-nav > li > a[aria-expanded="true"] {
  color: #666; }

/* OnClick */
.uk-navbar-nav > li > a:active {
  color: #333; }

/* Active */
.uk-navbar-nav > li.uk-active > a {
  color: #333; }

/* Parent icon modifier
 ========================================================================== */
.uk-navbar-parent-icon {
  margin-left: 4px; }

.uk-navbar-nav > li > a[aria-expanded="true"] .uk-navbar-parent-icon {
  transform: rotate(180deg); }

/* Item
 ========================================================================== */
.uk-navbar-item {
  padding: 0 15px;
  color: #666; }

/*
 * Remove margin from the last-child
 */
.uk-navbar-item > :last-child {
  margin-bottom: 0; }

/* Toggle
 ========================================================================== */
.uk-navbar-toggle {
  padding: 0 15px;
  color: #999; }

.uk-navbar-toggle:hover,
.uk-navbar-toggle[aria-expanded="true"] {
  color: #666;
  text-decoration: none; }

/*
 * Icon
 * Adopts `uk-icon`
 */
/* Hover */
/* Subtitle
 ========================================================================== */
.uk-navbar-subtitle {
  font-size: 0.875rem; }

/* Justify modifier
 ========================================================================== */
.uk-navbar-justify .uk-navbar-left,
.uk-navbar-justify .uk-navbar-right,
.uk-navbar-justify .uk-navbar-nav,
.uk-navbar-justify .uk-navbar-nav > li,
.uk-navbar-justify .uk-navbar-item,
.uk-navbar-justify .uk-navbar-toggle {
  flex-grow: 1; }

/* Style modifiers
 ========================================================================== */
/* Dropdown
 ========================================================================== */
/*
 * Adopts `uk-dropdown`
 * 1. Hide by default
 * 2. Set position
 * 3. Set a default width
 * 4. Style
 */
.uk-navbar-dropdown {
  /* 1 */
  display: none;
  /* 2 */
  position: absolute;
  z-index: 1020;
  --uk-position-offset: 0;
  --uk-position-shift-offset: 0;
  --uk-position-viewport-offset: 15px;
  /* 3 */
  box-sizing: border-box;
  width: 200px;
  /* 4 */
  padding: 15px;
  background: #f8f8f8;
  color: #666; }

/* Show */
.uk-navbar-dropdown.uk-open {
  display: block; }

/*
 * Remove margin from the last-child
 */
.uk-navbar-dropdown > :last-child {
  margin-bottom: 0; }

/*
 * Grid
 * Adopts `uk-grid`
 */
/* Gutter Horizontal */
.uk-navbar-dropdown-grid {
  margin-left: -30px; }

.uk-navbar-dropdown-grid > * {
  padding-left: 30px; }

/* Gutter Vertical */
.uk-navbar-dropdown-grid > .uk-grid-margin {
  margin-top: 30px; }

/* Stack */
.uk-navbar-dropdown-stack .uk-navbar-dropdown-grid > * {
  width: 100% !important; }

/*
 * Width modifier
 */
.uk-navbar-dropdown-width-2:not(.uk-navbar-dropdown-stack) {
  width: 400px; }

.uk-navbar-dropdown-width-3:not(.uk-navbar-dropdown-stack) {
  width: 600px; }

.uk-navbar-dropdown-width-4:not(.uk-navbar-dropdown-stack) {
  width: 800px; }

.uk-navbar-dropdown-width-5:not(.uk-navbar-dropdown-stack) {
  width: 1000px; }

/*
 * Size modifier
 */
.uk-navbar-dropdown-large {
  --uk-position-shift-offset: 0;
  padding: 40px; }

/*
 * Dropbar modifier
 * 1. Reset dropdown width to prevent to early shifting
 * 2. Reset style
 * 3. Padding
 */
.uk-navbar-dropdown-dropbar {
  /* 1 */
  width: auto;
  /* 2 */
  background: transparent;
  /* 3 */
  padding: 15px 0 15px 0;
  --uk-position-offset: 0;
  --uk-position-shift-offset: 0;
  --uk-position-viewport-offset: 15px; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-navbar-dropdown-dropbar {
    --uk-position-viewport-offset: 30px; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-navbar-dropdown-dropbar {
    --uk-position-viewport-offset: 40px; } }

.uk-navbar-dropdown-dropbar-large {
  --uk-position-shift-offset: 0;
  padding-top: 40px;
  padding-bottom: 40px; }

/* Dropdown Nav
 * Adopts `uk-nav`
 ========================================================================== */
/*
 * Items
 */
.uk-navbar-dropdown-nav > li > a {
  color: #999; }

/* Hover */
.uk-navbar-dropdown-nav > li > a:hover {
  color: #666; }

/* Active */
.uk-navbar-dropdown-nav > li.uk-active > a {
  color: #333; }

/*
 * Subtitle
 */
.uk-navbar-dropdown-nav .uk-nav-subtitle {
  font-size: 0.875rem; }

/*
 * Header
 */
.uk-navbar-dropdown-nav .uk-nav-header {
  color: #333; }

/*
 * Divider
 */
.uk-navbar-dropdown-nav .uk-nav-divider {
  border-top: 1px solid #e5e5e5; }

/*
 * Sublists
 */
.uk-navbar-dropdown-nav .uk-nav-sub a {
  color: #999; }

.uk-navbar-dropdown-nav .uk-nav-sub a:hover {
  color: #666; }

.uk-navbar-dropdown-nav .uk-nav-sub li.uk-active > a {
  color: #333; }

/* Dropbar
 ========================================================================== */
/*
 * 1. Reset dropbar
 * 2. Width
 */
.uk-navbar-dropbar {
  /* 1 */
  display: block !important;
  z-index: 980;
  padding: 0;
  /* 2 */
  left: 0;
  right: 0; }

/* ========================================================================
   Component: Subnav
 ========================================================================== */
/*
 * 1. Allow items to wrap into the next line
 * 2. Center items vertically if they have a different height
 * 3. Gutter
 * 4. Reset list
 */
.uk-subnav {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  align-items: center;
  /* 3 */
  margin-left: -20px;
  /* 4 */
  padding: 0;
  list-style: none; }

/*
 * 1. Space is allocated solely based on content dimensions: 0 0 auto
 * 2. Gutter
 * 3. Create position context for dropdowns
 */
.uk-subnav > * {
  /* 1 */
  flex: none;
  /* 2 */
  padding-left: 20px;
  /* 3 */
  position: relative; }

/* Items
 ========================================================================== */
/*
 * Items must target `a` elements to exclude other elements (e.g. dropdowns)
 * Using `:first-child` instead of `a` to support `span` elements for text
 * 1. Center content vertically, e.g. an icon
 * 2. Imitate white space gap when using flexbox
 * 3. Style
 */
.uk-subnav > * > :first-child {
  /* 1 */
  display: flex;
  align-items: center;
  /* 2 */
  column-gap: 0.25em;
  /* 3 */
  color: #999; }

/* Hover */
.uk-subnav > * > a:hover {
  color: #666;
  text-decoration: none; }

/* Active */
.uk-subnav > .uk-active > a {
  color: #333; }

/* Divider modifier
 ========================================================================== */
/*
 * Set gutter
 */
.uk-subnav-divider {
  margin-left: -41px; }

/*
 * Align items and divider vertically
 */
.uk-subnav-divider > * {
  display: flex;
  align-items: center; }

/*
 * Divider
 * 1. `nth-child` makes it also work without JS if it's only one row
 */
.uk-subnav-divider > ::before {
  content: "";
  height: 1.5em;
  margin-left: 0px;
  margin-right: 20px;
  border-left: 1px solid transparent; }

/* 1 */
.uk-subnav-divider > :nth-child(n+2):not(.uk-first-column)::before {
  border-left-color: #e5e5e5; }

/* Pill modifier
 ========================================================================== */
.uk-subnav-pill > * > :first-child {
  padding: 5px 10px;
  background: transparent;
  color: #999; }

/* Hover */
.uk-subnav-pill > * > a:hover {
  background-color: #f8f8f8;
  color: #666; }

/* OnClick */
.uk-subnav-pill > * > a:active {
  background-color: #f8f8f8;
  color: #666; }

/* Active */
.uk-subnav-pill > .uk-active > a {
  background-color: #1e87f0;
  color: #fff; }

/* Disabled
 * The same for all style modifiers
 ========================================================================== */
.uk-subnav > .uk-disabled > a {
  color: #999; }

/* ========================================================================
   Component: Iconnav
 ========================================================================== */
/*
 * 1. Allow items to wrap into the next line
 * 2. Reset list
 * 3. Gutter
 */
.uk-iconnav {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  margin: 0;
  padding: 0;
  list-style: none;
  /* 3 */
  margin-left: -10px; }

/*
 * Space is allocated based on content dimensions, but shrinks: 0 1 auto
 * 1. Gutter
 */
.uk-iconnav > * {
  /* 1 */
  padding-left: 10px; }

/* Items
 ========================================================================== */
/*
 * Items must target `a` elements to exclude other elements (e.g. dropdowns)
 * 1. Center content vertically if there is still some text
 * 2. Imitate white space gap when using flexbox
 * 3. Force text not to affect item height
 * 4. Style
 * 5. Required for `a` if there is still some text
 */
.uk-iconnav > * > a {
  /* 1 */
  display: flex;
  align-items: center;
  /* 2 */
  column-gap: 0.25em;
  /* 3 */
  line-height: 0;
  /* 4 */
  color: #999;
  /* 5 */
  text-decoration: none; }

/* Hover */
.uk-iconnav > * > a:hover {
  color: #666; }

/* Active */
.uk-iconnav > .uk-active > a {
  color: #666; }

/* Modifier: 'uk-iconnav-vertical'
 ========================================================================== */
/*
 * 1. Change direction
 * 2. Gutter
 */
.uk-iconnav-vertical {
  /* 1 */
  flex-direction: column;
  /* 2 */
  margin-left: 0;
  margin-top: -10px; }

/* 2 */
.uk-iconnav-vertical > * {
  padding-left: 0;
  padding-top: 10px; }

/* ========================================================================
   Component: Breadcrumb
 ========================================================================== */
/*
 * Reset list
 */
.uk-breadcrumb {
  padding: 0;
  list-style: none; }

/*
 * 1. Doesn't generate any box and replaced by child boxes
 */
.uk-breadcrumb > * {
  display: contents; }

/* Items
 ========================================================================== */
.uk-breadcrumb > * > * {
  font-size: 0.875rem;
  color: #999; }

/* Hover */
.uk-breadcrumb > * > :hover {
  color: #3CC0CC;
  text-decoration: none; }

/* Disabled */
/* Active */
.uk-breadcrumb > :last-child > span,
.uk-breadcrumb > :last-child > a:not([href]) {
  color: #fff; }

/*
 * Divider
 * `nth-child` makes it also work without JS if it's only one row
 * 1. Remove space between inline block elements.
 * 2. Style
 */
.uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before {
  content: "";
  display: inline-block;
  /* 1 */
  margin: 0 0px 0 calc(0px - 4px);
  /* 2 */
  font-size: 0.875rem;
  color: #6c757d; }

/* ========================================================================
   Component: Pagination
 ========================================================================== */
/*
 * 1. Allow items to wrap into the next line
 * 2. Gutter
 * 3. Reset list
 */
.uk-pagination {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  margin-left: 0;
  /* 3 */
  padding: 0;
  list-style: none; }

/*
 * 1. Space is allocated solely based on content dimensions: 0 0 auto
 * 2. Gutter
 * 3. Create position context for dropdowns
 */
.uk-pagination > * {
  /* 1 */
  flex: none;
  /* 2 */
  padding-left: 0;
  /* 3 */
  position: relative; }

/* Items
 ========================================================================== */
/*
 * 1. Prevent gap if child element is `inline-block`, e.g. an icon
 * 2. Style
 */
.uk-pagination > * > * {
  /* 1 */
  display: block;
  /* 2 */
  padding: 5px 10px;
  color: #999; }

/* Hover */
.uk-pagination > * > :hover {
  color: #666;
  text-decoration: none; }

/* Active */
.uk-pagination > .uk-active > * {
  color: #666; }

/* Disabled */
.uk-pagination > .uk-disabled > * {
  color: #999; }

/* ========================================================================
   Component: Tab
 ========================================================================== */
/*
 * 1. Allow items to wrap into the next line
 * 2. Gutter
 * 3. Reset list
 */
.uk-tab {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  margin-left: -20px;
  /* 3 */
  padding: 0;
  list-style: none; }

/*
 * 1. Space is allocated solely based on content dimensions: 0 0 auto
 * 2. Gutter
 * 3. Create position context for dropdowns
 */
.uk-tab > * {
  /* 1 */
  flex: none;
  /* 2 */
  padding-left: 20px;
  /* 3 */
  position: relative; }

/* Items
 ========================================================================== */
/*
 * Items must target `a` elements to exclude other elements (e.g. dropdowns)
 * 1. Center content vertically, e.g. an icon
 * 2. Imitate white space gap when using flexbox
 * 3. Center content if a width is set
 * 4. Style
 */
.uk-tab > * > a {
  /* 1 */
  display: flex;
  align-items: center;
  /* 2 */
  column-gap: 0.25em;
  /* 3 */
  justify-content: center;
  /* 4 */
  padding: 5px 10px;
  color: #999; }

/* Hover */
.uk-tab > * > a:hover {
  color: #666;
  text-decoration: none; }

/* Active */
.uk-tab > .uk-active > a {
  color: #333; }

/* Disabled */
.uk-tab > .uk-disabled > a {
  color: #999; }

/* Position modifier
 ========================================================================== */
/*
 * Bottom
 */
/*
 * Left + Right
 * 1. Reset Gutter
 */
.uk-tab-left,
.uk-tab-right {
  flex-direction: column;
  /* 1 */
  margin-left: 0; }

/* 1 */
.uk-tab-left > *,
.uk-tab-right > * {
  padding-left: 0; }

.uk-tab-left > * > a {
  justify-content: left; }

.uk-tab-right > * > a {
  justify-content: left; }

/* ========================================================================
   Component: Slidenav
 ========================================================================== */
/*
 * Adopts `uk-icon`
 */
.uk-slidenav {
  padding: 5px 10px;
  color: rgba(102, 102, 102, 0.5); }

/* Hover */
.uk-slidenav:hover {
  color: rgba(102, 102, 102, 0.9); }

/* OnClick */
.uk-slidenav:active {
  color: rgba(102, 102, 102, 0.5); }

/* Icon modifier
 ========================================================================== */
/*
 * Previous
 */
/*
 * Next
 */
/* Size modifier
 ========================================================================== */
.uk-slidenav-large {
  padding: 10px 10px; }

/* Container
 ========================================================================== */
.uk-slidenav-container {
  display: flex; }

/* ========================================================================
   Component: Dotnav
 ========================================================================== */
/*
 * 1. Allow items to wrap into the next line
 * 2. Reset list
 * 3. Gutter
 */
.uk-dotnav {
  display: flex;
  /* 1 */
  flex-wrap: wrap;
  /* 2 */
  margin: 0;
  padding: 0;
  list-style: none;
  /* 3 */
  margin-left: -12px; }

/*
 * 1. Space is allocated solely based on content dimensions: 0 0 auto
 * 2. Gutter
 */
.uk-dotnav > * {
  /* 1 */
  flex: none;
  /* 2 */
  padding-left: 12px; }

/* Items
 ========================================================================== */
/*
 * Items
 * 1. Hide text if present
 */
.uk-dotnav > * > * {
  display: block;
  box-sizing: border-box;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: rgba(102, 102, 102, 0.2);
  /* 1 */
  text-indent: 100%;
  overflow: hidden;
  white-space: nowrap; }

/* Hover */
.uk-dotnav > * > :hover {
  background-color: rgba(102, 102, 102, 0.6); }

/* OnClick */
.uk-dotnav > * > :active {
  background-color: rgba(102, 102, 102, 0.2); }

/* Active */
.uk-dotnav > .uk-active > * {
  background-color: rgba(102, 102, 102, 0.6); }

/* Modifier: 'uk-dotnav-vertical'
 ========================================================================== */
/*
 * 1. Change direction
 * 2. Gutter
 */
.uk-dotnav-vertical {
  /* 1 */
  flex-direction: column;
  /* 2 */
  margin-left: 0;
  margin-top: -12px; }

/* 2 */
.uk-dotnav-vertical > * {
  padding-left: 0;
  padding-top: 12px; }

/* ========================================================================
   Component: Accordion
 ========================================================================== */
.uk-accordion {
  padding: 0;
  list-style: none; }

/* Item
 ========================================================================== */
.uk-accordion > :nth-child(n+2) {
  margin-top: 20px; }

/* Title
 ========================================================================== */
.uk-accordion-title {
  display: block;
  font-size: 1.25rem;
  line-height: 1.4;
  color: #333; }

/* Hover */
.uk-accordion-title:hover {
  color: #666;
  text-decoration: none; }

/* Content
 ========================================================================== */
.uk-accordion-content {
  display: flow-root;
  margin-top: 20px; }

/*
 * Remove margin from the last-child
 */
.uk-accordion-content > :last-child {
  margin-bottom: 0; }

/* ========================================================================
   Component: Dropdown
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 * 3. Set a default width
 * 4. Style
 */
.uk-dropdown {
  /* 1 */
  display: none;
  /* 2 */
  position: absolute;
  z-index: 1020;
  --uk-position-offset: 10px;
  --uk-position-viewport-offset: 15px;
  /* 3 */
  box-sizing: border-box;
  min-width: 10rem;
  /* 4 */
  padding: 15px;
  background: #fff;
  color: #212529; }

/* Show */
.uk-dropdown.uk-open {
  display: block; }

/*
 * Remove margin from the last-child
 */
.uk-dropdown > :last-child {
  margin-bottom: 0; }

/* Size modifier
 ========================================================================== */
.uk-dropdown-large {
  padding: 40px; }

/* Nav
 * Adopts `uk-nav`
 ========================================================================== */
/*
 * Items
 */
.uk-dropdown-nav > li > a {
  color: #999; }

/* Hover + Active */
.uk-dropdown-nav > li > a:hover,
.uk-dropdown-nav > li.uk-active > a {
  color: #666; }

/*
 * Subtitle
 */
.uk-dropdown-nav .uk-nav-subtitle {
  font-size: 0.875rem; }

/*
 * Header
 */
.uk-dropdown-nav .uk-nav-header {
  color: #333; }

/*
 * Divider
 */
.uk-dropdown-nav .uk-nav-divider {
  border-top: 1px solid #e5e5e5; }

/*
 * Sublists
 */
.uk-dropdown-nav .uk-nav-sub a {
  color: #999; }

.uk-dropdown-nav .uk-nav-sub a:hover,
.uk-dropdown-nav .uk-nav-sub li.uk-active > a {
  color: #666; }

/* Grid modifiers
 ========================================================================== */
.uk-dropdown-stack .uk-dropdown-grid > * {
  width: 100% !important; }

/* ========================================================================
   Component: Modal
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 * 3. Allow scrolling for the modal dialog
 * 4. Horizontal padding
 * 5. Mask the background page
 * 6. Fade-in transition
 */
.uk-modal {
  /* 1 */
  display: none;
  /* 2 */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1010;
  /* 3 */
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  /* 4 */
  padding: 15px 15px;
  /* 5 */
  background: rgba(0, 0, 0, 0.6);
  /* 6 */
  opacity: 0;
  transition: opacity 0.15s linear; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-modal {
    padding: 50px 30px; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-modal {
    padding-left: 40px;
    padding-right: 40px; } }

/*
 * Open
 */
.uk-modal.uk-open {
  opacity: 1; }

/* Page
 ========================================================================== */
/*
 * Prevent scrollbars
 */
.uk-modal-page {
  overflow: hidden; }

/* Dialog
 ========================================================================== */
/*
 * 1. Create position context for spinner and close button
 * 2. Dimensions
 * 3. `!important` is needed to overwrite `uk-width-auto`. See `#modal-media-image` in tests
 * 4. Style
 * 5. Slide-in transition
 */
.uk-modal-dialog {
  /* 1 */
  position: relative;
  /* 2 */
  box-sizing: border-box;
  margin: 0 auto;
  width: 600px;
  /* 3 */
  max-width: 100% !important;
  /* 4 */
  background: #fff;
  /* 5 */
  opacity: 0;
  transform: translateY(-100px);
  transition: 0.3s linear;
  transition-property: opacity, transform; }

/*
 * Open
 */
.uk-open > .uk-modal-dialog {
  opacity: 1;
  transform: translateY(0); }

/* Size modifier
 ========================================================================== */
/*
 * Container size
 * Take the same size as the Container component
 */
.uk-modal-container .uk-modal-dialog {
  width: 1200px; }

/*
 * Full size
 * 1. Remove padding and background from modal
 * 2. Reset all default declarations from modal dialog
 */
/* 1 */
.uk-modal-full {
  padding: 0;
  background: none; }

/* 2 */
.uk-modal-full .uk-modal-dialog {
  margin: 0;
  width: 100%;
  max-width: 100%;
  transform: translateY(0); }

/* Sections
 ========================================================================== */
.uk-modal-body {
  display: flow-root;
  padding: 20px 20px; }

.uk-modal-header {
  display: flow-root;
  padding: 10px 20px;
  background: #f8f8f8; }

.uk-modal-footer {
  display: flow-root;
  padding: 10px 20px;
  background: #f8f8f8; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-modal-body {
    padding: 30px 30px; }
  .uk-modal-header {
    padding: 15px 30px; }
  .uk-modal-footer {
    padding: 15px 30px; } }

/*
 * Remove margin from the last-child
 */
.uk-modal-body > :last-child,
.uk-modal-header > :last-child,
.uk-modal-footer > :last-child {
  margin-bottom: 0; }

/* Title
 ========================================================================== */
.uk-modal-title {
  font-size: 2rem;
  line-height: 1.5; }

/* Close
 * Adopts `uk-close`
 ========================================================================== */
[class*='uk-modal-close-'] {
  position: absolute;
  z-index: 1010;
  top: 10px;
  right: 10px;
  padding: 5px; }

/*
 * Remove margin from adjacent element
 */
[class*='uk-modal-close-']:first-child + * {
  margin-top: 0; }

/*
 * Hover
 */
/*
 * Default
 */
/*
 * Outside
 * 1. Prevent scrollbar on small devices
 */
.uk-modal-close-outside {
  top: 0;
  /* 1 */
  right: -5px;
  transform: translate(0, -100%);
  color: white; }

.uk-modal-close-outside:hover {
  color: #fff; }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  /* 1 */
  .uk-modal-close-outside {
    right: 0;
    transform: translate(100%, -100%); } }

/*
 * Full
 */
/* ========================================================================
   Component: Lightbox
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 * 3. Allow scrolling for the modal dialog
 * 4. Horizontal padding
 * 5. Mask the background page
 * 6. Fade-in transition
 * 7. Prevent cancellation of pointer events while dragging
 */
.uk-lightbox {
  /* 1 */
  display: none;
  /* 2 */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1010;
  /* 5 */
  background: #000;
  /* 6 */
  opacity: 0;
  transition: opacity 0.15s linear;
  /* 7 */
  touch-action: pinch-zoom; }

/*
 * Open
 * 1. Center child
 * 2. Fade-in
 */
.uk-lightbox.uk-open {
  display: block;
  /* 2 */
  opacity: 1; }

/*
 * Focus
 */
.uk-lightbox :focus {
  outline-color: rgba(255, 255, 255, 0.7); }

.uk-lightbox :focus-visible {
  outline-color: rgba(255, 255, 255, 0.7); }

/* Page
 ========================================================================== */
/*
 * Prevent scrollbars
 */
.uk-lightbox-page {
  overflow: hidden; }

/* Item
 ========================================================================== */
/*
 * 1. Center child within the viewport
 * 2. Not visible by default
 * 3. Color needed for spinner icon
 * 4. Optimize animation
 * 5. Responsiveness
 *    Using `vh` for `max-height` to fix image proportions after resize in Safari and Opera
 */
.uk-lightbox-items > * {
  /* 1 */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* 2 */
  display: none;
  justify-content: center;
  align-items: center;
  /* 3 */
  color: rgba(255, 255, 255, 0.7);
  /* 4 */
  will-change: transform, opacity; }

/* 5 */
.uk-lightbox-items > * > * {
  max-width: 100vw;
  max-height: 100vh; }

.uk-lightbox-items > * > :not(iframe) {
  width: auto;
  height: auto; }

.uk-lightbox-items > .uk-active {
  display: flex; }

/* Toolbar
 ========================================================================== */
.uk-lightbox-toolbar {
  padding: 10px 10px;
  background: rgba(0, 0, 0, 0.3);
  color: rgba(255, 255, 255, 0.7); }

.uk-lightbox-toolbar > * {
  color: rgba(255, 255, 255, 0.7); }

/* Toolbar Icon (Close)
 ========================================================================== */
.uk-lightbox-toolbar-icon {
  padding: 5px;
  color: rgba(255, 255, 255, 0.7); }

/*
 * Hover
 */
.uk-lightbox-toolbar-icon:hover {
  color: #fff; }

/* Button (Slidenav)
 ========================================================================== */
/*
 * 1. Center icon vertically and horizontally
 */
.uk-lightbox-button {
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0.3);
  color: rgba(255, 255, 255, 0.7);
  /* 1 */
  display: inline-flex;
  justify-content: center;
  align-items: center; }

/* Hover */
.uk-lightbox-button:hover {
  color: #fff; }

/* OnClick */
/* Caption
 ========================================================================== */
.uk-lightbox-caption:empty {
  display: none; }

/* Iframe
 ========================================================================== */
.uk-lightbox-iframe {
  width: 80%;
  height: 80%; }

/* ========================================================================
   Component: Slideshow
 ========================================================================== */
/*
 * 1. Prevent tab highlighting on iOS.
 */
.uk-slideshow {
  /* 1 */
  -webkit-tap-highlight-color: transparent; }

/* Items
 ========================================================================== */
/*
 * 1. Create position and stacking context
 * 2. Reset list
 * 3. Clip child elements
 * 4. Prevent displaying the callout information on iOS.
 * 5. Disable horizontal panning gestures
 */
.uk-slideshow-items {
  /* 1 */
  position: relative;
  z-index: 0;
  /* 2 */
  margin: 0;
  padding: 0;
  list-style: none;
  /* 3 */
  overflow: hidden;
  /* 4 */
  -webkit-touch-callout: none;
  /* 5 */
  touch-action: pan-y; }

/* Item
 ========================================================================== */
/*
 * 1. Position items above each other
 * 2. Take the full width
 * 3. Clip child elements, e.g. for `uk-cover`
 * 4. Optimize animation
 */
.uk-slideshow-items > * {
  /* 1 */
  position: absolute;
  top: 0;
  left: 0;
  /* 2 */
  right: 0;
  bottom: 0;
  /* 3 */
  overflow: hidden;
  /* 4 */
  will-change: transform, opacity; }

/*
 * Hide not active items
 */
.uk-slideshow-items > :not(.uk-active) {
  display: none; }

/* ========================================================================
   Component: Text
 ========================================================================== */
/* Style modifiers
 ========================================================================== */
.uk-text-lead {
  font-size: 1.5rem;
  line-height: 1.5;
  color: #333; }

.uk-text-meta {
  font-size: 0.875rem;
  line-height: 1.4;
  color: #999; }

/* Size modifiers
 ========================================================================== */
.uk-text-small {
  font-size: 0.875rem;
  line-height: 1.5; }

.uk-text-large {
  font-size: 1.5rem;
  line-height: 1.5; }

.uk-text-default {
  font-size: 16px;
  line-height: 1.5; }

/* Weight modifier
 ========================================================================== */
.uk-text-light {
  font-weight: 300; }

.uk-text-normal {
  font-weight: 400; }

.uk-text-bold {
  font-weight: 700; }

.uk-text-lighter {
  font-weight: lighter; }

.uk-text-bolder {
  font-weight: bolder; }

/* Style modifier
 ========================================================================== */
.uk-text-italic {
  font-style: italic; }

/* Transform modifier
 ========================================================================== */
.uk-text-capitalize {
  text-transform: capitalize !important; }

.uk-text-uppercase {
  text-transform: uppercase !important; }

.uk-text-lowercase {
  text-transform: lowercase !important; }

/* Decoration modifier
 ========================================================================== */
.uk-text-decoration-none {
  text-decoration: none !important; }

/* Color modifiers
 ========================================================================== */
.uk-text-muted {
  color: #999 !important; }

.uk-text-emphasis {
  color: #333 !important; }

.uk-text-primary {
  color: #1e87f0 !important; }

.uk-text-secondary {
  color: #222 !important; }

.uk-text-success {
  color: #32d296 !important; }

.uk-text-warning {
  color: #faa05a !important; }

.uk-text-danger {
  color: #f0506e !important; }

/* Background modifier
 ========================================================================== */
/*
 * 1. The background clips to the foreground text. Works in all browsers.
 * 2. Default color is set to transparent.
 * 3. Container fits the text
 * 4. Style
 */
.uk-text-background {
  /* 1 */
  -webkit-background-clip: text;
  /* 2 */
  color: transparent !important;
  /* 3 */
  display: inline-block;
  /* 4 */
  background-color: #1e87f0; }

/* Alignment modifiers
 ========================================================================== */
.uk-text-left {
  text-align: left !important; }

.uk-text-right {
  text-align: right !important; }

.uk-text-center {
  text-align: center !important; }

.uk-text-justify {
  text-align: justify !important; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-text-left\@s {
    text-align: left !important; }
  .uk-text-right\@s {
    text-align: right !important; }
  .uk-text-center\@s {
    text-align: center !important; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-text-left\@m {
    text-align: left !important; }
  .uk-text-right\@m {
    text-align: right !important; }
  .uk-text-center\@m {
    text-align: center !important; } }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-text-left\@l {
    text-align: left !important; }
  .uk-text-right\@l {
    text-align: right !important; }
  .uk-text-center\@l {
    text-align: center !important; } }

/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-text-left\@xl {
    text-align: left !important; }
  .uk-text-right\@xl {
    text-align: right !important; }
  .uk-text-center\@xl {
    text-align: center !important; } }

/*
 * Vertical
 */
.uk-text-top {
  vertical-align: top !important; }

.uk-text-middle {
  vertical-align: middle !important; }

.uk-text-bottom {
  vertical-align: bottom !important; }

.uk-text-baseline {
  vertical-align: baseline !important; }

/* Wrap modifiers
 ========================================================================== */
/*
 * Prevent text from wrapping onto multiple lines
 */
.uk-text-nowrap {
  white-space: nowrap; }

/*
 * 1. Make sure a max-width is set after which truncation can occur
 * 2. Prevent text from wrapping onto multiple lines, and truncate with an ellipsis
 * 3. Fix for table cells
 */
.uk-text-truncate {
  /* 1 */
  max-width: 100%;
  /* 2 */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

/* 2 */
th.uk-text-truncate,
td.uk-text-truncate {
  max-width: 0; }

/*
 * Wrap long words onto the next line and break them if they are too long to fit.
 * 1. Make it work with table cells in all browsers.
 * Note: Not using `hyphens: auto` because it hyphenates text even if not needed.
 */
.uk-text-break {
  overflow-wrap: break-word; }

/* 1 */
th.uk-text-break,
td.uk-text-break {
  word-break: break-word; }

/* ========================================================================
   Component: Slider
 ========================================================================== */
/*
 * 1. Prevent tab highlighting on iOS.
 */
.uk-slider {
  /* 1 */
  -webkit-tap-highlight-color: transparent; }

/* Container
 ========================================================================== */
/*
 * Clip child elements
 */
.uk-slider-container {
  overflow: hidden; }

/*
 * Widen container to prevent box-shadows from clipping, `large-box-shadow`
 */
.uk-slider-container-offset {
  margin: -11px -25px -39px -25px;
  padding: 11px 25px 39px 25px; }

/* Items
 ========================================================================== */
/*
 * 1. Optimize animation
 * 2. Create a containing block. In Safari it's neither created by `transform` nor `will-change`.
 * 3. Disable horizontal panning gestures
 */
.uk-slider-items {
  /* 1 */
  will-change: transform;
  /* 2 */
  position: relative;
  /* 3 */
  touch-action: pan-y; }

/*
 * 1. Reset list style without interfering with grid
 * 2. Prevent displaying the callout information on iOS.
 */
.uk-slider-items:not(.uk-grid) {
  display: flex;
  /* 1 */
  margin: 0;
  padding: 0;
  list-style: none;
  /* 2 */
  -webkit-touch-callout: none; }

.uk-slider-items.uk-grid {
  flex-wrap: nowrap; }

/* Item
 ========================================================================== */
/*
 * 1. Let items take content dimensions (0 0 auto)
 *    `max-width` needed to keep image responsiveness and prevent content overflow
 * 3. Create position context
 */
.uk-slider-items > * {
  /* 1 */
  flex: none;
  max-width: 100%;
  /* 3 */
  position: relative; }

/* ========================================================================
   Component: Sticky
 ========================================================================== */
/*
 * 1. Create position context so it's t the same like when fixed.
 * 2. More robust if padding and border are used and the sticky height is transitioned
 */
.uk-sticky {
  /* 1 */
  position: relative;
  /* 2 */
  box-sizing: border-box; }

/*
 * 1. Force new layer to resolve frame rate issues on devices with lower frame rates
 */
.uk-sticky-fixed {
  z-index: 980;
  margin: 0 !important;
  /* 1 */
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

/*
 * Faster animations
 */
.uk-sticky[class*='uk-animation-'] {
  animation-duration: 0.2s; }

.uk-sticky.uk-animation-reverse {
  animation-duration: 0.2s; }

/*
 * Placeholder
 * Make content clickable for sticky cover and reveal effects
 */
.uk-sticky-placeholder {
  pointer-events: none; }

/* ========================================================================
   Component: Off-canvas
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 */
.uk-offcanvas {
  /* 1 */
  display: none;
  /* 2 */
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1000; }

/*
 * Flip modifier
 */
.uk-offcanvas-flip .uk-offcanvas {
  right: 0;
  left: auto; }

/* Bar
 ========================================================================== */
/*
 * 1. Set position
 * 2. Size and style
 * 3. Allow scrolling
 */
.uk-offcanvas-bar {
  /* 1 */
  position: absolute;
  top: 0;
  bottom: 0;
  left: -100%;
  /* 2 */
  box-sizing: border-box;
  width: 100%;
  padding: 20px 20px;
  background: #222;
  /* 3 */
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-offcanvas-bar {
    left: -350px;
    width: 350px;
    padding: 30px 30px; } }

/* Flip modifier */
.uk-offcanvas-flip .uk-offcanvas-bar {
  left: auto;
  right: -100%; }

/* Tablet landscape and bigger */
@media (min-width: 640px) {
  .uk-offcanvas-flip .uk-offcanvas-bar {
    right: -350px; } }

/*
 * Open
 */
.uk-open > .uk-offcanvas-bar {
  left: 0; }

.uk-offcanvas-flip .uk-open > .uk-offcanvas-bar {
  left: auto;
  right: 0; }

/*
 * Slide Animation (Used in slide and push mode)
 */
.uk-offcanvas-bar-animation {
  transition: left 0.3s ease-out; }

.uk-offcanvas-flip .uk-offcanvas-bar-animation {
  transition-property: right; }

/*
 * Reveal Animation
 * 1. Set position
 * 2. Clip the bar
 * 3. Animation
 * 4. Reset position
 */
.uk-offcanvas-reveal {
  /* 1 */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  /* 2 */
  width: 0;
  overflow: hidden;
  /* 3 */
  transition: width 0.3s ease-out; }

.uk-offcanvas-reveal .uk-offcanvas-bar {
  /* 4 */
  left: 0; }

.uk-offcanvas-flip .uk-offcanvas-reveal .uk-offcanvas-bar {
  /* 4 */
  left: auto;
  right: 0; }

.uk-open > .uk-offcanvas-reveal {
  width: 100%; }

/* Tablet landscape and bigger */
@media (min-width: 640px) {
  .uk-open > .uk-offcanvas-reveal {
    width: 350px; } }

/*
 * Flip modifier
 */
.uk-offcanvas-flip .uk-offcanvas-reveal {
  right: 0;
  left: auto; }

/* Close
 * Adopts `uk-close`
 ========================================================================== */
.uk-offcanvas-close {
  position: absolute;
  z-index: 1000;
  top: 5px;
  right: 5px;
  padding: 5px; }

/* Tablet landscape and bigger */
@media (min-width: 640px) {
  .uk-offcanvas-close {
    top: 10px;
    right: 10px; } }

/*
 * Remove margin from adjacent element
 */
.uk-offcanvas-close:first-child + * {
  margin-top: 0; }

/* Overlay
 ========================================================================== */
/*
 * Overlay the whole page. Needed for the `::before`
 * 1. Using `100vw` so no modification is needed when off-canvas is flipped
 * 2. Allow for closing with swipe gesture on devices with pointer events.
 */
.uk-offcanvas-overlay {
  /* 1 */
  width: 100vw;
  /* 2 */
  touch-action: none; }

/*
 * 1. Mask the whole page
 * 2. Fade-in transition
 */
.uk-offcanvas-overlay::before {
  /* 1 */
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.1);
  /* 2 */
  opacity: 0;
  transition: opacity 0.15s linear; }

.uk-offcanvas-overlay.uk-open::before {
  opacity: 1; }

/* Prevent scrolling
 ========================================================================== */
/*
 * Prevent horizontal scrollbar when the content is slide-out
 * Has to be on the `html` element too to make it work on the `body`
 */
.uk-offcanvas-page,
.uk-offcanvas-container {
  overflow-x: hidden; }

/* Container
 ========================================================================== */
/*
 * Prepare slide-out animation (Used in reveal and push mode)
 * Using `position: left` instead of `transform` because position `fixed` elements like sticky navbars
 * lose their fixed state and behaves like `absolute` within a transformed container
 * 1. Provide a fixed width and prevent shrinking
 */
.uk-offcanvas-container {
  position: relative;
  left: 0;
  transition: left 0.3s ease-out;
  /* 1 */
  box-sizing: border-box;
  width: 100%; }

/*
 * Activate slide-out animation
 */
:not(.uk-offcanvas-flip).uk-offcanvas-container-animation {
  left: 100%; }

.uk-offcanvas-flip.uk-offcanvas-container-animation {
  left: -100%; }

/* Tablet landscape and bigger */
@media (min-width: 640px) {
  :not(.uk-offcanvas-flip).uk-offcanvas-container-animation {
    left: 350px; }
  .uk-offcanvas-flip.uk-offcanvas-container-animation {
    left: -350px; } }

/* ========================================================================
   Component: Switcher
 ========================================================================== */
/*
 * Reset list
 */
.uk-switcher {
  margin: 0;
  padding: 0;
  list-style: none; }

/* Items
 ========================================================================== */
/*
 * Hide not active items
 */
.uk-switcher > :not(.uk-active) {
  display: none; }

/*
 * Remove margin from the last-child
 */
.uk-switcher > * > :last-child {
  margin-bottom: 0; }

/* ========================================================================
   Component: Notification
 ========================================================================== */
/*
 * 1. Set position
 * 2. Dimensions
 */
.uk-notification {
  /* 1 */
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 1040;
  /* 2 */
  box-sizing: border-box;
  width: 350px; }

/* Position modifiers
========================================================================== */
.uk-notification-top-right,
.uk-notification-bottom-right {
  left: auto;
  right: 10px; }

.uk-notification-top-center,
.uk-notification-bottom-center {
  left: 50%;
  margin-left: -175px; }

.uk-notification-bottom-left,
.uk-notification-bottom-right,
.uk-notification-bottom-center {
  top: auto;
  bottom: 10px; }

/* Responsiveness
========================================================================== */
/* Phones portrait and smaller */
@media (max-width: 639px) {
  .uk-notification {
    left: 10px;
    right: 10px;
    width: auto;
    margin: 0; } }

/* Message
========================================================================== */
.uk-notification-message {
  position: relative;
  padding: 15px;
  background: #f8f8f8;
  color: #666;
  font-size: 1.25rem;
  line-height: 1.4;
  cursor: pointer; }

* + .uk-notification-message {
  margin-top: 10px; }

/* Close
 * Adopts `uk-close`
 ========================================================================== */
.uk-notification-close {
  display: none;
  position: absolute;
  top: 20px;
  right: 15px; }

.uk-notification-message:hover .uk-notification-close {
  display: block; }

/* Style modifiers
 ========================================================================== */
/*
 * Primary
 */
.uk-notification-message-primary {
  color: #1e87f0; }

/*
 * Success
 */
.uk-notification-message-success {
  color: #32d296; }

/*
 * Warning
 */
.uk-notification-message-warning {
  color: #faa05a; }

/*
 * Danger
 */
.uk-notification-message-danger {
  color: #f0506e; }

/* ========================================================================
   Component: Tooltip
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Position
 * 3. Remove tooltip from document flow to keep the UIkit container from changing its size when injected into the document initially
 * 4. Dimensions
 * 5. Style
 */
.uk-tooltip {
  /* 1 */
  display: none;
  /* 2 */
  position: absolute;
  z-index: 1030;
  --uk-position-offset: 10px;
  --uk-position-viewport-offset: 10;
  /* 3 */
  top: 0;
  /* 4 */
  box-sizing: border-box;
  max-width: 200px;
  padding: 3px 6px;
  /* 5 */
  background: #666;
  border-radius: 0.8rem;
  color: #fff;
  font-size: 0.875rem; }

/* Show */
.uk-tooltip.uk-active {
  display: block; }

/* ========================================================================
   Component: Animation
 ========================================================================== */
[class*='uk-animation-'] {
  animation: 0.5s ease-out both; }

/* Animations
 ========================================================================== */
/*
 * Fade
 */
.uk-animation-fade {
  animation-name: uk-fade;
  animation-duration: 0.8s;
  animation-timing-function: linear; }

/*
 * Scale
 */
.uk-animation-scale-up {
  animation-name: uk-fade, uk-scale-up; }

.uk-animation-scale-down {
  animation-name: uk-fade, uk-scale-down; }

/*
 * Slide
 */
.uk-animation-slide-top {
  animation-name: uk-fade, uk-slide-top; }

.uk-animation-slide-bottom {
  animation-name: uk-fade, uk-slide-bottom; }

.uk-animation-slide-left {
  animation-name: uk-fade, uk-slide-left; }

.uk-animation-slide-right {
  animation-name: uk-fade, uk-slide-right; }

/*
 * Slide Small
 */
.uk-animation-slide-top-small {
  animation-name: uk-fade, uk-slide-top-small; }

.uk-animation-slide-bottom-small {
  animation-name: uk-fade, uk-slide-bottom-small; }

.uk-animation-slide-left-small {
  animation-name: uk-fade, uk-slide-left-small; }

.uk-animation-slide-right-small {
  animation-name: uk-fade, uk-slide-right-small; }

/*
 * Slide Medium
 */
.uk-animation-slide-top-medium {
  animation-name: uk-fade, uk-slide-top-medium; }

.uk-animation-slide-bottom-medium {
  animation-name: uk-fade, uk-slide-bottom-medium; }

.uk-animation-slide-left-medium {
  animation-name: uk-fade, uk-slide-left-medium; }

.uk-animation-slide-right-medium {
  animation-name: uk-fade, uk-slide-right-medium; }

/*
 * Kenburns
 */
.uk-animation-kenburns {
  animation-name: uk-kenburns;
  animation-duration: 15s; }

/*
 * Shake
 */
.uk-animation-shake {
  animation-name: uk-shake; }

/*
 * SVG Stroke
 * The `--uk-animation-stroke` custom property contains the longest path length.
 * Set it manually or use `uk-svg="stroke-animation: true"` to set it automatically.
 * All strokes are animated by the same pace and doesn't end simultaneously.
 * To end simultaneously, `pathLength="1"` could be used, but it's not working in Safari yet.
 */
.uk-animation-stroke {
  animation-name: uk-stroke;
  animation-duration: 2s;
  stroke-dasharray: var(--uk-animation-stroke); }

/* Direction modifier
 ========================================================================== */
.uk-animation-reverse {
  animation-direction: reverse;
  animation-timing-function: ease-in; }

/* Duration modifier
 ========================================================================== */
.uk-animation-fast {
  animation-duration: 0.1s; }

/* Toggle animation based on the State of the Parent Element
 ========================================================================== */
.uk-animation-toggle:not(:hover):not(:focus) [class*='uk-animation-'] {
  animation-name: none; }

/* Keyframes used by animation classes
 ========================================================================== */
/*
 * Fade
 */
@keyframes uk-fade {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/*
 * Scale
 */
@keyframes uk-scale-up {
  0% {
    transform: scale(0.9); }
  100% {
    transform: scale(1); } }

@keyframes uk-scale-down {
  0% {
    transform: scale(1.1); }
  100% {
    transform: scale(1); } }

/*
 * Slide
 */
@keyframes uk-slide-top {
  0% {
    transform: translateY(-100%); }
  100% {
    transform: translateY(0); } }

@keyframes uk-slide-bottom {
  0% {
    transform: translateY(100%); }
  100% {
    transform: translateY(0); } }

@keyframes uk-slide-left {
  0% {
    transform: translateX(-100%); }
  100% {
    transform: translateX(0); } }

@keyframes uk-slide-right {
  0% {
    transform: translateX(100%); }
  100% {
    transform: translateX(0); } }

/*
 * Slide Small
 */
@keyframes uk-slide-top-small {
  0% {
    transform: translateY(-10px); }
  100% {
    transform: translateY(0); } }

@keyframes uk-slide-bottom-small {
  0% {
    transform: translateY(10px); }
  100% {
    transform: translateY(0); } }

@keyframes uk-slide-left-small {
  0% {
    transform: translateX(-10px); }
  100% {
    transform: translateX(0); } }

@keyframes uk-slide-right-small {
  0% {
    transform: translateX(10px); }
  100% {
    transform: translateX(0); } }

/*
 * Slide Medium
 */
@keyframes uk-slide-top-medium {
  0% {
    transform: translateY(-50px); }
  100% {
    transform: translateY(0); } }

@keyframes uk-slide-bottom-medium {
  0% {
    transform: translateY(50px); }
  100% {
    transform: translateY(0); } }

@keyframes uk-slide-left-medium {
  0% {
    transform: translateX(-50px); }
  100% {
    transform: translateX(0); } }

@keyframes uk-slide-right-medium {
  0% {
    transform: translateX(50px); }
  100% {
    transform: translateX(0); } }

/*
 * Kenburns
 */
@keyframes uk-kenburns {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(1.2); } }

/*
 * Shake
 */
@keyframes uk-shake {
  0%, 100% {
    transform: translateX(0); }
  10% {
    transform: translateX(-9px); }
  20% {
    transform: translateX(8px); }
  30% {
    transform: translateX(-7px); }
  40% {
    transform: translateX(6px); }
  50% {
    transform: translateX(-5px); }
  60% {
    transform: translateX(4px); }
  70% {
    transform: translateX(-3px); }
  80% {
    transform: translateX(2px); }
  90% {
    transform: translateX(-1px); } }

/*
 * Stroke
 */
@keyframes uk-stroke {
  0% {
    stroke-dashoffset: var(--uk-animation-stroke); }
  100% {
    stroke-dashoffset: 0; } }

/* ========================================================================
   Component: Width
 ========================================================================== */
/* Equal child widths
 ========================================================================== */
[class*='uk-child-width'] > * {
  box-sizing: border-box;
  width: 100%; }

.uk-child-width-1-2 > * {
  width: 50%; }

.uk-child-width-1-3 > * {
  width: calc(100% * 1 / 3.001); }

.uk-child-width-1-4 > * {
  width: 25%; }

.uk-child-width-1-5 > * {
  width: 20%; }

.uk-child-width-1-6 > * {
  width: calc(100% * 1 / 6.001); }

.uk-child-width-auto > * {
  width: auto; }

/*
 * 1. Reset the `min-width`, which is set to auto by default, because
 *    flex items won't shrink below their minimum intrinsic content size.
 *    Using `1px` instead of `0`, so items still wrap into the next line,
 *    if they have zero width and padding and the predecessor is 100% wide.
 */
.uk-child-width-expand > :not([class*='uk-width']) {
  flex: 1;
  /* 1 */
  min-width: 1px; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-child-width-1-1\@s > * {
    width: 100%; }
  .uk-child-width-1-2\@s > * {
    width: 50%; }
  .uk-child-width-1-3\@s > * {
    width: calc(100% * 1 / 3.001); }
  .uk-child-width-1-4\@s > * {
    width: 25%; }
  .uk-child-width-1-5\@s > * {
    width: 20%; }
  .uk-child-width-1-6\@s > * {
    width: calc(100% * 1 / 6.001); }
  .uk-child-width-auto\@s > * {
    width: auto; }
  .uk-child-width-expand\@s > :not([class*='uk-width']) {
    flex: 1;
    min-width: 1px; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-child-width-1-1\@m > * {
    width: 100%; }
  .uk-child-width-1-2\@m > * {
    width: 50%; }
  .uk-child-width-1-3\@m > * {
    width: calc(100% * 1 / 3.001); }
  .uk-child-width-1-4\@m > * {
    width: 25%; }
  .uk-child-width-1-5\@m > * {
    width: 20%; }
  .uk-child-width-1-6\@m > * {
    width: calc(100% * 1 / 6.001); }
  .uk-child-width-auto\@m > * {
    width: auto; }
  .uk-child-width-expand\@m > :not([class*='uk-width']) {
    flex: 1;
    min-width: 1px; } }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-child-width-1-1\@l > * {
    width: 100%; }
  .uk-child-width-1-2\@l > * {
    width: 50%; }
  .uk-child-width-1-3\@l > * {
    width: calc(100% * 1 / 3.001); }
  .uk-child-width-1-4\@l > * {
    width: 25%; }
  .uk-child-width-1-5\@l > * {
    width: 20%; }
  .uk-child-width-1-6\@l > * {
    width: calc(100% * 1 / 6.001); }
  .uk-child-width-auto\@l > * {
    width: auto; }
  .uk-child-width-expand\@l > :not([class*='uk-width']) {
    flex: 1;
    min-width: 1px; } }

/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-child-width-1-1\@xl > * {
    width: 100%; }
  .uk-child-width-1-2\@xl > * {
    width: 50%; }
  .uk-child-width-1-3\@xl > * {
    width: calc(100% * 1 / 3.001); }
  .uk-child-width-1-4\@xl > * {
    width: 25%; }
  .uk-child-width-1-5\@xl > * {
    width: 20%; }
  .uk-child-width-1-6\@xl > * {
    width: calc(100% * 1 / 6.001); }
  .uk-child-width-auto\@xl > * {
    width: auto; }
  .uk-child-width-expand\@xl > :not([class*='uk-width']) {
    flex: 1;
    min-width: 1px; } }

/* Single Widths
 ========================================================================== */
/*
 * 1. `max-width` is needed for the pixel-based classes
 */
[class*='uk-width'] {
  box-sizing: border-box;
  width: 100%;
  /* 1 */
  max-width: 100%; }

/* Halves */
.uk-width-1-2 {
  width: 50%; }

/* Thirds */
.uk-width-1-3 {
  width: calc(100% * 1 / 3.001); }

.uk-width-2-3 {
  width: calc(100% * 2 / 3.001); }

/* Quarters */
.uk-width-1-4 {
  width: 25%; }

.uk-width-3-4 {
  width: 75%; }

/* Fifths */
.uk-width-1-5 {
  width: 20%; }

.uk-width-2-5 {
  width: 40%; }

.uk-width-3-5 {
  width: 60%; }

.uk-width-4-5 {
  width: 80%; }

/* Sixths */
.uk-width-1-6 {
  width: calc(100% * 1 / 6.001); }

.uk-width-5-6 {
  width: calc(100% * 5 / 6.001); }

/* Pixel */
.uk-width-small {
  width: 150px; }

.uk-width-medium {
  width: 300px; }

.uk-width-large {
  width: 450px; }

.uk-width-xlarge {
  width: 600px; }

.uk-width-2xlarge {
  width: 750px; }

/* Auto */
.uk-width-auto {
  width: auto; }

/* Expand */
.uk-width-expand {
  flex: 1;
  min-width: 1px; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  /* Whole */
  .uk-width-1-1\@s {
    width: 100%; }
  /* Halves */
  .uk-width-1-2\@s {
    width: 50%; }
  /* Thirds */
  .uk-width-1-3\@s {
    width: calc(100% * 1 / 3.001); }
  .uk-width-2-3\@s {
    width: calc(100% * 2 / 3.001); }
  /* Quarters */
  .uk-width-1-4\@s {
    width: 25%; }
  .uk-width-3-4\@s {
    width: 75%; }
  /* Fifths */
  .uk-width-1-5\@s {
    width: 20%; }
  .uk-width-2-5\@s {
    width: 40%; }
  .uk-width-3-5\@s {
    width: 60%; }
  .uk-width-4-5\@s {
    width: 80%; }
  /* Sixths */
  .uk-width-1-6\@s {
    width: calc(100% * 1 / 6.001); }
  .uk-width-5-6\@s {
    width: calc(100% * 5 / 6.001); }
  /* Pixel */
  .uk-width-small\@s {
    width: 150px; }
  .uk-width-medium\@s {
    width: 300px; }
  .uk-width-large\@s {
    width: 450px; }
  .uk-width-xlarge\@s {
    width: 600px; }
  .uk-width-2xlarge\@s {
    width: 750px; }
  /* Auto */
  .uk-width-auto\@s {
    width: auto; }
  /* Expand */
  .uk-width-expand\@s {
    flex: 1;
    min-width: 1px; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  /* Whole */
  .uk-width-1-1\@m {
    width: 100%; }
  /* Halves */
  .uk-width-1-2\@m {
    width: 50%; }
  /* Thirds */
  .uk-width-1-3\@m {
    width: calc(100% * 1 / 3.001); }
  .uk-width-2-3\@m {
    width: calc(100% * 2 / 3.001); }
  /* Quarters */
  .uk-width-1-4\@m {
    width: 25%; }
  .uk-width-3-4\@m {
    width: 75%; }
  /* Fifths */
  .uk-width-1-5\@m {
    width: 20%; }
  .uk-width-2-5\@m {
    width: 40%; }
  .uk-width-3-5\@m {
    width: 60%; }
  .uk-width-4-5\@m {
    width: 80%; }
  /* Sixths */
  .uk-width-1-6\@m {
    width: calc(100% * 1 / 6.001); }
  .uk-width-5-6\@m {
    width: calc(100% * 5 / 6.001); }
  /* Pixel */
  .uk-width-small\@m {
    width: 150px; }
  .uk-width-medium\@m {
    width: 300px; }
  .uk-width-large\@m {
    width: 450px; }
  .uk-width-xlarge\@m {
    width: 600px; }
  .uk-width-2xlarge\@m {
    width: 750px; }
  /* Auto */
  .uk-width-auto\@m {
    width: auto; }
  /* Expand */
  .uk-width-expand\@m {
    flex: 1;
    min-width: 1px; } }

/* Desktop and bigger */
@media (min-width: 1200px) {
  /* Whole */
  .uk-width-1-1\@l {
    width: 100%; }
  /* Halves */
  .uk-width-1-2\@l {
    width: 50%; }
  /* Thirds */
  .uk-width-1-3\@l {
    width: calc(100% * 1 / 3.001); }
  .uk-width-2-3\@l {
    width: calc(100% * 2 / 3.001); }
  /* Quarters */
  .uk-width-1-4\@l {
    width: 25%; }
  .uk-width-3-4\@l {
    width: 75%; }
  /* Fifths */
  .uk-width-1-5\@l {
    width: 20%; }
  .uk-width-2-5\@l {
    width: 40%; }
  .uk-width-3-5\@l {
    width: 60%; }
  .uk-width-4-5\@l {
    width: 80%; }
  /* Sixths */
  .uk-width-1-6\@l {
    width: calc(100% * 1 / 6.001); }
  .uk-width-5-6\@l {
    width: calc(100% * 5 / 6.001); }
  /* Pixel */
  .uk-width-small\@l {
    width: 150px; }
  .uk-width-medium\@l {
    width: 300px; }
  .uk-width-large\@l {
    width: 450px; }
  .uk-width-xlarge\@l {
    width: 600px; }
  .uk-width-2xlarge\@l {
    width: 750px; }
  /* Auto */
  .uk-width-auto\@l {
    width: auto; }
  /* Expand */
  .uk-width-expand\@l {
    flex: 1;
    min-width: 1px; } }

/* Large screen and bigger */
@media (min-width: 1600px) {
  /* Whole */
  .uk-width-1-1\@xl {
    width: 100%; }
  /* Halves */
  .uk-width-1-2\@xl {
    width: 50%; }
  /* Thirds */
  .uk-width-1-3\@xl {
    width: calc(100% * 1 / 3.001); }
  .uk-width-2-3\@xl {
    width: calc(100% * 2 / 3.001); }
  /* Quarters */
  .uk-width-1-4\@xl {
    width: 25%; }
  .uk-width-3-4\@xl {
    width: 75%; }
  /* Fifths */
  .uk-width-1-5\@xl {
    width: 20%; }
  .uk-width-2-5\@xl {
    width: 40%; }
  .uk-width-3-5\@xl {
    width: 60%; }
  .uk-width-4-5\@xl {
    width: 80%; }
  /* Sixths */
  .uk-width-1-6\@xl {
    width: calc(100% * 1 / 6.001); }
  .uk-width-5-6\@xl {
    width: calc(100% * 5 / 6.001); }
  /* Pixel */
  .uk-width-small\@xl {
    width: 150px; }
  .uk-width-medium\@xl {
    width: 300px; }
  .uk-width-large\@xl {
    width: 450px; }
  .uk-width-xlarge\@xl {
    width: 600px; }
  .uk-width-2xlarge\@xl {
    width: 750px; }
  /* Auto */
  .uk-width-auto\@xl {
    width: auto; }
  /* Expand */
  .uk-width-expand\@xl {
    flex: 1;
    min-width: 1px; } }

/* Intrinsic Widths
 ========================================================================== */
.uk-width-max-content {
  width: max-content; }

.uk-width-min-content {
  width: min-content; }

/* ========================================================================
   Component: Height
 ========================================================================== */
[class*='uk-height'] {
  box-sizing: border-box; }

/*
 * Only works if parent element has a height set
 */
.uk-height-1-1 {
  height: 100%; }

/*
 * Useful to create image teasers
 */
.uk-height-viewport {
  min-height: 100vh; }

.uk-height-viewport-2 {
  min-height: 200vh; }

.uk-height-viewport-3 {
  min-height: 300vh; }

.uk-height-viewport-4 {
  min-height: 400vh; }

/*
 * Pixel
 * Useful for `overflow: auto`
 */
.uk-height-small {
  height: 150px; }

.uk-height-medium {
  height: 300px; }

.uk-height-large {
  height: 450px; }

.uk-height-max-small {
  max-height: 150px; }

.uk-height-max-medium {
  max-height: 300px; }

.uk-height-max-large {
  max-height: 450px; }

/* ========================================================================
   Component: Column
 ========================================================================== */
[class*='uk-column-'] {
  column-gap: 30px; }

/* Desktop and bigger */
@media (min-width: 1200px) {
  [class*='uk-column-'] {
    column-gap: 40px; } }

/*
 * Fix image 1px line wrapping into the next column in Chrome
 */
[class*='uk-column-'] img {
  transform: translate3d(0, 0, 0); }

/* Divider
 ========================================================================== */
/*
 * 1. Double the column gap
 */
.uk-column-divider {
  column-rule: 1px solid #e5e5e5;
  /* 1 */
  column-gap: 60px; }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-column-divider {
    column-gap: 80px; } }

/* Width modifiers
 ========================================================================== */
.uk-column-1-2 {
  column-count: 2; }

.uk-column-1-3 {
  column-count: 3; }

.uk-column-1-4 {
  column-count: 4; }

.uk-column-1-5 {
  column-count: 5; }

.uk-column-1-6 {
  column-count: 6; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-column-1-2\@s {
    column-count: 2; }
  .uk-column-1-3\@s {
    column-count: 3; }
  .uk-column-1-4\@s {
    column-count: 4; }
  .uk-column-1-5\@s {
    column-count: 5; }
  .uk-column-1-6\@s {
    column-count: 6; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-column-1-2\@m {
    column-count: 2; }
  .uk-column-1-3\@m {
    column-count: 3; }
  .uk-column-1-4\@m {
    column-count: 4; }
  .uk-column-1-5\@m {
    column-count: 5; }
  .uk-column-1-6\@m {
    column-count: 6; } }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-column-1-2\@l {
    column-count: 2; }
  .uk-column-1-3\@l {
    column-count: 3; }
  .uk-column-1-4\@l {
    column-count: 4; }
  .uk-column-1-5\@l {
    column-count: 5; }
  .uk-column-1-6\@l {
    column-count: 6; } }

/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-column-1-2\@xl {
    column-count: 2; }
  .uk-column-1-3\@xl {
    column-count: 3; }
  .uk-column-1-4\@xl {
    column-count: 4; }
  .uk-column-1-5\@xl {
    column-count: 5; }
  .uk-column-1-6\@xl {
    column-count: 6; } }

/* Make element span across all columns
 * Does not work in Firefox yet
 ========================================================================== */
.uk-column-span {
  column-span: all; }

/* ========================================================================
   Component: Cover
 ========================================================================== */
/*
 * Works with iframes and embedded content
 * 1. Use attribute to apply transform instantly. Needed if transform is transitioned.
 * 2. Reset responsiveness for embedded content
 * 3. Center object
 * Note: Percent values on the `top` property only works if this element
 *       is absolute positioned or if the container has a height
 */
/* 1 */
[uk-cover],
[data-uk-cover] {
  /* 2 */
  max-width: none;
  /* 3 */
  position: absolute;
  left: 50%;
  top: 50%;
  --uk-position-translate-x: -50%;
  --uk-position-translate-y: -50%;
  transform: translate(var(--uk-position-translate-x), var(--uk-position-translate-y)); }

iframe[uk-cover],
iframe[data-uk-cover] {
  pointer-events: none; }

/* Container
 ========================================================================== */
/*
 * 1. Parent container which clips resized object
 * 2. Needed if the child is positioned absolute. See note above
 */
.uk-cover-container {
  /* 1 */
  overflow: hidden;
  /* 2 */
  position: relative; }

/* ========================================================================
   Component: Margin
 ========================================================================== */
/*
 * Default
 */
.uk-margin {
  margin-bottom: 20px; }

* + .uk-margin {
  margin-top: 20px !important; }

.uk-margin-top {
  margin-top: 20px !important; }

.uk-margin-bottom {
  margin-bottom: 20px !important; }

.uk-margin-left {
  margin-left: 20px !important; }

.uk-margin-right {
  margin-right: 20px !important; }

/* Small
 ========================================================================== */
.uk-margin-small {
  margin-bottom: 10px; }

* + .uk-margin-small {
  margin-top: 10px !important; }

.uk-margin-small-top {
  margin-top: 10px !important; }

.uk-margin-small-bottom {
  margin-bottom: 10px !important; }

.uk-margin-small-left {
  margin-left: 10px !important; }

.uk-margin-small-right {
  margin-right: 10px !important; }

/* Medium
 ========================================================================== */
.uk-margin-medium {
  margin-bottom: 40px; }

* + .uk-margin-medium {
  margin-top: 40px !important; }

.uk-margin-medium-top {
  margin-top: 40px !important; }

.uk-margin-medium-bottom {
  margin-bottom: 40px !important; }

.uk-margin-medium-left {
  margin-left: 40px !important; }

.uk-margin-medium-right {
  margin-right: 40px !important; }

/* Large
 ========================================================================== */
.uk-margin-large {
  margin-bottom: 40px; }

* + .uk-margin-large {
  margin-top: 40px !important; }

.uk-margin-large-top {
  margin-top: 40px !important; }

.uk-margin-large-bottom {
  margin-bottom: 40px !important; }

.uk-margin-large-left {
  margin-left: 40px !important; }

.uk-margin-large-right {
  margin-right: 40px !important; }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-margin-large {
    margin-bottom: 70px; }
  * + .uk-margin-large {
    margin-top: 70px !important; }
  .uk-margin-large-top {
    margin-top: 70px !important; }
  .uk-margin-large-bottom {
    margin-bottom: 70px !important; }
  .uk-margin-large-left {
    margin-left: 70px !important; }
  .uk-margin-large-right {
    margin-right: 70px !important; } }

/* XLarge
 ========================================================================== */
.uk-margin-xlarge {
  margin-bottom: 70px; }

* + .uk-margin-xlarge {
  margin-top: 70px !important; }

.uk-margin-xlarge-top {
  margin-top: 70px !important; }

.uk-margin-xlarge-bottom {
  margin-bottom: 70px !important; }

.uk-margin-xlarge-left {
  margin-left: 70px !important; }

.uk-margin-xlarge-right {
  margin-right: 70px !important; }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-margin-xlarge {
    margin-bottom: 140px; }
  * + .uk-margin-xlarge {
    margin-top: 140px !important; }
  .uk-margin-xlarge-top {
    margin-top: 140px !important; }
  .uk-margin-xlarge-bottom {
    margin-bottom: 140px !important; }
  .uk-margin-xlarge-left {
    margin-left: 140px !important; }
  .uk-margin-xlarge-right {
    margin-right: 140px !important; } }

/* Auto
 ========================================================================== */
.uk-margin-auto {
  margin-left: auto !important;
  margin-right: auto !important; }

.uk-margin-auto-top {
  margin-top: auto !important; }

.uk-margin-auto-bottom {
  margin-bottom: auto !important; }

.uk-margin-auto-left {
  margin-left: auto !important; }

.uk-margin-auto-right {
  margin-right: auto !important; }

.uk-margin-auto-vertical {
  margin-top: auto !important;
  margin-bottom: auto !important; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-margin-auto\@s {
    margin-left: auto !important;
    margin-right: auto !important; }
  .uk-margin-auto-left\@s {
    margin-left: auto !important; }
  .uk-margin-auto-right\@s {
    margin-right: auto !important; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-margin-auto\@m {
    margin-left: auto !important;
    margin-right: auto !important; }
  .uk-margin-auto-left\@m {
    margin-left: auto !important; }
  .uk-margin-auto-right\@m {
    margin-right: auto !important; } }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-margin-auto\@l {
    margin-left: auto !important;
    margin-right: auto !important; }
  .uk-margin-auto-left\@l {
    margin-left: auto !important; }
  .uk-margin-auto-right\@l {
    margin-right: auto !important; } }

/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-margin-auto\@xl {
    margin-left: auto !important;
    margin-right: auto !important; }
  .uk-margin-auto-left\@xl {
    margin-left: auto !important; }
  .uk-margin-auto-right\@xl {
    margin-right: auto !important; } }

/* Remove
 ========================================================================== */
.uk-margin-remove {
  margin: 0 !important; }

.uk-margin-remove-top {
  margin-top: 0 !important; }

.uk-margin-remove-bottom {
  margin-bottom: 0 !important; }

.uk-margin-remove-left {
  margin-left: 0 !important; }

.uk-margin-remove-right {
  margin-right: 0 !important; }

.uk-margin-remove-vertical {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.uk-margin-remove-adjacent + *,
.uk-margin-remove-first-child > :first-child {
  margin-top: 0 !important; }

.uk-margin-remove-last-child > :last-child {
  margin-bottom: 0 !important; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-margin-remove-left\@s {
    margin-left: 0 !important; }
  .uk-margin-remove-right\@s {
    margin-right: 0 !important; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-margin-remove-left\@m {
    margin-left: 0 !important; }
  .uk-margin-remove-right\@m {
    margin-right: 0 !important; } }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-margin-remove-left\@l {
    margin-left: 0 !important; }
  .uk-margin-remove-right\@l {
    margin-right: 0 !important; } }

/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-margin-remove-left\@xl {
    margin-left: 0 !important; }
  .uk-margin-remove-right\@xl {
    margin-right: 0 !important; } }

/* ========================================================================
   Component: Padding
 ========================================================================== */
.uk-padding {
  padding: 30px; }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-padding {
    padding: 40px; } }

/* Small
 ========================================================================== */
.uk-padding-small {
  padding: 15px; }

/* Large
 ========================================================================== */
.uk-padding-large {
  padding: 40px; }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-padding-large {
    padding: 70px; } }

/* Remove
 ========================================================================== */
.uk-padding-remove {
  padding: 0 !important; }

.uk-padding-remove-top {
  padding-top: 0 !important; }

.uk-padding-remove-bottom {
  padding-bottom: 0 !important; }

.uk-padding-remove-left {
  padding-left: 0 !important; }

.uk-padding-remove-right {
  padding-right: 0 !important; }

.uk-padding-remove-vertical {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.uk-padding-remove-horizontal {
  padding-left: 0 !important;
  padding-right: 0 !important; }

/* ========================================================================
   Component: Transition
 ========================================================================== */
/* Transitions
 ========================================================================== */
/*
 * The toggle is triggered on touch devices by two methods:
 * 1. Using `:focus` and tabindex
 * 2. Using `:hover` and a `touchstart` event listener registered on the document
 *    (Doesn't work on Surface touch devices)
 */
:where(.uk-transition-fade),
:where([class*='uk-transition-scale']),
:where([class*='uk-transition-slide']) {
  --uk-position-translate-x: 0;
  --uk-position-translate-y: 0; }

.uk-transition-fade,
[class*='uk-transition-scale'],
[class*='uk-transition-slide'] {
  --uk-translate-x: 0;
  --uk-translate-y: 0;
  --uk-scale-x: 1;
  --uk-scale-y: 1;
  transform: translate(var(--uk-position-translate-x), var(--uk-position-translate-y)) translate(var(--uk-translate-x), var(--uk-translate-y)) scale(var(--uk-scale-x), var(--uk-scale-y));
  transition: 0.3s ease-out;
  transition-property: opacity, transform, filter;
  opacity: 0; }

/*
 * Fade
 */
.uk-transition-toggle:hover .uk-transition-fade,
.uk-transition-toggle:focus .uk-transition-fade,
.uk-transition-toggle .uk-transition-fade:focus-within,
.uk-transition-active.uk-active .uk-transition-fade {
  opacity: 1; }

/*
 * Scale
 * 1. Make image rendering the same during the transition as before and after. Prefixed because of Safari.
 */
/* 1 */
[class*='uk-transition-scale'] {
  -webkit-backface-visibility: hidden; }

.uk-transition-scale-up {
  --uk-scale-x: 1;
  --uk-scale-y: 1; }

.uk-transition-scale-down {
  --uk-scale-x: 1.03;
  --uk-scale-y: 1.03; }

/* Show */
.uk-transition-toggle:hover .uk-transition-scale-up,
.uk-transition-toggle:focus .uk-transition-scale-up,
.uk-transition-toggle .uk-transition-scale-up:focus-within,
.uk-transition-active.uk-active .uk-transition-scale-up {
  --uk-scale-x: 1.03;
  --uk-scale-y: 1.03;
  opacity: 1; }

.uk-transition-toggle:hover .uk-transition-scale-down,
.uk-transition-toggle:focus .uk-transition-scale-down,
.uk-transition-toggle .uk-transition-scale-down:focus-within,
.uk-transition-active.uk-active .uk-transition-scale-down {
  --uk-scale-x: 1;
  --uk-scale-y: 1;
  opacity: 1; }

/*
 * Slide
 */
.uk-transition-slide-top {
  --uk-translate-y: -100%; }

.uk-transition-slide-bottom {
  --uk-translate-y: 100%; }

.uk-transition-slide-left {
  --uk-translate-x: -100%; }

.uk-transition-slide-right {
  --uk-translate-x: 100%; }

.uk-transition-slide-top-small {
  --uk-translate-y: calc(-1 * 10px); }

.uk-transition-slide-bottom-small {
  --uk-translate-y: 10px; }

.uk-transition-slide-left-small {
  --uk-translate-x: calc(-1 * 10px); }

.uk-transition-slide-right-small {
  --uk-translate-x: 10px; }

.uk-transition-slide-top-medium {
  --uk-translate-y: calc(-1 * 50px); }

.uk-transition-slide-bottom-medium {
  --uk-translate-y: 50px; }

.uk-transition-slide-left-medium {
  --uk-translate-x: calc(-1 * 50px); }

.uk-transition-slide-right-medium {
  --uk-translate-x: 50px; }

/* Show */
.uk-transition-toggle:hover [class*='uk-transition-slide'],
.uk-transition-toggle:focus [class*='uk-transition-slide'],
.uk-transition-toggle [class*='uk-transition-slide']:focus-within,
.uk-transition-active.uk-active [class*='uk-transition-slide'] {
  --uk-translate-x: 0;
  --uk-translate-y: 0;
  opacity: 1; }

/* Opacity modifier
 ========================================================================== */
.uk-transition-opaque {
  opacity: 1; }

/* Duration modifiers
 ========================================================================== */
.uk-transition-slow {
  transition-duration: 0.7s; }

/* ========================================================================
   Component: Position
 ========================================================================== */
:root {
  --uk-position-margin-offset: 0px; }

/* Directions
 ========================================================================== */
/*
 * 1. Prevent content overflow.
 */
[class*='uk-position-top'],
[class*='uk-position-bottom'],
[class*='uk-position-left'],
[class*='uk-position-right'],
[class*='uk-position-center'] {
  position: absolute !important;
  /* 1 */
  max-width: calc(100% - (var(--uk-position-margin-offset) * 2));
  box-sizing: border-box; }

/*
 * Edges
 * Don't use `width: 100%` because it's wrong if the parent has padding.
 */
.uk-position-top {
  top: 0;
  left: 0;
  right: 0; }

.uk-position-bottom {
  bottom: 0;
  left: 0;
  right: 0; }

.uk-position-left {
  top: 0;
  bottom: 0;
  left: 0; }

.uk-position-right {
  top: 0;
  bottom: 0;
  right: 0; }

/*
 * Corners
 */
.uk-position-top-left {
  top: 0;
  left: 0; }

.uk-position-top-right {
  top: 0;
  right: 0; }

.uk-position-bottom-left {
  bottom: 0;
  left: 0; }

.uk-position-bottom-right {
  bottom: 0;
  right: 0; }

/*
 * Center
 * 1. Fix text wrapping if content is larger than 50% of the container.
 *    Using `max-content` requires `max-width` of 100% which is set generally.
 */
.uk-position-center {
  top: calc(50% - var(--uk-position-margin-offset));
  left: calc(50% - var(--uk-position-margin-offset));
  --uk-position-translate-x: -50%;
  --uk-position-translate-y: -50%;
  transform: translate(var(--uk-position-translate-x), var(--uk-position-translate-y));
  /* 1 */
  width: max-content; }

/* Vertical */
[class*='uk-position-center-left'],
[class*='uk-position-center-right'] {
  top: calc(50% - var(--uk-position-margin-offset));
  --uk-position-translate-y: -50%;
  transform: translate(0, var(--uk-position-translate-y)); }

.uk-position-center-left {
  left: 0; }

.uk-position-center-right {
  right: 0; }

.uk-position-center-left-out {
  right: 100%;
  width: max-content; }

.uk-position-center-right-out {
  left: 100%;
  width: max-content; }

/* Horizontal */
.uk-position-top-center,
.uk-position-bottom-center {
  left: calc(50% - var(--uk-position-margin-offset));
  --uk-position-translate-x: -50%;
  transform: translate(var(--uk-position-translate-x), 0);
  /* 1 */
  width: max-content; }

.uk-position-top-center {
  top: 0; }

.uk-position-bottom-center {
  bottom: 0; }

/*
 * Cover
 */
.uk-position-cover {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

/* Margin
 ========================================================================== */
.uk-position-small {
  margin: 15px;
  --uk-position-margin-offset: 15px; }

.uk-position-medium {
  margin: 30px;
  --uk-position-margin-offset: 30px; }

.uk-position-large {
  margin: 30px;
  --uk-position-margin-offset: 30px; }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-position-large {
    margin: 50px;
    --uk-position-margin-offset: 50px; } }

/* Schemes
 ========================================================================== */
.uk-position-relative {
  position: relative  !important; }

.uk-position-absolute {
  position: absolute  !important; }

.uk-position-fixed {
  position: fixed  !important; }

.uk-position-sticky {
  position: sticky  !important; }

/* Layer
 ========================================================================== */
.uk-position-z-index {
  z-index: 1; }

.uk-position-z-index-zero {
  z-index: 0; }

.uk-position-z-index-negative {
  z-index: -1; }

/* ========================================================================
   Component: Flex
 ========================================================================== */
.uk-flex {
  display: flex; }

.uk-flex-inline {
  display: inline-flex; }

/* Alignment
 ========================================================================== */
/*
 * Align items along the main axis of the current line of the flex container
 * Row: Horizontal
 */
.uk-flex-left {
  justify-content: flex-start; }

.uk-flex-center {
  justify-content: center; }

.uk-flex-right {
  justify-content: flex-end; }

.uk-flex-between {
  justify-content: space-between; }

.uk-flex-around {
  justify-content: space-around; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-flex-left\@s {
    justify-content: flex-start; }
  .uk-flex-center\@s {
    justify-content: center; }
  .uk-flex-right\@s {
    justify-content: flex-end; }
  .uk-flex-between\@s {
    justify-content: space-between; }
  .uk-flex-around\@s {
    justify-content: space-around; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-flex-left\@m {
    justify-content: flex-start; }
  .uk-flex-center\@m {
    justify-content: center; }
  .uk-flex-right\@m {
    justify-content: flex-end; }
  .uk-flex-between\@m {
    justify-content: space-between; }
  .uk-flex-around\@m {
    justify-content: space-around; } }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-flex-left\@l {
    justify-content: flex-start; }
  .uk-flex-center\@l {
    justify-content: center; }
  .uk-flex-right\@l {
    justify-content: flex-end; }
  .uk-flex-between\@l {
    justify-content: space-between; }
  .uk-flex-around\@l {
    justify-content: space-around; } }

/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-flex-left\@xl {
    justify-content: flex-start; }
  .uk-flex-center\@xl {
    justify-content: center; }
  .uk-flex-right\@xl {
    justify-content: flex-end; }
  .uk-flex-between\@xl {
    justify-content: space-between; }
  .uk-flex-around\@xl {
    justify-content: space-around; } }

/*
 * Align items in the cross axis of the current line of the flex container
 * Row: Vertical
 */
.uk-flex-stretch {
  align-items: stretch; }

.uk-flex-top {
  align-items: flex-start; }

.uk-flex-middle {
  align-items: center; }

.uk-flex-bottom {
  align-items: flex-end; }

/* Direction
 ========================================================================== */
.uk-flex-row {
  flex-direction: row; }

.uk-flex-row-reverse {
  flex-direction: row-reverse; }

.uk-flex-column {
  flex-direction: column; }

.uk-flex-column-reverse {
  flex-direction: column-reverse; }

/* Wrap
 ========================================================================== */
.uk-flex-nowrap {
  flex-wrap: nowrap; }

.uk-flex-wrap {
  flex-wrap: wrap; }

.uk-flex-wrap-reverse {
  flex-wrap: wrap-reverse; }

/*
 * Aligns items within the flex container when there is extra space in the cross-axis
 * Only works if there is more than one line of flex items
 */
.uk-flex-wrap-stretch {
  align-content: stretch; }

.uk-flex-wrap-top {
  align-content: flex-start; }

.uk-flex-wrap-middle {
  align-content: center; }

.uk-flex-wrap-bottom {
  align-content: flex-end; }

.uk-flex-wrap-between {
  align-content: space-between; }

.uk-flex-wrap-around {
  align-content: space-around; }

/* Item ordering
 ========================================================================== */
/*
 * Default is 0
 */
.uk-flex-first {
  order: -1; }

.uk-flex-last {
  order: 99; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-flex-first\@s {
    order: -1; }
  .uk-flex-last\@s {
    order: 99; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-flex-first\@m {
    order: -1; }
  .uk-flex-last\@m {
    order: 99; } }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-flex-first\@l {
    order: -1; }
  .uk-flex-last\@l {
    order: 99; } }

/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-flex-first\@xl {
    order: -1; }
  .uk-flex-last\@xl {
    order: 99; } }

/* Item dimensions
 ========================================================================== */
/*
 * Initial: 0 1 auto
 * Content dimensions, but shrinks
 */
/*
 * No Flex: 0 0 auto
 * Content dimensions
 */
.uk-flex-none {
  flex: none; }

/*
 * Relative Flex: 1 1 auto
 * Space is allocated considering content
 */
.uk-flex-auto {
  flex: auto; }

/*
 * Absolute Flex: 1 1 0%
 * Space is allocated solely based on flex
 */
.uk-flex-1 {
  flex: 1; }

/* ========================================================================
   Component: Background
 ========================================================================== */
/* Color
 ========================================================================== */
.uk-background-default {
  background-color: #fff; }

.uk-background-muted {
  background-color: #f8f8f8; }

.uk-background-primary {
  background-color: #1e87f0; }

.uk-background-secondary {
  background-color: #222; }

/* Size
 ========================================================================== */
.uk-background-cover,
.uk-background-contain,
.uk-background-width-1-1,
.uk-background-height-1-1 {
  background-position: 50% 50%;
  background-repeat: no-repeat; }

.uk-background-cover {
  background-size: cover; }

.uk-background-contain {
  background-size: contain; }

.uk-background-width-1-1 {
  background-size: 100%; }

.uk-background-height-1-1 {
  background-size: auto 100%; }

/* Position
 ========================================================================== */
.uk-background-top-left {
  background-position: 0 0; }

.uk-background-top-center {
  background-position: 50% 0; }

.uk-background-top-right {
  background-position: 100% 0; }

.uk-background-center-left {
  background-position: 0 50%; }

.uk-background-center-center {
  background-position: 50% 50%; }

.uk-background-center-right {
  background-position: 100% 50%; }

.uk-background-bottom-left {
  background-position: 0 100%; }

.uk-background-bottom-center {
  background-position: 50% 100%; }

.uk-background-bottom-right {
  background-position: 100% 100%; }

/* Repeat
 ========================================================================== */
.uk-background-norepeat {
  background-repeat: no-repeat; }

/* Attachment
 ========================================================================== */
/*
 * 1. Fix bug introduced in Chrome 67: the background image is not visible if any element on the page uses `translate3d`
 */
.uk-background-fixed {
  background-attachment: fixed;
  /* 1 */
  backface-visibility: hidden; }

/*
 * Exclude touch devices because `fixed` doesn't work on iOS and Android
 */
@media (pointer: coarse) {
  .uk-background-fixed {
    background-attachment: scroll; } }

/* Image
 ========================================================================== */
/* Phone portrait and smaller */
@media (max-width: 639px) {
  .uk-background-image\@s {
    background-image: none !important; } }

/* Phone landscape and smaller */
@media (max-width: 959px) {
  .uk-background-image\@m {
    background-image: none !important; } }

/* Tablet landscape and smaller */
@media (max-width: 1199px) {
  .uk-background-image\@l {
    background-image: none !important; } }

/* Desktop and smaller */
@media (max-width: 1599px) {
  .uk-background-image\@xl {
    background-image: none !important; } }

/* Blend modes
 ========================================================================== */
.uk-background-blend-multiply {
  background-blend-mode: multiply; }

.uk-background-blend-screen {
  background-blend-mode: screen; }

.uk-background-blend-overlay {
  background-blend-mode: overlay; }

.uk-background-blend-darken {
  background-blend-mode: darken; }

.uk-background-blend-lighten {
  background-blend-mode: lighten; }

.uk-background-blend-color-dodge {
  background-blend-mode: color-dodge; }

.uk-background-blend-color-burn {
  background-blend-mode: color-burn; }

.uk-background-blend-hard-light {
  background-blend-mode: hard-light; }

.uk-background-blend-soft-light {
  background-blend-mode: soft-light; }

.uk-background-blend-difference {
  background-blend-mode: difference; }

.uk-background-blend-exclusion {
  background-blend-mode: exclusion; }

.uk-background-blend-hue {
  background-blend-mode: hue; }

.uk-background-blend-saturation {
  background-blend-mode: saturation; }

.uk-background-blend-color {
  background-blend-mode: color; }

.uk-background-blend-luminosity {
  background-blend-mode: luminosity; }

/* ========================================================================
   Component: Visibility
 ========================================================================== */
/*
 * Hidden
 * `hidden` attribute also set here to make it stronger
 */
[hidden],
.uk-hidden {
  display: none !important; }

/* Phone landscape and bigger */
@media (min-width: 640px) {
  .uk-hidden\@s {
    display: none !important; } }

/* Tablet landscape and bigger */
@media (min-width: 960px) {
  .uk-hidden\@m {
    display: none !important; } }

/* Desktop and bigger */
@media (min-width: 1200px) {
  .uk-hidden\@l {
    display: none !important; } }

/* Large screen and bigger */
@media (min-width: 1600px) {
  .uk-hidden\@xl {
    display: none !important; } }

/*
 * Visible
 */
/* Phone portrait and smaller */
@media (max-width: 639px) {
  .uk-visible\@s {
    display: none !important; } }

/* Phone landscape and smaller */
@media (max-width: 959px) {
  .uk-visible\@m {
    display: none !important; } }

/* Tablet landscape and smaller */
@media (max-width: 1199px) {
  .uk-visible\@l {
    display: none !important; } }

/* Desktop and smaller */
@media (max-width: 1599px) {
  .uk-visible\@xl {
    display: none !important; } }

/* Visibility
 ========================================================================== */
.uk-invisible {
  visibility: hidden !important; }

/* Based on the State of the Parent Element
 ========================================================================== */
/*
 * Can't use `display: none` nor `visibility: hidden` because both are not focusable.
 * The target stays visible if any element within receives focus through keyboard.
 */
/*
 * Discard space when hidden.
 */
.uk-visible-toggle:not(:hover):not(:focus) .uk-hidden-hover:not(:focus-within) {
  position: absolute !important;
  width: 0 !important;
  height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden !important; }

/*
 * Keep space when hidden.
 */
.uk-visible-toggle:not(:hover):not(:focus) .uk-invisible-hover:not(:focus-within) {
  opacity: 0 !important; }

/* Based on Hover Capability of the Pointing Device
 ========================================================================== */
/*
 * Hover
 */
/* Hide if primary pointing device doesn't support hover, e.g. touch screens. */
@media (hover: none) {
  .uk-hidden-touch {
    display: none !important; } }

/* Hide if primary pointing device supports hover, e.g. mice. */
@media (hover) {
  .uk-hidden-notouch {
    display: none !important; } }

/* ========================================================================
   Component: Utility
 ========================================================================== */
/* Panel
 ========================================================================== */
.uk-panel {
  display: flow-root;
  position: relative;
  box-sizing: border-box; }

/*
 * Remove margin from the last-child
 */
.uk-panel > :last-child {
  margin-bottom: 0; }

/*
 * Scrollable
 */
.uk-panel-scrollable {
  height: 170px;
  padding: 10px;
  border: 1px solid #e5e5e5;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  resize: both; }

/* Clearfix
 ========================================================================== */
/*
 * 1. `table-cell` is used with `::before` because `table` creates a 1px gap when it becomes a flex item, only in Webkit
 * 2. `table` is used again with `::after` because `clear` only works with block elements.
 * Note: `display: block` with `overflow: hidden` is currently not working in the latest Safari
 */
/* 1 */
.uk-clearfix::before {
  content: "";
  display: table-cell; }

/* 2 */
.uk-clearfix::after {
  content: "";
  display: table;
  clear: both; }

/* Float
 ========================================================================== */
/*
 * 1. Prevent content overflow
 */
.uk-float-left {
  float: left; }

.uk-float-right {
  float: right; }

/* 1 */
[class*='uk-float-'] {
  max-width: 100%; }

/* Overfow
 ========================================================================== */
.uk-overflow-hidden {
  overflow: hidden; }

/*
 * Enable scrollbars if content is clipped
 */
.uk-overflow-auto {
  overflow: auto;
  -webkit-overflow-scrolling: touch; }

.uk-overflow-auto > :last-child {
  margin-bottom: 0; }

/* Box Sizing
 ========================================================================== */
.uk-box-sizing-content {
  box-sizing: content-box; }

.uk-box-sizing-border {
  box-sizing: border-box; }

/* Resize
 ========================================================================== */
.uk-resize {
  resize: both; }

.uk-resize-horizontal {
  resize: horizontal; }

.uk-resize-vertical {
  resize: vertical; }

/* Display
 ========================================================================== */
.uk-display-block {
  display: block !important; }

.uk-display-inline {
  display: inline !important; }

.uk-display-inline-block {
  display: inline-block !important; }

/* Inline
 ========================================================================== */
/*
 * 1. Container fits its content
 * 2. Create position context
 * 3. Prevent content overflow
 * 4. Behave like most inline-block elements
 * 5. Force new layer without creating a new stacking context
 *    to fix 1px glitch when combined with overlays and transitions in Webkit
 * 6. Clip child elements
 */
[class*='uk-inline'] {
  /* 1 */
  display: inline-block;
  /* 2 */
  position: relative;
  /* 3 */
  max-width: 100%;
  /* 4 */
  vertical-align: middle;
  /* 5 */
  -webkit-backface-visibility: hidden; }

.uk-inline-clip {
  /* 6 */
  overflow: hidden; }

/* Responsive objects
 ========================================================================== */
/*
 * Preserve original dimensions
 * Because `img, `video`, `canvas` and  `audio` are already responsive by default, see Base component
 */
.uk-preserve-width,
.uk-preserve-width canvas,
.uk-preserve-width img,
.uk-preserve-width svg,
.uk-preserve-width video {
  max-width: none; }

/*
 * Responsiveness
 * Corrects `max-width` and `max-height` behavior if padding and border are used
 */
.uk-responsive-width,
.uk-responsive-height {
  box-sizing: border-box; }

/*
 * 1. Set a maximum width. `important` needed to override `uk-preserve-width img`
 * 2. Auto scale the height. Only needed if `height` attribute is present
 */
.uk-responsive-width {
  /* 1 */
  max-width: 100% !important;
  /* 2 */
  height: auto; }

/*
 * 1. Set a maximum height. Only works if the parent element has a fixed height
 * 2. Auto scale the width. Only needed if `width` attribute is present
 * 3. Reset max-width, which `img, `video`, `canvas` and  `audio` already have by default
 */
.uk-responsive-height {
  /* 1 */
  max-height: 100%;
  /* 2 */
  width: auto;
  /* 3 */
  max-width: none; }

/*
 * Fix initial iframe width. Without the viewport is expanded on iOS devices
 */
[uk-responsive],
[data-uk-responsive] {
  max-width: 100%; }

/* Object
 ========================================================================== */
.uk-object-cover {
  object-fit: cover; }

.uk-object-contain {
  object-fit: contain; }

.uk-object-fill {
  object-fit: fill; }

.uk-object-none {
  object-fit: none; }

.uk-object-scale-down {
  object-fit: scale-down; }

/* 
 * Position
 */
.uk-object-top-left {
  object-position: 0 0; }

.uk-object-top-center {
  object-position: 50% 0; }

.uk-object-top-right {
  object-position: 100% 0; }

.uk-object-center-left {
  object-position: 0 50%; }

.uk-object-center-center {
  object-position: 50% 50%; }

.uk-object-center-right {
  object-position: 100% 50%; }

.uk-object-bottom-left {
  object-position: 0 100%; }

.uk-object-bottom-center {
  object-position: 50% 100%; }

.uk-object-bottom-right {
  object-position: 100% 100%; }

/* Border
 ========================================================================== */
.uk-border-circle {
  border-radius: 50%; }

.uk-border-pill {
  border-radius: 500px; }

.uk-border-rounded {
  border-radius: 5px; }

/*
 * Fix `overflow: hidden` to be ignored with border-radius and CSS transforms in Webkit
 */
.uk-inline-clip[class*='uk-border-'] {
  -webkit-transform: translateZ(0); }

/* Box-shadow
 ========================================================================== */
.uk-box-shadow-small {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08); }

.uk-box-shadow-medium {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08); }

.uk-box-shadow-large {
  box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16); }

.uk-box-shadow-xlarge {
  box-shadow: 0 28px 50px rgba(0, 0, 0, 0.16); }

/*
 * Hover
 */
[class*='uk-box-shadow-hover'] {
  transition: box-shadow 0.1s ease-in-out; }

.uk-box-shadow-hover-small:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08); }

.uk-box-shadow-hover-medium:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08); }

.uk-box-shadow-hover-large:hover {
  box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16); }

.uk-box-shadow-hover-xlarge:hover {
  box-shadow: 0 28px 50px rgba(0, 0, 0, 0.16); }

/* Box-shadow bottom
 ========================================================================== */
/*
 * 1. Set position.
 * 2. Set style
 * 3. Fix shadow being clipped in Safari if container is animated
 */
@supports (filter: blur(0)) {
  .uk-box-shadow-bottom {
    display: inline-block;
    position: relative;
    z-index: 0;
    max-width: 100%;
    vertical-align: middle; }
  .uk-box-shadow-bottom::after {
    content: "";
    /* 1 */
    position: absolute;
    bottom: -30px;
    left: 0;
    right: 0;
    z-index: -1;
    /* 2 */
    height: 30px;
    border-radius: 100%;
    background: #444;
    filter: blur(20px);
    /* 3 */
    will-change: filter; } }

/* Drop cap
 ========================================================================== */
/*
 * 1. Firefox doesn't apply `::first-letter` if the first letter is inside child elements
 *    https://bugzilla.mozilla.org/show_bug.cgi?id=214004
 * 2. In Firefox, a floating `::first-letter` doesn't have a line box and there for no `line-height`
 *    https://bugzilla.mozilla.org/show_bug.cgi?id=317933
 */
.uk-dropcap::first-letter,
.uk-dropcap > p:first-of-type::first-letter {
  display: block;
  margin-right: 10px;
  float: left;
  font-size: 4.5em;
  line-height: 1; }

/* 2 */
@-moz-document url-prefix() {
  .uk-dropcap::first-letter,
  .uk-dropcap > p:first-of-type::first-letter {
    margin-top: 1.1%; } }

/* Logo
 ========================================================================== */
/*
 * 1. Style
 * 2. Required for `a`
 * 3. Behave like image but can be overridden through flex utility classes
 */
.uk-logo {
  /* 1 */
  font-size: 1.5rem;
  font-family: "Roboto", sans-serif;
  color: #333;
  /* 2 */
  text-decoration: none; }

/* 3 */
:where(.uk-logo) {
  display: inline-block;
  vertical-align: middle; }

/* Hover */
.uk-logo:hover {
  color: #333;
  /* 1 */
  text-decoration: none; }

.uk-logo :where(img, svg, video) {
  display: block; }

.uk-logo-inverse {
  display: none; }

/* Disabled State
 ========================================================================== */
.uk-disabled {
  pointer-events: none; }

/* Drag State
 ========================================================================== */
/*
 * 1. Needed if moving over elements with have their own cursor on hover, e.g. links or buttons
 * 2. Fix dragging over iframes
 */
.uk-drag,
.uk-drag * {
  cursor: move; }

/* 2 */
.uk-drag iframe {
  pointer-events: none; }

/* Dragover State
 ========================================================================== */
/*
 * Create a box-shadow when dragging a file over the upload area
 */
.uk-dragover {
  box-shadow: 0 0 20px rgba(100, 100, 100, 0.3); }

/* Blend modes
 ========================================================================== */
.uk-blend-multiply {
  mix-blend-mode: multiply; }

.uk-blend-screen {
  mix-blend-mode: screen; }

.uk-blend-overlay {
  mix-blend-mode: overlay; }

.uk-blend-darken {
  mix-blend-mode: darken; }

.uk-blend-lighten {
  mix-blend-mode: lighten; }

.uk-blend-color-dodge {
  mix-blend-mode: color-dodge; }

.uk-blend-color-burn {
  mix-blend-mode: color-burn; }

.uk-blend-hard-light {
  mix-blend-mode: hard-light; }

.uk-blend-soft-light {
  mix-blend-mode: soft-light; }

.uk-blend-difference {
  mix-blend-mode: difference; }

.uk-blend-exclusion {
  mix-blend-mode: exclusion; }

.uk-blend-hue {
  mix-blend-mode: hue; }

.uk-blend-saturation {
  mix-blend-mode: saturation; }

.uk-blend-color {
  mix-blend-mode: color; }

.uk-blend-luminosity {
  mix-blend-mode: luminosity; }

/* Transform
========================================================================== */
.uk-transform-center {
  transform: translate(-50%, -50%); }

/* Transform Origin
========================================================================== */
.uk-transform-origin-top-left {
  transform-origin: 0 0; }

.uk-transform-origin-top-center {
  transform-origin: 50% 0; }

.uk-transform-origin-top-right {
  transform-origin: 100% 0; }

.uk-transform-origin-center-left {
  transform-origin: 0 50%; }

.uk-transform-origin-center-right {
  transform-origin: 100% 50%; }

.uk-transform-origin-bottom-left {
  transform-origin: 0 100%; }

.uk-transform-origin-bottom-center {
  transform-origin: 50% 100%; }

.uk-transform-origin-bottom-right {
  transform-origin: 100% 100%; }

/* required styles */
.leaflet-pane,
.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-tile-container,
.leaflet-pane > svg,
.leaflet-pane > canvas,
.leaflet-zoom-box,
.leaflet-image-layer,
.leaflet-layer {
  position: absolute;
  left: 0;
  top: 0; }

.leaflet-container {
  overflow: hidden; }

.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-user-drag: none; }

/* Prevents IE11 from highlighting tiles in blue */
.leaflet-tile::selection {
  background: transparent; }

/* Safari renders non-retina tile on retina better with this, but Chrome is worse */
.leaflet-safari .leaflet-tile {
  image-rendering: -webkit-optimize-contrast; }

/* hack that prevents hw layers "stretching" when loading new tiles */
.leaflet-safari .leaflet-tile-container {
  width: 1600px;
  height: 1600px;
  -webkit-transform-origin: 0 0; }

.leaflet-marker-icon,
.leaflet-marker-shadow {
  display: block; }

/* .leaflet-container svg: reset svg max-width decleration shipped in Joomla! (joomla.org) 3.x */
/* .leaflet-container img: map is broken in FF if you have max-width: 100% on tiles */
.leaflet-container .leaflet-overlay-pane svg,
.leaflet-container .leaflet-marker-pane img,
.leaflet-container .leaflet-shadow-pane img,
.leaflet-container .leaflet-tile-pane img,
.leaflet-container img.leaflet-image-layer,
.leaflet-container .leaflet-tile {
  max-width: none !important;
  max-height: none !important; }

.leaflet-container.leaflet-touch-zoom {
  -ms-touch-action: pan-x pan-y;
  touch-action: pan-x pan-y; }

.leaflet-container.leaflet-touch-drag {
  -ms-touch-action: pinch-zoom;
  /* Fallback for FF which doesn't support pinch-zoom */
  touch-action: none;
  touch-action: pinch-zoom; }

.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  -ms-touch-action: none;
  touch-action: none; }

.leaflet-container {
  -webkit-tap-highlight-color: transparent; }

.leaflet-container a {
  -webkit-tap-highlight-color: rgba(51, 181, 229, 0.4); }

.leaflet-tile {
  filter: inherit;
  visibility: hidden; }

.leaflet-tile-loaded {
  visibility: inherit; }

.leaflet-zoom-box {
  width: 0;
  height: 0;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 800; }

/* workaround for https://bugzilla.mozilla.org/show_bug.cgi?id=888319 */
.leaflet-overlay-pane svg {
  -moz-user-select: none; }

.leaflet-pane {
  z-index: 400; }

.leaflet-tile-pane {
  z-index: 200; }

.leaflet-overlay-pane {
  z-index: 400; }

.leaflet-shadow-pane {
  z-index: 500; }

.leaflet-marker-pane {
  z-index: 600; }

.leaflet-tooltip-pane {
  z-index: 650; }

.leaflet-popup-pane {
  z-index: 700; }

.leaflet-map-pane canvas {
  z-index: 100; }

.leaflet-map-pane svg {
  z-index: 200; }

.leaflet-vml-shape {
  width: 1px;
  height: 1px; }

.lvml {
  behavior: url(#default#VML);
  display: inline-block;
  position: absolute; }

/* control positioning */
.leaflet-control {
  position: relative;
  z-index: 800;
  pointer-events: visiblePainted;
  /* IE 9-10 doesn't have auto */
  pointer-events: auto; }

.leaflet-top,
.leaflet-bottom {
  position: absolute;
  z-index: 1000;
  pointer-events: none; }

.leaflet-top {
  top: 0; }

.leaflet-right {
  right: 0; }

.leaflet-bottom {
  bottom: 0; }

.leaflet-left {
  left: 0; }

.leaflet-control {
  float: left;
  clear: both; }

.leaflet-right .leaflet-control {
  float: right; }

.leaflet-top .leaflet-control {
  margin-top: 10px; }

.leaflet-bottom .leaflet-control {
  margin-bottom: 10px; }

.leaflet-left .leaflet-control {
  margin-left: 10px; }

.leaflet-right .leaflet-control {
  margin-right: 10px; }

/* zoom and fade animations */
.leaflet-fade-anim .leaflet-tile {
  will-change: opacity; }

.leaflet-fade-anim .leaflet-popup {
  opacity: 0;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear; }

.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  opacity: 1; }

.leaflet-zoom-animated {
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0; }

.leaflet-zoom-anim .leaflet-zoom-animated {
  will-change: transform; }

.leaflet-zoom-anim .leaflet-zoom-animated {
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1); }

.leaflet-zoom-anim .leaflet-tile,
.leaflet-pan-anim .leaflet-tile {
  -webkit-transition: none;
  -moz-transition: none;
  transition: none; }

.leaflet-zoom-anim .leaflet-zoom-hide {
  visibility: hidden; }

/* cursors */
.leaflet-interactive {
  cursor: pointer; }

.leaflet-grab {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab; }

.leaflet-crosshair,
.leaflet-crosshair .leaflet-interactive {
  cursor: crosshair; }

.leaflet-popup-pane,
.leaflet-control {
  cursor: auto; }

.leaflet-dragging .leaflet-grab,
.leaflet-dragging .leaflet-grab .leaflet-interactive,
.leaflet-dragging .leaflet-marker-draggable {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing; }

/* marker & overlays interactivity */
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-image-layer,
.leaflet-pane > svg path,
.leaflet-tile-container {
  pointer-events: none; }

.leaflet-marker-icon.leaflet-interactive,
.leaflet-image-layer.leaflet-interactive,
.leaflet-pane > svg path.leaflet-interactive,
svg.leaflet-image-layer.leaflet-interactive path {
  pointer-events: visiblePainted;
  /* IE 9-10 doesn't have auto */
  pointer-events: auto; }

/* visual tweaks */
.leaflet-container {
  background: #ddd;
  outline: 0; }

.leaflet-container a {
  color: #0078A8; }

.leaflet-container a.leaflet-active {
  outline: 2px solid orange; }

.leaflet-zoom-box {
  border: 2px dotted #38f;
  background: rgba(255, 255, 255, 0.5); }

/* general typography */
.leaflet-container {
  font: 12px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif; }

/* general toolbar styles */
.leaflet-bar {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  border-radius: 4px; }

.leaflet-bar a,
.leaflet-bar a:hover {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  width: 26px;
  height: 26px;
  line-height: 26px;
  display: block;
  text-align: center;
  text-decoration: none;
  color: black; }

.leaflet-bar a,
.leaflet-control-layers-toggle {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: block; }

.leaflet-bar a:hover {
  background-color: #f4f4f4; }

.leaflet-bar a:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px; }

.leaflet-bar a:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom: none; }

.leaflet-bar a.leaflet-disabled {
  cursor: default;
  background-color: #f4f4f4;
  color: #bbb; }

.leaflet-touch .leaflet-bar a {
  width: 30px;
  height: 30px;
  line-height: 30px; }

.leaflet-touch .leaflet-bar a:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px; }

.leaflet-touch .leaflet-bar a:last-child {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px; }

/* zoom control */
.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  font: bold 18px 'Lucida Console', Monaco, monospace;
  text-indent: 1px; }

.leaflet-touch .leaflet-control-zoom-in, .leaflet-touch .leaflet-control-zoom-out {
  font-size: 22px; }

/* layers control */
.leaflet-control-layers {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  background: #fff;
  border-radius: 5px; }

.leaflet-control-layers-toggle {
  background-image: url(images/layers.png);
  width: 36px;
  height: 36px; }

.leaflet-retina .leaflet-control-layers-toggle {
  background-image: url(images/layers-2x.png);
  background-size: 26px 26px; }

.leaflet-touch .leaflet-control-layers-toggle {
  width: 44px;
  height: 44px; }

.leaflet-control-layers .leaflet-control-layers-list,
.leaflet-control-layers-expanded .leaflet-control-layers-toggle {
  display: none; }

.leaflet-control-layers-expanded .leaflet-control-layers-list {
  display: block;
  position: relative; }

.leaflet-control-layers-expanded {
  padding: 6px 10px 6px 6px;
  color: #333;
  background: #fff; }

.leaflet-control-layers-scrollbar {
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 5px; }

.leaflet-control-layers-selector {
  margin-top: 2px;
  position: relative;
  top: 1px; }

.leaflet-control-layers label {
  display: block; }

.leaflet-control-layers-separator {
  height: 0;
  border-top: 1px solid #ddd;
  margin: 5px -10px 5px -6px; }

/* Default icon URLs */
.leaflet-default-icon-path {
  background-image: url(images/marker-icon.png); }

/* attribution and scale controls */
.leaflet-container .leaflet-control-attribution {
  background: #fff;
  background: rgba(255, 255, 255, 0.7);
  margin: 0; }

.leaflet-control-attribution,
.leaflet-control-scale-line {
  padding: 0 5px;
  color: #333; }

.leaflet-control-attribution a {
  text-decoration: none; }

.leaflet-control-attribution a:hover {
  text-decoration: underline; }

.leaflet-container .leaflet-control-attribution,
.leaflet-container .leaflet-control-scale {
  font-size: 11px; }

.leaflet-left .leaflet-control-scale {
  margin-left: 5px; }

.leaflet-bottom .leaflet-control-scale {
  margin-bottom: 5px; }

.leaflet-control-scale-line {
  border: 2px solid #777;
  border-top: none;
  line-height: 1.1;
  padding: 2px 5px 1px;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: #fff;
  background: rgba(255, 255, 255, 0.5); }

.leaflet-control-scale-line:not(:first-child) {
  border-top: 2px solid #777;
  border-bottom: none;
  margin-top: -2px; }

.leaflet-control-scale-line:not(:first-child):not(:last-child) {
  border-bottom: 2px solid #777; }

.leaflet-touch .leaflet-control-attribution,
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  box-shadow: none; }

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box; }

/* popup */
.leaflet-popup {
  position: absolute;
  text-align: center;
  margin-bottom: 20px; }

.leaflet-popup-content-wrapper {
  padding: 1px;
  text-align: left;
  border-radius: 12px; }

.leaflet-popup-content {
  margin: 13px 19px;
  line-height: 1.4; }

.leaflet-popup-content p {
  margin: 18px 0; }

.leaflet-popup-tip-container {
  width: 40px;
  height: 20px;
  position: absolute;
  left: 50%;
  margin-left: -20px;
  overflow: hidden;
  pointer-events: none; }

.leaflet-popup-tip {
  width: 17px;
  height: 17px;
  padding: 1px;
  margin: -10px auto 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: white;
  color: #333;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4); }

.leaflet-container a.leaflet-popup-close-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 4px 0 0;
  border: none;
  text-align: center;
  width: 18px;
  height: 14px;
  font: 16px/14px Tahoma, Verdana, sans-serif;
  color: #c3c3c3;
  text-decoration: none;
  font-weight: bold;
  background: transparent; }

.leaflet-container a.leaflet-popup-close-button:hover {
  color: #999; }

.leaflet-popup-scrolled {
  overflow: auto;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd; }

.leaflet-oldie .leaflet-popup-content-wrapper {
  zoom: 1; }

.leaflet-oldie .leaflet-popup-tip {
  width: 24px;
  margin: 0 auto;
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
  filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678); }

.leaflet-oldie .leaflet-popup-tip-container {
  margin-top: -1px; }

.leaflet-oldie .leaflet-control-zoom,
.leaflet-oldie .leaflet-control-layers,
.leaflet-oldie .leaflet-popup-content-wrapper,
.leaflet-oldie .leaflet-popup-tip {
  border: 1px solid #999; }

/* div icon */
.leaflet-div-icon {
  background: #fff;
  border: 1px solid #666; }

/* Tooltip */
/* Base styles for the element that has a tooltip */
.leaflet-tooltip {
  position: absolute;
  padding: 6px;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  color: #222;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4); }

.leaflet-tooltip.leaflet-clickable {
  cursor: pointer;
  pointer-events: auto; }

.leaflet-tooltip-top:before,
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
  position: absolute;
  pointer-events: none;
  border: 6px solid transparent;
  background: transparent;
  content: ""; }

/* Directions */
.leaflet-tooltip-bottom {
  margin-top: 6px; }

.leaflet-tooltip-top {
  margin-top: -6px; }

.leaflet-tooltip-bottom:before,
.leaflet-tooltip-top:before {
  left: 50%;
  margin-left: -6px; }

.leaflet-tooltip-top:before {
  bottom: 0;
  margin-bottom: -12px;
  border-top-color: #fff; }

.leaflet-tooltip-bottom:before {
  top: 0;
  margin-top: -12px;
  margin-left: -6px;
  border-bottom-color: #fff; }

.leaflet-tooltip-left {
  margin-left: -6px; }

.leaflet-tooltip-right {
  margin-left: 6px; }

.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
  top: 50%;
  margin-top: -6px; }

.leaflet-tooltip-left:before {
  right: 0;
  margin-right: -12px;
  border-left-color: #fff; }

.leaflet-tooltip-right:before {
  left: 0;
  margin-left: -12px;
  border-right-color: #fff; }

html {
  font-size: .625rem; }

body {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  color: #2F3F8D; }

sup {
  font-size: 60%; }

ul {
  padding-left: 1.8rem;
  list-style: square; }

.invalid {
  color: #9d261d;
  font-weight: bold; }

input.invalid {
  border: 1px solid #9d261d; }

ol {
  padding-left: 1rem; }
  ol li {
    counter-increment: li;
    padding-left: 1rem;
    margin-bottom: 1.5rem; }
    ol li::marker {
      color: #3CC0CC;
      content: counter(li); }

img,
svg {
  max-width: 100%; }

a img {
  border: 0; }

a {
  text-decoration-skip: ink; }

em {
  color: #758DA7; }

.item-page div[itemprop="articleBody"] ul.accordion > li {
  padding: 0; }
  .item-page div[itemprop="articleBody"] ul.accordion > li:before {
    display: none; }
  .item-page div[itemprop="articleBody"] ul.accordion > li > a {
    border-bottom: 1px solid rgba(47, 63, 141, 0.3);
    font-size: 2rem;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    color: #2F3F8D;
    padding: 8px 0;
    position: relative;
    transition: all ease-in-out 0.4s; }
    .item-page div[itemprop="articleBody"] ul.accordion > li > a:before {
      content: '';
      display: block;
      height: 12px;
      width: 2px;
      background-color: #2F3F8D;
      position: absolute;
      right: 5px;
      top: 20px;
      transition: all ease-in-out 0.4s; }
    .item-page div[itemprop="articleBody"] ul.accordion > li > a:after {
      content: '';
      display: block;
      height: 2px;
      width: 12px;
      background-color: #2F3F8D;
      position: absolute;
      right: 0;
      top: 25px;
      transition: all ease-in-out 0.4s; }
    .item-page div[itemprop="articleBody"] ul.accordion > li > a.accordeon_actif:before {
      transform: rotate(45deg);
      transition: all ease-in-out 0.4s; }
    .item-page div[itemprop="articleBody"] ul.accordion > li > a.accordeon_actif:after {
      transform: rotate(45deg);
      transition: all ease-in-out 0.4s; }

.retour-haut-page {
  color: #2E2E2E;
  margin-top: 180px; }
  .retour-haut-page svg {
    position: relative;
    right: 1px; }

.menu-pre-footer .bloc-menu {
  background: url(../img/perso-bcg.png) no-repeat left 45px transparent; }

.page-accueil .bloc-actu {
  background-color: #F9F8F8;
  position: relative;
  z-index: 0; }

@media (min-width: 576px) {
  .header-inner .uk-sticky-fixed .uk-navbar-left img {
    width: 50%;
    top: -30px !important;
    transition: 0.2s all ease-in-out; } }

@media (max-width: 575.98px) {
  .header-inner .uk-navbar-left img {
    width: 170px; } }

.page-accueil .bandeau {
  position: relative;
  z-index: 3; }

@media (max-width: 575.98px) {
  .card-home-group {
    border: 1px solid #DFE7EA; }
    .card-home-group img {
      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px; } }

.page-detail main {
  padding-top: 15px; }

.view-map main {
  padding-top: 0; }
  .view-map main .control-label {
    display: none; }

.accordeon {
  border: 1px solid #E3E8ED;
  border-radius: 4px;
  clear: both; }
  .accordeon .menu-accordeon {
    padding: 15px;
    color: #2F3F8D; }
    .accordeon .menu-accordeon svg {
      transition: all 0.2s ease-in-out; }
      .accordeon .menu-accordeon svg rect {
        stroke-width: 3px; }
  .accordeon .accordeon-contenu {
    padding: 15px;
    overflow: hidden; }
    .accordeon .accordeon-contenu .plus_info {
      margin: 0 !important; }
  .accordeon.uk-open .menu-accordeon svg, .accordeon:hover .menu-accordeon svg {
    transform: rotate(45deg);
    transition: all 0.2s ease-in-out; }
  @media (max-width: 639px) {
    .accordeon .bouton {
      display: block; } }

.com_acymailing .main #acymodifyform {
  display: table;
  margin: 0 auto;
  margin-top: 15px;
  max-width: 610px;
  width: 100%; }
  .com_acymailing .main #acymodifyform h1, .com_acymailing .main #acymodifyform .h1 {
    color: #2F3F8D;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 2.4rem;
    margin-bottom: 30px;
    padding-left: 35px;
    background: url(../img/inscription.png) no-repeat left; }
  .com_acymailing .main #acymodifyform p {
    color: #758DA7;
    font-family: "Roboto", sans-serif;
    font-size: 1.6rem;
    font-weight: 300; }
  .com_acymailing .main #acymodifyform legend {
    color: #758DA7;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 15px; }
  .com_acymailing .main #acymodifyform form {
    margin-top: 50px; }
    .com_acymailing .main #acymodifyform form label {
      display: block;
      color: #758DA7;
      font-family: "Roboto", sans-serif;
      font-size: 1.4rem;
      font-weight: 500;
      margin-bottom: 15px; }
    .com_acymailing .main #acymodifyform form input[type="text"], .com_acymailing .main #acymodifyform form input[type="email"] {
      box-sizing: border-box;
      border: 1px solid #B9B9CC;
      border-radius: 4px;
      padding: 10px 15px;
      color: #B9B9CC;
      width: 100%; }
    .com_acymailing .main #acymodifyform form #trname {
      margin-right: 2rem; }
    .com_acymailing .main #acymodifyform form .inputVal {
      width: 100%; }
    .com_acymailing .main #acymodifyform form #acyuserinfo {
      display: flex;
      flex-flow: row wrap; }
      .com_acymailing .main #acymodifyform form #acyuserinfo #trname, .com_acymailing .main #acymodifyform form #acyuserinfo #tremail {
        flex: 1 25%; }
      .com_acymailing .main #acymodifyform form #acyuserinfo #trhtml, .com_acymailing .main #acymodifyform form #acyuserinfo #trpublic {
        flex: 1 100%; }
    .com_acymailing .main #acymodifyform form #trhtml, .com_acymailing .main #acymodifyform form #trpublic {
      display: flex;
      flex-direction: row;
      align-items: center; }
      .com_acymailing .main #acymodifyform form #trhtml label, .com_acymailing .main #acymodifyform form #trpublic label {
        margin-bottom: 0px; }
      .com_acymailing .main #acymodifyform form #trhtml .inputVal, .com_acymailing .main #acymodifyform form #trpublic .inputVal {
        display: flex;
        flex-direction: row;
        align-items: center; }
    .com_acymailing .main #acymodifyform form #trhtml {
      margin-bottom: 25px; }
      .com_acymailing .main #acymodifyform form #trhtml .inputVal span:first-of-type {
        margin-right: 1rem; }
      .com_acymailing .main #acymodifyform form #trhtml input {
        position: relative;
        margin-right: 1rem; }
        .com_acymailing .main #acymodifyform form #trhtml input:before {
          content: "";
          width: 20px;
          height: 20px;
          background-color: #2F3F8D;
          position: absolute;
          border-radius: 100%;
          top: -3px;
          z-index: 0; }
        .com_acymailing .main #acymodifyform form #trhtml input:checked:after {
          content: "";
          width: 6px;
          height: 6px;
          background-color: #fff;
          position: absolute;
          border-radius: 100%;
          z-index: 1;
          top: 4px;
          left: 7.5px; }
    .com_acymailing .main #acymodifyform form #trpublic {
      border-top: 1px solid #E3E8ED;
      padding-top: 25px;
      align-items: baseline; }
      .com_acymailing .main #acymodifyform form #trpublic .inputVal {
        display: flex;
        flex-direction: column;
        align-items: baseline; }
        .com_acymailing .main #acymodifyform form #trpublic .inputVal span:first-of-type {
          margin-bottom: 1rem; }
    .com_acymailing .main #acymodifyform form #trpublic input, .com_acymailing .main #acymodifyform form #acyusersubscription input {
      position: relative;
      margin-right: 1rem; }
      .com_acymailing .main #acymodifyform form #trpublic input:before, .com_acymailing .main #acymodifyform form #acyusersubscription input:before {
        content: "";
        width: 20px;
        height: 20px;
        background-color: #2F3F8D;
        position: absolute;
        top: -3px;
        z-index: 0;
        border-radius: 4px; }
      .com_acymailing .main #acymodifyform form #trpublic input:checked:after, .com_acymailing .main #acymodifyform form #acyusersubscription input:checked:after {
        content: "";
        width: 3px;
        height: 6px;
        position: absolute;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        z-index: 1;
        top: 2px;
        left: 7.5px; }
    .com_acymailing .main #acymodifyform form .adminform.acy_subscription_list {
      border-top: 1px solid #E3E8ED;
      margin-top: 25px;
      padding-top: 25px; }
      .com_acymailing .main #acymodifyform form .adminform.acy_subscription_list .acy_onelist .controls {
        display: flex;
        flex-direction: row; }
      .com_acymailing .main #acymodifyform form .adminform.acy_subscription_list .acystatus {
        float: none;
        width: initial;
        max-width: initial; }
        .com_acymailing .main #acymodifyform form .adminform.acy_subscription_list .acystatus .controls label {
          display: flex;
          flex-direction: row;
          margin-bottom: 0;
          line-height: initial; }
      .com_acymailing .main #acymodifyform form .adminform.acy_subscription_list .list_name {
        color: #2F3F8D;
        font-family: "Roboto", sans-serif;
        font-size: 1.4rem;
        margin-left: 2rem; }
      .com_acymailing .main #acymodifyform form .adminform.acy_subscription_list .list_description {
        display: none; }
    .com_acymailing .main #acymodifyform form .acymodifybutton {
      margin-top: 25px;
      margin-bottom: 50px;
      padding-top: 25px; }
      .com_acymailing .main #acymodifyform form .acymodifybutton input {
        border-radius: 4px;
        background-color: #3CC0CC;
        padding: 13px 70px;
        color: #fff;
        font-family: "Roboto", sans-serif;
        font-weight: 700;
        font-size: 1.3rem;
        letter-spacing: 0.37px;
        text-transform: uppercase;
        border: none;
        cursor: pointer;
        transition: all 0.3s;
        appearance: none; }
        .com_acymailing .main #acymodifyform form .acymodifybutton input:hover {
          background-color: #278b95; }

.la-sante-en-chiffre .item-page {
  overflow: visible; }

.la-sante-en-chiffre.familiale-scolaire .item-page .page-header {
  background-color: #914198;
  padding: 15px 10px;
  margin-bottom: 30px; }
  @media (min-width: 1200px) {
    .la-sante-en-chiffre.familiale-scolaire .item-page .page-header {
      width: 100vw;
      position: relative;
      right: 110px;
      bottom: 25px;
      padding-left: 110px;
      margin-bottom: 0; } }
  .la-sante-en-chiffre.familiale-scolaire .item-page .page-header h1, .la-sante-en-chiffre.familiale-scolaire .item-page .page-header .h1 {
    color: #fff;
    margin: 0; }

.la-sante-en-chiffre.etat-sante .item-page .page-header {
  background-color: #544598;
  padding: 15px 10px;
  margin-bottom: 30px; }
  @media (min-width: 1200px) {
    .la-sante-en-chiffre.etat-sante .item-page .page-header {
      width: 100vw;
      position: relative;
      right: 110px;
      bottom: 25px;
      padding-left: 110px;
      margin-bottom: 0; } }
  .la-sante-en-chiffre.etat-sante .item-page .page-header h1, .la-sante-en-chiffre.etat-sante .item-page .page-header .h1 {
    color: #fff;
    margin: 0; }

.la-sante-en-chiffre.alimentation .item-page .page-header {
  background-color: #F66A5D;
  padding: 15px 10px;
  margin-bottom: 30px; }
  @media (min-width: 1200px) {
    .la-sante-en-chiffre.alimentation .item-page .page-header {
      width: 100vw;
      position: relative;
      right: 110px;
      bottom: 25px;
      padding-left: 110px;
      margin-bottom: 0; } }
  .la-sante-en-chiffre.alimentation .item-page .page-header h1, .la-sante-en-chiffre.alimentation .item-page .page-header .h1 {
    color: #fff;
    margin: 0; }

.la-sante-en-chiffre.sante-mentale .item-page .page-header {
  background-color: #FEDC71;
  padding: 15px 10px;
  margin-bottom: 30px; }
  @media (min-width: 1200px) {
    .la-sante-en-chiffre.sante-mentale .item-page .page-header {
      width: 100vw;
      position: relative;
      right: 110px;
      bottom: 25px;
      padding-left: 110px;
      margin-bottom: 0; } }
  .la-sante-en-chiffre.sante-mentale .item-page .page-header h1, .la-sante-en-chiffre.sante-mentale .item-page .page-header .h1 {
    color: #2E2E2E;
    margin: 0; }

.la-sante-en-chiffre.tabac .item-page .page-header {
  background-color: #1DBDD6;
  padding: 15px 10px;
  margin-bottom: 30px; }
  @media (min-width: 1200px) {
    .la-sante-en-chiffre.tabac .item-page .page-header {
      width: 100vw;
      position: relative;
      right: 110px;
      bottom: 25px;
      padding-left: 110px;
      margin-bottom: 0; } }
  .la-sante-en-chiffre.tabac .item-page .page-header h1, .la-sante-en-chiffre.tabac .item-page .page-header .h1 {
    color: #fff;
    margin: 0; }

.la-sante-en-chiffre.sante-sexuelle .item-page .page-header {
  background-color: #90BF3B;
  padding: 15px 10px;
  margin-bottom: 30px; }
  @media (min-width: 1200px) {
    .la-sante-en-chiffre.sante-sexuelle .item-page .page-header {
      width: 100vw;
      position: relative;
      right: 110px;
      bottom: 25px;
      padding-left: 110px;
      margin-bottom: 0; } }
  .la-sante-en-chiffre.sante-sexuelle .item-page .page-header h1, .la-sante-en-chiffre.sante-sexuelle .item-page .page-header .h1 {
    color: #2E2E2E;
    margin: 0; }

.plan-du-site .item-page {
  width: 60%;
  margin: auto; }

@media screen and (min-width: 576px) {
  .plan-du-site .uk-accordion {
    display: flex;
    flex-direction: row;
    justify-content: space-between; } }

.p-barometre-sante-adulte .b-bandeau h1, .p-barometre-sante-adulte .b-bandeau .h1 {
  font-family: "myriad_probold", sans-serif;
  text-transform: uppercase;
  color: #fff;
  font-size: 108px;
  text-align: center;
  margin-bottom: 0;
  font-weight: 700; }
  @media (max-width: 767.98px) {
    .p-barometre-sante-adulte .b-bandeau h1, .p-barometre-sante-adulte .b-bandeau .h1 {
      font-size: 50px; } }

.p-barometre-sante-adulte h2, .p-barometre-sante-adulte .h2 {
  font-size: 72px;
  font-weight: 900;
  line-height: 72px;
  color: #141D47;
  margin-bottom: 32px;
  font-family: "Gantari", sans-serif; }
  @media (max-width: 767.98px) {
    .p-barometre-sante-adulte h2, .p-barometre-sante-adulte .h2 {
      font-size: 30px;
      line-height: 30px; } }

.p-barometre-sante-adulte h3, .p-barometre-sante-adulte .h3 {
  font-size: 40px;
  color: #141D47;
  margin-bottom: 32px;
  line-height: 50px;
  font-weight: 700;
  font-family: "Gantari", sans-serif; }
  @media (max-width: 767.98px) {
    .p-barometre-sante-adulte h3, .p-barometre-sante-adulte .h3 {
      font-size: 35px; } }

.p-barometre-sante-adulte p {
  color: #4A4F55;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  line-height: 31px; }

.p-barometre-sante-adulte .youtube_player {
  width: 100%;
  max-width: 100%; }
  .p-barometre-sante-adulte .youtube_player iframe {
    height: 536px; }
    @media (max-width: 991.98px) {
      .p-barometre-sante-adulte .youtube_player iframe {
        height: 242px; } }

.p-barometre-sante-adulte .b-intro {
  background: #effbfd; }

.p-barometre-sante-adulte .b-carte p {
  margin: 0; }

.p-barometre-sante-adulte .b-carte img {
  width: 100%;
  height: 100%; }

.p-barometre-sante-adulte .b-enquete ul {
  display: flex;
  flex-direction: row;
  padding: 0;
  margin-bottom: 0;
  gap: 16px;
  justify-content: space-between; }
  @media (max-width: 991.98px) {
    .p-barometre-sante-adulte .b-enquete ul {
      flex-direction: column; } }
  .p-barometre-sante-adulte .b-enquete ul li {
    flex: 1 1 0;
    list-style: none;
    padding: 16px;
    border-radius: 16px;
    border: 1px solid #F1F2FA;
    font-size: 16px;
    color: #4A4F55; }
    .p-barometre-sante-adulte .b-enquete ul li img {
      background-color: rgba(47, 63, 141, 0.1);
      border-radius: 6px;
      width: 35px;
      padding: 8px; }
    .p-barometre-sante-adulte .b-enquete ul li h4, .p-barometre-sante-adulte .b-enquete ul li .h4 {
      color: #2F3F8D;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      margin-bottom: 12px;
      margin-top: 16px;
      line-height: 27px; }

.p-barometre-sante-adulte .b-thematique h3, .p-barometre-sante-adulte .b-thematique .h3 {
  font-weight: 900;
  line-height: normal; }

@media (max-width: 767.98px) {
  .p-barometre-sante-adulte .b-thematique h4, .p-barometre-sante-adulte .b-thematique .h4 {
    font-size: 28px; } }

.p-barometre-sante-adulte .b-thematique .lien-thematique {
  color: #141D47;
  padding: 8px 12px;
  margin-right: 8px;
  border-radius: 8px;
  margin-bottom: 15px;
  line-height: normal;
  display: inline-block;
  letter-spacing: 0.24px;
  border: 3px solid #141D47; }

.p-barometre-sante-adulte .b-thematique .b-thematique-detail {
  margin-bottom: 128px; }
  @media (max-width: 991.98px) {
    .p-barometre-sante-adulte .b-thematique .b-thematique-detail {
      margin-bottom: 70px; } }
  @media (max-width: 767.98px) {
    .p-barometre-sante-adulte .b-thematique .b-thematique-detail {
      margin-bottom: 50px; } }
  .p-barometre-sante-adulte .b-thematique .b-thematique-detail:last-child {
    margin-bottom: 0; }

.p-barometre-sante-adulte .b-thematique p {
  color: #4A4F55;
  line-height: 31px;
  font-family: "Roboto", sans-serif; }
  @media (max-width: 991.98px) {
    .p-barometre-sante-adulte .b-thematique p {
      font-size: 16px; } }

.p-barometre-sante-adulte .b-thematique img {
  width: 100%;
  border-radius: 8px; }

.p-barometre-sante-adulte .b-thematique .pdf p {
  margin-bottom: 0; }

.p-barometre-sante-adulte .b-thematique .pdf a {
  color: #fff;
  display: block;
  text-align: center;
  padding: 12px 28px;
  border-radius: 8px;
  background-color: #2F3F8D; }
  @media (max-width: 991.98px) {
    .p-barometre-sante-adulte .b-thematique .pdf a {
      font-size: 16px;
      padding: 12px; } }
  .p-barometre-sante-adulte .b-thematique .pdf a img {
    width: 24px;
    height: 24px; }
    @media (max-width: 991.98px) {
      .p-barometre-sante-adulte .b-thematique .pdf a img {
        width: 15px;
        height: 15px; } }

.p-barometre-sante-adulte .b-thematique .youtube_player iframe {
  height: 536px; }
  @media (max-width: 991.98px) {
    .p-barometre-sante-adulte .b-thematique .youtube_player iframe {
      height: 225px; } }

.p-barometre-sante-adulte .b-statistiques h2, .p-barometre-sante-adulte .b-statistiques .h2 {
  margin-bottom: 32px; }

.p-barometre-sante-adulte .b-statistiques iframe {
  width: 100%;
  height: 660px;
  border-radius: 12px; }

.p-barometre-sante-adulte .b-en-savoir-plus h2, .p-barometre-sante-adulte .b-en-savoir-plus .h2, .p-barometre-sante-adulte .b-en-savoir-plus p, .p-barometre-sante-adulte .b-en-savoir-plus a {
  color: #fff; }

.p-barometre-sante-adulte .b-en-savoir-plus ul {
  margin-top: 32px;
  padding-left: 0; }
  .p-barometre-sante-adulte .b-en-savoir-plus ul li {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 12px; }
    .p-barometre-sante-adulte .b-en-savoir-plus ul li a {
      padding-left: 25px;
      text-decoration-line: underline; }
    .p-barometre-sante-adulte .b-en-savoir-plus ul li a[href*=".pdf"] {
      background: url(../img/st-file.png) no-repeat 3px 1px;
      background-size: 20px;
      padding-right: 45px; }
    .p-barometre-sante-adulte .b-en-savoir-plus ul li a[href*="http"] {
      background: url(../img/st-lien.png) no-repeat 3px 1px;
      background-size: 20px;
      padding-right: 45px; }

.p-barometre-sante-adulte .b-en-savoir-plus .pdf p {
  margin-bottom: 0; }

.p-barometre-sante-adulte .b-en-savoir-plus .pdf a {
  color: #fff;
  display: block;
  text-align: center;
  padding: 12px 28px;
  border-radius: 8px;
  background-color: #2F3F8D; }
  @media (max-width: 991.98px) {
    .p-barometre-sante-adulte .b-en-savoir-plus .pdf a {
      font-size: 16px;
      padding: 12px; } }

.p-barometre-sante-adulte .b-en-savoir-plus .pdf img {
  width: 100%; }

.menu-barometre ul li a {
  color: #141D47;
  font-size: 14px; }

.menu-barometre #offcanvas-overlay-barometre nav ul li {
  margin-top: 32px;
  font-size: 14px;
  font-weight: 700;
  color: #141D47; }
  .menu-barometre #offcanvas-overlay-barometre nav ul li a {
    margin-bottom: 0;
    padding: 0; }

.menu-barometre #offcanvas-overlay-barometre nav ul a {
  color: #141D47;
  padding: 8px 12px;
  margin-bottom: 8px;
  display: inline-block; }

.menu-barometre .uk-offcanvas-close {
  color: #3CC0CC;
  position: relative;
  left: 0;
  top: 0;
  float: right;
  padding: 0; }

.lp-barometre-sante-adulte .menu-toggle {
  display: none; }

.formulaire-accompagnement .rsform h3, .formulaire-accompagnement .rsform .h3 {
  font-size: 2.4rem;
  color: #141D47;
  font-weight: 500;
  margin-bottom: 12px; }

.formulaire-accompagnement .rsform h4, .formulaire-accompagnement .rsform .h4 {
  font-size: 18px;
  line-height: 28px;
  margin-top: 12px;
  font-weight: 500; }

.formulaire-accompagnement .rsform p {
  color: #252525;
  margin-bottom: 12px; }

.formulaire-accompagnement .rsform ol {
  font-size: 16px;
  color: #6F7E94;
  margin-bottom: 44px; }
  .formulaire-accompagnement .rsform ol li {
    margin-bottom: 6px; }
    .formulaire-accompagnement .rsform ol li::marker {
      color: #6F7E94; }

.formulaire-accompagnement .rsform input::placeholder {
  color: #B9B9CA; }

.formulaire-accompagnement .rsform .rsform-type-pagebreak .formControlLabel {
  display: none; }

.formulaire-accompagnement .rsform .rsform-type-pagebreak .formControls {
  margin-left: 0; }
  .formulaire-accompagnement .rsform .rsform-type-pagebreak .formControls .formBody button[type="button"].rsform-button-next, .formulaire-accompagnement .rsform .rsform-type-pagebreak .formControls .formBody button[type="button"].rsform-button-prev {
    background-color: #95C8D5;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    border-radius: 8px;
    border: none;
    padding: 0px 24px;
    line-height: 38px;
    font-size: 16px;
    width: 49%; }
    @media (max-width: 767.98px) {
      .formulaire-accompagnement .rsform .rsform-type-pagebreak .formControls .formBody button[type="button"].rsform-button-next, .formulaire-accompagnement .rsform .rsform-type-pagebreak .formControls .formBody button[type="button"].rsform-button-prev {
        width: 46% !important;
        padding: 0px 15px; } }
  .formulaire-accompagnement .rsform .rsform-type-pagebreak .formControls .formBody button[type="button"].rsform-button-prev {
    background-color: transparent;
    border: 1px solid #2F3F8D;
    margin-right: 16px;
    color: #2F3F8D; }
  .formulaire-accompagnement .rsform .rsform-type-pagebreak .formControls .formBody #commencerNext {
    width: 95%; }

.formulaire-accompagnement .rsform #rsform_3_page_0 .formRow .formSpan12 h3, .formulaire-accompagnement .rsform #rsform_3_page_0 .formRow .formSpan12 .h3 {
  margin-top: 10px; }
  @media (min-width: 768px) {
    .formulaire-accompagnement .rsform #rsform_3_page_0 .formRow .formSpan12 h3, .formulaire-accompagnement .rsform #rsform_3_page_0 .formRow .formSpan12 .h3 {
      margin-top: 76px; } }

.formulaire-accompagnement .rsform #rsform_3_page_0 .formRow .formSpan12 .rsform-block-commencer button {
  width: 100%;
  margin-bottom: 50px; }
  @media (min-width: 768px) {
    .formulaire-accompagnement .rsform #rsform_3_page_0 .formRow .formSpan12 .rsform-block-commencer button {
      margin-bottom: 233px; } }

.formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-block-fonction.rsform-type-textbox, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-block-fonction.rsform-type-textbox, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-block-fonction.rsform-type-textbox {
  margin-bottom: 32px; }

.formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-freetext, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-freetext, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-type-freetext {
  margin-bottom: 0; }
  .formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-freetext p, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-freetext p, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-type-freetext p {
    margin: 0; }
  .formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-freetext p > img, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-freetext p > img, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-type-freetext p > img {
    margin-bottom: 40px;
    width: 100%; }
  .formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-freetext p > span, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-freetext p > span, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-type-freetext p > span {
    text-transform: uppercase;
    letter-spacing: 2.8px;
    line-height: 27px;
    font-size: 14px;
    color: #64748B; }
  .formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-freetext h3, .formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-freetext .h3, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-freetext h3, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-freetext .h3, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-type-freetext h3, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-type-freetext .h3 {
    margin-top: 0;
    margin-bottom: 24px; }

.formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-textbox, .formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-checkboxgroup, .formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-textarea, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-textbox, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-checkboxgroup, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-textarea, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-type-textbox, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-type-checkboxgroup, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-type-textarea {
  margin-bottom: 12px; }
  .formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-textbox .formControlLabel, .formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-checkboxgroup .formControlLabel, .formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-textarea .formControlLabel, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-textbox .formControlLabel, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-checkboxgroup .formControlLabel, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-textarea .formControlLabel, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-type-textbox .formControlLabel, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-type-checkboxgroup .formControlLabel, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-type-textarea .formControlLabel {
    width: 95%;
    color: #6F7E94;
    font-weight: 500;
    text-align: left;
    line-height: 28px;
    margin-bottom: 4px; }
  .formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-textbox .formControls, .formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-checkboxgroup .formControls, .formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-textarea .formControls, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-textbox .formControls, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-checkboxgroup .formControls, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-textarea .formControls, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-type-textbox .formControls, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-type-checkboxgroup .formControls, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-type-textarea .formControls {
    width: 100%;
    margin-left: 0; }
    .formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-textbox .formControls input, .formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-textbox .formControls textarea, .formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-checkboxgroup .formControls input, .formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-checkboxgroup .formControls textarea, .formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-textarea .formControls input, .formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-textarea .formControls textarea, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-textbox .formControls input, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-textbox .formControls textarea, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-checkboxgroup .formControls input, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-checkboxgroup .formControls textarea, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-textarea .formControls input, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-textarea .formControls textarea, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-type-textbox .formControls input, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-type-textbox .formControls textarea, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-type-checkboxgroup .formControls input, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-type-checkboxgroup .formControls textarea, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-type-textarea .formControls input, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-type-textarea .formControls textarea {
      border: 1px solid #B9B9CA;
      border-radius: 4px;
      margin-bottom: 0;
      padding: 8px 12px;
      font-size: 16px;
      width: 100%; }
    .formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-textbox .formControls .formValidation, .formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-textbox .formControls .formDescription, .formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-checkboxgroup .formControls .formValidation, .formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-checkboxgroup .formControls .formDescription, .formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-textarea .formControls .formValidation, .formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-textarea .formControls .formDescription, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-textbox .formControls .formValidation, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-textbox .formControls .formDescription, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-checkboxgroup .formControls .formValidation, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-checkboxgroup .formControls .formDescription, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-textarea .formControls .formValidation, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-textarea .formControls .formDescription, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-type-textbox .formControls .formValidation, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-type-textbox .formControls .formDescription, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-type-checkboxgroup .formControls .formValidation, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-type-checkboxgroup .formControls .formDescription, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-type-textarea .formControls .formValidation, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-type-textarea .formControls .formDescription {
      display: none; }

.formulaire-accompagnement .rsform #rsform_3_page_1 .formSpan12 .rsform-type-textbox .formControlLabel, .formulaire-accompagnement .rsform #rsform_3_page_1 .formSpan12 .rsform-type-textarea .formControlLabel, .formulaire-accompagnement .rsform #rsform_3_page_2 .formSpan12 .rsform-type-textbox .formControlLabel, .formulaire-accompagnement .rsform #rsform_3_page_2 .formSpan12 .rsform-type-textarea .formControlLabel, .formulaire-accompagnement .rsform #rsform_3_page_3 .formSpan12 .rsform-type-textbox .formControlLabel, .formulaire-accompagnement .rsform #rsform_3_page_3 .formSpan12 .rsform-type-textarea .formControlLabel {
  width: 95%; }

.formulaire-accompagnement .rsform #rsform_3_page_1 .formSpan12 .rsform-type-textbox .formControls, .formulaire-accompagnement .rsform #rsform_3_page_1 .formSpan12 .rsform-type-textarea .formControls, .formulaire-accompagnement .rsform #rsform_3_page_2 .formSpan12 .rsform-type-textbox .formControls, .formulaire-accompagnement .rsform #rsform_3_page_2 .formSpan12 .rsform-type-textarea .formControls, .formulaire-accompagnement .rsform #rsform_3_page_3 .formSpan12 .rsform-type-textbox .formControls, .formulaire-accompagnement .rsform #rsform_3_page_3 .formSpan12 .rsform-type-textarea .formControls {
  width: 100%; }
  .formulaire-accompagnement .rsform #rsform_3_page_1 .formSpan12 .rsform-type-textbox .formControls input, .formulaire-accompagnement .rsform #rsform_3_page_1 .formSpan12 .rsform-type-textarea .formControls input, .formulaire-accompagnement .rsform #rsform_3_page_2 .formSpan12 .rsform-type-textbox .formControls input, .formulaire-accompagnement .rsform #rsform_3_page_2 .formSpan12 .rsform-type-textarea .formControls input, .formulaire-accompagnement .rsform #rsform_3_page_3 .formSpan12 .rsform-type-textbox .formControls input, .formulaire-accompagnement .rsform #rsform_3_page_3 .formSpan12 .rsform-type-textarea .formControls input {
    width: 90% !important; }
    @media (min-width: 768px) {
      .formulaire-accompagnement .rsform #rsform_3_page_1 .formSpan12 .rsform-type-textbox .formControls input, .formulaire-accompagnement .rsform #rsform_3_page_1 .formSpan12 .rsform-type-textarea .formControls input, .formulaire-accompagnement .rsform #rsform_3_page_2 .formSpan12 .rsform-type-textbox .formControls input, .formulaire-accompagnement .rsform #rsform_3_page_2 .formSpan12 .rsform-type-textarea .formControls input, .formulaire-accompagnement .rsform #rsform_3_page_3 .formSpan12 .rsform-type-textbox .formControls input, .formulaire-accompagnement .rsform #rsform_3_page_3 .formSpan12 .rsform-type-textarea .formControls input {
        width: 97% !important; } }

.formulaire-accompagnement .rsform #rsform_3_page_1 .formSpan6 .rsform-type-textbox .formControls .formBody input, .formulaire-accompagnement .rsform #rsform_3_page_2 .formSpan6 .rsform-type-textbox .formControls .formBody input, .formulaire-accompagnement .rsform #rsform_3_page_3 .formSpan6 .rsform-type-textbox .formControls .formBody input {
  width: 95%;
  border-radius: 4px; }
  @media (max-width: 767.98px) {
    .formulaire-accompagnement .rsform #rsform_3_page_1 .formSpan6 .rsform-type-textbox .formControls .formBody input, .formulaire-accompagnement .rsform #rsform_3_page_2 .formSpan6 .rsform-type-textbox .formControls .formBody input, .formulaire-accompagnement .rsform #rsform_3_page_3 .formSpan6 .rsform-type-textbox .formControls .formBody input {
      width: 90% !important; } }

.formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-textbox, .formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-checkboxgroup, .formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-textarea, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-textbox, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-checkboxgroup, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-textarea {
  display: flex;
  flex-direction: column; }

.formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-block-thematique > label {
  margin-bottom: 8px; }

.formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-textarea .formControls textarea {
  width: 97%; }

.formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-checkboxgroup .formBody .rsformgrid6 .rsformVerticalClear, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-checkboxgroup .formBody .rsformVerticalClear {
  margin-bottom: 0; }
  .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-checkboxgroup .formBody .rsformgrid6 .rsformVerticalClear label, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-checkboxgroup .formBody .rsformVerticalClear label {
    font-weight: 400;
    display: flex !important;
    align-items: start;
    margin: 0px 12px 13px 0;
    line-height: 2.8rem;
    font-size: 1.7rem;
    color: #6F7E94; }
    .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-checkboxgroup .formBody .rsformgrid6 .rsformVerticalClear label input, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-checkboxgroup .formBody .rsformVerticalClear label input {
      width: 20px;
      height: 20px;
      max-width: 20px;
      min-width: 20px;
      margin-right: 12px;
      border: 1px solid #B9B9CA; }

.formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-block-page-2.rsform-type-pagebreak {
  margin-top: 32px; }
  .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-block-page-2.rsform-type-pagebreak .formControls .formBody {
    display: flex; }

.formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-block-autre.rsform-type-textarea {
  margin-bottom: 3.2rem; }
  .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-block-autre.rsform-type-textarea textarea {
    border: 1px solid #B9B9CA;
    border-radius: 4px;
    margin-bottom: 0;
    padding: 8px 12px;
    font-size: 16px; }
    .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-block-autre.rsform-type-textarea textarea::placeholder {
      color: #B9B9CA; }

.formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-block-autre-description.rsform-type-checkboxgroup #autre_description-grouplbl {
  display: none; }

.formulaire-accompagnement .rsform #rsform_3_page_3 #rgpd0-lbl {
  display: flex; }

.formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-block-preciser {
  margin-bottom: 24px; }
  .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-block-preciser p {
    color: #2F3F8D;
    margin-bottom: 0; }
  .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-block-preciser h3, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-block-preciser .h3 {
    margin-bottom: 8px; }

.formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-block-periode {
  display: flex;
  flex-direction: column; }
  .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-block-periode label {
    color: #141D47;
    margin-bottom: 12px; }
  .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-block-periode .formControls .formBody .rsformVerticalClear {
    margin-bottom: 6px; }
    .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-block-periode .formControls .formBody .rsformVerticalClear label {
      display: flex;
      color: #6F7E94;
      margin: 0; }
      .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-block-periode .formControls .formBody .rsformVerticalClear label input {
        border: 1px solid #B9B9CA;
        border-radius: 4px;
        margin-bottom: 0;
        margin-right: 12px;
        line-height: 28px;
        padding: 8px 12px;
        font-size: 16px;
        width: 20px; }

.formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-block-remarques .formControls .formBody input {
  border: 1px solid #B9B9CA;
  border-radius: 4px;
  margin-bottom: 0;
  padding: 8px 12px;
  font-size: 16px;
  line-height: 28px !important; }

.formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-block-desc {
  margin-bottom: 32px; }
  .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-block-desc p {
    color: #6F7E94; }

.formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-block-rgpd.rsform-type-checkboxgroup {
  margin-bottom: 16px; }
  .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-block-rgpd.rsform-type-checkboxgroup > label {
    display: none; }
  .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-block-rgpd.rsform-type-checkboxgroup label {
    line-height: 22px;
    font-size: 14px;
    color: #2F3F8D;
    margin-bottom: 0;
    margin-top: 0; }
    .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-block-rgpd.rsform-type-checkboxgroup label input {
      width: 35px;
      height: 20px;
      border-radius: 4px;
      margin-bottom: 0;
      margin-right: 12px;
      line-height: 28px;
      padding: 8px 12px;
      font-size: 16px; }

.formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-block-rgpd2 p {
  color: #2F3F8D;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 32px; }
  .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-block-rgpd2 p a {
    color: #2F3F8D;
    text-decoration-line: underline; }

.formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-block-send .formControls {
  margin-left: 0; }
  .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-block-send .formControls .formBody {
    display: flex;
    width: 100%; }
    .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-block-send .formControls .formBody button {
      width: 50%; }
    .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-block-send .formControls .formBody button[type="submit"].rsform-submit-button, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-block-send .formControls .formBody button[type="button"].rsform-button-prev {
      background-color: #95C8D5;
      text-shadow: none;
      font-family: "Roboto", sans-serif;
      font-weight: 600;
      border-radius: 8px;
      border: none;
      padding: 0px 24px;
      line-height: 38px;
      font-size: 16px;
      width: 49%;
      margin-right: 15px; }
      @media (max-width: 767.98px) {
        .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-block-send .formControls .formBody button[type="submit"].rsform-submit-button, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-block-send .formControls .formBody button[type="button"].rsform-button-prev {
          width: 46% !important;
          padding: 0px 15px; } }
    .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-block-send .formControls .formBody button[type="button"].rsform-button-prev {
      background-color: transparent;
      border: 1px solid #2F3F8D;
      margin-right: 16px;
      color: #2F3F8D; }

.formulaire-accompagnement .rsform .thankyou p > img {
  width: 100%;
  margin-bottom: 209px; }
  @media (max-width: 767.98px) {
    .formulaire-accompagnement .rsform .thankyou p > img {
      margin-bottom: 70px; } }

.formulaire-accompagnement .rsform .thankyou h2, .formulaire-accompagnement .rsform .thankyou .h2 {
  font-size: 4.8rem;
  color: #141D47;
  margin-bottom: 16px;
  font-weight: 700; }

.formulaire-accompagnement .rsform .thankyou p {
  margin-top: 0;
  font-size: 2rem;
  line-height: 31px;
  color: #141D47;
  margin-bottom: 40px; }
  .formulaire-accompagnement .rsform .thankyou p a {
    color: #141D47;
    text-decoration: underline; }

.formulaire-accompagnement .rsform .thankyou a.btn {
  margin-bottom: 367px;
  color: #fff;
  display: inline-flex;
  padding: 0px 70px;
  font-size: 16px;
  font-weight: 600;
  line-height: 38px;
  text-decoration: none;
  background-color: #95C8D5; }
  @media (max-width: 767.98px) {
    .formulaire-accompagnement .rsform .thankyou a.btn {
      margin-bottom: 100px; } }

@media (max-width: 767.98px) {
  .formulaire-accompagnement .rsform .formResponsive input[type="text"], .formulaire-accompagnement .rsform .formResponsive textarea {
    width: 90% !important; } }

.mea_ressources h3, .mea_ressources .h3 {
  color: #2F3F8D;
  font-size: 2.4rem;
  font-weight: bold;
  padding-bottom: 20px;
  padding-top: 31px;
  margin: 0; }

.mea_ressources .videos .en-tete-video {
  display: block;
  color: #758DA7;
  font-size: 1.6rem;
  padding: 10px 0;
  font-weight: bold; }

.mea_ressources .videos p {
  margin: 0; }

.mea_ressources .videos .phocadownloadfile-32 a {
  width: auto !important;
  background-color: #2F3F8D;
  border: 0;
  border-radius: 4px; }

.mea_ressources .videos .phocadownloadfile-32 .info-fichier {
  padding: 15px 35px 15px 0;
  display: block; }

.mea_ressources .videos .phocadownloadfile-32 .icone-fichier {
  background-color: #141D47;
  padding: 0 15px;
  border-radius: 4px; }

.mea_ressources .videos .phocadownloadfile-32 .bloc-fichier {
  padding: 0; }

.mea_ressources .videos .phocadownloadfile-32 .nom {
  color: #fff; }

.mea_ressources .videos .phocadownloadfile-32 .poid {
  color: #3CC0CC; }

.mea_ressources .fichiers {
  border: 1px solid #E4E7EA;
  border-radius: 3px;
  padding: 12px; }
  .mea_ressources .fichiers p {
    margin: 0; }
  .mea_ressources .fichiers a {
    padding: 15px 0;
    border: 0; }
    .mea_ressources .fichiers a .nom_fichier {
      color: #2F3F8D; }
    .mea_ressources .fichiers a .bloc-fichier {
      padding: 0; }
    .mea_ressources .fichiers a:hover {
      box-shadow: none; }
    .mea_ressources .fichiers a .uk-width-3-5 {
      padding-left: 15px; }
    .mea_ressources .fichiers a .uk-width-1-5 {
      padding-left: 10px; }

.b-acces .generique-acces {
  padding-left: 35px;
  color: #2F3F8D;
  font-size: 1.4rem; }

.b-acces .adresse {
  background: url(../img/adresse.png) no-repeat left 3px; }

.b-acces .horaires {
  background: url(../img/horaires.png) no-repeat left 2px; }

.b-acces .mail {
  background: url(../img/mail.png) no-repeat left 1px; }

.b-acces .telephone {
  background: url(../img/telephone.png) no-repeat left top; }

.b-acces h2, .b-acces .h2 {
  margin: 35px 0; }

@media (min-width: 960px) {
  .b-acces__colonnegauche {
    padding-right: 70px; } }

.b-acces__question {
  background-color: #2F3F8D;
  color: #fff;
  border-radius: 4px;
  padding: 20px 25px; }
  .b-acces__question h2, .b-acces__question .h2 {
    color: #fff !important;
    margin: 0;
    background: url(../img/picto_question.jpg) no-repeat left top;
    padding-left: 35px;
    font-weight: normal !important; }
  .b-acces__question p {
    font-size: 1.4rem; }
  .b-acces__question a {
    border: 1px solid #fff;
    border-radius: 4px;
    text-transform: uppercase;
    text-align: center;
    color: #fff !important;
    display: block;
    width: 100%;
    padding: 8px 0;
    font-size: 1.3rem; }

.b-acces__images {
  padding-top: 40px; }
  @media (max-width: 640px) {
    .b-acces__images {
      display: none; } }

@media (max-width: 640px) {
  .bandeau .bannergroup {
    display: none; } }

.b-presse h1, .b-presse .h1 {
  color: #141D47;
  font-size: 2.4rem;
  font-weight: bold; }

.b-presse .pd-filebox {
  border: 1px solid #E4E7EA !important;
  border-radius: 4px;
  padding: 10px !important;
  margin: 20px 0 !important;
  overflow: hidden; }
  .b-presse .pd-filebox .pd-filenamebox a {
    color: #2F3F8D;
    font-size: 1.9rem;
    font-weight: bold; }
  .b-presse .pd-filebox .pd-filenamebox .date-ml {
    color: #92A1B2;
    display: block;
    font-size: 1.4rem;
    font-weight: normal; }
  .b-presse .pd-filebox .pd-filenamebox .pd-button-download {
    background-color: #2F3F8D;
    color: #fff;
    text-transform: uppercase;
    border-radius: 4px;
    font-size: 1.4rem;
    padding: 10px 30px;
    font-weight: bold;
    border: 0;
    cursor: pointer; }
  .b-presse .pd-filebox .pd-filenamebox .poid-extension {
    color: #92A1B2;
    font-size: 1.4rem;
    padding-right: 20px; }
  .b-presse .pd-filebox .pd-filenamebox #phocadownloadform {
    position: relative;
    top: 3px; }
  .b-presse .pd-filebox .pd-document16 {
    height: 37px !important;
    padding-left: 40px !important;
    color: #92A1B2;
    display: block;
    padding-top: 8px;
    font-size: 1.4rem; }
    .b-presse .pd-filebox .pd-document16 span {
      color: #2F3F8D;
      font-size: 1.9rem;
      font-weight: bold; }

.b-presse .pd-button-download .btn {
  background-color: #2F3F8D;
  color: #fff;
  text-transform: uppercase;
  border-radius: 4px;
  font-size: 1.4rem;
  padding: 10px 30px;
  font-weight: bold; }

.b-presse .pd-button-download .poid-extension {
  color: #92A1B2;
  font-size: 1.4rem;
  padding-right: 20px; }

.filtre_actu a .image_intro {
  width: 100%; }
  .filtre_actu a .image_intro img {
    border-radius: 4px;
    position: relative;
    z-index: 1;
    top: 3px;
    width: 100%; }
  .filtre_actu a .image_intro span {
    color: #fff;
    border: 1px solid #fff;
    border-radius: 4px;
    padding: 0px 20px;
    font-size: 1.4rem;
    z-index: 2; }

.filtre_actu a .intro-texte {
  padding: 15px;
  border: 1px solid #E3E8ED;
  border-radius: 0 0 4px 4px; }
  .filtre_actu a .intro-texte h2, .filtre_actu a .intro-texte .h2 {
    color: #2F3F8D;
    margin: 0; }
  .filtre_actu a .intro-texte .date {
    color: #758DA7;
    font-size: 1.4rem; }
  .filtre_actu a .intro-texte .thematique {
    font-size: 1.4rem;
    color: #758DA7; }

.contact-dokamo label {
  color: #758DA7;
  font-size: 1.4rem;
  padding-bottom: 16px;
  display: block; }

.contact-dokamo .validate-email {
  border: 1px solid #B9B9CC;
  border-radius: 4px;
  background-color: #fff;
  padding-left: 15px; }
  .contact-dokamo .validate-email::placeholder {
    color: #9FB0C2;
    font-size: 1.4rem; }

.contact-dokamo select {
  border: 1px solid #B9B9CC;
  border-radius: 4px;
  background-color: #fff;
  color: #2F3F8D;
  font-size: 1.4rem;
  box-shadow: none;
  padding: 6px 16px;
  height: auto; }
  .contact-dokamo select div {
    display: none; }

.contact-dokamo textarea {
  border: 1px solid #B9B9CC;
  border-radius: 4px;
  background-color: #fff;
  padding-left: 15px;
  min-height: 100px; }
  .contact-dokamo textarea::placeholder {
    color: #9FB0C2;
    font-size: 1.4rem; }

.contact-dokamo .validate-email, .contact-dokamo textarea {
  width: 100%; }

.contact-dokamo button {
  padding: 13px 80px;
  border-radius: 4px;
  background-color: #3CC0CC;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  line-height: 15px !important;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 0.37px; }

.contact-dokamo .informations {
  background-color: #6A65A7;
  color: #fff;
  padding: 21px 24px;
  border-radius: 4px;
  margin-bottom: 32px; }
  .contact-dokamo .informations h2, .contact-dokamo .informations .h2 {
    font-size: 2rem;
    color: #fff;
    background: url(../img/contact-bulle.gif) no-repeat left 4px;
    padding-left: 35px; }
  .contact-dokamo .informations address {
    position: relative;
    padding-left: 35px;
    margin-bottom: 0;
    font-size: 1.4rem; }
    .contact-dokamo .informations address.physique {
      background: url(../img/contact-position.gif) no-repeat left 3px; }
    .contact-dokamo .informations address.tel {
      background: url(../img/contact-tel.gif) no-repeat left 3px; }
      .contact-dokamo .informations address.tel a {
        color: inherit;
        text-decoration: underline; }
        .contact-dokamo .informations address.tel a:hover {
          color: inherit; }

.contact-dokamo .comment-sy-rendre {
  background-color: #2F3F8D;
  color: #fff;
  border-radius: 4px;
  padding: 20px 25px;
  margin-bottom: 30px; }
  .contact-dokamo .comment-sy-rendre h2, .contact-dokamo .comment-sy-rendre .h2 {
    color: #fff !important;
    margin: 0;
    background: url(../img/picto_sy_rendre.png) no-repeat left top;
    padding-left: 35px;
    padding-bottom: 2px; }
  .contact-dokamo .comment-sy-rendre p {
    font-size: 1.4rem; }
  .contact-dokamo .comment-sy-rendre a {
    border: 1px solid #fff;
    border-radius: 4px;
    text-transform: uppercase;
    text-align: center;
    color: #fff !important;
    display: block;
    width: 100%;
    padding: 8px 0;
    font-size: 1.3rem; }

.contact-dokamo .agence {
  border: 1px solid #E3E8ED;
  border-radius: 4px;
  padding: 24px; }
  .contact-dokamo .agence h2, .contact-dokamo .agence .h2 {
    color: #6A65A7;
    font-size: 2rem;
    background: url(../img/contact-loupe.gif) no-repeat left 3px;
    padding-left: 35px;
    font-family: "Roboto", sans-serif; }
  .contact-dokamo .agence p {
    font-size: 1.4rem;
    color: #758DA7; }
  .contact-dokamo .agence a {
    color: #6A65A7;
    border: 1px solid #6A65A7;
    border-radius: 4px;
    text-align: center;
    padding: 12px;
    text-transform: uppercase;
    font-size: 1.3rem;
    display: block;
    font-family: "Roboto", sans-serif;
    font-weight: 700; }

@media (min-width: 640px) {
  .contact-dokamo .bord-gauche {
    padding-right: 35px; } }

@media (max-width: 640px) {
  .contact-dokamo .bord-gauche {
    padding-left: 0; }
    .contact-dokamo .bord-gauche + div {
      padding-left: 0; } }

.b-contact {
  border-radius: .4rem;
  border: 1px solid #E3E8ED; }
  .b-contact .uk-card {
    background: #fff;
    width: 100%; }
    .b-contact .uk-card-body {
      padding: 1.5rem; }
  .b-contact__title {
    font-family: "Roboto", sans-serif;
    font-weight: 500; }
  .b-contact__title, .b-contact h3, .b-contact .h3 {
    margin: 1rem 0; }
  .b-contact__address {
    padding: 0 2.5rem; }
  .b-contact__deco {
    background-color: #3CC0CC;
    background-position: center 15px;
    background-repeat: no-repeat;
    border-radius: .4rem;
    width: 40px; }
    .b-contact__deco.contact-addictologie {
      background-image: url(../img/ico-addiction.svg); }
    .b-contact__deco.contact-sante-oral {
      background-image: url(../img/ico-dents-sante.svg); }
    .b-contact__deco.contact-cancers-feminins {
      background-image: url(../img/ico-cancer-sein.svg); }
    .b-contact__deco.contact-raa {
      background-image: url(../img/ico-raa.svg); }
    .b-contact__deco.contact-mange-mieux-bouge-plus {
      background-image: url(../img/ico-mange-mieux.svg); }
    .b-contact__deco.contact-sante-sexuelle {
      background-image: url(../img/ico-sante-sexuelle.svg); }
    .b-contact__deco.contact-tuberculose {
      background-image: url(../img/ico-tuberculose.svg); }
    .b-contact__deco.contact-barometre-sante {
      background-image: url(../img/ico-barometre-blanc.svg); }
    .b-contact__deco.contact-centre-d-education {
      background-image: url(../img/ico-centre-education.svg); }
    .b-contact__deco-simple {
      background: #807BCD;
      background-image: url(../img/ico-contact.svg);
      background-position: center 25px;
      background-repeat: no-repeat;
      width: 90px; }
  .b-contact__address, .b-contact__phone, .b-contact__mail {
    position: relative;
    padding: 0 2.5rem; }
    .b-contact__address:before, .b-contact__phone:before, .b-contact__mail:before {
      content: url(../img/ico-adresse.svg);
      position: absolute;
      left: 0; }
  .b-contact__address:before {
    content: url(../img/ico-adresse.svg); }
  .b-contact__phone:before {
    content: url(../img/ico-tel.svg); }
  .b-contact__mail:before {
    content: url(../img/ico-mail.svg); }
  .b-contact-simple .uk-card-body {
    padding: 2.5rem; }

.item-page {
  overflow: hidden; }
  .item-page .page-header .headline {
    display: flex;
    align-items: center;
    gap: 25px;
    margin: .5rem 0 3.2rem; }
    @media (max-width: 640px) {
      .item-page .page-header .headline {
        flex-wrap: wrap;
        gap: 20px; } }
    .item-page .page-header .headline__icon {
      background: #3CC0CC;
      border-radius: 50%;
      position: relative;
      width: 60px;
      height: 60px; }
      .item-page .page-header .headline__icon img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
    .item-page .page-header .headline h1, .item-page .page-header .headline .h1 {
      margin: unset; }
  .item-page .page-header h1, .item-page .page-header .h1 {
    color: #141D47;
    font-size: 2.4rem;
    font-weight: bold; }
  .item-page .item-image {
    padding-bottom: 35px; }
    .item-page .item-image img {
      max-width: 100%;
      border-radius: 4px; }
    @media (max-width: 640px) {
      .item-page .item-image {
        display: none; } }
  .item-page div[itemprop="articleBody"] {
    padding-bottom: 40px;
    color: #758DA7; }
    .item-page div[itemprop="articleBody"] h2, .item-page div[itemprop="articleBody"] .h2 {
      color: #2F3F8D;
      font-size: 2rem;
      font-weight: bold; }
    .item-page div[itemprop="articleBody"] h3, .item-page div[itemprop="articleBody"] .h3 {
      color: #2F3F8D;
      font-size: 1.6rem;
      font-weight: bold; }
    .item-page div[itemprop="articleBody"] blockquote {
      padding: 15px;
      border-radius: 4px;
      border: 1px solid #758DA7;
      font-size: 1.6rem; }
    .item-page div[itemprop="articleBody"] ul:not(.uk-slider-nav) {
      list-style-type: none;
      padding: 0; }
      .item-page div[itemprop="articleBody"] ul:not(.uk-slider-nav) li {
        position: relative;
        padding-left: 15px;
        margin: 1rem 0; }
        .item-page div[itemprop="articleBody"] ul:not(.uk-slider-nav) li:before {
          content: '';
          display: block;
          width: 8px;
          height: 8px;
          background-color: #3CC0CC;
          border-radius: 10px;
          position: absolute;
          left: 0;
          top: 7px; }
    .item-page div[itemprop="articleBody"] a span {
      color: #141D47;
      font-size: 2rem;
      font-weight: bold; }
    .item-page div[itemprop="articleBody"] a {
      color: #3CC0CC; }
    .item-page div[itemprop="articleBody"] .rl_sliders.accordion > .accordion-group {
      border: none;
      border-radius: inherit;
      border-bottom: 1px solid #141D47; }
    .item-page div[itemprop="articleBody"] .rl_sliders.accordion > .accordion-group > .accordion-body > .accordion-inner {
      border-top: 1px solid #141D47; }
    .item-page div[itemprop="articleBody"] a.bouton {
      background: url(../img/bouton.png) no-repeat 96% 50% #2F3F8D;
      color: #fff;
      border-radius: 4px;
      padding: 10px 50px 10px 20px;
      font-size: 1.3rem;
      transition: all ease-in-out 0.2s; }
      .item-page div[itemprop="articleBody"] a.bouton:hover {
        background-position: 93% 50%;
        transition: all ease-in-out 0.2s; }
    .item-page div[itemprop="articleBody"] a.formulaire {
      background-color: #E55A35;
      color: #fff;
      text-align: center;
      border-radius: 4px;
      padding: 10px 20px;
      font-size: 1.4rem;
      font-weight: bold;
      transition: all ease-in-out 0.2s; }
      .item-page div[itemprop="articleBody"] a.formulaire:hover {
        background-color: #fcaa3a;
        transition: all ease-in-out 0.2s; }
    .item-page div[itemprop="articleBody"] a.bouton-externe {
      background: url(../img/lien-externe.png) no-repeat right 50% #fff;
      color: #3CC0CC;
      padding: 0px 20px 0px 0px;
      font-size: 1.6rem; }
      .item-page div[itemprop="articleBody"] a.bouton-externe:hover {
        text-decoration: underline; }
    .item-page div[itemprop="articleBody"] a.bouton-telechargement {
      background: url(../img/icon-telecharge.png) no-repeat right center #fff;
      color: #3CC0CC;
      padding: 2px 35px 2px 0;
      font-size: 1.6rem; }
      .item-page div[itemprop="articleBody"] a.bouton-telechargement:hover {
        text-decoration: underline; }
    .item-page div[itemprop="articleBody"] .plus_info {
      overflow: hidden;
      border-top: 1px solid #E3E8ED;
      border-bottom: 1px solid #E3E8ED;
      padding: 15px 0; }
      .item-page div[itemprop="articleBody"] .plus_info h4, .item-page div[itemprop="articleBody"] .plus_info .h4 {
        color: #3CC0CC;
        font-size: 1.6rem;
        margin: 0 0 8px; }
      .item-page div[itemprop="articleBody"] .plus_info .texte {
        font-size: 1.6rem;
        color: #2F3F8D; }
        @media (max-width: 1200px) {
          .item-page div[itemprop="articleBody"] .plus_info .texte {
            padding-left: 15px !important; }
            .item-page div[itemprop="articleBody"] .plus_info .texte p {
              margin: 0; } }
      .item-page div[itemprop="articleBody"] .plus_info .bouton {
        display: inline-block;
        margin: 10px 0; }
    .item-page div[itemprop="articleBody"] table {
      border-spacing: 0;
      border-collapse: collapse;
      padding: 10px;
      border: 1px solid #2F3F8D; }
      .item-page div[itemprop="articleBody"] table td {
        border: 1px solid #2F3F8D;
        padding: 10px;
        vertical-align: top; }
        .item-page div[itemprop="articleBody"] table td h3, .item-page div[itemprop="articleBody"] table td .h3 {
          margin: 0;
          text-align: left; }
      .item-page div[itemprop="articleBody"] table th {
        background-color: #E3E8ED;
        text-align: center; }
      .item-page div[itemprop="articleBody"] table.border-0 {
        border: 0; }
        .item-page div[itemprop="articleBody"] table.border-0 td {
          border: 0; }
  .item-page.page-actu {
    margin: auto; }
    @media (min-width: 1200px) {
      .item-page.page-actu {
        width: 80%; } }
    .item-page.page-actu .date {
      color: #758DA7;
      font-size: 1.4rem;
      padding-bottom: 15px;
      display: block; }
    .item-page.page-actu .thematique {
      padding-bottom: 15px;
      font-size: 1.4rem;
      color: #758DA7; }
    .item-page.page-actu .lien-retour-blog {
      margin-top: 15px;
      display: inline-block;
      border: 1px solid #E3E8ED;
      border-radius: 4px;
      text-transform: uppercase;
      font-weight: bold;
      color: #2F3F8D;
      padding: 10px 15px;
      font-size: 1.4rem; }
      .item-page.page-actu .lien-retour-blog span {
        color: #3CC0CC;
        border-radius: 20px;
        border: 2px solid #3CC0CC;
        font-weight: bold;
        margin-right: 10px; }
      .item-page.page-actu .lien-retour-blog:hover {
        background-color: #E3E8ED; }
    .item-page.page-actu .item-image {
      padding-bottom: 35px; }
      .item-page.page-actu .item-image img {
        max-width: 100%;
        border-radius: 4px; }
  .item-page.page-evenement {
    margin: auto; }
    @media (min-width: 1200px) {
      .item-page.page-evenement {
        width: 80%; } }
    .item-page.page-evenement h1, .item-page.page-evenement .h1 {
      color: #E55A35; }
    .item-page.page-evenement .date {
      color: #E55A35;
      font-size: 1.6rem;
      padding-bottom: 15px;
      display: block;
      margin: 0;
      font-weight: bold; }
      .item-page.page-evenement .date span {
        margin-right: 10px; }
    .item-page.page-evenement .thematique {
      padding-bottom: 15px;
      font-size: 1.4rem;
      color: #758DA7; }
    .item-page.page-evenement .lien-retour-blog {
      margin-top: 15px;
      display: inline-block;
      border: 1px solid #E3E8ED;
      border-radius: 4px;
      text-transform: uppercase;
      font-weight: bold;
      color: #2F3F8D;
      padding: 10px 15px;
      font-size: 1.4rem; }
      .item-page.page-evenement .lien-retour-blog span {
        color: #3CC0CC;
        border-radius: 20px;
        border: 2px solid #3CC0CC;
        font-weight: bold;
        margin-right: 10px; }
      .item-page.page-evenement .lien-retour-blog:hover {
        background-color: #E3E8ED; }
    .item-page.page-evenement .item-image {
      padding-bottom: 35px; }
      .item-page.page-evenement .item-image img {
        max-width: 100%;
        border-radius: 4px; }

.phocadownloadfile-32 a {
  width: 400px;
  border: 1px solid #E4E7EA;
  display: inline-block;
  border-radius: 4px;
  transition: all 0.2s ease-in-out; }
  @media (max-width: 980px) {
    .phocadownloadfile-32 a {
      width: 99%; } }
  .phocadownloadfile-32 a:hover {
    box-shadow: 0 0 5px 0 #E4E7EA;
    transition: all 0.2s ease-in-out; }

.phocadownloadfile-32 .bloc-fichier {
  padding: 10px 10px 8px; }

.phocadownloadfile-32 .nom_fichier {
  font-size: 1.4rem !important;
  display: block; }

.phocadownloadfile-32 .icones {
  position: relative;
  left: 15px;
  display: block;
  height: 37px;
  width: 33px; }

.phocadownloadfile-32 .poid_fichier {
  font-size: 1.4rem !important;
  font-weight: normal !important;
  color: #959FAB;
  display: block;
  width: 100%; }

.main_recette {
  border: 1px solid #ebebeb;
  margin: auto; }
  .main_recette .recette1 {
    padding: 0;
    margin: auto; }
    .main_recette .recette1 .item1 {
      background-color: #304D6D;
      height: 167px;
      margin: 15px;
      padding: 0; }
      .main_recette .recette1 .item1 div {
        border: 4px solid #00a8c6;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        margin: auto;
        margin-top: 55px; }
    .main_recette .recette1 .item2 {
      padding: 15px;
      padding-left: 0;
      margin-left: 10px; }
      .main_recette .recette1 .item2 ul {
        padding-left: 42px; }
        .main_recette .recette1 .item2 ul li {
          width: 200px;
          margin: 0 12px 15px 0; }
  .main_recette .recette2 {
    background-color: #304D6D;
    width: 100%;
    margin: auto;
    padding: 0; }
    .main_recette .recette2 .h2 {
      color: #fff;
      text-align: center;
      margin-top: 15px; }
    .main_recette .recette2 .rec_item {
      color: #fff; }
      .main_recette .recette2 .rec_item h2, .main_recette .recette2 .rec_item .h2 {
        color: #fff;
        margin: 0; }
      .main_recette .recette2 .rec_item p {
        margin-top: 4px; }

.category-module_intro .bloc-intro {
  padding-top: 10px; }
  .category-module_intro .bloc-intro .mod-articles-category-introtext {
    color: #fff;
    font-size: 1.6rem;
    opacity: 0.7;
    line-height: 18px;
    height: 55px; }
  .category-module_intro .bloc-intro h2, .category-module_intro .bloc-intro .h2 {
    color: #fff;
    font-size: 2.4rem;
    font-weight: 500;
    margin-bottom: 19px; }
  .category-module_intro .bloc-intro a {
    background-color: #807BCD;
    border-radius: 3px 3px 0 0;
    color: #fff;
    transition: 0.4s ease-in-out; }
    .category-module_intro .bloc-intro a span {
      color: #494769;
      font-size: 1.2rem;
      transition: 0.4s ease-in-out; }
      .category-module_intro .bloc-intro a span.uk-icon {
        transition: 0.4s ease-in-out; }
        .category-module_intro .bloc-intro a span.uk-icon svg {
          color: #fff; }
    .category-module_intro .bloc-intro a strong {
      color: #fff;
      font-size: 1.6rem; }
    .category-module_intro .bloc-intro a:hover {
      background-color: #928dd4;
      transition: 0.4s ease-in-out; }
      .category-module_intro .bloc-intro a:hover span {
        color: #fff;
        transition: 0.4s ease-in-out; }
      .category-module_intro .bloc-intro a:hover .uk-icon {
        transition: 0.4s ease-in-out;
        padding-right: 8px; }
  .category-module_intro .bloc-intro .bloc-bouton {
    padding-top: 15px; }

@media (max-width: 640px) {
  .category-module_intro {
    padding-bottom: 15px;
    position: relative;
    z-index: 3; }
    .category-module_intro .bloc-intro {
      padding-top: 0; }
      .category-module_intro .bloc-intro .mod-articles-category-introtext {
        height: auto; }
      .category-module_intro .bloc-intro h2, .category-module_intro .bloc-intro .h2 {
        margin-top: 0; }
    .category-module_intro .bloc-bouton a {
      border-radius: 5px; } }

.category-module_actu a {
  color: #2F3F8D;
  border-radius: 0 0 3px 3px; }

.category-module_actu h3, .category-module_actu .h3 {
  color: #2F3F8D;
  font-size: 2.4rem;
  font-weight: bold;
  padding-bottom: 31px; }
  .category-module_actu h3 .tout-actu, .category-module_actu .h3 .tout-actu {
    color: #fff;
    background-color: #2F3F8D;
    text-transform: uppercase;
    font-weight: bold;
    border-radius: 3px;
    font-size: 1.3rem;
    padding: 8px 32px;
    display: inline-block;
    float: right; }

.category-module_actu h4, .category-module_actu .h4 {
  margin-top: 15px;
  font-size: 2rem;
  font-weight: bold;
  color: #2F3F8D;
  height: 55px; }

.category-module_actu .mod-articles-category-introtext {
  color: #2F3F8D; }

.category-module_actu .intro-image {
  border-radius: 3px 3px 0 0; }

.view-category .category-module_actu {
  margin-bottom: 25px; }
  .view-category .category-module_actu a {
    border: 1px solid rgba(117, 141, 167, 0.2);
    border-radius: 6px; }
    .view-category .category-module_actu a .bloc-texte {
      padding: 15px 25px 15px 0; }

@media (max-width: 959px) {
  .category-module_actu .tout-actu-mobile {
    color: #2F3F8D;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    border-radius: 3px;
    font-size: 1.3rem;
    padding: 8px 32px;
    margin: 20px 15px 10px;
    display: block;
    border: 1px solid #2F3F8D; }
  .view-article .category-module_actu .tout-actu-mobile {
    margin: 20px 0 10px; } }

@media (max-width: 640px) {
  .category-module_actu h3, .category-module_actu .h3 {
    padding: 0 15px 20px; }
  .category-module_actu .intro-image {
    padding-top: 15px;
    width: 100%; }
    .category-module_actu .intro-image img {
      border-radius: 4px;
      width: 100%;
      margin: 0 auto; }
  .category-module_actu .tout-actu-mobile {
    color: #2F3F8D;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    border-radius: 3px;
    font-size: 1.3rem;
    padding: 8px 32px;
    margin: 20px 15px 10px;
    display: block;
    border: 1px solid #2F3F8D; } }

@media (min-width: 640px) and (max-width: 1200px) {
  .category-module_actu h3, .category-module_actu .h3 {
    margin: 0 15px !important; } }

.category-module_mea_libre .mod-articles-category-introtext {
  color: #758DA7;
  font-size: 1.4rem;
  opacity: 0.7;
  line-height: 18px;
  height: 55px;
  transition: 0.4s ease-in-out;
  overflow: hidden; }

.category-module_mea_libre h2, .category-module_mea_libre .h2 {
  color: #6A65A7;
  font-size: 2rem;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  height: 55px;
  overflow: hidden;
  margin: 0 0 12px; }
  .category-module_mea_libre h2.detail, .category-module_mea_libre .detail.h2 {
    color: #2F3F8D; }

.category-module_mea_libre a {
  color: #3CC0CC; }
  .category-module_mea_libre a:hover .mod-articles-category-introtext {
    transition: 0.4s ease-in-out;
    color: #6A65A7; }
  @media (max-width: 640px) {
    .category-module_mea_libre a.detail {
      padding-left: 0; } }
  .category-module_mea_libre a.detail h2, .category-module_mea_libre a.detail .h2 {
    color: #2F3F8D; }
  .category-module_mea_libre a.detail:hover .mod-articles-category-introtext {
    transition: 0.4s ease-in-out;
    color: #6A89AB; }

.category-module_mea_libre .bloc-texte {
  padding-bottom: 40px; }

.category-module_mea_libre .intro-image-default {
  background-color: #2F3F8D;
  width: 168px;
  height: 167px; }
  @media (max-width: 1024px) {
    .category-module_mea_libre .intro-image-default {
      width: 100%;
      height: auto; } }

@media (max-width: 1279px) {
  .introduction-categorie div .item-image {
    display: block;
    margin: 0 auto;
    float: none;
    text-align: center; }
  .category-module_mea_libre {
    margin: 15px 0 !important;
    padding-left: 0; }
    .category-module_mea_libre .mod-articles-category-introtext {
      height: auto; }
    .category-module_mea_libre h2, .category-module_mea_libre .h2 {
      height: auto;
      font-size: 2rem; }
    .category-module_mea_libre span {
      position: relative !important; }
    .category-module_mea_libre .bloc-texte {
      padding-left: 10px;
      padding-bottom: 0; }
    .category-module_mea_libre .detail {
      padding-left: 10px !important; } }

@media (max-width: 639px) {
  .category-module_mea_libre a > .uk-child-width-1-2 > .uk-first-column {
    width: calc(100% * 1 / 3.001); }
  .category-module_mea_libre a > .uk-child-width-1-2 > .bloc-texte {
    width: calc(100% * 2 / 3.001); }
  .category-module_mea_libre a > .uk-child-width-1-2 .uk-position-bottom {
    display: none; } }

.moduletable_thematique .uk-nav {
  bottom: 15px;
  padding: 33px 32px 25px 32px; }
  .moduletable_thematique .uk-nav .nav-header {
    color: #3CC0CC;
    font-size: 2.4rem;
    font-weight: bold;
    padding-bottom: 10px;
    display: block; }
  .moduletable_thematique .uk-nav li {
    margin: 0 0 12px; }
    .moduletable_thematique .uk-nav li:last-child {
      margin: 0; }

.moduletable_thematique img {
  margin-right: 15px; }

.moduletable_thematique a {
  padding: 0 !important;
  color: #758DA7;
  font-size: 1.6rem; }
  .moduletable_thematique a span {
    transition: 0.4s ease-in-out; }
  .moduletable_thematique a:hover {
    color: #3CC0CC; }
    .moduletable_thematique a:hover span {
      padding-left: 10px;
      transition: 0.4s ease-in-out; }

.thematique-responsive {
  background-color: #6A65A7;
  padding: 0 15px;
  margin-top: 55px; }
  .thematique-responsive .uk-accordion {
    position: relative;
    bottom: 30px; }
  .thematique-responsive .uk-open .uk-accordion-title {
    background-color: #fff;
    color: #3CC0CC !important; }
    .thematique-responsive .uk-open .uk-accordion-title .bouton-haut {
      display: block; }
    .thematique-responsive .uk-open .uk-accordion-title .bouton-bas {
      display: none; }
  .thematique-responsive .uk-accordion-title {
    background-color: #3CC0CC;
    padding: 15px 30px 15px 35px;
    color: #fff !important;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-size: 2rem; }
    .thematique-responsive .uk-accordion-title .bouton-haut {
      display: none; }
    .thematique-responsive .uk-accordion-title .bouton-bas {
      display: block; }
  .thematique-responsive .uk-accordion-content {
    margin-top: 10px; }
  .thematique-responsive .menu {
    margin: 0 !important;
    border-radius: 0 0 8px 8px;
    padding-top: 10px; }
    .thematique-responsive .menu li:first-child {
      display: none; }

.category-module_evenement a {
  color: #E55A35; }
  .category-module_evenement a:hover .uk-icon {
    transition: 0.4s ease-in-out;
    padding-right: 8px; }
  .category-module_evenement a:hover .mod-articles-category-introtext {
    transition: 0.4s ease-in-out;
    color: #E55A35; }

.category-module_evenement h3, .category-module_evenement .h3 {
  color: #E55A35;
  font-size: 2.4rem;
  font-weight: bold;
  padding-bottom: 31px; }

.category-module_evenement .mod-articles-category-date strong {
  font-size: 2.4rem; }

.category-module_evenement .uk-icon {
  color: #2E2E2E;
  transition: 0.4s ease-in-out; }

.category-module_evenement .uk-background-default {
  padding: 32px; }

.category-module_evenement .bloc-texte {
  margin-left: 35px;
  width: 200px;
  padding-right: 10px;
  padding-left: 0; }
  @media (max-width: 1024px) {
    .category-module_evenement .bloc-texte {
      margin-left: 15px; } }

.category-module_evenement strong {
  font-weight: bold; }

.category-module_evenement .mod-articles-category-introtext {
  color: #758DA7;
  transition: 0.4s ease-in-out; }

.view-category .category-module_evenement a {
  border: 1px solid rgba(117, 141, 167, 0.2);
  padding: 10px 0; }

.view-category .category-module_evenement .mod-articles-category-date {
  margin-left: 20px; }

@media (max-width: 959px) {
  .category-module_evenement .tout-evenements {
    color: #E55A35;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    border-radius: 3px;
    font-size: 1.3rem;
    padding: 8px 32px;
    margin: 20px 15px 10px;
    display: block;
    border: 1px solid #E55A35; }
  .view-article .category-module_evenement .tout-evenements {
    margin: 20px 0 10px; } }

@media (max-width: 640px) {
  .category-module_evenement h3, .category-module_evenement .h3 {
    padding-left: 15px; }
  .category-module_evenement .uk-background-default {
    margin: 0 15px;
    padding: 12px; }
  .category-module_evenement .chevron {
    padding-left: 0;
    display: block; } }

@media (min-width: 640px) and (max-width: 1024px) {
  .category-module_evenement h3, .category-module_evenement .h3 {
    margin: 0 15px !important; }
  .category-module_evenement .chevron {
    display: none; } }

.menu-outils {
  background-color: #3CC0CC;
  border-radius: 3px 0 0 3px;
  padding: 26px; }
  .menu-outils button {
    border-radius: 15px;
    margin-bottom: 5px;
    background-color: #2F3F8D;
    color: #3CC0CC;
    padding: 5px 5px 5px 6px; }
    .menu-outils button:hover {
      background-color: #fff;
      color: #2F3F8D; }
  .menu-outils .text-outil {
    display: block;
    color: #fff;
    float: right;
    width: 154px; }
  .menu-outils ul {
    padding-top: 10px; }
    .menu-outils ul li {
      padding: 25px 0 0; }
      .menu-outils ul li a {
        color: #fff; }
        .menu-outils ul li a span {
          margin-right: 25px; }

.category-module_mea_couleurs div:nth-child(1) {
  background-color: #3CC0CC; }

.category-module_mea_couleurs div:nth-child(2) {
  background-color: #141D47; }

.category-module_mea_couleurs div:nth-child(3) {
  background-color: #6A65A7; }

.category-module_mea_couleurs p {
  font-size: 1.6rem;
  color: rgba(255, 255, 255, 0.5);
  margin-top: 0; }

.category-module_mea_couleurs h2, .category-module_mea_couleurs .h2 {
  color: #fff;
  font-size: 2.3rem;
  font-weight: bold;
  margin-bottom: 5px; }

.category-module_mea_couleurs a {
  color: #2F3F8D;
  font-size: 1.3rem;
  background-color: #fff;
  width: 250px; }

@media (max-width: 1024px) {
  .category-module_mea_couleurs h2, .category-module_mea_couleurs .h2 {
    margin: 15px 0; }
  .category-module_mea_couleurs p {
    display: none; }
  .category-module_mea_couleurs br {
    display: none; } }

@media (max-width: 640px) {
  .category-module_mea_couleurs img {
    float: left;
    width: 40px;
    margin-right: 15px; }
  .category-module_mea_couleurs h2, .category-module_mea_couleurs .h2 {
    font-size: 1.5rem;
    margin: 0;
    position: relative;
    top: 5px; }
  .category-module_mea_couleurs p {
    display: none; }
  .category-module_mea_couleurs a {
    clear: both;
    width: 100%;
    margin-top: 15px; }
  .category-module_mea_couleurs .uk-padding {
    padding: 15px; } }

.bannergroup_partenaires h2, .bannergroup_partenaires .h2 {
  color: #2F3F8D;
  font-size: 2.4rem;
  font-weight: bold;
  margin-bottom: 5px; }

@media (min-width: 960px) {
  .bannergroup_partenaires .uk-slidenav-previous {
    left: -25px; }
  .bannergroup_partenaires .uk-slidenav-next {
    right: -25px; } }

.tags_dokamo_search {
  position: relative;
  padding: 18px; }
  .tags_dokamo_search li:nth-child(2n+3) a, .tags_dokamo_search li:nth-child(2) a {
    background-color: #6A65A7; }
  .tags_dokamo_search li:nth-child(3n+3) a {
    background-color: #E55A35; }
  .tags_dokamo_search li a {
    font-size: 1.4rem;
    font-family: "Roboto", sans-serif;
    padding: 7px 12px;
    background-color: #3CC0CC;
    color: #fff;
    border-radius: 3px !important;
    display: block;
    margin: 0 10px; }
  .tags_dokamo_search .uk-slidenav {
    font-size: 1.4rem;
    font-family: "Roboto", sans-serif;
    padding: 7px 15px !important;
    background-color: #F8F8F8 !important;
    color: #758DA7 !important;
    display: block; }

#line-loader {
  width: 0%;
  height: 1px;
  border: 1px solid #5BD5AC;
  background: #5BD5AC;
  margin: auto;
  margin-top: -1px; }

.module_dokamo_search {
  z-index: 3;
  width: 510px; }
  @media (min-width: 1024px) {
    .module_dokamo_search {
      width: 400px; } }
  @media (min-width: 960px) {
    .module_dokamo_search {
      position: absolute;
      margin-left: -20px;
      background-color: #fff; } }
  @media (max-width: 640px) {
    .module_dokamo_search {
      width: auto;
      margin: 15px;
      position: relative !important; } }
  .module_dokamo_search #form_dokamo_search {
    background: #fff;
    padding: 5%;
    padding-left: 0;
    padding-right: 0; }
    .module_dokamo_search #form_dokamo_search input#input_mod_dokamo_search_query {
      border: 0;
      border-bottom: 1px solid rgba(47, 63, 141, 0.15);
      color: rgba(47, 63, 141, 0.58);
      width: 90%;
      font-size: 1.2em;
      background: url(../img/loupe.png) no-repeat right top; }
      @media (max-width: 640px) {
        .module_dokamo_search #form_dokamo_search input#input_mod_dokamo_search_query {
          width: 80%; } }
  .module_dokamo_search #no_result {
    display: none;
    transmition-duration: 0.5s;
    padding: 20px; }
    .module_dokamo_search #no_result.active {
      display: block; }
  .module_dokamo_search #hint {
    font-size: 0.8em;
    transition-duration: 0.5s;
    display: none;
    padding: 20px; }
    .module_dokamo_search #hint.active {
      display: block; }
  .module_dokamo_search #results_dokamo_search {
    box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16); }
    .module_dokamo_search #results_dokamo_search ul {
      margin-bottom: 0; }
      .module_dokamo_search #results_dokamo_search ul li {
        padding-left: 5%; }
        .module_dokamo_search #results_dokamo_search ul li:nth-child(even) {
          background: rgba(29, 61, 102, 0.07); }
        .module_dokamo_search #results_dokamo_search ul li:hover {
          background: rgba(35, 55, 109, 0.09); }
        .module_dokamo_search #results_dokamo_search ul li h3, .module_dokamo_search #results_dokamo_search ul li .h3 {
          margin-bottom: 0; }
        .module_dokamo_search #results_dokamo_search ul li .content_type {
          color: #00000080;
          text-transform: capitalize;
          font-size: 12px; }

@media (min-width: 640px) {
  .module_dokamo_search-bandeau {
    display: none !important; } }

.module_dokamo_search-intro {
  box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16);
  top: -80px;
  right: 0; }
  @media (max-width: 640px) {
    .module_dokamo_search-intro {
      display: none !important; } }

body.alias-supports-pro #module_dokamo_search #form_dokamo_search {
  background: #e8e7ff; }

.sk-folding-cube {
  margin: 10px auto;
  width: 20px;
  height: 20px;
  position: relative;
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
  display: none; }

.sk-folding-cube .sk-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1); }

.sk-folding-cube .sk-cube:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1e87f0;
  -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
  animation: sk-foldCubeAngle 2.4s infinite linear both;
  -webkit-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%; }

.sk-folding-cube .sk-cube2 {
  -webkit-transform: scale(1.1) rotateZ(90deg);
  transform: scale(1.1) rotateZ(90deg); }

.sk-folding-cube .sk-cube3 {
  -webkit-transform: scale(1.1) rotateZ(180deg);
  transform: scale(1.1) rotateZ(180deg); }

.sk-folding-cube .sk-cube4 {
  -webkit-transform: scale(1.1) rotateZ(270deg);
  transform: scale(1.1) rotateZ(270deg); }

.sk-folding-cube .sk-cube2:before {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s; }

.sk-folding-cube .sk-cube3:before {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s; }

.sk-folding-cube .sk-cube4:before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s; }

@-webkit-keyframes sk-foldCubeAngle {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0; }
  25%, 75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1; }
  90%, 100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0; } }

@keyframes sk-foldCubeAngle {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0; }
  25%, 75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1; }
  90%, 100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0; } }

.com_dokamo_search {
  background-color: #F9F8F8; }
  .com_dokamo_search .bandeau {
    margin-bottom: 0 !important; }
  .com_dokamo_search .colonne-gauche-recherche {
    margin-top: 25px; }
  .com_dokamo_search .mea_filtre_recherche {
    background-color: #fff;
    padding: 20px 25px;
    border-radius: 3px;
    border: 1px solid #E3E8ED; }
    .com_dokamo_search .mea_filtre_recherche h2, .com_dokamo_search .mea_filtre_recherche .h2 {
      color: #2F3F8D;
      font-size: 1.8rem;
      font-weight: bold; }
      .com_dokamo_search .mea_filtre_recherche h2 span, .com_dokamo_search .mea_filtre_recherche .h2 span {
        padding-right: 15px;
        color: #3CC0CC;
        font-weight: bold; }
    .com_dokamo_search .mea_filtre_recherche h3, .com_dokamo_search .mea_filtre_recherche .h3 {
      color: #758DA7;
      font-size: 1.4rem;
      margin: 0;
      padding-top: 20px;
      padding-bottom: 12px; }
    .com_dokamo_search .mea_filtre_recherche label {
      text-transform: capitalize;
      color: #2F3F8D;
      font-size: 1.2rem; }
    .com_dokamo_search .mea_filtre_recherche .liste-type div {
      text-transform: capitalize;
      color: #2F3F8D;
      font-size: 1.2rem; }
      .com_dokamo_search .mea_filtre_recherche .liste-type div input {
        margin-right: 5px; }
    .com_dokamo_search .mea_filtre_recherche .bouton-filtre {
      padding-top: 20px; }
      .com_dokamo_search .mea_filtre_recherche .bouton-filtre button {
        width: 100%;
        display: block;
        text-align: center; }
      .com_dokamo_search .mea_filtre_recherche .bouton-filtre .bouton-appliquer {
        background-color: #2F3F8D;
        border-radius: 3px;
        text-transform: uppercase;
        font-family: "Roboto", sans-serif;
        font-weight: 700;
        font-size: 1.3rem; }
      .com_dokamo_search .mea_filtre_recherche .bouton-filtre .bouton-reinit {
        color: #758DA7;
        text-decoration: underline;
        margin-top: 8px;
        font-size: 1.4rem;
        font-family: "Roboto", sans-serif; }
    .com_dokamo_search .mea_filtre_recherche .uk-radio:checked {
      background-color: #2F3F8D;
      padding: 10px; }
    .com_dokamo_search .mea_filtre_recherche .uk-checkbox:checked, .com_dokamo_search .mea_filtre_recherche .uk-checkbox:indeterminate {
      background-color: #2F3F8D;
      border-radius: 5px;
      padding: 10px; }
    .com_dokamo_search .mea_filtre_recherche .chzn-single {
      padding: 8px 15px;
      border-radius: 4px;
      border: 1px solid #E3E8ED;
      color: #2F3F8D;
      background: none #fff;
      height: auto;
      box-shadow: none;
      font-size: 1.2rem; }
  @media (min-width: 960px) {
    .com_dokamo_search .colonne-gauche-recherche {
      padding-right: 50px; } }
  .com_dokamo_search .main {
    padding-top: 0px; }
    .com_dokamo_search .main .main {
      margin-top: 25px; }
      .com_dokamo_search .main .main h2, .com_dokamo_search .main .main .h2 {
        color: #141D47;
        font-size: 2.4rem;
        font-weight: bold; }
        @media (min-width: 1024px) {
          .com_dokamo_search .main .main h2, .com_dokamo_search .main .main .h2 {
            margin-top: 20px; } }
        .com_dokamo_search .main .main h2 input, .com_dokamo_search .main .main .h2 input {
          background-color: #fff;
          color: #2F3F8D;
          padding: 5px 0 5px 15px;
          border-radius: 4px 0 0 4px;
          margin-left: 15px;
          font-size: 1.4rem;
          font-weight: normal;
          text-transform: capitalize; }
          @media (max-width: 640px) {
            .com_dokamo_search .main .main h2 input, .com_dokamo_search .main .main .h2 input {
              margin-left: 0;
              margin-top: 10px; } }
        .com_dokamo_search .main .main h2 button, .com_dokamo_search .main .main .h2 button {
          background-color: #fff;
          padding: 0 10px;
          border-radius: 0 4px 4px 0; }
          @media (max-width: 640px) {
            .com_dokamo_search .main .main h2 button, .com_dokamo_search .main .main .h2 button {
              margin-top: 10px; } }
      .com_dokamo_search .main .main .resultats h2, .com_dokamo_search .main .main .resultats .h2 {
        font-size: 1.8rem;
        color: #2F3F8D; }
      .com_dokamo_search .main .main .resultats > li {
        padding: 0;
        position: relative; }
        .com_dokamo_search .main .main .resultats > li a {
          display: block;
          background-color: #fff;
          padding: 20px 25px;
          border-radius: 3px;
          border: 1px solid #E3E8ED;
          margin-bottom: 15px;
          color: #758DA7; }
        .com_dokamo_search .main .main .resultats > li:hover h2, .com_dokamo_search .main .main .resultats > li:hover .h2 {
          color: #3CC0CC; }
        .com_dokamo_search .main .main .resultats > li .btn-lien-copy {
          position: absolute;
          right: 15px;
          bottom: 30px;
          z-index: 4;
          padding: 5px 20px;
          background-color: #fff;
          color: #2F3F8D;
          border: 1px solid #2F3F8D;
          border-radius: 3px;
          font-family: "Roboto", sans-serif;
          font-weight: 700;
          font-size: 1.3rem;
          cursor: pointer;
          transition: all ease-in-out 0.2s; }
          .com_dokamo_search .main .main .resultats > li .btn-lien-copy:hover {
            background-color: #3CC0CC;
            color: #fff;
            border-color: #fff;
            transition: all ease-in-out 0.2s; }
        .com_dokamo_search .main .main .resultats > li .btn-lien-copy-end {
          padding: 5px 20px;
          background-color: #3CC0CC;
          color: #fff;
          border-color: #fff;
          border-radius: 3px;
          font-family: "Roboto", sans-serif;
          font-weight: 700;
          font-size: 1.3rem; }
      .com_dokamo_search .main .main .resultats p img {
        float: left;
        margin: 0 15px 15px 0; }
        @media (min-width: 1024px) {
          .com_dokamo_search .main .main .resultats p img {
            width: 168px;
            height: 168px;
            object-fit: contain; } }
      .com_dokamo_search .main .main .resultats hr {
        clear: both; }
      @media (max-width: 640px) {
        .com_dokamo_search .main .main .info-label {
          position: relative !important;
          margin: 0 !important;
          padding: 0 0 15px 0; }
          .com_dokamo_search .main .main .info-label span {
            margin: 0 !important; } }
      .com_dokamo_search .main .main .info-label span {
        border-radius: 4px;
        padding: 2px 15px;
        font-size: 1.2rem;
        background-color: #758DA7;
        color: #fff;
        margin-left: 5px;
        text-transform: capitalize; }
        .com_dokamo_search .main .main .info-label span.public {
          background-color: #2F3F8D;
          color: #fff; }
        .com_dokamo_search .main .main .info-label span.pro {
          background-color: #5BD5AC;
          color: #fff; }
      .com_dokamo_search .main .main .annee {
        border-radius: 4px;
        padding: 2px 12px;
        font-size: 1.2rem;
        background-color: #959FAB;
        border: 1px solid #959FAB;
        color: #fff;
        margin-left: 10px; }
      .com_dokamo_search .main .main .label-tags, .com_dokamo_search .main .main .label-thematique {
        padding-left: 5px; }
        .com_dokamo_search .main .main .label-tags span, .com_dokamo_search .main .main .label-thematique span {
          padding: 0;
          background: none;
          font-size: 1.4rem;
          color: #758DA7;
          margin-left: 5px; }
    .com_dokamo_search .main .fenetre-recherche {
      margin-top: 0;
      background: url(../../images/bandeau_recherche.jpg) no-repeat center top transparent; }
      @media (min-width: 1024px) {
        .com_dokamo_search .main .fenetre-recherche {
          background-size: 100% auto; } }
      @media (max-width: 1024px) {
        .com_dokamo_search .main .fenetre-recherche {
          background-size: auto 100%; } }
      .com_dokamo_search .main .fenetre-recherche .recherche-haut {
        background-color: #F9F8F8;
        color: #758DA7;
        padding: 25px;
        border-radius: 4px 4px 0 0; }
        .com_dokamo_search .main .fenetre-recherche .recherche-haut p {
          margin: 0; }
        .com_dokamo_search .main .fenetre-recherche .recherche-haut h1, .com_dokamo_search .main .fenetre-recherche .recherche-haut .h1 {
          color: #2F3F8D;
          font-size: 2rem;
          font-weight: bold; }
      .com_dokamo_search .main .fenetre-recherche .recherche-bas {
        background-color: #fff;
        color: #758DA7;
        border-radius: 0 0 4px 4px;
        padding: 25px; }
        .com_dokamo_search .main .fenetre-recherche .recherche-bas input {
          background-color: #fff;
          border-bottom: 1px solid #E4E7EA;
          padding-left: 0; }
          .com_dokamo_search .main .fenetre-recherche .recherche-bas input::placeholder {
            color: #758DA7; }
        .com_dokamo_search .main .fenetre-recherche .recherche-bas button {
          background-color: #2F3F8D;
          color: #fff;
          border-radius: 4px;
          text-transform: uppercase;
          font-size: 1.4rem; }
      .com_dokamo_search .main .fenetre-recherche .fenetre {
        margin-top: 140px;
        margin-bottom: 140px; }
  .com_dokamo_search #pagination {
    padding-bottom: 35px;
    color: #2F3F8D;
    font-size: 1.4rem; }
    .com_dokamo_search #pagination #prev-page {
      padding-left: 15px;
      padding-right: 8px;
      color: #E3E8ED; }
    .com_dokamo_search #pagination #next-page {
      padding-right: 15px;
      padding-left: 8px;
      color: #2F3F8D; }
    .com_dokamo_search #pagination .en-cours {
      border: 1px solid #E3E8ED;
      border-radius: 4px;
      padding: 2px 6px 0;
      font-size: 1.4rem;
      color: #5BD5AC;
      position: relative;
      bottom: 3px; }

.titre_formulaire {
  color: #2F3F8D;
  font-size: 24px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  margin-top: 0; }

.formulaire_faq {
  padding: 24px 32px;
  background-color: #304d6d; }
  @media (min-width: 640px) {
    .formulaire_faq {
      background: url(../img/bg_mea_faq.png) no-repeat right 50% #304d6d; } }
  .formulaire_faq h2, .formulaire_faq .h2 {
    color: #fff;
    font-size: 24px;
    background: url(../img/picto_mea_faq.png) no-repeat left top;
    padding-left: 50px;
    font-family: "Roboto", sans-serif;
    font-weight: 500; }
  .formulaire_faq .uk-select, .formulaire_faq .uk-textarea {
    width: 100%; }
  .formulaire_faq input, .formulaire_faq select, .formulaire_faq textarea {
    background-color: #fff;
    padding: 10px 16px;
    color: #758DA7; }
    .formulaire_faq input::placeholder, .formulaire_faq select::placeholder, .formulaire_faq textarea::placeholder {
      color: #758DA7; }
  .formulaire_faq button {
    background-color: #3CC0CC;
    padding: 2px 80px 0;
    text-transform: uppercase;
    font-size: 1.3rem; }
  @media (min-width: 640px) {
    .formulaire_faq .champs {
      padding-right: 24px; } }
  .formulaire_faq .texte-rgpd {
    color: #999; }
    .formulaire_faq .texte-rgpd a {
      color: #fff; }

#faq-poser-une-question-form {
  display: none; }

.bannergroup_sante-en-chiffre {
  padding: 30px 0; }
  .bannergroup_sante-en-chiffre h3, .bannergroup_sante-en-chiffre .h3 {
    color: #2F3F8D;
    font-size: 2.4rem;
    font-weight: bold;
    margin: 0; }
  .bannergroup_sante-en-chiffre .tous-chiffres {
    color: #fff;
    background-color: #2F3F8D;
    text-transform: uppercase;
    font-weight: bold;
    border-radius: 3px;
    font-size: 1.3rem;
    padding: 8px 32px;
    display: inline-block; }
    @media (max-width: 959px) {
      .bannergroup_sante-en-chiffre .tous-chiffres {
        color: #2F3F8D;
        background-color: #fff;
        text-transform: uppercase;
        text-align: center;
        font-weight: bold;
        border-radius: 3px;
        font-size: 1.3rem;
        padding: 8px 32px;
        margin: 20px 0 0 30px;
        display: block;
        border: 1px solid #2F3F8D; } }

.moduletable_pied_page {
  padding: 50px 0; }
  .moduletable_pied_page ul {
    list-style-type: none;
    padding: 0;
    width: 100%; }
    .moduletable_pied_page ul li a {
      color: #fff;
      font-size: 1.4rem;
      padding: 6px 0px 6px 0; }
      .moduletable_pied_page ul li a:hover {
        color: #3CC0CC; }
    .moduletable_pied_page ul li.banneritem a {
      padding: 0 10px; }
    .moduletable_pied_page ul li.deeper .nav-header {
      font-size: 1.6rem;
      padding: 6px 0 15px;
      display: block;
      color: #fff; }
    .moduletable_pied_page ul li.deeper:nth-child(4) a {
      font-weight: bold;
      padding-bottom: 10px;
      display: block;
      font-size: 1.6rem; }
  .moduletable_pied_page > ul > li {
    padding: 0 15px; }
    .moduletable_pied_page > ul > li:first-child {
      padding-left: 0; }
    .moduletable_pied_page > ul > li:last-child {
      padding-right: 0; }
  .moduletable_pied_page .mea_pro {
    background-color: #fff;
    border-radius: 3px;
    display: block;
    width: 100%;
    padding: 15px; }
    .moduletable_pied_page .mea_pro .titre {
      color: #2F3F8D;
      font-family: "Roboto", sans-serif;
      font-weight: 500;
      font-size: 1.6rem;
      margin-bottom: 5px; }
    .moduletable_pied_page .mea_pro .sous-titre {
      color: #758DA7;
      margin-top: 5px; }
    .moduletable_pied_page .mea_pro span {
      background-color: #5BD5AC;
      color: #fff;
      border-radius: 3px;
      display: block;
      text-transform: uppercase;
      padding: 8px;
      text-align: center; }
    .moduletable_pied_page .mea_pro:hover span {
      color: #141D47; }

@media (max-width: 1024px) {
  .menu-pre-footer .moduletable_pied_page {
    position: relative !important;
    padding-bottom: 17px; }
    .menu-pre-footer .moduletable_pied_page .nav-header {
      padding: 33px 0 20px !important;
      display: block;
      font-size: 16px;
      font-weight: bold; }
    .menu-pre-footer .moduletable_pied_page ul {
      display: block; }
      .menu-pre-footer .moduletable_pied_page ul li ul li {
        padding-bottom: 10px; }
      .menu-pre-footer .moduletable_pied_page ul li a {
        padding: 0; }
      .menu-pre-footer .moduletable_pied_page ul li.item-147 {
        padding-top: 30px; }
      .menu-pre-footer .moduletable_pied_page ul li.item-147 + li a, .menu-pre-footer .moduletable_pied_page ul li.item-147 + li + li a, .menu-pre-footer .moduletable_pied_page ul li.item-147 + li + li + li a {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 16px;
        display: block; } }

.menu-footer {
  padding: 10px 0; }
  .menu-footer ul {
    list-style-type: none;
    padding: 0; }
    .menu-footer ul li {
      color: #fff;
      font-size: 1.4rem; }
      .menu-footer ul li:before {
        content: '-';
        padding: 0 8px;
        float: left; }
      .menu-footer ul li a {
        color: #fff;
        font-size: 1.4rem;
        padding: 0; }
      .menu-footer ul li:first-child:before {
        display: none;
        padding: 0; }

@media (max-width: 640px) {
  .menu-footer ul {
    display: block; }
    .menu-footer ul li:before {
      display: none; } }

@media (max-width: 959px) {
  .menu-principal .uk-navbar-toggle-icon {
    color: #3CC0CC;
    position: relative;
    bottom: 2px;
    line-height: 30px; } }

.menu-principal .retour-accueil {
  color: #617993;
  font-weight: bold; }
  @media (min-width: 1920px) {
    .menu-principal .retour-accueil {
      width: 336px; } }
  @media (max-width: 1919.98px) {
    .menu-principal .retour-accueil {
      width: 223px; } }
  @media (min-width: 959px) {
    .menu-principal .retour-accueil .desktop {
      position: absolute;
      left: 0;
      bottom: -35px;
      display: block; }
    .menu-principal .retour-accueil .mobile {
      display: none; } }
  @media (max-width: 959px) {
    .menu-principal .retour-accueil .desktop {
      display: none; }
    .menu-principal .retour-accueil .mobile {
      display: block; } }

.menu-principal ul li a {
  color: #141D47;
  font-size: 1.4rem; }
  @media (min-width: 1920px) {
    .menu-principal ul li a {
      font-size: 1.6rem; } }
  .menu-principal ul li a span {
    top: 1px; }
  .menu-principal ul li a:hover {
    color: #3CC0CC; }
  .menu-principal ul li a.loupe {
    position: relative;
    top: 4px; }

.menu-principal ul li.active > a {
  color: #3CC0CC; }

.menu-responsive {
  background-color: #141D47;
  padding: 65px 25px;
  font-family: "Roboto", sans-serif;
  font-size: 1.6rem;
  overflow-x: hidden;
  z-index: 2; }
  .menu-responsive .uk-nav {
    margin: 0 !important; }
  .menu-responsive ul {
    padding: 0; }
  .menu-responsive li {
    padding-top: 10px; }
    .menu-responsive li.divider {
      border-bottom: 1px solid rgba(255, 255, 255, 0.15); }
      .menu-responsive li.divider span {
        display: none; }
    .menu-responsive li .nav-header {
      background-color: #192A3B;
      color: #758DA7;
      padding: 5px 25px;
      display: block; }
  .menu-responsive li.uk-parent a:after {
    display: none; }
  .menu-responsive li.uk-parent a.suivant span {
    float: right; }
  .menu-responsive li.uk-parent a.retour {
    color: #758DA7;
    padding: 10px 0px 14px; }
    .menu-responsive li.uk-parent a.retour span {
      float: left;
      color: #fff;
      margin-right: 5px;
      position: relative;
      top: 4px;
      right: 4px; }
  .menu-responsive h2, .menu-responsive .h2 {
    background-color: #192A3B;
    color: #758DA7;
    padding: 5px 25px;
    margin-left: -50vw !important;
    margin-right: -50vw !important; }
  .menu-responsive .support-pro {
    background-color: #5BD5AC;
    text-transform: uppercase;
    font-weight: bold;
    border-radius: 3px;
    font-size: 1.3rem;
    padding: 8px 32px;
    text-align: center;
    margin: 15px 0 25px; }

.menu-responsive2 {
  z-index: 3000; }

.menu-responsive-fermer {
  background-color: #2F3F8D;
  padding: 17px 25px;
  color: #fff; }
  .menu-responsive-fermer .uk-offcanvas-close {
    color: #3CC0CC;
    position: relative;
    left: 0;
    top: 0;
    float: left;
    padding: 0; }

.uk-offcanvas-bar .nav-child li {
  padding: 5px 0; }
  .uk-offcanvas-bar .nav-child li a {
    font-size: 16px !important; }

.moduletable_secondaire ul li.item-132 a {
  background-color: #5BD5AC;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 3px;
  font-size: 1.3rem;
  padding: 8px 32px; }
  .moduletable_secondaire ul li.item-132 a:hover {
    color: #2F3F8D; }

.moduletable_secondaire ul li.uk-open a:after {
  transform: initial; }

.moduletable_secondaire ul li.active > a {
  color: #807BCD;
  font-weight: bold; }
  .moduletable_secondaire ul li.active > a:hover {
    text-decoration: none; }

.moduletable_secondaire ul li.active .active a {
  color: #3CC0CC;
  font-weight: bold;
  text-decoration: none; }
  .moduletable_secondaire ul li.active .active a:hover {
    text-decoration: none; }

.moduletable_secondaire ul li a {
  color: #141D47;
  font-size: 1.4rem; }
  .moduletable_secondaire ul li a.uk-open {
    color: #3CC0CC; }
  .moduletable_secondaire ul li a:hover {
    text-decoration: underline; }
  .moduletable_secondaire ul li a:after {
    position: absolute;
    right: 10px;
    transform: rotate(180deg); }

/**
* @author : Gaël DIGARD
* @date   : 08/04/2019
* @desc   : Mise en page des quizz dans les modals
*
 */
.quizz-modal {
  width: 100vw;
  font-family: "Roboto", sans-serif;
  /**
  * Quizz progress bar
  */
  /**
  * Question numbers
  */
  /**
  * Title question
  */
  /**
  * Answers block
  *
  */
  /**
  * Response display
  *
  */
  /**
  * Resultats display
  *
  */
  /**
  * Buttons zone
  * Validate, next, show results
  *
  */ }
  .quizz-modal [class*="uk-modal-close-"] {
    left: 0;
    width: 200px;
    opacity: 0.3; }
    .quizz-modal [class*="uk-modal-close-"] span {
      display: inline-block;
      margin-top: 20px;
      text-transform: uppercase; }
    .quizz-modal [class*="uk-modal-close-"] svg {
      border: 4px solid #F9F8F8;
      float: left;
      border-radius: 100%;
      padding: 7px; }
      .quizz-modal [class*="uk-modal-close-"] svg line {
        stroke-width: 4px; }
    .quizz-modal [class*="uk-modal-close-"]:hover {
      color: white; }
  .quizz-modal .quizz-header {
    padding: 25px 0;
    background-color: #2F3F8D;
    color: white;
    text-align: center; }
    .quizz-modal .quizz-header img {
      margin-bottom: 15px; }
    .quizz-modal .quizz-header h1, .quizz-modal .quizz-header .h1, .quizz-modal .quizz-header h2, .quizz-modal .quizz-header .h2 {
      color: white;
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      margin: 0;
      padding: 0; }
    .quizz-modal .quizz-header h1, .quizz-modal .quizz-header .h1 {
      font-family: "Roboto", sans-serif;
      font-weight: 700;
      padding-bottom: 15px; }
    .quizz-modal .quizz-header h2, .quizz-modal .quizz-header .h2 {
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      line-height: 19px;
      margin: auto;
      margin-bottom: 0;
      width: 50%;
      opacity: 0.6; }
    @media (min-width: 960px) {
      .quizz-modal .quizz-header .uk-modal-close-full {
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%); } }
    @media (max-width: 959px) {
      .quizz-modal .quizz-header .uk-modal-close-full {
        text-indent: -9999px; } }
  .quizz-modal #progress-bar-container {
    background: #E3E8ED;
    height: 10px;
    width: 100%; }
    .quizz-modal #progress-bar-container #progress-bar {
      height: 100%;
      width: 0;
      background: #3CC0CC;
      border-radius: 0 4px 4px 0;
      transition-duration: 0.2s;
      transition-timing-function: ease-out; }
  .quizz-modal h3, .quizz-modal .h3 {
    height: 24px;
    color: #758DA7;
    font-size: 20px;
    font-weight: 300;
    line-height: 24px;
    text-align: center; }
  .quizz-modal hr {
    height: 6px;
    width: 48px;
    border-radius: 3px;
    background-color: #3CC0CC;
    margin: auto; }
  .quizz-modal section:nth-of-type(1) {
    min-height: 28px;
    max-width: 675px;
    color: #304D6D;
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    text-align: center;
    margin: 50px auto; }
  .quizz-modal section:nth-of-type(2) {
    max-width: 675px;
    color: #304D6D;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    margin: 50px auto; }
  .quizz-modal section:nth-of-type(3) {
    max-width: 400px;
    margin: auto; }
    .quizz-modal section:nth-of-type(3) div {
      min-height: 60px;
      box-sizing: border-box;
      margin-bottom: 25px; }
      .quizz-modal section:nth-of-type(3) div:last-of-type {
        margin: 0; }
      .quizz-modal section:nth-of-type(3) div input {
        display: none; }
        .quizz-modal section:nth-of-type(3) div input + label:after {
          content: '';
          display: block;
          width: 18px;
          height: 18px;
          border-radius: 15px;
          border: 2px solid #92A1B2;
          position: absolute;
          left: 19px;
          top: 17px; }
        .quizz-modal section:nth-of-type(3) div input:checked + label:after {
          content: '';
          display: block;
          width: 18px;
          height: 18px;
          border-radius: 15px;
          border: 2px solid #3CC0CC;
          position: absolute;
          left: 19px;
          top: 17px;
          background: url("/templates/skazy/dist/img/answer-cocher-quizz.png") center center no-repeat transparent; }
        .quizz-modal section:nth-of-type(3) div input.good-answer + label:after {
          border-color: #fff;
          background: #50E3C2 url("/templates/skazy/dist/img/answer-correct-quizz.png") center center no-repeat; }
        .quizz-modal section:nth-of-type(3) div input.wrong-answer + label:after {
          border-color: #fff;
          background: transparent url("/templates/skazy/dist/img/answer-wrong-quizz.png") center center no-repeat;
          opacity: 1; }
      .quizz-modal section:nth-of-type(3) div label {
        position: relative;
        border: 1px solid #E3E8ED;
        border-radius: 4px;
        width: 100%;
        box-sizing: border-box;
        min-height: 60px;
        color: #758DA7;
        font-size: 16px;
        font-weight: 300;
        display: inline-block;
        cursor: pointer;
        padding: 16px 10px 16px 70px; }
        .quizz-modal section:nth-of-type(3) div label:before {
          content: '';
          display: block;
          background: #2F3F8D;
          height: 100%;
          width: 60px;
          position: absolute;
          z-index: -1;
          border-radius: 4px 0 0 4px;
          left: 0;
          top: 0; }
        .quizz-modal section:nth-of-type(3) div label.good-answer {
          background: #50E3C2;
          color: white;
          border: 0;
          border-left: 1px solid #E3E8ED;
          font-weight: bold; }
        .quizz-modal section:nth-of-type(3) div label.wrong-answer {
          background-color: #E55A35;
          color: #fff;
          border: 0;
          border-left: 1px solid #E3E8ED; }
          .quizz-modal section:nth-of-type(3) div label.wrong-answer:after {
            opacity: 0.3; }
  .quizz-modal section:nth-of-type(4) {
    color: #2F3F8D;
    max-width: 600px;
    min-width: 300px;
    padding: 15px 0 15px 100px;
    position: relative;
    border-radius: 4px 0 0 4px;
    display: none;
    margin: 25px auto 0; }
    .quizz-modal section:nth-of-type(4) .explication {
      font-size: 16px;
      font-weight: 600;
      display: inline-block;
      margin-bottom: 2%; }
    .quizz-modal section:nth-of-type(4).active {
      border: 1px solid #E3E8ED;
      display: block; }
    .quizz-modal section:nth-of-type(4) .bg-answer {
      left: 0;
      top: 0;
      height: 100%;
      width: 90px;
      background: #50E3C2 url("/templates/skazy/dist/img/answer-big-correct-quizz.png") center center no-repeat;
      position: absolute;
      z-index: -1; }
  .quizz-modal section:nth-of-type(5) {
    color: #2F3F8D;
    max-width: 600px;
    min-width: 300px;
    text-align: center;
    padding: 15px 0;
    position: relative;
    border-radius: 4px 0 0 4px;
    display: none;
    margin: 25px auto 0;
    /**
    * Images who change according
    * to the results %
    *
    */ }
    .quizz-modal section:nth-of-type(5).active {
      border: 1px solid #E3E8ED;
      display: block; }
    .quizz-modal section:nth-of-type(5) h2, .quizz-modal section:nth-of-type(5) .h2 {
      font-weight: 600;
      font-size: 18px;
      color: #2F3F8D; }
      .quizz-modal section:nth-of-type(5) h2 .vert, .quizz-modal section:nth-of-type(5) .h2 .vert {
        color: #3CC0CC; }
    .quizz-modal section:nth-of-type(5) h3, .quizz-modal section:nth-of-type(5) .h3 {
      font-size: 14px;
      font-weight: 300;
      line-height: 19px;
      text-align: left;
      margin: auto;
      max-width: 370px;
      height: auto;
      margin-bottom: 15px; }
    .quizz-modal section:nth-of-type(5) hr.hr-results {
      width: 100%;
      height: 1px;
      border: 0;
      margin-top: 24px;
      margin-bottom: 24px;
      background: #E3E8ED; }
    .quizz-modal section:nth-of-type(5) button.plus-infos {
      height: 40px;
      width: 263px;
      border: 1px solid #758DA7;
      border-radius: 4px;
      font-size: 13px;
      font-weight: bold;
      letter-spacing: 0.46px;
      line-height: 15px;
      text-align: center;
      text-transform: uppercase;
      background: transparent;
      color: #758DA7; }
    .quizz-modal section:nth-of-type(5) #image-all-good,
    .quizz-modal section:nth-of-type(5) #image-not-good {
      display: block;
      margin: auto;
      margin-top: -110px; }
    .quizz-modal section:nth-of-type(5) button {
      cursor: pointer;
      height: 40px;
      width: 220px;
      border-radius: 4px;
      background-color: #3CC0CC;
      border: 0;
      color: #FFFFFF;
      text-transform: uppercase;
      font-size: 13px;
      font-weight: bold;
      letter-spacing: 0.37px;
      line-height: 15px;
      text-align: center; }
  .quizz-modal #button-display {
    width: 400px;
    margin: auto;
    text-align: right; }
    .quizz-modal #button-display button {
      border: 0;
      background: transparent;
      color: #354A76;
      font-size: 13px;
      font-weight: bold;
      letter-spacing: 0.46px;
      line-height: 15px;
      text-align: right;
      text-transform: uppercase;
      cursor: pointer;
      background: transparent url("/templates/skazy/dist/img/arrow-quizz.png") center right no-repeat;
      min-width: 150px;
      height: 23px;
      padding-right: 31px;
      margin: 25px 0; }
    .quizz-modal #button-display button#previous-question {
      background: transparent url("/templates/skazy/dist/img/left-arrow-quizz.png") center left no-repeat;
      padding-left: 31px;
      padding-right: 0px;
      float: left; }

/**
* Launcher
* Bloc qui dit "Cliquez ici pour faire le quizz"
 */
.custom-quizz-launcher {
  background-color: #2F3F8D;
  padding: 25px;
  color: #fff;
  border-radius: 4px;
  margin-top: 45px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between; }
  .custom-quizz-launcher .intro-text {
    width: 356px;
    margin-right: 10px; }
    @media (max-width: 1024px) {
      .custom-quizz-launcher .intro-text {
        width: 100%; } }
  .custom-quizz-launcher .button-modal {
    background-color: #141D47;
    flex: 1;
    color: #3CC0CC;
    font-size: 18px;
    text-align: center;
    padding-top: 27px;
    border-radius: 4px; }
    .custom-quizz-launcher .button-modal a {
      margin-top: 8px;
      margin-bottom: 28px;
      background: #3CC0CC;
      color: #fff !important;
      width: 259px; }
  .custom-quizz-launcher h3, .custom-quizz-launcher .h3 {
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    color: #fff !important;
    margin-top: 8px;
    margin-bottom: 8px; }
  .custom-quizz-launcher h4, .custom-quizz-launcher .h4 {
    font-size: 13px;
    font-weight: normal;
    color: white;
    margin-top: 0px; }

.partage-quizz {
  max-width: 600px;
  margin: 20px auto 0 auto;
  display: flex;
  justify-content: flex-start;
  flex-flow: wrap; }

.text-partage-quiz {
  color: #8095AE;
  font-size: 1.5rem;
  font-weight: 500; }

.share {
  padding-left: 10px; }

.partage-mail {
  padding-top: 2px; }

th {
  border: 1px solid #304D6D;
  box-sizing: border-box; }

.menu-pre-footer .bannergroup_reseaux_sociaux {
  border-top: 1px solid #fff;
  padding-top: 18px;
  width: 96px;
  margin-top: 12px; }

.moduletable_menu_detail ul li {
  margin-top: 8px !important; }
  .moduletable_menu_detail ul li a {
    padding-right: 20px; }

.moduletable_menu_detail .uk-list ul {
  padding-left: 30px; }

.moduletable_menu_detail, .menu-detail-responsive {
  border: 1px solid rgba(117, 141, 167, 0.2);
  bottom: 60px;
  padding: 33px 32px 50px 32px;
  overflow: hidden; }
  .moduletable_menu_detail h2, .moduletable_menu_detail .h2, .menu-detail-responsive h2, .menu-detail-responsive .h2 {
    color: #141D47;
    font-size: 2.4rem;
    font-weight: bold;
    margin: 5px 0 10px; }
  .moduletable_menu_detail ul li a, .menu-detail-responsive ul li a {
    color: #758DA7;
    font-size: 1.6rem;
    display: block;
    position: relative;
    line-height: 16px;
    margin: 5px 0; }
    .moduletable_menu_detail ul li a svg, .menu-detail-responsive ul li a svg {
      position: absolute;
      right: 0;
      top: 0; }
    .moduletable_menu_detail ul li a.active, .menu-detail-responsive ul li a.active {
      font-weight: bold; }
      .moduletable_menu_detail ul li a.active.level-3, .menu-detail-responsive ul li a.active.level-3 {
        color: #3CC0CC; }
    .moduletable_menu_detail ul li a.deeper, .menu-detail-responsive ul li a.deeper {
      color: #3CC0CC;
      font-weight: bold; }
    .moduletable_menu_detail ul li a:hover, .menu-detail-responsive ul li a:hover {
      color: #3CC0CC; }
    .moduletable_menu_detail ul li a.btn-pro, .menu-detail-responsive ul li a.btn-pro {
      background-color: #5BD5AC;
      color: #fff;
      text-transform: uppercase;
      font-size: 1.3rem;
      text-align: center;
      padding: 15px;
      border-radius: 3px;
      margin-top: 25px; }
      .moduletable_menu_detail ul li a.btn-pro:hover, .menu-detail-responsive ul li a.btn-pro:hover {
        background-color: #3CC0CC; }
  .moduletable_menu_detail ul li ul li a, .menu-detail-responsive ul li ul li a {
    margin: 15px 0; }
  .moduletable_menu_detail .button-thematique, .menu-detail-responsive .button-thematique {
    margin: 0;
    border: 0;
    cursor: pointer;
    background-color: #141D47;
    color: #fff;
    border-radius: 3px;
    padding: 10px 0; }
    .moduletable_menu_detail .button-thematique span, .menu-detail-responsive .button-thematique span {
      padding-left: 5px; }
    .moduletable_menu_detail .button-thematique:hover, .menu-detail-responsive .button-thematique:hover {
      background-color: #2F3F8D; }
  .moduletable_menu_detail #thematique-detail, .menu-detail-responsive #thematique-detail {
    padding: 20px; }
    .moduletable_menu_detail #thematique-detail h3, .moduletable_menu_detail #thematique-detail .h3, .menu-detail-responsive #thematique-detail h3, .menu-detail-responsive #thematique-detail .h3 {
      color: #3CC0CC;
      font-size: 2rem;
      font-weight: bold;
      display: block;
      padding-bottom: 8px; }
    .moduletable_menu_detail #thematique-detail ul li, .menu-detail-responsive #thematique-detail ul li {
      margin: 0 0 5px !important; }
      .moduletable_menu_detail #thematique-detail ul li img, .menu-detail-responsive #thematique-detail ul li img {
        width: 21px;
        margin-right: 15px; }
      .moduletable_menu_detail #thematique-detail ul li a, .menu-detail-responsive #thematique-detail ul li a {
        margin: 0;
        font-size: 1.8rem; }
      .moduletable_menu_detail #thematique-detail ul li.active a, .menu-detail-responsive #thematique-detail ul li.active a {
        color: #3CC0CC; }

.supports-pro .moduletable_menu_detail ul li a.active.level-3 {
  color: #5BD5AC; }

.supports-pro .moduletable_menu_detail ul li a.deeper {
  color: #5BD5AC; }

.supports-pro .moduletable_menu_detail ul li a:hover {
  color: #5BD5AC; }

.l-agence .moduletable_menu_detail ul li a.active.level-2 {
  color: #807BCD; }

.l-agence .moduletable_menu_detail ul li a.deeper {
  color: #807BCD; }

.l-agence .moduletable_menu_detail ul li a:hover {
  color: #807BCD; }

.bannergroup_bandeau_detail .bandeau-nav {
  z-index: 3; }
  .bannergroup_bandeau_detail .bandeau-nav a {
    background-color: #6A65A7;
    color: #fff;
    display: inline-block;
    margin-left: 1px; }
    .bannergroup_bandeau_detail .bandeau-nav a:hover {
      background-color: #807BCD; }
    .bannergroup_bandeau_detail .bandeau-nav a:first-child {
      border-radius: 3px 0 0 3px; }
    .bannergroup_bandeau_detail .bandeau-nav a:last-child {
      border-radius: 0 3px 3px 0; }

.introduction-categorie h2, .introduction-categorie .h2 {
  font-size: 2.4rem;
  color: #141D47;
  font-weight: bold; }

.introduction-categorie .category-desc {
  color: #758DA7;
  font-size: 1.6rem; }
  .introduction-categorie .category-desc a {
    color: #3CC0CC; }

.blog {
  padding-bottom: 30px;
  padding-top: 15px; }
  .blog .page-header {
    padding-bottom: 15px; }
    .blog .page-header h1, .blog .page-header .h1 {
      color: #141D47;
      font-size: 2.4rem;
      font-weight: bold; }

@media (min-width: 1200px) {
  .page-evenement .zone-liens {
    padding-left: 0; } }

.page-actu .zone-liens {
  position: relative; }
  @media (min-width: 1200px) {
    .page-actu .zone-liens {
      right: 100px; } }

.trie-date {
  color: #758DA7; }
  .trie-date button {
    border: 1px solid #E3E8ED;
    border-radius: 4px;
    color: #2F3F8D;
    padding: 5px 8px;
    background-color: #fff;
    line-height: normal;
    margin-left: 12px; }
    .trie-date button .uk-icon {
      margin-left: 8px; }
  .trie-date .uk-dropdown.uk-open {
    border: 1px solid #E3E8ED;
    border-radius: 4px; }
    .trie-date .uk-dropdown.uk-open a {
      color: #2F3F8D; }

.menu-filtre {
  border: 1px solid #E3E8ED;
  border-radius: 4px;
  width: fit-content; }
  .menu-filtre h2, .menu-filtre .h2 {
    color: #2F3F8D;
    font-size: 2.2rem;
    padding-right: 45px; }
    .menu-filtre h2 span, .menu-filtre .h2 span {
      color: #3CC0CC;
      padding-right: 15px; }
  .menu-filtre a {
    color: #2F3F8D;
    display: block;
    padding-left: 35px;
    position: relative; }
    .menu-filtre a:before {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 20px;
      border: 1px solid #E3E8ED;
      background-color: #fff;
      position: absolute;
      left: 0;
      top: 0;
      transition: all ease-in-out 0.2s; }
    .menu-filtre a:hover:before {
      border: 8px solid #2F3F8D;
      height: 6px;
      width: 6px;
      transition: all ease-in-out 0.2s; }
  .menu-filtre .uk-active a:before {
    border: 8px solid #2F3F8D;
    height: 6px;
    width: 6px;
    transition: all ease-in-out 0.2s; }

.category-desc {
  color: #758DA7; }
  .category-desc h2, .category-desc .h2 {
    color: #2F3F8D;
    font-size: 2rem;
    font-weight: bold; }
  .category-desc h3, .category-desc .h3 {
    color: #2F3F8D;
    font-size: 1.6rem;
    font-weight: bold; }
  .category-desc blockquote {
    padding: 15px;
    border-radius: 4px;
    border: 1px solid #758DA7;
    font-size: 1.6rem; }
  .category-desc ul {
    list-style-type: none;
    padding: 0; }
    .category-desc ul li {
      position: relative;
      padding-left: 15px; }
      .category-desc ul li:before {
        content: '';
        display: block;
        width: 8px;
        height: 8px;
        background-color: #3CC0CC;
        border-radius: 10px;
        position: absolute;
        left: 0;
        top: 7px; }
  .category-desc a span {
    color: #141D47;
    font-size: 2rem;
    font-weight: bold; }
  .category-desc .rl_sliders.accordion > .accordion-group {
    border: none;
    border-radius: inherit;
    border-bottom: 1px solid #141D47; }
  .category-desc .rl_sliders.accordion > .accordion-group > .accordion-body > .accordion-inner {
    border-top: 1px solid #141D47; }
  .category-desc a.bouton {
    background: url(../img/bouton.png) no-repeat 96% 50% #2F3F8D;
    color: #fff;
    border-radius: 4px;
    padding: 10px 50px 10px 20px;
    font-size: 1.3rem;
    transition: all ease-in-out 0.2s; }
    .category-desc a.bouton:hover {
      background-position: 93% 50%;
      transition: all ease-in-out 0.2s; }
  .category-desc a.bouton-externe {
    background: url(../img/lien-externe.png) no-repeat 100% 50% #fff;
    color: #3CC0CC;
    padding: 0px 28px 0px 0px;
    font-size: 1.6rem; }
    .category-desc a.bouton-externe:hover {
      text-decoration: underline; }

.filtre_evenement li {
  margin-bottom: 20px; }

.filtre_evenement a {
  padding: 15px;
  border: 1px solid #E3E8ED;
  border-radius: 4px; }
  .filtre_evenement a .uk-icon {
    color: #2E2E2E;
    transition: 0.4s ease-in-out; }
  .filtre_evenement a .date {
    color: #E55A35;
    line-height: 20px;
    font-size: 1.4rem; }
    .filtre_evenement a .date strong {
      font-size: 2.4rem; }
  .filtre_evenement a .bloc-texte .page-header {
    padding: 0; }
  .filtre_evenement a .bloc-texte h2, .filtre_evenement a .bloc-texte .h2 {
    color: #E55A35;
    margin: 0;
    padding: 0 15px 0 0;
    font-size: 1.4rem;
    font-weight: bold;
    height: 33px; }
  .filtre_evenement a .bloc-texte p {
    padding-top: 8px;
    margin: 0;
    height: 44px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 230px;
    color: #758DA7;
    font-size: 1.4rem; }
  .filtre_evenement a .bloc-texte .thematique {
    font-size: 1.4rem;
    color: #758DA7; }

.questions-posees {
  padding-bottom: 15px; }
  .questions-posees .question {
    cursor: pointer;
    color: #2F3F8D;
    position: relative;
    padding-right: 25px;
    font-size: 1.6rem; }
    .questions-posees .question span {
      position: absolute;
      right: 0;
      top: 4px; }
      .questions-posees .question span svg {
        transition: all 0.2s ease-in-out; }
  .questions-posees .accordion {
    margin-top: 0;
    margin-bottom: 15px; }
    .questions-posees .accordion.uk-open .question svg, .questions-posees .accordion:hover .question svg {
      transform: rotate(45deg);
      transition: all 0.2s ease-in-out; }

.bloc-poser-une-question {
  padding-bottom: 25px; }
  .bloc-poser-une-question h1, .bloc-poser-une-question .h1 {
    color: #141D47;
    font-size: 2.4rem;
    font-weight: bold; }
  .bloc-poser-une-question .texte-intro {
    color: #758DA7;
    font-size: 1.6rem; }
  .bloc-poser-une-question .menu-filtre li input {
    display: none; }
    .bloc-poser-une-question .menu-filtre li input:checked + label:before {
      border: 8px solid #2F3F8D;
      height: 6px;
      width: 6px;
      transition: all ease-in-out 0.2s; }
  .bloc-poser-une-question .menu-filtre li label {
    padding-left: 35px;
    padding-top: 2px;
    position: relative;
    cursor: pointer;
    color: #304D6D; }
    .bloc-poser-une-question .menu-filtre li label:hover:before {
      border: 8px solid #2F3F8D;
      height: 6px;
      width: 6px;
      transition: all ease-in-out 0.2s; }
    .bloc-poser-une-question .menu-filtre li label:before {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 20px;
      border: 1px solid #E3E8ED;
      background-color: #fff;
      position: absolute;
      left: 0;
      top: 0;
      transition: all ease-in-out 0.2s; }
  .bloc-poser-une-question .menu-filtre .btn-question {
    margin-top: 25px;
    display: block;
    border: 0;
    width: 100%;
    background-color: #141D47 !important;
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    appearance: none; }
    .bloc-poser-une-question .menu-filtre .btn-question:hover {
      background-color: #2F3F8D; }
  @media all and (max-width: 959px) {
    .bloc-poser-une-question .menu-filtre {
      width: 95%; }
      .bloc-poser-une-question .menu-filtre li {
        width: 49%;
        display: inline-block;
        font-size: 1.2rem; } }
  .bloc-poser-une-question .accordeon .menu-accordeon .titre-question {
    color: #2F3F8D;
    font-size: 1.8rem;
    padding-bottom: 10px;
    padding-right: 25px; }
  .bloc-poser-une-question .accordeon .menu-accordeon .label-thematique {
    font-size: 1.4rem;
    color: #758DA7; }
  .bloc-poser-une-question .accordeon .accordeon-contenu a {
    color: #3CC0CC; }
  .bloc-poser-une-question #pagination {
    padding-top: 35px;
    color: #2F3F8D;
    font-size: 1.4rem; }
    .bloc-poser-une-question #pagination #prev-page {
      padding-left: 15px;
      padding-right: 8px;
      color: #E3E8ED; }
    .bloc-poser-une-question #pagination #next-page {
      padding-right: 15px;
      padding-left: 8px;
      color: #2F3F8D; }
    .bloc-poser-une-question #pagination .en-cours {
      border: 1px solid #E3E8ED;
      border-radius: 4px;
      padding: 2px 6px 0;
      font-size: 1.4rem;
      color: #5BD5AC;
      position: relative;
      bottom: 3px; }

.la-sante-en-chiffre .currentTitle img {
  height: 30px; }

.currentTitle {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 20px;
  transition: all 0.3s ease;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #2F3F8D;
  cursor: pointer; }
  .currentTitle.actif {
    background-color: #141D47; }
    .currentTitle.actif span {
      transform: rotate(180deg); }
  .currentTitle h2, .currentTitle .h2 {
    color: #fff;
    margin: 0;
    margin-left: 1.5rem; }
  .currentTitle span {
    position: absolute;
    right: 20px;
    color: #fff; }

.menu-detail-responsive {
  bottom: 0;
  padding: 15px;
  overflow: hidden;
  height: 0;
  opacity: 0;
  transition: all 0.3s ease; }
  .menu-detail-responsive.toggle {
    height: auto;
    opacity: 1; }
  .menu-detail-responsive ul.menu li {
    margin: 27px 0; }
    .menu-detail-responsive ul.menu li.actif .deep svg {
      transform: rotate(0deg); }
    .menu-detail-responsive ul.menu li.active a.active {
      color: #3CC0CC; }
    .menu-detail-responsive ul.menu li:first-child {
      margin-top: 10px; }
    .menu-detail-responsive ul.menu li:last-child {
      margin-bottom: 0; }
    .menu-detail-responsive ul.menu li a {
      margin: 0; }
  .menu-detail-responsive .deeper.parent > a {
    pointer-events: none; }
  .menu-detail-responsive .deeper a.deep {
    margin-bottom: 0; }
    .menu-detail-responsive .deeper a.deep.current {
      color: #3CC0CC;
      font-weight: bold;
      background-color: transparent; }
    .menu-detail-responsive .deeper a.deep svg {
      transform: rotate(90deg); }
  .menu-detail-responsive .deeper ul {
    overflow: hidden;
    height: 0;
    opacity: 0;
    transition: all 0.3s ease;
    margin-top: 0;
    padding-left: 10px; }
    .menu-detail-responsive .deeper ul li:first-child {
      margin-top: 27px; }
    .menu-detail-responsive .deeper ul li.active a {
      font-weight: initial;
      text-decoration: underline; }
    .menu-detail-responsive .deeper ul.toggle {
      height: auto;
      opacity: 1; }

.formulaire-accompagnement .rsform h3, .formulaire-accompagnement .rsform .h3 {
  font-size: 2.4rem;
  color: #141D47;
  font-weight: 500;
  margin-bottom: 12px; }

.formulaire-accompagnement .rsform h4, .formulaire-accompagnement .rsform .h4 {
  font-size: 18px;
  line-height: 28px;
  margin-top: 12px;
  font-weight: 500; }

.formulaire-accompagnement .rsform p {
  color: #252525;
  margin-bottom: 12px; }

.formulaire-accompagnement .rsform ol {
  font-size: 16px;
  color: #6F7E94;
  margin-bottom: 44px; }
  .formulaire-accompagnement .rsform ol li {
    margin-bottom: 6px; }
    .formulaire-accompagnement .rsform ol li::marker {
      color: #6F7E94; }

.formulaire-accompagnement .rsform input::placeholder {
  color: #B9B9CA; }

.formulaire-accompagnement .rsform .rsform-type-pagebreak .formControlLabel {
  display: none; }

.formulaire-accompagnement .rsform .rsform-type-pagebreak .formControls {
  margin-left: 0; }
  .formulaire-accompagnement .rsform .rsform-type-pagebreak .formControls .formBody button[type="button"].rsform-button-next, .formulaire-accompagnement .rsform .rsform-type-pagebreak .formControls .formBody button[type="button"].rsform-button-prev {
    background-color: #95C8D5;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    border-radius: 8px;
    border: none;
    padding: 0px 24px;
    line-height: 38px;
    font-size: 16px;
    width: 49%; }
    @media (max-width: 767.98px) {
      .formulaire-accompagnement .rsform .rsform-type-pagebreak .formControls .formBody button[type="button"].rsform-button-next, .formulaire-accompagnement .rsform .rsform-type-pagebreak .formControls .formBody button[type="button"].rsform-button-prev {
        width: 46% !important;
        padding: 0px 15px; } }
  .formulaire-accompagnement .rsform .rsform-type-pagebreak .formControls .formBody button[type="button"].rsform-button-prev {
    background-color: transparent;
    border: 1px solid #2F3F8D;
    margin-right: 16px;
    color: #2F3F8D; }
  .formulaire-accompagnement .rsform .rsform-type-pagebreak .formControls .formBody #commencerNext {
    width: 95%; }

.formulaire-accompagnement .rsform #rsform_3_page_0 .formRow .formSpan12 h3, .formulaire-accompagnement .rsform #rsform_3_page_0 .formRow .formSpan12 .h3 {
  margin-top: 10px; }
  @media (min-width: 768px) {
    .formulaire-accompagnement .rsform #rsform_3_page_0 .formRow .formSpan12 h3, .formulaire-accompagnement .rsform #rsform_3_page_0 .formRow .formSpan12 .h3 {
      margin-top: 76px; } }

.formulaire-accompagnement .rsform #rsform_3_page_0 .formRow .formSpan12 .rsform-block-commencer button {
  width: 100%;
  margin-bottom: 50px; }
  @media (min-width: 768px) {
    .formulaire-accompagnement .rsform #rsform_3_page_0 .formRow .formSpan12 .rsform-block-commencer button {
      margin-bottom: 233px; } }

.formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-block-fonction.rsform-type-textbox, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-block-fonction.rsform-type-textbox, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-block-fonction.rsform-type-textbox {
  margin-bottom: 32px; }

.formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-freetext, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-freetext, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-type-freetext {
  margin-bottom: 0; }
  .formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-freetext p, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-freetext p, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-type-freetext p {
    margin: 0; }
  .formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-freetext p > img, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-freetext p > img, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-type-freetext p > img {
    margin-bottom: 40px;
    width: 100%; }
  .formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-freetext p > span, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-freetext p > span, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-type-freetext p > span {
    text-transform: uppercase;
    letter-spacing: 2.8px;
    line-height: 27px;
    font-size: 14px;
    color: #64748B; }
  .formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-freetext h3, .formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-freetext .h3, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-freetext h3, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-freetext .h3, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-type-freetext h3, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-type-freetext .h3 {
    margin-top: 0;
    margin-bottom: 24px; }

.formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-textbox, .formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-checkboxgroup, .formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-textarea, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-textbox, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-checkboxgroup, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-textarea, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-type-textbox, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-type-checkboxgroup, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-type-textarea {
  margin-bottom: 12px; }
  .formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-textbox .formControlLabel, .formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-checkboxgroup .formControlLabel, .formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-textarea .formControlLabel, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-textbox .formControlLabel, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-checkboxgroup .formControlLabel, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-textarea .formControlLabel, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-type-textbox .formControlLabel, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-type-checkboxgroup .formControlLabel, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-type-textarea .formControlLabel {
    width: 95%;
    color: #6F7E94;
    font-weight: 500;
    text-align: left;
    line-height: 28px;
    margin-bottom: 4px; }
  .formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-textbox .formControls, .formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-checkboxgroup .formControls, .formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-textarea .formControls, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-textbox .formControls, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-checkboxgroup .formControls, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-textarea .formControls, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-type-textbox .formControls, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-type-checkboxgroup .formControls, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-type-textarea .formControls {
    width: 100%;
    margin-left: 0; }
    .formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-textbox .formControls input, .formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-textbox .formControls textarea, .formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-checkboxgroup .formControls input, .formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-checkboxgroup .formControls textarea, .formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-textarea .formControls input, .formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-textarea .formControls textarea, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-textbox .formControls input, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-textbox .formControls textarea, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-checkboxgroup .formControls input, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-checkboxgroup .formControls textarea, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-textarea .formControls input, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-textarea .formControls textarea, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-type-textbox .formControls input, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-type-textbox .formControls textarea, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-type-checkboxgroup .formControls input, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-type-checkboxgroup .formControls textarea, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-type-textarea .formControls input, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-type-textarea .formControls textarea {
      border: 1px solid #B9B9CA;
      border-radius: 4px;
      margin-bottom: 0;
      padding: 8px 12px;
      font-size: 16px;
      width: 100%; }
    .formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-textbox .formControls .formValidation, .formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-textbox .formControls .formDescription, .formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-checkboxgroup .formControls .formValidation, .formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-checkboxgroup .formControls .formDescription, .formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-textarea .formControls .formValidation, .formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-textarea .formControls .formDescription, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-textbox .formControls .formValidation, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-textbox .formControls .formDescription, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-checkboxgroup .formControls .formValidation, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-checkboxgroup .formControls .formDescription, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-textarea .formControls .formValidation, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-textarea .formControls .formDescription, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-type-textbox .formControls .formValidation, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-type-textbox .formControls .formDescription, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-type-checkboxgroup .formControls .formValidation, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-type-checkboxgroup .formControls .formDescription, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-type-textarea .formControls .formValidation, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-type-textarea .formControls .formDescription {
      display: none; }

.formulaire-accompagnement .rsform #rsform_3_page_1 .formSpan12 .rsform-type-textbox .formControlLabel, .formulaire-accompagnement .rsform #rsform_3_page_1 .formSpan12 .rsform-type-textarea .formControlLabel, .formulaire-accompagnement .rsform #rsform_3_page_2 .formSpan12 .rsform-type-textbox .formControlLabel, .formulaire-accompagnement .rsform #rsform_3_page_2 .formSpan12 .rsform-type-textarea .formControlLabel, .formulaire-accompagnement .rsform #rsform_3_page_3 .formSpan12 .rsform-type-textbox .formControlLabel, .formulaire-accompagnement .rsform #rsform_3_page_3 .formSpan12 .rsform-type-textarea .formControlLabel {
  width: 95%; }

.formulaire-accompagnement .rsform #rsform_3_page_1 .formSpan12 .rsform-type-textbox .formControls, .formulaire-accompagnement .rsform #rsform_3_page_1 .formSpan12 .rsform-type-textarea .formControls, .formulaire-accompagnement .rsform #rsform_3_page_2 .formSpan12 .rsform-type-textbox .formControls, .formulaire-accompagnement .rsform #rsform_3_page_2 .formSpan12 .rsform-type-textarea .formControls, .formulaire-accompagnement .rsform #rsform_3_page_3 .formSpan12 .rsform-type-textbox .formControls, .formulaire-accompagnement .rsform #rsform_3_page_3 .formSpan12 .rsform-type-textarea .formControls {
  width: 100%; }
  .formulaire-accompagnement .rsform #rsform_3_page_1 .formSpan12 .rsform-type-textbox .formControls input, .formulaire-accompagnement .rsform #rsform_3_page_1 .formSpan12 .rsform-type-textarea .formControls input, .formulaire-accompagnement .rsform #rsform_3_page_2 .formSpan12 .rsform-type-textbox .formControls input, .formulaire-accompagnement .rsform #rsform_3_page_2 .formSpan12 .rsform-type-textarea .formControls input, .formulaire-accompagnement .rsform #rsform_3_page_3 .formSpan12 .rsform-type-textbox .formControls input, .formulaire-accompagnement .rsform #rsform_3_page_3 .formSpan12 .rsform-type-textarea .formControls input {
    width: 90% !important; }
    @media (min-width: 768px) {
      .formulaire-accompagnement .rsform #rsform_3_page_1 .formSpan12 .rsform-type-textbox .formControls input, .formulaire-accompagnement .rsform #rsform_3_page_1 .formSpan12 .rsform-type-textarea .formControls input, .formulaire-accompagnement .rsform #rsform_3_page_2 .formSpan12 .rsform-type-textbox .formControls input, .formulaire-accompagnement .rsform #rsform_3_page_2 .formSpan12 .rsform-type-textarea .formControls input, .formulaire-accompagnement .rsform #rsform_3_page_3 .formSpan12 .rsform-type-textbox .formControls input, .formulaire-accompagnement .rsform #rsform_3_page_3 .formSpan12 .rsform-type-textarea .formControls input {
        width: 97% !important; } }

.formulaire-accompagnement .rsform #rsform_3_page_1 .formSpan6 .rsform-type-textbox .formControls .formBody input, .formulaire-accompagnement .rsform #rsform_3_page_2 .formSpan6 .rsform-type-textbox .formControls .formBody input, .formulaire-accompagnement .rsform #rsform_3_page_3 .formSpan6 .rsform-type-textbox .formControls .formBody input {
  width: 95%;
  border-radius: 4px; }
  @media (max-width: 767.98px) {
    .formulaire-accompagnement .rsform #rsform_3_page_1 .formSpan6 .rsform-type-textbox .formControls .formBody input, .formulaire-accompagnement .rsform #rsform_3_page_2 .formSpan6 .rsform-type-textbox .formControls .formBody input, .formulaire-accompagnement .rsform #rsform_3_page_3 .formSpan6 .rsform-type-textbox .formControls .formBody input {
      width: 90% !important; } }

.formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-textbox, .formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-checkboxgroup, .formulaire-accompagnement .rsform #rsform_3_page_1 .rsform-type-textarea, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-textbox, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-checkboxgroup, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-textarea {
  display: flex;
  flex-direction: column; }

.formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-block-thematique > label {
  margin-bottom: 8px; }

.formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-textarea .formControls textarea {
  width: 97%; }

.formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-checkboxgroup .formBody .rsformgrid6 .rsformVerticalClear, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-checkboxgroup .formBody .rsformVerticalClear {
  margin-bottom: 0; }
  .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-checkboxgroup .formBody .rsformgrid6 .rsformVerticalClear label, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-checkboxgroup .formBody .rsformVerticalClear label {
    font-weight: 400;
    display: flex !important;
    align-items: start;
    margin: 0px 12px 13px 0;
    line-height: 2.8rem;
    font-size: 1.7rem;
    color: #6F7E94; }
    .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-checkboxgroup .formBody .rsformgrid6 .rsformVerticalClear label input, .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-type-checkboxgroup .formBody .rsformVerticalClear label input {
      width: 20px;
      height: 20px;
      max-width: 20px;
      min-width: 20px;
      margin-right: 12px;
      border: 1px solid #B9B9CA; }

.formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-block-page-2.rsform-type-pagebreak {
  margin-top: 32px; }
  .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-block-page-2.rsform-type-pagebreak .formControls .formBody {
    display: flex; }

.formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-block-autre.rsform-type-textarea {
  margin-bottom: 3.2rem; }
  .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-block-autre.rsform-type-textarea textarea {
    border: 1px solid #B9B9CA;
    border-radius: 4px;
    margin-bottom: 0;
    padding: 8px 12px;
    font-size: 16px; }
    .formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-block-autre.rsform-type-textarea textarea::placeholder {
      color: #B9B9CA; }

.formulaire-accompagnement .rsform #rsform_3_page_2 .rsform-block-autre-description.rsform-type-checkboxgroup #autre_description-grouplbl {
  display: none; }

.formulaire-accompagnement .rsform #rsform_3_page_3 #rgpd0-lbl {
  display: flex; }

.formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-block-preciser {
  margin-bottom: 24px; }
  .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-block-preciser p {
    color: #2F3F8D;
    margin-bottom: 0; }
  .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-block-preciser h3, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-block-preciser .h3 {
    margin-bottom: 8px; }

.formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-block-periode {
  display: flex;
  flex-direction: column; }
  .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-block-periode label {
    color: #141D47;
    margin-bottom: 12px; }
  .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-block-periode .formControls .formBody .rsformVerticalClear {
    margin-bottom: 6px; }
    .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-block-periode .formControls .formBody .rsformVerticalClear label {
      display: flex;
      color: #6F7E94;
      margin: 0; }
      .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-block-periode .formControls .formBody .rsformVerticalClear label input {
        border: 1px solid #B9B9CA;
        border-radius: 4px;
        margin-bottom: 0;
        margin-right: 12px;
        line-height: 28px;
        padding: 8px 12px;
        font-size: 16px;
        width: 20px; }

.formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-block-remarques .formControls .formBody input {
  border: 1px solid #B9B9CA;
  border-radius: 4px;
  margin-bottom: 0;
  padding: 8px 12px;
  font-size: 16px;
  line-height: 28px !important; }

.formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-block-desc {
  margin-bottom: 32px; }
  .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-block-desc p {
    color: #6F7E94; }

.formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-block-rgpd.rsform-type-checkboxgroup {
  margin-bottom: 16px; }
  .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-block-rgpd.rsform-type-checkboxgroup > label {
    display: none; }
  .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-block-rgpd.rsform-type-checkboxgroup label {
    line-height: 22px;
    font-size: 14px;
    color: #2F3F8D;
    margin-bottom: 0;
    margin-top: 0; }
    .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-block-rgpd.rsform-type-checkboxgroup label input {
      width: 35px;
      height: 20px;
      border-radius: 4px;
      margin-bottom: 0;
      margin-right: 12px;
      line-height: 28px;
      padding: 8px 12px;
      font-size: 16px; }

.formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-block-rgpd2 p {
  color: #2F3F8D;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 32px; }
  .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-block-rgpd2 p a {
    color: #2F3F8D;
    text-decoration-line: underline; }

.formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-block-send .formControls {
  margin-left: 0; }
  .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-block-send .formControls .formBody {
    display: flex;
    width: 100%; }
    .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-block-send .formControls .formBody button {
      width: 50%; }
    .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-block-send .formControls .formBody button[type="submit"].rsform-submit-button, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-block-send .formControls .formBody button[type="button"].rsform-button-prev {
      background-color: #95C8D5;
      text-shadow: none;
      font-family: "Roboto", sans-serif;
      font-weight: 600;
      border-radius: 8px;
      border: none;
      padding: 0px 24px;
      line-height: 38px;
      font-size: 16px;
      width: 49%;
      margin-right: 15px; }
      @media (max-width: 767.98px) {
        .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-block-send .formControls .formBody button[type="submit"].rsform-submit-button, .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-block-send .formControls .formBody button[type="button"].rsform-button-prev {
          width: 46% !important;
          padding: 0px 15px; } }
    .formulaire-accompagnement .rsform #rsform_3_page_3 .rsform-block-send .formControls .formBody button[type="button"].rsform-button-prev {
      background-color: transparent;
      border: 1px solid #2F3F8D;
      margin-right: 16px;
      color: #2F3F8D; }

.formulaire-accompagnement .rsform .thankyou p > img {
  width: 100%;
  margin-bottom: 209px; }
  @media (max-width: 767.98px) {
    .formulaire-accompagnement .rsform .thankyou p > img {
      margin-bottom: 70px; } }

.formulaire-accompagnement .rsform .thankyou h2, .formulaire-accompagnement .rsform .thankyou .h2 {
  font-size: 4.8rem;
  color: #141D47;
  margin-bottom: 16px;
  font-weight: 700; }

.formulaire-accompagnement .rsform .thankyou p {
  margin-top: 0;
  font-size: 2rem;
  line-height: 31px;
  color: #141D47;
  margin-bottom: 40px; }
  .formulaire-accompagnement .rsform .thankyou p a {
    color: #141D47;
    text-decoration: underline; }

.formulaire-accompagnement .rsform .thankyou a.btn {
  margin-bottom: 367px;
  color: #fff;
  display: inline-flex;
  padding: 0px 70px;
  font-size: 16px;
  font-weight: 600;
  line-height: 38px;
  text-decoration: none;
  background-color: #95C8D5; }
  @media (max-width: 767.98px) {
    .formulaire-accompagnement .rsform .thankyou a.btn {
      margin-bottom: 100px; } }

@media (max-width: 767.98px) {
  .formulaire-accompagnement .rsform .formResponsive input[type="text"], .formulaire-accompagnement .rsform .formResponsive textarea {
    width: 90% !important; } }

.titre-actu {
  color: #2F3F8D;
  font-size: 2.4rem;
  font-weight: bold;
  padding-bottom: 31px; }

@media (max-width: 640px) {
  .page-detail .bandeau {
    display: none; }
  .header {
    box-shadow: none; } }

.espace-pro a {
  border: 1px solid #E3E8ED;
  border-radius: 3px;
  font-size: 2rem;
  color: #2F3F8D;
  font-weight: 500;
  transition: 0.2s all ease-in-out; }
  .espace-pro a img {
    margin-right: 12px; }
  .espace-pro a:hover {
    color: #3CC0CC;
    transition: 0.2s all ease-in-out; }

.b-chiffres-cles__value {
  font-size: 2.4rem;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  color: #807BCD;
  margin-bottom: 1.5rem; }

.b-chiffres-cles-complet__bloc:first-child {
  width: 100%;
  max-width: 100%;
  border-bottom: 1px dashed #2F3F8D;
  padding-bottom: 1.5rem; }
  .b-chiffres-cles-complet__bloc:first-child .b-chiffres-cles-complet__value {
    color: #3CC0CC;
    font-size: 3.5rem; }

.b-chiffres-cles-complet__value {
  font-size: 2.4rem;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  color: #807BCD;
  margin-bottom: 1.5rem; }

.b-organigramme .uk-card {
  background: #fff; }
  .b-organigramme .uk-card-body {
    border-radius: .4rem;
    border: 1px solid #E3E8ED;
    padding: 0; }
  .b-organigramme .uk-card-title {
    margin: 0; }

.b-organigramme__photo {
  padding: .5rem 0 .5rem .5rem; }
  .b-organigramme__photo img {
    border-radius: .4rem;
    width: 100%;
    height: 100%;
    min-height: 100px;
    object-fit: cover;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.13); }

.b-organigramme__content {
  padding: 1.5rem; }
  .b-organigramme__content p {
    margin: 0; }

.b-organigramme__suppleant {
  color: #758DA7;
  margin: 1.5rem 0 0 !important; }

.b-organigramme__role {
  font-size: 1.3rem;
  border-radius: .4rem;
  padding: .5rem .8rem;
  display: inline-block;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  margin-top: 1rem !important; }
  .b-organigramme__role:last-of-type {
    color: #758DA7;
    background: rgba(117, 141, 167, 0.4); }
  .b-organigramme__role:first-of-type {
    color: #fff; }

.b-organigramme-presidence .b-organigramme__role:first-of-type {
  background: #807BCD; }

.b-organigramme-congres .b-organigramme__role:first-of-type {
  background: #3CC0CC; }

.b-organigramme-provinces .b-organigramme__role:first-of-type {
  background: #ED9353; }

.b-organigramme-administrateurs .b-organigramme__role:first-of-type {
  background: #2F3F8D; }

.b-slider__slide {
  position: relative; }
  .b-slider__slide.uk-active .b-slider__contenu {
    display: block; }

.b-slider__contenu {
  display: none;
  background: #2F3F8D;
  color: #fff;
  border-radius: .8rem;
  padding: 1.5rem;
  max-width: 415px;
  margin: 0 0 0 auto; }
  @media (min-width: 1200px) {
    .b-slider__contenu {
      margin-top: -10rem;
      position: relative; } }

.b-tile__bloc {
  color: #fff;
  font-family: "Roboto", sans-serif; }
  .b-tile__bloc .uk-tile {
    border-radius: .4rem;
    display: flex;
    align-items: center;
    justify-content: center; }

.b-tile__title,
.b-tile h3,
.b-tile .h3 {
  color: #fff;
  margin-bottom: 1.5rem;
  font-size: 1.8rem;
  font-family: "Roboto", sans-serif;
  font-weight: 500; }

.b-tile__text {
  color: #fff;
  font-family: "Roboto", sans-serif; }

.b-tile-outlined .b-tile__bloc, .b-tile-outlined .b-tile__title {
  color: #758DA7;
  font-family: "Roboto", sans-serif;
  font-weight: 500; }
  .b-tile-outlined .b-tile__bloc .uk-tile, .b-tile-outlined .b-tile__title .uk-tile {
    border-radius: .4rem;
    border: 1px solid #E3E8ED;
    background: none; }

.b-tile-outlined .b-tile__title {
  margin-bottom: 1.5rem;
  font-size: 1.6rem; }

.b-tile-outlined .b-tile__text {
  color: #758DA7; }

.u-card {
  margin-top: 2.4rem !important; }
  .u-card__bloc {
    border: 1px solid #E3E8ED;
    border-radius: 4px; }
    .u-card__bloc:not(:first-child) {
      border-top: none;
      border-right: 2rem solid #A4B71B; }
    .u-card__bloc-content {
      padding: 2.4rem 1.5rem; }
      @media (max-width: 960px) {
        .u-card__bloc-content.bloc-content-1 {
          background: unset !important; } }
      .u-card__bloc-content:last-child {
        padding: 2.4rem; }
      .u-card__bloc-content h3, .u-card__bloc-content .h3 {
        margin-bottom: 1.5rem; }
        .u-card__bloc-content h3:not(:first-child), .u-card__bloc-content .h3:not(:first-child) {
          margin-top: 2.4rem; }
      .u-card__bloc-content p {
        margin-top: 1.5rem; }
        .u-card__bloc-content p:last-child {
          margin-bottom: 0; }
  .u-card__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px; }

.u-card-simple__bloc {
  margin-top: 2.4rem;
  border: 1px solid #E3E8ED;
  border-radius: 4px;
  padding: 1.5rem 2.4rem; }
  .u-card-simple__bloc h3, .u-card-simple__bloc .h3 {
    margin: 0 0 1.5rem; }
  .u-card-simple__bloc-image {
    margin-top: 8rem; }

.u-card-simple__image {
  width: 170px;
  height: 130px;
  margin: -8rem 0 1.5rem; }

.alias-notre-fonctionnement-organisation .u-card__bloc-content {
  display: flex;
  align-items: center; }
  .alias-notre-fonctionnement-organisation .u-card__bloc-content p:first-of-type {
    margin-top: 0; }

.centpourcent {
  margin-left: -50vw;
  margin-right: -50vw;
  clear: both;
  position: relative;
  right: 50%;
  left: 50%;
  width: 100vw; }

.cart {
  right: -182px;
  transition: all 0.4s ease-in-out; }

.encart {
  transition: all 0.4s ease-in-out;
  z-index: 3000; }

a:hover {
  text-decoration: none; }

.uk-offcanvas-content {
  overflow: hidden; }

* {
  outline: none; }

.picto_valide {
  background: url(../img/picto_valide.png) no-repeat left 50%;
  padding-left: 30px; }

.picto_invalide {
  background: url(../img/picto_invalide.png) no-repeat left 50%;
  padding-left: 30px; }

.dont-break-out {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;
  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto; }

.youtube_player {
  max-width: 560px; }
  .youtube_player > iframe {
    width: 100% !important; }

.mea_ressources .youtube_player {
  max-width: unset; }

@media (max-width: 639px) {
  .u-md-pl-0 {
    padding-left: 0 !important; } }

.text-violet {
  color: #807BCD; }

.text-orange {
  color: #ED9353; }

.text-bleu {
  color: #2F3F8D; }

.text-vert {
  color: #3CC0CC; }

.bg-violet {
  background: #807BCD; }

.bg-orange {
  background: #ED9353; }

.bg-vert {
  background: #3CC0CC; }

.hover-secondary02 div, .hover-secondary02 a {
  transition: all ease-in-out 0.2s; }

.hover-secondary02:hover div, .hover-secondary02:hover a {
  color: #BE581F !important;
  transition: all ease-in-out 0.2s; }

.youtube_player_social iframe {
  border-radius: 0.8rem; }
  @media (min-width: 992px) {
    .youtube_player_social iframe {
      height: 250px; } }

.u-list-repeatable__item {
  border: 1px solid #E3E8ED;
  border-radius: 4px;
  padding: 1rem 1.5rem;
  gap: 1rem 4rem; }
  .u-list-repeatable__item:not(:last-child) {
    margin-bottom: 1rem; }

.u-list-repeatable__img {
  width: 45px;
  height: 45px;
  object-fit: cover;
  margin-right: 1.5rem; }

.u-list-repeatable__libelle {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #2F3F8D; }

.u-list-repeatable__link, .u-list-repeatable__mail, .u-list-repeatable__tel {
  color: #758DA7 !important;
  position: relative;
  padding-left: 3rem; }
  .u-list-repeatable__link::before, .u-list-repeatable__mail::before, .u-list-repeatable__tel::before {
    position: absolute;
    left: 0; }

.u-list-repeatable__link::before {
  content: url(../img/ico-link.svg); }

.u-list-repeatable__mail::before {
  content: url(../img/ico-mail.svg); }

.u-list-repeatable__tel::before {
  content: url(../img/ico-tel.svg); }

.u-list-repeatable2__item {
  border: 1px solid #E3E8ED;
  border-radius: 4px;
  padding: 1.5rem 2rem; }

.u-list-repeatable2__img {
  max-width: 32px !important;
  width: 32px !important;
  height: 32px;
  object-fit: scale-down;
  margin-right: 2rem; }

.u-list-repeatable2__libelle {
  font-family: "Roboto", sans-serif;
  color: #141D47;
  font-size: 1.8rem !important; }

.bg_violet {
  background-color: #6A65A7; }

.mea-ou-nous-trouver .uk-active a, .mea-dernier-outils .uk-active a {
  background-color: #141D47 !important;
  color: #fff !important; }

/* ====================== Le responsive du vw-full de mobil vers desktop (vw-sm-full etc...)
 */
.vw-full {
  margin-left: -50vw;
  margin-right: -50vw;
  clear: both;
  position: relative;
  right: 50%;
  left: 50%;
  width: 100vw; }

.vw-full {
  margin-left: -50vw;
  margin-right: -50vw;
  clear: both;
  position: relative;
  right: 50%;
  left: 50%;
  width: 100vw; }

@media (min-width: 576px) {
  .vw-sm-full {
    margin-left: -50vw;
    margin-right: -50vw;
    clear: both;
    position: relative;
    right: 50%;
    left: 50%;
    width: 100vw; } }

@media (min-width: 768px) {
  .vw-md-full {
    margin-left: -50vw;
    margin-right: -50vw;
    clear: both;
    position: relative;
    right: 50%;
    left: 50%;
    width: 100vw; } }

@media (min-width: 992px) {
  .vw-lg-full {
    margin-left: -50vw;
    margin-right: -50vw;
    clear: both;
    position: relative;
    right: 50%;
    left: 50%;
    width: 100vw; } }

@media (min-width: 1200px) {
  .vw-xl-full {
    margin-left: -50vw;
    margin-right: -50vw;
    clear: both;
    position: relative;
    right: 50%;
    left: 50%;
    width: 100vw; } }

@media (min-width: 1400px) {
  .vw-xxl-full {
    margin-left: -50vw;
    margin-right: -50vw;
    clear: both;
    position: relative;
    right: 50%;
    left: 50%;
    width: 100vw; } }

@media (min-width: 1920px) {
  .vw-xxxl-full {
    margin-left: -50vw;
    margin-right: -50vw;
    clear: both;
    position: relative;
    right: 50%;
    left: 50%;
    width: 100vw; } }

@media (max-width: 991.98px) {
  .vw-md-full-under {
    margin-left: -50vw;
    margin-right: -50vw;
    clear: both;
    position: relative;
    right: 50%;
    left: 50%;
    width: 100vw; } }

.bg-degrade-accueil {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f5fcfd+0,ffffff+77 */
  background: #F5FCFD;
  /* Old browsers */
  background: -moz-linear-gradient(top, #F5FCFD 0%, #fff 77%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #F5FCFD 0%, #fff 77%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #F5FCFD 0%, #fff 77%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */ }

.fil-arianne {
  z-index: 0; }

.uk-breadcrumb > * > * {
  font-size: 1.4rem;
  color: #fff;
  text-shadow: 1px 1px 2px #2E2E2E; }

.uk-breadcrumb a {
  padding-right: 13px;
  position: relative; }
  .uk-breadcrumb a:after {
    content: "";
    width: 5px;
    height: 5px;
    border-radius: 10px;
    background-color: #fff;
    display: block;
    position: absolute;
    top: 8px;
    right: 0; }

.arianne-container-pro {
  margin-left: -50vw;
  margin-right: -50vw;
  clear: both;
  right: 50%;
  left: 50%;
  width: 100vw;
  background-size: 100%;
  background: url(../img/support-pro-bg.jpg) no-repeat center top #5BD5AC; }

.arianne-pro {
  padding: 24px 0; }
  .arianne-pro h1, .arianne-pro .h1 {
    color: #fff;
    padding: 15px 0 55px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 2.4rem; }
    .arianne-pro h1 svg, .arianne-pro .h1 svg {
      margin-bottom: 16px; }
  .arianne-pro a {
    color: #fff; }
  .arianne-pro span {
    color: #fff !important; }

.arianne-container-chiffre {
  margin-left: -50vw;
  margin-right: -50vw;
  clear: both;
  right: 50%;
  left: 50%;
  width: 100vw;
  background-size: 100%;
  background: url(../img/nos-chiffres-bg.jpg) no-repeat center top #2F3F8D; }

.arianne-chiffres {
  padding: 24px 0; }
  .arianne-chiffres h1, .arianne-chiffres .h1 {
    color: #fff;
    padding: 15px 0 55px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 2.4rem; }
  .arianne-chiffres a {
    color: #fff; }
  .arianne-chiffres span {
    color: #fff !important; }

.arianne-container-lagence {
  margin-left: -50vw;
  margin-right: -50vw;
  clear: both;
  right: 50%;
  left: 50%;
  width: 100vw;
  background-size: 100%;
  background: url(../img/agence-bg.jpg) no-repeat center top #807BCD; }

.arianne-lagence {
  padding: 24px 0; }
  .arianne-lagence h1, .arianne-lagence .h1 {
    color: #fff;
    padding: 15px 0 55px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 2.4rem; }
  .arianne-lagence a {
    color: #fff; }
  .arianne-lagence span {
    color: #fff !important; }

.arianne-contact {
  background-color: #2F3F8D;
  padding: 16px 20px;
  border-radius: 4px;
  line-height: 16px;
  font-size: 1.4rem; }
  .arianne-contact ul {
    margin: 0 !important; }
    .arianne-contact ul a:after {
      top: 6px; }

/*
 * Primary
 */
.uk-notification-message-primary {
  background: #1e87f0;
  color: #f8f8f8; }

/*
 * Success
 */
.uk-notification-message-success {
  background: #32d296;
  color: #f8f8f8; }

/*
 * Warning
 */
.uk-notification-message-warning {
  background: #faa05a;
  color: #f8f8f8; }

/*
 * Danger
 */
.uk-notification-message-danger {
  background: #f0506e;
  color: #f8f8f8; }

.uk-close {
  color: #f8f8f8; }
