.page-accueil {
	.bandeau {
		position: relative;
		z-index: 3;
	}
}

@include media-breakpoint-down(sm) {
  .card-home-group {
	border: 1px solid $gray-101;
	img{
	  border-top-left-radius:16px ;
	  border-bottom-left-radius:16px ;
	}
  }
}