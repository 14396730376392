.introduction-categorie{
	h2{
		font-size: 2.4rem;
		color: $bleu2;
		font-weight: bold;
	}
	.category-desc{
		color: $gris;
		font-size: 1.6rem;
		a{
			color: $vert;
		}
	}
}

.blog{
	padding-bottom: 30px;
	padding-top: 15px;
	.page-header{
		h1{
			color: $bleu2;
			font-size: 2.4rem;
			font-weight: bold;
		}
		padding-bottom: 15px;
	}
}

@media (min-width:1200px) {
	.page-evenement {
		.zone-liens {
			padding-left: 0;
		}
	}
}

.page-actu{
	.zone-liens{
		position: relative;
		@media (min-width:1200px) {
			right: 100px;
		}
	}
}

.trie-date{
	color: $gris;
	button{
		border: 1px solid $gris5;
		border-radius: 4px;
		color:$bleu;
		padding: 5px 8px;
		background-color: $blanc;
		line-height: normal;
		margin-left: 12px;
		.uk-icon{
			margin-left: 8px;
		}
	}
	.uk-dropdown.uk-open{
		border: 1px solid $gris5;
		border-radius: 4px;
		a{
			color:$bleu;
		}
	}
}

.menu-filtre{
	border: 1px solid $gris5;
	border-radius: 4px;
	width: fit-content;
	h2{
		color: $bleu;
		font-size: 2.2rem;
		padding-right: 45px;
		span{
			color: $vert;
			padding-right: 15px;
		}
	}
	a{
		color: $bleu;
		display: block;
		padding-left: 35px;
		position: relative;
		&:before{
			content: '';
			display: block;
			width: 20px;
			height: 20px;
			border-radius: 20px;
			border: 1px solid $gris5;
			background-color: $blanc;
			position: absolute;
			left: 0;
			top: 0;
			transition: all ease-in-out 0.2s;
		}
		&:hover{
			&:before{
				border: 8px solid $bleu;
				height: 6px;
				width: 6px;
				transition: all ease-in-out 0.2s;
			}
		}
	}
	.uk-active{
		a{
			&:before{
				border: 8px solid $bleu;
				height: 6px;
				width: 6px;
				transition: all ease-in-out 0.2s;
			}
		}
	}
}