// mef du module fil d'arianne

.fil-arianne{
	z-index: 0;
}

.uk-breadcrumb > * > *{
	font-size: 1.4rem;
	color: $blanc;
	text-shadow: 1px 1px 2px $noir;
}

.uk-breadcrumb{
	a{
		padding-right: 13px;
		position: relative;
		&:after{
			content: "";
			width: 5px;
			height: 5px;
			border-radius: 10px;
			background-color: $blanc;
			display: block;
			position: absolute;
			top: 8px;
			right: 0;
		}
	}
}

.arianne-container-pro {
	margin-left: -50vw;
	margin-right: -50vw;
	clear: both;
	right: 50%;
	left: 50%;
	width: 100vw;
	background-size: 100%;
	background: url(../img/support-pro-bg.jpg) no-repeat center top $vert2;
}

.arianne-pro {
	padding: 24px 0;
	h1{
		color: $blanc;
		padding: 15px 0 55px;
		font-family: $global-font-family;
		font-weight: $font-weight-medium;
		font-size: 2.4rem;
		svg{
			margin-bottom:16px;
		}
	}
	a{
		color: $blanc;
	}
	span{
		color: $blanc !important;
	}
}

.arianne-container-chiffre {
	margin-left: -50vw;
	margin-right: -50vw;
	clear: both;
	right: 50%;
	left: 50%;
	width: 100vw;
	background-size: 100%;
	background: url(../img/nos-chiffres-bg.jpg) no-repeat center top $bleu;
}

.arianne-chiffres{
	padding: 24px 0;
	h1{
		color: $blanc;
		padding: 15px 0 55px;
		font-family: $global-font-family;
		font-weight: $font-weight-medium;
		font-size: 2.4rem;
	}
	a{
		color: $blanc;
	}
	span{
		color: $blanc !important;
	}
}

.arianne-container-lagence {
	margin-left: -50vw;
	margin-right: -50vw;
	clear: both;
	right: 50%;
	left: 50%;
	width: 100vw;
	background-size: 100%;
	background: url(../img/agence-bg.jpg) no-repeat center top $violet2;
}

.arianne-lagence{
	padding: 24px 0;
	h1{
		color: $blanc;
		padding: 15px 0 55px;
		font-family: $global-font-family;
		font-weight: $font-weight-medium;
		font-size: 2.4rem;
	}
	a{
		color: $blanc;
	}
	span{
		color: $blanc !important;
	}
}

.arianne-contact{
	background-color: $bleu;
	padding: 16px 20px;
	border-radius: 4px;
	line-height: 16px;
	font-size:1.4rem;
	ul{
		margin:0 !important;
		a:after{
			top:6px;
		}
	}
}