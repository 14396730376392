.moduletable_secondaire {
  ul {
    li {
      &.item-132 {
        a {
          background-color: $vert2;
          color: $blanc;
          text-transform: uppercase;
          font-weight: bold;
          border-radius: 3px;
          font-size: 1.3rem;
          padding: 8px 32px;

          &:hover {
            color: $bleu;
          }
        }
      }

      &.uk-open {
        a {
          &:after {
            transform: initial;
          }
        }
      }

      &.active {

        > a {
          color: $violet2;
          font-weight: bold;
          &:hover {
            text-decoration: none;
          }
        }

        .active {
          a {
            color: $vert;
            font-weight: bold;
            text-decoration: none;
            &:hover {
              text-decoration: none;
            }
          }
        }
      }

      a {
        color: $bleu2;
        font-size: 1.4rem;
        //padding: 8px 24px 8px 0;

        &.uk-open {
          color: $vert;
        }

        &:hover {
          text-decoration: underline;
        }

        &:after {
          position: absolute;
          right: 10px;
          transform: rotate(180deg);
        }
      }

    }
  }
}