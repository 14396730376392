
.tags_dokamo_search{
  //overflow: hidden;
  position: relative;
  padding:18px;
  li{
    &:nth-child(2n+3), &:nth-child(2){
      a {
        background-color: $violet;
      }
    }
    &:nth-child(3n+3){
      a {
        background-color: $rouge;
      }
    }
    a{
      font-size: 1.4rem;
      font-family: $global-font-family;
      padding: 7px 12px;
      background-color: $vert;
      color: $blanc;
      border-radius: 3px !important;
      display: block;
      margin: 0 10px;
    }
  }
  .uk-slidenav{
    font-size: 1.4rem;
    font-family: $global-font-family;
    padding: 7px 15px !important;
    background-color: #F8F8F8 !important;
    color: $gris !important;
    display: block;
  }
}

#line-loader{
  width:0%;
  height:1px;
  border:1px solid $vert2;
  background: $vert2;
  margin:auto;
  margin-top:-1px;
}

.module_dokamo_search{
  z-index: 3;
  width:510px;
  @media (min-width: 1024px) {
    width:400px;
  }
  @media (min-width: 960px) {
    position: absolute;
    margin-left: -20px;
    background-color: #fff;
  }
  @media (max-width: 640px) {
    width: auto;
    margin: 15px;
    position: relative !important;
  }
  #form_dokamo_search{
    background:$blanc;
    padding:5%;
    padding-left:0;
    padding-right:0;

    input#input_mod_dokamo_search_query{
      //margin-bottom: 15px;
      border:0;
      border-bottom: 1px solid rgba($bleu,0.15);
      color: rgba($bleu,0.58);
      width:90%;
      font-size:1.2em;
      background: url(../img/loupe.png) no-repeat right top;
      @media (max-width: 640px) {
        width:80%;
      }
    }
  }

  #no_result {
    display: none;
    transmition-duration:0.5s;
    padding: 20px;
    &.active {
      display: block;
    }
  }

  #hint {
    font-size:0.8em;
    transition-duration:0.5s;
    display: none;
    padding: 20px;
    &.active{
      display: block;
    }
  }

  //Block de résultats ajax
  #results_dokamo_search{
    box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16);
    ul{
      margin-bottom: 0;
      li{
        padding-left:5%;
        &:nth-child(even) {
          background: rgba(29, 61, 102, 0.07)
        }
        &:hover{
          background: rgba(35, 55, 109, 0.09)
        }
        h3 {
          margin-bottom: 0;
        }
        .content_type{
          color:#00000080;
          text-transform: capitalize ;
          font-size:12px;
        }
      }
    }
  }

}

.module_dokamo_search-bandeau{
  @media (min-width: 640px) {
   display: none !important;
  }
}

.module_dokamo_search-intro{
  box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16);
  top: -80px;
  right: 0;
  @media (max-width: 640px) {
    display: none !important;
  }
}

body.alias-supports-pro{
  #module_dokamo_search #form_dokamo_search{
    background: #e8e7ff;
  }
}

//
// spinner
//
.sk-folding-cube {
  margin: 10px auto;
  width: 20px;
  height: 20px;
  position: relative;
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
  display:none;
}

.sk-folding-cube .sk-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.sk-folding-cube .sk-cube:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1e87f0;
  -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
  animation: sk-foldCubeAngle 2.4s infinite linear both;
  -webkit-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}
.sk-folding-cube .sk-cube2 {
  -webkit-transform: scale(1.1) rotateZ(90deg);
  transform: scale(1.1) rotateZ(90deg);
}
.sk-folding-cube .sk-cube3 {
  -webkit-transform: scale(1.1) rotateZ(180deg);
  transform: scale(1.1) rotateZ(180deg);
}
.sk-folding-cube .sk-cube4 {
  -webkit-transform: scale(1.1) rotateZ(270deg);
  transform: scale(1.1) rotateZ(270deg);
}
.sk-folding-cube .sk-cube2:before {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.sk-folding-cube .sk-cube3:before {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
.sk-folding-cube .sk-cube4:before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}
@-webkit-keyframes sk-foldCubeAngle {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  } 25%, 75% {
      -webkit-transform: perspective(140px) rotateX(0deg);
      transform: perspective(140px) rotateX(0deg);
      opacity: 1;
    } 90%, 100% {
        -webkit-transform: perspective(140px) rotateY(180deg);
        transform: perspective(140px) rotateY(180deg);
        opacity: 0;
      }
}

@keyframes sk-foldCubeAngle {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  } 25%, 75% {
      -webkit-transform: perspective(140px) rotateX(0deg);
      transform: perspective(140px) rotateX(0deg);
      opacity: 1;
    } 90%, 100% {
        -webkit-transform: perspective(140px) rotateY(180deg);
        transform: perspective(140px) rotateY(180deg);
        opacity: 0;
      }
}
