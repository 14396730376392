.filtre_actu{
  a{
    .image_intro{
      width: 100%;
      img{
        border-radius: 4px;
        position: relative;
        z-index: 1;
        top: 3px;
        width: 100%;
      }
      span{
        color: $blanc;
        border: 1px solid $blanc;
        border-radius: 4px;
        padding: 0px 20px;
        font-size: 1.4rem;
        z-index: 2;
      }
    }
    .intro-texte{
      padding: 15px;
      border: 1px solid $gris5;
      border-radius: 0 0 4px 4px;
      h2{
        color: $bleu;
        margin: 0;
      }
      .date{
        color: $gris;
        font-size: 1.4rem;
      }
      .thematique{
        font-size: 1.4rem;
        color: $gris;
      }
    }
  }
}