.category-module_evenement{
  a{
    color: $rouge;
    &:hover{
      .uk-icon{
        transition: $transition;
        padding-right: 8px;
      }
      .mod-articles-category-introtext{
        transition: $transition;
        color: $rouge;
      }
    }
  }
  h3{
      color: $rouge;
      font-size: 2.4rem;
      font-weight: bold;
      padding-bottom: 31px;
  }
  .mod-articles-category-date{
    strong{
      font-size: 2.4rem;
    }
  }
  .uk-icon{
    color: $noir;
    transition: $transition;
  }
  .uk-background-default{
    padding: 32px;
  }
  .bloc-texte{
    margin-left: 35px;
    width: 200px;
    @media(max-width: 1024px) {
      margin-left: 15px;
    }
    padding-right: 10px;
    padding-left: 0;
  }
  strong{
    font-weight: bold;
  }
  .mod-articles-category-introtext{
    color: $gris;
    transition: $transition;
  }
}

.view-category{
  .category-module_evenement{
    a{
      border: 1px solid rgba($gris,0.2);
      padding: 10px 0;
    }
    .mod-articles-category-date{
      margin-left: 20px;
    }
  }
}
@media(max-width: 959px){
  .category-module_evenement {
    .tout-evenements{
      color: $rouge;
      text-transform: uppercase;
      text-align: center;
      font-weight: bold;
      border-radius: 3px;
      font-size: 1.3rem;
      padding: 8px 32px;
      margin: 20px 15px 10px;
      display: block;
      border: 1px solid $rouge;
    }
  }
  .view-article{
    .category-module_evenement{
      .tout-evenements{
        margin: 20px 0 10px;
      }
    }
  }
}
@media(max-width: 640px){
  .category-module_evenement {
    h3 {
      padding-left: 15px;
    }

    .uk-background-default {
      margin: 0 15px;
      padding: 12px;
    }

    .chevron {
      padding-left: 0;
      display: block;
    }
  }
}

@media(min-width: 640px)and(max-width:1024px){
  .category-module_evenement {
    h3 {
      margin: 0 15px !important;
    }
    .chevron {
      display: none;
    }
  }
}
