.titre_formulaire{
	color: $bleu;
	font-size: 24px;
	font-family: $global-font-family;
  	font-weight: $font-weight-medium;
	margin-top: 0;
}

.formulaire_faq{
	padding:24px 32px;
	background-color:$bleu6;
	@media (min-width: 640px) {
		background: url(../img/bg_mea_faq.png) no-repeat right 50% $bleu6;
	}
	h2{
		color: $blanc;
		font-size: 24px;
		background: url(../img/picto_mea_faq.png) no-repeat left top;
		padding-left: 50px;
		font-family: $global-font-family;
		font-weight: $font-weight-medium;
	}
	.uk-select, .uk-textarea{
	  width: 100%;
	}
	input, select, textarea{
		background-color: $blanc;
		padding: 10px 16px;
		color: $gris;
		&::placeholder {
			color: $gris;
		}
	}
	button{
		background-color: $vert;
		padding: 2px 80px 0;
		text-transform: uppercase;
		font-size: 1.3rem;
	}
	.champs{
		@media (min-width: 640px) {
			padding-right:24px;
		}
	}
  .texte-rgpd{
	color: $contact;
	a{
	  color: $white;
	}
  }
}

#faq-poser-une-question-form{
	display:none;
}