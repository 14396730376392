.b-organigramme {
  .uk-card {
    background: $blanc;
    &-body {
      border-radius: .4rem;
      border: 1px solid $gris5;
      padding: 0;
    }
    &-title {
      margin: 0;
    }
  }
  &__photo {
    padding: .5rem 0 .5rem .5rem;
    img {
      border-radius: .4rem;
      width: 100%;
      height: 100%;
      min-height: 100px;
      object-fit: cover;
      box-shadow: 0 10px 20px 0 rgba(0,0,0,0.13);
    }
  }
  &__content {
    padding: 1.5rem;
    p {
      margin: 0;
    }
  }
  &__suppleant {
    color: $gris;
    margin: 1.5rem 0 0 !important;
  }
  &__role {
    font-size: 1.3rem;
    border-radius: .4rem;
    padding: .5rem .8rem;
    display: inline-block;
    color: $blanc;
    font-family: $global-font-family;
    font-weight: $font-weight-medium;
    margin-top: 1rem !important;
    &:last-of-type {
      color: $gris;
      background: rgba($gris, .4);
    }
    &:first-of-type {
      color: $blanc;
    }
  }
}
.b-organigramme-presidence {
  .b-organigramme {
    &__role:first-of-type {
      background: $violet2;
    }
  }
}
.b-organigramme-congres {
  .b-organigramme {
    &__role:first-of-type {
      background: $vert;
    }
  }
}
.b-organigramme-provinces {
  .b-organigramme {
    &__role:first-of-type {
      background: $orange2;
    }
  }
}
.b-organigramme-administrateurs {
  .b-organigramme {
    &__role:first-of-type {
      background: $bleu;
    }
  }
}
