.bannergroup_partenaires{
	h2{
		color: $bleu;
		font-size: 2.4rem;
		font-weight: bold;
   		margin-bottom: 5px;
	}
	@media (min-width: 960px) {
		.uk-slidenav-previous{
			left: -25px;
		}
		.uk-slidenav-next{
			right: -25px;
		}
	}
}