.bannergroup_bandeau_detail {
	.bandeau-nav{
		z-index: 3;
		a{
			background-color: $violet;
			color: $blanc;
			display: inline-block;
			margin-left: 1px;
			&:hover{
				background-color: $violet2;
			}
			&:first-child{
				border-radius: 3px 0 0 3px;
			}
			&:last-child{
				border-radius: 0 3px 3px 0;
			}
		}
	}
}