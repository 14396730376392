// Mef page newsletter.

.com_acymailing {
  .main {
    #acymodifyform {
      display: table;
      margin: 0 auto;
      margin-top: 15px;
      max-width: 610px;
      width: 100%;

      h1 {
        color: $bleu;
        font-family: $global-font-family;
        font-weight: $font-weight-medium;
        font-size: 2.4rem;
        margin-bottom: 30px;
        padding-left: 35px;
        background: url(../img/inscription.png)  no-repeat left;
      }

      p {
        color: $gris;
        font-family: $global-font-family;
        font-size: 1.6rem;
        font-weight: 300;
      }

      legend {
        color: $gris;
        font-family: $global-font-family;
        font-size: 14px;
        font-weight: $font-weight-medium;
        margin-bottom: 15px;
      }

      form {
        margin-top: 50px;
        label {
          display: block;
          color: $gris;
          font-family: $global-font-family;
          font-size: 1.4rem;
          font-weight: $font-weight-medium;
          margin-bottom: 15px;
        }

        input[type="text"], input[type="email"]  {
          box-sizing: border-box;
          border: 1px solid $gris9;
          border-radius: 4px;
          padding: 10px 15px;
          color: $gris9;
          width: 100%;
        }

        #trname {
          margin-right: 2rem;
        }

        .inputVal {
          width: 100%;
        }

        #acyuserinfo {
          display: flex;
          flex-flow: row wrap;
          #trname, #tremail {
            flex: 1 25%;
          }

          #trhtml, #trpublic {
            flex: 1 100%;
          }
        }

        #trhtml, #trpublic {
          display: flex;
          flex-direction: row;
          align-items: center;
          label {
            margin-bottom: 0px;
          }

          .inputVal {
            display: flex;
            flex-direction: row;
            align-items: center;
          }
        }

        #trhtml {
          margin-bottom: 25px;
          .inputVal {
            span {
              &:first-of-type {
                margin-right: 1rem;
              }
            }
          }

          input {
            position: relative;
            margin-right: 1rem;
            &:before {
              content: "";
              width: 20px;
              height: 20px;
              background-color: $bleu;
              position: absolute;
              border-radius: 100%;
              top: -3px;
              z-index: 0;
            }
            &:checked {
              &:after {
                content: "";
                width: 6px;
                height: 6px;
                background-color: $blanc;
                position: absolute;
                border-radius: 100%;
                z-index: 1;
                top: 4px;
                left: 7.5px;
              }
            }
          }
        }

        #trpublic {
          border-top: 1px solid $gris5;
          padding-top: 25px;
          align-items: baseline;
          .inputVal {
            display: flex;
            flex-direction: column;
            align-items: baseline;
            span {
              &:first-of-type {
                margin-bottom: 1rem;
              }
            }
          }
        }

        #trpublic, #acyusersubscription {
          input {
            position: relative;
            margin-right: 1rem;
            &:before {
              content: "";
              width: 20px;
              height: 20px;
              background-color: $bleu;
              position: absolute;
              top: -3px;
              z-index: 0;
              border-radius: 4px;
            }
            &:checked {
              &:after {
                content: "";
                width: 3px;
                height: 6px;
                position: absolute;
                border: solid $blanc;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
                z-index: 1;
                top: 2px;
                left: 7.5px;
              }
            }
          }
        }

        .adminform.acy_subscription_list {
          border-top: 1px solid $gris5;
          margin-top: 25px;
          padding-top: 25px;
          .acy_onelist {
            //display: flex;
            //flex-direction: row;
            //margin-bottom: 2rem;
            //.acyListInfo {
            //  width: 100%;
            //}

            .controls {
              display: flex;
              flex-direction: row;
            }
          }

          .acystatus {
            float: none;
            width: initial;
            max-width: initial;
            .controls {
              label {
                display: flex;
                flex-direction: row;
                margin-bottom: 0;
                line-height: initial;
              }
            }
          }

          .list_name {
            color: $bleu;
            font-family: $global-font-family;
            font-size: 1.4rem;
            margin-left: 2rem;
          }

          .list_description {
            display: none;
          }
        }

        .acymodifybutton {
          margin-top: 25px;
          margin-bottom: 50px;
          padding-top: 25px;
          input {
            border-radius: 4px;
            background-color: $vert;
            padding: 13px 70px;
            color: $blanc;
            font-family: $global-font-family;
            font-weight: $font-weight-bold;
            font-size: 1.3rem;
            letter-spacing: 0.37px;
            text-transform: uppercase;
            border: none;
            cursor: pointer;
            transition: all 0.3s;
            appearance: none;
            &:hover {
              background-color: darken($vert, 15%);
            }
          }
        }
      }
    }
  }
}
