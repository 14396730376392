.questions-posees{
    padding-bottom:15px;
     .question{
       cursor: pointer;
       color: $bleu;
       position: relative;
       padding-right: 25px;
       font-size: 1.6rem;
       span{
         position: absolute;
         right: 0;
         top: 4px;
         svg{
           transition: all 0.2s ease-in-out;
         }
       }
     }
  .accordion{
    margin-top: 0;
    margin-bottom: 15px;
    &.uk-open, &:hover{
      .question{
        svg{
          transform: rotate(45deg);
          transition: all 0.2s ease-in-out;
        }
      }
    }
  }
}

.bloc-poser-une-question{
  padding-bottom: 25px;
  h1{
    color: $bleu2;
    font-size: 2.4rem;
    font-weight: bold;
  }
  .texte-intro{
    color: $gris;
    font-size: 1.6rem;
  }
  .menu-filtre{
    li {
      input {
        display: none;
        &:checked + label:before {
          border: 8px solid $bleu;
          height: 6px;
          width: 6px;
          transition: all ease-in-out 0.2s;
        }
      }
      label {
        padding-left: 35px;
        padding-top: 2px;
        position: relative;
        cursor: pointer;
        color: #304D6D;
        &:hover {
          &:before {
            border: 8px solid $bleu;
            height: 6px;
            width: 6px;
            transition: all ease-in-out 0.2s;
          }
        }
        &:before {
          content: '';
          display: block;
          width: 20px;
          height: 20px;
          border-radius: 20px;
          border: 1px solid $gris5;
          background-color: $blanc;
          position: absolute;
          left: 0;
          top: 0;
          transition: all ease-in-out 0.2s;
        }
      }
    }
    .btn-question{
      margin-top: 25px;
      display: block;
      border: 0;
      width: 100%;
      background-color: $bleu2 !important;
      text-transform: uppercase;
      color:$blanc;
      text-align: center;
      padding: 10px;
      border-radius: 4px;
      cursor: pointer;
      appearance: none;
      &:hover{
        background-color: $bleu;
      }
    }
  }

  @media all and (max-width: $medium) {
    .menu-filtre{
      width: 95%;
      li {
        width: 49%;
        display: inline-block;
        font-size: 1.2rem;
      }
    }
  }

  .accordeon{
    .menu-accordeon{
      .titre-question {
        color: $bleu;
        font-size: 1.8rem;
        padding-bottom:10px;
        padding-right: 25px;
      }
      .label-thematique{
        font-size: 1.4rem;
        color: $gris;
      }
    }
    .accordeon-contenu{
      a{
        color: $vert;
      }
    }
  }
  #pagination{
    padding-top: 35px;
    color: $bleu;
    font-size: 1.4rem;
    #prev-page{
      padding-left: 15px;
      padding-right: 8px;
      color: $gris5;
    }
    #next-page{
      padding-right: 15px;
      padding-left: 8px;
      color: $bleu;
    }
    .en-cours{
      border: 1px solid $gris5;
      border-radius: 4px;
      padding: 2px 6px 0;
      font-size: 1.4rem;
      color: $vert2;
      position: relative;
      bottom: 3px;
    }
  }
}
