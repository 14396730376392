.bg-degrade-accueil{
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f5fcfd+0,ffffff+77 */
  background: $secondary01; /* Old browsers */
  background: -moz-linear-gradient(top,  $secondary01 0%, $white 77%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  $secondary01 0%,$white 77%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  $secondary01 0%,$white 77%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

@include media-breakpoint-up(lg) {
  .bg-rebee {
  
  }
}