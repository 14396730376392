.category-module_intro{
	.bloc-intro{
		padding-top: 10px;
		.mod-articles-category-introtext{
			color: $blanc;
			font-size: 1.6rem;
			opacity: 0.7;
			line-height: 18px;
			height: 55px;
		}
		h2{
			color: $blanc;
			font-size: 2.4rem;
			font-weight: 500;
			margin-bottom: 19px;
		}
		a{
			background-color: $violet2;
			border-radius: 3px 3px 0 0;
			color: $blanc;
			transition: $transition;
			span{
				color: $violet3;
				font-size: 1.2rem;
				transition: $transition;
				&.uk-icon{
					transition: $transition;
					svg{
						color: $blanc;
					}
				}
			}
			strong{
				color: $blanc;
				font-size:1.6rem;
			}
			&:hover{
				background-color: lighten($violet2,5%);
				transition: $transition;
				span{
					color: $blanc;
					transition: $transition;
				}
				.uk-icon{
					transition: $transition;
					padding-right: 8px;
				}
			}
		}
		.bloc-bouton{
			padding-top: 15px;
		}
	}
}

@media(max-width: 640px){
	.category-module_intro{
		padding-bottom: 15px;
		position: relative;
		z-index: 3;
		.bloc-intro {
			padding-top: 0;
			.mod-articles-category-introtext {
				height: auto;
			}
			h2{
				margin-top: 0;
			}
		}
		.bloc-bouton{
			a {
				border-radius: 5px;
			}
		}
	}
}