.plan-du-site {
  .item-page {
    width: 60%;
    margin: auto;
  }

  .uk-accordion {
    @media screen and (min-width: 576px)  {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
}