.item-page{
	overflow: hidden;
	.page-header{
		.headline {
			display: flex;
			align-items: center;
			gap: 25px;
			margin: .5rem 0 3.2rem;
			@media (max-width: $breakpoint-small) {
				flex-wrap: wrap;
				gap: 20px;
			}
			&__icon {
				background: $vert;
				border-radius: 50%;
				position: relative;
				width: 60px;
				height: 60px;
				img	{
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}
			}
			h1 {
				margin: unset;
			}
		}
		h1{
			color: $bleu2;
			font-size: 2.4rem;
			font-weight: bold;
		}
	}
	.item-image{
		padding-bottom: 35px;
		img{
			max-width: 100%;
			border-radius: 4px;
		}
		@media(max-width: 640px){
			display: none;
		}
	}
	div[itemprop="articleBody"]{
		padding-bottom: 40px;
		h2{
			color: $bleu;
			font-size: 2rem;
			font-weight: bold;
		}
		h3{
			color: $bleu;
			font-size: 1.6rem;
			font-weight: bold;
		}
		color: $gris;
		blockquote{
			padding: 15px;
			border-radius: 4px;
			border: 1px solid $gris;
			font-size: 1.6rem;
		}
		ul:not(.uk-slider-nav){
			list-style-type: none;
			padding: 0;
			li{
				position: relative;
				padding-left: 15px;
				margin: 1rem 0;
				&:before{
					content: '';
					display: block;
					width: 8px;
					height: 8px;
					background-color: $vert;
					border-radius: 10px;
					position: absolute;
					left: 0;
					top: 7px;
				}
			}
		}
		a span {
			color: $bleu2;
			font-size: 2rem;
			font-weight: bold;
		}
		a{
			color:$vert;
		}
		.rl_sliders.accordion>.accordion-group {
			border: none;
			border-radius: inherit;
			border-bottom: 1px solid $bleu2;
		}
		.rl_sliders.accordion>.accordion-group>.accordion-body>.accordion-inner {
			border-top: 1px solid $bleu2;
		}
		a.bouton{
			background:url(../img/bouton.png) no-repeat 96% 50% $bleu;
			color: $blanc;
			border-radius: 4px;
			padding: 10px 50px 10px 20px;
			font-size:1.3rem;
			transition: all ease-in-out 0.2s;
			&:hover{
				background-position: 93% 50%;
				transition: all ease-in-out 0.2s;
			}
		}
		a.formulaire{
			background-color:$rouge;
			color: $blanc;
			text-align: center;
			border-radius: 4px;
			padding: 10px 20px;
			font-size:1.4rem;
			font-weight: bold;
			transition: all ease-in-out 0.2s;
			&:hover{
				background-color:$orange;
				transition: all ease-in-out 0.2s;
			}
		}
		a.bouton-externe{
			background:url(../img/lien-externe.png) no-repeat right 50% $blanc;
			color: $vert;
			padding: 0px 20px 0px 0px;
			font-size:1.6rem;
			&:hover{
				text-decoration: underline;
			}
		}
		a.bouton-telechargement{
			background:url(../img/icon-telecharge.png) no-repeat right center $blanc;
			color: $vert;
			padding: 2px 35px 2px 0;
			font-size:1.6rem;
			&:hover{
				text-decoration: underline;
			}
		}
		.plus_info{
			overflow: hidden;
			border-top: 1px solid $gris5;
			border-bottom: 1px solid $gris5;
			padding: 15px 0;
			h4{
				color: $vert;
				font-size: 1.6rem;
				margin: 0 0 8px;
			}
			.texte{
				font-size: 1.6rem;
				color: $bleu;
				@media (max-width:1200px) {
					padding-left: 15px !important;
					p{
						margin: 0;
					}
				}
			}
      .bouton {
        display: inline-block;
        margin: 10px 0;
      }
		}

		table{
			border-spacing: 0;
			border-collapse: collapse;
			padding: 10px;
			border: 1px solid $bleu;
			td{
				border: 1px solid $bleu;
				padding: 10px;
				vertical-align: top;
				h3{
					margin: 0;
					text-align: left;
				}
			}
			th{
				background-color: $gris5;
				text-align: center;
			}
			&.border-0{
				border: 0;
				td{
					border: 0;
				}
			}
		}
	}
	&.page-actu{
		margin: auto;
		@media (min-width:1200px) {
			width: 80%;
		}
		.date{
			color: $gris;
			font-size: 1.4rem;
			padding-bottom: 15px;
			display: block;
		}
		.thematique{
			padding-bottom: 15px;
			font-size: 1.4rem;
			color: $gris;
		}
		.lien-retour-blog{
			margin-top: 15px;
			display: inline-block;
			border: 1px solid $gris5;
			border-radius: 4px;
			text-transform: uppercase;
			font-weight:bold;
			color: $bleu;
			padding: 10px 15px;
			font-size: 1.4rem;
			span{
				color: $vert;
				border-radius: 20px;
				border: 2px solid $vert;
				font-weight: bold;
				margin-right: 10px;
			}
			&:hover{
				background-color: $gris5;
			}
		}
		.item-image{
			padding-bottom: 35px;
			img{
				max-width: 100%;
				border-radius: 4px;
			}
		}
	}
	&.page-evenement{
		margin: auto;
		@media (min-width:1200px) {
			width: 80%;
		}
		h1{
			color: $rouge;
		}
		.date{
			color: $rouge;
			font-size: 1.6rem;
			padding-bottom: 15px;
			display: block;
			margin: 0;
			font-weight: bold;
			span{
				margin-right: 10px;
			}
		}
		.thematique{
			padding-bottom: 15px;
			font-size: 1.4rem;
			color: $gris;
		}
		.lien-retour-blog{
			margin-top: 15px;
			display: inline-block;
			border: 1px solid $gris5;
			border-radius: 4px;
			text-transform: uppercase;
			font-weight:bold;
			color: $bleu;
			padding: 10px 15px;
			font-size: 1.4rem;
			span{
				color: $vert;
				border-radius: 20px;
				border: 2px solid $vert;
				font-weight: bold;
				margin-right: 10px;
			}
			&:hover{
				background-color: $gris5;
			}
		}
		.item-image{
			padding-bottom: 35px;
			img{
				max-width: 100%;
				border-radius: 4px;
			}
		}
	}
}

.phocadownloadfile-32{
	a{
		width: 400px;
		@media (max-width:980px) {
			width: 99%;
		}
		border: 1px solid $gris7;
		display: inline-block;
		border-radius: 4px;
		transition: all 0.2s ease-in-out;
		&:hover{
			box-shadow: 0 0 5px 0 $gris7;
			transition: all 0.2s ease-in-out;
		}
	}
	.bloc-fichier{
		padding: 10px 10px 8px;
	}
	.nom_fichier{
		font-size:1.4rem !important;
		display: block;
	}
	.icones{
		position: relative;
		left: 15px;
		display: block;
		height: 37px;
		width: 33px;
	}
	.poid_fichier{
		font-size: 1.4rem !important;
		font-weight: normal !important;
		color: $gris6;
		display: block;
		width: 100%;
	}
}

.main_recette {
	border: 1px solid #ebebeb;
	margin: auto;
	.recette1 {
		padding: 0;
		margin: auto;
		.item1 {
			background-color: #304D6D;
			height: 167px;
			margin: 15px;
			padding: 0;
			div {
				border: 4px solid #00a8c6;
				border-radius: 50%;
				width: 50px;
				height: 50px;
				margin: auto;
				margin-top: 55px;
			}
		}
		.item2 {
			padding: 15px;
			padding-left: 0;
			margin-left: 10px;
			ul {
				padding-left: 42px;
				li {
					width: 200px;
					margin: 0 12px 15px 0;
				}
			}
		}
	}
	.recette2 {
		background-color: #304D6D;
		width: 100%;
		margin: auto;
		padding: 0;
		.h2 {
			color: #fff;
			text-align: center;
			margin-top: 15px;
		}
		.rec_item {
			color: #fff;
			h2 {
				color: #fff;
				margin: 0;
			}
			p {
				margin-top: 4px;
			}
		}
	}
}
