/**
* @author : Gaël DIGARD
* @date   : 08/04/2019
* @desc   : Mise en page des quizz dans les modals
*
 */

$height_answer : 60px;


.quizz-modal{
  width:100vw;
  font-family: $global-font-family;

  //
  // Close button
  //
  [class*="uk-modal-close-"]{
    left:0;
    width:200px;
    opacity:0.3;
    span{
      display: inline-block;
      margin-top:20px;
      text-transform: uppercase;
    }
    svg{
      border: 4px solid $gris2;
      float:left;
      border-radius: 100%;
      padding: 7px;
      line{
        stroke-width: 4px;
      }
    }

    &:hover{
      color:white;
    }
  }

  .quizz-header{
  padding: 25px 0;
    background-color:$bleu;
    color:white;
    text-align: center;

    img{
      margin-bottom: 15px;
    }

    h1, h2{
      color: white;
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      margin: 0;
      padding: 0;
    }
    h1{
      font-family: $global-font-family;
      font-weight: $font-weight-bold;
      padding-bottom: 15px;
    }
    h2{
      font-family: $global-font-family;
      font-size: 16px;
      line-height: 19px;
      margin: auto;
      margin-bottom:0;
      width: 50%;
      opacity: 0.6;
    }
    .uk-modal-close-full{
      @media (min-width: 960px) {
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
      }
      @media (max-width: 959px) {
        text-indent: -9999px;
      }
    }
  }

  /**
  * Quizz progress bar
  */
  #progress-bar-container{
    background:$gris5;
    height:10px;
    width:100%;
    #progress-bar{
      height:100%;
      width:0;
      background: $vert;
      border-radius: 0 4px 4px 0;
      transition-duration:0.2s;
      transition-timing-function: ease-out;

    }
  }

  /**
  * Question numbers
  */
  h3 {
    height: 24px;
    color: #758DA7;
    font-size: 20px;
    font-weight: 300;
    line-height: 24px;
    text-align: center;
  }

  // let's be a semantic lover...
  hr{
    height: 6px;
    width: 48px;
    border-radius: 3px;
    background-color: #3CC0CC;
    margin:auto;
  }

  /**
  * Title question
  */
  section:nth-of-type(1){
    min-height: 28px;
    max-width: 675px;
    color: #304D6D;
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    text-align: center;
    margin: 50px auto;
  }

  section:nth-of-type(2) {
    max-width: 675px;
    color: #304D6D;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    margin:50px auto;
  }

  /**
  * Answers block
  *
  */
  section:nth-of-type(3){
    max-width:400px;
    margin:auto;

    div{
      min-height:$height_answer;
      box-sizing:border-box;
      margin-bottom:  25px;
      &:last-of-type{
        margin: 0;
      }
      input{
        display: none;
        & + label{
          &:after{
            content: '';
            display: block;
            width: 18px;
            height: 18px;
            border-radius: 15px;
            border: 2px solid $gris8;
            position: absolute;
            left: 19px;
            top: 17px;
          }
        }
        &:checked + label{
          &:after{
            content: '';
            display: block;
            width: 18px;
            height: 18px;
            border-radius: 15px;
            border: 2px solid $vert;
            position: absolute;
            left: 19px;
            top: 17px;
            background: url('/templates/skazy/dist/img/answer-cocher-quizz.png') center center no-repeat transparent;
          }
        }
        &.good-answer + label:after{
          border-color: $blanc;
          background: $vert-quizz url('/templates/skazy/dist/img/answer-correct-quizz.png') center center no-repeat;
        }

        &.wrong-answer + label:after{
          border-color: $blanc;
          background: transparent url('/templates/skazy/dist/img/answer-wrong-quizz.png') center center no-repeat;
          opacity: 1;
        }
      }

      label{
        position: relative;
        border: 1px solid $gris5;
        border-radius: 4px;
        width:100%;
        box-sizing: border-box;
        min-height:60px;
        color: $gris;
        font-size: 16px;
        font-weight: 300;
        display:inline-block;
        cursor:pointer;
        padding: 16px 10px 16px 70px;
        &:before{
          content: '';
          display: block;
          background: $bleu;
          height: 100%;
          width:$height_answer;
          position:absolute;
          z-index:-1;
          border-radius: 4px 0 0 4px;
          left:0;
          top:0;
        }
        &.good-answer{
          background: $vert-quizz;
          color:white;
          border:0;
          border-left:1px solid $gris5;
          font-weight:bold;
        }

        &.wrong-answer{
          background-color: $rouge;
          color:$blanc;
          border:0;
          border-left:1px solid $gris5;
          &:after{
            opacity: 0.3;
          }
        }
      }
    }

  }

  /**
  * Response display
  *
  */
  section:nth-of-type(4){

    .explication{
      font-size: 16px;
      font-weight: 600;
      display:inline-block;
      margin-bottom: 2%;
    }

    color: $bleu;
    max-width: 600px;
    min-width: 300px;
    padding:15px 0 15px 100px;
    position:relative;
    border-radius: 4px 0 0 4px;
    display: none;
    margin:25px auto 0;

    &.active{
      border: 1px solid $gris5;
      display: block;

    }

    .bg-answer{
      left:0;
      top:0;
      height: 100%;
      width: 90px;
      background: #50E3C2 url("/templates/skazy/dist/img/answer-big-correct-quizz.png") center center no-repeat;
      position: absolute;
      z-index:-1;
    }
  }

  /**
  * Resultats display
  *
  */
  section:nth-of-type(5){
    color: $bleu;
    max-width: 600px;
    min-width: 300px;
    text-align: center;
    padding:15px 0;
    position:relative;
    border-radius: 4px 0 0 4px;
    display: none;
    margin:25px auto 0;

    &.active{
      border: 1px solid $gris5;
      display: block;
    }

    h2{
      font-weight:600;
      font-size:18px;
      color:$bleu;

      .vert{
        color:$vert;
      }
    }

    h3{
      font-size:14px;
      font-weight:300;
      line-height: 19px;
      text-align:left;
      margin:auto;
      max-width:370px;
      height: auto;
      margin-bottom: 15px;
    }

    hr.hr-results{
      width:100%;
      height: 1px;
      border:0;
      margin-top:24px;
      margin-bottom:24px;
      background: $gris5;
    }



    button.plus-infos{
      height: 40px;	width: 263px;	border: 1px solid #758DA7;	border-radius: 4px;
      font-size: 13px;	font-weight: bold;	letter-spacing: 0.46px;	line-height: 15px;	text-align: center;
      text-transform: uppercase;
      background: transparent;
      color:$gris;
    }

    /**
    * Images who change according
    * to the results %
    *
    */
    #image-all-good,
    #image-not-good{
      display: block;
      margin:auto;
      margin-top:-110px;
    }

    #image-all-good{}
    #image-not-good{}

    // play again
    button{
        cursor:pointer;
        height: 40px;
        width: 220px;
        border-radius: 4px;
        background-color: #3CC0CC;
        border:0;
        color: #FFFFFF;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: bold;
        letter-spacing: 0.37px;
        line-height: 15px;
        text-align: center;

    }
  }

  /**
  * Buttons zone
  * Validate, next, show results
  *
  */

  #button-display{
    width: 400px;
    margin:auto;
    text-align:right;

    button{
      border:0;
      background:transparent;
      color:$bleu5;
      font-size: 13px;
      font-weight: bold;
      letter-spacing: 0.46px;
      line-height: 15px;
      text-align: right;
      text-transform: uppercase;
      cursor:pointer;
      background: transparent url("/templates/skazy/dist/img/arrow-quizz.png") center right no-repeat;
      min-width: 150px;
      height: 23px;
      padding-right: 31px;
      margin:25px 0;
    }
    button#previous-question{
      background: transparent url("/templates/skazy/dist/img/left-arrow-quizz.png") center left no-repeat;
      padding-left: 31px;
      padding-right: 0px;
      float:left;
    }
  }

}


/**
* Launcher
* Bloc qui dit "Cliquez ici pour faire le quizz"
 */
.custom-quizz-launcher{
  background-color: $bleu;
  padding:25px;
  color:$blanc;
  border-radius: 4px;
  margin-top:45px;
  display: flex;
  flex-direction: row;
  flex-wrap:wrap;
  justify-content: space-between;
  .intro-text {
    width: 356px;
    @media (max-width: 1024px) {
      width: 100%;
    }
    margin-right:10px;
  }
  .button-modal {
    background-color: $bleu2;
    flex: 1;
    color: $vert;
    font-size: 18px;
    text-align:center;
    padding-top: 27px;
    border-radius: 4px;
    a{
      margin-top: 8px;
      margin-bottom: 28px;
      background: $vert;
      color:$blanc !important;
      width:259px;
    }
  }
 h3{
   font-size: 24px;	font-weight: 500;	line-height: 28px;
   color:$blanc !important;
   margin-top:8px;
   margin-bottom:8px;
 }
  h4{
    font-size: 13px;
    font-weight: normal;
    color:white;
    margin-top:0px;
  }
}
