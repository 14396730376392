.item-page div[itemprop="articleBody"] {
  ul.accordion {
   & > li {
      padding: 0;
      &:before {
        display: none;
      }
      & > a {
        border-bottom: 1px solid rgba($bleu, 0.3);
        font-size: 2rem;
        font-family: $global-font-family;
        font-weight: $font-weight-bold;
        color: $bleu;
        padding: 8px 0;
        position: relative;
        transition: all ease-in-out 0.4s;
        &:before{
          content:'';
          display: block;
          height: 12px;
          width: 2px;
          background-color: $bleu;
          position: absolute;
          right: 5px;
          top:20px;
          transition: all ease-in-out 0.4s;
        }
        &:after{
          content:'';
          display: block;
          height: 2px;
          width: 12px;
          background-color: $bleu;
          position: absolute;
          right: 0;
          top: 25px;
          transition: all ease-in-out 0.4s;
        }
        &.accordeon_actif{
          &:before{
            transform: rotate(45deg);
            transition: all ease-in-out 0.4s;
          }
          &:after{
            transform: rotate(45deg);
            transition: all ease-in-out 0.4s;
          }
        }
      }
    }
  }
}