.category-module_actu{
  a{
    color: $bleu;
    border-radius: 0 0 3px 3px;
  }
  h3{
      color: $bleu;
      font-size: 2.4rem;
      font-weight: bold;
      padding-bottom: 31px;
    .tout-actu{
      color: $blanc;
      background-color: $bleu;
      text-transform: uppercase;
      font-weight: bold;
      border-radius: 3px;
      font-size: 1.3rem;
      padding: 8px 32px;
      display: inline-block;
      float: right;
    }
  }
  h4{
    margin-top: 15px;
    font-size: 2rem;
    font-weight: bold;
    color: $bleu;
    height: 55px;
  }
  .mod-articles-category-introtext{
    color: $bleu;
  }
  .intro-image{
    border-radius: 3px 3px 0 0;
  }
}

.view-category{
  .category-module_actu{
    margin-bottom: 25px;
    a{
      border: 1px solid rgba($gris,0.2);
      border-radius: 6px;
      .bloc-texte{
        padding: 15px 25px 15px 0;
      }
    }
  }
}
@media(max-width: 959px){
  .category-module_actu{
    .tout-actu-mobile{
      color: $bleu;
      text-transform: uppercase;
      text-align: center;
      font-weight: bold;
      border-radius: 3px;
      font-size: 1.3rem;
      padding: 8px 32px;
      margin: 20px 15px 10px;
      display: block;
      border: 1px solid $bleu;
    }
  }
  .view-article{
    .category-module_actu{
      .tout-actu-mobile{
        margin: 20px 0 10px;
      }
    }
  }
}
@media(max-width: 640px){
  .category-module_actu{
    h3{
      padding:0 15px 20px;
    }
    .intro-image{
      padding-top: 15px;
      width: 100%;
      img{
        border-radius: 4px;
        width: 100%;
        margin: 0 auto;
      }
    }
    .tout-actu-mobile{
      color: $bleu;
      text-transform: uppercase;
      text-align: center;
      font-weight: bold;
      border-radius: 3px;
      font-size: 1.3rem;
      padding: 8px 32px;
      margin: 20px 15px 10px;
      display: block;
      border: 1px solid $bleu;
    }
  }
}

@media(min-width: 640px)and(max-width:1200px){
  .category-module_actu {
    h3 {
      margin: 0 15px !important;
    }
  }
}
