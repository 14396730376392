// élément qui prend toute la longueur du site, rajouter un .container dans l'élément qui a cette classe
.bg_violet{
	background-color: $violet;
}

.mea-ou-nous-trouver, .mea-dernier-outils{
  .uk-active{
	a{
	  background-color: $primary02 !important;
	  color: $white !important;
	}
  }
}