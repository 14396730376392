.b-acces{
  .generique-acces{
    padding-left: 35px;
    color: $bleu;
    font-size:1.4rem;
  }
  .adresse{
    background: url(../img/adresse.png) no-repeat left 3px;
  }
  .horaires{
    background: url(../img/horaires.png) no-repeat left 2px;
  }
  .mail{
    background: url(../img/mail.png) no-repeat left 1px;
  }
  .telephone{
    background: url(../img/telephone.png) no-repeat left top;
  }
  h2{
    margin: 35px 0;
  }
  &__colonnegauche{
    @media(min-width: 960px) {
      padding-right: 70px;
    }
  }
  &__question{
    background-color: $bleu;
    color: $blanc;
    border-radius: 4px;
    padding: 20px 25px;
    h2{
      color: $blanc !important;
      margin: 0;
      background: url(../img/picto_question.jpg) no-repeat left top;
      padding-left: 35px;
      font-weight: normal !important;
    }
    p{
      font-size: 1.4rem;
    }
    a{
      border: 1px solid $blanc;
      border-radius: 4px;
      text-transform: uppercase;
      text-align: center;
      color: $blanc !important;
      display: block;
      width: 100%;
      padding: 8px 0;
      font-size: 1.3rem;
    }
  }
  &__images{
    padding-top: 40px;
    @media(max-width: 640px){
      display: none;
    }
  }
}