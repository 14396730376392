.espace-pro{
	a {
		border: 1px solid $gris5;
		border-radius: 3px;
		font-size: 2rem;
		color: $bleu;
		font-weight: 500;
		transition: 0.2s all ease-in-out;
		img{
			margin-right: 12px;
		}
		&:hover{
			color: $vert;
			transition: 0.2s all ease-in-out;
		}
	}
}