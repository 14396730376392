.formulaire-accompagnement {
  .rsform {
    h3 {
      font-size: 2.4rem;
      color: $primary02;
      font-weight: 500;
      margin-bottom: 12px;
    }

    h4 {
      font-size: 18px;
      line-height: 28px;
      margin-top: 12px;
      font-weight: $font-weight-medium;
    }

    p {
      color: $gris15;
      margin-bottom: 12px;
    }

    ol {
      font-size: 16px;
      color: $gris16;
      margin-bottom: 44px;

      li {
        margin-bottom: 6px;

        &::marker {
          color: $gris16;
        }
      }
    }

    input::placeholder {
      color: $gris18;
    }

    .rsform-type-pagebreak {
      .formControlLabel {
        display: none;
      }

      .formControls {
        margin-left: 0;

        .formBody {
          button[type="button"].rsform-button-next, button[type="button"].rsform-button-prev {
            background-color: $secondary04;
            font-family: $font-family-roboto-normal;
            font-weight: $font-weight-semi-bold;
            border-radius: 8px;
            border: none;
            padding: 0px 24px;
            line-height: 38px;
            font-size: 16px;
            width: 49%;
            @include media-breakpoint-down(md) {
              width: 46% !important;
              padding: 0px 15px;
            }
          }

          button[type="button"].rsform-button-prev {
            background-color: transparent;
            border: 1px solid $primary;
            margin-right: 16px;
            color: $primary;
          }

          #commencerNext {
            width: 95%;
          }
        }
      }
    }

    #rsform_3_page_0 {
      .formRow .formSpan12 {
        h3 {
          margin-top: 10px;
          @include media-breakpoint-up(md) {
            margin-top: 76px;
          }
        }

        .rsform-block-commencer {
          button {
            width: 100%;
            margin-bottom: 50px;
            @include media-breakpoint-up(md) {
              margin-bottom: 233px;
            }
          }
        }
      }
    }

    #rsform_3_page_1, #rsform_3_page_2, #rsform_3_page_3 {
      .rsform-block-fonction.rsform-type-textbox {
        margin-bottom: 32px;
      }

      .rsform-type-freetext {
        margin-bottom: 0;

        p {
          margin: 0;
        }

        p > img {
          margin-bottom: 40px;
          width: 100%;
        }

        p > span {
          text-transform: uppercase;
          letter-spacing: 2.8px;
          line-height: 27px;
          font-size: 14px;
          color: $gris17;
        }

        h3 {
          margin-top: 0;
          margin-bottom: 24px;
        }
      }

      .rsform-type-textbox, .rsform-type-checkboxgroup, .rsform-type-textarea {
        margin-bottom: 12px;

        .formControlLabel {
          width: 95%;
          color: $gris16;
          font-weight: $font-weight-medium;
          text-align: left;
          line-height: 28px;
          margin-bottom: 4px;
        }

        .formControls {
          width: 100%;
          margin-left: 0;

          input, textarea {
            border: 1px solid $gris18;
            border-radius: 4px;
            margin-bottom: 0;
            padding: 8px 12px;
            font-size: 16px;
            width: 100%;
          }

          .formValidation, .formDescription {
            display: none;
          }
        }
      }

      .formSpan12 {
        .rsform-type-textbox, .rsform-type-textarea {
          .formControlLabel {
            width: 95%;
          }

          .formControls {
            width: 100%;

            input {
              width: 90% !important;
              @include media-breakpoint-up(md) {
                width: 97% !important;
              }
            }
          }
        }
      }

      .formSpan6 {
        .rsform-type-textbox .formControls .formBody input {
          width: 95%;
          border-radius: 4px;
          @include media-breakpoint-down(md) {
            width: 90% !important;
          }
        }
      }
    }

    #rsform_3_page_1, #rsform_3_page_2 {
      .rsform-type-textbox, .rsform-type-checkboxgroup, .rsform-type-textarea {
        display: flex;
        flex-direction: column;
      }
    }

    #rsform_3_page_2 {

      .rsform-block-thematique > label {
        margin-bottom: 8px;
      }

      .rsform-type-textarea {
        .formControls {
          textarea {
            width: 97%;
          }
        }
      }

      .rsform-type-checkboxgroup {
        .formBody .rsformgrid6 .rsformVerticalClear, .formBody .rsformVerticalClear {
          margin-bottom: 0;

          label {
            font-weight: $font-weight-normal;
            display: flex !important;
            align-items: start;
            margin: 0px 12px 13px 0;
            line-height: 2.8rem;
            font-size: 1.7rem;
            color: $gris16;

            input {
              width: 20px;
              height: 20px;
              max-width: 20px;
              min-width: 20px;
              margin-right: 12px;
              border: 1px solid $gris18;
            }
          }
        }
      }

      .rsform-block-page-2.rsform-type-pagebreak {
        margin-top: 32px;

        .formControls .formBody {
          display: flex;
        }
      }

      .rsform-block-autre.rsform-type-textarea {
        margin-bottom: 3.2rem;

        textarea {
          border: 1px solid $gris18;
          border-radius: 4px;
          margin-bottom: 0;
          padding: 8px 12px;
          font-size: 16px;

          &::placeholder {
            color: $gris18;
          }
        }
      }

      .rsform-block-autre-description.rsform-type-checkboxgroup {
        #autre_description-grouplbl {
          display: none;
        }
      }
    }

    #rsform_3_page_3 {
      #rgpd0-lbl {
        display: flex;
      }

      .rsform-block-preciser {
        margin-bottom: 24px;

        p {
          color: $primary;
          margin-bottom: 0;
        }

        h3 {
          margin-bottom: 8px;
        }
      }

      .rsform-block-periode {
        display: flex;
        flex-direction: column;

        label {
          color: $primary02;
          margin-bottom: 12px;
        }

        .formControls .formBody .rsformVerticalClear {
          margin-bottom: 6px;

          label {
            display: flex;
            color: $gris16;
            margin: 0;

            input {
              border: 1px solid $gris18;
              border-radius: 4px;
              margin-bottom: 0;
              margin-right: 12px;
              line-height: 28px;
              padding: 8px 12px;
              font-size: 16px;
              width: 20px;
            }
          }
        }
      }

      .rsform-block-remarques {
        .formControls .formBody {
          input {
            border: 1px solid $gris18;
            border-radius: 4px;
            margin-bottom: 0;
            padding: 8px 12px;
            font-size: 16px;
            line-height: 28px !important;
          }
        }
      }

      .rsform-block-desc {
        margin-bottom: 32px;

        p {
          color: $gris16;
        }
      }

      .rsform-block-rgpd.rsform-type-checkboxgroup {
        margin-bottom: 16px;

        > label {
          display: none;
        }

        label {
          line-height: 22px;
          font-size: 14px;
          color: $primary;
          margin-bottom: 0;
          margin-top: 0;

          input {
            width: 35px;
            height: 20px;
            border-radius: 4px;
            margin-bottom: 0;
            margin-right: 12px;
            line-height: 28px;
            padding: 8px 12px;
            font-size: 16px;
          }
        }
      }

      .rsform-block-rgpd2 {
        p {
          color: $primary;
          font-size: 14px;
          line-height: 22px;
          margin-bottom: 32px;

          a {
            color: $primary;
            text-decoration-line: underline;
          }
        }
      }

      .rsform-block-send {
        .formControls {
          margin-left: 0;

          .formBody {
            display: flex;
            width: 100%;

            button {
              width: 50%;
            }

            button[type="submit"].rsform-submit-button, button[type="button"].rsform-button-prev {
              background-color: $secondary04;
              text-shadow: none;
              font-family: $font-family-roboto-normal;
              font-weight: $font-weight-semi-bold;
              border-radius: 8px;
              border: none;
              padding: 0px 24px;
              line-height: 38px;
              font-size: 16px;
              width: 49%;
              margin-right: 15px;

              @include media-breakpoint-down(md) {
                width: 46% !important;
                padding: 0px 15px;
              }
            }

            button[type="button"].rsform-button-prev {
              background-color: transparent;
              border: 1px solid $primary;
              margin-right: 16px;
              color: $primary;
            }
          }
        }
      }
    }

    .thankyou {
      p > img {
        width: 100%;
        margin-bottom: 209px;

        @include media-breakpoint-down(md) {
          margin-bottom: 70px;
        }
      }

      h2 {
        font-size: 4.8rem;
        color: $primary02;
        margin-bottom: 16px;
        font-weight: $font-weight-bold;
      }

      p {
        margin-top: 0;
        font-size: 2rem;
        line-height: 31px;
        color: $primary02;
        margin-bottom: 40px;

        a {
          color: $bleu2;
          text-decoration: underline;
        }
      }

      a.btn {
        margin-bottom: 367px;
        color: $white;
        display: inline-flex;
        padding: 0px 70px;
        font-size: 16px;
        font-weight: 600;
        line-height: 38px;
        text-decoration: none;
        background-color: $secondary04;

        @include media-breakpoint-down(md) {
          margin-bottom: 100px;
        }
      }
    }

    .formResponsive input[type="text"], .formResponsive textarea {
      @include media-breakpoint-down(md) {
        width: 90% !important;
      }
    }
  }
}