// mef des notifications

/*
 * Primary
 */

.uk-notification-message-primary {
	background: $notification-message-primary-color;
	color: $notification-message-background;
	@if(mixin-exists(hook-notification-message-primary)) {@include hook-notification-message-primary();}
}

/*
 * Success
 */

.uk-notification-message-success {
	background: $notification-message-success-color;
	color: $notification-message-background;
	@if(mixin-exists(hook-notification-message-success)) {@include hook-notification-message-success();}
}

/*
 * Warning
 */

.uk-notification-message-warning {
	background: $notification-message-warning-color;
	color: $notification-message-background;
	@if(mixin-exists(hook-notification-message-warning)) {@include hook-notification-message-warning();}
}

/*
 * Danger
 */

.uk-notification-message-danger {
	background: $notification-message-danger-color;
	color: $notification-message-background;
	@if(mixin-exists(hook-notification-message-danger)) {@include hook-notification-message-danger();}
}

.uk-close {
	color: $notification-message-background;
}