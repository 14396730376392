.menu-outils{
	background-color: $vert;
	border-radius: 3px 0 0 3px;
	padding: 26px;
	button{
		border-radius: 15px;
		margin-bottom: 5px;
		background-color: $bleu;
		color: $vert;
		padding: 5px 5px 5px 6px;
		&:hover{
			background-color: $blanc;
			color: $bleu;
		}
	}
	.text-outil{
		display: block;
		color: $blanc;
		float: right;
		width: 154px;
	}
	ul{
		padding-top: 10px;
		li{
			padding: 25px 0 0;
			a{
				color: $blanc;
				span{
					margin-right: 25px;
				}
			}
		}
	}
}