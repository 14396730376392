.moduletable_thematique{
	.uk-nav{
		bottom: 15px;
		padding: 33px 32px 25px 32px;
		.nav-header{
			color: $vert;
			font-size: 2.4rem;
			font-weight: bold;
			padding-bottom: 10px;
			display: block;
		}
		li{
			margin: 0 0 12px;
			&:last-child{
				margin: 0;
			}
		}
	}
	img{
		margin-right: 15px;
	}
	a{
	  padding: 0 !important;
		color: $gris;
		font-size: 1.6rem;
		span{
			transition: $transition;
		}
		&:hover{
			color: $vert;
			span{
				padding-left: 10px;
				transition: $transition;
			}
		}
	}
}

.thematique-responsive{
	background-color: $violet;
	padding: 0 15px;
	margin-top: 55px;
	.uk-accordion{
		position: relative;
		bottom: 30px;
	}
	.uk-open{
		.uk-accordion-title{
			background-color: $blanc;
			color: $vert !important;
			.bouton-haut{
				display: block;
			}
			.bouton-bas{
				display: none;
			}
		}
	}
	.uk-accordion-title{
		background-color: $vert;
		padding: 15px 30px 15px 35px;
		color: $blanc !important;
		font-family: $global-font-family;
		font-weight: $font-weight-bold;
		font-size: 2rem;
		.bouton-haut{
			display: none;
		}
		.bouton-bas{
			display: block;
		}
	}
	.uk-accordion-content{
		margin-top: 10px;
	}
	.menu{
		margin: 0 !important;
		border-radius: 0 0 8px 8px;
		padding-top: 10px;
		li:first-child{
			display: none;
		}
	}
}