
.moduletable_pied_page {
	padding: 50px 0;
	ul {
		list-style-type: none;
		padding: 0;
		width: 100%;
		li {
			a {
				color: $blanc;
				font-size: 1.4rem;
				padding: 6px 0px 6px 0;
				&:hover{
					color: $vert;
				}
			}
			&.banneritem{
				a {
					padding: 0 10px;
				}
			}
			&.deeper{
				.nav-header{
					font-size: 1.6rem;
					padding: 6px 0 15px;
					display: block;
					color: $blanc;
				}
			}
			&.deeper:nth-child(4){
				a{
					font-weight: bold;
					padding-bottom: 10px;
					display: block;
					font-size: 1.6rem;
				}
			}
		}

	}
	&>ul{
		&>li{
			padding: 0 15px;
			&:first-child{
				padding-left: 0;
			}
			&:last-child{
				padding-right: 0;
			}
		}
	}
	.mea_pro{
		background-color: $blanc;
		border-radius: 3px;
		display: block;
		width: 100%;
		padding: 15px;
		.titre{
			color: $bleu;
			font-family: $global-font-family;
			font-weight: $font-weight-medium;
			font-size: 1.6rem;
			margin-bottom: 5px;
		}
		.sous-titre{
			color: $gris;
			margin-top: 5px;
		}
		span{
			background-color: $vert2;
			color: $blanc;
			border-radius: 3px;
			display: block;
			text-transform: uppercase;
			padding: 8px;
			text-align: center;
		}
		&:hover{
			span{
				color: $bleu2;
			}
		}
	}
}

@media(max-width: 1024px) {
	.menu-pre-footer {
		.moduletable_pied_page {
			position: relative !important;
		  	padding-bottom: 17px;
		  .nav-header{
			padding: 33px 0 20px !important;
			display: block;
			font-size: 16px;
			font-weight: bold;
		  }
		  ul {
				display: block;
				li {
					ul{
						li{
							padding-bottom: 10px;
						}
					}
					a {
						padding: 0;
					}
				  &.item-147{
					padding-top: 30px;
				  }
				  &.item-147 + li a, &.item-147 + li + li a, &.item-147 + li + li + li a{
					font-size: 16px;
					font-weight: bold;
					margin-bottom: 16px;
					display: block;
				  }
				}
			}
		}
	}
}