.p-barometre-sante-adulte {

  .b-bandeau {
    h1 {
      font-family: $font-myriad;
      text-transform: uppercase;
      color: $white;
      font-size: 108px;
      text-align: center;
      margin-bottom: 0;
      font-weight: $font-weight-bold;

      @include media-breakpoint-down(md) {
        font-size: 50px;
      }
    }
  }

  h2 {
    font-size: 72px;
    font-weight: 900;
    line-height: 72px;
    color: $primary02;
    margin-bottom: 32px;
    font-family: $font-family-gantari;

    @include media-breakpoint-down(md) {
      font-size: 30px;
      line-height: 30px;
    }
  }

  h3 {
    font-size: 40px;
    color: $primary02;
    margin-bottom: 32px;
    line-height: 50px;
    font-weight: $font-weight-bold;
    font-family: $font-family-gantari;

    @include media-breakpoint-down(md) {
      font-size: 35px;
    }
  }

  p {
    color: $gris14;
    font-family: $global-font-family;
    font-size: 20px;
    line-height: 31px;
  }

  .youtube_player {
    width: 100%;
    max-width: 100%;

    iframe {
      height: 536px;
      @include media-breakpoint-down(lg) {
        height: 242px;
      }
    }
  }

  .b-intro {
    background: $gris13;
  }

  .b-carte {
    p {
      margin: 0;
    }

    img {
      width: 100%;
      height: 100%;

    }
  }

  .b-enquete {
    ul {
      display: flex;
      flex-direction: row;
      padding: 0;
      margin-bottom: 0;
      gap: 16px;
      justify-content: space-between;
      @include media-breakpoint-down(lg) {
        flex-direction: column;
      }

      li {
        flex: 1 1 0;
        list-style: none;
        padding: 16px;
        border-radius: 16px;
        border: 1px solid #F1F2FA;
        font-size: 16px;
        color: $gris14;

        img {
          background-color: rgba(47, 63, 141, 0.10);
          border-radius: 6px;
          width: 35px;
          padding: 8px;
        }

        h4 {
          color: $primary;
          font-size: 24px;
          font-style: normal;
          font-weight: $font-weight-bold;
          margin-bottom: 12px;
          margin-top: 16px;
          line-height: 27px;
        }

      }
    }
  }

  .b-thematique {

    h3 {
      font-weight: $font-weight-black;
      line-height: normal;
    }

    h4 {
      @include media-breakpoint-down(md) {
        font-size: 28px;
      }
    }

    .lien-thematique {
      color: $primary02;
      padding: 8px 12px;
      margin-right: 8px;
      border-radius: 8px;
      margin-bottom: 15px;
      line-height: normal;
      display: inline-block;
      letter-spacing: 0.24px;
      border: 3px solid $primary02;
    }

    .b-thematique-detail {
      margin-bottom: 128px;
      @include media-breakpoint-down(lg) {
        margin-bottom: 70px;
      }

      @include media-breakpoint-down(md) {
        margin-bottom: 50px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    p {
      color: $gris14;
      line-height: 31px;
      font-family: $global-font-family;
      @include media-breakpoint-down(lg) {
        font-size: 16px;
      }
    }

    img {
      width: 100%;
      border-radius: 8px;
    }

    .pdf {
      p {
        margin-bottom: 0;
      }

      a {
        color: $white;
        display: block;
        text-align: center;
        padding: 12px 28px;
        border-radius: 8px;
        background-color: $primary;

        @include media-breakpoint-down(lg) {
          font-size: 16px;
          padding: 12px;
        }

        img {
          width: 24px;
          height: 24px;
          @include media-breakpoint-down(lg) {
            width: 15px;
            height: 15px;
          }
        }
      }
    }

    .youtube_player {
      iframe {
        height: 536px;
        @include media-breakpoint-down(lg) {
          height: 225px;
        }
      }
    }
  }

  .b-statistiques {

    h2 {
      margin-bottom: 32px;
    }

    iframe {
      width: 100%;
      height: 660px;
      border-radius: 12px;
    }
  }

  .b-en-savoir-plus {

    h2, p, a {
      color: $blanc;
    }

    ul {
      margin-top: 32px;
      padding-left: 0;

      li {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 12px;

        a {
          padding-left: 25px;
          text-decoration-line: underline;
        }

        a[href*=".pdf"] {
          background: url(../img/st-file.png) no-repeat 3px 1px;
          background-size: 20px;
          padding-right: 45px;
        }

        a[href*="http"] {
          background: url(../img/st-lien.png) no-repeat 3px 1px;
          background-size: 20px;
          padding-right: 45px;
        }
      }
    }

    .pdf {

      p {
        margin-bottom: 0;
      }

      a {
        color: $white;
        display: block;
        text-align: center;
        padding: 12px 28px;
        border-radius: 8px;
        background-color: $primary;

        @include media-breakpoint-down(lg) {
          font-size: 16px;
          padding: 12px;
        }
      }

      img {
        width: 100%;
      }
    }
  }
}

.menu-barometre {
  ul {
    li {
      a {
        color: $primary02;
        font-size: 14px;
      }
    }
  }

  #offcanvas-overlay-barometre {
    nav {
      ul {
        li {
          margin-top: 32px;
          font-size: 14px;
          font-weight: 700;
          color: $bleu2;

          a {
            margin-bottom: 0;
            padding: 0;
          }
        }

        a {
          color: $bleu2;
          padding: 8px 12px;
          margin-bottom: 8px;
          display: inline-block;
        }
      }
    }
  }

  .uk-offcanvas-close {
    color: #3CC0CC;
    position: relative;
    left: 0;
    top: 0;
    float: right;
    padding: 0;
  }
}

.lp-barometre-sante-adulte {
  .menu-toggle {
    display: none;
  }
}