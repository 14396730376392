.bannergroup_sante-en-chiffre{
	padding: 30px 0;
	h3{
		color: $bleu;
		font-size: 2.4rem;
		font-weight: bold;
		margin: 0;
	}
	.tous-chiffres{
		color: $blanc;
		background-color: $bleu;
		text-transform: uppercase;
		font-weight: bold;
		border-radius: 3px;
		font-size: 1.3rem;
		padding: 8px 32px;
		display: inline-block;
		@media(max-width: 959px){
			color: $bleu;
			background-color: $blanc;
			text-transform: uppercase;
			text-align: center;
			font-weight: bold;
			border-radius: 3px;
			font-size: 1.3rem;
			padding: 8px 32px;
			margin: 20px 0 0 30px;
			display: block;
			border: 1px solid $bleu;
		}
	}
}
