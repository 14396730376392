.moduletable_menu_detail {
	ul{
		li{
			margin-top: 8px !important;
			a{
				padding-right: 20px;
			}
		}
	}
  .uk-list ul{
	padding-left: 30px;
  }
}

.moduletable_menu_detail, .menu-detail-responsive{
	border:1px solid rgba($gris3,0.2) ;
	bottom:60px;
	padding: 33px 32px 50px 32px;
	overflow: hidden;
	h2{
		color: $bleu2;
		font-size: 2.4rem;
		font-weight: bold;
		margin: 5px 0 10px;
	}
	ul{
		li{
			a{
				color: $gris;
				font-size: 1.6rem;
				display: block;
				position: relative;
				line-height: 16px;
				margin: 5px 0;
				svg{
					position: absolute;
					right: 0;
					top: 0;
				}
				&.active{
					font-weight: bold;
					&.level-3{
						color: $vert;
					}
				}
				&.deeper{
					color: $vert;
					font-weight: bold;
				}
				&:hover{
					color: $vert;
				}
				&.btn-pro{
					background-color: $vert2;
					color: $blanc;
					text-transform: uppercase;
					font-size: 1.3rem;
					text-align: center;
					padding: 15px;
					border-radius: 3px;
					margin-top: 25px;
					&:hover{
						background-color: $vert;
					}
				}
			}
			ul{
				li{
					a{
						margin: 15px 0;
					}
				}
			}
		}
	}
	.button-thematique{
		margin: 0;
		border: 0;
		cursor: pointer;
		background-color: $bleu2;
		color: $blanc;
		border-radius: 3px;
		padding: 10px 0;
		span{
			padding-left: 5px;
		}
		&:hover{
			background-color: $bleu;
		}
	}
	#thematique-detail{
		padding: 20px;
		h3{
			color: $vert;
			font-size: 2rem;
			font-weight: bold;
			display: block;
			padding-bottom: 8px;
		}
		ul{
			li{
				margin: 0 0 5px !important;
				img{
					width: 21px;
					margin-right: 15px;
				}
				a{
					margin: 0;
					font-size: 1.8rem;
				}
				&.active{
					a{
						color: $vert;
					}
				}
			}
		}
	}
}

.supports-pro{
	.moduletable_menu_detail{
		ul{
			li{
				a{
					&.active{
						&.level-3{
							color: $vert2;
						}
					}
					&.deeper{
						color: $vert2;
					}
					&:hover{
						color: $vert2;
					}
				}
			}
		}
	}
}

.l-agence{
	.moduletable_menu_detail{
		ul{
			li{
				a{
					&.active{
						&.level-2{
							color: $violet2;
						}
					}
					&.deeper{
						color: $violet2;
					}
					&:hover{
						color: $violet2;
					}
				}
			}
		}
	}
}