.b-tile {
  &__bloc {
    color: $blanc;
    font-family: $global-font-family;
    .uk-tile {
      border-radius: .4rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &__title,
  h3 {
    color: $blanc;
    margin-bottom: 1.5rem;
    font-size: 1.8rem;
    font-family: $global-font-family;
    font-weight: $font-weight-medium;
  }
  &__text {
    color: $blanc;
    font-family: $global-font-family;
  }
}

.b-tile-outlined {
  .b-tile {
    &__bloc,
    &__title {
      color: $gris;
      font-family: $global-font-family;
      font-weight: $font-weight-medium;
      .uk-tile {
        border-radius: .4rem;
        border: 1px solid $gris5;
        background: none;
      }
    }
    &__title {
      margin-bottom: 1.5rem;
      font-size: 1.6rem;
    }
    &__text {
      color: $gris;
    }
  }
}
