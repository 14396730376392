.u-list-repeatable {
  &__item {
    border: 1px solid $gris5;
    border-radius: 4px;
    padding: 1rem 1.5rem;
    gap: 1rem 4rem;
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
  &__img {
    width: 45px;
    height: 45px;
    object-fit: cover;
    margin-right: 1.5rem;
  }
  &__libelle {
    font-family: $global-font-family;
    font-weight: $font-weight-medium;
    color: $bleu;
  }
  &__link,
  &__mail,
  &__tel {
    color: $gris !important;
    position:relative;
    padding-left: 3rem;
    &::before {
      position: absolute;
      left: 0;
    }
  }
  &__link::before {
    content: url(../img/ico-link.svg);
  }
  &__mail::before {
    content: url(../img/ico-mail.svg);
  }
  &__tel::before {
    content: url(../img/ico-tel.svg);
  }
}

.u-list-repeatable2 {
  &__item {
    border: 1px solid $gris5;
    border-radius: 4px;
    padding: 1.5rem 2rem;
  }
  &__img {
    max-width: 32px !important;
    width: 32px !important;
    height: 32px;
    object-fit: scale-down;
    margin-right: 2rem;
  }
  &__libelle {
    font-family: $global-font-family;
    color: $bleu2;
    font-size: 1.8rem !important;
  }
}
