.u-card {
  margin-top: 2.4rem !important;
  &__bloc {
    border: 1px solid $gris5;
    border-radius: 4px;
    &:not(:first-child) {
      border-top: none;
      border-right: 2rem solid $vert4;
    }
    &-content {
      padding: 2.4rem 1.5rem;
      &.bloc-content-1 {
        @media (max-width: $breakpoint-medium) {
          background: unset !important;
        }
      }
      &:last-child {
        padding: 2.4rem;
      }
      h3 {
        margin-bottom: 1.5rem;
        &:not(:first-child) {
          margin-top: 2.4rem;
        }
      }
      p {
        margin-top: 1.5rem;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }
}
.u-card-simple {
  &__bloc {
    margin-top: 2.4rem;
    border: 1px solid $gris5;
    border-radius: 4px;
    padding: 1.5rem 2.4rem;
    h3 {
      margin: 0 0 1.5rem;
    }
    &-image {
      margin-top: 8rem;
    }
  }
  &__image {
    width: 170px;
    height: 130px;
    margin: -8rem 0 1.5rem;
  }
}

.alias-notre-fonctionnement-organisation {
  .u-card__bloc {
    &-content {
      display: flex;
      align-items: center;
      p:first-of-type {
        margin-top: 0;
      }
    }
  }
}
