.titre-actu{
  color: $bleu;
  font-size: 2.4rem;
  font-weight: bold;
  padding-bottom: 31px;
}
@media (max-width: 640px){
  .page-detail{
    .bandeau{
      display: none;
    }
  }
  .header{
    box-shadow: none;
  }
}