@import url('https://fonts.googleapis.com/css2?family=Gantari:wght@400;500;600;700;900&family=Roboto:wght@400;500;700&display=swap');

// stylelint-disable value-keyword-case

$font-myriad: 'myriad_probold', sans-serif;

$global-font-family: 'Roboto', sans-serif;
$font-family-roboto-normal:      $global-font-family;
$font-family-roboto-medium:      $global-font-family;
$font-family-roboto-bold:      $global-font-family;
$font-family-gantari: 'Gantari', sans-serif;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;


$blanc : $white;

$transition: .4s ease-in-out;

// override uikit

$search-default-width: auto;

$navbar-nav-item-height: auto;
$dropdown-background: $blanc;
$base-link-hover-text-decoration: none;
$button-link-hover-text-decoration: none;

$breadcrumb-divider: "";
$breadcrumb-divider-margin-horizontal: 0px;
$breadcrumb-item-active-color: $blanc;
$breadcrumb-item-hover-color: $vert;

$offcanvas-bar-width: 100%;

$medium: 959px;

// bootstrap variables

// appel dans le scss : map-get($font-sizes,16)

$font-sizes: (
		12 : 1.2rem,
		13 : 1.3rem,
		14 : 1.4rem,
		15 : 1.5rem,
		16 : 1.6rem,
		18 : 1.8rem,
		24 : 2.4rem,
		28 : 2.8rem,
		30 : 3rem,
		32 : 3.2rem,
		40 : 4rem,
		50 : 5rem,
		60 : 6rem
);

/* ======= variables de bootsrap à réutiliser
https://getbootstrap.com/docs/5.1/utilities/text/#font-weight-and-italics
*/

$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-bold:            700;
$font-weight-black:            900;
$font-weight-medium : 500;
$font-weight-semi-bold : 600;

$font-weight: (
		light: $font-weight-light,
		normal: $font-weight-normal,
		bold: $font-weight-bold,
		black: $font-weight-black,
		medium: $font-weight-medium,
		semi-bold: $font-weight-semi-bold,
);

/* ======= border-radius
https://getbootstrap.com/docs/5.1/utilities/borders/#variables
*/

$border-radius: 0.8rem;
$border-radius-sm: .6rem;
$border-radius-lg: 5px 20px 5px 20px;
$border-radius-pill: 50rem;

$radius: ();

$radius: (
		null: $border-radius,
		0: 0,
		1: $border-radius-sm,
		2: $border-radius,
		3: $border-radius-lg,
		4: 0.4rem,
		5: 0.5rem,
		8: 0.8rem,
		9: 0.9rem,
		15: 1.5rem,
		16: 1.6rem,
		64: 6.4rem,
		circle:50%,
		pill: $border-radius-pill,
		intro: 50% 50% 0 0,
);

/* ======= spacers
https://getbootstrap.com/docs/5.1/utilities/spacing/#sass
*/

$spacer: 1rem;
$spacers: (
		0: 0,
		1: 0.1rem,
		2: 0.2rem,
		3: 0.3rem,
		4: 0.4rem,
		5: 0.5rem,
		6: 0.6rem,
		8: 0.8rem,
		10: 1rem,
		12: 1.2rem,
		13: 1.3rem,
		15: 1.5rem,
		16: 1.6rem,
		20: 2rem,
		24: 2.4rem,
		25: 2.5rem,
		29: 2.9rem,
		30: 3rem,
		32: 3.2rem,
		35: 3.5rem,
		37: 3.7rem,
		40: 4rem,
		42: 4.2rem,
		44: 4.4rem,
		48: 4.8rem,
		50: 5rem,
		52: 5.2rem,
		55: 5.5rem,
		60: 6rem,
		62: 6.2rem,
		64: 6.4rem,
		65: 6.5rem,
		70: 7rem,
		72: 7.2rem,
		80: 8rem,
		85: 8.5rem,
		95: 9.5rem,
		96: 9.6rem,
		100: 10rem,
		104: 10.4rem,
		108: 10.8rem,
		110: 11rem,
		116: 11.6rem,
		120: 12rem,
		128: 12.8rem,
		140: 14rem,
		148: 14.8rem,
		160: 16rem,
		170: 17rem,
		200: 20rem,
		240: 24rem,
		245: 24.5rem,
		250: 25rem,
		300: 30rem,
		400: 40rem,
		n25: -25%,
);

/* ======= les gutters
https://getbootstrap.com/docs/5.1/layout/gutters/#change-the-gutters
*/

$grid-gutter-width: 3rem;
$gutters: (
		0: 0,
		1: $spacer * .25,
		2: $spacer * .5,
		3: $spacer,
		4: $spacer * 1.5,
		5: $spacer * 3,
		10: 1rem,
		12: 1.2rem,
		16: 1.6rem,
		24: 2.4rem,
);

/* ======= les buttons
https://getbootstrap.com/docs/5.2/components/buttons/#sass-variables
*/

$btn-padding-y:               8px;
$btn-padding-x:               32px;
$btn-font-size:               map-get($font-sizes,13);

// Allows for customizing button radius independently from global border radius
$btn-border-radius:           3px;

/* ======= les selects
https://getbootstrap.com/docs/5.1/forms/select/#variables
*/
$form-select-padding-y:             2.1rem;
$form-select-padding-x:             1.6rem;
$form-select-font-size:             1.6rem;
$form-select-border-radius:       8px;
$form-select-color:                 $primary;
$form-select-indicator-color:       #758DA7;

/* ======= les positions
https://getbootstrap.com/docs/5.2/utilities/position/#sass
*/
$position-values: (
		0: 0,
		5: 5%,
		25: 25%,
		50: 50%,
		90: 90%,
		100: 100%,
		10px: 10px,
		14px: 14px,
		24px: 24px,
		50px: 50px,
		70px: 70px,
		100px: 100px,
		120px: 120px,
		150px: 150px,
		170px: 170px,
		200px: 200px,
		230px: 230px,
		240px: 240px,
		250px: 250px,
		n8px: -8px,
		n10px: -10px,
		n19px: -19px,
		n25px: -25px,
		n50px: -50px,
		n90px: -90px,
		n200px: -200px,
		n240px: -240px,
		n340px: -340px,
		n350px: -350px,
		100n20: calc(100% + 20px),
		100n50: calc(100% - 50px),
		100n100: calc(100% + 100px),
		100n200: calc(100% + 200px),
		100n250: calc(100% + 250px),
		100n350: calc(100% + 350px),
);

/* ======= https://getbootstrap.com/docs/5.2/utilities/sizing/#utilities-api
*/

$sizings: (
		25: 25%,
		50: 50%,
		75: 75%,
		100: 100%,
		auto: auto,
		135px: 135px,
		150px: 150px,
		180px: 180px,
		190px: 190px,
		200px: 200px,
		240px: 240px,
		260px: 260px,
		296px: 296px,
		340px: 340px,
		368px: 368px,
		400px: 400px,
		500px: 500px,
		520px: 520px,
		800px: 800px
);

/* ======= utilities de bootstrap
*/

$utilities: ();
// stylelint-disable-next-line scss/dollar-variable-default
$utilities: map-merge(
				(
						"z-index": (
							property: z-index,
							class: z-index,
							values: ( 0, 1, 2, 3, 4)
						),
						"font-family": (
								property: font-family,
								class: font,
								responsive:true,
								values: (
										family-gantari: $font-family-gantari,
										family-roboto: $global-font-family,
								)
						),
						"font-size": (
								//rfs: true,
								property: font-size,
								responsive:true,
								class: fs,
								values: $font-sizes
						),
						"font-weight": (
								property: font-weight,
								class: fw,
								values: $font-weight
						),
						"position": (
								property: position,
								responsive:true,
								values: static relative absolute fixed sticky
						),
						"rounded": (
								property: border-radius,
								class: rounded,
								responsive: true,
								values: ( $radius )
						),
						"rounded-top": (
								property: border-top-left-radius border-top-right-radius,
								class: rounded-top,
								responsive: true,
								values: ( $radius )
						),
						"width": (
								property: width,
								class: w,
								responsive: true,
								values: $sizings
						),
						"height": (
								property: height,
								class: h,
								responsive: true,
								values: $sizings
						),
						"top": (
								property: top,
								responsive: true,
								values: $position-values
						),
						"start": (
								property: left,
								class: start,
								responsive: true,
								values: $position-values
						),
						"end": (
								property: right,
								class: end,
								responsive: true,
								values: $position-values
						),
						"bottom": (
								property: bottom,
								responsive: true,
								values: $position-values
				),
				),
				$utilities
);

/*
https://getbootstrap.com/docs/5.2/components/card/#sass-variables
 */

$card-spacer-y: 0;
$card-spacer-x: 55px;

/*
https://getbootstrap.com/docs/5.2/layout/grid/#variables
*/

$grid-breakpoints: (
		xs: 0,
		sm: 576px,
		md: 768px,
		lg: 992px,
		xl: 1200px,
		xxl: 1400px,
		xxxl: 1920px
);

/*
https://getbootstrap.com/docs/5.2/components/accordion/#sass-variables
 */

$accordion-color: $white;
$accordion-bg: $primary;
$accordion-border-width: 0;
$accordion-border-radius: 0;
$accordion-button-bg: $primary;
$accordion-button-active-bg: $primary;
$accordion-button-active-color: $white;
$accordion-button-color: $white;
$accordion-icon-color: $white;
$accordion-icon-active-color: $white;

/*
https://getbootstrap.com/docs/5.2/components/card/#variables
 */

$card-spacer-y:                     21px;
$card-spacer-x:                     24px;
$card-title-spacer-y:               $spacer * .5;
$card-border-width:                 0;
$card-border-radius:                4px;