// Menu detail responsive

.la-sante-en-chiffre{
  .currentTitle {
    img{
      height: 30px;
    }
  }
}

.currentTitle {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 20px;
  transition: all 0.3s ease;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: $bleu;
  cursor: pointer;
  &.actif {
    background-color: $bleu2;
    span {
      transform: rotate(180deg);
    }
  }
  h2 {
    color: $blanc;
    margin: 0;
    margin-left: 1.5rem;
  }
  span {
    position: absolute;
    right: 20px;
    color: $blanc;
  }
}

.menu-detail-responsive {
  bottom: 0;
  padding: 15px;
  overflow: hidden;
  height: 0;
  opacity: 0;
  transition: all 0.3s ease;

  &.toggle {
    height: auto;
    opacity: 1;
  }

  ul.menu {
    li {
      margin: 27px 0;
      &.actif {
        .deep {
          svg {
            transform: rotate(0deg);
          }
        }
      }
      &.active {
        a.active {
          color: $vert;
        }
      }
      &:first-child {
        margin-top: 10px;
      }
      &:last-child {
        margin-bottom: 0;
      }
      a {
        margin: 0;
      }
    }
  }

  .deeper {
    &.parent {
      > a {
        pointer-events: none;
      }
    }
    a.deep {
      margin-bottom: 0;
      &.current {
        color: $vert;
        font-weight: bold;
        background-color: transparent;
      }
      svg {
        transform: rotate(90deg);
      }
    }
    ul {
      overflow: hidden;
      height: 0;
      opacity: 0;
      transition: all 0.3s ease;
      margin-top: 0;
      padding-left: 10px;
      li {
        &:first-child {
          margin-top: 27px;
        }
        &.active {
          a {
            font-weight: initial;
            text-decoration: underline;
          }
        }
      }
      &.toggle {
        height: auto;
        opacity: 1;
      }
    }
  }
}
