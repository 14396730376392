.b-slider {
  &__slide {
    position: relative;
    &.uk-active {
      .b-slider__contenu {
        display: block;
      }
    }
  }
  &__contenu {
    display: none;
    background: $bleu;
    color: $blanc;
    border-radius: .8rem;
    padding: 1.5rem;
    max-width: 415px;
    margin: 0 0 0 auto;
    @media (min-width: $breakpoint-large) {
      margin-top: -10rem;
      position: relative;
    }
  }
}
