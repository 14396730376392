

.filtre_evenement{
  li{
    margin-bottom: 20px;
  }
  a{
    padding: 15px;
    border: 1px solid $gris5;
    border-radius: 4px;
    .uk-icon{
      color: $noir;
      transition: $transition;
    }
    .date{
      color: $rouge;
      line-height: 20px;
      strong{
        font-size: 2.4rem;
      }
      font-size: 1.4rem;
    }
    .bloc-texte{
      .page-header{
        padding: 0;
      }
      h2{
        color: $rouge;
        margin: 0;
        padding: 0 15px 0 0;
        font-size: 1.4rem;
        font-weight: bold;
        height: 33px;
      }
      p{
        padding-top: 8px;
        margin: 0;
        height: 44px;
        overflow: hidden;
        text-overflow:ellipsis;
        width: 230px;
        color: $gris;
        font-size: 1.4rem;
      }
      .thematique{
        font-size: 1.4rem;
        color: $gris;
      }
    }
  }
}