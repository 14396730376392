.category-module_mea_couleurs{
	div{
		&:nth-child(1){
			background-color: $vert;
		}
		&:nth-child(2){
			background-color: $bleu2;
		}
		&:nth-child(3){
			background-color: $violet;
		}
	}
	p{
		font-size: 1.6rem;
		color: rgba($blanc,0.5);
      margin-top: 0;
	}
	h2{
		color: $blanc;
		font-size: 2.3rem;
		font-weight: bold;
      margin-bottom: 5px;
	}
	a{
		color: $bleu;
		font-size: 1.3rem;
		background-color: $blanc;
		width: 250px;
	}
}

@media(max-width: 1024px){
	.category-module_mea_couleurs{
		h2{
			margin: 15px 0;
		}
		p{
			display: none;
		}
		br{
			display: none;
		}
	}
}

@media(max-width: 640px){
	.category-module_mea_couleurs{
		img{
			float: left;
			width: 40px;
			margin-right: 15px;
		}
		h2{
			font-size: 1.5rem;
			margin: 0;
			position: relative;
			top: 5px;
		}
		p{
			display: none;
		}
		a{
			clear: both;
			width: 100%;
			margin-top: 15px;
		}
		.uk-padding{
			padding: 15px;
		}
	}
}