// élément qui prend toute la longueur du site, rajouter un .container dans l'élément qui a cette classe
.centpourcent{
	margin-left: -50vw;
	margin-right: -50vw;
	clear: both;
	position: relative;
	right: 50%;
	left: 50%;
	width: 100vw;
}

.cart{
	right: -182px;
	transition: all 0.4s ease-in-out;
}

.encart{
	transition: all 0.4s ease-in-out;
	z-index: 3000;
}

a{
	&:hover{
		text-decoration: none;
	}
}

.uk-offcanvas-content{
	overflow: hidden;
}

*
{
	outline: none;
}

.picto_valide{
	background: url(../img/picto_valide.png) no-repeat left 50%;
	padding-left: 30px;
}

.picto_invalide{
	background: url(../img/picto_invalide.png) no-repeat left 50%;
	padding-left: 30px;
}

.dont-break-out {

  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;

}

// Basic youtube video instruction for mobile
.youtube_player {
  max-width: 560px;
  & > iframe {
    width: 100% !important;
  }
}
.mea_ressources .youtube_player {
	max-width: unset;
}
@media (max-width: 639px) {
  .u-md-pl-0 {
    padding-left: 0 !important;
  }
}

.text-violet{
	color: $violet2;
}

.text-orange{
	color: $orange2;
}

.text-bleu{
	color: $bleu;
}

.text-vert{
	color: $vert;
}

.bg-violet {
	background: $violet2;
}
.bg-orange {
	background: $orange2;
}
.bg-vert {
	background: $vert;
}

.hover-secondary02{
  div, a{
	transition: all ease-in-out 0.2s;
  }
  &:hover div, &:hover a{
	color: $secondary02 !important;
	transition: all ease-in-out 0.2s;
  }
}

.youtube_player_social{
  iframe{
	border-radius:0.8rem;
	@include media-breakpoint-up(lg) {
	  height: 250px;
	}
  }
}