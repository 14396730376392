.la-sante-en-chiffre{
	.item-page{
		overflow: visible;
	}
	&.familiale-scolaire{
		.item-page{
			@include barometre-jeune($familiale-scolaire);
		}
	}
	&.etat-sante{
		.item-page{
			@include barometre-jeune($etat-sante);
		}
	}
	&.alimentation{
		.item-page{
			@include barometre-jeune($alimentation);
		}
	}
	&.sante-mentale{
		.item-page{
			@include barometre-jeune($sante-mentale);
		}
	}
	&.tabac{
		.item-page{
			@include barometre-jeune($tabac);
		}
	}
	&.sante-sexuelle{
		.item-page{
			@include barometre-jeune($sante-sexuelle);
		}
	}
}