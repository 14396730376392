.b-presse{
  h1{
    color: $bleu2;
    font-size: 2.4rem;
    font-weight: bold;
  }
  .pd-filebox{
    border: 1px solid $gris7 !important;
    border-radius: 4px;
    padding: 10px !important;
    margin: 20px 0 !important;
    overflow: hidden;
    .pd-filenamebox{
      a{
        color: $bleu;
        font-size: 1.9rem;
        font-weight: bold;
      }
      .date-ml{
        color: $gris8;
        display: block;
        font-size: 1.4rem;
        font-weight: normal;
      }
      .pd-button-download{
        background-color: $bleu;
        color: $blanc;
        text-transform: uppercase;
        border-radius: 4px;
        font-size: 1.4rem;
        padding: 10px 30px;
        font-weight: bold;
        border: 0;
        cursor: pointer;
      }
      .poid-extension{
        color: $gris8;
        font-size: 1.4rem;
        padding-right: 20px;
      }
      #phocadownloadform{
        position: relative;
        top: 3px;
      }
    }
    .pd-document16{
      height: 37px !important;
      padding-left: 40px !important;
      color: $gris8;
      display: block;
      padding-top: 8px;
      font-size: 1.4rem;
      span{
        color: $bleu;
        font-size: 1.9rem;
        font-weight: bold;
      }
    }
  }
  .pd-button-download{
    .btn{
      background-color: $bleu;
      color: $blanc;
      text-transform: uppercase;
      border-radius: 4px;
      font-size: 1.4rem;
      padding: 10px 30px;
      font-weight: bold;
    }
    .poid-extension{
      color: $gris8;
      font-size: 1.4rem;
      padding-right: 20px;
    }
  }
}