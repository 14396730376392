// position de module pied de page

.retour-haut-page{
	color: $noir;
	margin-top:180px;
	svg{
		position: relative;
		right: 1px;
	}
}

.menu-pre-footer {
	.bloc-menu {
		background: url(../img/perso-bcg.png) no-repeat left 45px transparent;
	}
}