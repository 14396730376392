.category-module_mea_libre{
	.mod-articles-category-introtext{
		color: $gris;
		font-size: 1.4rem;
		opacity: 0.7;
		line-height: 18px;
		height: 55px;
		transition: $transition;
		overflow: hidden;
	}
	h2{
		color: $violet;
		font-size: 2rem;
		font-family: $global-font-family;
		font-weight: $font-weight-bold;
		height: 55px;
		overflow: hidden;
		margin: 0 0 12px;
		&.detail{
			color: $bleu;
		}
	}
	a{
		color: $vert;
		&:hover{
			.mod-articles-category-introtext{
				transition: $transition;
				color: $violet;
			}
		}
		&.detail{
			@media(max-width: 640px) {
				padding-left: 0;
			}
			h2 {
				color: $bleu;
			}
			&:hover{
				.mod-articles-category-introtext{
					transition: $transition;
					color: $bleu3;
				}
			}
		}
	}
	.bloc-texte{
		padding-bottom: 40px;
	}
	.intro-image-default{
		background-color: $bleu;
		width: 168px;
		height: 167px;
		@media(max-width:1024px ) {
			width: 100%;
			height: auto;
		}
	}
}

@media(max-width:1279px ){
	.introduction-categorie div{
		//padding-left: 0;
    .item-image {
      display: block;
      margin: 0 auto;
      float: none;
      text-align: center;
    }
	}
	.category-module_mea_libre{
		margin: 15px 0 !important;
		padding-left: 0;
		.mod-articles-category-introtext{
			height: auto;
		}
		h2{
			height: auto;
			font-size: 2rem;
		}
		span{
			position: relative !important;
		}
		.bloc-texte{
			padding-left: 10px;
			padding-bottom: 0;
		}
		.detail{
			padding-left: 10px !important;
		}
	}
}

@media(max-width:639px ){
  .category-module_mea_libre{
    a > .uk-child-width-1-2 {
      & > .uk-first-column {
        width: calc(100% * 1 / 3.001);
      }
      & > .bloc-texte {
        width: calc(100% * 2 / 3.001);
      }
      .uk-position-bottom {
        display: none;
      }
    }
  }
}
