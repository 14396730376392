.menu-responsive{
	background-color: $bleu2;
	padding: 65px 25px;
	font-family: $global-font-family;
	font-size: 1.6rem;
	overflow-x:hidden;
	z-index: 2;
	.uk-nav{
		margin: 0 !important;
	}
	ul{
		padding: 0;
	}
	li{
		padding-top: 10px;
		&.divider{
			@extend %centpourcent;
			border-bottom: 1px solid rgba($blanc,0.15);
			span{
				display: none;
			}
		}
		.nav-header{
			@extend %centpourcent;
			background-color: $bleu4;
			color: $gris;
			padding: 5px 25px;
			display: block;
		}
	}
	li.uk-parent{
		a{
			&:after{
				display: none;
			}
			&.suivant {
				span {
					float: right;
				}
			}
			&.retour{
				color: $gris;
				padding:10px 0px 14px;
				span{
					float: left;
					color: $blanc;
					margin-right: 5px;
					position: relative;
					top: 4px;
					right: 4px;
				}
			}
		}
	}
	h2{
		@extend %centpourcent;
		background-color: $bleu4;
		color: $gris;
		padding: 5px 25px;
		margin-left: -50vw !important;
		margin-right: -50vw !important;
	}
	.support-pro{
		background-color: $vert2;
		text-transform: uppercase;
		font-weight: bold;
		border-radius: 3px;
		font-size: 1.3rem;
		padding: 8px 32px;
		text-align: center;
		margin: 15px 0 25px;
	}
}

.menu-responsive2{
	z-index: 3000;
}

.menu-responsive-fermer{
	background-color: $bleu;
	padding: 17px 25px;
	color: $blanc;
	.uk-offcanvas-close{
		color: $vert;
		position: relative;
		left: 0;
		top: 0;
		float: left;
		padding: 0;
	}
}

.uk-offcanvas-bar {
  .nav-child {
	li {
	  padding: 5px 0;
	  a {
		font-size: 16px !important;
	  }
	}
  }
}