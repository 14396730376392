.mea_ressources{
	h3{
		color: $bleu;
		font-size: 2.4rem;
		font-weight: bold;
		padding-bottom: 20px;
		padding-top: 31px;
		margin: 0;
	}
	.videos{
		.en-tete-video{
			display: block;
			color: $gris;
			font-size: 1.6rem;
			padding: 10px 0;
			font-weight: bold;
		}
		p{
			margin: 0;
		}
		.phocadownloadfile-32{
			a{
				width: auto !important;
				background-color: $bleu;
				border: 0;
				border-radius: 4px;
			}
			.info-fichier{
				padding: 15px 35px 15px 0;
				display: block;
			}
			.icone-fichier{
				background-color: $bleu2;
				padding: 0 15px;
				border-radius: 4px;
			}
			.bloc-fichier{
				padding: 0;
			}
			.nom{
				color: $blanc;
			}
			.poid{
				color: $vert;
			}
		}
	}
	.fichiers{
		border: 1px solid $gris7;
		border-radius: 3px;
		padding: 12px;
		p{
			margin: 0;
		}
		a{
			padding: 15px 0;
			border: 0;
			.nom_fichier{
				color: $bleu;
			}
			.bloc-fichier{
				padding: 0;
			}
			&:hover{
				box-shadow: none;
			}
			.uk-width-3-5{
				padding-left: 15px;
			}
			.uk-width-1-5{
				padding-left: 10px;
			}
		}
	}
}