.com_dokamo_search{
  background-color: $gris2;
  .bandeau{
    margin-bottom: 0 !important;
  }
  .colonne-gauche-recherche{
    margin-top: 25px;
  }
  .mea_filtre_recherche{
    background-color: $blanc;
    padding: 20px 25px;
    border-radius: 3px;
    border: 1px solid $gris5;
    h2{
      color: $bleu;
      font-size: 1.8rem;
      font-weight: bold;
      span{
        padding-right: 15px;
        color: $vert;
        font-weight: bold;
      }
    }
    h3{
      color: $gris;
      font-size: 1.4rem;
      margin: 0;
      padding-top: 20px;
      padding-bottom: 12px;
    }

    label{
      text-transform: capitalize;
      color: $bleu;
      font-size: 1.2rem;
    }

    .liste-type{
      div{
        text-transform: capitalize;
        color: $bleu;
        font-size: 1.2rem;
        input{
          margin-right: 5px;
        }
      }
    }
    .bouton-filtre{
      padding-top: 20px;
      button{
        width: 100%;
        display: block;
        text-align: center;
      }
      .bouton-appliquer{
        background-color: $bleu;
        border-radius: 3px;
        text-transform: uppercase;
        font-family: $global-font-family;
        font-weight: $font-weight-bold;
        font-size: 1.3rem;
      }
      .bouton-reinit{
        color: $gris;
        text-decoration: underline;
        margin-top: 8px;
        font-size: 1.4rem;
        font-family: $global-font-family;
      }
    }
    .uk-radio:checked{
      background-color: $bleu;
      padding: 10px;
    }
    .uk-checkbox:checked, .uk-checkbox:indeterminate{
      background-color: $bleu;
      border-radius: 5px;
      padding: 10px;
    }
    .chzn-single{
      padding: 8px 15px;
      border-radius: 4px;
      border: 1px solid $gris5;
      color: $bleu;
      background: none $blanc;
      height: auto;
      box-shadow: none;
      font-size: 1.2rem;
    }
  }
  .colonne-gauche-recherche{
	  @media(min-width: 960px){
		  padding-right: 50px;
	  }
  }
  .main{
    padding-top: 0px;
    .main{
      margin-top: 25px;
      h2{
        color: $bleu2;
        font-size: 2.4rem;
        font-weight: bold;
        @media(min-width: 1024px) {
          margin-top: 20px;
        }
        input{
	        @media(max-width: 640px){
		        margin-left: 0;
		        margin-top: 10px;
	        }
          background-color: $blanc;
          color: $bleu;
          padding: 5px 0 5px 15px;
          border-radius: 4px 0 0 4px;
          margin-left: 15px;
          font-size:1.4rem;
          font-weight: normal;
          text-transform: capitalize;
        }
        button{
	        @media(max-width: 640px){
		        margin-top: 10px;
	        }
          background-color: $blanc;
          padding: 0 10px;
          border-radius: 0 4px 4px 0;
        }
      }
      .resultats{
        h2{
          font-size: 1.8rem;
          color: $bleu;
        }
        &>li{
          padding: 0;
          position: relative;
          a{
            display: block;
            background-color: $blanc;
            padding: 20px 25px;
            border-radius: 3px;
            border: 1px solid $gris5;
            margin-bottom: 15px;
            color: $gris;
          }
          &:hover{
            h2{
              color:#3CC0CC;
            }
          }
          .btn-lien-copy{
            position: absolute;
            right: 15px;
            bottom: 30px;
            z-index: 4;
            padding: 5px 20px;
            background-color: $blanc;
            color: $bleu;
            border: 1px solid $bleu;
            border-radius: 3px;
            font-family: $global-font-family;
            font-weight: $font-weight-bold;
            font-size: 1.3rem;
            cursor: pointer;
            transition: all ease-in-out 0.2s;
            &:hover{
              background-color: $vert;
              color: $blanc;
              border-color: $blanc;
              transition: all ease-in-out 0.2s;
            }
          }
          .btn-lien-copy-end{
            padding: 5px 20px;
            background-color: $vert;
            color: $blanc;
            border-color: $blanc;
            border-radius: 3px;
            font-family: $global-font-family;
            font-weight: $font-weight-bold;
            font-size: 1.3rem;
          }
        }
        p{
          img{
            float: left;
            margin:0 15px 15px 0;
            @media(min-width: 1024px) {
              width: 168px;
              height: 168px;
              object-fit: contain;
            }
          }
        }
        hr{
          clear: both;
        }
      }
      .info-label{
	      @media(max-width: 640px){
		      position: relative !important;
		      margin: 0 !important;
		      padding: 0 0 15px 0;
		      span{
			      margin: 0 !important;
		      }
	      }
        span{
          border-radius: 4px;
          padding: 2px 15px;
          font-size: 1.2rem;
          background-color: $gris;
          color: $blanc;
          margin-left: 5px;
          text-transform: capitalize;
          &.public{
            background-color: $bleu;
            color: $blanc;
          }
          &.pro{
            background-color: $vert2;
            color: $blanc;
          }
        }
      }
      .annee{
        border-radius: 4px;
        padding: 2px 12px;
        font-size: 1.2rem;
        background-color: $gris6;
        border: 1px solid $gris6;
        color: $blanc;
        margin-left: 10px;
      }
      .label-tags, .label-thematique{
        padding-left: 5px;
        span{
          padding: 0;
          background: none;
          font-size: 1.4rem;
          color: $gris;
          margin-left: 5px;
        }
      }
    }
    .fenetre-recherche{
      margin-top: 0;
      background: url(../../images/bandeau_recherche.jpg) no-repeat center top transparent;
      @media(min-width: 1024px) {
        background-size: 100% auto;
      }
      @media(max-width: 1024px) {
        background-size: auto 100%;
      }
      .recherche-haut{
        background-color: $gris2;
        color: $gris;
        padding: 25px;
        border-radius: 4px 4px 0 0;
        p{
          margin: 0;
        }
        h1{
          color: $bleu;
          font-size: 2rem;
          font-weight: bold;
        }
      }
      .recherche-bas{
        background-color: $blanc;
        color: $gris;
        border-radius: 0 0 4px 4px;
        padding:25px;
        input{
          background-color: $blanc;
          border-bottom: 1px solid $gris7;
          padding-left: 0;
          &::placeholder{
            color: $gris;
          }
        }
        button{
          background-color: $bleu;
          color: $blanc;
          border-radius: 4px;
          text-transform: uppercase;
          font-size: 1.4rem;
        }
      }
      .fenetre{
        margin-top:140px;
        margin-bottom:140px;
      }
    }
  }
  #pagination{
    padding-bottom: 35px;
    color: $bleu;
    font-size: 1.4rem;
    #prev-page{
      padding-left: 15px;
      padding-right: 8px;
      color: $gris5;
    }
    #next-page{
      padding-right: 15px;
      padding-left: 8px;
      color: $bleu;
    }
    .en-cours{
      border: 1px solid $gris5;
      border-radius: 4px;
      padding: 2px 6px 0;
      font-size: 1.4rem;
      color: $vert2;
      position: relative;
      bottom: 3px;
    }
  }
}
