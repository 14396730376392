.b-chiffres-cles {
  &__value {
    font-size: 2.4rem;
    font-family: $global-font-family;
    font-weight: $font-weight-bold;
    color: $violet2;
    margin-bottom: 1.5rem;
  }
}

.b-chiffres-cles-complet {
  &__bloc {
    &:first-child {
      width: 100%;
      max-width: 100%;
      border-bottom: 1px dashed $bleu;
      padding-bottom: 1.5rem;
      .b-chiffres-cles-complet {
        &__value {
          color: $vert;
          font-size: 3.5rem;
        }
      }
    }
  }
  &__value {
    font-size: 2.4rem;
    font-family: $global-font-family;
    font-weight: $font-weight-bold;
    color: $violet2;
    margin-bottom: 1.5rem;
  }
}
