.partage-quizz{
	max-width: 600px;
	margin: 20px auto 0 auto;
	display: flex;
	justify-content: flex-start;
	flex-flow: wrap;
}

.text-partage-quiz{
	color: #8095AE;
	font-size: 1.5rem;
	font-weight: 500;
}

.share{;
	padding-left: 10px;
}

.partage-mail{
	padding-top: 2px;
}