// syntax

/* déclaration d'une fonction

@mixin truc($variable){
	color:$variable;
}

////* appel de la fonction

.class{
	@include truc(#000);
}

/* déclaration d'une procédure

%ma-fonction{
	color:#000;
}

////* appel de la procédure

.class{
	@extend %ma-fonction;
	border:0;
}


// fonctions déjà existantes
 
 - lighten($color, $amount) : Makes a color lighter.
 - darken($color, $amount) : Makes a color darker.
 - saturate($color, $amount) : Makes a color more saturated.
 - desaturate($color, $amount) : Makes a color less saturated.
 
Le tout sur ce site : http://sass-lang.com/documentation/Sass/Script/Functions.html
 
*/

%centpourcent{
	margin-left: -50vw;
	margin-right: -50vw;
	clear: both;
	position: relative;
	right: 50%;
	left: 50%;
	width: 100vw;
}

@function color-yiq($color, $dark: $noir, $light: $blanc) {
	$r: red($color);
	$g: green($color);
	$b: blue($color);

	$yiq: (($r * 299) + ($g * 587) + ($b * 114)) / 1000;

	@if ($yiq >= 150) {
		@return $dark;
	} @else {
		@return $light;
	}
}

@mixin barometre-jeune($couleur){
	.page-header{
		background-color: $couleur;
		padding: 15px 10px;
		margin-bottom: 30px;
		@media(min-width:1200px) {
			width: 100vw;
			position: relative;
			right: 110px;
			bottom: 25px;
			padding-left: 110px;
			margin-bottom: 0;
		}
		h1{
			color: color-yiq($couleur);
			margin: 0;
		}
	}
}

/* ========= appel des fonts
*/

// renseigner la variable de la font et la variable de la font-weight
@mixin font($font-family, $font-size, $font-weight, $color){
  font-family: $font-family;
  font-weight: $font-weight;
  font-size: $font-size;
  color: $color;
}