.page-accueil {
	.bloc-actu {
		background-color: $gris2;
		position: relative;
		z-index: 0;
	}
}

@include media-breakpoint-up(sm) {
  .header-inner {
	.uk-sticky-fixed {
	  .uk-navbar-left{
		img{
		  width: 50%;
		  top:-30px !important;
		  transition: 0.2s all ease-in-out;
		}
	  }
	}
  }
}

@include media-breakpoint-down(sm) {
  .header-inner {
	.uk-navbar-left{
	  img{
		width: 170px;
	  }
	}
  }
}