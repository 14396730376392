.category-desc{
	h2{
		color: $bleu;
		font-size: 2rem;
		font-weight: bold;
	}
	h3{
		color: $bleu;
		font-size: 1.6rem;
		font-weight: bold;
	}
	color: $gris;
	blockquote{
		padding: 15px;
		border-radius: 4px;
		border: 1px solid $gris;
		font-size: 1.6rem;
	}
	ul{
		list-style-type: none;
		padding: 0;
		li{
			position: relative;
			padding-left: 15px;
			&:before{
				content: '';
				display: block;
				width: 8px;
				height: 8px;
				background-color: $vert;
				border-radius: 10px;
				position: absolute;
				left: 0;
				top: 7px;
			}
		}
	}
	a span {
		color: $bleu2;
		font-size: 2rem;
		font-weight: bold;
	}
	.rl_sliders.accordion>.accordion-group {
		border: none;
		border-radius: inherit;
		border-bottom: 1px solid $bleu2;
	}
	.rl_sliders.accordion>.accordion-group>.accordion-body>.accordion-inner {
		border-top: 1px solid $bleu2;
	}
	a.bouton{
		background:url(../img/bouton.png) no-repeat 96% 50% $bleu;
		color: $blanc;
		border-radius: 4px;
		padding: 10px 50px 10px 20px;
		font-size:1.3rem;
		transition: all ease-in-out 0.2s;
		&:hover{
			background-position: 93% 50%;
			transition: all ease-in-out 0.2s;
		}
	}
	a.bouton-externe{
		background:url(../img/lien-externe.png) no-repeat 100% 50% $blanc;
		color: $vert;
		padding: 0px 28px 0px 0px;
		font-size:1.6rem;
		&:hover{
			text-decoration: underline;
		}
	}
}

