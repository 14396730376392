.accordeon{
	border: 1px solid $gris5;
	border-radius: 4px;
	clear: both;
	.menu-accordeon{
		padding: 15px;
		color: $bleu;
		svg{
			transition: all 0.2s ease-in-out;
			rect {
				stroke-width: 3px;
			}
		}
	}
	.accordeon-contenu{
		padding: 15px;
		overflow: hidden;
		.plus_info{
			margin: 0 !important;
		}
	}
	&.uk-open, &:hover{
		.menu-accordeon{
			svg{
				transform: rotate(45deg);
				transition: all 0.2s ease-in-out;
			}
		}
	}
  @media (max-width: 639px) {
    .bouton {
      display: block;
    }
  }
}
