.page-detail{
	main{
		padding-top: 15px;
	}
}

.view-map{
  main{
	padding-top: 0;
	.control-label{
	  display: none;
	}
  }
}