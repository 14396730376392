.menu-principal{
	@media (max-width: 959px) {
		.uk-navbar-toggle-icon{
			color: $vert;
			position: relative;
			bottom:2px;
      line-height: 30px;
		}
	}
	.retour-accueil{
		color: $gris4;
		font-weight: bold;
		@include media-breakpoint-up(xxxl) {
		  width: 336px;
		}
		@include media-breakpoint-down(xxxl) {
		  width: 223px;
		}
		@media (min-width: 959px) {
			.desktop{
				position: absolute;
				left: 0;
				bottom: -35px;
				display: block;
			}
			.mobile{
				display: none;
			}
		}
		@media (max-width: 959px) {
			.desktop{
				display: none;
			}
			.mobile{
				display: block;
			}
		}
	}
	ul{
		li{
			a{
				color: $bleu2;
				font-size: 1.4rem;
			  	@include media-breakpoint-up(xxxl) {
				  font-size: 1.6rem;
				}
			 
				span{
					top: 1px;
				}
				&:hover{
					color: $vert;
				}
				&.loupe{
					position: relative;
					top:4px;
				}
			}
			&.active{
				&>a{
					color: $vert;
				}
			}
		}
	}
}
